<div class="content-heading">
  <div class="header-container">
    <h3>Reporte de Inventarios</h3>
    <small>Reporte Web</small>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row mt-3">


            <div class="col">
              <div class="row">
                <div class="col">&nbsp; Organizaci&oacute;n</div>
              </div>
              <div class="row">
                <div class="col">
                  <select class="form-control" [(ngModel)]="organizationSelected"
                          name="RegionNameOri" required [disabled]="working"
                          (change)="getRegion()">
                    <option *ngFor="let unit of organizacionList" [value]="unit.UnitId">{{unit.UnitDescription}}</option>
                  </select>
                </div>
              </div>
            </div>

            
            <div class="col">
              <div class="row">
                <div class="col">&nbsp; Clasificaci&oacute;n Nodo</div>
              </div>
              <div class="row">
                <div class="col">
                  <select required
                          [disabled]="working"
                          (change)="getNodeByRegionLevel()"
                          class="form-control"
                          name="clasificationSelected"
                          [(ngModel)]="clasificationSelected">
                    <!--<option [value]="'0'">Todos</option>-->
                    <option [value]="'1'">Planta</option>
                    <option [value]="'2'" [hidden]="user.Org === 2">Ceve</option>
                    <!-- <option [value]="'2'">Autoservicio</option> -->
                  </select>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">&nbsp; Nodo</div>
              </div>
              <div class="row">
                <div class="col">
                  <ng-autocomplete
                    [placeholder]="'Búsqueda'"
                    [disabled]="working"
                    [data]="unitList"
                    [searchKeyword]="'UnitDescription'"
                    [itemTemplate]="itemInputTemplate"
                    [notFoundTemplate]="notFoundinputTemplate"
                    [debounceTime]="600"
                    [minQueryLength]="3"
                    [(ngModel)]="unitSelected"
                  >
                  </ng-autocomplete>
                  <ng-template #itemInputTemplate let-item>
                    <a [innerHTML]="item.UnitDescription"></a>
                  </ng-template>
                  <ng-template #notFoundinputTemplate let-notFound>
                    <div [innerHTML]="'No se encontraron coincidencias'"></div>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">&nbsp; Fecha de Inventario</div>
              </div>
              <div class="row">
                <div class="col">
                  <input
                    type="date"
                    class="form-control"
                    [disabled]="working"
                    [(ngModel)]="selectedDate"
                  />
                </div>
              </div>
            </div>
            <!-- <div class="col">
              <div class="row">&nbsp;</div>
              <div class="row">
                <button
                  [disabled]="searchingNode"
                  type="button"
                  class="btn btn-outline-primary"
                  (click)="getInventory()"
                >
                  <i class="fa fa-search" aria-hidden="true"></i>&nbsp; Buscar
                </button>
              </div>
            </div> -->
          </div>
          <!-- <div class="row mt-3 ">

            <div class="col-lg-12 col-md-12">
            <app-ceq-table
              [gridAdditionalOptions]="gridInventoryOptions"
              [columnDef]="columnGeneratedDefs"
              (apiEmitter)="gridEmiter($event)"
              #inventoryGrid
            >
            </app-ceq-table>
            </div>

          </div> -->
          <br><br>
          <div class="row">
            <!--Buttons-->
            <div class="col" >
              <button class="mr-1  btn btn-labeled btn-outline-success mb-2" [disabled]="downloading" (click)="download()">
                <span class="btn-label">
                <i class="fa fa-file-excel" aria-hidden="true" *ngIf="!downloading"></i>
                <i class="fa fa-circle-notch fa-spin" *ngIf="downloading"></i>
              </span>
                <span>Descargar reporte</span>
              </button>
            </div>
            <br><br><br>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

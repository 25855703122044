import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';

import { RoutesMapComponent } from './routes-map/routes-map.component';
import { environment } from '../../../environments/environment';

const routes: Routes = [
  { path: 'map-routes', component: RoutesMapComponent },
];

@NgModule({
  declarations: [RoutesMapComponent],
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})

export class MapModule { }

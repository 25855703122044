import { Injectable } from '@angular/core';
import { GenericService } from '../../common/services/GenericServices';
import { Observable } from 'rxjs';

import { CeveByContainer } from "../../common/models/CeveByContainer.Model";
@Injectable({
    providedIn: 'root'
})

export class CeveByContainerService {

    constructor(private genericService: GenericService) { }
  private managedEquipmentList: { EqId: number; isManaged: boolean, BalanceMc: boolean }[] = [];

    getManagedEquipmentList(){
      return this.managedEquipmentList;
    }

  setManagedEquipmentList(list: { EqId: number; isManaged: boolean, BalanceMc: boolean }[]) {
    this.managedEquipmentList = [];
      this.managedEquipmentList = list;
      // console.log(this.managedEquipmentList);
    }

  GetCeveByContainers(ceveId: number): Observable<CeveByContainer[]> {
    let url = "CeveByContainer"
    return this.genericService.getRequest(url, {}, ceveId);
  }

  PostCeveByContainers(containers: CeveByContainer[], unitId: number, org: number): Observable<boolean> {
    let url = "CeveByContainer/range/" + unitId;
    return this.genericService.postRequest(url, {}, org, containers);
   }

  getMinContainers(): Observable<any> {
    let url = `CeveByContainer/getMinContainers`;
    return this.genericService.getRequest(url, {}, 0);
  }

  getCheckedRouteBalanceContainers(ceveId: number): Observable<number[]> {
    let url = `CeveByContainer/${ceveId}/routebalance`;
    return this.genericService.getRequest(url, {}, 0);
  }

}

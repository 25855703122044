import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Router } from '@angular/router';
import { SettingsService } from '../../../../core/settings/settings.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { CeqTableComponent } from '../../../common/ceq-table/ceq-table.component';
import { EditChildRendered } from '../../../common/renders/edit-child-rendered';
import { DeleteChildRendered } from '../../../common/renders/delete-child-rendered';
import { TransportLineService } from '../transport-line.service';
import { TransportLine } from '../../../common/models/TransportLine.Model';
import { UserLogin } from '../../../common/models/UserLogin.Model';

@Component({
  selector: 'app-carrier-list',
  templateUrl: './transport-line-list.component.html',
  styleUrls: ['./transport-line-list.component.scss']
})
export class TransportLineListComponent implements OnInit {

  constructor(private router: Router,
    private settings: SettingsService,
    private transportLineService: TransportLineService) { }


  @ViewChild('CeqGrid', { static: false }) ceqGrid: CeqTableComponent;
  options: GridOptions;
  PageSizes = ["10", "25", "50", "100", "1000"];
  lineList: TransportLine[] = [];
  keyword = "LineName";
  selectedLine: string;
  userLogin: UserLogin;
  type: string;
  line: string;

  gridOptions = {
    context: this,
    frameworkComponents: {
      childEditRenderer: EditChildRendered,
      childDeleteMessageRenderer: DeleteChildRendered
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) { },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    },
    onCellClicked(event: any) {
      if (event.colDef.field == 'LineName') {
        Swal.fire('Línea de Transporte', event.value)
      }
    }
  }

  columnDefs = [
    {
      headerName: 'ID',
      field: 'TransportLineId',
      width: 10,
      cellClass: 'align-start',
      sortable: true,
      unSortIcon: true,
      sort: 'asc',
      hide: true,
      resizable: true,
      editable: false
    },
    {
      headerName: 'Línea',
      field: 'LineName',
      width: 40,
      cellClass: 'align-start',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      editable: false
    },
    {
      headerName: 'Razón Social',
      field: 'FiscalName',
      width: 60,
      cellClass: 'align-start',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      editable: false
    },
    {
      headerName: 'RFC',
      field: 'TransportNIT',
      width: 30,
      cellClass: 'align-start',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      editable: false
    },
    {
      headerName: 'Última modificación',
      field: 'ModifiedAt',
      width: 20,
      cellClass: 'align-start',
      sortable: true,
      unSortIcon: true,
      editable: false,
      resizable: true,
      cellRenderer: (params: any) => moment(params.data.ModifiedAt).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      headerName: 'Modificado por',
      field: 'ModifiedBy',
      width: 20,
      cellClass: 'align-start',
      resizable: true,
      editable: false
    },
    {
      headerName: 'Editar',
      field: 'icon',
      width: 5,
      cellRenderer: 'childEditRenderer',
      colId: 'params',
      cellClass: 'text-center',
      hide: false,
    },
    {
      headerName: 'Eliminar',
      field: 'icon',
      width: 5,
      cellRenderer: 'childDeleteMessageRenderer',
      colId: 'params',
      cellClass: 'text-center',
      hide: false,
    }
  ];


  ngOnInit(): void {
    this.userLogin = this.settings.getUser();
  }

  gridEmiter(GridOptions: GridOptions) {
    this.options = GridOptions;
    this.getLineList()
  }

  onPageSizeChange(value: number) {
    console.log(value);
  }

  btnAddClick() {
    window.open('/tax/transportline/create', '_blank');
  }

  edit(row: any) {
    console.log("row", row.data);
    window.open('/tax/transportline/' + row.data.TransportLineId, '_blank');
  }

  delete(row: any) {
    Swal.fire({
      title: 'Confirmación',
      text: "¿Desea eliminar la línea de transporte " + row.data.LineName + "?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> &nbsp; Aceptar',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i> &nbsp; Cancelar',
      backdrop: `rgba(0,157,210,0.5)`
    }).then((result) => {
      if (result.isConfirmed) {
        this.transportLineService.deleteLine(row.data.TransportLineId).subscribe(data => {
          console.log("result", data);
          Swal.fire(
            '¡Éxito!',
            'La línea de transporte se eliminó correctamente.',
            'success'
          );
        }, (error) => {
          console.log("error", error);
          Swal.fire('¡Error!', error.error, 'error');
        }, () => {
          this.getLines();
        });
      }
    })
  }


  getLineList() {
    this.lineList = [];
    this.transportLineService.getLines().subscribe(data => {
      this.lineList = data;
    });
    this.lineList = this.lineList.sort((a, b) => a.LineName < b.LineName ? -1 : 1);
    
    this.getLines();
  }

  getLines() {
    this.ceqGrid.endPoint = `transportline/search/${this.line}`;
    this.ceqGrid.refreshTable();
  }

  selectEvent(event: any) {
    this.line = event.LineName;
    this.getLines();
  }

  cmbLines(): void {
    this.line = `undefined`
    this.getLines();
  }

}

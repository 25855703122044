<div class="content-heading">
  <div class="header-container">
    <h3>Productos</h3>
    <small>Cat&aacute;logo de Productos</small>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-default">
        <div class="card-header">
          <div class="row">
            <div class="col-lg-4">
              <label>C&oacute;digo</label>
              <input
                type="text"
                class="form-control"
                placeholder="Código"
                [(ngModel)]="productCode"
                (input)="getProduct()"
              />
            </div>
            <div class="col-lg-4">
              <label>Nombre</label>
              <input
                type="text"
                class="form-control"
                placeholder="Nombre"
                [(ngModel)]="productName"
                (input)="getProduct()"
              />
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <app-ceq-table #CeqGrid [gridAdditionalOptions]="gridOptions" [columnDef]="columnDefs"
                (apiEmitter)="gridEmiter($event)">
              </app-ceq-table>
            </div>
          </div>
          <div class="row mt-3 col-lg-12 col-md-12">
            <div class="pagesize-control">
              Tama&ntilde;o de p&aacute;gina:
              <select (change)="onPageSizeChange($event.target.value)">
                <option *ngFor="let size of pageSizes" [ngValue]="size">{{size}}</option>
              </select>
            </div>
          </div>
          <div class="row mt-3 col-lg-12 col-md-12">
            <!-- <button type="button" class="btn btn-outline-primary" (click)="btnAddClick()">
              <i class="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;
              Nuevo centro de venta
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

import { InventoryService } from 'src/app/ceq/inventory/inventory.service';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { EmployeeService } from 'src/app/ceq/catalog/employee/employee.service';
import { CarrierService } from '../carrier.service';
import { CarrierModel } from 'src/app/ceq/common/models/Carrier.Model';
import { UserLogin } from 'src/app/ceq/common/models/UserLogin.Model';
import { Employee } from 'src/app/ceq/common/models/Employee.Model';
import { Unit } from 'src/app/ceq/common/models/Unit.Model';

@Component({
  selector: 'app-carrier-detail',
  templateUrl: './carrier-detail.component.html',
  styleUrls: ['./carrier-detail.component.scss']
})
export class CarrierDetailComponent implements OnInit {

  constructor(private url: ActivatedRoute,
    private router: Router,
    private settings: SettingsService,
    private carrierService: CarrierService,
    private employeeService: EmployeeService,
    private inventoryService: InventoryService) {
    this.carrier = new CarrierModel();
  }

  org: number = 1;
  userLogin: UserLogin;
  carrier: CarrierModel;
  vehicleId: number;
  isNew: boolean = false;
  units: Unit[] = [];
  employees: Employee[] = [];
  selectedUnit: string = "";
  selectedEmployee: string = "";
  searchEmployeeField = 'EmployeeName';
  searchUnitField = 'UnitDescription';

  ngOnInit(): void {
    this.userLogin = this.settings.getUser();
    let currentId = this.url.snapshot.paramMap.get('id');
    this.carrier.Org = this.userLogin.Org;

    if (this.isNullOrWhitespace(currentId)) {
      this.isNew = true;
      this.fillCombos();
    }
    else {
      this.isNew = false;
      this.carrier.EmployeeCode = currentId;
      this.getFiscalConfig();
    }
  }

  formSubmit(value: any) {
    if (this.isNew) {
      //Create
      this.carrierService.createCarrier(this.carrier).subscribe(data => {
        Swal.fire('¡Éxito!', 'Vehículo creada', 'success');
        this.btnCancelOnClick();
      }, (error) => {
        Swal.fire('¡Error!', error.error, 'error');
      });
    }
    else {
      //Update
      this.carrierService.updateCarrier(this.carrier).subscribe(data => {
        Swal.fire('¡Éxito!', 'Vehículo actualizado', 'success');
        this.btnCancelOnClick();
      }, (error) => {
        Swal.fire('¡Error!', error.error, 'error');
      });
    }
  }

  btnCancelOnClick() {
    // this.router.navigate(['/tax/carrier']);
    window.close();
  }

  changeEmployee(event: Employee): void {
    this.carrier.EmployeeCode = event.EmployeeCode;
    // this.getLabelFilters();
  }

  changeUnit(event: Unit): void {
    this.carrier.UnitId = event.UnitId;
    // this.getLabelFilters();
  }


  isNullOrWhitespace(input) {
    if (typeof input === 'undefined' || input == null) return true;
    return input.replace(/\s/g, '').length < 1;
  }

  fillCombos() {
    //Employees
    this.employeeService.GetEmployeesByUnitId(this.userLogin.UnitId, "", 0).subscribe(data => {
      this.employees = data;

      if (!this.isNew) {
        this.selectedEmployee = this.employees.find(x => x.EmployeeCode == this.carrier.EmployeeCode).EmployeeName;
      }
    });
    //Units
    this.inventoryService.getAllFactoryCeve().subscribe((data: Unit[]) => {
      this.units = data;

      if (this.isNew) {
        this.carrier.UnitId = this.userLogin.UnitId;
        this.selectedUnit = this.units.find(x => x.UnitId == this.userLogin.UnitId).UnitDescription;
      }
      else {
        this.selectedUnit = this.units.find(x => x.UnitId == this.carrier.UnitId).UnitDescription;
      }
    })
  }

  getFiscalConfig() {
    this.carrierService.getCarrierById(this.carrier.EmployeeCode).subscribe(data => {
      this.carrier = data;
      this.selectedEmployee = data.EmployeeCode;
      //obtengo los datos de los combos
      this.fillCombos();

    }, (error) => {
      Swal.fire('¡Error!', "No se encontró el vehículo solicitado", 'error');
      this.btnCancelOnClick();
    });
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, observable } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { UserLogin } from 'src/app/ceq/common/models/UserLogin.Model';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { GenericService } from 'src/app/ceq/common/services/GenericServices';
import { Notification as NotificationDTO } from 'src/app/ceq/common/models/Notification.Model';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  userLogin: UserLogin;
  currentMessage$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private angularFireMessaging: AngularFireMessaging, private settingsServ: SettingsService, private genericServ: GenericService) {
    // this.angularFireMessaging.messaging.subscribe((_messaging) => {
    //   _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //   _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    // });
    this.userLogin = this.settingsServ.getUser();
  }

  requestPermission() {
    Notification.requestPermission().then(perm => {
      if (perm == 'granted') {
        this.angularFireMessaging.requestToken.subscribe((token) => {
          if (this.userLogin) {
            this.genericServ.putRequest(`user/${this.userLogin.Org}/${this.userLogin.Id}/${token}`, {}, 0, {}).subscribe(() => {
              // console.log('web token added successfully');
            }, err => {
              console.log('error adding web token', err)
            });
          }
        }, (err) => {
          console.error('No se pudieron obtener permisos para las notificaciones', err);
        });
      }
      else {
        console.log("Permiso de notificaciones denegado");
      }
    });
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      this.currentMessage$.next(payload);
    });
  }

  sendMessage(org: number, fromUser: number, toUnit: number, notification: NotificationDTO): Observable<any> {
    return this.genericServ.postRequest(`Notification/${org}/${fromUser}/${toUnit}`, {}, 0, notification);
  }

  sendMail(org: number, fromUser: number, toUnit: number, notification: NotificationDTO): Observable<any> {
    return this.genericServ.postRequest(`Notification/sendMail/${org}/${fromUser}/${toUnit}`, {}, 0, notification);
  }

  getNotifications(org: number, userId: number): Observable<NotificationDTO[]> {
    return this.genericServ.getRequest(`Notification/${org}/${userId}`, {});
  }

  readNotification(org: number, notificationId: number): Observable<any> {
    return this.genericServ.putRequest(`Notification/${org}/${notificationId}`, {}, 0, {});
  }

  readNotificationList(org: number, notificationList: number[]): Observable<any> {
    return this.genericServ.putRequest(`Notification/${org}/readList`, {}, 0, {notificationList});
  }
}

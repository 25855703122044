import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/ceq/common/services/GenericServices';
import { ContainerType } from 'src/app/ceq/common/models/ContainerType.Model';

/**
 * Service class for ContainerType entity with the next methods:
 * 
 * @remarks
 * getContainerTypeData()
 * addContainerType()
 * editContainerType()
 * removeContainerType()
 *
 * @class ContainerTypeService
 */
@Injectable({
  providedIn: 'root'
})
export class ContainerTypeService {

  constructor(private genericService: GenericService) { }

  /**
 * Returns every ContainerType in database
 * 
 * 
 * @returns {Observable<ContainerType[]>} - Observable with an array of ContainerType
 * @memberof ContainerTypeService
 */
  getContainerType(): Observable<ContainerType[]> {
    return this.genericService.getRequest('ContainerType', {}, 0);
  }

  /**
   * Returns an observable with an instance of ContainerType by ContainerTypeId
   * 
   * @param {string} containerTypeId - The Id of ContainerType to get certain data
   * 
   * @returns {Observable<ContainerType>} - Observable with an instance of ContainerType
   * @memberof ContainerTypeService
   */
  getContainerTypeData(containerTypeId: string): Observable<ContainerType> {
    return this.genericService.getRequest(`ContainerType/${containerTypeId}`, {}, 0);
  }

  /**
   * Add a new ContainerType to database
   *
   * @param {ContainerType} ContainerType - The ContainerType object to add without id
   * @returns {Observable<any>} - Status request
   * @memberof ContainerTypeService
   */
  addContainerType(ContainerType: ContainerType): Observable<any> {
    return this.genericService.postRequest('ContainerType', {}, 0, ContainerType);
  }

  /**
   * Update a ContainerType from a certain ContainerType object with id
   *
   * @param {ContainerType} ContainerType - The ContainerType object to update with id
   * @returns {Observable<any>} - Status request
   * @memberof ContainerTypeService
   */
  editContainerType(ContainerType: ContainerType): Observable<any> {
    return this.genericService.putRequest(`ContainerType/${ContainerType.ContainerTypeId}`, {}, 0, ContainerType);
  }

  /**
   * Remove a ContainerType from a certain Id
   * 
   * @param {string} containerTypeId - The Id of ContainerType to delete
   * @returns {Observable<any>} - Status request
   * @memberof ContainerTypeService
   */
  removeContainerType(containerTypeId: string): Observable<any> {
    return this.genericService.deleteRequest(`ContainerType/${containerTypeId}`, {}, 0);
  }
}

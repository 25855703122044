<div class="content-heading">
  <div class="header-container">
    <h3>Validación de Procesos</h3>
    <small>Validaciones sobre los procesos más importantes del sistema</small>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <div class="row filter-container">
                <div class="col-sm-4">
                  <span>Fecha:</span>
                  <input type="date"
                         [(ngModel)]="selectedDate"
                         max="{{ actualDate }}"
                         class="form-control w-70"
                         (change)="selectEvent(null)" />
                </div>
                <div class="col-sm-4">
                  <span>Proceso</span>
                  <ng-autocomplete (selected)="selectEvent($event)"
                                   (inputCleared)="clearEvent()"
                                   [initialValue]=""
                                   [searchKeyword]="keyword"
                                   [data]="eventList"
                                   [placeholder]="'Seleccione...'"
                                   [itemTemplate]="itemOutputTemplate"
                                   [notFoundTemplate]="notFoundoutputTemplate">
                  </ng-autocomplete>
                  <ng-template #itemOutputTemplate let-item>
                    <a [innerHTML]="item"></a>
                  </ng-template>

                  <ng-template #notFoundoutputTemplate let-notFound>
                    <div [innerHTML]="'No se encontraron coincidencias'"></div>
                  </ng-template>
                </div>
              </div>
              <br />
              <app-ceq-table #CeqGrid
                             [gridAdditionalOptions]="gridOptions"
                             [columnDef]="columnDefs"
                             (apiEmitter)="gridEmiter($event)">
              </app-ceq-table>
            </div>
          </div>
          <div class="row mt-3 col-lg-12 col-md-12">
            <div class="pagesize-control">
              Tama&ntilde;o de p&aacute;gina:
              <select (change)="onPageSizeChange($event.target.value)">
                <option *ngFor="let size of PageSizes" [ngValue]="size">{{size}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="content-heading">
  <div class="header-container">
    <h3>Reporte de Altas y Bajas de Equipo</h3>
    <small>Reporte Web</small>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-default">
        <div class="card-body">
          <div id="RECSEG">
            <div class="row filter-container">
              <div class="col-sm-5">
                <span>Tipo</span>
                <select [(ngModel)]="TipoSelected"
                        class="form-control"
                         [ngModelOptions]="{standalone: true}"
                        name="tipoOption">
                  <option *ngFor="let obj of tipos" [value]="obj.Id">{{obj.Description}}</option>
                </select>


              </div>
              <div class="col-sm-5">
                <span>Equipo</span>

                <ng-autocomplete [placeholder]="'Búsqueda'"
                                 [disabled]=""
                                 [data]="items"
                                 [searchKeyword]="inputArgumentItems"
                                 [itemTemplate]="itemOutputTemplateItem"
                                 [notFoundTemplate]="notFoundoutputTemplate"
                                 [debounceTime]="600"
                                 [minQueryLength]="3"
                                 [(ngModel)]="selectedItem"
                                 [ngModelOptions]="{standalone: true}"
                                 (selected)='selectItemEvent($event)'>
                </ng-autocomplete>
                <ng-template #itemOutputTemplateItem let-item>
                  <a [innerHTML]="item.ItemDescription"></a>
                </ng-template>
                <ng-template #notFoundoutputTemplate let-notFound>
                  <div [innerHTML]="'No se encontraron coincidencias'"></div>
                </ng-template>
              </div>
            </div>

            <div class="row filter-container">
              <div class="col-sm-5">
                <span>Desde</span>
                <input [disabled]="working"
                       type="date"
                       class="form-control"
                       [(ngModel)]="desde"
                       (change)="changeDate(true)" />


              </div>
              <div class="col-sm-5">
                <span>Hasta</span>
                <input [disabled]="working"
                       type="date"
                       class="form-control"
                       [(ngModel)]="hasta"
                       (change)="changeDate(true)" />
              </div>
            </div>

            <div class="row filter-container">
              <div class="col-sm-5">
                <span>Usuario Registro</span>
                <input type="search" [(ngModel)]="usrreg" [disabled]="false" [ngModelOptions]="{ standalone: true }" class="form-control" />
              </div>
              <div class="col-sm-5">
                <span>Usuario Recibe</span>
                <input type="search" [(ngModel)]="usrrec" [disabled]="false" [ngModelOptions]="{ standalone: true }" class="form-control" />
              </div>
            </div>

            <div class="row filter-container">
              <div class="col-sm-5">
                <span>Colaborador</span>
                <input type="search" [(ngModel)]="colaborador" [disabled]="false" [ngModelOptions]="{ standalone: true }" class="form-control" />
              </div>
              <div class="col-sm-5">
                <span>Pedido</span>
                <input type="search" [(ngModel)]="pedido" [disabled]="false" [ngModelOptions]="{ standalone: true }" class="form-control" />
              </div>
            </div>

            <div class="row filter-container">
              <div class="col-sm-5">
                <span>Factura/Folio/Acta</span>
                <input type="search" [(ngModel)]="comprobante" [disabled]="false" [ngModelOptions]="{ standalone: true }" class="form-control" />
              </div>
              <div class="col-sm-5">
                <span>Orden de Compra</span>
                <input type="search" [(ngModel)]="orden" [disabled]="false" [ngModelOptions]="{ standalone: true }" class="form-control" />
              </div>
            </div>


            <div class="row filter-container">
              <div class="col-sm-5">
                <span>Estatus</span>
                <select [(ngModel)]="statusSelected"
                        class="form-control"
                        [ngModelOptions]="{standalone: true}"
                        name="StatusOption">
                  <option *ngFor="let obj of status" [value]="obj.Id">{{obj.Description}}</option>
                </select>
              </div>
              <div class="col-sm-5">
                <span>Proveedor</span>

                <ng-autocomplete [placeholder]="'Búsqueda'"
                                 [disabled]=""
                                 [data]="providers"
                                 [searchKeyword]="inputArgumentProvider"
                                 [itemTemplate]="itemOutputTemplateProv"
                                 [notFoundTemplate]="notFoundoutputTemplate"
                                 [debounceTime]="600"
                                 [minQueryLength]="3"
                                 [(ngModel)]="selectedProvider"
                                 [ngModelOptions]="{standalone: true}"
                                 (selected)='selectProviderEvent($event)'>
                </ng-autocomplete>
                <ng-template #itemOutputTemplateProv let-item>
                  <a [innerHTML]="item.providerName"></a>
                </ng-template>
                <ng-template #notFoundoutputTemplate let-notFound>
                  <div [innerHTML]="'No se encontraron coincidencias'"></div>
                </ng-template>
              </div>
            </div>
            <br>
            <div class="row mt-3 col-lg-12 col-md-12">
              <!--Buttons-->
              <button class="mr-1  btn btn-labeled btn-outline-success mb-2" [disabled]="downloading" (click)="download()">
                <span class="btn-label">
                <i class="fa fa-file-excel" aria-hidden="true" *ngIf="!downloading"></i>
                <i class="fa fa-circle-notch fa-spin" *ngIf="downloading"></i>
                </span>
                <span>Descargar reporte</span>                
              </button>
            </div>
          </div>
          </div>
        </div>
    </div>
  </div>
</div>



import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import * as moment from 'moment';

import { GlobalFunctionsService } from '../../common/functions/global-functions.service';
import { EsabMovementsService } from './esab-movements.service';
import { SettingsService } from 'src/app/core/settings/settings.service';

import { EsabAvailable, EsabMovements } from '../../common/models/EsabMovements.Model';
import { CeqTableComponent } from '../../common/ceq-table/ceq-table.component';
import { UserLogin } from '../../common/models/UserLogin.Model';
import { Unit } from '../../common/models/Unit.Model';

@Component({
  selector: 'app-esab-movements',
  templateUrl: './esab-movements.component.html',
  styleUrls: ['./esab-movements.component.scss'],
})
export class EsabMovementsComponent implements OnInit {
  inputArgument: string = 'UnitDescription';
  selectedUnit: Unit = new Unit();
  date: Date = new Date();
  counter: { ContainerId: number; code: string; qty: number }[] = [];
  userLogin: UserLogin;
  nodes: Unit[] = [];
  downloading: boolean = false;
  searching: boolean = false;

  @ViewChild('CeqGrid', { static: false }) ceqGrid: CeqTableComponent;

  options: GridOptions;
  datepipe: DatePipe = new DatePipe('en-US');
  gridOptions = {
    context: this,
    frameworkComponents: {},
    suppressHorizontalScroll: false,
    onRowDoubleClicked(event: any) {},
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending:
        '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>',
    },
  };

  constructor(
    private router: Router,
    private services: EsabMovementsService,
    private settings: SettingsService,
    private globalFunctionService: GlobalFunctionsService
  ) {}

  columnDefs = [
    {
      headerName: '',
      children: [
        {
          headerName: 'FECHA DE CREACIÓN',
          field: 'CreatedAt',
          editable: false,
          resizable: true,
          cellRenderer: (params: any) =>
            moment(params.data.CreatedAt).format('DD-MM-YYYY HH:mm:ss'),
        },{
          headerName: 'FECHA DE APLICACIÓN',
          field: 'MovementDate',
          editable: false,
          resizable: true,
          cellRenderer: (params: any) =>
            moment(params.data.MovementDate).format('DD-MM-YYYY HH:mm:ss'),
        },
        {
          headerName: 'MOVIMIENTO',
          field: 'MovementType',
          editable: false,
          resizable: true,
        },
      ],
    },
    {
      headerName: 'ENTRADAS',
      children: [
        {
          headerName: 'CANTIDAD',
          editable: false,
          cellClass: 'text-right',
          cellRenderer: (params: any) =>
            params.data.IsInput &&
            params.data.MovementType != 'Saldo Inicial' &&
            params.data.MovementType != 'Disponible'
              ? this.globalFunctionService.separatorFormatter(params.data.Quantity)
              : '-',
        },
        {
          headerName: 'EQUIPO',
          editable: false,
          cellClass: 'text-center',
          cellRenderer: (params: any) =>
            params.data.IsInput &&
            params.data.MovementType != 'Saldo Inicial' &&
            params.data.MovementType != 'Disponible'
              ? params.data.ContainerCode
              : '-',
        },
      ],
    },
    {
      headerName: 'SALIDAS',
      children: [
        {
          headerName: 'CANTIDAD',
          editable: false,
          cellClass: 'text-right',
          cellRenderer: (params: any) => 
            params.data.IsInput ||
            params.data.MovementType == 'Saldo Inicial' ||
            params.data.MovementType == 'Disponible'
              ? '-'
              : this.globalFunctionService.separatorFormatter(params.data.Quantity),
        },
        {
          headerName: 'EQUIPO',
          editable: false,
          cellClass: 'text-center',
          cellRenderer: (params: any) => 
            params.data.IsInput ||
            params.data.MovementType == 'Saldo Inicial' ||
            params.data.MovementType == 'Disponible'
              ? '-'
              : params.data.ContainerCode,
        },
      ],
    },
    {
      headerName: 'SALDOS',
      children: [
        {
          headerName: 'CANTIDAD',
          field: 'Available',
          editable: false,
          cellClass: 'text-right',
          cellRenderer: (params: any) => this.globalFunctionService.separatorFormatter(params.data.Available)
        },
        {
          headerName: 'EQUIPO',
          field: 'ContainerCode',
          cellClass: 'text-center',
          editable: false,
        },
      ],
    },
  ];

  async ngOnInit() {
    this.globalFunctionService.hasFSBRPending();
    this.globalFunctionService.canOperate();
    
    this.userLogin = await this.settings.getUser();
    await this.services.getCentralTime().toPromise()
    .then((resp: Date)=>{
      this.date = resp;
    })
    await this.getUserNodes();
    this.find();
  }

  gridEmiter(GridOptions: GridOptions) {
    this.options = GridOptions;
    this.ceqGrid.endPoint = '';
    this.ceqGrid.data = [];
  }

  async getUserNodes() {
    await this.services
      .getUserNodes(this.userLogin.Org, this.userLogin.Id)
      .toPromise()
      .then((resp: Unit[]) => {
        console.log(resp);
        resp.map((x) => {
          if (x.LevelType == 'CEVE') {
            this.nodes.push(x);
          }
        });
        this.selectedUnit = this.nodes[0];
      })
      .catch();
  }

  find() {
    this.searching = true;
    //this.options.api.showLoadingOverlay();
    const mappedResult: EsabMovements[] = [];
    this.counter = [];
    console.log(this.date);
    let currentDate = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    this.services
      .getInitial(
        this.selectedUnit.UnitId,
        currentDate
      )
      .toPromise()
      .then((resp: EsabAvailable[]) => {
        resp.map((x) => {
          let value = x.Quantity;
          mappedResult.forEach((element) => {
            value =
              element.ContainerId == x.ContainerId
                ? value + element.Quantity
                : value;
          });
          mappedResult.push({
            ContainerId: x.ContainerId,
            ContainerCode: x.ContainerCode,
            IsInput: true,
            MovementType: 'Saldo Inicial',
            MovementDate: currentDate,
            CreatedAt: currentDate,
            Quantity: x.Quantity,
            Available: value,
          });
          this.counter.push({
            ContainerId: x.ContainerId,
            code: x.ContainerCode,
            qty: value,
          });
        });
        console.log(mappedResult);
        this.services
          .getMovements(
            this.selectedUnit.UnitId,
            currentDate
          )
          .toPromise()
          .then((resp: EsabMovements[]) => {
            resp.map((x) => {
              let value = 0;
              mappedResult.forEach((element) => {
                value =
                  element.ContainerId == x.ContainerId
                    ? element.IsInput
                      ? value + element.Quantity
                      : value - element.Quantity
                    : value;
                if (x.IsInput) {
                  x.Available = value + x.Quantity;
                } else {
                  x.Available = value - x.Quantity;
                }
              });
              mappedResult.push(x);
              var count = this.counter.find(
                (c) => c.ContainerId == x.ContainerId
              );
              if (count != undefined) {
                this.counter.find((c) => c.ContainerId == x.ContainerId).qty =
                  x.Available;
              }
            });
            this.counter.forEach((x) => {
              mappedResult.push({
                ContainerId: x.ContainerId,
                ContainerCode: x.code,
                IsInput: true,
                MovementType: 'Disponible',
                Available: x.qty,
                MovementDate: currentDate,
                Quantity: x.qty,
                CreatedAt: currentDate,
              });
            });
            this.ceqGrid.data = mappedResult;
          })
          .catch((error) => {
            console.log(error);
            // this.options.api.hideOverlay();
            this.globalFunctionService.messageToast(`No se pudieron obtener los movimientos.`, 'warning');
            return;
          })
          .finally(()=>{
            //this.options.api.hideOverlay();
            this.searching = false;
          });
      })
      .catch((error) => {
        //this.options.api.hideOverlay();
        this.searching = false;
        console.log(error);
        this.globalFunctionService.messageToast(`No se pudo obtener el saldo inicial.`, 'warning');
        return;
      })
  }

  getExcel() {
    this.downloading = true;
    let currentDate = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    this.services
      .getMovementsExcel(
        currentDate,
        this.selectedUnit.UnitId
      )
      .toPromise()
      .then((resp: any) => {
        if (resp == null) {
          this.globalFunctionService.messageToast(`No se ha encontrado información.`, 'warning');
          return;
        }
        let blob = new Blob([resp], { type: resp.type });
        const anchor = document.createElement('a');

        anchor.download = `Movimientos ESAB ${this.date} - ${this.selectedUnit.UnitDescription}.xlsx`;
        anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
        anchor.click();
      })
      .catch((error) => {
        console.log(error);
        this.globalFunctionService.messageToast(`No se pudo obtener el archivo.`, 'warning');
        return;
      })
      .finally(() => {
        this.downloading = false;
      });
  }

}

<div>
    <label>Tiempo restante: {{hours}} hrs {{minutes}} min {{seconds}} seg</label>
</div>

<section class="countDown-stopwatch" *ngIf="showCountdownBox">
    <div class="row">
        <div class="col col-md-12">
            <h3>Desea agregar m&aacute;s tiempo a la compulsa?</h3>
        </div>
    </div>
    <div class="row">
        <div class="col col-md-3">
            <button class="mr-1 btn btn-labeled btn-primary mb-2" (click)="increaseEventCountDown(15)" [disabled]="!countdown15Enabled">
                <span class="btn-label">
                    <i class="fas fa-clock"></i>
                </span>
                <span>+15 min.</span>
            </button>
        </div>
        <div class="col col-md-3">
            <button class="mr-1 btn btn-labeled btn-primary mb-2" (click)="increaseEventCountDown(30)" [disabled]="!countdown30Enabled">
                <span class="btn-label">
                    <i class="fas fa-clock"></i>
                </span>
                <span>+30 min.</span>
            </button>
        </div>
        <div class="col col-md-3">
            <button class="mr-1 btn btn-labeled btn-primary mb-2" (click)="increaseEventCountDown(45)" [disabled]="!countdown45Enabled">
                <span class="btn-label">
                    <i class="fas fa-clock"></i>
                </span>
                <span>+45 min.</span>
            </button>
        </div>
        <div class="col col-md-3">
            <button class="mr-1 btn btn-labeled btn-primary mb-2" (click)="increaseEventCountDown(60)" [disabled]="!countdown60Enabled">
                <span class="btn-label">
                    <i class="fas fa-clock"></i>
                </span>
                <span>+60 min.</span>
            </button>
        </div>
    </div>
</section>

<div class="row form-group">
    <div class="col col-xl-3 col-lg-3 col-md-4 col-sm-6" *ngIf="!adjApkRunning && !adjWebRunning && showWebPhisical">
        <button type="button" class="mr-1 btn btn-labeled btn-outline-success mb-2" (click)="adjWeb()" style="width: 195px;">
            <span class="btn-label">
                <i class="fas fa-globe"></i>
            </span>
            <span>Conteo f&iacute;sico en WEB</span>
        </button>
        
    </div>
    <div class="col col-xl-3 col-lg-3 col-md-4 col-sm-6" *ngIf="adjWebRunning">
        <button type="button" class="mr-1 btn btn-labeled btn-outline-danger mb-2" (click)="cancelAdjweb()" style="width: 245px;">
            <span class="btn-label">
                <i class="fas fa-globe"></i>
            </span>
            <span>Cancelar conteo f&iacute;sico en WEB</span>
        </button>
    </div>
    <div class="col col-xl-3 col-lg-3 col-md-4 col-sm-6" *ngIf="!adjApkRunning && !adjWebRunning && showApkPhisical">
        <button type="button" class="mr-1 btn btn-labeled btn-outline-warning mb-2" (click)="adjApk()" style="display: flex; align-items: center;">
            <span class="btn-label">
                <i class="fas fa-mobile-alt" style="text-align: center; flex: 1;"></i>
            </span>
            <span>Conteo f&iacute;sico en APK</span>
        </button>
    </div>
    <div class="col col-xl-3 col-lg-3 col-md-4 col-sm-6" *ngIf="adjApkRunning">
        <button type="button" class="mr-1 btn btn-labeled btn-outline-danger mb-2" (click)="cancelAdjapk()" style="width: 235px;">
            <span class="btn-label">
                <i class="fas fa-mobile"></i>
            </span>
            <span>Cancelar conteo f&iacute;sico en APK</span>
        </button>
    </div>


    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6" *ngIf="!adjApkRunning && !adjWebRunning">
        <button type="button" class="mr-1 btn btn-labeled btn-outline-danger mb-2" (click)="cancelAdjust()" style="width: 180px;">
            <span class="btn-label">
                <i class="fas fa-times"></i>
            </span>
            <span>Cancelar compulsa</span>
        </button>
    </div>
</div>

<div class="form-container">
    <div class="row hide-print">
        <!-- Validar etiqueta vacía -->
    </div>

    <br>
    <app-loading *ngIf="searching"></app-loading>
    <div id="cutContainer">
        <h4 *ngIf="factoryCutObject.Status === 'PUBLISHED'">Fecha y hora de posteo: {{factoryCutObject.ModifiedAt | date: 'dd/MMMM/yyyy'}} a las {{factoryCutObject.ModifiedAt | date: 'HH:mm'}}</h4>
        <div class="full-border">
            <div class="data-container pointer">
                <div class="d-flex black-text">
                    <div class="number-width col-header col-lg-4">
                        <div class="col-lg-4 d-block"><b></b></div>
                        <div class="col" style="display: block; white-space: break-spaces; max-width: 400px;">
                            <h3><b>{{selectedNodeCut.UnitDescription}}</b></h3>
                        </div>
                        <div class="col mb-2 text-wrap">
                            <h4>Folio compulsa: {{adjustNode.AdjustCode}}</h4>
                        </div>
                    </div>
                    <div class="col-header cell-width" *ngFor="let container of containers" style="display: grid;">
                        <div class="col" style="display: contents; white-space: break-spaces; max-width: 100px; text-align: center;"><b>{{container.ContainerDescription.split('.')[0]}}</b></div>
                        <div class="col" class="bg-data" style="display: contents; white-space: break-spaces; max-width: 100px; text-align: center;"><b>{{container.ContainerCode}}</b></div>
                    </div>
                </div>

                <div class="d-flex white-text">
                    <div class="number-width border-side bg-title">
                        <b>
                            <h4>1.- Disponible inicial</h4>
                        </b>
                    </div>
                    <div class="center-data cell-width border-side bg-title" *ngFor="let container of previousInventory">
                        <b>{{container.quantity | number:'1.0':'en'}}</b>
                    </div>
                </div>

                <div class="d-flex">
                    <div class="number-width border-side bg-data black-text">
                        <h4>2.- Compras</h4>
                    </div>
                    <div class="center-data cell-width border-side blue-text bg-data" *ngFor="let container of purchases">
                        {{container.quantity | number:'1.0':'en'}}
                    </div>
                </div>

                <div class="d-flex">
                    <div class="number-width border-side bg-data black-text">
                        <h4>3.- Bajas por Reciclaje (Plantas/Cedis)</h4>
                    </div>
                    <div class="center-data cell-width border-side blue-text bg-data" *ngFor="let container of recycling">
                        {{container.quantity | number:'1.0':'en'}}
                    </div>
                </div>

                <div class="d-flex">
                    <div class="number-width border-side bg-data black-text">
                        <h4>4.- Otras bajas Plantas y Cedis</h4>
                    </div>
                    <div class="center-data cell-width border-side blue-text bg-data" *ngFor="let container of other">
                        {{container.quantity | number:'1.0':'en'}}
                    </div>
                </div>

                <div class="d-flex">
                    <div class="number-width border-side bg-data black-text">
                        <h4>5.- Recuperaci&oacute;n de Seguridad Plantas y Cedis</h4>
                    </div>
                    <div class="center-data cell-width border-side blue-text bg-data" *ngFor="let container of recovery">
                        {{container.quantity | number:'1.0':'en'}}
                    </div>
                </div>

                <div class="d-flex white-text">
                    <div class="number-width border-side bg-title">
                        <b>
                            <h4>6.- Inv. Te&oacute;rico (Disponible al corte de inventario)</h4>
                        </b>
                    </div>
                    <div class="center-data cell-width border-side bg-title" *ngFor="let container of theoricInventory">
                        <b>{{container.quantity | number:'1.0':'en'}}</b>
                    </div>
                </div>

                <div class="d-flex">
                    <div class="number-width border-side bg-data black-text">
                        <h4>7.- Inventario F&iacute;sico</h4>
                    </div>
                    <div class="center-data cell-width border-side blue-text bg-data border-bottom-gray" *ngFor="let container of physicalInventory">
                        <input class="form-control" type="number"
                               style="text-align: center;"
                               min="0"
                               step="1"
                               onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                               onkeyup=" if(this.value<0){this.value= this.value * -1}"
                               onfocusout="if(this.value===''||this.value===null||this.value===undefined){this.value=0;}"
                               [ngClass]="{'red-text': container.quantity < 0, 'blue-text': container.quantity >= 0}"
                               [(ngModel)]="container.quantity"
                               [disabled]="!phisicalInvActive">

                    </div>
                    <!-- [disabled]="working || factoryCutObject.Status === 'CLOSED' || factoryCutObject.Status === 'PUBLISHED' || !isFabAdmn || !isLastCutDate || !isDateTimeRangeValid" -->
                </div>

                <div class="d-flex">
                    <div class="number-width border-side bg-data black-text">
                        <h4>8.- Tr&aacute;nsito</h4>
                    </div>
                    <div class="center-data cell-width border-side blue-text bg-data border-bottom-gray" *ngFor="let container of transitInventory">
                        <b [ngClass]="{'red-text': container.quantity < 0, 'blue-text': container.quantity >= 0}">{{container.quantity | number:'1.0':'en'}}</b>
                    </div>
                </div>

                <div class="d-flex white-text">
                    <div class="number-width border-side bg-title border-bottom-black">
                        <b>
                            <h4>9.- Inventario Real Final (F&iacute;sico + Tr&aacute;nsito)</h4>
                        </b>
                    </div>
                    <div class="center-data cell-width border-side bg-title black-text border-bottom-black white-text" *ngFor="let container of realInventory; let i = index">
                        <b>{{(physicalInventory[i].quantity + transitInventory[i].quantity) | number:'1.0':'en'}}</b>
                    </div>
                </div>

                <div class="d-flex">
                    <div class="number-width border-side bg-data black-text">
                        <h4>10.- Flujo con Centros de Venta</h4>
                    </div>
                    <div class="center-data cell-width border-side blue-text bg-data border-bottom-gray" *ngFor="let container of ceveRelation">
                        <b [ngClass]="{'red-text': container.quantity < 0, 'blue-text': container.quantity >= 0}">{{container.quantity | number:'1.0':'en'}}</b>
                    </div>
                </div>

                <div class="d-flex">
                    <div class="number-width border-side bg-data black-text">
                        <h4>11.- Flujo con Plantas/Cedis</h4>
                    </div>
                    <div class="center-data cell-width border-side blue-text bg-data border-bottom-gray" *ngFor="let container of factoryRelation">
                        <b [ngClass]="{'red-text': container.quantity < 0, 'blue-text': container.quantity >= 0}">{{container.quantity | number:'1.0':'en'}}</b>
                    </div>
                </div>

                <div class="d-flex">
                    <div class="number-width border-side bg-data black-text">
                        <h4>12.- Flujo Total.</h4>
                    </div>
                    <div class="center-data cell-width border-side blue-text bg-data border-bottom-gray" *ngFor="let container of totalBalance; let i = index">
                        <b [ngClass]="{'red-text': container.quantity < 0, 'blue-text': container.quantity >= 0}">{{container.quantity | number:'1.0':'en'}}</b>
                    </div>
                </div>

                <div class="d-flex white-text">
                    <div class="number-width border-side bg-title border-bottom-black">
                        <b>
                            <h4>13.- Diferencias de inventario (Real final - Te&oacute;rico)</h4>
                        </b>
                    </div>
                    <div class="center-data cell-width border-side bg-total border-bottom-black" *ngFor="let container of realInventory; let i = index">
                        <b [ngClass]="{'red-text': physicalInventory[i].quantity + transitInventory[i].quantity - theoricInventory[i].quantity < 0, 'black-text': physicalInventory[i].quantity + transitInventory[i].quantity - theoricInventory[i].quantity >= 0}">
                            {{(physicalInventory[i].quantity + transitInventory[i].quantity - theoricInventory[i].quantity) | number:'1.0':'en' }}</b>
                    </div>
                </div>

                <div class="d-flex white-text">
                    <div class="number-width border-side bg-title border-bottom-black">
                        <b>
                            <h4>14.- Diferencias Porcentual (14=13 / 6))</h4>
                        </b>
                    </div>
                    <div class="center-data cell-width border-side bg-total black-text border-bottom-black" *ngFor="let container of realInventory; let i = index">
                        <b>{{ getPercentDifference(physicalInventory[i].quantity + transitInventory[i].quantity - theoricInventory[i].quantity, theoricInventory[i].quantity).toFixed(1)}}%</b>
                    </div>
                </div>

            </div>
        </div>
        <br>

    </div>
    <br>
</div>

<div class="row form-group">
    <div class="col-lg-12">

        <button type="button" class="mr-1 btn btn-labeled btn-outline-primary" (click)="saveAdjust()" *ngIf="displaySave" [disabled]="!enableSave">
            <span class="btn-label">
                <i class="fa fa-save"></i>
            </span>
            <span>Guardar compulsa</span>
        </button>

        <button type="button" class="mr-1 btn btn-labeled btn-outline-primary ml-2" (click)="signAdjust()" *ngIf="displaySign" [disabled]="!enableSign">
            <span class="btn-label">
                <i class="fas fa-file-signature"></i>
            </span>
            <span>Firmar compulsa</span>
        </button>

        <button type="button" class="mr-1 btn btn-labeled btn-outline-primary ml-2" (click)="reopenAdjust()" *ngIf="displayReopen" [disabled]="!enableReopen">
            <span class="btn-label">
                <i class="fas fa-undo-alt"></i>
            </span>
            <span>Reabrir compulsa</span>
        </button>

        <button type="button" class="mr-1 btn btn-labeled btn-outline-primary mb-2" (click)="saveAdjustApk()" *ngIf="displaySaveAPK" [disabled]="!enableSaveAPK">
            <span class="btn-label">
                <i class="fa fa-save"></i>
              </span>
              <span>Guardar Conteo APK</span>
        </button>

    </div>
</div>
<div class="content-heading">
  <div class="header-container">
    <h3>Reporte de Car&aacute;tulas de Inventario</h3>
    <small>Reporte Web</small>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row">
            <!--Filtros-->
            <div class="mt-3 col-lg-4 col-md-4">
              <span>Tipo</span>
              <select class="form-control" [(ngModel)]="type" (change)="cmbUnitChange($event.target.value)" required>
                <option [value]="1" [hidden]="userLogin.Org === 2">CEVE</option>
                <option [value]="2">PLANTA</option>
                <!--<option [value]="2">AUTOSERVICIO</option>-->
              </select>


            </div>
            <div class="mt-3 col-lg-4 col-md-4">
              <span>Desde</span>
              <select class="form-control" [(ngModel)]="CalendarDesde" (change)="applyFilters($event.target.value)" required>
                <!--<option [value]="0">TODOS</option>-->

                <option *ngFor="let unit of CutDates" [value]="unit.CalendarId">{{unit.StartAt | date :  "yyyy-MM-dd"}}</option>
              </select>

            </div>
            <div class="mt-3 col-lg-4 col-md-4">
              <span>Hasta</span>
              <select class="form-control" [(ngModel)]="CalendarHasta" (change)="applyFilters($event.target.value)" required>
                <!--<option [value]="0">TODOS</option>-->
                <option *ngFor="let unit of CutDates" [value]="unit.CalendarId">{{unit.StartAt | date :  "yyyy-MM-dd"}}</option>
              </select>
            </div>

          </div>
          <br />

          <br />
          <div class="row">
            <div class="mt-3 col-lg-4 col-md-4">
              <!--Buttons-->
              <button class="mr-1  btn btn-labeled btn-outline-success mb-2" [disabled]="downloading1" (click)="download(1)">
                <span class="btn-label">
                <i class="fa fa-file-excel" aria-hidden="true" *ngIf="!downloading1"></i>
                <i class="fa fa-circle-notch fa-spin" *ngIf="downloading1"></i>
                </span>
                <span>Descargar Reporte Detalle</span>
              </button>
            </div>
            <div class="mt-3 col-lg-4 col-md-4" *ngIf="repDetallado">
              <!--Buttons-->
              <button class="mr-1  btn btn-labeled btn-outline-success mb-2" [disabled]="downloading2" (click)="download(2)">
                <span class="btn-label">
                <i class="fa fa-file-excel" aria-hidden="true" *ngIf="!downloading2"></i>
                <i class="fa fa-circle-notch fa-spin" *ngIf="downloading2"></i>
                </span>
                <span>Descargar Reporte Concentrado</span>
              </button>
            </div>
            <div></div>
            <div class="mt-3 col-lg-4 col-md-4" *ngIf="repOrganizacional">
              <!--Buttons-->
              <button class="mr-1  btn btn-labeled btn-outline-success mb-2" [disabled]="downloading3" (click)="download(3)">
                <span class="btn-label">
                <i class="fa fa-file-excel" aria-hidden="true" *ngIf="!downloading3"></i>
                <i class="fa fa-circle-notch fa-spin" *ngIf="downloading3"></i>
                </span>
                <span>Descargar Reporte Organizacional</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="content-heading">
  <div class="header-container">
    <h3>Reporte de Movimientos</h3>
    <small>Reporte Web</small>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row mt-3 col-lg-12 col-md-12">
            <!--Filtros-->
            <div div class="col-sm-4">
              Fecha inicial
              <input type="date" class="form-control w-80" maxlength="20" [ngModel]="minDate | date:'yyyy-MM-dd'" (ngModelChange)="minDate=$event" required>
            </div>
            <div class="col-sm-4">
              Fecha final
              <input type="date" class="form-control w-80" maxlength="20" [ngModel]="maxDate | date:'yyyy-MM-dd'" (ngModelChange)="maxDate=$event" required>
            </div>
          </div>
          <div class="row mt-3 col-lg-12 col-md-12">
            <div class="col-sm-4">
              <span>Organizaci&oacute;n</span>
              <select class="form-control" [(ngModel)]="orgSelected" name="RegionNameOri" (ngModelChange)="changeFilterMovType2()" required>
                <!--<option [value]="0" selected>Todos</option>-->
                <option *ngFor="let unit of organizacionList" [value]="unit.UnitId">{{unit.UnitDescription}}</option>
              </select>
            </div>
            <div class="col-sm-4">
              <span>Regi&oacute;n</span>
              <select class="form-control" [(ngModel)]="regSelected" name="RegionNameOriReg" (ngModelChange)="changeFilterMovType3()" required>

                <option *ngFor="let unit of regionList" [value]="unit.UnitId">{{unit.UnitDescription}}</option>
              </select>
            </div>
          </div>
          <div class="row mt-3 col-lg-12 col-md-12">
            <div class="col-sm-4">
              <span>Nodo</span>
              <select class="form-control" [(ngModel)]="moveSelected" name="RegionNameOri" (ngModelChange)="changeFilterMovType()" required>
                <option [value]="0" selected>Todos</option>
                <option *ngFor="let unit of moveList" [value]="unit.UnitId">{{unit.UnitDes}}</option>
              </select>
            </div>
            <div class="col-sm-4">
              <span>Tipo Movimiento</span>
              <select class="form-control" [(ngModel)]="moveSelected2" name="RegionNameOri" (ngModelChange)="changeFilterMovType()" required>
                <option [value]="0" selected>Todos</option>
                <option *ngFor="let unit of moveList2" [value]="unit.MovementType">{{unit.Description}}</option>
              </select>
            </div>
          </div>
          <br>
          <div class="row mt-3 col-lg-12 col-md-12">
            <div class="col-sm-4" >
              <button class="mr-1  btn btn-labeled btn-outline-success mb-2" [disabled]="downloading" (click)="download()">
                <span class="btn-label">
                <i class="fa fa-file-excel" aria-hidden="true" *ngIf="!downloading"></i>
                <i class="fa fa-circle-notch fa-spin" *ngIf="downloading"></i>
              </span>
                <span>Descargar reporte</span>
              </button>
            </div>
            <br><br><br>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


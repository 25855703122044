import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/index';
import { environment } from 'src/environments/environment';


@Injectable()
export class GenericService {

  constructor(private httpClient: HttpClient) {
  }

  public getRequest(url: string, params: any, org: number = 0): Observable<any> {
    return this.Execute(HttpRequestType.GET, url, org, params);
  }
  public postRequest(url: string, params: any, org: number, data: any): Observable<any> {
    return this.Execute(HttpRequestType.POST, url, org, params, data);
  }
  public putRequest(url: string, params: any, org: number, data: any): Observable<any> {
    return this.Execute(HttpRequestType.PUT, url, org, params, data);
  }
  public deleteRequest(url: string, params: any, org: number): Observable<any> {
    return this.Execute(HttpRequestType.DELETE, url, org, params);
  }
  public postExcelRequest(url: string, params: any, org: number = 0,data:any): Observable<any> {
    return this.Execute(HttpRequestType.POST_EXCEL, url, org, params,data);
  }

  public getExcelRequest(url: string, params: any, org: number = 0): Observable<any> {
    return this.Execute(HttpRequestType.GET_EXCEL, url, org, params);
  }

  public getPdfRequest(url: string, params: any, org: number = 0): Observable<any> {
    return this.Execute(HttpRequestType.GET_PDF, url, org, params);
  }

  

  /**
   * Arma la url completa , crea los parámetros y  ejecuta la petición http correspondiente
   * @param requestType Tipo de petición que se ejecutara
   * @param url Parte dinámica de la petición
   * @param org Valor de Org que se concatenara al final si es mayor que 0
   * @param params Parámetros que se enviaran como queryString
   * @param data Cuerpo de la petición
   */
  private Execute(requestType: HttpRequestType, url: string, org: number, params: any, data: any = null): Observable<any> {
    //defino la base de la url
    let URL = `${environment.services_url}${environment.service_version}`;
    let APIKEY = `${environment.apiKey}`;

    //concateno la url a la base de la url
    URL += url;
    if (org > 0) {
      URL += '/' + org;
    }
    //creo los parámetros
    let queryParams = new HttpParams({ fromObject: params });
    //Inicializo los encabezados
    let headers = new HttpHeaders();
    //anexo el token

    if (!URL.includes('loginAD'))
    {
     let user = JSON.parse(localStorage.getItem("user"));
     if (user != null && user != undefined) {
       headers = headers.set('Authorization', user.Token);
       headers = headers.set('ApiKey',APIKEY);
                                      
       headers = headers.set('CeqUser', user.Email);
       headers = headers.set('CeqToken', user.Token);
       headers = headers.set('ClientOrigin', 'WEB');
     }


    }


    switch (requestType) {
      case HttpRequestType.GET:
        return this.httpClient.get(URL, { headers: headers, params: queryParams });

      case HttpRequestType.POST:
        return this.httpClient.post(URL, data, { headers: headers, params: queryParams });

      case HttpRequestType.PUT:
        return this.httpClient.put(URL, data, { headers: headers, params: queryParams });

      case HttpRequestType.DELETE:
        return this.httpClient.delete(URL, { headers: headers, params: queryParams });

        case HttpRequestType.GET_EXCEL:
         headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        return this.httpClient.get(URL, { headers: headers, responseType: 'blob' as 'json', params: queryParams });

      case HttpRequestType.POST_EXCEL:
        headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        return this.httpClient.post(URL,data, { headers: headers, responseType: 'blob' as 'json', params: queryParams });

        case HttpRequestType.GET_PDF:
         headers.append('Content-Type', 'application/pdf')
        return this.httpClient.get(URL, { headers: headers,  responseType: 'blob' as 'json', observe: 'response', params: queryParams });

      default:
        break;
    }
  }
}

enum HttpRequestType {
  GET,
  POST,
  PUT,
  DELETE,
  GET_EXCEL,
  GET_PDF,
  POST_EXCEL
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CarrierModel } from '../../common/models/Carrier.Model';
import { GenericService } from '../../common/services/GenericServices';

@Injectable({
  providedIn: 'root'
})
export class CarrierService {

  constructor(private genericService: GenericService) { }

  controllerUrl: string = "carrier";

  getCarrierList(unitId: number): Observable<CarrierModel[]> {
    let url = this.controllerUrl;
    return this.genericService.getRequest(`${url}/unit/${unitId}`, {}, 0);
  }

  getCarrierById(employeeCode: string): Observable<CarrierModel> {
    let url = `${this.controllerUrl}/${employeeCode}`;
    return this.genericService.getRequest(url, {}, 0);
  }

  createCarrier(carrier: CarrierModel): Observable<CarrierModel> {
    let url = this.controllerUrl;
    return this.genericService.postRequest(url, {}, 0, carrier);
  }

  updateCarrier(carrier: CarrierModel): Observable<CarrierModel> {
    let url = this.controllerUrl;
    return this.genericService.putRequest(url, {}, 0, carrier);
  }

  deleteCarrier(employeeCode: string): Observable<any> {
    let url = `${this.controllerUrl}/${employeeCode}`;
    return this.genericService.deleteRequest(url, {}, 0);
  }


}

<div class="content-heading">
  <div class="header-container">
    <h3>Inventario Moldes</h3>
    <small>Consulta de inventario </small>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <div class="row filter-container">
                <div class="col-sm-3">
                  <span>Nodo</span>
                  <ng-autocomplete [initialValue]="initialInputName"
                                   [placeholder]="'Búsqueda'"
                                   [disabled]=""
                                   [data]="outputData"
                                   [searchKeyword]="inputArgument"
                                   (selected)='selectOutputEvent($event)'
                                   [itemTemplate]="itemOutputTemplate"
                                   [notFoundTemplate]="notFoundoutputTemplate"
                                   [debounceTime]="600"
                                   [isLoading]="isLoadingItemsOutput"
                                   [minQueryLength]="3"
                                   [(ngModel)]="selectedUnit"
                                   (inputCleared)="cmbOutputChange()">
                  </ng-autocomplete>
                  <ng-template #itemOutputTemplate let-item>
                    <a [innerHTML]="item.name"></a>
                  </ng-template>

                  <ng-template #notFoundoutputTemplate let-notFound>
                    <div [innerHTML]="'No se encontraron coincidencias'"></div>
                  </ng-template>
                  <BR>
                </div>
                <div class="col-sm-2">
                  Tipo
                  <select class="form-control" name="ManagementId" required [(ngModel)]="containerTypeId" (change)='getManagement()'>
                    <!--cmbParentChange-->
                   
                    <option *ngFor="let man of ContainerTypeList" [value]="man.ContainerTypeId">{{man.ContainerTypeDescription}}</option>
                  </select>
                </div>
                <div class="col-sm-3">
                  Fecha inicial
                  <input (change)="updateTable()" type="date" class="form-control w-80" maxlength="20" [ngModel]="minDate | date:'yyyy-MM-dd'" (ngModelChange)="minDate=$event" required>
                </div>
                <div class="col-sm-3">
                  Fecha final
                  <input (change)="updateTable()" type="date" class="form-control w-80" maxlength="20" [ngModel]="maxDate | date:'yyyy-MM-dd'" (ngModelChange)="maxDate=$event" required>
                </div>
                <div class="col-sm-1" style="align-items:stretch">
                  Actualizar<br />
                  <button type="button" class="btn btn-outline-info" (click)="updateTable()">
                    <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;
                  </button>
                  <!-- <button type="button" class="btn btn-info" (click)="updateTable()">
                    <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;
                  </button> -->
                </div>
              </div>
              <app-ceq-table #CeqGrid
                             [gridAdditionalOptions]="gridOptions"
                             [columnDef]="columnDefs"
                             (apiEmitter)="gridEmiter($event)">
              </app-ceq-table>
              <div class="col-sm-4">
                <br>
                <!-- <button type="button" class="btn btn-outline-primary" (click)="btnClick()" >
                  <i class="fa fa-plus-circle" ></i>&nbsp;
                  Consultar
                  btn btn-outline-primary
                </button> -->
              </div>
            </div>
          </div>
          <div class="row mt-3 col-lg-12 col-md-12">
            <div class="pagesize-control">
              Tama&ntilde;o de p&aacute;gina:
              <select (change)="onPageSizeChange($event.target.value)">
                <option *ngFor="let size of PageSizes" [ngValue]="size">{{size}}</option>
              </select>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>


import { BaseModel } from "./BaseModel.Model";

export class FiscalConfigModel extends BaseModel {

    constructor() {
        super();
        this.Org = 0;
        this.IdFiscalConfig = 0;
        this.FiscalKey = "";
        this.FiscalValue = "";
    }

    Org: number;
    IdFiscalConfig: number;
    FiscalKey: string;
    FiscalValue: string;
}
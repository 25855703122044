import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions, ColumnApi } from 'ag-grid-community';

import { CeqTableComponent } from '../../common/ceq-table/ceq-table.component';
import { AttachmentChildRendered } from '../../common/renders/attachment-child-render';
import { CapitalizeChildRendered } from '../../common/renders/capitalize-child-render';
import { CommentChildRendered } from '../../common/renders/comments-child-render';
import { InvoiceChildRendered } from '../../common/renders/invoice-child-render';
import { AcceptChildRendered } from '../../common/renders/accept-child-render';
import { EditChildRendered } from '../../common/renders/edit-child-rendered';
import { FileChildRendered } from '../../common/renders/file-child-render';
import { ViewChildRendered } from '../../common/renders/view-child-rendered';

import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';

import { GlobalFunctionsService } from 'src/app/ceq/common/functions/global-functions.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { PurchaseService } from '../purchase.service';

import { UserLogin } from '../../common/models/UserLogin.Model';
import { Factory } from '../../common/models/Factory.Model';
import { Unit } from '../../common/models/Unit.Model';
import { ReportsService } from '../../reports/reports.service';
import { Region } from '../../common/models/Region.Model';
import { List } from 'lodash';

enum Status {
  '',
  'Programado',
  'Recibido',
  'Pagado',
  'Revisión Recibo',
  'Revisión Facturación',
  'Pago Atrasado',
  'Recibo Atrasado',
  'Cancelado'
}

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})
export class PlanningComponent implements OnInit {
  @ViewChild('listPlanningGrid', { static: false }) listPlanningGrid: CeqTableComponent;
  @ViewChild('listCapexGrid', { static: false }) listCapexGrid: CeqTableComponent;

  constructor(private purchaseService: PurchaseService,
    private globalFunctionService: GlobalFunctionsService,
    private settings: SettingsService,
    private datepipe: DatePipe,
    private reportService: ReportsService,
    private router: Router) { }

  private gridColumnApi!: ColumnApi;

  showOtherGrid: boolean = false;

  PlanningOption: GridOptions;
  CapexOption: GridOptions;

  org: number;
  userLogin: UserLogin;
  downloading: boolean = false;
  downloading2: boolean = false;

  selectedUnitId: number = 0;
  selectedUnitName: string;

  activities: string[] = []

  // planning
  initialDate: string = "";
  finalDate: string = "";
  minDate: string
  maxDate: string;
  status: StatusDTO[] = [];
  statusSelected: string = "0";
  orderId: string = "";
  containerCode: string = "";
  invoice: string = "";
  purchaseOrder: string = "";
  receiptNumber: string = "";
  regionPurchase: string = "";
  regionReceive: string = "";

  userNodes: any[] = [];

  // Nodo Compra
  purchNodeData: any[] = [];
  selectedPurchUnitName: string = "";
  selectedPurchUnitId: number = 0;
  initialPurchNodeName: string = "";
  isLoadingItemsPurchNode: boolean = false;
  lockOriginSearch: boolean = false;

  // Nodo Recibe
  receiNodeData: any[] = [];
  selectedReceiUnitName: string = "";
  selectedReceiUnitId: number = 0;
  initialReceiNodeName: string = "";
  isLoadingItemsReceiNode: boolean = false;

  selectedRemissionStatus: any;
  remissionStatuss: any;
  lockDestinySearch: boolean = false;

  //Proveedor
  providers: any[] = [];
  selectedProviderName: string = "";
  selectedProviderId: number = 0;
  isLoadingProviders: boolean = false;

  // capex
  capexYear: string = undefined;
  unitDescOrganizatonCapex: string = '';
  unitIdOrganizatonCapex: number = 0;
  percentCapex: string = '';
  quantityPercent: number = 0;

  initialInputName: string = '';
  outputData: any[] = [];
  inputArgument = 'name';
  isLoadingItemsOutput: boolean = false;

  selectedFile: File = null;
  step: number = 0;

  selectedpurchaseFile: File = null;

  typeEvent: string = '';
  isDownloading: boolean = false;

  isAutPurhcase: boolean = false;
  working: boolean = false;

  capexRegions: List<Region> = [];

  columnPlanningDefs = [
    {
      headerName: 'Pedido',
      field: 'orderId',
      width: 90,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Estatus',
      field: 'status',
      width: 90,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true,
      cellRenderer: function (params) {
        return Status[params.data.status];
      }
    },
    {
      headerName: 'Proveedor',
      field: 'providerName',
      width: 100,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Día Entrega',
      field: 'deliverDay',
      width: 90,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: false,
      unSortIcon: false,
      suppressSizeToFit: true
    },
    {
      headerName: 'Fecha Entrega',
      field: 'deliverDate',
      width: 100,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      sort: 'asc',
      suppressSizeToFit: true,
      cellRenderer: (params: any) => moment(params.data.deliverDate).format('DD-MM-YYYY')
    },
    {
      headerName: 'Editar',
      field: 'icon',
      width: 50,
      cellRenderer: 'childEditRenderer',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      colId: 'edit',
      cellClass: 'text-center',
      hide: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Usuario Admin',
      field: 'userAdmin',
      width: 110,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Nodo Compra',
      field: 'unitPurchaseDesc',
      width: 100,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Datos Factura',
      field: 'icon',
      width: 90,
      cellRenderer: 'invoiceChildRendered',
      colId: 'invoice',
      cellClass: 'text-center',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      hide: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Usuario Compra',
      field: 'userPurchase',
      width: 110,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Nodo Recibe',
      field: 'unitReceivesDesc',
      width: 90,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Recibir',
      field: 'icon',
      width: 50,
      cellRenderer: 'acceptChildRendered',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      colId: 'receive',
      cellClass: 'text-center',
      hide: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Usuario Recibe',
      field: 'userReceives',
      width: 110,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Cantidad',
      field: 'quantity',
      width: 90,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red', textAlign: 'right' } } else { return { textAlign: 'right' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true,
      cellRenderer: (params: any) => this.globalFunctionService.separatorFormatter(params.data.quantity)
    },
    {
      headerName: 'Articulo',
      field: 'containerCode',
      width: 90,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Diferencia recibido',
      field: 'differenceReceipt',
      width: 120,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red', textAlign: 'right' } } else { return { textAlign: 'right' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true,
      cellRenderer: (params: any) => this.globalFunctionService.separatorFormatter(params.data.differenceReceipt)
    },
    {
      headerName: 'Certificado por Seguridad',
      field: 'securityCertDesc',
      width: 150,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Certificado por Control',
      field: 'controlCertDesc',
      width: 150,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Lote / Serie',
      field: 'reference',
      width: 150,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Costo Total Equipo',
      field: 'totalEquipmentAmount',
      width: 120,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red', textAlign: 'right' } } else { return { textAlign: 'right' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true,
      cellRenderer: (params: any) => this.globalFunctionService.currencyFormatter(params.data.totalEquipmentAmount)
    },
    {
      headerName: 'Costo Total Flete',
      field: 'totalFreightAmount',
      width: 120,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red', textAlign: 'right' } } else { return { textAlign: 'right' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true,
      cellRenderer: (params: any) => this.globalFunctionService.currencyFormatter(params.data.totalFreightAmount)
    },
    {
      headerName: 'Región compra',
      field: 'regionPurchaseDesc',
      width: 100,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Región recibe',
      field: 'regionReceivesDesc',
      width: 100,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Horario Entrega',
      field: 'deliverySchedule',
      width: 100,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Comentarios',
      field: 'icon',
      width: 90,
      cellRenderer: 'commentChildRendered',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      colId: 'comment',
      cellClass: 'text-center',
      hide: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Capitaliza',
      field: 'icon',
      width: 90,
      cellRenderer: 'capitalizeChildRendered',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      colId: 'capitaliza',
      cellClass: 'text-center',
      hide: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Capitalización',
      field: 'capitalization',
      width: 100,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Usuario Capitaliza',
      field: 'userCapitalization',
      width: 120,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Año Capex',
      field: 'capexYear',
      width: 100,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Capex Exedido',
      field: 'capexExced',
      width: 100,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Factura',
      field: 'invoice',
      width: 100,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Orden Compra',
      field: 'purchaseOrder',
      width: 120,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'No. Recibo',
      field: 'receiptNumber',
      width: 90,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Factura Cancelada',
      field: 'invoiceCancel',
      width: 120,
      cellClass: 'align-start',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Adjuntos',
      field: 'icon',
      width: 70,
      cellRenderer: 'childAttachment',
      cellStyle: params => { if (params.data.capexExced === 'SI') { return { background: '#FDD7E4', color: 'red' } } },
      colId: 'attach',
      cellClass: 'text-center',
      hide: false,
      resizable: true,
      suppressSizeToFit: true
    }
  ];


  columnCapexDefs = [
    {
      headerName: 'Nombre del Capex',
      field: 'capexName',
      width: 150,
      cellClass: 'align-start',
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Usuario Admin',
      field: 'userAdmin',
      width: 180,
      cellClass: 'align-start',
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Nodo',
      field: 'unitDesc',
      width: 150,
      cellClass: 'align-start',
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Región',
      field: 'organization',
      width: 150,
      cellClass: 'align-start',
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Articulo',
      field: 'containerCode',
      width: 150,
      cellClass: 'align-start',
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Valor Capex',
      field: 'originalAmount',
      width: 100,
      cellClass: 'align-start',
      cellStyle: { textAlign: 'right' },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true,
      cellRenderer: (params: any) => this.globalFunctionService.currencyFormatter(params.data.originalAmount)
    },
    {
      headerName: 'Tipo Moneda',
      field: 'currencyCode',
      width: 110,
      cellClass: 'align-start',
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Año a Ejercer',
      field: 'capexYear',
      width: 110,
      cellClass: 'align-start',
      cellStyle: { textAlign: 'right' },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Capex Reservado',
      field: 'reserveAmount',
      width: 120,
      cellClass: 'align-start',
      cellStyle: { textAlign: 'right' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true,
      cellRenderer: (params: any) => this.globalFunctionService.currencyFormatter(params.data.reserveAmount)
    },
    {
      headerName: 'Capex Ejercido',
      field: 'executedAmount',
      width: 100,
      cellClass: 'align-start',
      cellStyle: { textAlign: 'right' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true,
      cellRenderer: (params: any) => this.globalFunctionService.currencyFormatter(params.data.executedAmount)
    },
    {
      headerName: 'Capex Disponible',
      field: 'availableAmount',
      width: 120,
      cellClass: 'align-start',
      cellStyle: { textAlign: 'right' },
      editable: false,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      suppressSizeToFit: true,
      cellRenderer: (params: any) => this.globalFunctionService.currencyFormatter(params.data.availableAmount)
    },
    {
      headerName: '% Disponible',
      field: 'percentageAvailableAmount',
      width: 100,
      cellClass: 'align-start',
      cellStyle: { textAlign: 'right' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true,
      valueFormatter: params => params.data.percentageAvailableAmount.toFixed(2)
    },
    {
      headerName: 'Editar',
      field: 'icon',
      width: 50,
      cellRenderer: 'childEditRenderer',
      colId: 'editCapex',
      cellClass: 'text-center',
      hide: false,
      resizable: true,
      suppressSizeToFit: true
    }
  ];

  gridPlanningOptions = {
    context: this,
    frameworkComponents: {
      childViewRenderer: ViewChildRendered,
      childEditRenderer: EditChildRendered,
      invoiceChildRendered: InvoiceChildRendered,
      acceptChildRendered: AcceptChildRendered,
      commentChildRendered: CommentChildRendered,
      capitalizeChildRendered: CapitalizeChildRendered,
      fileChildRendered: FileChildRendered,
      childAttachment: AttachmentChildRendered
    },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    },
    suppressHorizontalScroll: false,
    onRowDoubleClicked(event: any) { }
  };

  gridCapexOptions = {
    context: this,
    frameworkComponents: {
      childViewRenderer: ViewChildRendered,
      childEditRenderer: EditChildRendered,
      childAttachment: AttachmentChildRendered
    },
    suppressHorizontalScroll: false,
    onCellClicked(event: any) {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = event.data.capexName;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
  
      console.log(event.data.capexName,' copiado.');
    },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    }
  };

  async ngOnInit() {
    this.globalFunctionService.hasFSBRPending();
    this.globalFunctionService.canOperate();
    
    this.org = this.settings.getUser().Org;
    this.userLogin = this.settings.getUser();

    this.initialDate = moment(new Date()).format('yyyy-MM-DD');
    this.finalDate = moment(new Date()).format('yyyy-MM-DD');

    this.status.push({ Id: 0, Description: "Todos" });
    this.status.push({ Id: 1, Description: "Programado" });
    this.status.push({ Id: 2, Description: "Recibido" });
    this.status.push({ Id: 3, Description: "Pagado" });
    this.status.push({ Id: 4, Description: "Revisión Recibo" });
    this.status.push({ Id: 5, Description: "Revisión Facturación" });
    this.status.push({ Id: 6, Description: "Pago Atrasado" });
    this.status.push({ Id: 7, Description: "Recibo Atrasado" });
    this.status.push({ Id: 8, Description: "Cancelado" });

    this.activities = this.settings.getUserAccess();

    if (this.activities.find(x => x.toUpperCase() == 'COMAUT') != undefined) {
      this.isAutPurhcase = true;
    }

    await this.getRegions().then(() => {

      this.getAutocomplteItems();

      this.getAutocompleteItemsCapex();

      this.getProviders();
      //  this.searchPlannign();
      //  this.getCapex();

    });
  }

  gridPlanningEmiter(gridOptions: GridOptions) {
    this.PlanningOption = gridOptions;
    this.gridColumnApi = gridOptions.columnApi;

    // Autoriza Compra EQV (Edicion)
    if (this.activities.find(x => x.toUpperCase() == 'COMAUT') != undefined) {
      this.gridColumnApi.applyColumnState({
        state: [
          { colId: 'edit', hide: false }
        ]
      });
      console.log("hide false. se muestra");
    }
    else {
      this.gridColumnApi.applyColumnState({
        state: [
          { colId: 'edit', hide: true }
        ]
      });
      console.log("hide true. se oculta");
    }

    // Registro Compras EQV (Recibe)
    if (this.activities.find(x => x.toUpperCase() == 'COMEQP') != undefined) {
      this.gridColumnApi.applyColumnState({
        state: [
          { colId: 'receive', hide: false }
        ]
      });
      console.log("hide false. se muestra");
    }
    else {
      this.gridColumnApi.applyColumnState({
        state: [
          { colId: 'receive', hide: true }
        ]
      });
      console.log("hide true. se oculta");
    }

    // Registro Factura EQV (Datos Factura)
    if (this.activities.find(x => x.toUpperCase() == 'COMREGF') != undefined) {
      this.gridColumnApi.applyColumnState({
        state: [
          { colId: 'invoice', hide: false }
        ]
      });
      console.log("hide false. se muestra");
    }
    else {
      this.gridColumnApi.applyColumnState({
        state: [
          { colId: 'invoice', hide: true }
        ]
      });
      console.log("hide true. se oculta");
    }

    // Capitaliza Compras EQV (Capitaliza)
    if (this.activities.find(x => x.toUpperCase() == 'COMCAP') != undefined) {
      this.gridColumnApi.applyColumnState({
        state: [
          { colId: 'capitaliza', hide: false }
        ]
      });
      console.log("hide false. se muestra");
    }
    else {
      this.gridColumnApi.applyColumnState({
        state: [
          { colId: 'capitaliza', hide: true }
        ]
      });
      console.log("hide true. se oculta");
    }
    this.getPlanning();
  }

  gridCapexEmiter(GridOptions: GridOptions) {
    this.CapexOption = GridOptions;
  }

  getPlanning() {
    var paramPlanning = this.urlParamPlannig();
    this.listPlanningGrid.endPoint = `purchaseplanning?${paramPlanning}`;
    this.listPlanningGrid.refreshTable();
  }

  getCapex() {
    var paramCapex = this.urlParamCapex();
    this.listCapexGrid.endPoint = `capex?${paramCapex}`;
    this.listCapexGrid.refreshTable();
  }

  getAutocomplteItems() {
    this.isLoadingItemsPurchNode = true;
    this.isLoadingItemsReceiNode = true;

    this.purchaseService
      .getFactoriesByUser(this.userLogin.Id)
      .toPromise()
      .then((resp: Factory[]) => {
        if (resp.length > 0) {
          this.purchNodeData = resp.map(f => {
            return {
              id: f.UnitId,
              name: `${f.FactoryName}`
            }
          });
          this.receiNodeData = resp.map(f => {
            return {
              id: f.UnitId,
              name: `${f.FactoryName}`
            }
          });
          this.userNodes = resp.map(f => {
            return {
              id: f.UnitId,
              name: `${f.FactoryName}`
            }
          });

          this.isLoadingItemsPurchNode = false;
          this.isLoadingItemsReceiNode = false;
        }
        else {
          this.purchNodeData = [{
            id: undefined,
            name: 'No tiene Plantas asignadas',
          }];
          this.receiNodeData = [{
            id: undefined,
            name: 'No tiene Plantas asignadas',
          }];
          this.isLoadingItemsPurchNode = false;
          this.isLoadingItemsReceiNode = false;
        }
      })
      .catch(err => {
        this.isLoadingItemsPurchNode = false;
        this.isLoadingItemsReceiNode = false;
        console.log(err);
      });
  }

  getAutocompleteItemsCapex() {
    this.isLoadingItemsOutput = true;
    this.purchaseService.getAllFactoryCeve().subscribe((ceveList: Unit[]) => {
      this.selectedUnitName = ceveList.find(x => x.UnitId == this.userLogin.UnitId).UnitDescription;
      this.selectedUnitId = this.userLogin.UnitId;
      this.outputData = ceveList.map(ceve => {
        return {
          id: ceve.UnitId,
          name: `${ceve.UnitDescription}`
        }
      });
      this.isLoadingItemsOutput = false;
    }, err => {
      this.isLoadingItemsOutput = false;
    });
  }

  getProviders() {
    this.isLoadingProviders = true;
    this.purchaseService.getProviders().subscribe((provList: any[]) => {
      this.providers = provList.filter(x => x.providerStatus == 'Activo').map(prov => {
        return {
          id: prov.providerId,
          name: `${prov.providerName}`
        }
      });
      this.isLoadingProviders = false;
    }, err => {
      this.isLoadingProviders = false;
    });
  }




  urlParamPlannig() {
    let paramP = "";

    paramP = "startDate=" + this.initialDate;
    paramP = paramP + "&endDate=" + this.finalDate;

    if (this.statusSelected !== '') {
      paramP = paramP + "&status=" + this.statusSelected;
    }
    if (this.containerCode !== '') {
      paramP = paramP + '&containerCode=' + this.containerCode;
    }

    if (this.orderId !== '') {
      paramP = paramP + '&orderId=' + this.orderId;
    }
    if (this.invoice !== '') {
      paramP = paramP + '&invoice=' + this.invoice;
    }
    if (this.purchaseOrder !== '') {
      paramP = paramP + '&purchaseOrder=' + this.purchaseOrder;
    }
    if (this.receiptNumber !== '') {
      paramP = paramP + '&receiptNumber=' + this.receiptNumber;
    }
    if (this.selectedPurchUnitId !== 0) {
      paramP = paramP + '&unitPurchase=' + this.selectedPurchUnitId;
    }
    if (this.regionPurchase !== '') {
      paramP = paramP + '&regionPurchase=' + this.regionPurchase;
    }
    if (this.selectedReceiUnitId !== 0) {
      paramP = paramP + '&unitReceive=' + this.selectedReceiUnitId;
    }
    if (this.regionReceive !== '') {
      paramP = paramP + '&regionReceive=' + this.regionReceive;
    }

    paramP = paramP + '&providerId=' + this.selectedProviderId;

    return paramP;
  }

  urlParamCapex() {
    let paramC = "";

    paramC = "unitId=" + this.selectedUnitId;

    if (this.capexYear !== '' && this.capexYear !== undefined) {
      paramC = paramC + "&capexYear=" + this.capexYear;
    }
    if (this.unitDescOrganizatonCapex !== '' && this.unitDescOrganizatonCapex !== undefined) {
      paramC = paramC + '&unitDescOrganizatonCapex=' + this.unitDescOrganizatonCapex;
    }
    if (this.percentCapex === 'MAA') {
      paramC = paramC + '&percentCapexGreaterThan=' + this.quantityPercent;
    }
    if (this.percentCapex === 'MEA') {
      paramC = paramC + '&percentCapexSmallerThan=' + this.quantityPercent;
    }
    if (this.percentCapex === 'IGU') {
      paramC = paramC + '&percentCapexEqualTo=' + this.quantityPercent;
    }

    return paramC;
  }

  selectPurchNodeEvent(item: any) {
    if (!this.lockDestinySearch && !this.lockOriginSearch) {
      this.lockDestinySearch = true;
    }

    this.selectedPurchUnitId = item.id

    if (this.lockDestinySearch) {
      this.working = true;
      this.selectedReceiUnitId = 0;
      this.selectedReceiUnitName = "";
      this.purchaseService.getInteractionUnits(item.id).toPromise()
        .then((nodes: Unit[]) => {
          this.working = false;
          this.receiNodeData = nodes.map(f => {
            return {
              id: f.UnitId,
              name: `${f.UnitDescription}`
            }
          });
          this.selectedReceiUnitId = 0;
          this.selectedReceiUnitName = "";
        })
        .catch(err => {
          this.working = false;
          console.log(err);
          this.receiNodeData = [];
          this.getPlanning();
          this.globalFunctionService.messageToast(`El nodo ${item.name} no cuenta con pedidos.`, 'warning');
        });

    }
  }

  selectReceiNodeEvent(item: any) {
    if (!this.lockDestinySearch && !this.lockOriginSearch) {
      this.lockOriginSearch = true;
    }
    this.selectedReceiUnitId = item.id;

    if (this.lockOriginSearch) {
      this.working = true;
      this.selectedPurchUnitId = 0;
      this.selectedPurchUnitName = "";
      this.purchaseService.getInteractionUnits(item.id).toPromise()
        .then((nodes: Unit[]) => {
          this.purchNodeData = nodes.map(f => {
            return {
              id: f.UnitId,
              name: `${f.UnitDescription}`
            }
          });
          this.selectedPurchUnitId = 0;
          this.selectedPurchUnitName = "";
          this.working = false;
        })
        .catch(err => {
          this.working = false;
          console.log(err);
          this.purchNodeData=[];
          this.getPlanning();
          this.globalFunctionService.messageToast(`El nodo ${item.name} no cuenta con pedidos.`, 'warning');
        });

    }
  }

  selectOutputEvent(item: any) {
    this.selectedUnitId = item.id;
  }

  cmbPurchNodeChange() {
    this.selectedPurchUnitName = '';
    this.selectedPurchUnitId = 0;

    if (!this.lockOriginSearch) {
      this.selectedReceiUnitId = 0;
      this.selectedReceiUnitName = "";
    }
    if (this.selectedPurchUnitId == 0 && this.selectedReceiUnitId == 0) {
      this.lockDestinySearch = false;
      this.lockOriginSearch = false;
    }
    if (!this.lockDestinySearch) {
      this.receiNodeData = this.userNodes;
    }
    if (!this.lockOriginSearch) {
      this.purchNodeData = this.userNodes;
    }
  }

  cmbReceiNodeChange() {
    this.selectedReceiUnitId = 0;
    this.selectedReceiUnitName = '';


    if (!this.lockDestinySearch) {
      this.selectedPurchUnitId = 0;
      this.selectedPurchUnitName = "";
    }
    if (this.selectedPurchUnitId == 0 && this.selectedReceiUnitId == 0) {
      this.lockDestinySearch = false;
      this.lockOriginSearch = false;
    }
    if (!this.lockDestinySearch) {
      this.receiNodeData = this.userNodes;
    }
    if (!this.lockOriginSearch) {
      this.purchNodeData = this.userNodes;
    }
  }

  cmbOutputChange() {
    this.selectedUnitId = 0;
    this.initialInputName = '';
  }

  selectProviderEvent(item: any) {
    this.selectedProviderId = item.id;
  }

  cmbProviderCleared() {
    this.selectedProviderId = 0;
    this.selectedProviderName = '';
  }

  finalDateChange() {

  }

  searchPlannign() {
    if (this.initialDate != "" && this.finalDate != "") {
      if (this.initialDate > this.finalDate) {
        Swal.fire({
          icon: 'warning',
          text: 'La fecha final no puede ser menor a la inicial ',
        });
        return;
      }
    }

    this.getPlanning();
  }

  capexSearch() {
    this.getCapex();
  }

  changeTab(showOtherGrid: boolean) {
    this.showOtherGrid = showOtherGrid;
    if (this.showOtherGrid) {
      this.capexSearch();
    }
    else {
      this.searchPlannign();
    }
  }

  edit(item: any): void {
    const orderId = item.data.orderId;
    const providerId = item.data.providerId;
    const capexId = item.data.capexId;

    if (item.colDef.colId === 'edit') {
      this.typeEvent = 'EDIPUR';
      window.open(`/purchase/purchase/${orderId}/${providerId}/${this.typeEvent}`, '_blank');
    } else if (item.colDef.colId === 'editCap') {
      //window.open(`/purchase/purchase/${orderId}/${providerId}/${this.typeEvent}`, '_blank');
    } else if (item.colDef.colId === 'editCapex') {
      window.open(`/purchase/capex-detail/${capexId}`, '_blank');
    }
    if (item.colDef.headerName == "Adjuntos") {
      window.open(`/purchase/attachment/ceq_compras/${item.data.orderId}/Compras/CED`, '_blank');
    }
  }

  invoiceCell(item: any): void {
    const orderId = item.data.orderId;
    const providerId = item.data.providerId;
    const capexId = item.data.capexId;

    if (this.userLogin.UnitId === item.data.unitPurchase) {
      this.typeEvent = 'INVOICE';
      window.open(`/purchase/purchase/${orderId}/${providerId}/${this.typeEvent}`, '_blank');
    } else {
      Swal.fire('¡Aviso!', 'El Nodo principal no es igual al Nodo Compra', 'warning');
    }
  }

  accept(item: any): void {
    let actualDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    const orderId = item.data.orderId;
    const providerId = item.data.providerId;
    const capexId = item.data.capexId;
    if (new Date(this.datepipe.transform(item.data.deliverDate, 'yyyy-MM-dd')) > new Date(actualDate)) {
      Swal.fire('¡Aviso!', 'Esta compra aún no puede recibirse', 'warning');
    }
    else if (this.userLogin.UnitId === item.data.unitReceives) {
      this.typeEvent = 'RECPUR';
      window.open(`/purchase/purchase/${orderId}/${providerId}/${this.typeEvent}`, '_blank');
    } else {
      Swal.fire('¡Aviso!', 'El Nodo principal no es igual al Nodo Recibe', 'warning');
    }
  }

  comment(item: any): void {
    const orderId = item.data.orderId;
    const providerId = item.data.providerId;
    const capexId = item.data.capexId;
    window.open('/equipment-io/eventlog-list/' + item.data.orderId, '_blank');
  }

  capitalize(item: any): void {
    const orderId = item.data.orderId;
    const providerId = item.data.providerId;
    const capexId = item.data.capexId;
    this.typeEvent = 'CAPITALIZA';
    window.open(`/purchase/purchase/${orderId}/${providerId}/${this.typeEvent}`, '_blank');
  }

  files(item: any): void {
    const orderId = item.data.orderId;
    const providerId = item.data.providerId;
    const capexId = item.data.capexId;
    window.open(`/purchase/attachment/ceq_compras/${item.data.orderId}/Compras/CED`, '_blank');
  }



  downloadExcel() {
    this.downloading = true;
    var paramPlanning = this.urlParamPlannig();
    this.purchaseService.getDownloadExcel(paramPlanning).subscribe((resp: any) => {
      if (resp == null) {
        this.globalFunctionService.messageToast(`No se ha encontrado información.`, 'warning');
        return;
      }
      console.log("termino llamada");

      let blob = new Blob([resp], { type: resp.type });

      const anchor = document.createElement('a');

      anchor.download = `Consulta_Planeacion.xlsx`;
      anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
      anchor.click();
      this.downloading = false;
    }, err => {
      this.globalFunctionService.messageToast(`Error en la invocación.`, 'warning');
      this.downloading = false;
      console.log(err);
    });
  }

  async downloadExcelCapex() {
    if (!this.validateDates()) {
      return;
    }

    this.showLoadingAlert();

    var paramCapex = this.urlParamCapex();
    this.purchaseService.searchCapex(paramCapex).subscribe((result: any) => {
      let blob = new Blob([result], { type: result.type });
      const anchor2 = document.createElement('a');
      anchor2.download = `Consulta_Capex.xlsx`;
      anchor2.href = (window.webkitURL || window.URL).createObjectURL(blob);
      anchor2.click();
    },
      (err) => {
        Swal.close();
        if (err.error.Message == undefined || err.error.Message == null) {
          Swal.fire('¡Aviso!', err.error, 'warning');
        }
        else {
          Swal.fire('Aviso!', err.error.Message, 'warning');
        }
      },
      () => {
        Swal.close();
      });
  }

  downloadPurchase() {
    Swal.fire({
      title: 'Descargando archivo',
      html: 'Por favor espere',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
    });
    this.downloading2 = true;
    this.purchaseService.downloadPurchaseExcel().subscribe((result: any) => {
      let blob = new Blob([result], { type: result.type });
      const anchor = document.createElement('a');
      anchor.download = `Plantilla compras.xlsx`;
      anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
      anchor.click();
      this.downloading2 = false;
    },
      (err) => {
        Swal.close();
        Swal.fire('¡Aviso!', err, 'warning');
        this.downloading2 = false;
      },
      () => {
        Swal.close();
      });

  }

  uploadPurchase(files: any) {
    this.selectedpurchaseFile = files[0];

    Swal.fire({
      title: 'Validando archivo',
      html: 'Por favor espere',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
    });

    this.purchaseService.validatePurchaseExcel(this.selectedpurchaseFile).subscribe((valResp) => {
      Swal.close();
      if (valResp.isOptional) {
        Swal.fire({
          title: '¡Aviso!',
          html: valResp.message,
          showDenyButton: true,
          confirmButtonText: 'Si',
          denyButtonText: 'No',
          width: 800,
          allowOutsideClick: false,
        }).then((result) => {

          if (result.isConfirmed) {
            this.savePurchases();
          }
        });
      }
      else {
        this.savePurchases();
      }
    }, (err) => {
      Swal.close();
      console.log(err);
      Swal.fire('¡Aviso!', err.error.Message, 'warning');
    });
  };

  savePurchases() {
    Swal.fire({
      title: 'Guardando ordenes de compra',
      html: 'Por favor espere',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
    });

    this.purchaseService.uploadPurchaseExcel(this.selectedpurchaseFile).subscribe((resp) => {
      Swal.close();
      if (resp.Orders == 1) {
        Swal.fire('', `Se guardo 1 orden de compras`, 'success');
      }
      else {
        Swal.fire('', `Se guardaron ${resp.Orders} ordenes de compras`, 'success');
      }
    }, (err) => {
      Swal.close();
      console.log(err);
      Swal.fire('¡Aviso!', err.error.Message, 'warning')
    }, () => {
    });

  }


  downloadCapex() {
    if (!this.validateDates()) {
      return;
    }

    this.showLoadingAlert();
    this.purchaseService.downloadCapex(this.selectedUnitId).subscribe((result: any) => {
      let blob = new Blob([result], { type: result.type });
      const anchor2 = document.createElement('a');
      anchor2.download = `Capex.xlsx`;
      anchor2.href = (window.webkitURL || window.URL).createObjectURL(blob);
      anchor2.click();
    },
      (err) => {
        Swal.close();
        if (err.error.Message == undefined || err.error.Message == null) {
          Swal.fire('¡Aviso!', err.error, 'warning');
        }
        else {
          Swal.fire('¡Aviso!', err.error.Message, 'warning');
        }
      },
      () => {
        Swal.close();
      });
  }

  validateDates(): boolean {
    if (new Date(this.datepipe.transform(this.initialDate, 'yyyy-MM-dd')) > new Date(this.datepipe.transform(this.finalDate, 'yyyy-MM-dd'))) {
      Swal.fire('¡Aviso!', 'La fecha inicial no puede ser mayor a la fecha final, por favor verifique', 'warning');
      return false;
    }
    return true;
  }

  onFileSelected(data: any) {
    this.selectedFile = data[0];

    Swal.fire({
      title: 'Validando archivo',
      html: 'Por favor espere',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
    });

    this.purchaseService.ValidateCapexExcel(this.selectedFile).subscribe(response => {
      Swal.close();
      if (response.isOptional) {
        Swal.fire({
          title: '¡Aviso!',
          html: response.message,
          showDenyButton: true,
          confirmButtonText: 'Si',
          denyButtonText: 'No',
          width: 800,
          allowOutsideClick: false,
        }).then((result) => {

          if (result.isConfirmed) {
            this.applyChange();
          }
        });
      }
      else {
        this.applyChange();
      }
    }, (err) => {
      Swal.close();
      console.log(err);
      Swal.fire('¡Aviso!', err.error.Message, 'warning');
    });
  }

  async applyChange() {
    Swal.fire({
      title: 'Guardando Capex',
      html: 'Por favor espere',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
    });
    this.step = 2;
    this.purchaseService.applyNewCapex(this.selectedFile).subscribe(data => {
      console.log(data);
      this.clearFile();
      Swal.fire('', 'El Capex se cargó correctamente', 'success')
    }, (err) => {
      console.log(err, 'Error');
      this.clearFile();
      Swal.close();
      if (err.error.Message == undefined || err.error.Message == null) {
        Swal.fire('¡Aviso!', err.error, 'warning');
      }
      else {
        Swal.fire('¡Aviso!', err.error.Message, 'warning');
      }
    });
  }

  clearFile() {
    this.step = 0;
    this.selectedFile = null;
  }

  cmbTypeChange(event: any): void {

  }

  showLoadingAlert() {
    Swal.fire({
      title: 'Descargando archivo',
      html: 'Por favor espere',
      didOpen: () => {
        Swal.showLoading()
      },
    });
  }

  newPurchase() {
    window.open(`/purchase/purchase/NEW/0/EDIPUR`, '_blank');
  }

  async getRegions() {
    await this.reportService.getRegionList(this.userLogin.Org)
      .toPromise()
      .then(resp => {
        this.capexRegions = resp;
      })
      .catch(err => {
        console.error(err);
      })
  }

  /**
   * 
   * @param event 
   */
  uploadClean(event: any): void {
    event.target.value = '';
  }  
}

export class StatusDTO {
  Id: number;
  Description: string;

}

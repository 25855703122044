import { Injectable } from '@angular/core';
import { List } from 'lodash';
import { Observable } from 'rxjs/internal/Observable';
import { EquipmentIO } from '../common/models/EquipmentIO.Model';
import { EquipmentIODetailDTO } from '../common/models/EquipmentIODetailDTO.Model';
import { GenericService } from '../common/services/GenericServices';

@Injectable({
  providedIn: 'root'
})
export class EquipmentIoService {

  constructor(private genericService: GenericService) { }



  SaveEquipmentIO(equipment: EquipmentIO) {
    return this.genericService.postRequest(`EquipmentIO/`, {}, 0, equipment);
  }

  UpdateEquipmentIO(equipment: EquipmentIO) {
    return this.genericService.putRequest(`EquipmentIO/`, {}, 0, equipment);
  }

  UpdateInvoiceEquipmentIO(id: string, invoice : string): Observable<EquipmentIO> {
    return this.genericService.getRequest(`EquipmentIO/invoice/` + id +'/'+invoice, {});
  }

  readData(id: string): Observable<EquipmentIO> {
    return this.genericService.getRequest(`EquipmentIO/`+id, {});
  }



  generateExcel(module: string, parametros: string)  {

    let url = "EquipmentIO/searchReport/" + module + "?"+ parametros;
    return this.genericService.getExcelRequest(url, { }, 0);
  }
  
  getStates(): Observable<any> {
    return this.genericService.getRequest(`ZipCode/states/list` , {});
  }

  getCities(state_code: string): Observable<any> {
    return this.genericService.getRequest(`ZipCode/cities/list/`+ state_code, {});
  }


  CancelEquipmentIO(id: string): Observable<EquipmentIO> {
    return this.genericService.getRequest(`EquipmentIO/Cancel/` + id, {});
  }
  RejectEquipmentIO(id: string): Observable<EquipmentIO> {
    return this.genericService.getRequest(`EquipmentIO/Reject/` + id, {});
  }
  AcceptEquipmentIO(id: string): Observable<EquipmentIO> {
    return this.genericService.getRequest(`EquipmentIO/Accept/` + id, {});
  }

  readDataDetail(id: string): Observable<List<EquipmentIODetailDTO>> {
    return this.genericService.getRequest(`EquipmentIO/Detail/` + id, {});
  }

  postEventLog(id: string, msg : string): Observable<string> {
    return this.genericService.getRequest(`eventlog/Create/` + id+'/'+msg, {});
  }
  
  getEventLogList(id: string): Observable<any[]> {
    return this.genericService.getRequest(`eventlog/list/` + id, {});
  }

}

import { Component, OnInit } from '@angular/core';
import { CalendarService } from '../../configuration/calendar/calendar.service';
import Swal from 'sweetalert2';
import { Calendar } from '../../common/models/Calendar.Model';
import { ReportsService } from "../reports.service";
import { Router } from '@angular/router';
import { SettingsService } from '../../../core/settings/settings.service';
import { AdjustService } from '../../inventory/adjust/adjust.service';
import { UserLogin } from '../../common/models/UserLogin.Model';
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';

@Component({
  selector: 'app-report-cut',
  templateUrl: './report-cut.component.html',
  styleUrls: ['./report-cut.component.scss']
})

export class ReportCutComponent implements OnInit {

  CutDates: Calendar[];  // lista de eventos del calendario
  CalendarDesde: string = "";
  CalendarHasta: string = "";
  type: number = 0;
  c: Calendar[];
  C1: Calendar;
  C2: Calendar;

  typeLevel: string;
  downloading1: boolean = false;
  downloading2: boolean = false;
  downloading3: boolean = false;

  selectedOriginUnit: number;
  repDetallado: boolean = false;
  repOrganizacional: boolean = false;
  userLogin: UserLogin;

  constructor(
    private reportsService: ReportsService, 
    private calendarServ: CalendarService, 
    private router: Router, 
    private settings: SettingsService,
    private globalFunctionService: GlobalFunctionsService,
    private adjustService:AdjustService) { }

  async ngOnInit() {
    this.userLogin = this.settings.getUser();
    this.globalFunctionService.canOperate();
    this.globalFunctionService.hasFSBRPending();

    await this.calendarServ.CalendarInventoryInProgress().toPromise().then((calendar: Calendar[]) => {
      if (calendar.length == 1) {
        this.globalFunctionService.messageToast(`No es posible realizar esta operación, se encuentra en toma de inventario fisico.`, 'warning');
        this.router.navigate([`/home`]);
      }
      else {
        //this.router.navigate([`/management/remission-detail/new`]);
      }

    });

    await this.adjustService.validateAdjustActive(this.settings.getUser().UnitId)
      .toPromise()
      .then(resp => {
        if (resp.AdjustCode !== '') {
          this.globalFunctionService.messageToast(`No es posible realizar esta operación, se encuentra en compulsa.`, 'warning');
          this.router.navigate([`/home`]);
        }
      });


    this.repDetallado = this.settings.getUserAccess().includes('CARRES');
    this.repOrganizacional = this.settings.getUserAccess().includes('CARORG');

    if (this.userLogin.Org == 2) {
      this.type = 2;
      this.typeLevel = 'PLANTA';
    } else {
      this.type = 1;
      this.typeLevel = 'CEVE';
    }
    this.getCalandarEvents();
  }

  applyFilters(event: any) {

  }

  cmbUnitChange(event: any) {


   console.log(event);
    if (event == 2) {
      this.typeLevel = 'PLANTA';
      //this.getNodes(this.typeLevel);
    }
    if (event == 1) {
      this.typeLevel = 'CEVE';
      //this.getNodes(this.typeLevel);
    }
    console.log(this.typeLevel);
  }

  /**
   * retieves all event from database
   *
   * @memberof CalendarComponent
   */
  getCalandarEvents(): void {
    this.calendarServ.getCalendarEvents().subscribe((events: Calendar[]) => {
      if (events != null) {
        events = events.sort((b,a)=>new Date(a.StartAt).getTime() - new Date(b.StartAt).getTime());
        this.CutDates = events;
        //let sizeArray = events.length-1;
        this.c =[];
        let fecha=new Date();

        events.forEach( x=> {

          let f = new Date(x.StartAt);

          if (f.toISOString() > fecha.toISOString())
          {
            console.log ("Mayor "+x);
          }
          else
          {
            this.c.push(x);
          }
        });
        this.CutDates = this.c;
        this.CalendarDesde = this.c[0].CalendarId.toString();
        this.CalendarHasta = this.c[0].CalendarId.toString();
       }
      });

  }

  download(Detalle: number) {

    if (this.CalendarDesde == "") {
      this.globalFunctionService.messageToast(`Debe seleccionar una fecha Desde.`, 'error');
      return;
    }

    if (this.CalendarHasta == "") {
      this.globalFunctionService.messageToast(`Debe seleccionar una fecha Hasta.`, 'error');
      return;
    }


    const  a= this.CutDates.find(x => x.CalendarId == Number(this.CalendarDesde)).StartAt;
    const  b = this.CutDates.find(x => x.CalendarId == Number(this.CalendarHasta)).StartAt;

    //let f1 = new Date(this.C1.StartAt);
    //let f2 = new Date(this.C2.StartAt);
    if (a>  b) {
      this.globalFunctionService.messageToast(`Error La fecha desde no puede ser mayor a la fecha hasta, verifique.`, 'error');
      return;
    }

    if (Detalle==1)
      this.downloading1 = true;
    if (Detalle == 2)
      this.downloading2 = true;
    if (Detalle == 3)
      this.downloading3 = true;


    if (this.selectedOriginUnit === undefined) {
      if (this.typeLevel === 'PLANTA') {
        this.selectedOriginUnit = 2;
      }
      if (this.typeLevel === 'CEVE') {
        this.selectedOriginUnit = 1;
      }
    }
    else {
      if (this.typeLevel === 'PLANTA') {
        this.selectedOriginUnit = 2;
      }
      if (this.typeLevel === 'CEVE') {
        this.selectedOriginUnit = 1;
      }
    }

    this.reportsService.getRepCutExcel(this.selectedOriginUnit, this.CalendarDesde, this.CalendarHasta, Detalle).subscribe((resp: any) => {

      console.log("termino llamada");

      let deta: string = '';

      if (Detalle == 3)
        deta = 'Organizacional';
      if (Detalle == 2)
        deta = 'Resumen';
      if (Detalle == 1)
        deta = 'Detallado';




      let blob = new Blob([resp], { type: resp.type });

      const anchor = document.createElement('a');

      if (this.selectedOriginUnit == 1) {
        anchor.download = "Reporte_Caratula_CEVE_" + deta + ".xlsx";
      } else {
        anchor.download = "Reporte_Caratula_FAB_" + deta + ".xlsx";
      }
      anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
      anchor.click();

      if (Detalle == 1)
        this.downloading1 = false;
      if (Detalle == 2)
        this.downloading2 = false;
      if (Detalle == 3)
        this.downloading3 = false;


    }, err => {
      this.globalFunctionService.messageToast(`Error en la invocación.`, 'warning');
      if (Detalle == 1)
        this.downloading1 = false;
      if (Detalle == 2)
        this.downloading2 = false;
      if (Detalle == 3)
        this.downloading3 = false;
      console.log(err);
    });
  }


}

import { BaseModel } from './BaseModel.Model';
export class Region extends BaseModel {
  UnitId: number;
  ParentId: number;
  RegionName: string;
  RegionCode: string;
  RegionActive: boolean;
  Org: number;
  constructor() {
    super();
    this.RegionActive = true;
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { GenericService } from '../common/services/GenericServices';
import { DatePipe } from '@angular/common'
import { List } from 'lodash';
import { Unit } from '../common/models/Unit.Model';
import { Ceve } from '../common/models/Ceve.Model';
import { Factory } from '../common/models/Factory.Model';
import { Attachment } from '../common/models/Attachment.Model';
import { CurrencyModel } from '../common/models/Currency.Model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  constructor(private genericServ: GenericService, private datepipe: DatePipe) {}

  getAttachmenRecord(table: string,key : string): Observable<any[]> {
    let url = `attachment/record/${table}/${key}`;
    return this.genericServ.getRequest(url, {});
  }

   removeAttachmenRecord(id: string): Observable<any[]> {
    let url = `attachment/${id}`;
     return this.genericServ.deleteRequest(url, {},0);
  }


  viewAttachmenRecord(id: string): Observable<any[]> {
    let url = `attachment/view/${id}`;
    return this.genericServ.getExcelRequest(url, {},0)
  }

  SendFile(data: any, table: string, key: string): Observable<any[]> {
    let url = `attachment/saveFile?table=${table}&key=${key}`;
    return this.genericServ.postRequest(url, {}, 0, data);
  }

  //getPlanningList(startDate: string, endDate: string): Observable<any> {
  //  let url = `purchaseplanning/planning/${startDate}/${endDate}`;
  //  return this.genericServ.getRequest(url, {});
  //}

  //getCapex(unitId: number, capexYear: string, unitDescOrganizatonCapex: string, percentCapexGreaterThan: number, percentCapexSmallerThan: number, percentCapexEqualTo: number): Observable<any> {
  //  let url = `capex/${unitId}/${capexYear}/${unitDescOrganizatonCapex}/${percentCapexGreaterThan}/${percentCapexSmallerThan}/${percentCapexEqualTo}`;
  //  return this.genericServ.getRequest(url, {});
  //}

  ValidateCapexExcel(excel: any): Observable<any> {
    const dataForm = new FormData();
    dataForm.append('file-0', excel);
    return this.genericServ.postRequest('capex/validatenewcapex', {}, 0, dataForm);
  }

  applyNewCapex(excel: any): Observable<any> {
    const dataForm = new FormData();
    dataForm.append('file-0', excel);
    return this.genericServ.postRequest(`capex/apply`, {}, 0, dataForm);
  }

  downloadPurchaseExcel(): Observable<any[]>{
    return this.genericServ.getExcelRequest('purchaseplanning/purchaseformat/excel', {}, 0);
  }

  validatePurchaseExcel(excel: any):Observable<any>{
    const dataForm = new FormData();
    dataForm.append('file-0', excel);
    return this.genericServ.postRequest('purchaseplanning/purchaseformat/validate', {}, 0,dataForm);
  }

  uploadPurchaseExcel(excel: any):Observable<any>{
    const dataForm = new FormData();
    dataForm.append('file-0', excel);
    return this.genericServ.postRequest('purchaseplanning/purchaseformat/save', {}, 0,dataForm);
  }

  downloadCapex(unitId: number): Observable<any[]> {
    let url = `capex/downloadcapex/${unitId}`;
    return this.genericServ.getExcelRequest(url, {}, 0);
  }

  getAllFactoryCeve(): Observable<List<Unit>> {
    return this.genericServ.getRequest('unit/GetAllFactoryCeve', {});
  }

  getuserProfile(org: number, userId: number) {
    return this.genericServ.getRequest(`UserProfile/${org}/${userId}/all`, {});
  }

  searchCapex(params: string): Observable<any[]> {
    let url = `capex/searchCapex?${params}`;
    return this.genericServ.getExcelRequest(url, {}, 0);
  }

  /**
  * Get the ceve leader of the concentrator where unit are , instead return the ceve
  *
  * @param {number} unitId - Unit id to find a ceve
  * @returns {Observable<Ceve>} - Observable of ceve type
  * @memberof RemissionService
  */
  getCeveLeaderData(unitId: number): Observable<Ceve> {
    return this.genericServ.getRequest(`ceve/${unitId}/leader`, {});
  }

  /**
   * Get a factory by unit number
   *
   * @param {number} unitId - Unit id to find a factory
   * @returns {Observable<Factory>} Observable of factory type
   * @memberof RemissionService
   */
  getFactoryData(unitId: number): Observable<Factory> {
    return this.genericServ.getRequest(`factory/${unitId}`, {});
  }

  getCeveListByArgument(argument: string, userId: number): Observable<List<Ceve>> {
    return this.genericServ.getRequest(`ceve/GetByArgument/${argument}/${userId}`, {});
  }

  getFactoryListByArgument(argument: string, userId: number): Observable<List<Factory>> {
    return this.genericServ.getRequest(`factory/GetByArgument/${argument}/${userId}`, {});
  }

  getPurchase(orderId: string, providerId: number, action: string): Observable<string> {
    return this.genericServ.getRequest(`purchaseplanning/getPurchase/${orderId}/${providerId}/${action}`, {});
  }

  savePurchaseEdit(data: any): Observable<any> {
    return this.genericServ.postRequest(`purchase/saveEDITPUR/`, {}, 0, data);
  }

  savePurchaseRecive(data: any): Observable<any> {
    return this.genericServ.postRequest(`purchase/saveRECPUR/`, {}, 0, data);
  }

  //savePurchaseStatus(orderId: string, status: number, message: string, typeEvent: string, data: any): Observable<any> {
  //  return this.genericServ.postRequest(`purchase/savePurchaseStatus/${orderId}/${status}/${message}/${typeEvent}`, {}, 0, data);
  //}

  savePurchaseReceiveDiff(orderId: string, status: number, message: string, data: any): Observable<any> {
    return this.genericServ.postRequest(`purchase/savePurchaseReceiveDiff/${orderId}/${status}/${message}`, {}, 0, data);
  }

  conciliatePurchase(data: any){
    return this.genericServ.postRequest(`purchase/conciliate`, {}, 0, data);
  }

  cancelPurchase(action: string, data: any): Observable<any> {
    return this.genericServ.postRequest(`purchase/cancelPurchase/${action}`, {}, 0, data);

  }

  savePurhcaseInvoice(action: string, message: string, data: any): Observable<any> {
    return this.genericServ.postRequest(`purchase/savePurchaseInvoice/${action}/${message}`, {}, 0, data);
  }

  rebillPurchase(message: string, typeEvent: string, data: any): Observable<any> {
    return this.genericServ.postRequest(`purchase/rebillPurchase/${message}/${typeEvent}`, {}, 0, data);
  }

  cancelOrder(message: string, data: any): Observable<any> {
    return this.genericServ.postRequest(`purchase/cancelOrder/${message}`, {}, 0, data);
  }

  getDownloadExcel(params: string): Observable<any[]> {
    return this.genericServ.getExcelRequest(`purchaseplanning/purchaseplanningexcel?${params}`, {});
  }

  getProviders(): Observable<any[]> {
    return this.genericServ.getRequest(`provider`, {});
  }

  savePurhcaseCapitaliza(action: string, data: any): Observable<any> {
    return this.genericServ.postRequest(`purchase/savePurchaseCapitaliza/${action}`, {}, 0, data);
  }

  getItems(): Observable<any[]> {
    return this.genericServ.getRequest(`item`, {});
  }

  validateAttachment(data: any): Observable<any[]> {
    return this.genericServ.getRequest(`attachment/record/ceq_compras/${data.orderId}`, {});
  }

  getAttachmentList(table: string, key: string): Observable<Attachment[]>{
    return this.genericServ.getRequest(`attachment/record/${table}/${key}`, {});
  }

  getCurrencies(): Observable<any[]> {
    return this.genericServ.getRequest(`currency`, {});
  }

  getCapex(org: number, capexId: number): Observable<any> {
    return this.genericServ.getRequest(`capex/${org}/${capexId}`, {});
  }

  saveCapex(data: any): Observable<any> {
    return this.genericServ.putRequest(`capex`, {}, 0, data);
  }

  getFactoriesByUser(userId: number): Observable<Factory[]> {
    return this.genericServ.getRequest(`factory/getByUser/${userId}`, {});
  }

  getInteractionUnits(unitId: number):Observable<Unit[]>{
    return this.genericServ.getRequest(`unit/purchase/${unitId}`, {});
  }
}

import { Injectable } from '@angular/core';
import { List } from 'lodash';
import { Observable } from 'rxjs';
import { Inventory } from '../common/models/Inventory.Model';
import { Unit } from '../common/models/Unit.Model';
import { Container } from '../common/models/Container.Model';
import { GenericService } from '../common/services/GenericServices';
import { BalanceDetail } from '../common/models/BalanceDetail.Model';
import { Balance } from '../common/models/Balance.Model';
import { MldBalance } from '../common/models/MldBalance.Model';
import { AutomaticTransfer } from '../common/models/AutomaticTransfer';
import { ContainerType } from '../common/models/ContainerType.Model';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(private genericService: GenericService) { }

  getLevelList(org: number): Observable<List<Unit>> {
    return this.genericService.getRequest('unit/' + org + '/permisse', {});
  }
  getLevelListByArgument(org: number, item: string): Observable<List<Unit>> {
    return this.genericService.getRequest('unit/' + org + '/permisse', {});
  }

  getAllFactoryCeve(): Observable<List<Unit>> {
    return this.genericService.getRequest('unit/GetAllFactoryCeve', {});
  }
  AutomaticTransferChageStatus(id: string, ActualStatus: number) {
    let change: number
    if (ActualStatus == 1)
      change = 0;
    else
      change = 1;

    return this.genericService.getRequest(`automatictransfer/changestatus/${id}/${change}`, {});

  }

  addAutomaticTransfer(automaticTransfer: AutomaticTransfer): Observable<any> {
    return this.genericService.postRequest(`automatictransfer`, {}, 0, automaticTransfer);
  }

  getConteinerList(filter: string): Observable<List<Container>> {
    return this.genericService.getRequest(`container?$filter=ContainerId in (${filter})`, {});
  }

  getConteinerTypeList(filter: string): Observable<List<ContainerType>> {
    return this.genericService.getRequest(`containertype`, {});
  }

  getContainersByFactory(unitId: number): Observable<any> {
    return this.genericService.getRequest(`FactoryByContainers?$filter=UnitId eq ${unitId}`, {});
  }

  getContainersByCeve(unitId: number): Observable<any> {
    return this.genericService.getRequest(`CeveByContainer?$filter=UnitId eq ${unitId}`, {});
  }

  getBalanceDetailByNodes(org: number, nodeA: number, nodeB: number, initialDate: string, finalDate: string): Observable<BalanceDetail[]> {
    let query = `balancedetail/detailBetweenNodes/${org}/${nodeA}/${nodeB}/${initialDate}/${finalDate}`;
    return this.genericService.getRequest(query, {});
  }

  getBalanceByNodes(org: number, nodeA: number, nodeB: number, conteinerId: number = 0): Observable<Balance[]> {
    if (conteinerId === 0) {
      return this.genericService.getRequest(`balance/getByFactoryCeve/${org}/${nodeA}/${nodeB}`, {});
    } else {
      return this.genericService.getRequest(`balance?$filter=Org eq ${org} and  UnitIdA eq ${nodeA} and ConteinerId eq ${conteinerId}`, {});
    }
  }


  getBalanceByNodesMld(org: number, nodeA: number, nodeB: number, conteinerId: number = 0): Observable<MldBalance[]> {
    if (conteinerId === 0) {
      return this.genericService.getRequest(`balance/getByFactoryCeveMld/${org}/${nodeA}/${nodeB}`, {});
    } else {
      return this.genericService.getRequest(`balance?$filter=Org eq ${org} and  UnitIdA eq ${nodeA} and ConteinerId eq ${conteinerId}`, {});
    }
  }

  getRangeBalance(nodeA: number, nodeB: number, startDate: string, endDate: String, startTime: string, endTime: string): Observable<Balance[]> {
    return this.genericService.getRequest(`balance/range/${nodeA}/${nodeB}/${startDate}/${endDate}/${startTime}/${endTime}`, {});
  }

  getInventoryByNode(node: number): Observable<Inventory[]> {
    return this.genericService.getRequest("inventory?$filter=OrgUnit eq " + node, {});
  }

  getInventoryByDate(inventoryId: number, startDate: string): Observable<Inventory[]> {
    let query = `inventory/date/${inventoryId}/${startDate}`;
    return this.genericService.getRequest(query, {});
  }

  getBalanceDetailReport(nodeA: number, nodeB: number, initialDate: string, finalDate: string):Observable<any>{
    return this.genericService.getExcelRequest(`balancedetail/excel/${nodeA}/${nodeB}/${initialDate}/${finalDate}`, {});
  }

  getResetBalanceDate(nodeA: number, nodeB: number){
    return this.genericService.getRequest(`balance/resetdate/${nodeA}/${nodeB}`, {});
  }

}

import { StringDecoder } from "string_decoder";
import { BaseModel } from "./BaseModel.Model";

export class CarrierModel extends BaseModel {

    constructor() {
        super();

        this.Org = 0;
        this.UnitId = 0;
        this.LicensePlate = "";
        this.VehicleConfiguration = "";
        this.SubTipoTrailer = "";
        this.LicenseTrailer = "";
        this.ModelYear = "";
        this.SCTPermit = "";
        this.NumberSCTPermit = 0;
        this.Insurance = "";
        this.InsurancePolicy = "";
        this.EmployeeCode = "";
    }

    Org: number;
    UnitId: number;
    LicensePlate: string;
    VehicleConfiguration: string;
    SubTipoTrailer: string;
    LicenseTrailer: string;
    ModelYear: string;
    SCTPermit: string;
    NumberSCTPermit: number;
    Insurance: string;
    InsurancePolicy: string;
    EmployeeCode: string;
}
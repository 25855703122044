<div class="content-heading">
  <div class="header-container">
    <h3>Movimientos ESAB</h3>
    <small>Entradas, salidas, altas y bajas</small>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-default">
        <div class="card-body">
          <!-- FILTROS -->
          <div class="row">
            <div class="col-lg-4">
              <label>Fecha</label>
              Fecha inicial
              <input
                type="date"
                class="form-control w-80"
                maxlength="20"
                [ngModel]="date | date: 'yyyy-MM-dd'"
                (ngModelChange)="date = $event"
                required
              />
            </div>
            <div class="col-lg-5">
              <label>Centro de Ventas</label>
              <ng-autocomplete
                [placeholder]="'Búsqueda'"
                [disabled]=""
                [data]="nodes"
                [searchKeyword]="inputArgument"
                [itemTemplate]="itemOutputTemplate"
                [notFoundTemplate]="notFoundoutputTemplate"
                [debounceTime]="600"
                [minQueryLength]="3"
                [(ngModel)]="selectedUnit"
              >
              </ng-autocomplete>
              <ng-template #itemOutputTemplate let-item>
                <a [innerHTML]="item.UnitDescription"></a>
              </ng-template>
              <ng-template #notFoundoutputTemplate let-notFound>
                <div [innerHTML]="'No se encontraron coincidencias'"></div>
              </ng-template>
            </div>
            <div class="col">
              <label>&nbsp; </label>
              <div>
                <button
                  [disabled]="searching"
                  type="button"
                  class="mr-1  btn btn-labeled btn-outline-primary mb-2"
                  (click)="find()"
                >
                <span class="btn-label">
                  <i class="fa fa-search"></i>
                </span>
                  <span>Buscar</span>
                </button>
              </div>
            </div>
          </div>
          <BR />

          <div class="row">
            <div class="col-lg-12">
              <app-ceq-table
                #CeqGrid
                [gridAdditionalOptions]="gridOptions"
                [columnDef]="columnDefs"
                (apiEmitter)="gridEmiter($event)"
              >
              </app-ceq-table>
            </div>
          </div>
          <BR />
          <button [disabled]="downloading" type="button" class="mr-1  btn btn-labeled btn-outline-primary mb-2" (click)="getExcel()" >
              <span *ngIf="downloading" class="btn-label"><i class="fas fa-circle-notch fa-spin"></i></span>
              <span *ngIf="downloading">Descargando</span>
              <span *ngIf="!downloading" class="btn-label"><i class="fa fa-file-excel"></i></span>
              <span *ngIf="!downloading">Descargar en Excel</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

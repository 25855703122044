import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import Swal from 'sweetalert2';
import { SettingsService } from '../../../../core/settings/settings.service';
import { CeqTableComponent } from '../../../common/ceq-table/ceq-table.component';
import { DeleteChildRendered } from '../../../common/renders/delete-child-rendered';
import { EditChildRendered } from '../../../common/renders/edit-child-rendered';
import { CurrencyService } from '../currency.service';
import * as moment from 'moment';

@Component({
  selector: 'app-currency-list',
  templateUrl: './currency-list.component.html',
  styleUrls: ['./currency-list.component.scss']
})
export class CurrencyListComponent implements OnInit {
  @ViewChild('CeqGrid', { static: false }) ceqGrid: CeqTableComponent;

  options: GridOptions;
  PageSizes = [10, 25, 50, 100, 1000];

  org: number;

  gridOptions = {
    context: this,
    frameworkComponents: {
      childEditRenderer: EditChildRendered,
      childDeleteMessageRenderer: DeleteChildRendered
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) { },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    }
  }

  columnDefs = [
    {
      headerName: 'Código',
      field: 'currencyCode',
      width: 3,
      cellClass: 'align-start',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      editable: false
    },
    {
      headerName: 'Nombre',
      field: 'currencyName',
      width: 50,
      cellClass: 'align-start',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      editable: false
    },
    {
      headerName: 'Última modificación',
      field: 'ModifiedAt',
      width: 20,
      cellClass: 'align-start',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      editable: false,
      cellRenderer: (params: any) => moment(params.data.ModifiedAt).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      headerName: 'Modificado por',
      field: 'ModifiedBy',
      width: 20,
      cellClass: 'align-start',
      resizable: true,
      editable: false
    },
    {
      headerName: 'Editar',
      field: 'icon',
      width: 10,
      cellRenderer: 'childEditRenderer',
      colId: 'params',
      cellClass: 'text-center',
      resizable: true,
      hide: false,
    },
    {
      headerName: 'Eliminar',
      field: 'icon',
      width: 10,
      cellRenderer: 'childDeleteMessageRenderer',
      colId: 'params',
      cellClass: 'text-center',
      resizable: true,
      hide: false,
    }
  ];

  constructor(private currencyService: CurrencyService, private settings: SettingsService, private router: Router) { }

  ngOnInit(): void {
    this.org = this.settings.getUser().Org;
  }

  gridEmiter(GridOptions: GridOptions) {
    this.options = GridOptions;
    this.getCurrency();
  }

  onPageSizeChange(value: number) {
    console.log(value);
  }

  btnAddClick() {
    this.router.navigate(['/tax/currency/create']);
    this.getCurrency();
  }

  edit(row: any) {
    this.router.navigate([`/tax/currency/${row.data.currencyId}`]);
    this.getCurrency();
  }

  delete(row: any) {
    Swal.fire({
      title: 'Confirmacion',
      text: "¿Desea eliminar la moneda " + row.data.currencyCode + "?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> &nbsp; Aceptar',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i> &nbsp; Cancelar',
      backdrop: `rgba(0,157,210,0.5)`
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("row: " + row.data.currencyId);
        this.currencyService.deleteCurrency(row.data.currencyId).subscribe(data => {
          console.log("result", data);
          Swal.fire(
            '¡Éxito!',
            'La configuración se eliminó correctamente.',
            'success'
          );
        }, (error) => {
          console.log("error", error);
          Swal.fire('¡Error!', error.error, 'error');
        }, () => {
          this.getCurrency();
        });
      }
    })
  }

  getCurrency() {
    this.currencyService.getCurencyList().subscribe(data => {
      this.ceqGrid.data = data;
    });
  }
}

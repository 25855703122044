import { BaseModel } from "./BaseModel.Model"

export class VehicleConfigModel extends BaseModel {

    constructor() {
      super();
      this.Org = 0;
      this.VehicleKey = "";
      this.VehicleDescription = "";

      // new fields
      this.VehicleWeightETA = 0;
      this.VehicleWeightB = 0;
      this.VehicleWeightC = 0;
      this.VehicleWeightD = 0;
      this.VehicleTotalDimensions = 0;
    }

    Org: number;
    VehicleKey: string;
    VehicleDescription: string;

    // new fields
    VehicleWeightETA: number;
    VehicleWeightB: number;
    VehicleWeightC: number;
    VehicleWeightD: number;
    VehicleTotalDimensions: number;
}

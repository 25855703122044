import { Injectable } from '@angular/core';
import { GenericService } from '../common/services/GenericServices';
import { Observable } from 'rxjs';
import { Unit } from '../common/models/Unit.Model';
import { Miscellaneous } from '../common/models/Miscellaneous.Model';
import { Ceve } from '../common/models/Ceve.Model';
import { List } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MiscellaneousService {

  constructor(private genericService: GenericService) { }


  GetEquipContainer(cont: string): Observable<any[]> {
    let url = "Container/GetContainer/" + cont;
    return this.genericService.getRequest(url, {});
  }

  getUnit(node: number): Observable<Unit> {
    return this.genericService.getRequest("unit/" + node, {});
  }

  getAttachedFiles(id: string): Observable<any[]>{
    return this.genericService.getRequest("attachment/record/ceq_miscellaneous/" + id, {});
  }

  SaveMiscellaneous(miscellaneousComplete: Miscellaneous){
    return this.genericService.postRequest("miscellaneous", {}, 0 , miscellaneousComplete);
  }

  GetMiscellaneous(miscellaneousId: string){
    return this.genericService.getRequest("miscellaneous/" + miscellaneousId, {});
  }

  GetMiscellaneousList(miscellaneousId: string){
    return this.genericService.getRequest("miscellaneous/list/249690000000000000/2023-04-27/2023-04-30/todos", {});
  }

  GetMiscellaneousStatus(){
    return this.genericService.getRequest("miscellaneous/status", {});
  }

  getCevesByUser(userId: number): Observable<Ceve[]> {
    let url = `ceve/getByUser/${userId}`;
    return this.genericService.getRequest(url, {});
  }

  getLevelListByArgument(org : number): Observable<List<Unit>> {
    return this.genericService.getRequest('unit/'+org+'/permisse', {});
  }
  getLocalTime(){
    return this.genericService.getRequest(`calendar/getCentralTime`,{},0);
  }
  confirmMiscellaneous(miscellaneousId: string){
    return this.genericService.putRequest(`miscellaneous/confirm/${miscellaneousId}`,{},0,{});
  }
  rejectMiscellaneous(miscellaneousId: string){
    return this.genericService.putRequest(`miscellaneous/reject/${miscellaneousId}`,{},0,{});
  }
  cancelMiscellaneous(miscellaneousId: string){
    return this.genericService.putRequest(`miscellaneous/cancel/${miscellaneousId}`,{},0,{});
  }
  UpdateMiscellaneous(miscellaneousComplete: Miscellaneous){
    return this.genericService.putRequest(`miscellaneous/update/`,{},0,miscellaneousComplete);
  }
  getMiscellaneousExcel(nodeId: number, initialDate:string, finalDate:string,statusSelected: string): Observable<any> {
    let url = `miscellaneous/list/${nodeId}/${initialDate}/${finalDate}/${statusSelected}/excel`;
    return this.genericService.getExcelRequest(url, {}, 0);
  }

}

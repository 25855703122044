import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { VehicleconfigService } from '../vehicleconfig.service';
import { VehicleConfigModel } from 'src/app/ceq/common/models/Vehicleconfig.Model';

import Swal from 'sweetalert2';
import { UserLogin } from 'src/app/ceq/common/models/UserLogin.Model';



@Component({
  selector: 'app-vehicleconfig-detail',
  templateUrl: './vehicleconfig-detail.component.html',
  styleUrls: ['./vehicleconfig-detail.component.scss']
})
export class VehicleconfigDetailComponent implements OnInit {

  constructor(private url: ActivatedRoute,
    private router: Router,
    private settings: SettingsService,
    private vehicleConfigService: VehicleconfigService,) {
    this.vehicleconfig = new VehicleConfigModel();
  }

  org: number = 1;
  userLogin: UserLogin;
  vehicleconfig: VehicleConfigModel;
  vehicleconfigKey: string;
  isNew: boolean = false;

  ngOnInit(): void {
    this.userLogin = this.settings.getUser();
    let currentKey = this.url.snapshot.paramMap.get('id');
    this.vehicleconfig.Org = this.userLogin.Org;
    if (this.isNullOrWhitespace(currentKey)) {
      this.isNew = true;
    }
    else {
      this.vehicleconfig.VehicleKey = currentKey
      this.getVehicleConfig();
      this.isNew = false;
    }
  }

  isNullOrWhitespace(input) {
    if (typeof input === 'undefined' || input == null) return true;
    return input.replace(/\s/g, '').length < 1;
  }

  formSubmit(value: any) {
    if (this.isNew) {
      //Create
      this.vehicleConfigService.addVehicleConfig(this.vehicleconfig).subscribe(data => {
        Swal.fire('¡Éxito!', 'Configuración vehicular creada', 'success');
        this.btnCancelOnClick();
      }, (error) => {
        Swal.fire('¡Error!', error.error, 'error');
      });
    }
    else {
      //Update
      this.vehicleConfigService.updateVehicleConfig(this.vehicleconfig).subscribe(data => {
        Swal.fire('¡Éxito!', 'Configuración vehicular actualizado', 'success');
        this.btnCancelOnClick();
      }, (error) => {
        Swal.fire('¡Error!', error.error, 'error');
      });
    }
  }

  btnCancelOnClick() {
    this.router.navigate(['/tax/vehicleconfig']);
  }

  getVehicleConfig() {
    this.vehicleConfigService.getVehicleConfigByKey(this.vehicleconfig.VehicleKey).subscribe(data => {
      this.vehicleconfig = data;
    }, (error) => {
      Swal.fire('¡Error!', "No se encontró el vehículo solicitado", 'error');
      this.btnCancelOnClick();
    });
  }

}

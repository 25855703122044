export enum CeveCutLineType {
    PhysicalInventory = 0,
    Coupons = 1,
    PromissoryNote = 2,
    Others = 3,
    TotalVehicle = 4,
    RtmVehicles = 5,
    RouteCharges = 6,
    StockAllocation = 7,
    VanLoadRejection = 8,
    TotalPhysicalInventory = 9,
    LogisticsBalance = 10,
    ChargeToCollaborator = 11,
    DifferenceNotAttributable = 12,
    SupposedInventory = 13,
    DifferenceAttributable = 14,
    NewBalance = 15,
    SecurityRecovery = 16,
    Losses = 17,
    asDriverVehicles = 18,
    asClients = 19,
    Transit = 20,
    paybillAS = 21,
    TransitLeader = 22,
    PreviousInventory  = 23,
    InputMovements = 24,
    OutputMovements = 25,
    InvAvailableCalculated = 26,
    valeChofer = 27,
    valeColabs = 28,
    compulsas = 29,
    ClosingInventory = 30,
    CurrentComodato = 31,
    ExpiredComodato = 32,
    ExpiredChargedComodato = 33,
    Miscellaneous = 34,
    AdjustCeve = 35,
    TotalDifferences = 36,
  }

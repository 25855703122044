import { BaseModel } from "./BaseModel.Model";

export class InventoryMovement extends BaseModel {
  Org: number;
  MovementId: string;
  MoveDate: string;
  OutputUnit: number;
  InputUnit: number;
  MovementType: string;
  Applied: boolean;
  Reference:string;
  MoveInfo: string;


  constructor() {
    super();
    this.Org = 0;
    this.MovementId = '';
    this.MoveDate = '';
    this.OutputUnit = 0;
    this.InputUnit = 0;
    this.MovementType = '';
    this.Applied = false,
    this.Reference = '';
    this.MoveInfo = '';
  }


}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { toNumber } from 'lodash';
import * as moment from 'moment';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { AdjustService, EventStatus } from '../adjust.service';
import { Unit } from 'src/app/ceq/common/models/Unit.Model';
import { CeqAdjust } from 'src/app/ceq/common/models/AdjustUnit.Model';
import { AdjustLineEnum } from '../../../common/models/adjustLineEnum.Enum';

import { GlobalFunctionsService } from 'src/app/ceq/common/functions/global-functions.service';
import { CalendarService } from 'src/app/ceq/configuration/calendar/calendar.service';
import { InventoryCutService } from '../../inventory-cut/inventory-cut.service';
import { CeveCutStatus } from 'src/app/ceq/common/models/CeveCutStatus.Enum';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { UserLogin } from 'src/app/ceq/common/models/UserLogin.Model';
import { BaseModel } from "../../../common/models/BaseModel.Model";



@Component({
  selector: 'app-adjust-ceve-cut',
  templateUrl: './adjust-ceve-cut.component.html',
  styleUrls: ['./adjust-ceve-cut.component.scss']
})
export class AdjustCeveCutComponent implements OnInit, OnDestroy {

  constructor(
    private globalFunctionService: GlobalFunctionsService,
    private db: AngularFireDatabase,
    private adjustServive: AdjustService,
    private settings: SettingsService,
    private calendarServ: CalendarService,
    private inventoryCutService: InventoryCutService) { }
  //Parent-child 
  @Output("parentCancelAdjust") parentCancelAdjust: EventEmitter<any> = new EventEmitter();

  @Input() selectedNodeCut: Unit;
  @Input() adjustNode: CeqAdjust;

  //subs
  eventsSub: Subscription;
  towersSub: Subscription;
  userInAdjustPage: boolean = false;

  //properties
  user: UserLogin;
  userActivities: string[] = [];
  adjWebRunning: boolean = false;
  adjApkRunning: boolean = false;

  displaySave: boolean = false;
  // displayClose: boolean = false;
  displaySign: boolean = false;
  displayReopen: boolean = false;

  enableSave: boolean = false;
  // enableClose: boolean = false;
  enableSign: boolean = false;
  enableReopen: boolean = false;

  showWebPhisical: boolean = true;
  showApkPhisical: boolean = true;

  displaySaveAPK: boolean = false;
  enableSaveAPK: boolean = false;

  showCountdownBox: boolean = false;
  adjLapTime;
  timer: any;
  currentDateTime: Date = new Date();
  hours: string = "0";
  minutes: string = "0";
  seconds: string = "0";

  countdownMaxTime: string = '2:30:00';
  countdown15Enabled: boolean = false;
  countdown30Enabled: boolean = false;
  countdown45Enabled: boolean = false;
  countdown60Enabled: boolean = false;


  async ngOnInit() {
    this.userInAdjustPage = true;
    this.user = this.settings.getUser();

    this.userActivities = this.settings.getUserAccess();

    //Cut
    await this.InititalizeData()
      .then(async () => {
        this.validateAdjustStatus();

        await this.adjustServive.getAdjustMaxHours().toPromise()
          .then(resp => {
            this.countdownMaxTime = resp.Value;
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        this.showErrorMessage(err);
      });
  }

  ngOnDestroy(): void {
    this.userInAdjustPage = false;
    this.stopTimer();
    this.CloseFirebaseConnection();
  }

  showWebOptions() {
    this.displaySave = true;
    // this.displayClose = true;
    this.displaySign = true;
    this.displayReopen = true;
  }

  hideWebOptions() {
    this.displaySave = false;
    // this.displayClose = false;
    this.displaySign = false;
    this.displayReopen = false;
  }

  disableWebOptions() {
    this.enableSave = false;
    // this.enableClose = false;
    this.enableSign = false;
    this.enableReopen = false;
  }

  async reloadAdjust() {
    await this.adjustServive.getAdjust(this.adjustNode.AdjustCode)
      .toPromise()
      .then(resp => {
        this.adjustNode = resp;
        this.validateAdjustStatus();
      })
      .catch(err => {
        this.showErrorMessage(err);
      });
  }

  validateAdjustStatus() {
    console.log('status', this.adjustNode.Status);
    let counter = this.userActivities.includes('ADJINVWEB');
    let certifier = this.userActivities.includes('ADJCHKCERT');
    let onlyViewAdj = this.userActivities.includes("ADJVIEWWEB");

    this.inventoryForm.disable();

    this.showWebOptions();
    this.disableWebOptions();
    if (this.adjustNode.Status == "CREATED") {
      //si el conteo de equipo en web no esta activo no se muestran las opciones
      if (this.adjWebRunning) {
        //when adjust is started or has ben reopened
        if (counter && !onlyViewAdj) {//Actividad de contador de inventario en web    
          this.inventoryForm.enable();
          this.enableSave = true;
          this.enableSign = true;
        }
      }
      else {
        this.hideWebOptions();
      }
    }
    if (this.adjustNode.Status == "SAVED") {
      this.adjWebRunning = true;
      // this.enableClose = true;

      if (counter && !onlyViewAdj) {//Actividad de contador de inventario en web    
        this.inventoryForm.enable();
        this.enableSign = true;
        this.enableSave = true;
        // this.physicalInventory.disable();
      }
    }
    if (this.adjustNode.Status == "REOPENED") {
      this.adjWebRunning = true;

      if (counter && !onlyViewAdj) {//Actividad de contador de inventario en web    
        this.inventoryForm.enable();
        this.enableSave = true;
        this.enableSign = true;
        // this.physicalInventory.disable();
      }
    }
    if (this.adjustNode.Status == "SIGNED") {
      this.adjWebRunning = true;
      this.enableSign = true;
      this.enableReopen = true;

      if (counter) {
        this.enableSign = false;
        this.enableReopen = false;
      }

      //si ambos usuarios firmaron ya se aplica la compulsa
      if (this.adjustNode.CertifierUser !== '' && this.adjustNode.CertifiedUser !== '') {
        this.applyAdjust();
      }

    }
    if (this.adjustNode.Status == "SIGNEDAPK") {
      if (counter && !onlyViewAdj) {//Actividad de contador de inventario en web    
        if (!this.isSaving) {
          this.applyAdjustAPK();
        }
      }
      else { //Usuario certificador de compulsa 
        this.hideLoading();
        this.CloseFirebaseConnection();
        this.adjWebRunning = false;
        this.adjApkRunning = false;
        this.isSaving = false;
        Swal.fire("Compulsa", "Compulsa firmada correctamente, el Ajuste de inventario se aplicara en el ordenador del usuario contador.", "success")
          .then(() => {
            this.parentCancelAdjust.emit();
          });
      }
    }
    if (this.adjustNode.Status == "CLOSED") {
      this.adjWebRunning = true;
      // this.enableSign = true;
      // this.enableReopen = true;
    }
    if (this.adjustNode.Status == "APPLYED") {
      this.adjWebRunning = true;
    }
    if (this.adjustNode.Status == "APK") {
      if (counter && !onlyViewAdj) {//Actividad de contador de inventario en web    
        this.inventoryForm.enable();
        this.physicalInventory.disable();
        this.displaySaveAPK = true;
        this.enableSaveAPK = true;
      }
      this.hideWebOptions();
      this.adjApk(false);

    }

  }

  async adjWeb() {
    await this.adjustServive.updateEventStatus(this.selectedNodeCut.UnitId, this.adjustNode.AdjustCode, 'COUNTING')
      .toPromise()
      .then(resp => {
        this.adjWebRunning = true;
        this.validateAdjustStatus();
        //change cut status to new
        this.status = CeveCutStatus.NEW;
      })
      .catch(error => {

      });
  }

  cancelAdjweb() {
    Swal.fire({
      text: `¿Desea cancelar el conteo físico?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> &nbsp; Si',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i> &nbsp; No',
      backdrop: `rgba(0,157,210,0.5)`,
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.adjWebRunning = false;
        this.hideWebOptions();
        this.enableSave = false;
        this.inventoryForm.disable();

        await this.adjustServive.cancelApkAdjust(this.adjustNode.AdjustCode)
          .toPromise()
          .then(async resp => {

            this.showLoading('Cargando inventario', 'Por favor espere');
            await this.adjustServive
              .getAdjustInventoryCut(this.selectedNodeCut.UnitId)
              .toPromise()
              .then((x) => {
                this.existInventory(x);
              });
            this.hideLoading();

          })
          .catch(error => {

          });

      }
    });

  }

  async adjApk(reestartCount: boolean = true) {

    await this.adjustServive.validateAvailableConnetions(2)
      .toPromise()
      .then(async (resp) => {
        let adjustCon = {
          Org: this.user.Org,
          AdjustCode: this.adjustNode.AdjustCode,
          ConnChannel: 'Event',
          FirebaseKey: this.user.Token.length > 200 ? this.user.Token.substring(0, 199) : this.user.Token,
          ConnUser: this.user.Email
        };
        let towerCon = {
          Org: this.user.Org,
          AdjustCode: this.adjustNode.AdjustCode,
          ConnChannel: 'Tower',
          FirebaseKey: this.user.Token.length > 200 ? this.user.Token.substring(0, 199) : this.user.Token,
          ConnUser: this.user.Email
        };
        await this.adjustServive.addConnection(adjustCon)
          .toPromise()
          .then(() => {

          })
          .catch(err => {
            this.showErrorMessage(err);
          });
        await this.adjustServive.addConnection(towerCon)
          .toPromise()
          .then(() => {

          })
          .catch(err => {
            this.showErrorMessage(err);
          });


        this.adjustNode.Status = "APK"
        this.OpenFirebaseConnection();

        if (reestartCount) {
          //todo tal vez deban de ir
          // this.initControls();
          // this.setControls();
          // this.subscribeControls();

          await this.adjustServive.startApkAdjust(this.adjustNode.AdjustCode)
            .toPromise()
            .then(resp => {
              this.saveAdjustApk();

              // this.adjApkRunning = true;
              // this.hideWebOptions();
              // if (this.userActivities.includes('ADJINVWEB') && !this.userActivities.includes("ADJVIEWWEB")) {//Actividad de contador de inventario en web    
              //   this.inventoryForm.enable();
              //   this.physicalInventory.disable();
              //   this.displaySaveAPK = true;
              //   this.enableSaveAPK = true;
              // }
            })
            .catch(error => {

            });
        }
        else {
          this.adjApkRunning = true;
          this.hideWebOptions();
          if (this.userActivities.includes('ADJINVWEB') && !this.userActivities.includes("ADJVIEWWEB")) {//Actividad de contador de inventario en web    
            this.inventoryForm.enable();
            this.physicalInventory.disable();
            this.displaySaveAPK = true;
            this.enableSaveAPK = true;
          }
        }

      })
      .catch(err => {
        this.showErrorMessage(err);

        this.adjWebRunning = false;
        this.adjApkRunning = false;
      });
  }

  async cancelAdjapk() {
    Swal.fire({
      text: `¿Desea cancelar el conteo fisico en APK?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> &nbsp; Si',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i> &nbsp; No',
      backdrop: `rgba(0,157,210,0.5)`,
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {

        await this.adjustServive.deleteConnection(this.adjustNode.AdjustCode)
          .toPromise()
          .then(async () => {

            this.CloseFirebaseConnection();
            this.displaySaveAPK = false;
            this.enableSaveAPK = false;
            this.adjApkRunning = false;
            this.adjustNode.Status = "CREATED";

            await this.adjustServive.cancelApkAdjust(this.adjustNode.AdjustCode)
              .toPromise()
              .then(async resp => {

                this.showLoading('Cargando inventario', 'Por favor espere');
                await this.adjustServive
                  .getAdjustInventoryCut(this.selectedNodeCut.UnitId)
                  .toPromise()
                  .then((x) => {
                    this.existInventory(x);
                  });
                this.hideLoading();

              })
              .catch(error => {

              });
          })
          .catch(err => {
            this.showErrorMessage(err);
          });
      }
    });

  }



  async cancelAdjust() {
    Swal.fire({
      text: `¿Desea cancelar la compulsa?, Esto concluira la compulsa activa.`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> &nbsp; Si',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i> &nbsp; No',
      backdrop: `rgba(0,157,210,0.5)`,
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        let comment = await this.requestCancelComment();
        if (comment != 'CANCELADJ' && comment != '') {
          this.adjWebRunning = false;
          this.adjApkRunning = false;

          await this.adjustServive.cancelAdjust(this.adjustNode.AdjustCode, comment).toPromise()
            .then(resp => {

              this.parentCancelAdjust.emit();
            })
            .catch(err => {
              console.log(err);
              Swal.fire('Compulsa', err.error.Message, 'warning');
            });
        }
      }
    });
  }

  async requestCancelComment(): Promise<string> {
    let comment = 'CANCELADJ';
    await Swal.fire({
      input: 'textarea',
      title: 'Comentario de cancelacion',
      inputPlaceholder: 'Debe ingresar un comentario que justifique la cancelacion de la compulsa',
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      showCancelButton: true,
      allowOutsideClick: false
    }).then(async result => {
      if (result.isConfirmed) {
        if (!result.value) {
          Swal.fire('Debe ingresar el comentario de cancelacion.');
        }
        comment = result.value;
      }
    });
    return comment;
  }

  async saveAdjust() {

    this.disableWebOptions();
    this.showLoading('Guardando compulsa', 'Por favor espere.');
    let newInv = this.buildAdjustBody();
    await this.adjustServive.saveAdjust(this.adjustNode.AdjustCode, newInv)
      .toPromise()
      .then(resp => {
        this.hideLoading();
        this.globalFunctionService.messageToast(`Inventario guardado.`, 'success');
        this.reloadAdjust();
      })
      .catch(err => {
        this.hideLoading();
        this.showErrorMessage(err);
        this.enableSave = true;
      });
    //   }
    // });
  }

  async closeAdjust() {
    this.disableWebOptions();
  }

  async signAdjust() {
    this.disableWebOptions();
    this.showLoading('Firmando compulsa', 'por favor espere');
    //guardo antes de firmar 
    if (this.adjustNode.Status == "SIGNED") {
      await this.adjustServive.signAdjust(this.adjustNode.AdjustCode)
        .toPromise()
        .then(result => {
          this.hideLoading();
          this.globalFunctionService.messageToast(`Compulsa firmada correctamente.`, 'success');
          this.reloadAdjust();
        })
        .catch(err => {
          this.showErrorMessage(err);
          this.reloadAdjust();
        });

    }
    else {
      let newInv = this.buildAdjustBody();
      await this.adjustServive.saveAdjust(this.adjustNode.AdjustCode, newInv)
        .toPromise()
        .then(async resp => {
          //firmo despues de guardar
          await this.adjustServive.signAdjust(this.adjustNode.AdjustCode)
            .toPromise()
            .then(result => {
              this.hideLoading();
              this.globalFunctionService.messageToast(`Compulsa firmada correctamente.`, 'success');
              this.reloadAdjust();
            })
            .catch(err => {
              this.showErrorMessage(err);
              this.reloadAdjust();
            });

        })
        .catch(err => {
          this.hideLoading();
          this.showErrorMessage(err);
          this.reloadAdjust();
        });
    }
  }

  async reopenAdjust() {
    this.disableWebOptions();
    await this.adjustServive.reopenAdjust(this.adjustNode.AdjustCode)
      .toPromise()
      .then(result => {
        this.globalFunctionService.messageToast(`Compulsa reabierta.`, 'success');
        this.reloadAdjust();
      })
      .catch(err => {
        this.showErrorMessage(err);
      });
  }

  async saveAdjustApk() {
    this.enableSaveAPK = false;
    this.showLoading('Guardando compulsa', 'Por favor espere.');
    let newInv = this.buildAdjustBody();
    await this.adjustServive.saveAdjustApk(this.adjustNode.AdjustCode, newInv)
      .toPromise()
      .then(resp => {
        this.hideLoading();
        this.globalFunctionService.messageToast(`Inventario guardado.`, 'success');
        this.reloadAdjust();
      })
      .catch(err => {
        this.hideLoading();
        this.showErrorMessage(err);
        this.enableSaveAPK = true;
      });
  }

  async applyAdjustAPK() {
    if (this.isSaving) { return; }
    this.isSaving = true;

    this.showLoading('Aplicando compulsa', 'Por favor espere.');
    let newInv = this.buildAdjustBody();
    await this.adjustServive.applyAdjustAPK(this.adjustNode.AdjustCode, newInv).toPromise()
      .then(() => {
        this.adjustNode.Status = "APPLYED";
        this.adjWebRunning = false;
        this.adjApkRunning = false;
        this.hideLoading();
        this.CloseFirebaseConnection();
        Swal.fire("Compulsa", "Ajuste de inventario realizado con exito", "success")
        this.isSaving = false;
        this.parentCancelAdjust.emit();

      })
      .catch(err => {
        this.isSaving = false;
        console.log(err);
        Swal.fire('Compulsa', err.error.Message, 'warning');
      });

    //   }
    // });
  }

  buildAdjustBody(): any[] {
    let newInv = [];
    for (let i = 0; i < this.containerData.length; i++) {

      let containerId = this.containerData[i].ContainerId;
      let contaienrCode = this.containerData[i].ContainerName;
      //PhysicalInventory
      newInv.push({
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.physicalInventory.controls[i].value === null ? 0 : this.physicalInventory.controls[i].value,
        Line: AdjustLineEnum.PhysicalInventory
      });

      //Coupons
      newInv.push({
        Line: AdjustLineEnum.Coupons,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.couponInventory.controls[i].value === null ? 0 : this.couponInventory.controls[i].value,
      });
      //Promisses
      newInv.push({
        Line: AdjustLineEnum.PromissoryNote,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.promissoryNoteInventory.controls[i].value === null ? 0 : this.promissoryNoteInventory.controls[i].value,
      });
      //TotalVehicles
      newInv.push({
        Line: AdjustLineEnum.TotalVehicle,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.totalInventoryVehicle.controls[i].value === null ? 0 : this.totalInventoryVehicle.controls[i].value,
      });
      //RTM
      newInv.push({
        Line: AdjustLineEnum.RtmVehicles,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.rtmVehicles.controls[i].value === null ? 0 : this.rtmVehicles.controls[i].value,
      });
      //Route
      newInv.push({
        Line: AdjustLineEnum.RouteCharges,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.routeCharges.controls[i].value === null ? 0 : this.routeCharges.controls[i].value,
      });
      //StockAllocation
      newInv.push({
        Line: AdjustLineEnum.StockAllocation,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.stockAllocation.controls[i].value === null ? 0 : this.stockAllocation.controls[i].value,
      });
      //VanLoadRejection
      newInv.push({
        Line: AdjustLineEnum.VanLoadRejection,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.vanLoadRejection.controls[i].value === null ? 0 : this.vanLoadRejection.controls[i].value,
      });
      //TotalPhysicalInventory
      newInv.push({
        Line: AdjustLineEnum.TotalPhysicalInventory,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.totalPhysicalInventory.controls[i].value === null ? 0 : this.totalPhysicalInventory.controls[i].value,
      });
      //LogisticsBalance
      newInv.push({
        Line: AdjustLineEnum.LogisticsBalance,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.logisticsBalance.controls[i].value === null ? 0 : this.logisticsBalance.controls[i].value,
      });
      //ChargeToCollaborator
      newInv.push({
        Line: AdjustLineEnum.ChargeToCollaborator,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.chargedToCollaborator[i] === null ? 0 : this.chargedToCollaborator[i],
      });
      //ValeChofer
      newInv.push({
        Line: AdjustLineEnum.valeChofer,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.valeChofer[i] === null ? 0 : this.valeChofer[i],
      });
      //ValeColabs
      newInv.push({
        Line: AdjustLineEnum.valeColabs,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.valeColabs[i] === null ? 0 : this.valeColabs[i],
      });
      //Compulsas
      newInv.push({
        Line: AdjustLineEnum.compulsas,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.compulsas[i] === null ? 0 : this.compulsas[i],
      });
      //paybillAS
      newInv.push({
        Line: AdjustLineEnum.paybillAS,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.paybillAS[i] === null ? 0 : this.paybillAS[i],
      });
      //diffNotAttributable
      newInv.push({
        Line: AdjustLineEnum.DifferenceNotAttributable,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.diffNotAttributable.controls[i].value === null ? 0 : this.diffNotAttributable.controls[i].value,
      });
      //supposedInventory
      newInv.push({
        Line: AdjustLineEnum.SupposedInventory,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.supposedInventory[i] === null ? 0 : this.supposedInventory[i],
      });
      //diffAttributable
      newInv.push({
        Line: AdjustLineEnum.DifferenceAttributable,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.diffAttributable.controls[i].value === null ? 0 : this.diffAttributable.controls[i].value,
      });
      //SecurityRecovery
      newInv.push({
        Line: AdjustLineEnum.SecurityRecovery,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.securityRecovery[i] === null ? 0 : this.securityRecovery[i],
      });
      //Losses
      newInv.push({
        Line: AdjustLineEnum.Losses,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.losses[i] === null ? 0 : this.losses[i],
      });
      //AsDriverVehicles
      newInv.push({
        Line: AdjustLineEnum.asDriverVehicles,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.asDriverVehicles[i] === null ? 0 : this.asDriverVehicles[i],
      });
      //AsClient
      newInv.push({
        Line: AdjustLineEnum.asClients,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.asClients[i] === null ? 0 : this.asClients[i],
      });
      //Transit
      newInv.push({
        Line: AdjustLineEnum.Transit,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.transit[i] === null ? 0 : this.transit[i],
      });
      //TransitLeader
      newInv.push({
        Line: AdjustLineEnum.TransitLeader,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.transitLeader[i] === null ? 0 : this.transitLeader[i],
      });
      //PreviousInventory
      newInv.push({
        Line: AdjustLineEnum.PreviousInventory,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.previousInventory[i] === null ? 0 : this.previousInventory[i],
      });
      //InputMovements
      newInv.push({
        Line: AdjustLineEnum.InputMovements,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.inputMovements[i] === null ? 0 : this.inputMovements[i],
      });
      //OutputMovements
      newInv.push({
        Line: AdjustLineEnum.OutputMovements,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.outputMovements[i] === null ? 0 : this.outputMovements[i],
      });
      //InvAvailableCalculated
      newInv.push({
        Line: AdjustLineEnum.InvAvailableCalculated,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.invAvailableCalculated[i] === null ? 0 : this.invAvailableCalculated[i],
      });
      //ClosingInventory
      newInv.push({
        Line: AdjustLineEnum.ClosingInventory,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.closingInventory[i] === null ? 0 : this.closingInventory[i],
      });
      //CurrentComodato
      newInv.push({
        Line: AdjustLineEnum.CurrentComodato,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.currentComodato[i] === null ? 0 : this.currentComodato[i],
      });
      //ExpiredComodato
      newInv.push({
        Line: AdjustLineEnum.ExpiredComodato,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.expiredComodato[i] === null ? 0 : this.expiredComodato[i],
      });
      //ExpiredChargedComodato
      newInv.push({
        Line: AdjustLineEnum.ExpiredChargedComodato,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.expiredChargedComodato[i] === null ? 0 : this.expiredChargedComodato[i],
      });

    }

    return newInv;
  }

  async applyAdjust() {
    this.showLoading('Aplicando compulsa', 'Por favor espere.');
    await this.adjustServive.applyAdjust(this.adjustNode.AdjustCode).toPromise()
      .then(() => {
        this.hideLoading();
        Swal.fire("Compulsa", "Ajuste de inventario realizado con exito", "success");
        this.parentCancelAdjust.emit();
      })
      .catch(err => {
        console.log(err);
        Swal.fire('Compulsa', err.error.Message, 'warning');
      });
  }

  async lapseAdjust() {
    this.showLoading('Caducando compulsa', 'Se agoto el tiempo de compulsa, pasara a estatus caducada');

    this.adjWebRunning = false;
    this.adjApkRunning = false;

    await this.adjustServive.lapseAdjust(this.adjustNode.AdjustCode).toPromise()
      .then(resp => {
        this.CloseFirebaseConnection();
        this.adjWebRunning = false;
        this.adjApkRunning = false;
        this.hideLoading();
        this.parentCancelAdjust.emit();
      })
      .catch(err => {
        console.log(err);
        this.hideLoading();
        Swal.fire('Compulsa', err.error.Message, 'warning');
      });



    this.hideLoading();
  }


  showLoading(title: string, message: string) {
    Swal.fire({
      title: title,
      html: message,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
    });
  }

  hideLoading() {
    Swal.close();
  }

  startTimer() {

    let canIncreaseTime = this.userActivities.includes('ADJCNTINCR');

    this.timer = setInterval(() => {

      //Obtengo el dia actual
      this.currentDateTime = new Date();

      //obtengo el tiempo limite para la compulsa
      let timeArr = this.adjustNode.AdjustCountdown.split(':');
      //sumo el tiempo limite al tempo de creacion de la compulsa 
      this.adjLapTime = moment(this.adjustNode.CreatedAt).add(timeArr[0], 'hours').add(timeArr[1], 'minutes').add(timeArr[2], 'seconds');


      //si el tiempo limite de la compulsa es menor al actual caduco la compulsa
      let difSeconds = this.adjLapTime.diff(moment(this.currentDateTime), 'milliseconds');

      if (difSeconds < 0) {
        console.log('Caduco la compulsa');
        //detengo el timer
        this.stopTimer();
        //Caduco la compulsa
        this.lapseAdjust();
        return;
      }
      let hrs = difSeconds / 1000 / 60 / 60;
      if (hrs < 0) {
        this.hours = '0';
        this.minutes = '0';
        this.seconds = '0';
        return;
      }
      let mins = hrs < 1 ? (hrs + 1 % Math.floor(hrs + 1)) * 60 : (hrs % Math.floor(hrs)) * 60;
      let secs = mins < 1 ? (mins + 1 % Math.floor(mins + 1)) * 60 : (mins % Math.floor(mins)) * 60;

      this.hours = isNaN(hrs) ? '0' : Math.floor(hrs).toString();
      this.minutes = isNaN(mins) ? '0' : Math.floor(mins).toString();
      this.seconds = isNaN(secs) ? '0' : Math.floor(secs).toString();

      if (secs < 2) {
        //Cada minuto valido si el tiempo maximo de la compulsa se actualizo
        this.adjustServive.getAdjustCountdown(this.adjustNode.AdjustCode).subscribe(resp => {
          console.log(resp.Message);
          this.adjustNode.AdjustCountdown = resp.Message;
        }, error => {
          console.log(error);
        });
      }

      this.showCountdownBox = false;
      if (hrs < 1 && mins < 15 && canIncreaseTime) {
        //muestro el mensaje para aumentar el tiempo si hay una opcion disponible para aumentar
        if (this.countdown15Enabled ||
          this.countdown30Enabled ||
          this.countdown45Enabled ||
          this.countdown60Enabled) {
          this.showCountdownBox = true;
        }
      }

    }, 1000);
  }

  stopTimer() {
    clearInterval(this.timer);
  }

  evaluateMAxCountDown() {

    let headerCountDown = this.adjustNode.AdjustCountdown.split(':');
    let hours = toNumber(headerCountDown[0]);
    let mins = toNumber(headerCountDown[1]);

    console.log('countdownMaxTime', this.countdownMaxTime);
    let countdownTime = this.countdownMaxTime.split(':');
    let maxCountDownHours = toNumber(countdownTime[0]);
    let maxCountDownminutes = toNumber(countdownTime[1]);

    this.countdown15Enabled = true;
    this.countdown30Enabled = true;
    this.countdown45Enabled = true;
    this.countdown60Enabled = true;

    if (hours >= maxCountDownHours) {
      this.countdown60Enabled = false;

      if (mins + 15 > maxCountDownminutes) {
        this.countdown15Enabled = false;
      }
      if (mins + 30 > maxCountDownminutes) {
        this.countdown30Enabled = false;
      }
      if (mins + 45 > maxCountDownminutes) {
        this.countdown45Enabled = false;
      }
    }
  }

  async increaseEventCountDown(minutes: number) {
    this.globalFunctionService.messageToast(`Incrementando tiempo.`, 'info');
    // this.showCountdownBox = false;

    let arr = this.adjustNode.AdjustCountdown.split(':');

    if (minutes == 60) {
      arr[0] = (toNumber(arr[0]) + 1).toString();
    }
    else {
      arr[1] = (toNumber(arr[1]) + minutes).toString();
    }

    let countDown = arr.join(':');
    //actualizo tiempo en db
    await this.adjustServive.increaseEventCountdown(this.adjustNode.AdjustCode, countDown)
      .toPromise()
      .then(() => {
        //Compulsa local
        this.adjustNode.AdjustCountdown = countDown;
        let timeArr = countDown.split(':');
        this.adjLapTime = moment(this.adjustNode.CreatedAt).add(timeArr[0], 'hours').add(timeArr[1], 'minutes').add(timeArr[2], 'seconds');
        this.evaluateMAxCountDown();
        this.globalFunctionService.messageToast(`Tiempo incrementado.`, 'success');
      })
      .catch(err => {
        this.showErrorMessage(err);
      });


  }



  //#region firebase

  // firebaseMessages: AngularFireList<FirebaseMessage>;
  // messages: FirebaseMessage[] = [];


  firebaseEvents: AngularFireList<FirebaseEvent>;
  firebaseTowers: AngularFireList<FirebaseTowers>;
  // firebaseSeller: AngularFireList<FirebaseSeller>;

  updateAdjustConnetions() {
    let adjustCon = {
      Org: this.user.Org,
      AdjustCode: this.adjustNode.AdjustCode,
      ConnChannel: 'Event',
      FirebaseKey: this.user.Token.length > 200 ? this.user.Token.substring(0, 199) : this.user.Token,
      ConnUser: this.user.Email
    };
    this.adjustServive.updateConnection(adjustCon)
      .toPromise()
      .then(() => {

      })
      .catch((err) => {
        this.showErrorMessage(err);
      })

    let towerCon = {
      Org: this.user.Org,
      AdjustCode: this.adjustNode.AdjustCode,
      ConnChannel: 'Tower',
      FirebaseKey: this.user.Token.length > 200 ? this.user.Token.substring(0, 199) : this.user.Token,
      ConnUser: this.user.Email
    };
    this.adjustServive.updateConnection(towerCon)
      .toPromise()
      .then(() => {

      })
      .catch((err) => {
        this.showErrorMessage(err);
      })


  }

  OpenFirebaseConnection() {
    //Configure firebase RTD objects to be listened
    // this.firebaseMessages = this.db.list('Messages');

    // console.log("connection opened");
    // console.log(`${this.selectedNodeCut.UnitId}/${this.adjustNode.AdjustCode}/Events`);

    this.db.database.goOnline();
    //Start firebase listeners
    this.firebaseEvents = this.db.list(`${this.selectedNodeCut.UnitId}/${this.adjustNode.AdjustCode}/Events`);
    this.firebaseTowers = this.db.list(`${this.selectedNodeCut.UnitId}/${this.adjustNode.AdjustCode}/Towers`);
    // this.firebaseSeller = this.db.list(`${this.selectedNodeCut.UnitId}/${this.adjustNode.AdjustCode}/Sellers`);


    //Implement firebase sate change listener
    this.dataState();
  }

  CloseFirebaseConnection() {
    if (this.db != undefined) {
      if (this.db.database != undefined) {
        this.db.database.goOffline();
      }
    }

    if (this.eventsSub != undefined) {
      this.eventsSub.unsubscribe();
      this.eventsSub.remove(this.eventsSub);
    }
    if (this.towersSub != undefined) {
      this.towersSub.unsubscribe();
      this.towersSub.remove(this.towersSub);
    }
    this.firebaseEvents = null;
    this.firebaseTowers = null;
  }


  dataState() {

    this.eventsSub = this.firebaseEvents.stateChanges().subscribe(async event => {
      if (!this.userInAdjustPage) { return; }
      // console.log("firebase event", event);
      // console.log("firebase event", event.payload.val());

      //'child_added' | 'child_removed' | 'child_changed' | 'child_moved';
      if (event.type == 'child_removed') {
        this.CloseFirebaseConnection();
        return;
      }

      this.adjustNode.AdjustCountdown = event.payload.val().Countdow;
      let timeArr = this.adjustNode.AdjustCountdown.split(':');
      this.adjLapTime = moment(this.adjustNode.CreatedAt).add(timeArr[0], 'hours').add(timeArr[1], 'minutes').add(timeArr[2], 'seconds');

      if (event.payload.val().Status == "SIGNEDAPK") {
        this.adjustNode.Status = 'SIGNEDAPK';
        this.validateAdjustStatus();
      }
      else {
        this.evaluateMAxCountDown();

        //Actualizo coneccion para indicar que esta activa
        this.updateAdjustConnetions();
      }
    });

    this.towersSub = this.firebaseTowers.stateChanges().subscribe(async event => {
      if (!this.userInAdjustPage) { return; }
      if (this.gettingDetail || !this.adjApkRunning) { return; }

      this.globalFunctionService.messageToast(`Actualizando inventario.`, 'info');

      await this.getAdjustDetail();

      this.globalFunctionService.messageToast(`Inventario actualizado.`, 'info');

      //Actualizo coneccion para indicar que esta activa
      this.updateAdjustConnetions();

    });

    // this.firebaseSeller.stateChanges().subscribe(async event => {
    //   // console.log('Seller state changes data', event);
    //   // console.log('Seller state changes json', event.payload.toJSON());
    // });
  }

  gettingDetail: boolean = false;
  async getAdjustDetail() {
    this.gettingDetail = true;
    await this.adjustServive.getAdjustDetailGrouped(this.adjustNode.AdjustCode)
      .toPromise()
      .then(detail => {

        if (this.adjApkRunning) {
          let lineDetail = detail.filter(x => x.Line == 0);
          for (let i = 0; i < this.containerData.length; i++) {
            let containerCode = this.containerData[i].ContainerName;
            let detailFiltered = lineDetail.filter(x => x.ContainerCode == containerCode);
            let val = 0;
            detailFiltered.map(d => val += d.Quantity);
            this.physicalInventory.controls[i].setValue(val);
          }
        }
        else {
          this.setAdjustDetailLines(detail);
        }
        this.gettingDetail = false;
      })
      .catch(err => {
        this.showErrorMessage(err);
      });

  }

  showErrorMessage(err) {
    if (err?.error?.Message !== '' && err?.error?.Message !== undefined) {
      Swal.fire('Compulsa', err.error.Message, 'warning');
    }
    else if (err?.message !== '' && err?.message !== undefined) {
      Swal.fire('Compulsa', err.message, 'warning');
    }
    else {
      Swal.fire('Compulsa', err, 'warning');
    }
  }
  //#endregion


  //#region Cut Load


  ceveCutId: string;
  ceveCutDate: string;

  chargedIsCollapsed: boolean = false;
  asSum: any[] = [];
  isLoading: boolean;
  hasSelected: boolean;
  signCompleted: boolean;
  status: CeveCutStatus;
  isCeveSupervisor: boolean;
  isCeveAdmn: boolean;
  isNodeChild: boolean = false;
  isNodeLeader: boolean = false;
  modifiedAt: string;
  isDispatcherReciverSign: boolean;
  isManagerSign: boolean;
  isSupervisorSign: boolean;
  isSaving: boolean;
  disableButton: boolean;
  //signsActivities
  signActDispatcherReceiver: boolean = false;
  signActAdministrator: boolean = false;
  signActSuppervisorDisp: boolean = false;
  datepipe: DatePipe = new DatePipe('en-US');
  persistentCreatedAt: Date = undefined;
  persistentCreatedBy: string = '';



  signs: {
    dispatcherReciverSign: string;
    managerSignature: string;
    supervisorSignature: string;
    dispatcherReciverComment: string;
    managerComment: string;
    supervisorComment: string;
  };

  //Product type titles
  containerData: Array<{
    ContainerId: string;
    ContainerDescription: string;
    ContainerName: string;
    ContainerCode: string;
  }> = [];

  //set arrays at one form
  inventoryForm = new FormGroup({
    physicalInventory: new FormArray([]),
    physicalInventoryfromLapsed: new FormArray([]),
    couponInventory: new FormArray([]),
    promissoryNoteInventory: new FormArray([]),
    totalInventoryVehicle: new FormArray([]),
    rtmVehicles: new FormArray([]),
    routeCharges: new FormArray([]),
    stockAllocation: new FormArray([]),
    vanLoadRejection: new FormArray([]),
    totalPhysicalInventory: new FormArray([]),
    logisticsBalance: new FormArray([]),
    diffNotAttributable: new FormArray([]),
    diffAttributable: new FormArray([]),
  });

  inventoryArray = [
    { name: 'asVehicle', value: [] },
    { name: 'transit', value: [] },
    { name: 'losses', value: [] },
    { name: 'securityRecovery', value: [] },
    { name: 'chargedToCollaborator', value: [] },
    { name: 'valeChofer', value: [] },
    { name: 'valeColabs', value: [] },
    { name: 'compulsas', value: [] },
    { name: 'paybillAS', value: [] },
    { name: 'supposedInventory', value: [] },
    { name: 'previousInventory', value: [] },
    { name: 'inputMovements', value: [] },
    { name: 'outputMovements', value: [] },
    { name: 'invAvailableCalculated', value: [] },
    { name: 'transitLeader', value: [] },
    { name: 'closingInventory', value: [] },
    { name: 'currentComodato', value: [] },
    { name: 'expiredComodato', value: [] },
    { name: 'expiredChargedComodato', value: [] },
  ];

  //#region Access to formarray
  //Acces to each formarray without use the form
  get physicalInventory(): FormArray {
    return this.inventoryForm.get('physicalInventory') as FormArray;
  }
  get physicalInventoryfromLapsed(): FormArray {
    return this.inventoryForm.get('physicalInventoryfromLapsed') as FormArray;
  }
  get couponInventory(): FormArray {
    return this.inventoryForm.get('couponInventory') as FormArray;
  }
  get promissoryNoteInventory(): FormArray {
    return this.inventoryForm.get('promissoryNoteInventory') as FormArray;
  }
  get totalInventoryVehicle(): FormArray {
    return this.inventoryForm.get('totalInventoryVehicle') as FormArray;
  }
  get rtmVehicles(): FormArray {
    return this.inventoryForm.get('rtmVehicles') as FormArray;
  }
  get routeCharges(): FormArray {
    return this.inventoryForm.get('routeCharges') as FormArray;
  }
  get stockAllocation(): FormArray {
    return this.inventoryForm.get('stockAllocation') as FormArray;
  }
  get vanLoadRejection(): FormArray {
    return this.inventoryForm.get('vanLoadRejection') as FormArray;
  }
  get totalPhysicalInventory(): FormArray {
    return this.inventoryForm.get('totalPhysicalInventory') as FormArray;
  }
  get logisticsBalance(): FormArray {
    return this.inventoryForm.get('logisticsBalance') as FormArray;
  }
  get diffNotAttributable(): FormArray {
    return this.inventoryForm.get('diffNotAttributable') as FormArray;
  }
  get diffAttributable(): FormArray {
    return this.inventoryForm.get('diffAttributable') as FormArray;
  }
  get chargedToCollaborator(): any[] {
    return this.inventoryArray.find((x) => x.name == 'chargedToCollaborator')
      .value;
  }
  get valeChofer(): any[] {
    return this.inventoryArray.find((x) => x.name == 'valeChofer').value;
  }
  get valeColabs(): any[] {
    return this.inventoryArray.find((x) => x.name == 'valeColabs').value;
  }
  get compulsas(): any[] {
    return this.inventoryArray.find((x) => x.name == 'compulsas').value;
  }
  get paybillAS(): any[] {
    return this.inventoryArray.find((x) => x.name == 'paybillAS').value;
  }
  get supposedInventory(): any[] {
    return this.inventoryArray.find((x) => x.name == 'supposedInventory').value;
  }
  get previousInventory(): any[] {
    return this.inventoryArray.find((x) => x.name == 'previousInventory').value;
  }
  get inputMovements(): any[] {
    return this.inventoryArray.find((x) => x.name == 'inputMovements').value;
  }
  get outputMovements(): any[] {
    return this.inventoryArray.find((x) => x.name == 'outputMovements').value;
  }
  get invAvailableCalculated(): any[] {
    return this.inventoryArray.find((x) => x.name == 'invAvailableCalculated')
      .value;
  }
  get asDriverVehicles(): any[] {
    if (this.inventoryArray.some(x => x.name == 'asDriverVehicle')) {
      return this.inventoryArray.find((x) => x.name == 'asDriverVehicle').value;
    }
    else {
      return [];
    }
  }
  get asClients(): any[] {
    if (this.inventoryArray.some(x => x.name == 'asClient')) {
      return this.inventoryArray.find((x) => x.name == 'asClient').value;
    }
    else {
      return [];
    }
  }
  get transit(): any[] {
    return this.inventoryArray.find((x) => x.name == 'transit').value;
  }
  get transitLeader(): any[] {
    return this.inventoryArray.find((x) => x.name == 'transitLeader').value;
  }
  get losses(): any[] {
    return this.inventoryArray.find((x) => x.name == 'losses').value;
  }
  get securityRecovery(): any[] {
    return this.inventoryArray.find((x) => x.name == 'securityRecovery').value;
  }
  get closingInventory(): any[] {
    return this.inventoryArray.find((x) => x.name == 'closingInventory').value;
  }
  get currentComodato(): any[] {
    return this.inventoryArray.find((x) => x.name == 'currentComodato').value;
  }
  get expiredComodato(): any[] {
    return this.inventoryArray.find((x) => x.name == 'expiredComodato').value;
  }
  get expiredChargedComodato(): any[] {
    return this.inventoryArray.find((x) => x.name == 'expiredChargedComodato').value;
  }

  //#endregion


  async InititalizeData() {
    this.showLoading('Cargando inventario', 'Por favor espere.');

    this.asSum = [];
    this.isLoading = false;
    await this.getEvents();
    this.ceveCutId = '';
    this.signs = {
      dispatcherReciverSign: '',
      managerSignature: '',
      supervisorSignature: '',
      dispatcherReciverComment: '',
      managerComment: '',
      supervisorComment: '',
    };
    this.hasSelected = false;
    this.signCompleted = false;
    this.status = CeveCutStatus.NEW;
    // let userLogin = this.settings.getUser();
    var activities = this.settings.getUserAccess();
    this.isCeveSupervisor = false;
    this.isCeveAdmn = false;
    if (activities.find((x) => x == 'SUPCEVE')) {
      this.isCeveSupervisor = true;
    }
    if (activities.find((x) => x == 'ADMCEVE')) {
      this.isCeveAdmn = true;
    }
    if (activities.includes('FICADRC')) {
      this.signActDispatcherReceiver = true;
    }
    if (activities.includes('FICAACE')) {
      this.signActAdministrator = true;
    }
    if (activities.includes('FICASDC')) {
      this.signActSuppervisorDisp = true;
    }

    // this.inventoryCutService
    //   .getCevesByUser(this.userLogin.Id)
    //   .subscribe((ceve) => (this.ceve = ceve));

    await this.selectEvent().then(() => {

      //initial operations
      // this.initControls();
      // this.setControls();
      //subscribers
      // this.subscribeControls();

    })
      .catch(err => {
        this.hideLoading();
        Swal.fire('', err.error, 'warning');
      });
  }

  async getEvents() {
    await this.calendarServ.getLastCutId(this.selectedNodeCut.UnitId).toPromise()
      .then(data => {
        this.ceveCutDate = data.cutDate;
        this.ceveCutId = data.cutId;
      })
      .catch(err => {
        Swal.fire('Compulsa', err.error, 'warning');
      });
  }

  setControls() {

    for (var index = 0; index < this.containerData.length; index++) {
      //init for controls
      this.physicalInventory.push(new FormControl(0));
      this.physicalInventoryfromLapsed.push(new FormControl(0));
      this.couponInventory.push(new FormControl(0));
      this.promissoryNoteInventory.push(new FormControl(0));
      // this.othersInventory.push(new FormControl(0));
      this.totalInventoryVehicle.push(new FormControl(0));
      this.rtmVehicles.push(new FormControl(0));
      this.routeCharges.push(new FormControl(0));
      this.stockAllocation.push(new FormControl(0));
      this.vanLoadRejection.push(new FormControl(0));
      this.totalPhysicalInventory.push(new FormControl(0));
      this.logisticsBalance.push(new FormControl('0'));
      this.diffNotAttributable.push(new FormControl(''));
      this.diffAttributable.push(new FormControl(0));

      //init for data
      this.asDriverVehicles.push(0);
      this.asClients.push(0);
      this.transit.push(0);
      this.transitLeader.push(0);
      this.losses.push(0);
      this.securityRecovery.push(0);
      this.chargedToCollaborator.push(0);
      this.valeChofer.push(0);
      this.valeColabs.push(0);
      this.compulsas.push(0);
      this.paybillAS.push(0);
      this.supposedInventory.push(0);
      this.previousInventory.push(0);
      this.inputMovements.push(0);
      this.outputMovements.push(0);
      this.invAvailableCalculated.push(0);
      this.asSum.push(0);
      this.closingInventory.push(0);
      this.currentComodato.push(0);
      this.expiredComodato.push(0);
      this.expiredChargedComodato.push(0);
    }
  }

  subscribeControls() {
    this.subscribeTotalVehicles();
    this.subscribeTotalPhysicalInventory();
    this.subscribeDifferenceNotAttributable();
    this.subscribeSupposedInventory();
    this.subscribeDiffAttributable();
  }
  //subscribe controls to calculate vehicle inventory
  subscribeTotalVehicles() {
    this.rtmVehicles.valueChanges.subscribe((x) => {
      this.onTotalVehicleChange();
    });
    this.routeCharges.valueChanges.subscribe((x) => {
      this.onTotalVehicleChange();
    });
    this.stockAllocation.valueChanges.subscribe((x) => {
      this.onTotalVehicleChange();
    });
    this.vanLoadRejection.valueChanges.subscribe((x) => {
      this.onTotalVehicleChange();
    });
  }

  //subscribe controls to calcule total physical inventory
  subscribeTotalPhysicalInventory() {
    this.physicalInventory.valueChanges.subscribe((x) => {
      this.onTotalPhysicalInventoryChange();
    });
    this.couponInventory.valueChanges.subscribe((x) => {
      this.onTotalPhysicalInventoryChange();
    });
    this.promissoryNoteInventory.valueChanges.subscribe((x) => {
      this.onTotalPhysicalInventoryChange();
    });
    this.totalInventoryVehicle.valueChanges.subscribe((x) => {
      this.onTotalPhysicalInventoryChange();
    });
  }

  //subscribe controls to calcule diff not imputable to ceve
  subscribeDifferenceNotAttributable() {
    this.logisticsBalance.valueChanges.subscribe((x) => {
      this.onDiffNotAttributableChange();
    });
  }

  //subscribe controls to calculate supposed inventory
  subscribeSupposedInventory() {
    this.logisticsBalance.valueChanges.subscribe((x) => {
      this.onDiffNotAttributableChange();
    });
  }

  subscribeDiffAttributable() {
    this.totalPhysicalInventory.valueChanges.subscribe((x) => {
      this.onDiffAttributableChange();
    });
  }

  onTotalVehicleChange() {
    let result: Array<number> = [];
    for (var i = 0; i < this.totalInventoryVehicle.length; i++) {
      result.push(
        Number(this.rtmVehicles.controls[i].value) +
        Number(this.asDriverVehicles[i]) +
        Number(this.asClients[i]) +
        Number(this.routeCharges.controls[i].value) +
        Number(this.currentComodato[i]) +
        Number(this.stockAllocation.controls[i].value) -
        Number(this.vanLoadRejection.controls[i].value) -
        Number(this.expiredComodato[i])
      );
    }
    this.totalInventoryVehicle.setValue(result);
  }

  //operations for the calculation of total physical inventory
  onTotalPhysicalInventoryChange() {
    let result: Array<number> = [];
    for (var i = 0; i < this.totalPhysicalInventory.length; i++) {
      result.push(
        Number(this.physicalInventory.controls[i].value) +
        Number(this.couponInventory.controls[i].value) +
        Number(this.promissoryNoteInventory.controls[i].value) +
        Number(this.totalInventoryVehicle.controls[i].value) +
        Number(this.paybillAS[i]) +
        Number(this.transit[i]) +
        Number(this.transitLeader[i])
      );
    }
    this.totalPhysicalInventory.setValue(result);
  }

  //operations for the calculate of difference not attributable to ceve
  onDiffNotAttributableChange() {
    let result: Array<number> = [];
    for (let i = 0; i < this.chargedToCollaborator.length; i++) {
      result.push(
        (Number(this.chargedToCollaborator[i]) + Number(this.losses[i])) * -1
      );
    }
    this.diffNotAttributable.setValue(result);
  }

  //operations for the calculate of differential Attributable
  onDiffAttributableChange() {
    let result: Array<number> = [];
    for (let i = 0; i < this.diffAttributable.length; i++) {
      result.push(
        this.status == 'LAPSED'
          ? 0
          :
          Number(this.totalPhysicalInventory.controls[i].value) -
          (Number(this.supposedInventory[i]) + Number(this.asClients[i]))
      );
    }
    this.diffAttributable.setValue(result);
  }

  toggleChargeCollapsed() {
    this.chargedIsCollapsed = !this.chargedIsCollapsed;
    console.log(this.chargedIsCollapsed);
  }


  async selectEvent(item?) {

    this.isLoading = true;
    this.hasSelected = false;

    await this.inventoryCutService.getCeveByContainers(this.selectedNodeCut.UnitId).toPromise().then(async (x) => {
      this.containerData = [];
      x.forEach((element) => {
        this.containerData.push({
          ContainerDescription: element.ContainerDescription.split('.', 1),
          ContainerName: element.ContainerCode,
          ContainerId: element.ContainerId,
          ContainerCode: element.ContainerCode
        });
      });
      // this.inventoryCutService
      //   .getCeveCut(
      //     this.selectedNodeCut.UnitId,
      //     this.datepipe.transform(this.ceveCutDate, 'yyyy-MM-dd')
      //   )


      if (this.adjustNode.Status == "CREATED") {
        this.hideLoading();
        this.showLoading('Cargando caratula', 'por favor espere');
        await this.loadCutData();
        this.startTimer();
        this.evaluateMAxCountDown();

      }
      else {
        this.initControls();
        this.setControls();
        this.subscribeControls();

        await this.getAdjustDetail().then(() => {
          this.hideLoading();
          this.globalFunctionService.messageToast(`Inventario cargado.`, 'success');
          this.startTimer();
          this.evaluateMAxCountDown();

        });
      }

    });

  }

  async loadCutData() {
    await this.adjustServive
      .getAdjustInventoryCut(this.selectedNodeCut.UnitId)
      .toPromise()
      .then(async (x) => {
        this.existInventory(x);
        this.hideLoading();
        // this.showToast("Caratula cargada correctamente", "success");
        Swal.fire('Compulsa', 'Prerrequisitos en sistemas completos, durante la ventana de tiempo de certificación no se podrán realizar transacciones.', 'success');

        if (this.adjustNode.Status == 'APK') {
          await this.adjApk(false);
        }
        else if (this.adjustNode.Status == 'COUNTED') {
          await this.adjApk(false);
        }

      })
      .catch((err) => {
        console.log(err);
      });
  }

  existInventory(ceveCutDetail: Array<AdjustDetailCut>) {
    // if (ceveCut.Version != undefined && ceveCut.Version != null) {
    //   // this.cutVersion = ceveCut.Version;
    // }
    // this.signs.dispatcherReciverSign = ceveCut.DispatcherReciverSign;
    // this.signs.managerSignature = ceveCut.ManagerSign;
    // this.signs.supervisorSignature = ceveCut.SupervisorSign;
    // this.signs.dispatcherReciverComment = ceveCut.DispatcherReciverComment;
    // this.signs.managerComment = ceveCut.ManagerComment;
    // this.signs.supervisorComment = ceveCut.SupervisorComment;
    // this.isManagerSign = ceveCut.ManagerSign != '';
    // this.isDispatcherReciverSign = ceveCut.DispatcherReciverSign != '';
    // this.isSupervisorSign = ceveCut.SupervisorSign != '';
    // this.signCompleted =
    //   this.isManagerSign &&
    //   this.isDispatcherReciverSign &&
    //   this.isSupervisorSign;
    // this.status = ceveCut.Status;
    // this.ceveCutId = ceveCut.CeveCutId;
    // this.persistentCreatedAt = ceveCut.CreatedAt;
    // this.persistentCreatedBy = ceveCut.CreatedBy;
    // this.modifiedAt = this.datepipe.transform(
    //   ceveCut.ModifiedAt,
    //   'dd/MM/yyyy - HH:mm'
    // );
    this.initControls();
    this.setControls();
    this.subscribeControls();
    for (let i = 0; i < ceveCutDetail.length; i++) {
      for (let k = 0; k < this.containerData.length; k++) {
        if (
          ceveCutDetail[i].ContainerId ==
          Number(this.containerData[k].ContainerId)
        ) {
          switch (ceveCutDetail[i].Line) {
            case AdjustLineEnum.PhysicalInventory:
              this.physicalInventory.controls[k].setValue(
                ceveCutDetail[i].Quantity
              );
              this.physicalInventoryfromLapsed.controls[k].setValue(
                ceveCutDetail[i].Quantity < 0 ? 0 : ceveCutDetail[i].Quantity
              );
              break;
            case AdjustLineEnum.Coupons:
              this.couponInventory.controls[k].setValue(
                ceveCutDetail[i].Quantity
              );
            case AdjustLineEnum.PromissoryNote:
              this.promissoryNoteInventory.controls[k].setValue(
                ceveCutDetail[i].Quantity
              );
              break;
            case AdjustLineEnum.TotalVehicle:
              this.totalInventoryVehicle.controls[k].setValue(
                ceveCutDetail[i].Quantity
              );
              break;
            case AdjustLineEnum.RtmVehicles:
              this.rtmVehicles.controls[k].setValue(ceveCutDetail[i].Quantity);
              break;
            case AdjustLineEnum.RouteCharges:
              this.routeCharges.controls[k].setValue(ceveCutDetail[i].Quantity);
              break;
            case AdjustLineEnum.StockAllocation:
              this.stockAllocation.controls[k].setValue(
                ceveCutDetail[i].Quantity
              );
              break;
            case AdjustLineEnum.VanLoadRejection:
              this.vanLoadRejection.controls[k].setValue(
                ceveCutDetail[i].Quantity
              );
              break;
            case AdjustLineEnum.TotalPhysicalInventory:
              this.totalPhysicalInventory.controls[k].setValue(
                ceveCutDetail[i].Quantity
              );
              break;
            case AdjustLineEnum.LogisticsBalance:
              this.logisticsBalance.controls[k].setValue(
                ceveCutDetail[i].Quantity
              );
              break;
            case AdjustLineEnum.ChargeToCollaborator:
              this.chargedToCollaborator[k] = ceveCutDetail[i].Quantity;
              break;

            case AdjustLineEnum.valeChofer:
              this.valeChofer[k] = ceveCutDetail[i].Quantity;
              break;
            case AdjustLineEnum.valeColabs:
              this.valeColabs[k] = ceveCutDetail[i].Quantity;
              break;
            case AdjustLineEnum.compulsas:
              this.compulsas[k] = ceveCutDetail[i].Quantity;
              break;
            case AdjustLineEnum.paybillAS:
              this.paybillAS[k] = ceveCutDetail[i].Quantity;
              break;
            case AdjustLineEnum.DifferenceNotAttributable:
              // if (this.cutVersion > 0) {
              this.diffNotAttributable.controls[k].setValue(
                ceveCutDetail[i].Quantity
              );

              break;
            case AdjustLineEnum.SupposedInventory:
              this.invAvailableCalculated[k] = ceveCutDetail[i].Quantity;
              this.supposedInventory[k] = ceveCutDetail[i].Quantity;
              break;
            case AdjustLineEnum.DifferenceAttributable:

              this.diffAttributable.controls[k].setValue(
                ceveCutDetail[i].Quantity
              );

              break;
            case AdjustLineEnum.SecurityRecovery:
              this.securityRecovery[k] = ceveCutDetail[i].Quantity;
              break;
            case AdjustLineEnum.Losses:
              this.losses[k] = ceveCutDetail[i].Quantity;
              break;
            case AdjustLineEnum.asDriverVehicles:
              this.asDriverVehicles[k] = ceveCutDetail[i].Quantity;
              break;
            case AdjustLineEnum.asClients:
              this.asClients[k] = ceveCutDetail[i].Quantity;
              break;
            case AdjustLineEnum.Transit:
              this.transit[k] = ceveCutDetail[i].Quantity;
              break;
            case AdjustLineEnum.TransitLeader:
              this.transitLeader[k] = ceveCutDetail[i].Quantity;
              break;
            case AdjustLineEnum.PreviousInventory:
              this.previousInventory[k] = ceveCutDetail[i].Quantity;
              break;
            case AdjustLineEnum.InputMovements:
              this.inputMovements[k] = ceveCutDetail[i].Quantity;
              break;
            case AdjustLineEnum.OutputMovements:
              this.outputMovements[k] = ceveCutDetail[i].Quantity;
              break;
            case AdjustLineEnum.InvAvailableCalculated:
              this.invAvailableCalculated[k] = ceveCutDetail[i].Quantity;
              break;
            case AdjustLineEnum.ClosingInventory:
              this.closingInventory[k] = ceveCutDetail[i].Quantity;
              break;
            case AdjustLineEnum.CurrentComodato:
              this.currentComodato[k] = ceveCutDetail[i].Quantity;
              break;
            case AdjustLineEnum.ExpiredComodato:
              this.expiredComodato[k] = ceveCutDetail[i].Quantity;
              break;
            case AdjustLineEnum.ExpiredChargedComodato:
              this.expiredChargedComodato[k] = ceveCutDetail[i].Quantity;
              break;

            default:
              break;
          }
        }
      }
    }
    this.asSum = [];
    for (let k = 0; k < this.containerData.length; k++) {
      this.asSum.push(this.asDriverVehicles[k] + this.asClients[k]);
    }
    // if (
    //   !this.isCeveAdmn ||
    //   this.status == CeveCutStatus.CLOSED ||
    //   this.status == CeveCutStatus.PUBLISHED ||
    //   this.status == CeveCutStatus.INCOMPLETESIGNED ||
    //   this.status == CeveCutStatus.LAPSED ||
    //   this.isNodeChild
    // ) {
    this.inventoryForm.disable();
    // }
    this.onDiffNotAttributableChange();
    this.onDiffAttributableChange();
    this.onTotalVehicleChange();
    this.onTotalPhysicalInventoryChange();

    this.hasSelected = true;
    this.disableButton = true;
    this.isLoading = false;
  }

  setAdjustDetailLines(detail: Array<AdjustDetail>) {
    const unique = (value, index, self) => {
      return self.indexOf(value) === index
    }


    let detailLines = detail.map(x => x.Line).filter(unique).sort((a, b) => a - b);
    for (let i = 0; i < detailLines.length; i++) {
      let currentLine = detailLines[i];
      for (let k = 0; k < this.containerData.length; k++) {
        let containerDetail = detail.filter(x => x.ContainerCode == this.containerData[k].ContainerCode && x.Line == currentLine);

        switch (currentLine) {
          case AdjustLineEnum.PhysicalInventory:
            this.physicalInventory.controls[k].setValue(
              containerDetail[0].Quantity
            );
            this.physicalInventoryfromLapsed.controls[k].setValue(
              containerDetail[0].Quantity < 0 ? 0 : containerDetail[0].Quantity
            );
            break;
          case AdjustLineEnum.Coupons:
            this.couponInventory.controls[k].setValue(
              containerDetail[0].Quantity
            );
            break;
          case AdjustLineEnum.PromissoryNote:
            this.promissoryNoteInventory.controls[k].setValue(
              containerDetail[0].Quantity
            );
            break;
          case AdjustLineEnum.TotalVehicle:
            this.totalInventoryVehicle.controls[k].setValue(
              containerDetail[0].Quantity
            );
            break;
          case AdjustLineEnum.RtmVehicles:
            this.rtmVehicles.controls[k].setValue(containerDetail[0].Quantity);
            break;
          case AdjustLineEnum.RouteCharges:
            this.routeCharges.controls[k].setValue(containerDetail[0].Quantity);
            break;
          case AdjustLineEnum.StockAllocation:
            this.stockAllocation.controls[k].setValue(
              containerDetail[0].Quantity
            );
            break;
          case AdjustLineEnum.VanLoadRejection:
            this.vanLoadRejection.controls[k].setValue(
              containerDetail[0].Quantity
            );
            break;
          case AdjustLineEnum.TotalPhysicalInventory:
            this.totalPhysicalInventory.controls[k].setValue(
              containerDetail[0].Quantity
            );
            break;
          case AdjustLineEnum.LogisticsBalance:
            this.logisticsBalance.controls[k].setValue(
              containerDetail[0].Quantity
            );
            break;
          case AdjustLineEnum.ChargeToCollaborator:
            this.chargedToCollaborator[k] = containerDetail[0].Quantity;
            break;

          case AdjustLineEnum.valeChofer:
            this.valeChofer[k] = containerDetail[0].Quantity;
            break;
          case AdjustLineEnum.valeColabs:
            this.valeColabs[k] = containerDetail[0].Quantity;
            break;
          case AdjustLineEnum.compulsas:
            this.compulsas[k] = containerDetail[0].Quantity;
            break;
          case AdjustLineEnum.paybillAS:
            this.paybillAS[k] = containerDetail[0].Quantity;
            break;
          case AdjustLineEnum.DifferenceNotAttributable:
            // if (this.cutVersion > 0) {
            this.diffNotAttributable.controls[k].setValue(
              containerDetail[0].Quantity
            );

            break;
          case AdjustLineEnum.SupposedInventory:
            this.invAvailableCalculated[k] = containerDetail[0].Quantity;
            this.supposedInventory[k] = containerDetail[0].Quantity;
            break;
          case AdjustLineEnum.DifferenceAttributable:

            this.diffAttributable.controls[k].setValue(
              containerDetail[0].Quantity
            );

            break;
          case AdjustLineEnum.SecurityRecovery:
            this.securityRecovery[k] = containerDetail[0].Quantity;
            break;
          case AdjustLineEnum.Losses:
            this.losses[k] = containerDetail[0].Quantity;
            break;
          case AdjustLineEnum.asDriverVehicles:
            this.asDriverVehicles[k] = containerDetail[0].Quantity;
            break;
          case AdjustLineEnum.asClients:
            this.asClients[k] = containerDetail[0].Quantity;
            break;
          case AdjustLineEnum.Transit:
            this.transit[k] = containerDetail[0].Quantity;
            break;
          case AdjustLineEnum.TransitLeader:
            this.transitLeader[k] = containerDetail[0].Quantity;
            break;
          case AdjustLineEnum.PreviousInventory:
            this.previousInventory[k] = containerDetail[0].Quantity;
            break;
          case AdjustLineEnum.InputMovements:
            this.inputMovements[k] = containerDetail[0].Quantity;
            break;
          case AdjustLineEnum.OutputMovements:
            this.outputMovements[k] = containerDetail[0].Quantity;
            break;
          case AdjustLineEnum.InvAvailableCalculated:
            this.invAvailableCalculated[k] = containerDetail[0].Quantity;
            break;
          case AdjustLineEnum.ClosingInventory:
            this.closingInventory[k] = containerDetail[0].Quantity;
            break;
          case AdjustLineEnum.CurrentComodato:
            this.currentComodato[k] = containerDetail[0].Quantity;
            break;
          case AdjustLineEnum.ExpiredComodato:
            this.expiredComodato[k] = containerDetail[0].Quantity;
            break;
          case AdjustLineEnum.ExpiredChargedComodato:
            this.expiredChargedComodato[k] = containerDetail[0].Quantity;
            break;

          default:
            break;
        }

      }
      // }
    }
    // }
    this.asSum = [];
    for (let k = 0; k < this.containerData.length; k++) {
      this.asSum.push(this.asDriverVehicles[k] + this.asClients[k]);
    }

    this.onDiffNotAttributableChange();
    this.onDiffAttributableChange();
    this.onTotalVehicleChange();
    this.onTotalPhysicalInventoryChange();
    this.validateAdjustStatus();

    this.hasSelected = true;
    this.disableButton = true;
    this.isLoading = false;
  }

  initControls() {
    this.inventoryForm = new FormGroup({
      physicalInventory: new FormArray([]),
      physicalInventoryfromLapsed: new FormArray([]),
      couponInventory: new FormArray([]),
      promissoryNoteInventory: new FormArray([]),
      totalInventoryVehicle: new FormArray([]),
      rtmVehicles: new FormArray([]),
      routeCharges: new FormArray([]),
      stockAllocation: new FormArray([]),
      vanLoadRejection: new FormArray([]),
      totalPhysicalInventory: new FormArray([]),
      logisticsBalance: new FormArray([]),
      diffNotAttributable: new FormArray([]),
      diffAttributable: new FormArray([]),
    });

    this.inventoryArray = [
      { name: 'asDriverVehicle', value: [] },
      { name: 'asClient', value: [] },
      { name: 'transit', value: [] },
      { name: 'transitLeader', value: [] },
      { name: 'losses', value: [] },
      { name: 'securityRecovery', value: [] },
      { name: 'chargedToCollaborator', value: [] },
      { name: 'valeChofer', value: [] },
      { name: 'valeColabs', value: [] },
      { name: 'compulsas', value: [] },
      { name: 'paybillAS', value: [] },
      { name: 'supposedInventory', value: [] },
      { name: 'previousInventory', value: [] },
      { name: 'inputMovements', value: [] },
      { name: 'outputMovements', value: [] },
      { name: 'invAvailableCalculated', value: [] },
      { name: 'closingInventory', value: [] },
      { name: 'currentComodato', value: [] },
      { name: 'expiredComodato', value: [] },
      { name: 'expiredChargedComodato', value: [] },
    ];
    this.asSum = [];
  }

  //#endregion


}

export class AdjustDetailCut extends BaseModel {
  CutId: string;
  Line: number;
  ContainerId: number;
  Quantity: number;
}

export class AdjustDetail extends BaseModel {
  Org: number;
  AdjustCode: string;
  DetailId: string;
  TowerNumber: number;
  TowerStatus: string;
  ContainerLocation: string;
  ContainerCode: string;
  Quantity: number;
  Line: number;
}


export class FirebaseMessage {
  MessageId: number;
  MessageContent: string;
  Sended: Date;
  Readed: Date;
}

export class FirebaseEvent {
  UnitId: number;
  AdjustCode: string;
  CreatedAt: Date;
  Countdow: string;
  Status: string
}

export class FirebaseTowers {
  TowerNumber: number;
  Status: string;
}

export class FirebaseSeller {
  SellerLogin: string;
}
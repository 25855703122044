import { Injectable } from '@angular/core';
import { Channel } from '../../common/models/Channel.Model';
import { GenericService } from '../../common/services/GenericServices';
import { Observable } from 'rxjs';
import { ChannelByCeve } from '../../common/models/ChannelByCeve.Model';

@Injectable({
  providedIn: 'root'
})
export class ChannelService {

  constructor(private genericService: GenericService) { }


  GetChannelByCode(channelCode: string): Observable<Channel> {
    let url = "channel/" + channelCode;
    return this.genericService.getRequest(url, {}, 0);
  }

  getChannelByCeve(ceveId: number): Observable<Channel[]> {
    let url = `channel/getbyparent/${ceveId}`;
    return this.genericService.getRequest(url, {}, 0);
  }

  GetChannel(): Observable<Channel[]> {
    let url = "channel";
    return this.genericService.getRequest(url, {}, 0);
  }

  //Get channels assigned to the ceve except self service 
  getMultichannelByCeve(ceveId: number): Observable<Channel[]> {
    let url = `channel/multichannel/byceve/${ceveId}`;
    return this.genericService.getRequest(url, {}, 0);
  }

  CreateChannel(channel: Channel): Observable<Channel> {
    let url = "channel";
    return this.genericService.postRequest(url, {}, 0, channel);
  }

  CreateUnitChannel(channel: Channel, UnitCode: string): Observable<Channel> {
    let url = "unit/PostUnit/" + UnitCode;
    return this.genericService.postRequest(url, {}, 0, channel);
  }

  UpdateChannel(channelCode: string, channel: Channel): Observable<Channel> {
    let url = "channel/" + channelCode;
    return this.genericService.putRequest(url, {}, 0, channel);
  }

  UpdateUnitChannel(channelCode: string, channel: Channel): Observable<Channel> {
    let url = "unit/" + channelCode;
    return this.genericService.putRequest(url, {}, 0, channel);
  }

  DeleteChannel(channelCode: string): Observable<boolean> {
    let url = "channel/" + channelCode;
    return this.genericService.deleteRequest(url, {}, 0)
  }

  DeleteUnitChannel(channelCode: string): Observable<boolean> {
    let url = "unit/" + channelCode;
    return this.genericService.deleteRequest(url, {}, 0)
  }


  GetChannelsByCeve(CeveId: Number): Promise<ChannelByCeve[]> {
    return new Promise((resolve, reject) => {
      this.genericService.getRequest(`channelByCeve/${CeveId}`, {}, 0).toPromise()
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err.error);
        })
    });
  }

  AddChannelByCeve(CeveId: Number, channelCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.genericService.postRequest(`channelByCeve/${CeveId}/${channelCode}`, {}, 0, null).toPromise()
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err.error);
        })
    });
  }

  RemoveChannelByCeve(CeveId: Number, channelCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.genericService.deleteRequest(`channelByCeve/${CeveId}/${channelCode}`, {}, 0).toPromise()
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err.error);
        })
    });
  }

  
}

import { Component, Input, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import { CustomerBalanceService } from '../../self-service/balance/customer-balance.service';

@Component({
  selector: 'app-select-cell',
  template: `<input type="checkbox" class="check-box" [checked]="checked" (change)="invokeParentMethod($event)">`
})
// tslint:disable-next-line:component-class-suffix
export class SelectChildRendered implements ICellRendererAngularComp, OnInit {
  checked: boolean;
  public params: any;

  constructor(private customerBalServ: CustomerBalanceService) {
  }

  ngOnInit(): void {
    this.checked = this.customerBalServ.allChecked;
  }

  agInit(params: any): void {
    this.params = params;
  }

  public invokeParentMethod(event: any): void {
    const selected = event.target.checked;
    this.params.context.select(this.params.data, selected);
  }

  refresh(): boolean {
    return false;
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from '../../../common/models/Product.Model'
import { UserLogin } from '../../../common/models/UserLogin.Model';
import { SettingsService } from '../../../../core/settings/settings.service';
import { TaxService } from '../../tax.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {

  productCode: string = '';
  product: Product = new Product();
  user: UserLogin;

  constructor(private settings: SettingsService,
    private taxServices: TaxService,
    private url: ActivatedRoute,
    private router: Router) {
      this.productCode = this.url.snapshot.paramMap.get('id');
     }

  ngOnInit(): void {
    this.user = this.settings.getUser();
    this.taxServices.getProductByCode(this.user.Org, this.productCode).toPromise()
    .then((resp:Product) =>{
      this.product = resp;
      console.log(resp);
    })
    .catch()
  }

  btnCancelOnClick(){
    // this.router.navigate(['/tax/product']);
    window.close();
  }
}

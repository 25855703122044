import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from '../../common/services/GenericServices';
import { Item } from '../../common/models/Item.Model';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(private genericService: GenericService) { }

  GetItems(): Observable<Item[]> {
    let url = "item";
    return this.genericService.getRequest(url, {});
  }

  getItemById(itemId: number): Observable<Item> {
    let url = `item/id/${itemId}`;
    return this.genericService.getRequest(url, {}, 0);
  }

  getItemByCode(itemCode: string): Observable<Item> {
    let url = `item/code/${itemCode}`;
    return this.genericService.getRequest(url, {}, 0);
  }

  addItem(place: Item): Observable<Item> {
    let url = "item";
    return this.genericService.postRequest(url, {}, 0, place);
  }

  updateItem(item: Item): Observable<Item> {
    let url = "item";
    return this.genericService.putRequest(url, {}, 0, item);
  }

  removeItem(itemId: number): Observable<boolean> {
    let url = `item/${itemId}`;
    return this.genericService.deleteRequest(url, {}, 0);
  }

  SwitchItem(itemId: number, active: number): Observable<boolean> {
    let url = `item/${itemId}/state/${active}`;
    return this.genericService.putRequest(url, {}, 0, null);
  }

}

import { Component, HostBinding, OnInit } from '@angular/core';

import { SettingsService } from './core/settings/settings.service';

import { Observable, of, Subscription } from 'rxjs';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Logger, CryptoUtils } from 'msal';
import { HttpClient, HttpHeaders,HttpResponse } from '@angular/common/http';
import  jwt_decode  from "jwt-decode";
import { UserLogin } from './ceq/common/models/UserLogin.Model';
import Swal from 'sweetalert2';
import { GenericService } from './ceq/common/services/GenericServices';
import { Router } from "@angular/router"

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    profile: any;
    subscriptions: Subscription[] = [];
    router: Router;

    title = 'MSAL Angular - Sample App';
    isIframe = false;
    loggedIn = false;
    Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true
    });

    constructor(private broadcastService: BroadcastService,private genericService: GenericService,private http: HttpClient, private authService: MsalService,public settings: SettingsService) { }


    @HostBinding('class.layout-fixed') get isFixed() { return this.settings.getLayoutSetting('isFixed'); };
    @HostBinding('class.aside-collapsed') get isCollapsed() { return this.settings.getLayoutSetting('isCollapsed'); };
    @HostBinding('class.layout-boxed') get isBoxed() { return this.settings.getLayoutSetting('isBoxed'); };
    @HostBinding('class.layout-fs') get useFullLayout() { return this.settings.getLayoutSetting('useFullLayout'); };
    @HostBinding('class.hidden-footer') get hiddenFooter() { return this.settings.getLayoutSetting('hiddenFooter'); };
    @HostBinding('class.layout-h') get horizontal() { return this.settings.getLayoutSetting('horizontal'); };
    @HostBinding('class.aside-float') get isFloat() { return this.settings.getLayoutSetting('isFloat'); };
    @HostBinding('class.offsidebar-open') get offsidebarOpen() { return this.settings.getLayoutSetting('offsidebarOpen'); };
    @HostBinding('class.aside-toggled') get asideToggled() { return this.settings.getLayoutSetting('asideToggled'); };
    @HostBinding('class.aside-collapsed-text') get isCollapsedText() { return this.settings.getLayoutSetting('isCollapsedText'); };



    ngOnInit() {
        // prevent empty links to reload the page
        document.addEventListener('click', e => {
            const target = e.target as HTMLElement;
            if (target.tagName === 'A' && ['', '#'].indexOf(target.getAttribute('href')) > -1)
                e.preventDefault();
        });

        let loginSuccessSubscription: Subscription;
        let loginFailureSubscription: Subscription;

        this.isIframe = window !== window.parent && !window.opener;

        this.checkAccount();

        loginSuccessSubscription = this.broadcastService.subscribe('msal:loginSuccess', () => {
          this.checkAccount();
          //console.log ("Antes de Redirect a Home APP");
          window.location.reload();
        });

        loginFailureSubscription = this.broadcastService.subscribe('msal:loginFailure', (error) => {
          //console.log('Login Fails:', error);
        });

       // this.subscriptions.push(loginSuccessSubscription);
        //this.subscriptions.push(loginFailureSubscription);

        this.authService.handleRedirectCallback((authError, response) => {
          if (authError) {
            console.error('Redirect Error: ', authError.errorMessage);
            return;
          }

         // console.log('Redirect Success: ', response.accessToken);
        });

        this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
          //console.log('MSAL Logging: ', message);
        }, {
          correlationId: CryptoUtils.createNewGuid(),
          piiLoggingEnabled: false
        }));

        let wStatus= localStorage.getItem("enableWidgets");
        if(wStatus == "undefined" || wStatus == null){
          localStorage.setItem("enableWidgets",'true');
        }
    }

    checkAccount() {
        this.loggedIn = !!this.authService.getAccount();
        if (this.loggedIn)
        {

         let token= localStorage.getItem("msal.idtoken");
         let decoded = this.getDecodedAccessToken(token);
         let obj= decoded.name;

         let tUser= localStorage.getItem("user");

         if (tUser == null || tUser == "undefined")
         {

           this.LoginAD(decoded.preferred_username, token).subscribe(response => {

             var rlogin = localStorage.getItem("Rlogin");
             if (rlogin != "1") {
                  if (response.Org == 0) {
                     this.Toast.fire({
                      icon: 'error',
                      title: 'Estimado '+ response.Name + ' su petición de registro al sistema de CEQ ha sido enviada, es necesario a que el Operador de seguridad del sistema lo procese'
                        });

                      this.settings.isLogin = false;
             
                      localStorage.setItem("Rlogin", "1");
                        window.location.href = "/home";
                  }
                  else {
                      localStorage.setItem("Rlogin", "0");
                   }

               if (response.Org == -1) {
                 window.location.href = "/selectorg";

               }
               //window.location.href = "/home";
              //return;
            }
            this.settings.setUser(response).then(() => {
              //redirecciona a pantalla de inicio
              this.settings.isLogin = true;
              window.location.reload();

            }).catch(err => {
              this.Toast.fire({
                icon: 'error',
                title: JSON.stringify(err)
              });
            });

          }, (error) => {

            this.Toast.fire({
              icon: 'error',
              title: 'Error al recuperar datos de usuario'
            });
          });
         }
        }
      }

      login() {
        const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

        if (isIE) {
          this.authService.loginRedirect();
        } else {
          this.authService.loginPopup();
        }
      }

      logout() {
        this.authService.logout();
      }
      ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
      }

      LoginAD(login: string, password: string): Observable<UserLogin> {
        let url = "security/loginAD";
        return this.genericService.postRequest(url, {}, 0, { Login: login, Password: password });
      }
      getDecodedAccessToken(token: string): any {
        try{
            return jwt_decode(token);
        }
        catch(Error){
            return null;
        }
      }

}

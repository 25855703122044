<div class="content-heading">
  <div class="header-container">
    <h3>Reporte de Flujos</h3>
    <small>Reporte Web</small>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row mt-3 col-lg-12 col-md-12">


            <div class="col-sm-4">
              <span>Organizaci&oacute;n Origen</span>
              <select class="form-control" [(ngModel)]="orgSelectedOri" name="GenNameOri" (ngModelChange)="changeFilterOrgOri()" required>
                <option *ngFor="let unit of organizacionList" [value]="unit.UnitId">{{unit.UnitDescription}}</option>
              </select>
            </div>

            <div class="col-sm-4">
              <span>Origen</span>
              <select class="form-control" [(ngModel)]="Ori" name="CountryId" (ngModelChange)="changeFilterOri()" required>
                <option [value]="0" selected>Todos</option>
                <option *ngFor="let unit of origenList" [value]="unit.UnitId">{{unit.UnitDes}}</option>
              </select>
            </div>
          </div>

          <div class="row mt-3 col-lg-12 col-md-12">
            <div class="col-sm-4">
              <span>Organizaci&oacute;n Destino</span>
              <select class="form-control" [(ngModel)]="orgSelectedDes" name="GenNameDes" (ngModelChange)="changeFilterOrgDes()" required>
                <option *ngFor="let unit of organizacionList" [value]="unit.UnitId">{{unit.UnitDescription}}</option>
              </select>
            </div>

            <div class="col-sm-4">
              <span>Destino</span>
              <select class="form-control" [(ngModel)]="Dest" name="CountryId" (ngModelChange)="changeFilterDest()" required>
                <option [value]="0" selected>Todos</option>
                <option *ngFor="let unit of destinoList" [value]="unit.UnitId">{{unit.UnitDes}}</option>
              </select>
            </div>
          </div>
          <br>
          <div class="col" >
          <div class="row mt-3 col-lg-12 col-md-12">
            <!--Buttons-->
            <button class="mr-1  btn btn-labeled btn-outline-success mb-2" [disabled]="downloading" (click)="download()">
              <span class="btn-label">
              <i class="fa fa-file-excel" aria-hidden="true" *ngIf="!downloading"></i>
              <i class="fa fa-circle-notch fa-spin" *ngIf="downloading"></i>
            </span>
             <span>Descargar reporte</span>
            </button>
          </div>
        </div>               
        </div>
      </div>
    </div>
  </div>
</div>


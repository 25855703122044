import { BaseModel } from "./BaseModel.Model";

export class CapexModel extends BaseModel {
  constructor() {
    super();
    this.org = 0;
    this.capexId = 0;
    this.capexName = '';
    this.unitId = 0;
    this.currencyId = 0;
    this.currencyName = '';
    this.capexYear = '';
    this.userAdmin = '';
    this.organization = '';
    this.original = '';
    this.originalAmount = 0;
    this.reserve = '';
    this.reserveAmount = 0;
    this.executed = '';
    this.executedAmount = 0;
    this.available = '';
    this.availableAmount = 0;
  }

  org: number;
  capexId: number;
  capexName: string;
  unitId: number;
  currencyId: number;
  currencyName: string;
  capexYear: string;
  userAdmin: string;
  organization: string;
  original: string;
  originalAmount: number;
  reserve: string;
  reserveAmount: number;
  executed: string;
  executedAmount: number;
  available: string;
  availableAmount: number;
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/core/settings/settings.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';

import { CeqTableComponent } from 'src/app/ceq/common/ceq-table/ceq-table.component';
import { EditChildRendered } from '../../../common/renders/edit-child-rendered';
import { DeleteChildRendered } from '../../../common/renders/delete-child-rendered';
import { ZipcodeService } from '../zipcode.service';
import { FiscalConfigModel } from 'src/app/ceq/common/models/FiscalConfig.Model';
import { UserLogin } from 'src/app/ceq/common/models/UserLogin.Model';

@Component({
  selector: 'app-zipcode-list',
  templateUrl: './zipcode-list.component.html',
  styleUrls: ['./zipcode-list.component.scss']
})
export class ZipcodeListComponent implements OnInit {

  constructor(private router: Router,
    private settings: SettingsService,
    private zipcodeService: ZipcodeService) { }

  // ViewChild
  @ViewChild('CeqGrid', { static: false }) ceqGrid: CeqTableComponent;
  options: GridOptions;
  PageSizes = [10, 25, 50, 100, 1000];
  pageSize = 10;
  fileToUpload: File | null = null;
  saving: boolean = false;
  userLogin: UserLogin;
  neigbhoodCode: string = '';
  stateCode: string = '';


  gridOptions = {
    context: this,
    frameworkComponents: {
      childEditRenderer: EditChildRendered,
      childDeleteMessageRenderer: DeleteChildRendered
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) { },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    }
  }

  columnDefs = [
    {
      headerName: 'Código postal',
      field: 'IdZipCode',
      width: 10,
      cellClass: 'align-start',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      editable: false
    },
    {
      headerName: 'Colonia',
      field: 'NeighborhoodCode',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      unSortIcon: true,
      resizable: true,
      cellRenderer: (params: any) => (params.data.NeighborhoodCode + ' - ' + params.data.NeighborhoodName)
    },
    {
      headerName: 'Ciudad',
      field: 'CityCode',
      width: 20,
      cellClass: 'align-start',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      editable: false,
      cellRenderer: (params: any) => (params.data.CityCode + ' - ' + params.data.CityDescription)
    },
    {
      headerName: 'Estado',
      field: 'StateCode',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      unSortIcon: true,
      resizable: true,
      cellRenderer: (params: any) => (params.data.StateCode + ' - ' + params.data.StateDescription)
    },
    {
      headerName: 'Localidad',
      field: 'StateCode',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      unSortIcon: true,
      resizable: true,
      cellRenderer: (params: any) => (params.data.LocationCode + ' - ' + params.data.LocationDescription)
    }
  ];

  ngOnInit(): void {
    this.userLogin = this.settings.getUser();
  }

  gridEmiter(GridOptions: GridOptions) {
    this.options = GridOptions;
    this.getFiscalConfig();
  }

  onPageSizeChange(value: number) {
    this.pageSize = value;
    this.getFiscalConfig();
  }

  btnAddClick() {
    this.router.navigate(['/tax/zipcode/create']);
  }

  edit(row: any) {
    this.router.navigate(['/tax/zipcode', row.data.IdZipCode]);
  }

  delete(row: any) {
    Swal.fire({
      title: 'Confirmacion',
      text: "¿Desea eliminar el código postal " + row.data.IdZipCode + "?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> &nbsp; Aceptar',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i> &nbsp; Cancelar',
      backdrop: `rgba(0,157,210,0.5)`
    }).then((result) => {
      if (result.isConfirmed) {
        this.zipcodeService.deleteZipCode(row.data.Org, row.data.IdZipCode).subscribe(data => {
          console.log("result", data);
          Swal.fire(
            '¡Éxito!',
            'La Código postal se eliminó correctamente.',
            'success'
          );
        }, (error) => {
          console.log("error", error);
          Swal.fire('¡Error!', error.error, 'error');
        }, () => {
          this.getFiscalConfig();
        });
      }
    })
  }


  getFiscalConfig() {
    // this.zipcodeService.getZipCodeList().subscribe(data => {
    //   this.ceqGrid.data = data;
    // });

    var url: string = `zipcode/${this.userLogin.Org}/list/${this.pageSize}`;
    if(this.stateCode != ''){
      url += `?stateCode=${this.stateCode}`
    }
    if(this.neigbhoodCode != ''){
      url += this.stateCode != '' ? `&neighborhoodCode=${this.neigbhoodCode}` : `?neighborhoodCode=${this.neigbhoodCode}`
    }
    this.ceqGrid.endPoint = url;
    this.ceqGrid.refreshTable();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);

    if (this.fileToUpload != null) {
      if (this.fileToUpload.name.endsWith(".csv")) {
        this.uploadFileToActivity();
      }
      else {
        Swal.fire('Cuidado!', 'El archivo seleccionado debe tener formato .csv ', 'warning');
        this.fileToUpload = null;
      }
    }
    console.log(this.fileToUpload);

  }

  uploadFileToActivity() {
    const formData: FormData = new FormData();
    formData.append('zipCodes', this.fileToUpload, this.fileToUpload.name);
    this.saving = true;
    this.zipcodeService.importZipCodes(formData)
      .toPromise()
      .then(x => {
        Swal.fire('¡Éxito!', 'Se ha guardado la información: ', 'success');
        this.saving = false;
        this.fileToUpload = null;
      })
      .catch((e) => {
        Swal.fire('Error!', 'Ha ocurrido un error al guardar', 'error');
        this.saving = false;
        this.fileToUpload = null;
      });
  }


}

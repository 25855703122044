import { Router } from '@angular/router';
import { Unit } from '../../common/models/Unit.Model';
import { Component, OnInit,ViewChild } from '@angular/core';
import { Management } from '../../common/models/Management.Model';
import { ReportsService } from '../reports.service'
import { SettingsService } from '../../../core/settings/settings.service';
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';
import { GridOptions } from 'ag-grid-community';
import { CeqTableComponent } from '../../common/ceq-table/ceq-table.component';
import Swal from 'sweetalert2';
import { Calendar } from '../../common/models/Calendar.Model';
import { CalendarService } from '../../configuration/calendar/calendar.service';
import { AdjustService } from '../../inventory/adjust/adjust.service';


@Component({
  selector: 'app-rep-balance',
  templateUrl: './rep-balance.component.html',
  styleUrls: ['./rep-balance.component.scss']
})
export class RepBalanceComponent implements OnInit {
  constructor(private router: Router,
    private calendarService: CalendarService,
    private reportService : ReportsService, 
    private settings: SettingsService,
    private globalFunctionService: GlobalFunctionsService,
    private adjustService:AdjustService
    ) { }

  downloading: boolean = false;
  GenOri: string;
  GenDes: string;
  gerenciasList: Management[];
  origenList :  Unit [];
  destinoList :  Unit [];
  saldosList :  Unit [];
  organizacionList: Unit [];
  orgSelectedOri : string;
  orgSelectedDes : string ;
  Ori: string;
  Dest : string; 
  GenNameOri : string;
  GenNameDes : string;
  canSearch : boolean = false;
  @ViewChild('listReceivedGrid', { static: false }) listReceivedGrid: CeqTableComponent;
  receivedOption: GridOptions;
  gridOptions = {
    context: this,
    frameworkComponents: {
     
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) { },
  };
  
  columnGeneratedDefs = [
   
    {
      headerName: 'Gerencia Origen ',
      field: 'ManagementOrigen',
      width: 25,
      cellClass: 'align-start',
      editable: false,
      sortable: false,
      resizable: true
    },
    {
      headerName: 'Clasificación Origen',
      field: 'TypeOrigen',
      width: 25,
      cellClass: 'align-start',
      editable: false,
      sortable: false,
      resizable: true
    },
    {
      headerName: 'Origen',
      field: 'OutputName',
      width: 25,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      resizable: true
    },
    {
      headerName: 'Gerencia Destino',
      field: 'ManagementDestiny',
      width: 25,
      cellClass: 'align-start',
      editable: false,
      sortable: false,
      resizable: true
    },
    {
      headerName: 'Clasificacion Destino',
      field: 'TypeDestiny',
      width: 25,
      cellClass: 'align-start',
      editable: false,
      sortable: false,
      resizable: true
    },
    {
      headerName: 'Destino',
      field: 'InputName',
      width: 25,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      resizable: true
    },
    
    {
      headerName: 'Equipo',
      field: 'ContainerCode',
      width: 15,
      cellClass: 'align-start',
      editable: false,
      sortable: false,
      resizable: true
    },
    {
      headerName: 'Cantidad',
      field: 'Quantity',
      width: 25,
      cellClass: 'align-end',
      editable: false,
      sortable: true,
      resizable: true
    },
  ];


  async ngOnInit(){
    this.globalFunctionService.canOperate();
    this.globalFunctionService.hasFSBRPending();
    await this.calendarService.CalendarInventoryInProgress().subscribe((calendar: Calendar[] )=>{ 
      if (calendar.length==1)
      { 
        this.globalFunctionService.messageToast(`No es posible realizar esta operación, se encuentra en toma de inventario fisico.`, 'warning');
        this.router.navigate([`/home`]);
      }
    });

    await this.adjustService.validateAdjustActive(this.settings.getUser().UnitId)
      .toPromise()
      .then(resp => {
        if (resp.AdjustCode !== '') {
          this.globalFunctionService.messageToast(`No es posible realizar esta operación, se encuentra en compulsa.`, 'warning');
          this.router.navigate([`/home`]);
        }
      });


    this.getOrganizacion();
  }


  gridReceivedEmiter(gridOptions?: GridOptions){
    console.log('gridOptions');
    console.log(gridOptions);
    this.receivedOption = gridOptions;
    this.listReceivedGrid.data = [];
    
  }
  
  download() {
  if (this.Ori==null || this.Dest==null)
  {
    this.globalFunctionService.messageToast(`Por favor capture los filtros faltantes.`, 'warning');
    return;
  }
    this.downloading = true;
    console.log ("Antes de llamada");
    this.reportService.getRepSaldosExcel(this.Ori,this.Dest,this.orgSelectedOri,this.orgSelectedDes).subscribe((resp: any) => {
      console.log("Fin llamada");
      if(resp == null){
        this.downloading=false;
        this.globalFunctionService.messageToast(`No se ha encontrado información.`, 'warning');
        return;
      }
     console.log("termino llamada");
     
      let blob = new Blob([resp], { type: resp.type });

      const anchor = document.createElement('a');
     
      anchor.download = `CEQ-ReporteFlujos.xlsx`;
      anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
      anchor.click();
      this.downloading=false;
      
    }, err => {
      this.downloading=false;
      this.globalFunctionService.messageToast(`Error en la invocación`, 'warning');
      console.log(err);
    });


  
    
  }


  getOrganizacion(): void {

    let userId=0;
    let org=1;
    let user = JSON.parse(localStorage.getItem("user"));
     if (user != null && user != undefined) {
       userId=user.Id;
       org=user.Org;
     }
    this.reportService.getOrganizacionesByUser(org,userId).subscribe((regiones: Unit[]) => {
      this.organizacionList = regiones;
      this.orgSelectedOri=regiones[0].UnitId.toString();
      this.orgSelectedDes=regiones[0].UnitId.toString();
      this.changeFilterOrgOri();
      this.changeFilterOrgDes();
    });

   }
 

changeFilterOrgOri()
{
  let userId=0;
  let org=1;
  let user = JSON.parse(localStorage.getItem("user"));
   if (user != null && user != undefined) {
     userId=user.Id;
     org=user.Org;
   }
  this.reportService.getUserNodes(org,userId,this.orgSelectedOri).subscribe((move: any[]) => {
    this.origenList = move;
    this.Ori=move[0].UnitId.toString();

       
  });

}

changeFilterOrgDes()
{
  let userId=0;
  let org=1;
  let user = JSON.parse(localStorage.getItem("user"));
   if (user != null && user != undefined) {
     userId=user.Id;
     org=user.Org;
   }
  this.reportService.getUserNodes(org,userId,this.orgSelectedDes).subscribe((move: any[]) => {
    this.destinoList = move;
    //this.Dest="0";
    this.Dest=move[0].UnitId.toString();

       
  });

}

changeFilterOri()
{
}


changeFilterDest()
{}


}

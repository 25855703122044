<div class="content-heading">
  <div class="header-container">
    <h3>Traspasos Automáticos</h3>
    <small>Creación de nuevo traspaso</small>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-default">
        <div class="card-body">
          <!-- FILTROS -->
          <div class="row">
            <div class="col-lg-12">



              <div class="form-group col col-md-12">
                <label class="form-label">Descripci&oacute;n</label>
                <input class="form-control" type="text" value="" placeholder="Descripción del canal" [(ngModel)]="item.Description" name="Description" required maxlength="200" />
              </div>


            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <label>Intermedio</label>


            </div>
            <div class="col-lg-4">
              <label>Final</label>
              <div>

              </div>

            </div>

            <div class="col-lg-4">
              <label>Satelite</label>
              <div>

              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <div>

                <ng-autocomplete [placeholder]="'Búsqueda'"
                                 [disabled]="working"
                                 [data]="unitList"
                                 [searchKeyword]="'UnitDescription'"
                                 [itemTemplate]="itemInputTemplate"
                                 [notFoundTemplate]="notFoundinputTemplate"
                                 (selected)='UpdateRegionIntermediate($event)'
                                 (inputCleared)="IntermediateOutputChange()"
                                 [debounceTime]="600"
                                 [minQueryLength]="3"
                                 [(ngModel)]="IntermediateSelected"
                                 required>
                </ng-autocomplete>
                <ng-template #itemInputTemplate let-item>
                  <a [innerHTML]="item.UnitDescription"></a>
                </ng-template>
                <ng-template #notFoundinputTemplate let-notFound>
                  <div [innerHTML]="'No se encontraron coincidencias'"></div>
                </ng-template>


              </div>


            </div>

            <div class="col-lg-4">
              <div>
                <ng-autocomplete [placeholder]="'Búsqueda'"
                                 [disabled]="working"
                                 [data]="unitList"
                                 [searchKeyword]="'UnitDescription'"
                                 [itemTemplate]="itemInputTemplate"
                                 [notFoundTemplate]="notFoundinputTemplate"
                                 (selected)='UpdateRegionFinal($event)'
                                 (inputCleared)="FinalOutputChange()"
                                 [debounceTime]="600"
                                 [minQueryLength]="3"
                                 [(ngModel)]="FinalSelected"
                                 required>
                </ng-autocomplete>
                <ng-template #itemInputTemplate let-item>
                  <a [innerHTML]="item.UnitDescription"></a>
                </ng-template>
                <ng-template #notFoundinputTemplate let-notFound>
                  <div [innerHTML]="'No se encontraron coincidencias'"></div>
                </ng-template>
              </div>
            </div>
            <div class="col-lg-4">
              <div>

                <ng-autocomplete [placeholder]="'Búsqueda'"
                                 [disabled]="working"
                                 [data]="unitList"
                                 [searchKeyword]="'UnitDescription'"
                                 [itemTemplate]="itemInputTemplate"
                                 [notFoundTemplate]="notFoundinputTemplate"
                                 (selected)='UpdateRegionSatellite($event)'
                                 (inputCleared)="SatelliteOutputChange()"
                                 [debounceTime]="600"
                                 [minQueryLength]="3"
                                 [(ngModel)]="SatelliteSelected"
                                 required>
                </ng-autocomplete>
                <ng-template #itemInputTemplate let-item>
                  <a [innerHTML]="item.UnitDescription"></a>
                </ng-template>
                <ng-template #notFoundinputTemplate let-notFound>
                  <div [innerHTML]="'No se encontraron coincidencias'"></div>
                </ng-template>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4">
              <label>{{IntermedeateRegion}}</label>
            </div>
            <div class="col-lg-4">

              <label>{{FinalRegion}}</label>
            </div>
            <div class="col-lg-4">
              <label>{{SatelliteRegion}}</label>

            </div>


          </div>


          <BR />
          <BR />
          <div class="row">
            <div class="col-lg-4">


              <button [disabled]="downloading"
                      type="button"
                      class="mr-1  btn btn-labeled btn-outline-primary mb-2"
                      (click)="addTransfer()">
                <div *ngIf="downloading">
                  <i class="fas fa-circle-notch fa-spin"></i> Descargando
                </div>
                <div *ngIf="!downloading">
                  <span class="btn-label">
                  <i class="fa fa-check"></i>
                  </span>
                  <span>Aceptar</span>
                </div>
              </button>
              &nbsp;
              <button [disabled]="downloading"
                      type="button"
                      class="mr-1  btn btn-labeled btn-outline-danger mb-2"
                      (click)="cancelTransfer()">
                <div *ngIf="downloading">
                  <i class="fa fa-chevron-circle-left "></i> Descargando
                </div>
                <div *ngIf="!downloading">
                  <span class="btn-label">
                  <i class="fa fa-chevron-circle-left"></i>
                  </span>
                   <span>Salir</span>
                </div>
              </button>

            </div>
          </div>
        </div>
          </div>


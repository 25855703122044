<div class="content-heading">
  <div class="header-Container">
    <h3>Reporte de Comodato</h3>
    <small>Reporte de comodato</small>
  </div>
</div>


  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-default">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <span>
                  Centro de ventas
                </span>
                <ng-autocomplete [placeholder]="'Búsqueda'"
                                 [disabled]=""
                                 [data]="nodes"
                                 [searchKeyword]="inputArgument"
                                 [itemTemplate]="itemOutputTemplate"
                                 [notFoundTemplate]="notFoundoutputTemplate"
                                 [debounceTime]="600"
                                 [minQueryLength]="3"
                                 (selected)='selectCeVeEvent($event)'
                                 (inputCleared)='clearCeVeEvent($event)'
                                 [(ngModel)]="selectedUnit">
                </ng-autocomplete>
                <ng-template #itemOutputTemplate let-item>
                  <a [innerHTML]="item.UnitDescription"></a>
                </ng-template>
                <ng-template #notFoundoutputTemplate let-notFound>
                  <div [innerHTML]="'No se encontraron coincidencias'"></div>
                </ng-template>
              </div>

              <div class="col">
                Fecha inicial

                <input [disabled]="working"
                       type="date"
                       class="form-control"

                       [(ngModel)]="initialDate"
                       (change)="changeDate(true)" />
              </div>
              <div class="col-sm-1"></div>
              <div class="col">
                Fecha final
                <input
                       type="date"
                       class="form-control"
                        [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="finalDate"
                       (change)="changeDate(false)"
                       />
              </div>
           </div>
          <br />
          <div class="buttons-container">
            <button type="button" class="mr-1  btn btn-labeled btn-outline-success mb-2"  (click)="btnDowndload()" [disabled]="downloading">
              <span class="btn-label">
              <i class="fa fa-file-excel" aria-hidden="true" *ngIf="!downloading"></i>
              <i class="fa fa-circle-notch fa-spin" *ngIf="downloading"></i>
              </span>
              <span *ngIf="!downloading">Descargar reporte</span>
              <span *ngIf="downloading">Descargando</span>
            </button>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>

import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';

import { GlobalFunctionsService } from 'src/app/ceq/common/functions/global-functions.service';
import { InventoryCutService } from '../inventory-cut.service';
import { RemissionService } from '../../../enterprise/remission/remission.service';
import { SettingsService } from '../../../../core/settings/settings.service';
import { CalendarService } from '../../../configuration/calendar/calendar.service';

import { InventoryMovementDetail } from 'src/app/ceq/common/models/InventoryMovementDetail.Model';
import { FactoryByContainer } from 'src/app/ceq/common/models/FactoryByContainer';
import { InventoryMovement } from 'src/app/ceq/common/models/InventoryMovement.Model';
import { FactoryCutDetail } from 'src/app/ceq/common/models/FactoryCutDetail.Model';
import { FactoryCutLine } from './FactoryCutLine.Enum';
import { FactoryCut } from '../../../common/models/FactoryCut.Model';
import { Container } from 'src/app/ceq/common/models/Container.Model';
import { UserLogin } from 'src/app/ceq/common/models/UserLogin.Model';
import { Calendar } from 'src/app/ceq/common/models/Calendar.Model';
import { Factory } from 'src/app/ceq/common/models/Factory.Model';

interface movObj {
  Movement: InventoryMovement;
  Details: Array<InventoryMovementDetail>;
}

interface item {
  containerId: number;
  quantity: number;
}

@Component({
  selector: 'app-inventory-factory',
  templateUrl: './inventory-factory.component.html',
  styleUrls: ['./inventory-factory.component.scss'],
})
export class InventoryFactoryComponent implements OnInit {
  user: UserLogin;
  datepipe: DatePipe = new DatePipe('en-US');
  factory: any;
  date: Date = new Date();
  currentYear = new Date().getFullYear();
  selectedDate: Date = new Date();
  isLoadingFactory: boolean = false;
  factoryArgument = 'name';
  factoryList: any[] = [];
  events: Calendar[] = [];
  lastEvent: Calendar;
  containers: Container[] = [];
  containersByFactory: FactoryByContainer[] = [];
  searching: boolean = false;
  loadingDates: boolean = true;
  dataReady: boolean = false;
  factoryCutObject: FactoryCut = new FactoryCut();
  previousInventory: item[] = [];
  unitSelected: number = 0;
  previousDate: string;
  purchases: item[] = [];
  recycling: item[] = [];
  other: item[] = [];
  recovery: item[] = [];
  theoricInventory: item[] = [];
  physicalInventory: item[] = [];
  transitInventory: item[] = [];
  ceveRelation: item[] = [];
  factoryRelation: item[] = [];
  realInventory: item[] = [];
  totalBalance: item[] = [];
  difference: item[] = [];
  percentage: item[] = [];
  miscellaneous: item[] = [];
  adjustFab: item[] = [];
  totalDifferences: item[] = [];
  factoryCut: any[] = [];
  closeDate: Date;
  isFabSupervisor: boolean;
  isFabAdmn: boolean;
  working: boolean = false;
  closing: boolean = false;
  isSupervisorSign: boolean = false;
  isManagerSign: boolean = false;
  isRegionManagerSign: boolean = false;
  isLastCutDate: boolean = false;
  actualDateTime: string;
  isDateTimeRangeValid: boolean = false;
  factoryTitle: string = '';
  supervisorSign: string = '|';
  managerSign: string = '|';
  regionalManagerSign: string = '|';

  //signsActivities
  signActSuppervisorInverseLogistic: boolean = false;
  signActCheifLogistic: boolean = false;
  signActRegionalLogistic: boolean = false;

  constructor(
    private settingsServ: SettingsService,
    private remissionServ: RemissionService,
    private calendarServ: CalendarService,
    private inventoryCutServ: InventoryCutService,
    private globalFunctionService: GlobalFunctionsService
  ) {}

  ngOnInit(): void {
    this.globalFunctionService.hasFSBRPending();
    this.globalFunctionService.canOperate();
    this.initializeData();
  }

  async getCentralTime(): Promise<Date> {
    var centralDate: Date = new Date();
    await this.inventoryCutServ
      .getCentralTime()
      .toPromise()
      .then((x) => {
        centralDate = x;
      })
      .catch(() => {
        this.globalFunctionService.messageToast(`No se ha podido obtener la hora central.`, 'warning');
        return;
      });
    return centralDate;
  }

  async initializeData(): Promise<void> {
    await this.updateTime();
    this.date = await this.getCentralTime();
    this.selectedDate = undefined;
    this.factoryCutObject.FactoryCutId = '';
    this.user = this.settingsServ.getUser();
    this.getEvents();
    var activities = this.settingsServ.getUserAccess();
    this.isFabAdmn = false;
    this.isFabSupervisor = false;
    if (activities.find((x) => x == 'SUPPLANTA')) {
      this.isFabSupervisor = true;
    }
    if (activities.find((x) => x == 'ADMPLANTA')) {
      this.isFabAdmn = true;
    }
    if (activities.includes('FICASLI')) {
      this.signActSuppervisorInverseLogistic = true;
    }
    if (activities.includes('FICAGJL')) {
      this.signActCheifLogistic = true;
    }
    if (activities.includes('FICAGRL')) {
      this.signActRegionalLogistic = true;
    }
  }

  /**
   * Gets all events on database
   *
   * @memberof InventoryFactoryComponent
   */
  getEvents(): void {
    this.calendarServ.getCalendarCutEvents().subscribe(
      (events: Calendar[]) => {
        this.loadingDates = false;
        this.events = events.sort((b,a)=>new Date(a.StartAt).getTime() - new Date(b.StartAt).getTime());
        var dates = events.map((x) => x.StartAt);
        var newDate = dates.reduce((a, b) => (a > b ? a : b));
        this.lastEvent = events.find((x) => x.StartAt == newDate);
      },
      (err) => {
        this.loadingDates = false;
        console.log(err);
      }
    );
  }

  /**
   * Finds factory cut by day, If can't find a previous factory cut generates the actual inventory cut
   *
   * @memberof InventoryFactoryComponent
   */
  getFactoryCutByDay(): void {
    this.inventoryCutServ
      .getCeveActive(this.factory.id)
      .toPromise()
      .then((data) => {
        if (data) {
          this.inventoryCutServ
            .getCeveRelationCut(
              this.user.Org,
              this.factory.id,
              this.selectedDate
            )
            .subscribe(
              (ceveCut) => {
                this.clearLists();
                this.getCutFactory();
              },
              (err) => {
                this.globalFunctionService.messageToast(`Ocurrió un error al obtener la información de los centro de ventas relacionados.`, 'warning');
                this.searching = false;
              }
            );
        } else {
          Swal.fire({
            icon: 'error',
            title: `El nodo seleccionado no se encuentra activo.`,
          });
          this.searching = false;
          return;
        }
      });
  }

  getCutFactory(): void {
    this.inventoryCutServ
      .getFactoryCutByDay(this.user.Org, this.factory.id, this.selectedDate)
      .subscribe(
        (factoryCut) => {
          this.factoryCutObject = factoryCut;
          if(this.factoryCutObject.SupervisorSign != undefined){
            if(this.factoryCutObject.SupervisorSign == ''){
              this.supervisorSign='|';
            }else{
              this.supervisorSign = this.factoryCutObject.SupervisorSign;
            }
          }
          if(this.factoryCutObject.ManagerSign != undefined){
            if(this.factoryCutObject.ManagerSign == ''){
              this.managerSign='|';
            }else{
              this.managerSign = this.factoryCutObject.ManagerSign;
            }
          }
          if(this.factoryCutObject.RegionalManagerSign != undefined){
            if(this.factoryCutObject.RegionalManagerSign == ''){
              this.regionalManagerSign='|';
            }else{
              this.regionalManagerSign = this.factoryCutObject.RegionalManagerSign;
            }
          }
          this.getFactoryCutDetail();
        },
        (err) => {
          const endDate = this.events.find(
            (evnt) => evnt.StartAt === this.selectedDate
          );
          const index = this.events.indexOf(endDate);
          let startDate: Date;
          if (index >= this.events.length - 1) {
            startDate = endDate.StartAt;
          } else {
            startDate = this.events[index + 1].EndAt;
          }
          this.inventoryCutServ
            .getCeveActive(this.factory.id)
            .toPromise()
            .then((isActive) => {
              if (isActive) {
                if (!this.isDateTimeRangeValid) {
                  Swal.fire({
                    icon: 'warning',
                    title:
                      'La captura de inventario no está disponible en este momento',
                  });
                  this.searching = false;
                  return;
                } else {
                  //Mensaje de espera
                  Swal.fire({
                    title: 'Actualizando información...',
                    html: 'Por favor espere',
                    didOpen: () => {
                      Swal.showLoading();
                    },
                  });

                  this.inventoryCutServ
                    .generateFactoryCutSP(
                      this.factory.id,
                      this.datepipe.transform(startDate, 'yyyy-MM-ddTHH:mm'),
                      this.datepipe.transform(
                        this.selectedDate,
                        'yyyy-MM-ddTHH:mm'
                      )
                    )
                    .toPromise()
                    .then((resp) => {
                      Swal.close();
                      this.searching = false;
                      this.searchInventory();
                    })
                    .catch((respInvErr: HttpErrorResponse) => {
                      Swal.fire({
                        icon: 'error',
                        title: 'No se pudo obtener la carátula',
                      });
                      this.searching = false;
                      console.log(respInvErr);
                      return;
                    });
                }
              } else {
                Swal.fire({
                  icon: 'error',
                  title: `El nodo seleccionado no se encuentra activo.`,
                });
                this.searching = false;
                return;
              }
            });
        }
      );
  }

  processCeveCut(
    ceveCut: any[],
    containerId: number,
    listToAdd: item[],
    line: number
  ): void {
    const lineDetail: any = ceveCut.filter((dtl) => dtl.key === line)[0].detail;
    const containerExists: FactoryCutDetail = lineDetail.find(
      (dtl) => dtl.ContainerId === containerId
    );
    if (containerExists) {
      listToAdd.push({
        containerId: containerId,
        quantity: containerExists.Quantity,
      });
    } else {
      listToAdd.push({
        containerId: containerId,
        quantity: 0,
      });
    }
  }

  /**
   * Gets the factory cut detail
   *
   * @memberof InventoryFactoryComponent
   */
  getFactoryCutDetail(): void {
    this.inventoryCutServ
      .getFactoryCutDetail(this.factoryCutObject.FactoryCutId)
      .subscribe(
        (factoryCutDetail: FactoryCutDetail[]) => {
          console.log(factoryCutDetail);
          this.containers.map((cnt) => {
           
            this.createLineList(
              cnt.ContainerId,
              FactoryCutLine.PREVIOUS,
              factoryCutDetail,
              this.previousInventory
            );
            this.createLineList(
              cnt.ContainerId,
              FactoryCutLine.PURCHASES,
              factoryCutDetail,
              this.purchases
            );
            this.createLineList(
              cnt.ContainerId,
              FactoryCutLine.RECYCLING,
              factoryCutDetail,
              this.recycling
            );
            this.createLineList(
              cnt.ContainerId,
              FactoryCutLine.OTHERS,
              factoryCutDetail,
              this.other
            );
            this.createLineList(
              cnt.ContainerId,
              FactoryCutLine.RECOVERY,
              factoryCutDetail,
              this.recovery
            );
            this.createLineList(
              cnt.ContainerId,
              FactoryCutLine.MISCELLANEOUS,
              factoryCutDetail,
              this.miscellaneous
            );
            this.createLineList(
              cnt.ContainerId,
              FactoryCutLine.ADJUSTFAB,
              factoryCutDetail,
              this.adjustFab
            );
            this.createLineList(
              cnt.ContainerId,
              FactoryCutLine.TOTALDIFFERENCES,
              factoryCutDetail,
              this.totalDifferences
            );
            this.createLineList(
              cnt.ContainerId,
              FactoryCutLine.THEORIC,
              factoryCutDetail,
              this.theoricInventory
            );
            this.createLineList(
              cnt.ContainerId,
              FactoryCutLine.PHYSICAL,
              factoryCutDetail,
              this.physicalInventory
            );
            this.createLineList(
              cnt.ContainerId,
              FactoryCutLine.TRANSIT,
              factoryCutDetail,
              this.transitInventory
            );
            this.createLineList(
              cnt.ContainerId,
              FactoryCutLine.CEVE,
              factoryCutDetail,
              this.ceveRelation
            );
            this.createLineList(
              cnt.ContainerId,
              FactoryCutLine.FACTORY,
              factoryCutDetail,
              this.factoryRelation
            );
            this.createLineList(
              cnt.ContainerId,
              FactoryCutLine.REAL,
              factoryCutDetail,
              this.realInventory
            );
            this.createLineList(
              cnt.ContainerId,
              FactoryCutLine.TOTALBALANCE,
              factoryCutDetail,
              this.totalBalance
            );
            this.createLineList(
              cnt.ContainerId,
              FactoryCutLine.DIFFERENCE,
              factoryCutDetail,
              this.difference
            );
            this.createLineList(
              cnt.ContainerId,
              FactoryCutLine.PERCENTAGE,
              factoryCutDetail,
              this.percentage
            );
          });
          this.isRegionManagerSign =
            this.factoryCutObject.RegionalManagerSign != '';
          this.isSupervisorSign = this.factoryCutObject.SupervisorSign != '';
          this.isManagerSign = this.factoryCutObject.ManagerSign != '';
          this.searching = false;
          this.dataReady = true;
          if (
            this.factoryCutObject.Status == 'CLOSED' &&
            this.isRegionManagerSign &&
            this.isSupervisorSign &&
            this.isManagerSign &&
            this.isDateTimeRangeValid
          ) {
            this.publish();
          }
          this.outOfTime();
        },
        (err) => {
          this.globalFunctionService.messageToast(`Ocurrió un error al obtener el corte de inventario para la planta seleccionada.`, 'warning');
          this.searching = false;
        }
      );
  }

  /**
   * Creates lists for every single line in DOM Table
   *
   * @param {number} containerId - Container id to add to list
   * @param {string} line - Line to add the container
   * @param {FactoryCutDetail[]} detail - Detail list where get the item
   * @param {item[]} listToAdd - list to add the item
   * @memberof InventoryFactoryComponent
   */
  createLineList(
    containerId: number,
    line: number,
    detail: FactoryCutDetail[],
    listToAdd: item[]
  ): void {
    const containerExists: FactoryCutDetail = detail.find(
      (dtl) => dtl.Line === line && dtl.ContainerId === containerId
    );
    let physical: item;
    if (line === FactoryCutLine.REAL) {
      physical = this.physicalInventory.find(
        (dtl) => dtl.containerId === containerId
      );
    }
    if (containerExists) {
      listToAdd.push({
        containerId: containerId,
        quantity:
          line === FactoryCutLine.REAL
            ? containerExists.Quantity - physical.quantity
            : containerExists.Quantity,
      });
    } else {
      listToAdd.push({
        containerId: containerId,
        quantity: line === FactoryCutLine.REAL ? 0 - physical.quantity : 0,
      });
    }
  }

  /**
   * triggers when user select some result on autocomplete and assign this value to factory variable
   *
   * @param {*} event
   * @memberof InventoryFactoryComponent
   */
  selectFactory(event: any): void {
    this.factory = event;
    if(this.factory != undefined){
      this.factoryTitle = this.factory.name;
      let name = this.factoryTitle.toString().split('-')[1];
      if(name.length > 0){
        this.factoryTitle = name;
      }
      this.factoryTitle = this.factoryTitle.toString().replace('BIM','');
      this.factoryTitle = this.factoryTitle.toString().replace('PANADERIA','');
      this.factoryTitle = this.factoryTitle.toString().replace('CENTRO DE DISTRIBUCIÓN','CEDIS');
      this.factoryTitle = this.factoryTitle.toString().trim();
      //console.log(this.factoryTitle);
    }
    this.inventoryCutServ
      .getFactoryContainerListById(this.factory.id)
      .subscribe((x) => {
        this.containers = x;
        console.log(this.containers);
        console.log(this.factory);
        this.unitSelected = this.factory.id;
      });
  }

  /**
   * Gets a factory list searching by an argument provided by the user
   *
   * @param {*} event
   * @memberof InventoryFactoryComponent
   */
  getFactoryByArgument(event: any): void {
    this.isLoadingFactory = true;
    this.remissionServ.getFactoryListByArgument(event, this.user.Id).subscribe(
      (factoryList: Factory[]) => {
        if (factoryList !== null) {
          this.factoryList = factoryList.map((factory) => {
            this.unitSelected = factory.UnitId;
            return {
              id: factory.UnitId,
              name: `${factory.FactoryRef} - ${factory.FactoryName}`,
            };
          });
        }
        this.isLoadingFactory = false;
      },
      (err) => {
        this.globalFunctionService.messageToast(`Ocurrió un error al búscar las fábricas.`, 'warning');
        this.isLoadingFactory = false;
      }
    );
  }

  /**
   * Gets all factory inventory, defines the start date and invoke methods to clear an create list to fill the DOM table
   *
   * @memberof InventoryFactoryComponent
   */
  async searchInventory(): Promise<void> {
    this.searching = true;
    await this.updateTime();
    if (
      (await this.getCentralTime()) < new Date(this.selectedDate) &&
      this.selectedDate == this.lastEvent.StartAt
    ) {
      Swal.fire({
        icon: 'warning',
        title: `La captura de inventario estará disponible hasta el dia ${
          this.lastEvent.StartAt.toString().split('T')[0]
        } a las ${this.lastEvent.StartAt.toString().split('T')[1]}`,
      });
      this.searching = false;
      return;
    }
    this.factoryCutObject = new FactoryCut();
    this.isLastCutDate = false;
    if (this.selectedDate == this.lastEvent.StartAt) {
      this.isLastCutDate = true;
    }
    this.actualDateTime = this.datepipe.transform(
      await this.getCentralTime(),
      'yyyy-MM-ddTHH:mm:ss'
    );
    if (
      this.actualDateTime >= this.lastEvent.StartAt.toString() &&
      this.actualDateTime <= this.lastEvent.EndAt.toString() &&
      this.isLastCutDate
    ) {
      this.isDateTimeRangeValid = true;
    } else {
      this.isDateTimeRangeValid = false;
    }
    var selectedDateIndex = this.events.indexOf(
      this.events.find((x) => x.StartAt == this.selectedDate)
    );
    this.previousDate =
      selectedDateIndex >= this.events.length - 1
        ? (this.datepipe.transform(this.inventoryCutServ.getPreviousDate(new Date(this.events[selectedDateIndex].StartAt)), 'yyyy-MM-ddTHH:mm'))
        : this.events[selectedDateIndex + 1].StartAt.toString();
    this.dataReady = false;
    this.factoryCutObject = new FactoryCut();
    this.factoryCutObject.FactoryCutId = '';
    this.factoryCutObject.Status = 'NEW';
    this.getFactoryCutByDay();
  }

  /**
   * Calculates section 18
   *
   * @param {number} periodicDifference - periodic difference
   * @param {number} theoricInventory - theoric Inventory
   * @return {*}  {number} - (Periodic difference / theoric Inventory) * 100
   * @memberof InventoryFactoryComponent
   */
  getPercentDifference(
    periodicDifference: number,
    theoricInventory: number
  ): number {
    if (theoricInventory === 0) {
      return 0;
    }
    return (periodicDifference / theoricInventory) * 100;
  }

  /**
   * Clear all list to fill the DOM Table
   *
   * @memberof InventoryFactoryComponent
   */
  clearLists(): void {
    this.previousInventory = [];
    this.purchases = [];
    this.recycling = [];
    this.other = [];
    this.recovery = [];
    this.theoricInventory = [];
    this.physicalInventory = [];
    this.realInventory = [];
    this.ceveRelation = [];
    this.factoryRelation = [];
    this.transitInventory = [];
    this.totalBalance = [];
    this.difference = [];
    this.percentage = [];
    this.miscellaneous = [];
    this.adjustFab = [];
    this.totalDifferences = [];
  }

  /**
   * Validates if is a new factory, if is new invokes saveSigns() method, otherwhise invokes removeFactoryCut() Method
   *
   * @memberof InventoryFactoryComponent
   */
  async saveCut(close: boolean = false): Promise<void> {
    await this.updateTime();
    this.working = true;
    if (
      close &&
      this.factoryCutObject.Status != 'CLOSED' &&
      this.factoryCutObject.Status != 'NEW'
    ) {
      const confirm = await this.warningApply(
        `¿Desea cerrar el corte? El registro no podrá ser editado.`
      );
      if (!confirm) {
        Swal.close();
        this.working = false;
        return;
      }
    }
    if (!close && this.factoryCutObject.Status != 'NEW') {
      const confirm = await this.warningApply(`¿Desea guardar el corte?`);
      if (!confirm) {
        Swal.close();
        this.working = false;
        return;
      }
    }
    this.showLoading();
    this.saveSigns(close);
  }

  async publish() {
    await this.updateTime();
    this.generateCut(this.lastEvent.EndAt);
    // if(this.timedOut()){return;}
    this.inventoryCutServ
      .publishFactoryCut(this.factoryCutObject.FactoryCutId)
      .toPromise()
      .then((x) => {
        this.addMovementForInventoryCut();
      })
      .catch((error: HttpErrorResponse) => {
        Swal.close();
        this.globalFunctionService.messageToast(`No se pudo publicar el inventario.`, 'error');
        console.log(error.error);
        this.working = false;
        this.searchInventory();
      });
  }

  /**
   * Shows a warning message to user whit OK and Cancell  options
   *
   * @return {*}  {Promise<boolean>} - User action selected
   * @memberof InventoryFactoryComponent
   */
  async warningApply(message: string): Promise<boolean> {
    const result = await Swal.fire({
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> &nbsp; Aceptar',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i> &nbsp; Cancelar',
      backdrop: `rgba(0,157,210,0.5)`,
    });
    return result.isConfirmed;
  }

  async saveSignsRemove(sign: string) {
    this.working = true;
    // if(this.timedOut()){return;}
    const confirm = await this.warningApply(
      `¿Desea firmar el corte? Al ingresar la tercer firma el corte será publicado.`
    );
    if (!confirm) {
      Swal.close();
      this.working = false;
      return;
    }
    this.showLoading();
    await this.updateTime();
    // this.inventoryCutServ.removeFactoryCut(this.factoryCutObject.FactoryCutId).subscribe(() => {
    this.saveSigns(true, sign);
    // });
  }

  async saveSigns(close: boolean, sign: string = '') {
    var date = await this.getCentralTime();
    this.factoryCut = [];
    var signInfo = `${this.user.Name}|${
      this.user.Email != '' ? this.user.Email : ''
    }|Fecha: ${this.datepipe.transform(this.selectedDate, 'yyyy-MM-dd')}`;

    this.generateCut(date);
    this.saveFactoryCut(date, close, [sign, signInfo]);
  }

  /**
   * Saves the actual factory cut object in database
   *
   * @param {Date} date - Date of factiry cut
   * @param {any[]} [resp=[]] - Signs response optional
   * @memberof InventoryFactoryComponent
   */
  async saveFactoryCut(date: Date, close: boolean, resp: any[] = []) {
    (this.factoryCutObject.Org = this.user.Org),
      (this.factoryCutObject.UnitId = this.factory.id),
      (this.factoryCutObject.FactoryCutDate = this.selectedDate),
      (this.factoryCutObject.SupervisorSign = this.factoryCutObject
        .SupervisorSign
        ? this.factoryCutObject.SupervisorSign
        : '');
    this.factoryCutObject.ManagerSign = this.factoryCutObject.ManagerSign
      ? this.factoryCutObject.ManagerSign
      : '';
    this.factoryCutObject.RegionalManagerSign = this.factoryCutObject
      .RegionalManagerSign
      ? this.factoryCutObject.RegionalManagerSign
      : '';
    (this.factoryCutObject.Status = close ? 'CLOSED' : 'SAVED'),
      (this.factoryCutObject.Observation = this.factoryCutObject.Observation
        ? this.factoryCutObject.Observation
        : ''),
      (this.factoryCutObject.CreatedBy = this.user.Name),
      (this.factoryCutObject.CreatedAt = new Date(this.selectedDate));
    (this.factoryCutObject.ModifiedBy = this.user.Name),
      (this.factoryCutObject.ModifiedAt = await this.getCentralTime());
    const factoryCutDetail = this.factoryCut;
    const factoryCut = this.factoryCutObject;
    if (resp[0] == 'isSupervisorSign') {
      this.factoryCutObject.SupervisorSign = resp[1];
    } else if (resp[0] == 'isManagerSign') {
      this.factoryCutObject.ManagerSign = resp[1];
    } else if (resp[0] == 'isRegionManagerSign') {
      this.factoryCutObject.RegionalManagerSign = resp[1];
    }
    this.inventoryCutServ
      .addFactoryCut({ factoryCut, factoryCutDetail })
      .subscribe(
        () => {
          this.working = false;
          Swal.close();
          this.globalFunctionService.messageToast(`Corte de fábrica guardado correctamente.`, 'success');
          this.searchInventory();
        },
        (err: HttpErrorResponse) => {
          Swal.close();
          this.globalFunctionService.messageToast(`No se pudo guardar el inventario.`, 'error');
          console.log(err.error);
          this.working = false;
          this.searchInventory();
          console.log(err);
        }
      );
  }

  /**
   * Generates factory cut lists to add to database
   *
   * @memberof InventoryFactoryComponent
   */

  generateCut(date: Date): void {
    this.factoryCut = [];
    let previousList: FactoryCutDetail[] = [];
    this.generateCutList(
      this.previousInventory,
      previousList,
      FactoryCutLine.PREVIOUS,
      date
    );

    let purchaseList: FactoryCutDetail[] = [];
    this.generateCutList(
      this.purchases,
      purchaseList,
      FactoryCutLine.PURCHASES,
      date
    );

    let recyclingList: FactoryCutDetail[] = [];
    this.generateCutList(
      this.recycling,
      recyclingList,
      FactoryCutLine.RECYCLING,
      date
    );

    let otherList: FactoryCutDetail[] = [];
    this.generateCutList(this.other, otherList, FactoryCutLine.OTHERS, date);

    let recoveryList: FactoryCutDetail[] = [];
    this.generateCutList(
      this.recovery,
      recoveryList,
      FactoryCutLine.RECOVERY,
      date
    );

    let miscellaneous: FactoryCutDetail[] = [];
    this.generateCutList(
      this.miscellaneous,
      miscellaneous,
      FactoryCutLine.MISCELLANEOUS,
      date
    );
    let adjustFab: FactoryCutDetail[] = [];
    this.generateCutList(
      this.adjustFab,
      adjustFab,
      FactoryCutLine.ADJUSTFAB,
      date
    );
    let totalDifferences: FactoryCutDetail[] = [];
    this.generateCutList(
      this.totalDifferences,
      totalDifferences,
      FactoryCutLine.TOTALDIFFERENCES,
      date
    );

    let theoricList: FactoryCutDetail[] = [];
    this.generateCutList(
      this.theoricInventory,
      theoricList,
      FactoryCutLine.THEORIC,
      date
    );

    let physicalList: FactoryCutDetail[] = [];
    this.generateCutList(
      this.physicalInventory,
      physicalList,
      FactoryCutLine.PHYSICAL,
      date
    );

    let transitList: FactoryCutDetail[] = [];
    this.generateCutList(
      this.transitInventory,
      transitList,
      FactoryCutLine.TRANSIT,
      date
    );

    let ceveList: FactoryCutDetail[] = [];
    this.generateCutList(
      this.ceveRelation,
      ceveList,
      FactoryCutLine.CEVE,
      date
    );

    let factoryList: FactoryCutDetail[] = [];
    this.generateCutList(
      this.factoryRelation,
      factoryList,
      FactoryCutLine.FACTORY,
      date
    );

    let realList: FactoryCutDetail[] = [];
    this.generateCutList(
      this.transitInventory,
      realList,
      FactoryCutLine.REAL,
      date
    );

    let totalBalance: FactoryCutDetail[] = [];
    this.generateCutList(
      this.totalBalance,
      totalBalance,
      FactoryCutLine.TOTALBALANCE,
      date
    );

    let difference: FactoryCutDetail[] = [];
    this.generateCutList(
      this.transitInventory,
      difference,
      FactoryCutLine.DIFFERENCE,
      date
    );

    let percentage: FactoryCutDetail[] = [];
    this.generateCutList(
      this.transitInventory,
      percentage,
      FactoryCutLine.PERCENTAGE,
      date
    );
  }

  /**
   * Creates a factory detail list whit a desired line
   *
   * @param {item[]} listFrom - List to get the detail
   * @param {FactoryCutDetail[]} listTo - List to add the detail
   * @param {string} line - Line to assign the detail
   * @param {Date} date - Date to add in control attributes
   * @memberof InventoryFactoryComponent
   */
  generateCutList(
    listFrom: item[],
    listTo: FactoryCutDetail[],
    line: number,
    date: Date
  ): void {
    let index = 0;
    listFrom.map((item) => {
      const itemCut: FactoryCutDetail = {
        FactoryCutId: '',
        Line: line,
        ContainerId: item.containerId,
        Quantity:
          line === FactoryCutLine.REAL
            ? item.quantity + this.physicalInventory[index].quantity
            : line === FactoryCutLine.DIFFERENCE
            ? this.physicalInventory[index].quantity +
              item.quantity -
              this.theoricInventory[index].quantity
            : line === FactoryCutLine.PERCENTAGE
            ? Math.round(
                Number(
                  this.getPercentDifference(
                    this.physicalInventory[index].quantity +
                      item.quantity -
                      this.theoricInventory[index].quantity,
                    this.theoricInventory[index].quantity
                  ).toFixed(1)
                ) * 100
              )
            : item.quantity,
        CreatedBy: this.user.Name,
        CreatedAt: date,
        ModifiedBy: this.user.Name,
        ModifiedAt: date,
      };
      listTo.push(itemCut);
      index++;
    });
    this.factoryCut.push(listTo);
  }

  addMovementForInventoryCut() {
    var positiveMovements: Array<InventoryMovementDetail> = [];
    var negativeMovements: Array<InventoryMovementDetail> = [];
    var movementsList: Array<movObj> = [];
    let date = this.datepipe.transform(this.selectedDate, 'yyyy-MM-dd');
    for (let index = 0; index < this.physicalInventory.length; index++) {
      if (
        this.realInventory[index].quantity -
          this.theoricInventory[index].quantity +
          this.physicalInventory[index].quantity >
        0
      ) {
        var newDetail: InventoryMovementDetail = new InventoryMovementDetail();
        newDetail.Org = this.user.Org;
        newDetail.MovementId = '';
        newDetail.MovementType = 'AJUST';
        newDetail.MoveDate = date;
        newDetail.ConteinerId =
          this.physicalInventory[index].containerId.toString();
        newDetail.ConteinerType = 'EV';
        newDetail.Quantity =
          this.realInventory[index].quantity -
          this.theoricInventory[index].quantity +
          this.physicalInventory[index].quantity;
        newDetail.CreatedBy = this.user.Name;
        newDetail.ModifiedBy = this.user.Name;
        positiveMovements.push(newDetail);
      } else if (
        this.realInventory[index].quantity -
          this.theoricInventory[index].quantity +
          this.physicalInventory[index].quantity <
        0
      ) {
        var newDetail: InventoryMovementDetail = new InventoryMovementDetail();
        newDetail.Org = this.user.Org;
        newDetail.MovementId = '';
        newDetail.MovementType = 'AJUST';
        newDetail.MoveDate = date;
        newDetail.ConteinerId =
          this.physicalInventory[index].containerId.toString();
        newDetail.ConteinerType = 'EV';
        newDetail.Quantity =
          (this.realInventory[index].quantity -
            this.theoricInventory[index].quantity +
            this.physicalInventory[index].quantity) *
          -1;
        newDetail.CreatedBy = this.user.Name;
        newDetail.ModifiedBy = this.user.Name;
        negativeMovements.push(newDetail);
      }
    }

    if (positiveMovements.length > 0) {
      var posHeader = new InventoryMovement();
      posHeader.Org = this.user.Org;
      posHeader.MovementId = '';
      posHeader.MoveDate = date;
      posHeader.OutputUnit = 0;
      posHeader.InputUnit = this.factory.id;
      posHeader.MovementType = 'AJUST';
      posHeader.Applied = false;
      posHeader.Reference = this.factoryCutObject.FactoryCutId;
      posHeader.MoveInfo = 'Alta ajuste de inventario PLANTA';
      posHeader.CreatedBy = this.user.Name;
      posHeader.ModifiedBy = this.user.Name;

      let posMov: movObj = {
        Movement: posHeader,
        Details: positiveMovements,
      };
      movementsList.push(posMov);
    }

    if (negativeMovements.length > 0) {
      var negHeader = new InventoryMovement();
      negHeader.Org = this.user.Org;
      negHeader.MovementId = '';
      negHeader.MoveDate = date;
      negHeader.OutputUnit = this.factory.id;
      negHeader.InputUnit = 0;
      negHeader.MovementType = 'AJUST';
      negHeader.Applied = false;
      negHeader.Reference = this.factoryCutObject.FactoryCutId;
      negHeader.MoveInfo = 'Baja ajuste de inventario PLANTA';
      negHeader.CreatedBy = this.user.Name;
      negHeader.ModifiedBy = this.user.Name;
      let negMov: movObj = {
        Movement: negHeader,
        Details: negativeMovements,
      };
      movementsList.push(negMov);
    }
    this.inventoryCutServ
      .addMovementForInventoryCut(this.factory.id, date, movementsList)
      .toPromise()
      .then(() => {
        this.globalFunctionService.messageToast(`Carátula de inventario guardada.`, 'success');
        this.searchInventory();
      })
      .catch((error: HttpErrorResponse) => {
        this.globalFunctionService.messageToast(`No se pudo publicar el inventario.`, 'error');
        console.log(error);
      });
  }

  async openFactoryCut() {
    this.working = true;
    const confirm = await this.warningApply(
      `¿Desea reabrir el corte? El registro podrá ser editado y las firmas ingresadas se eliminarán.`
    );
    if (!confirm) {
      Swal.close();
      this.working = false;
      return;
    }
    this.showLoading();
    await this.updateTime();
    // if(this.timedOut()){return;}
    this.inventoryCutServ
      .openFactoryCut(this.factoryCutObject.FactoryCutId)
      .toPromise()
      .then((x) => {
        this.searchInventory();
      })
      .catch((error: HttpErrorResponse) => {
        Swal.close();
        this.globalFunctionService.messageToast(`No se pudo abrir el inventario.`, 'error');
        console.log(error.error);
        this.working = false;
        this.searchInventory();
      })
      .finally(() => {
        this.working = false;
        Swal.close();
      });
  }

  print(): void {
    window.print();
  }

  async outOfTime() {
    await this.updateTime();
    if (
      (await this.getCentralTime()) > this.lastEvent.EndAt &&
      this.factoryCutObject.Status == 'LAPSED'
    ) {
      Swal.fire({
        icon: 'warning',
        title: 'La carátula ha caducado.',
        text:
          'El límite para la captura de inventario fue hasta el día y hora: ' +
          this.lastEvent.EndAt.toString(),
      });
    }
  }

  async updateTime() {
    this.actualDateTime = this.datepipe.transform(
      await this.getCentralTime(),
      'yyyy-MM-ddTHH:mm:ss'
    );
  }

  showLoading() {
    Swal.fire({
      title: 'En proceso...',
      html: 'Por favor espere',
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }
}

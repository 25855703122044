<div class="content-heading">
  <div class="header-container">
    <h3>Reporte de Inventario en Tr&aacute;nsito</h3>
    <small>Reporte Web</small>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row">
            <!--Filtros-->
            <div class="mt-3 col-lg-4 col-md-4">
              <span>Regi&oacute;n</span>
              <select class="form-control" [(ngModel)]="unit" (change)="applyFilters($event.target.value)" required>
                <!--<option [value]="0">TODOS</option>-->
                <option *ngFor="let unit of regionList" [value]="unit.UnitId">{{unit.RegionName}}</option>
              </select>
            </div>
            <div class="mt-3 col-lg-4 col-md-4">
              <span>Clasificaci&oacute;n</span>
              <select class="form-control" [(ngModel)]="type" (change)="cmbUnitChange($event.target.value)" required>
                <option [value]="0" selected="selected">PLANTA</option>
                <option [value]="1">CEVE</option>
                <!--<option [value]="2">AUTOSERVICIO</option>-->
              </select>
            </div>
            <div class="mt-3 col-lg-4 col-md-4">
              <span>Nodo:</span>
              <ng-autocomplete [initialValue]=""
                               [(ngModel)]="selectedOriginUnitName"
                               [searchKeyword]="'UnitDescription'"
                               [data]="nodeList"
                               [placeholder]="'Seleccione una opción'"
                               (selected)='onOriginUnitChange($event)'
                               (inputCleared)="onOriginUnitCleared()"
                               [itemTemplate]="itemTemplate"
                               [notFoundTemplate]="notFoundTemplate"
                               [disabled]="false">
              </ng-autocomplete>

              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.UnitDescription"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="'No se encontraron coincidencias'"></div>
              </ng-template>
            </div>
          </div>
          <br />
          <div class="row">
            <!--Grid-->
            <div class="col-md-12" hidden>
              <app-ceq-table [gridAdditionalOptions]="gridOptions" [columnDef]="columnDefs" (apiEmitter)="gridEmiter($event)" #CeqGrid>
              </app-ceq-table>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-12">
              <!--Buttons-->
              <button class="mr-1  btn btn-labeled btn-outline-success mb-2" [disabled]="downloading" (click)="download()">
                <span class="btn-label">
                  <i class="fa fa-file-excel" aria-hidden="true" *ngIf="!downloading"></i>
                  <i class="fa fa-circle-notch fa-spin" *ngIf="downloading"></i>
                </span>
                <span>Descargar reporte</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


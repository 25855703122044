<div class="content-heading">
    <div class="header-container">
        <h3>Consulta de Flujos de Moldes</h3>
        <small>Posición entre nodos</small>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="card card-default">
                <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 col-xs-12">
                        <div class="row filter-container">
                          <div class="col-sm-4">
                            <span>Nodo A</span>
                            <div class="ng-autocomplete w-80">
                              <ng-autocomplete placeholder="Búscar nodo A" [data]="nodesA" [searchKeyword]="nameNode" (selected)='changeFilterLevel($event)' [itemTemplate]="itemOutputTemplate" [notFoundTemplate]="notFoundoutputTemplate" (inputCleared)="clearNodeA()">
                              </ng-autocomplete>
                            </div>
                            <ng-template #itemOutputTemplate let-item>
                              <a [innerHTML]="item.name"></a>
                            </ng-template>

                            <ng-template #notFoundoutputTemplate let-notFound>
                              <div [innerHTML]="'No se encontraron coincidencias'"></div>
                            </ng-template>
                          </div>
                          <div class="col-sm-4">
                            <span>Nodo B</span>
                            <div class="ng-autocomplete w-80">
                              <ng-autocomplete placeholder="Búscar nodo B" [data]="nodesB" [searchKeyword]="nameNode" (selected)='changeFilterLevelB($event)' [itemTemplate]="itemOutputTemplate" [notFoundTemplate]="notFoundoutputTemplate" (inputCleared)="clearNodeB()">
                              </ng-autocomplete>
                            </div>
                            <ng-template #itemOutputTemplate let-item>
                              <a [innerHTML]="item.name"></a>
                            </ng-template>

                            <ng-template #notFoundoutputTemplate let-notFound>
                              <div [innerHTML]="'No se encontraron coincidencias'"></div>
                            </ng-template>
                          </div>
                        </div>
                        <br *ngIf="balance || loading">
                        <div style="display: flex; justify-content: center; align-items: center;" *ngIf="loading">
                          <i class="fa fa-circle-o-notch fa-spin fa-2x"></i>&nbsp;&nbsp;&nbsp;<span>Cargando flujo entre nodos</span>
                        </div>
                        <div class="row" *ngIf="balance && selectedLevelA && selectedLevelB && !this.loading">
                          <div class="col col-sm-3">
                            <div class="header-container" style="color:#929292">
                              <h4>Flujo entre nodos</h4>
                              <span>Positivos a favor de {{selectedLevelA.UnitDescription}}</span>
                              <br>
                              <span>Negativos a favor de {{selectedLevelB.UnitDescription}}</span>
                            </div>
                          </div>
                          <div class="col col-sm-9">
                            <div class="d-flex justify-content-center" style="color:#929292" *ngIf="balance.length === 0">
                              <div class="header-container">
                                <h3>No existe flujo entre los nodos seleccionados</h3>
                              </div>
                            </div>
                            <div class="cell-container d-flex" *ngIf="balance.length > 0">
                              
                              <div class="cell" *ngFor="let balance of balance">
                                <div class="cell-header"><b>{{getContainerCode(balance.ConteinerId)}}/{{getContainerType(balance.ConteinerId,balance.ContainerTypeId)}} </b></div>
                                <div class="cell-info">{{balance.Balance}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="balance && selectedLevelA && selectedLevelB && !this.loading">
                          <br><br>
                          <i>
                            <i class="fas fa-info-circle text-primary"> </i>
                            El flujo mostrado se constituye por: remisiones, faltantes, sobrantes y traspasos generados entre ambos nodos. Para ver detalles, consultar reporte de Movimientos<br />
                            Puede estar afectado por ajustes planificados (reseteos, acumulados, otros). Para más información, solicitar a ON.
                          </i>
                        </div>

                        <hr>
                        <div class="d-flex justify-content-center" style="color:#929292" *ngIf="balance">
                          <div class="header-container">
                            <h3>Consulta de Remisiones</h3>
                          </div>
                        </div>
                        <div class="row" *ngIf="balance">
                          <div class="col-sm-12">
                            <br>
                            <i>
                              <i class="fas fa-info-circle text-primary"> </i>
                              Cantidades originales de cada remisión. No inclluye ajustes por Faltantes o Sobrantes<br /><br />
                            </i>
                          </div>
                        </div>
                        <div class="row" *ngIf="balance">
                          <div class="col-sm-4">
                            Fecha Inicial
                            <input type="date" class="form-control w-80" maxlength="20" [ngModel]="InitialDate | date:'yyyy-MM-dd'" [ngModelOptions]="{standalone: true}" (ngModelChange)="InitialDate=$event" [max]="FinalDate ? (FinalDate | date:'yyyy-MM-dd') : (actualDate | date:'yyyy-MM-dd')" required>
                          </div>
                          <div class="col-sm-4">
                            Fecha Final
                            <input type="date" class="form-control w-80" maxlength="20" [ngModel]="FinalDate | date:'yyyy-MM-dd'" [ngModelOptions]="{standalone: true}" (ngModelChange)="FinalDate=$event" [max]="actualDate | date:'yyyy-MM-dd'" required>
                          </div>

                          <div class="col-sm-4">
                            <br>
                            <button type="button" class="btn btn-outline-primary" (click)="btnClick()" [disabled]="balance.length === 0">
                              <i class="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;
                              Consultar
                            </button>
                          </div>
                        </div>
                        <br>
                        <app-loading *ngIf="searchingDetail"></app-loading>
                        <div *ngIf="!searchingDetail">
                          <div class="table-balance-between-nodes" *ngIf="(dataList.length > 0 || dataListB.length > 0) && selectedLevelA && selectedLevelB">
                            <div class="full-header">BALANCE DE EQUIPOS ENTRE NODOS</div>
                            <div class="balance-between-detail">
                              <div class="name-nodes-container">
                                <div class="header-cell w-100" style="height: 3em; border-bottom: 2px solid rgb(168, 168, 168);">
                                  POSITIVOS A FAVOR {{selectedLevelA.UnitDescription}}
                                </div>
                                <div class="header-cell w-100" style="height: 3em; border-right: 2px solid rgb(168, 168, 168);">
                                  NEGATIVOS A FAVOR {{selectedLevelB.UnitDescription}}
                                </div>
                              </div>
                              <div class="cell-container">
                                <div class="cell1" *ngFor="let container of containersSet; let i = index;">
                                  <div class="cell-header1">{{getContainerCode(container.containerId)}}</div>
                                  <div class="cell-data">{{container.total - containersSetB[i].total}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br>
                          <div class="data-container" *ngIf="dataList.length > 0">
                            <div class="unit-container">
                              Envios de {{selectedLevelA.UnitDescription}} a {{selectedLevelB.UnitDescription}}
                            </div>
                            <table class="table-container">
                              <thead>
                                <tr>
                                  <th class="cell-header2">
                                    Fecha
                                  </th>
                                  <th class="cell-header2">
                                    Remisi&oacute;n
                                  </th>
                                  <th class="cell-header2" *ngFor="let balance of balance">
                                    {{getContainerCode(balance.ConteinerId)}}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let data of dataList;let od = odd;let ev = even;" [ngClass]="{odd:od, even:ev}">
                                  <td class="cell-data">
                                    {{data.date | date:'yyyy-MM-dd'}}
                                  </td>
                                  <td class="cell-data">
                                    {{data.reference.split('|')[0]}}
                                  </td>
                                  <td class="cell-data" *ngFor="let item of data.items">
                                    {{item}}
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td class="cell-data">&nbsp;</td>
                                  <td class="cell-data"><b>TOTAL DE ENVIOS</b></td>
                                  <td class="cell-data" *ngFor="let container of containersSet">{{container.total}}</td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                          <br>
                          <div class="data-container" *ngIf="dataListB.length > 0">
                            <div class="unit-container">
                              Envios de {{selectedLevelB.UnitDescription}} a {{selectedLevelA.UnitDescription}}
                            </div>
                            <table class="table-container">
                              <thead>
                                <tr>
                                  <th class="cell-header2">
                                    Fecha
                                  </th>
                                  <th class="cell-header2">
                                    Remisi&oacute;n
                                  </th>
                                  <th class="cell-header2" *ngFor="let balance of balance">
                                    {{getContainerCode(balance.ConteinerId)}}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let data of dataListB;let od = odd;let ev = even;" [ngClass]="{odd:od, even:ev}">
                                  <td class="cell-data">
                                    {{data.date | date:'yyyy-MM-dd'}}
                                  </td>
                                  <td class="cell-data">
                                    {{data.reference.split('|')[0]}}
                                  </td>
                                  <td class="cell-data" *ngFor="let item of data.items">
                                    {{item}}
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td class="cell-data">&nbsp;</td>
                                  <td class="cell-data"><b>TOTAL DE ENVIOS</b></td>
                                  <td class="cell-data" *ngFor="let container of containersSetB">{{container.total}}</td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>

                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

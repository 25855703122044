import { Injectable } from '@angular/core'
import { GenericService } from '../common/services/GenericServices'
import { Observable, observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class MultichannelService {

  constructor(private genericService: GenericService) { }

  getRouteBalance(ceveId: number, channelId: number, routeId: number, containers: number[], date: string): Observable<any> {
    return this.genericService.postRequest(`multichannel/routebalance/${ceveId}/${channelId}/${routeId}/${date}`, {}, 0, containers)
  }

  getRouteBalanceResume(ceveId: number, date: string): Observable<BalanceResume> {
    return this.genericService.getRequest(`multichannel/routebalance/resume/${ceveId}/${date}`, {}, 0)
  }

  getRouteBalanceReport(ceveId: number, channelId: number, routeId: number, containers: number[], startDate: string, endDate: string): Observable<any> {
    return this.genericService.postExcelRequest(`report/multichannel/${ceveId}/${channelId}/${routeId}/${startDate}/${endDate}`, {}, 0, containers)
  }


}

export class BalanceResume {

  constructor() {
    this.saldo_final_qp = 0;
    this.inventario_dia_anterior = 0;
    this.cargo = 0;
    this.devolucion_rtm = 0;
    this.recoleccion_fisica = 0;
    this.diferencia_vendedor = 0;
    this.vale_vendedor = 0;
    this.recuperacion_vale = 0;
    this.recuperacion_vendedor = 0;
    this.no_controlado = 0;
    this.inventario_cliente = 0;
    this.saldo_final_qp = 0;
  }

  saldo_inicial_qp: number;
  inventario_dia_anterior: number;
  cargo: number;
  devolucion_rtm: number;
  recoleccion_fisica: number;
  diferencia_vendedor: number;
  vale_vendedor: number;
  recuperacion_vale: number;
  recuperacion_vendedor: number;
  no_controlado: number;
  inventario_cliente: number;
  saldo_final_qp: number;
}
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings/settings.service';

@Injectable()
export class MenuService {

    menuItems: Array<any>;

    constructor(private settingService: SettingsService ) {
        this.menuItems = [];
    }

    addMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        auth?: string,
        submenu?: Array<{text: string, link: string, auth: string }>
    }>) {

      if (this.menuItems.length > 0) {
        return;
      }
        const Perm =   this.settingService.getUserAccess();
    
        //console.log(Perm);
        items.forEach((item) => {
            let subm = item.submenu;
            let nsubm = Array<any>();
           
            if (subm != null)
            {
                subm.forEach((sm) =>
                {
                    //console.log("Menu : "+sm.text+ " Auth "+ sm.auth);
                    if (sm.auth != null && Perm != null)
                    {
                      //  console.log ("Include "+ sm.auth + " Perm"+Perm);

                        if (Perm.includes(sm.auth))
                        {
                            let it = {
                                text: sm.text,
                                link : sm.link,
                                auth : sm.auth,
                            };
                            nsubm.push(it);
                            //console.log ("Permitido");
                        }
                    }
                });
                item.submenu=nsubm;
            }
            
            // valida la seguridad para pintar el menu
            if (item.submenu  != null)
            {
                if (item.submenu.length>0)
                    this.menuItems.push(item);
            }
        });
    }

  getMenu() {
        //console.log('Obteniendo menus');
        return this.menuItems;
    }

}

import { BaseModel } from './BaseModel.Model';
export class Org extends BaseModel {
  UnitId: number;
  OrgCode: string;
  OrgName: string;
  OrgActive: boolean;
  Org: number;

  constructor() {
    super();
    this.UnitId = 0;
    this.OrgCode = "";
    this.OrgName = "";
    this.OrgActive = true;
  }
}


export class Orgs {
  org: number;
  name: string;
}

export class OrgByUser {
  org: number;
  userLogin: string;
  userName: string;
}

import { BaseModel } from './BaseModel.Model';
export class TransportLine extends BaseModel {
  constructor() {
    super();
    this.Org;
    this.TransportLineId;
    this.LineName;
    this.FiscalName;
    this.TransportNIT;
    this.TransportAddress;
  }

  Org: number;
  TransportLineId: number;
  LineName: string;
  FiscalName: string;
  TransportNIT: string;
  TransportAddress: string;
}

import { BaseModel } from "./BaseModel.Model";

export class CurrencyModel extends BaseModel {

  constructor() {
    super();
    this.org = 0;
    this.currencyId = 0;
    this.currencyCode = "";
    this.currencyName = "";
  }

  org: number;
  currencyId: number;
  currencyCode: string;
  currencyName: string;
}

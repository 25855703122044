import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Unit } from '../../common/models/Unit.Model';
import { EsabMovementsService } from '../../inventory/esab-movements/esab-movements.service';
import { UserLogin } from '../../common/models/UserLogin.Model';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { TrackerService } from '../tracker.service';
import { toNumber } from 'lodash';
import { TrackerEmployeeOP } from '../../common/models/TrackerEmployeeOP';

//openLayer Map
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import { OSM, Vector as VectorSource } from 'ol/source';
import { useGeographic } from 'ol/proj';
import Feature from 'ol/Feature.js';
import { Icon, Stroke, Style } from 'ol/style.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import { LineString, Point } from 'ol/geom';
import WebGLPointsLayer from 'ol/layer/WebGLPoints.js';
import Overlay from 'ol/Overlay.js';
import { toLonLat } from 'ol/proj.js';
import { toStringHDMS } from 'ol/coordinate.js';



@Component({
  selector: 'app-routes-map',
  templateUrl: './routes-map.component.html',
  styleUrls: ['./routes-map.component.scss']
})
export class RoutesMapComponent implements OnInit, OnDestroy {

  userLogin: UserLogin;

  //Unit Autocomplete
  inputArgument: string = 'UnitDescription';
  nodes: Unit[] = [];
  selectedUnit: Unit = new Unit();
  selectedUnitDescription: string;

  //Days filter
  selectedDay: string;

  //Employee filter
  employees: any[] = [];
  selectedEmployee: string;

  // map
  public map!: Map;
  loadingMap: boolean = false;
  zoom: number = 16;
  lat: number = 0;
  lng: number = 0;
  markers: any[] = [];
  points: number[][] = [];

  //timeline
  loadingTimeline: boolean = false;
  timeLineVisible: boolean;
  timelineAlt: boolean = true;
  timeLineCustomers: TrackerEmployeeOP[] = [];
  isAccOpen2 = false;

  //Autorefresh
  delay: number = 300000; // 5 minutes
  // delay: number = 10000; // 10 seconds
  intervalId: any;
  viewGmap: boolean = false;

  constructor(
    private settings: SettingsService,
    private services: EsabMovementsService,
    private trackerService: TrackerService,
  ) {
    //activa el uso de coordenadas geograficas en el mapa
    useGeographic();
  }

  ngOnInit(): void {
    this.selectedDay = new Date().toISOString().split('T')[0];
    this.userLogin = this.settings.getUser();
    this.initOpenLayerMap();
    this.getUserNodes();
    this.getLocation();
  }

  ngOnDestroy() {
    this.StopTimer();
  }

  getLocation() {
    if (navigator.geolocation) {
      this.loadingMap = true;
      navigator.geolocation.getCurrentPosition((position: any) => {
        console.log("position", position);
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          this.zoom = 15;
          this.loadingMap = false;
          this.centerMap(this.lng, this.lat);

          let iconFeature = new Feature({
            geometry: new Point([this.lng, this.lat]),
            name: 'Ubicación Actual',
            style: new Style({
              image: new Icon({
                anchor: [50, 50],
                anchorXUnits: 'pixels',
                anchorYUnits: 'pixels',
                color: "#f00",
                src: 'src/assets/img/logo_icon.png',
              }),
            })
          });
          this.pointSource.addFeature(iconFeature);

          iconFeature.addEventListener('click', function (e) {
            console.log(e);
          });
        }
      })
    }
  }

  //#region  Ceve autocomplete

  getUserNodes() {
    this.services
      .getUserNodes(this.userLogin.Org, this.userLogin.Id)
      .toPromise()
      .then((resp: Unit[]) => {
        resp.map((x) => {
          if (x.LevelType == 'CEVE') {
            this.nodes.push(x);
          }
        });
        if (this.nodes.length > 0) {
          this.selectedUnit = this.nodes.find(x => x.UnitId == this.userLogin.UnitId);
          this.selectedUnitDescription = this.nodes.find(x => x.UnitId == this.userLogin.UnitId)?.UnitDescription;
          // this.getTrackerDays(this.userLogin.UnitId)
          this.getTrackerEmployee();
        }
      })
      .catch();
  }

  selectCeVeEvent(value: any): void {

    this.selectedUnit = value;
    this.getTrackerEmployee();
  }

  //#endregion

  //#region DateFilter

  cmbDayChange() {
    this.getTrackerEmployee();
  }

  //#endregion

  //#region EmployeeFilter

  getTrackerEmployee() {
    if (this.selectedDay === null || this.selectedDay === undefined || this.selectedUnit === undefined) { return; }

    this.trackerService.getTrackerEmployee(this.selectedUnit.UnitId, this.selectedDay).subscribe(data => {
      this.employees = data;

      if (data.length > 0) {
        this.selectedEmployee = data[0].Employee;
      }
      else {
        this.zoom = 20;
        this.selectedEmployee = null;
        this.markers = [];
        this.loadingTimeline = false;
        this.timeLineVisible = false;
        this.getLocation();
      }
    }, (err) => { },
      () => {
        this.cmbEmployeeChange();
      });
  }

  cmbEmployeeChange() {
    this.timeLineVisible = true;
    this.getTrackerPoints();
  }

  //#endregion

  //#region Tracker points

  getTrackerPoints() {
    if (this.selectedDay == null || this.selectedDay == undefined) {
      this.loadingTimeline = false;
      this.timeLineVisible = false;
      return;
    }
    if (this.selectedEmployee == null || this.selectedEmployee == undefined) {
      this.loadingTimeline = false;
      this.timeLineVisible = false;
      return;
    }

    this.loadingMap = true;
    this.loadingTimeline = true;
    this.points = [];
    this.trackerService.getTrackerPoints(this.selectedUnit.UnitId, this.selectedDay, this.selectedEmployee).subscribe(data => {
      if (data.length > 0) {
        for (let index = 0; index < data.length; index++) {
          let openLayerCoords = [data[index].Longitude, data[index].Latitude];
          this.points.push(openLayerCoords);
        }
      }
      else {
        this.loadingTimeline = false;
        this.timeLineVisible = false;
        this.getLocation();
      }
    }, (err) => { },
      () => {
        this.loadingMap = false;
        this.getTimelineCustomers();
      });
  }

  //#endregion

  //#region Timeline

  getTimelineCustomers() {
    this.markers = [];
    this.timeLineCustomers = [];
    this.trackerService.getTrackedCustomers(this.selectedDay, this.selectedEmployee).subscribe(data => {
      if (data.length > 0) {
        this.timeLineCustomers = data;
        //Mapping customer points
        data.map(customer => {
          this.markers.push(
            {
              lat: toNumber(customer.Latitude),
              long: toNumber(customer.Longitude),
              label: customer.CustomerName
            });
        });
        this.loadingTimeline = false;
      }
      else {
        this.loadingTimeline = false;
        this.timeLineVisible = false;
        this.getLocation();
      }

      //Validate if autorefresh is on
      if (!this.intervalId) {
        this.Refresh();
      }

      this.drawMapLine();
      this.drawMapPoints();
    });
  }

  symbolSelector(moveType: string) {
    switch (moveType) {
      case 'TRAN':
        return 'fa fa-inbox';
      case 'RTM':
        return 'fa fa-people-carry';
      case 'Carga':
        return 'fa fa-people-carry';
      case 'SER':
        return 'fa fa-boxes';
      case 'ONROUTE':
        return 'bi bi-stoplights';
      case 'ONSITE':
        return 'fa fa-flag-checkered';
      default:
        return 'fa fa-check';
    }

  }

  badgeSelector(moveType: string) {
    switch (moveType) {
      case 'TRAN':
        return 'timeline-badge success';
      case 'RTM':
        return 'timeline-badge primary';
      case 'Carga':
        return 'timeline-badge primary';
      case 'SER':
        return 'timeline-badge primary';
      case 'ONROUTE':
        return 'timeline-badge GreenStopLight';
      case 'ONSITE':
        return 'timeline-badge PurpleBlack';
      default:
        return 'timeline-badge';

    }

  }

  //#endregion

  Refresh() {
    this.intervalId = setInterval(() => {
      //reload data
      this.getTrackerPoints();
    }, this.delay);

  }

  StopTimer() {
    clearInterval(this.intervalId);
  }


  //openMap

  //**Configuracion inicial del mapa */
  mapView: View = new View({
    center: [0, 0],
    zoom: 2,
    maxZoom: 20,
  });

  //**Capa para dibujar linear con el mouse */
  raster = new TileLayer({
    source: new OSM(),
  });

  //**Capa para agregar formas en el mapa  */
  vsource = new VectorSource();
  vector = new VectorLayer({
    source: this.vsource,
    style: new Style({
      stroke: new Stroke({
        color: '#000',
        width: 5,
      }),
    }),
  });

  //**Capa para agregar puntos en el mapa */
  pointSource = new VectorSource();
  pointsLayer = new WebGLPointsLayer({
    source: this.pointSource,
    style: {
      'icon-src': 'assets/img/point-blue.png',
      'icon-width': 50,
      'icon-height': 50,
      'icon-rotate-with-view': false,
      'icon-displacement': [0, 9],
    }
  });

  cevepointSource = new VectorSource();
  cevepointsLayer = new WebGLPointsLayer({
    source: this.cevepointSource,
    style: {
      'icon-src': 'assets/img/ceve-map-icon.png',
      'icon-width': 50,
      'icon-height': 50,
      'icon-rotate-with-view': false,
      'icon-displacement': [0, 9],
    }
  });

  //**Inicializacion del mapa */
  initOpenLayerMap() {
    this.map = new Map({
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        this.raster,
        this.vector,
        this.pointsLayer,
        this.cevepointsLayer
      ],
      overlays: [this.popupOverlay],
      target: 'map',
      view: this.mapView,
    });

    // //Agrega interaccion para dibujar en el mapa
    // let draw = new Draw({
    //   source: this.vsource,
    //   type: 'LineString',
    // });
    // this.map.addInteraction(draw);

    this.addPointOverEvent();
  }

  /**Mueve la ubicacion del mapa */
  centerMap(lng, lat) {
    this.mapView.setZoom(this.zoom);
    this.mapView.setCenter([lng, lat]);
  }

  //**Pinta la linea en el mapa */
  drawMapLine() {
    var route = new LineString(this.points);
    var routeFeature = new Feature({
      type: 'route',
      geometry: route
    });
    var iconFeature = new Feature({
      geometry: new Point(this.points[0]),
      name: 'Null Island',
      population: 4000,
      rainfall: 500,
    });
    
    this.vector.setSource(new VectorSource({
      features: [routeFeature, iconFeature]
    }));

    //centro el mapa para abarcar toda la ruta
    let routeGeo = routeFeature.getGeometry();
    this.mapView.fit(routeGeo, { padding: [20, 20, 20, 20] });
  }

  //**Dibuja los puntos de clientes en el mapa */
  drawMapPoints() {
    this.closePopup();
    this.pointSource.removeFeatures(this.pointSource.getFeatures());

    let pointsArr: Feature<Point>[] = [];
    let cevePointsArr: Feature<Point>[] = [];

    for (let index = 0; index < this.markers.length; index++) {
      let currentMark = this.markers[index];
      let lat = currentMark.lat;
      let long = currentMark.long;
      let label = currentMark.label;

      // pointsArr.push(new Feature(new Point([long, lat])));


      if (label.includes('CEVE -')) {
        let iconFeature = new Feature({
          geometry: new Point([long, lat]),
          name: label,
          population: 4000,
          rainfall: 500,
          style: {}
        });
        cevePointsArr.push(iconFeature);
      }
      else {
        let iconFeature = new Feature({
          geometry: new Point([long, lat]),
          name: label,
          population: 4000,
          rainfall: 500,
          style: {}
        });
        pointsArr.push(iconFeature);
      }

    }
    this.pointSource.addFeatures(pointsArr);
    this.cevepointSource.addFeatures(cevePointsArr);
    this.addMapPop();
  }


  addPointOverEvent() {
    // change mouse cursor when over marker
    this.map.on('pointermove', function (e) {
      const pixel = e.map.getEventPixel(e.originalEvent);
      const hit = e.map.hasFeatureAtPixel(pixel);
      e.map.getTargetElement().style.cursor = hit ? 'pointer' : '';
    });


    //evento click en el 
    this.map.on('singleclick', function (evt) {
      //oculto el popup si esta visible 
      let popup = document.getElementById('popup');
      if (!popup.className.includes('d-none')) {
        popup.className = popup.className + ' d-none';
      }

      //busco el elemento donde hago click
      const feature = evt.map.forEachFeatureAtPixel(evt.pixel, function (feature) {
        return feature;
      });
      //valido el click en un elementp
      if (!feature) {
        return;
      }
      //valido que el elemento sea un punto
      if (feature.getGeometry().getType() !== 'Point') {
        return;
      }


      let popupClass = popup.className;
      popup.className = popupClass.replace(' d-none', '');

      const content = document.getElementById('popup-content');
      const coordinate = evt.coordinate;
      content.innerHTML = feature.get('name');


      var popupOverlay = new Overlay({
        element: popup,
        autoPan: {
          animation: {
            duration: 250,
          },
        },
      });
      popupOverlay.setPosition(coordinate);
      evt.map.addOverlay(popupOverlay);
    });

  }

  popover: any;
  disposePopover() {
    if (this.popover) {
      this.popover.dispose();
      this.popover = undefined;
    }
  }

  popupOverlay = new Overlay({
    // element: ,
    positioning: 'bottom-center',
    stopEvent: false,
  });

  addMapPop() {

    this.popupOverlay.setElement(document.getElementById('popup'))
    this.map.addOverlay(this.popupOverlay);


    //evento click en el mapa
    this.map.on('click', evt => {
      const feature = this.map.forEachFeatureAtPixel(evt.pixel, function (feature) {
        return feature;
      });

      this.disposePopover();

      if (!feature) {
        return;
      }
      this.popupOverlay.setPosition(evt.coordinate);

    });
  }


  closePopup() {
    // this.map.getOverlays().clear();
    let popup = document.getElementById('popup');
    if (!popup.className.includes('d-none')) {
      popup.className = popup.className + ' d-none';
    }
  }


  //genera una imagen png del mapa
  exportPng() {
    let mapCanvas = document.createElement('canvas');
    let size = this.map.getSize();

    mapCanvas.width = size[0];
    mapCanvas.height = size[1];
    const mapContext = mapCanvas.getContext('2d');
    Array.prototype.forEach.call(
      this.map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
      function (canvas) {
        if (canvas.width > 0) {
          const opacity =
            canvas.parentNode.style.opacity || canvas.style.opacity;
          mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
          let matrix;
          const transform = canvas.style.transform;
          if (transform) {
            // Get the transform parameters from the style's transform matrix
            matrix = transform
              .match(/^matrix\(([^\(]*)\)$/)[1]
              .split(',')
              .map(Number);
          } else {
            matrix = [
              parseFloat(canvas.style.width) / canvas.width,
              0,
              0,
              parseFloat(canvas.style.height) / canvas.height,
              0,
              0,
            ];
          }
          // Apply the transform to the export map context
          CanvasRenderingContext2D.prototype.setTransform.apply(
            mapContext,
            matrix,
          );
          const backgroundColor = canvas.parentNode.style.backgroundColor;
          if (backgroundColor) {
            mapContext.fillStyle = backgroundColor;
            mapContext.fillRect(0, 0, canvas.width, canvas.height);
          }
          mapContext.drawImage(canvas, 0, 0);
        }
      },
    );
    mapContext.globalAlpha = 1;
    mapContext.setTransform(1, 0, 0, 1, 0, 0);

    const anchor = document.createElement('a');
    anchor.download = `mapa.png`;
    anchor.href = mapCanvas.toDataURL();
    anchor.click();

    this.map.renderSync();
  }


}

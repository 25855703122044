import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from '../../common/services/GenericServices';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  constructor(private genericService: GenericService) { }

  getProviderList(): Observable<any> {
    let url = 'provider';
    return this.genericService.getRequest(url, {});
  }

  getProviderById(providerId: number): Observable<any> {
    let url = 'provider/' + providerId;
    return this.genericService.getRequest(url, {});
  }

  createProvider(provider: any): Observable<any> {
    let url = 'provider';
    return this.genericService.postRequest(url, {}, 0, provider);
  }

  updateProvider(provider: any): Observable<any> {
    let url = 'provider';
    return this.genericService.putRequest(url, {}, 0, provider);
  }

  deleteProvider(providerId: number): Observable<any> {
    let url = 'provider/' + providerId;
    return this.genericService.deleteRequest(url, {}, 0);
  }
}

import { ThrowStmt } from '@angular/compiler';
import { BaseModel } from './BaseModel.Model';

export class Product extends BaseModel {

    constructor() {
        super();
        this.Org = 0;
        this.ItemPlantaId = 0;
        this.Codebar = '';
        this.ProductCode = '';
        this.ProductName = '';
        this.ProductDescription = '';
        this.ProductType = 0;
        this.ProductPrice = 0.0;
        this.ProductWeight = 0.0;
        this.ProductConversion = 0;
    }

    Org: number;
    ItemPlantaId: number;
    Codebar: string;
    ProductCode: string;
    ProductName: string;
    ProductDescription: string;
    ProductType: number;
    ProductPrice: number;
    ProductWeight: number;
    ProductConversion: number;
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/core/settings/settings.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';

import { CeqTableComponent } from 'src/app/ceq/common/ceq-table/ceq-table.component';
import { EditChildRendered } from '../../../common/renders/edit-child-rendered';
import { DeleteChildRendered } from '../../../common/renders/delete-child-rendered';
import { FiscalconfigService } from '../fiscalconfig.service';


@Component({
  selector: 'app-fiscalconfig-list',
  templateUrl: './fiscalconfig-list.component.html',
  styleUrls: ['./fiscalconfig-list.component.scss']
})
export class FiscalconfigListComponent implements OnInit {

  constructor(private router: Router,
    private settings: SettingsService,
    private fiscalConfigService: FiscalconfigService) { }


  // ViewChild
  @ViewChild('CeqGrid', { static: false }) ceqGrid: CeqTableComponent;
  options: GridOptions;
  PageSizes = [10, 25, 50, 100, 1000];

  gridOptions = {
    context: this,
    frameworkComponents: {
      childEditRenderer: EditChildRendered,
      childDeleteMessageRenderer: DeleteChildRendered
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) { },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    }
  }

  columnDefs = [
    {
      headerName: 'Llave',
      field: 'FiscalKey',
      width: 40,
      cellClass: 'align-start',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      editable: false
    },
    {
      headerName: 'Valor',
      field: 'FiscalValue',
      width: 20,
      cellClass: 'align-start',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      editable: false
    },
    {
      headerName: 'Última modificación',
      field: 'ModifiedAt',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      unSortIcon: true,
      resizable: true,
      cellRenderer: (params: any) => moment(params.data.ModifiedAt).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      headerName: 'Modificado por',
      field: 'ModifiedBy',
      width: 20,
      cellClass: 'align-start',
      resizable: true,
      editable: false
    },
    {
      headerName: 'Editar',
      field: 'icon',
      width: 5,
      cellRenderer: 'childEditRenderer',
      colId: 'params',
      cellClass: 'text-center',
      hide: false,
    },
    {
      headerName: 'Eliminar',
      field: 'icon',
      width: 5,
      cellRenderer: 'childDeleteMessageRenderer',
      colId: 'params',
      cellClass: 'text-center',
      hide: false,
    }
  ];


  ngOnInit(): void {
  }

  gridEmiter(GridOptions: GridOptions) {
    this.options = GridOptions;
    this.getFiscalConfig();
  }

  onPageSizeChange(value: number) {
    console.log(value);
  }

  btnAddClick() {
    this.router.navigate(['/tax/fiscalconfig/create']);
  }

  edit(row: any) {
    this.router.navigate(['/tax/fiscalconfig', row.data.IdFiscalConfig]);
  }

  delete(row: any) {
    Swal.fire({
      title: 'Confirmacion',
      text: "¿Desea eliminar la configuración " + row.data.FiscalKey + "?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> &nbsp; Aceptar',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i> &nbsp; Cancelar',
      backdrop: `rgba(0,157,210,0.5)`
    }).then((result) => {
      if (result.isConfirmed) {
        this.fiscalConfigService.deleteFiscalConfig(row.data.IdFiscalConfig).subscribe(data => {
          console.log("result", data);
          Swal.fire(
            '¡Éxito!',
            'La configuración se eliminó correctamente.',
            'success'
          );
        }, (error) => {
          console.log("error", error);
          Swal.fire('¡Error!', error.error, 'error');
        }, () => {
          this.getFiscalConfig();
        });
      }
    })
  }


  getFiscalConfig() {
    this.fiscalConfigService.getFiscalConfigList().subscribe(data => {
      this.ceqGrid.data = data;
    });
  }

}

<div class="content-heading">
    <div class="header-container">
        <h3>C&oacute;digo postal</h3>
        <small>Cat&aacute;logo de c&oacute;digos postales</small>
    </div>
</div>


<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="card card-default">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      C&oacute;digo de colonia
                    </div>
                    <div class="col">
                      Estado
                    </div>
                    <div class="col">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">

                      <input
                      type="text"
                      class="form-control"
                      placeholder="Colonia"
                      [(ngModel)]="neigbhoodCode"
                      (input)="getFiscalConfig()"
                    />

                  </div>
                    <div class="col">

                      <input
                      type="text"
                      class="form-control"
                      placeholder="Estado"
                      [(ngModel)]="stateCode"
                      (input)="getFiscalConfig()"
                    />

                    </div>
                    <div class="col">
                    </div>
                  </div>



                    <div class="row">
                        <div class="col-md-12 col-xs-12">

                            <div class="row filter-container">



                            </div>
                            <br>
                            <div>
                                <app-ceq-table #CeqGrid [gridAdditionalOptions]="gridOptions" [columnDef]="columnDefs" (apiEmitter)="gridEmiter($event)">
                                </app-ceq-table>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3 col-lg-12 col-md-12">
                        <div class="pagesize-control">
                            Tama&ntilde;o de p&aacute;gina:
                            <select (change)="onPageSizeChange($event.target.value)">
                                <option *ngFor="let size of PageSizes" [ngValue]="size">{{size}}</option>
                            </select>
                        </div>

                    </div>
                    <br>
                    <div class="row mt-3 col-lg-12 col-md-12">
                        <!-- <button type="button" class="btn btn-outline-primary" (click)="btnAddClick()">
                            <i class="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;
                            Nuevo c&oacute;digo postal
                        </button> -->

                        <div class="form-group">
                            <label for="file">Importar c&oacute;digos postales </label>
                            <br>
                            
                            <div class="file-upload-container">
                              <button class="mr-1 btn btn-labeled btn-outline-primary mb-2" (click)="fileInput.click()">
                                <span class="btn-label">
                                  <i class="fa fa-file-csv"></i>
                                </span>
                                <span>Seleccionar archivo</span>
                              </button>
                            
                              <input type="file" id="file" #fileInput (change)="handleFileInput($event.target.files)" style="display: none;">
                            
                              <span class="ml-2" (click)="fileInput.click()">
                                {{ fileToUpload ? fileToUpload.name : 'Sin archivos seleccionados' }}
                              </span>
                            </div>
                            
                            <!-- <input type="file" id="file" [(ngModel)]="fileToUpload" (change)="handleFileInput($event.target.files)"> -->
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

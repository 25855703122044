import { Routes, CanActivate } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from "../ceq/common/guards/auth-guard.guard";
import { AuthGuardLogged } from '../ceq/common/guards/auth-guard-logged';

export const routes: Routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] },
            { path: 'widgets', loadChildren: () => import('./widgets/widgets.module').then(m => m.WidgetsModule), canActivate: [AuthGuard] },
            { path: 'catalog', loadChildren: () => import('../ceq/catalog/catalog.module').then(m => m.CatalogModule), canActivate: [AuthGuard] },
            { path: 'management', loadChildren: () => import('../ceq/enterprise/enterprise.module').then(m => m.EnterpriseModule), canActivate: [AuthGuard] },
            { path: 'incidence', loadChildren: () => import('../ceq/incidence/incidence.module').then(m => m.IncidenceModule), canActivate: [AuthGuard] },
            { path: 'security', loadChildren: () => import('../ceq/security/security.module').then(m => m.SecurityModule), canActivate: [AuthGuard] },
            { path: 'inventory', loadChildren: () => import('../ceq/inventory/inventory.module').then(m => m.InventoryModule), canActivate: [AuthGuard] },
            { path: 'self-service', loadChildren: () => import('../ceq/self-service/balance/customer-balance.module').then(m => m.CustomerBalanceModule), canActivate: [AuthGuard] },
            { path: 'configuration', loadChildren: () => import('../ceq/configuration/configuration.module').then(m => m.ConfigurationModule), canActivate: [AuthGuard] },
            { path: 'commercial', loadChildren: () => import('../ceq/commercial/commercial.module').then(m => m.CommercialModule), canActivate: [AuthGuard] },
            { path: 'reports', loadChildren: () => import('../ceq/reports/reports-module').then(m => m.ReportsModule), canActivate: [AuthGuard] },
            { path: 'tax', loadChildren: () => import('../ceq/tax/tax.module').then(m => m.TaxModule), canActivate: [AuthGuard] },
            { path: 'purchase', loadChildren: () => import('../ceq/purchase/purchase.module').then(m => m.PurchaseModule), canActivate: [AuthGuard] },
            { path: 'equipment-io', loadChildren: () => import('../ceq/equipment-io/equipment-io.module').then(m => m.EquipmentIoModule), canActivate: [AuthGuard] },
            { path: 'miscellaneous', loadChildren: () => import('../ceq/miscellaneous/miscellaneous.module').then(m => m.MiscellaneousModule), canActivate: [AuthGuard] },
            { path: 'map', loadChildren: () => import('../ceq/map/map.module').then(m => m.MapModule) },
            { path: 'multichannel', loadChildren: () => import('../ceq/multichannel/multichannel.module').then(m => m.MultichannelModule) },
        ]
    },

    { path: 'login', component: LoginComponent, canActivate: [AuthGuardLogged] },
    // Not found
    { path: '**', redirectTo: 'home' },

];

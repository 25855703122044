import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment-return',
  templateUrl: './equipment-return.component.html',
  styleUrls: ['./equipment-return.component.scss']
})
export class EquipmentReturnComponent implements OnInit {

  RoutesSync: Route[] = [];
  RoutesReceived: Route[] = [];



  constructor() { }

  ngOnInit(): void {
    this.GetChannelRoutes();
  }


  /**Obtengo las rutas sincronizadas */
  GetChannelRoutes() {
    this.RoutesSync = [
      { routeCode: "0001", routeId: 1 },
      { routeCode: "0002", routeId: 2 },
      { routeCode: "0003", routeId: 3 },
      { routeCode: "0004", routeId: 4 },
      { routeCode: "0005", routeId: 5 },
      { routeCode: "0006", routeId: 6 },
      { routeCode: "0007", routeId: 7 },
      { routeCode: "0008", routeId: 8 },
      { routeCode: "0009", routeId: 9 },
      { routeCode: "0010", routeId: 10 },
      { routeCode: "0001", routeId: 1 },
      { routeCode: "0002", routeId: 2 },
      { routeCode: "0003", routeId: 3 },
      { routeCode: "0004", routeId: 4 },
      { routeCode: "0005", routeId: 5 },
      { routeCode: "0006", routeId: 6 },
      { routeCode: "0007", routeId: 7 },
      { routeCode: "0008", routeId: 8 },
      { routeCode: "0009", routeId: 9 },
      { routeCode: "0010", routeId: 10 }
    ];
  }

  /**Evento que se dispara al hacer click en una ruta syncronizada */
  SyncRouteClick(routeId: number) {
    console.log(routeId);
  }



}


export interface Route {
  routeCode: string;
  routeId: number;
}
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'accept-cell',
    template: `<span><i class="fa fa-check" (click)="invokeParentMethod();" style="width:100%;"></i></span>`,
    styles: [
        `.btn {
      line-height: 0.5
    }`
    ]
})

export class AcceptChildRendered implements ICellRendererAngularComp {
    public params: any;

    agInit(params: any): void {
        this.params = params;
    }

    public invokeParentMethod() {
        this.params.context.accept(this.params);
    }

    refresh(): boolean {
        return false;
    }
}

<!---------------------------------------------------------------------------->
<div class="content-wrapper">

  <div class="card b mb-2">
    <div class="card-header bb">
      <h4 class="card-title">Detalle de Configuraci&oacute;n Fiscal</h4>
    </div>    
  </div>


    <form #Form="ngForm" (ngSubmit)="formSubmit(Form.value)">
      <div class="form-container">
        <div class="row">

          <div class="form-group col-sm-4">
            <label class="form-label">C&oacute;digo</label>
            <input class="form-control" type="text" value="" placeholder="C&oacute;digo" [(ngModel)]="fiscalconfig.FiscalKey" name="FiscalKey" required maxlength="50" [disabled]="fiscalconfig.IdFiscalConfig !== 0" />
          </div>

          <div class="form-group col-sm-4">
            <label class="form-label">Valor</label>
            <input class="form-control" type="text" value="" placeholder="Valor" [(ngModel)]="fiscalconfig.FiscalValue" name="FiscalValue" required maxlength="100" />
          </div>

        </div>
        <br /><br />
        <div class="buttons-container">
          <button type="submit" class="mr-1 btn btn-labeled btn-outline-primary mb-2" [disabled]="!Form.valid">
            <span class="btn-label">
              <i class="fa fa-save"></i>
            </span>
            <span>Guardar</span>
          </button>
          &nbsp;&nbsp;
          <button type="button" class="mr-1 btn btn-labeled btn-outline-danger mb-2" (click)="btnCancelOnClick()">
            <span class="btn-label">
            <i class="fa fa-chevron-circle-left"></i>
            </span>
            <span>Volver</span>
          </button>

        </div>
      </div>
    </form>
</div>

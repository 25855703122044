<div class="content-heading">
  <div class="header-container">
    <h3>L&iacute;neas de Transportes</h3>
    <small>Cat&aacute;logo de l&iacute;neas de transportes</small>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 col-xs-12">

              <div class="row filter-container">
                <div class="col-sm-4 ng-autocomplete">

                  <span>L&iacute;nea de Transporte</span>
                  <ng-autocomplete placeholder="Escribe para buscar..." [data]="lineList" [searchKeyword]="keyword" (selected)='selectEvent($event)' (inputCleared)="cmbLines()" [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" [(ngModel)]="selectedLine">
                  </ng-autocomplete>
                  <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.LineName"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="'No se encontraron coincidencias'"></div>
                  </ng-template>

                </div>
              </div>
              <br>
              <div>
                <app-ceq-table #CeqGrid [gridAdditionalOptions]="gridOptions" [columnDef]="columnDefs" (apiEmitter)="gridEmiter($event)">
                </app-ceq-table>
              </div>
            </div>
          </div>
          <div class="row mt-3 col-lg-12 col-md-12">
            <button type="button" class="mr-1 btn btn-labeled btn btn-outline-primary mb-2" (click)="btnAddClick()">
              <span class="btn-label">
              <i class="fa fa-plus-circle" aria-hidden="true"></i>
              </span>
              <span>Nueva l&iacute;nea</span>
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { UserblockService } from './userblock.service';
import { UserLogin } from "../../../ceq/common/models/UserLogin.Model"
import { SettingsService } from '../../../core/settings/settings.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    user: UserLogin;
    userPicture: any;
    outputList: any[] = [];
    OutputUnit : number;

    constructor(public userblockService: UserblockService,
        private settings: SettingsService,
        private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.user = this.settings.getUser();
        // this.userPicture = "https://www.activared.com.mx/wp-content/uploads/2020/03/FB_IMG_1585417094909.jpg";
        this.RefreshUserImage();
        this.OutputUnit=this.user.UnitId;

        this.userblockService.getMultiorgNodes(this.user.UnitId, 1).subscribe((ceves: any[]) => {
            this.outputList = ceves;
          }, err => {
            console.log(err);
          });
    }


    cmbOutputListChange(event: any) {

        console.log ("Valor "+ event);
       /*
        this.remission.OutputUnit = event;
        if (this.remission.OutputUnit > 100000000000000000) {
          this.remission.Driver = 'BARCEL';
        }
        if (this.remission.OutputUnit > 200000000000000000) {
          this.remission.Driver = 'BIMBO';
        }
        if (this.remission.OutputUnit > 300000000000000000) {
          this.remission.Driver = 'RICOLINO';
        }
        */
      }

      
    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

    RefreshUserImage() {
        this.settings.customerImage.subscribe(image => {
            let objectURL = 'data:image/png;base64,' + JSON.parse(image).Image;
            this.userPicture = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        });
    }

}

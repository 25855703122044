import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AttachmentChildRendered } from '../../common/renders/attachment-child-render';
import { DeleteChildRendered } from '../../common/renders/delete-child-rendered';
import { ViewChildRendered } from '../../common/renders/view-child-rendered';
import { CeqTableComponent } from '../../common/ceq-table/ceq-table.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { ElementRef } from '@angular/core';
import * as moment from 'moment';
import { toLower } from 'lodash';
import Swal from 'sweetalert2';

import { Attachment } from '../../common/models/Attachment.Model';

import { GlobalFunctionsService } from '../../common/functions/global-functions.service';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { PurchaseService } from '../purchase.service'

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit {

  @ViewChild('CeqGrid', { static: false }) ceqGrid: CeqTableComponent;
  @ViewChild('waveLoad') myInputVariable: ElementRef;
  saving: boolean = false;
  uploadedWave: File[] = [];
  table: string ;
  key: string ;
  permisos: string;
  options: GridOptions;
  subtitulo: string;
  waveLoadClassButton: string = 'btn btn-outline-primary';
  canEliminate: boolean = false;
  canCreate: boolean = false;
  canDownload: boolean = false;
  Working: boolean = false;
  DefaultSize: number = 5120000;
  attachmentList: Attachment[] = [];
  url: string;
  safeUrl: SafeResourceUrl;
  img: SafeUrl;
  isImage: boolean = false;
  isText: boolean = false;
  title: string;
  storage: string;
  contentString: string;
  fileSize: number = 1024000;

  gridOptions = {
    context: this,
    frameworkComponents: {
      childViewMessageRenderer: ViewChildRendered,
      childDeleteMessageRenderer: DeleteChildRendered,
      childAttachment: AttachmentChildRendered
    },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) { }
  };

  columnDefs = [
    {
      headerName: 'Archivo',
      field: 'attachedFileName',
      width: 50,
      cellClass: 'align-start',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      editable: false
    },
    {
      headerName: 'Subido en',
      field: 'CreatedAt',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      unSortIcon: true,
      resizable: true,
      cellRenderer: (params: any) => moment(params.data.ModifiedAt).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      headerName: 'Subido por',
      field: 'CreatedBy',
      width: 20,
      cellClass: 'align-start',
      editable: false
    },
    {
      headerName: 'Vista Previa',
      field: 'icon',
      width: 10,
      cellRenderer: 'childViewMessageRenderer',
      colId: 'params',
      cellClass: 'text-center'
    },
    {
      headerName: 'Descargar',
      field: 'icon',
      width: 10,
      cellRenderer: 'childAttachment',
      colId: 'params',
      cellClass: 'text-center'
    },
    {
      headerName: 'Eliminar',
      field: 'icon',
      width: 10,
      cellRenderer: 'childDeleteMessageRenderer',
      colId: 'params',
      cellClass: 'text-center'
    },
  ];

  constructor(private BussinesService: PurchaseService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private globalService: GlobalFunctionsService,
    private settingsServ:SettingsService) { }

  ngOnInit(): void {
    this.settingsServ.getSettingsByKey('UploadSize').subscribe((conf: any) => {
      this.fileSize = conf.Value;
    });
    this.table = this.route.snapshot.params['Table'];
    if (this.table == undefined) {
      this.globalService.messageToast('No se ha proporcionado el identificador de la tabla','warning')
      return;
    }

    this.key = this.route.snapshot.params['Key'];
    if (this.key == undefined) {
      this.globalService.messageToast('No se ha proporcionado el identificador de la llave','warning')
      return;
    }

    this.getAttachments();

    this.subtitulo = this.route.snapshot.params['Title'];
    if (this.subtitulo == undefined) {
      this.globalService.messageToast('No se ha proporcionado el identificador de titulo','warning')
      return;
    }

    this.permisos = this.route.snapshot.params['Perm'];
    if (this.permisos == undefined) {
      this.globalService.messageToast('No se ha proporcionado el identificador de Permisos','warning')
      return;
    }

    if (this.permisos.toUpperCase().includes('C'))
      this.canCreate = true;

    if (this.permisos.toUpperCase().includes('D'))
      this.canDownload = true;

    if (this.permisos.toUpperCase().includes('E'))
      this.canEliminate = true;
    
    this.settingsServ.getSettingsByKey ("UploadSize")
      .toPromise()
      .then( x  => {      
        let vtmp = Number(x.Value);
        if (vtmp != undefined)
          this.DefaultSize = Number(x.Value);
      })
      .catch((e: HttpErrorResponse) => {
      });
  }

  btnCancelOnClick() {
    window.close();
  }

  delete(item: any): void {
    if (this.canEliminate) {
      const { attachedFileName, attachedtid } = item.data;
      Swal.fire({
        text: `¿Desea eliminar el archivo adjunto  ${attachedFileName}? El registro no podrá ser recuperado.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> &nbsp; Aceptar',
        cancelButtonText: '<i class="fa fa-thumbs-down"></i> &nbsp; Cancelar',
        backdrop: `rgba(0,157,210,0.5)`
      }).then((result) => {
        if (result.isConfirmed) {
          this.removeFile(item.data.attachedId);
        }
      });
    } else {
      this.globalService.messageToast('No cuenta con permisos para realizar esta accion', 'error');

    }
  }

  async getAttachments(){
    await this.BussinesService.getAttachmentList(this.table, this.key).toPromise().then((data: Attachment[]) => {
      this.attachmentList = data;
      this.ceqGrid.data = this.attachmentList;
    });
  }

  edit(item: any): void {
    if (this.canDownload) {
      this.viewFile(item.data.attachedId, item.data.attachedFileName);
    }
    else {
      this.globalService.messageToast('No cuenta con permisos para realizar esta acción', 'error');
    }
  }

  viewFile(id: string,filename: string) {
    this.BussinesService.viewAttachmenRecord(id).subscribe((resp: any) => {
      if (resp == null) {
        this.globalService.messageToast('No se ha encontrado información.', 'warning')
        return;
      }
      console.log(`Descargo ${resp.type} `);

      let blob = new Blob([resp], { type: resp.type });

      const anchor = document.createElement('a');

      anchor.download = filename;
      anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
      anchor.click();

    }, err => {
      this.globalService.messageToast('Error en la invocación','warning')
      console.log(err);
    });
  }

  view(item: any): void {
    let array = item.data.attachedFileName.split('.');
    this.storage = item.data.ModifiedBy;
    let extension = toLower(array[array.length-1]);
    if(extension == 'eml' || extension == 'msg'){
      this.globalService.messageToast(`Este tipo de archivos (.${extension}) no se pueden previsualizar. Será necesario que lo descargue y abrá en su cliente de correo asociado.`,'warning')
      return;
    }
    if(extension == 'jpg' || extension == 'jpeg' || extension == 'gif' || extension == 'png' || extension == 'bmp'){

      this.BussinesService.viewAttachmenRecord(item.data.attachedId).subscribe((resp: any) => {
        if (resp == null) {
          this.globalService.messageToast('No se ha encontrado información.','warning')
          return;
        }  
        this.title = item.data.attachedFileName;
        
        let blob = new Blob([resp], { type: resp.type });        
        const objectURL = (window.webkitURL || window.URL).createObjectURL(blob);
        const img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.img = img;
        this.isImage = true;
        this.isText = false;
      }, err => {
        this.globalService.messageToast('Error en la invocación','warning');
        console.log(err);
      });
    }else if(extension == 'xml' || extension == 'txt') {
      this.BussinesService.viewAttachmenRecord(item.data.attachedId).subscribe((resp: any) => {
        if (resp == null) {
          this.globalService.messageToast('No se ha encontrado información.','warning');
          return;
        }  
        this.title = item.data.attachedFileName;
        
        let blob = new Blob([resp], { type: 'text/plain' });
        this.contentString = blob.text.toString();
        this.getDataString(blob).then(resp => this.contentString = resp);        
        this.isText = true;
        this.isImage = false;
      }, err => {
        this.globalService.messageToast('Error en la invocación','warning');
        console.log(err);
      });

    }else{
      this.isImage = false;
      this.isText = false;
      let attachDoc = `https://${this.storage}.blob.core.windows.net/attachment/${item.data.attachedId}`;
      this.url = `https://docs.google.com/viewer?url=${attachDoc}&embedded=true`;
      window.open(this.url);  
    }
  }

  async getDataString(data: any){
    return await data.text();
  }


  removeFile(id: string) {
    this.BussinesService.removeAttachmenRecord(id)
      .toPromise()
      .then(x => {
        this.getAttachments();
        this.globalService.messageToast('Se ha eliminado el archivo con éxito.', 'success');
      })
      .catch((e: HttpErrorResponse) => {
        console.log('Error remover: ',e.error);
        this.globalService.messageToast('Ha ocurrido un error, no se pudo eliminar el archivo.', 'error');
      });
  }

  ListEmiter(GridOptions: GridOptions) {
    this.options = GridOptions;
  }

  onSendWaveLoad() {   
    const dataForm = new FormData();
    for (let i = 0; i < this.uploadedWave.length; i++) {
      if(this.uploadedWave[i].size > this.fileSize){
        this.Working = false;
        this.globalService.messageToast(`No puede adjuntar archivos (${this.globalService.separatorFormatter(this.uploadedWave[i].size/1024)} KB) mayores al tamaño permitido (${this.globalService.separatorFormatter(this.fileSize/1024)} KB).`, 'warning');
        this.uploadedWave = [];
        return;
      }
      dataForm.append('file-' + i, this.uploadedWave[i])
    }
    this.saving = true;
    this.BussinesService.SendFile (dataForm, this.table, this.key )
      .toPromise()
      .then(x => {
        this.saving = false;
        this.Working = false;
        this.uploadedWave = [];
        if (this.uploadedWave.length == 0) {
          this.waveLoadClassButton = 'btn btn-outline-primary';
        }
        
        this.getAttachments();
        this.myInputVariable.nativeElement.value = '';
        this.globalService.messageToast('Se adjunto correctamente el archivo', 'success');
      })
      .catch((e: HttpErrorResponse) => {
        this.saving = false;
        this.Working = false;
        this.myInputVariable.nativeElement.value = '';
        this.globalService.messageToast('Ha ocurrido un error, no se pudo cargar el archivo.', 'error');
        console.log('Error envio: ',e.error);
      });
  }

  onWaveLoadSelect(data: any) {
    let nameFile = data[0].name;
    if(nameFile.length > 100){
      this.globalService.messageToast("El nombre del archivo solo puede contener máximo 100 caráctares.", 'warning');
      return;
    }
    if(this.attachmentList != null){
      let exist = this.attachmentList.find(a => a.attachedFileName == nameFile);
      if(exist != undefined){
        if(exist.attachedFileName == nameFile){
          this.myInputVariable.nativeElement.value = '';
          this.globalService.messageToast("El nombre del archivo ya existe.", 'warning');
          return
        }
      }
    }
    nameFile = nameFile.toLowerCase();

    if (this.Working) {
      this.myInputVariable.nativeElement.value = '';
      this.globalService.messageToast("Se esta procesando un archivo, espere un momento.", 'warning');
      return
    }

    var type: string[] = nameFile.split('.');
    if (type[type.length - 1] != 'xls' &&
      type[type.length - 1] != 'xlsx' &&
      type[type.length - 1] != 'pdf' &&
      type[type.length - 1] != 'doc' &&
      type[type.length - 1] != 'docx' &&
      type[type.length - 1] != 'jpg' &&
      type[type.length - 1] != 'jpeg' &&
      type[type.length - 1] != 'bmp' &&
      type[type.length - 1] != 'gif' &&
      type[type.length - 1] != 'png' &&
      type[type.length - 1] != 'xml' &&
      type[type.length - 1] != 'txt' &&
      type[type.length - 1] != 'msg' &&
      type[type.length - 1] != 'eml' &&
      type[type.length - 1] != 'vnd.ms-excel') {
        this.myInputVariable.nativeElement.value = '';
        this.globalService.messageToast("El archivo ''" + data[0].name + "'' no es formato: xls, xlsx, pdf, doc, docx, jpg, jpeg, png, msg, bmp, gif, eml, xml o txt.", 'warning');
    } else {
      if (data.size > this.DefaultSize) {
        this.myInputVariable.nativeElement.value = '';
        this.globalService.messageToast("El archivo  ''" + data[0].name + "'' supera el tamaño permitido " + this.DefaultSize + ".", 'warning');
      } else {
        this.Working = true;
        this.uploadedWave.push(data[0]);
        this.onSendWaveLoad();
        data = null;
      }              
    }
  
    if (this.uploadedWave.length > 0) {
     this.waveLoadClassButton = 'btn btn-outline-primary';
    }
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../common/guards/auth-guard.guard';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { RouteBalanceComponent } from './route-balance/route-balance.component';
import { RouteBalanceReportComponent } from './route-balance-report/route-balance-report.component';
import { EquipmentReturnComponent } from './equipment-return/equipment-return.component';

const routes: Routes = [
  { path: 'route-balance', component: RouteBalanceComponent, canActivate: [AuthGuard] },
  { path: 'equipment-return', component: EquipmentReturnComponent, canActivate: [AuthGuard] }
]

@NgModule({
  declarations: [RouteBalanceComponent, RouteBalanceReportComponent, EquipmentReturnComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    FormsModule
  ]
})
export class MultichannelModule { }
import { Component, OnInit } from '@angular/core';
import { MultichannelService } from '../multichannel.service';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';
import { EquipmentloanserviceService } from '../../self-service/equipment-loan/equipmentloanservice.service';
import { ContainerService } from '../../catalog/container/container/container.service';
import { Unit } from '../../common/models/Unit.Model';
import { Channel } from '../../common/models/Channel.Model';
import { Route } from '../../common/models/Route.Model';
import { Container } from '../../common/models/Container.Model';
import { PromiseStatus } from 'ag-grid-community';
import { CeveByContainerService } from '../../catalog/ceve/ceveByContainer.service';
import { number } from 'ngx-custom-validators/src/app/number/validator';
import { ChannelService } from '../../catalog/channel/channel.service';
import { MultiselectOption } from '../../common/ceq-multiselect/ceq-multiselect.component';

@Component({
  selector: 'app-route-balance-report',
  templateUrl: './route-balance-report.component.html',
  styleUrls: ['./route-balance-report.component.scss']
})
export class RouteBalanceReportComponent implements OnInit {

  constructor(
    private multichannelSerice: MultichannelService,
    private settings: SettingsService,
    private globalService: GlobalFunctionsService,
    private equipmentLoanService: EquipmentloanserviceService,
    private containerService: ContainerService,
    private ceveByContainerService: CeveByContainerService,
    private channelService: ChannelService
  ) { }

  working: boolean = false;

  //filters
  routeBalanceReportActivitty: boolean = false;
  startDate: string;
  endDate: string;
  maxDate: string;
  resalta: boolean = false;

  //Filters
  units: Unit[] = [];
  selectedUnit: Unit = undefined;
  channels: Channel[] = [];
  channelId: number = undefined;
  routes: Route[] = [];
  routeId: number = undefined;
  containerTypeList: MultiselectOption[] = [];
  selectedcontainers: number[] = this.containerTypeList.map(x => x.Id);



  ngOnInit(): void {
    let userActivities = this.settings.getUserAccess();
    this.routeBalanceReportActivitty = userActivities.includes('REPMULTICH');
    this.startDate = this.globalService.getStringDate(new Date(), false, 0);
    this.endDate = this.globalService.getStringDate(new Date(), false, 0);
    this.maxDate = this.globalService.getStringDate(new Date(), true, -3);
    this.getUserProfile();
  }

  async getUserProfile() {
    let user = this.settings.getUser();
    await this.globalService.GetParentList(user.Id,'CEVE')
      .toPromise()
      .then(resp => {
        this.units = resp;
        if (this.units.length > 0) {
          this.units.unshift({
            OrgId: this.settings.getUser().Org,
            UnitId: 0,
            ParentId: 0,
            UnitCode: '',
            LevelType: '',
            UnitDescription: 'Todos',
            CreatedAt: new Date(),
            CreatedBy: '',
            ModifiedAt: new Date(),
            ModifiedBy: ''
          });
          this.onUnitSelected(this.units[0]);
        }
        this.working = false;
      }).catch(() => {
        this.globalService.messageToast('No se pudieron obtener los centros de venta', 'error');
        this.working = false;
      });
  }

  onUnitClear() {
    this.selectedUnit = undefined;
    this.channels = [];
    this.channelId = undefined;
    this.routes = [];
    this.routeId = undefined;
    this.containerTypeList=[];
    this.selectedcontainers = [];
  }

  async onUnitSelected(event: Unit) {
    this.selectedUnit = event;
    await this.getContainers();
    await this.getChannelsByCeve();
  }

  async onChannelSelected(event: any) {
    await this.getRoutesByChannel();
  }

  async onRouteSelected(event: any) {
    // console.log(this.routeId);
  }

  onStartDateSelected() {
    if (new Date(this.startDate) > new Date(this.endDate)) {
      this.endDate = this.startDate
    }
  }

  onEndDateSelected() {
    if (new Date(this.startDate) > new Date(this.endDate)) {
      this.startDate = this.endDate
    }
  }

  async getChannelsByCeve() {
    this.working = true;
    await this.channelService.getMultichannelByCeve(this.selectedUnit.UnitId)
      .toPromise()
      .then(async resp => {
        this.working = false;
        this.channels = resp;
        if (this.channels.length > 0 || this.selectedUnit.UnitId == 0) {
          this.channels.unshift({
            Org: this.settings.getUser().Org,
            UnitId: 0,
            ChannelCode: '',
            ChannelName: 'Todos',
            CreatedAt: new Date(),
            CreatedBy: '',
            ModifiedAt: new Date(),
            ModifiedBy: ''
          });
          this.channelId = this.channels[0].UnitId;
          this.onChannelSelected(this.channelId);
        }
      })
      .catch(async (error) => {
        this.working = false;
        console.error(error);
        await this.globalService.messageToast(error.error.Message, 'error');
      });
  }

  async getRoutesByChannel() {
    this.working = true;
    this.equipmentLoanService.getRoutesByChannel(this.selectedUnit.UnitId, this.channelId)
      .toPromise()
      .then(async resp => {
        this.working = false;
        this.routes = resp;
        if (this.routes.length > 0 || this.channelId == 0) {
          this.routes.unshift({
            Org: this.settings.getUser().Org,
            RouteId: 0,
            ChannelId: 0,
            CeveId: 0,
            RouteCode: '',
            RouteName: 'Todos',
            CreatedAt: new Date(),
            CreatedBy: '',
            ModifiedAt: new Date(),
            ModifiedBy: ''
          });
          this.routeId = this.routes[0].RouteId;
        }
      })
      .catch(async (error) => {
        this.working = false;
        console.error(error);
        await this.globalService.messageToast(error.error.Message, 'error');
      });
  }

  async getContainers() {
    this.working = true;
    await this.containerService.getContainersByContainerOrg(this.settings.getUser().Org, 0, 0)
      .toPromise()
      .then(async resp => {
        this.working = false;
        let tmpContainers = resp.map(x => { return { Checked: false, Id: x.ContainerId, Text: x.ContainerCode } });
        if (resp.length > 0) {
          //Consulto el equipo configurado por defecto
          let initialContainers = await this.getInitialContainers();
          //si no hay equipo configurado por defecto, selecciono todos el equipo
          if (initialContainers.length == 0) {
            initialContainers = tmpContainers.map(x => x.Id);
          }
          initialContainers.forEach(item => {
            let c = tmpContainers.find(x => x.Id == item);
            if (c != undefined && c != null) {
              c.Checked = true;
            }
          })

          this.containerTypeList = tmpContainers;
          this.selectedcontainers = this.containerTypeList.filter(x => x.Checked == true).map(x => x.Id);
        }
      })
      .catch(async error => {
        this.working = false;
        console.error(error);
        await this.globalService.messageToast(error.error.Message, 'error');
      })
  }

  async getInitialContainers(): Promise<number[]> {
    let containerIds: number[] = [];
    await this.ceveByContainerService.getCheckedRouteBalanceContainers(this.selectedUnit.UnitId)
      .toPromise()
      .then(resp => {
        containerIds = resp;
      })
      .catch(err => {
        console.error(err);
      });
    return new Promise(resolve => resolve(containerIds));
  }


  ContainerSelected() {
    // console.log(this.selectedcontainers);
  }


  onRemissionTypeFilterChange(event: MultiselectOption[]) {
    // this.remissionTypeCheckedList = event;
    // this.remissionTypeSelected = event.map(x => x.Id).join(',');

    this.selectedcontainers = event.map(x => x.Id);

    // this.selectedcontainers = this.containerTypeList.filter(x => x.Checked == true).map(x => x.Id);

  }

  onRemissionTypeItemCheckedChange(event: MultiselectOption) {
    // if (event.Checked) {
    //   this.remissionTypeCheckedList.push(event);
    // }
    // else{
    //   this.remissionTypeCheckedList = this.remissionTypeCheckedList.filter(x=>x.Id!=event.Id);
    // }


    // this.remissionTypeSelected = this.remissionTypeCheckedList.map(x => x.Id).join(',');

    // this.selectedcontainers = event.map(x => x.Id);

  }


  async Download() {
    this.working = true;
    await this.globalService.messageToast("Descargando Archivo", 'loading');
    await this.multichannelSerice.getRouteBalanceReport(this.selectedUnit.UnitId, this.channelId, this.routeId, this.selectedcontainers, this.startDate, this.endDate)
      .toPromise()
      .then(async resp => {
        if (resp == null) {
          this.working = false;
          await this.globalService.messageToast("No se ha encontrado información.", 'warning');
          return;
        }
        let blob = new Blob([resp], { type: resp.type });
        const anchor = document.createElement('a');
        anchor.download = `Reporte Saldo de Rutas.xlsx`;
        anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
        anchor.click();
        this.working = false;
        await this.globalService.messageToast("Archivo Descargado", 'success');
      })
      .catch(async error => {
        this.working = false;
        console.error(error);
        await this.globalService.messageToast(error.error.Message, 'error');
      });
  }

}

import { BaseModel } from './BaseModel.Model';
export class Item extends BaseModel {
    Org: number;
    ItemId: number;
    ItemCode: number;
    ItemDescription: string;
    ItemActive: boolean;

    constructor() {
        super();
    }
}

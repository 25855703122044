import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { NoticeComponent } from './notice/notice.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BalanceComponent } from './balance/balance.component';
import { MldBalanceComponent } from './mld-balance/mld-balance.component';
import { InventoryCeveComponent } from './inventory-cut/inventory-ceve/inventory-ceve.component';
import { AuthGuard } from '../common/guards/auth-guard.guard';
import { FormsModule } from '@angular/forms';
import { InventoryFactoryComponent } from './inventory-cut/inventory-factory/inventory-factory.component';
import { EsabMovementsComponent } from './esab-movements/esab-movements.component';
import { AutomatictransferCatComponent } from './automatictransfer-cat/automatictransfer-cat.component';
import { AutomatictransferDetComponent } from './automatictransfer-det/automatictransfer-det.component';
import { BalanceAdjustComponent } from './balance-adjust/balance-adjust.component';
import { DatePipe } from '@angular/common';
import { NoticemldComponent } from './noticemld/noticemld.component';
import { MldInventoryFactoryComponent } from './inventory-cut/mld-inventory-factory/mld-inventory-factory.component';
import { AdjustCeveComponent } from './adjust/adjust-ceve/adjust-ceve.component';
import { AdjustFactoryComponent } from './adjust/adjust-factory/adjust-factory.component';
import { AdjustCeveCutComponent } from './adjust/adjust-ceve-cut/adjust-ceve-cut.component';
import { AdjustFactoryCutComponent } from './adjust/adjust-factory-cut/adjust-factory-cut.component';



const routes: Routes = [
  { path: 'notice', component: NoticeComponent, canActivate: [AuthGuard] },
  { path: 'noticemld', component: NoticemldComponent, canActivate: [AuthGuard] },
  { path: 'balance', component: BalanceComponent, canActivate: [AuthGuard] },
  { path: 'mldbalance', component: MldBalanceComponent, canActivate: [AuthGuard] },
  { path: 'inventory-cut/inventory-ceve', component: InventoryCeveComponent, canActivate: [AuthGuard] },
  { path: 'inventory-cut/inventory-factory', component: InventoryFactoryComponent, canActivate: [AuthGuard] },
  { path: 'inventory-cut/mld-inventory-factory', component: MldInventoryFactoryComponent, canActivate: [AuthGuard] },
  { path: 'esab-movements', component: EsabMovementsComponent, canActivate: [AuthGuard] },
  { path: 'automatictransfer-cat', component: AutomatictransferCatComponent, canActivate: [AuthGuard] },
  { path: 'automatictransfer-det', component: AutomatictransferDetComponent, canActivate: [AuthGuard] },
  { path: 'balance-adjust', component: BalanceAdjustComponent, canActivate: [AuthGuard] },
  { path: 'adjust/ceve', component: AdjustCeveComponent, canActivate: [AuthGuard] },
  { path: 'adjust/factory', component: AdjustFactoryComponent, canActivate: [AuthGuard] },
];

@NgModule({
  declarations: [NoticeComponent,
    BalanceComponent,
    MldBalanceComponent,
    InventoryCeveComponent,
    InventoryFactoryComponent,
    EsabMovementsComponent,
    AutomatictransferCatComponent,
    AutomatictransferDetComponent,
    BalanceAdjustComponent,
    NoticemldComponent,
    MldInventoryFactoryComponent,
    AdjustCeveComponent,
    AdjustFactoryComponent,
    AdjustCeveCutComponent,
    AdjustFactoryCutComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    FormsModule,
  ],
  exports: [
    AdjustCeveCutComponent,
  ],
  providers: [
    DatePipe
  ]
})
export class InventoryModule { }


import { BaseModel } from "./BaseModel.Model";

export class ZipCodeModel extends BaseModel {

    constructor() {
        super();
    }

    Org: number;
    IdZipCode: string;
    StateCode: string;
    StateDescription: string;
    CityCode: string;
    CityDescription: string;
    NeighborhoodCode: string;
    NeighborhoodName: string;

}
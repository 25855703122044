<div>
    <label>Tiempo restante: {{hours}} hrs {{minutes}} min {{seconds}} seg</label>
</div>

<section class="countDown-stopwatch" *ngIf="showCountdownBox">
    <div class="row">
        <div class="col col-md-12">
            <h3>Desea agregar m&aacute;s tiempo a la compulsa?</h3>
        </div>
    </div>
    <div class="row">
        <div class="col col-md-3">
            <button class="mr-1 btn btn-labeled btn-primary mb-2" (click)="increaseEventCountDown(15)" [disabled]="!countdown15Enabled">
                <span class="btn-label">
                    <i class="fas fa-clock"></i>
                </span>
                <span>+15 min.</span>
            </button>
        </div>
        <div class="col col-md-3">
            <button class="mr-1 btn btn-labeled btn-primary mb-2" (click)="increaseEventCountDown(30)" [disabled]="!countdown30Enabled">
                <span class="btn-label">
                    <i class="fas fa-clock"></i>
                </span>
                <span>+30 min.</span>
            </button>
        </div>
        <div class="col col-md-3">
            <button class="mr-1 btn btn-labeled btn-primary mb-2" (click)="increaseEventCountDown(45)" [disabled]="!countdown45Enabled">
                <span class="btn-label">
                    <i class="fas fa-clock"></i>
                </span>
                <span>+45 min.</span>
            </button>
        </div>
        <div class="col col-md-3">
            <button class="mr-1 btn btn-labeled btn-primary mb-2" (click)="increaseEventCountDown(60)" [disabled]="!countdown60Enabled">
                <span class="btn-label">
                    <i class="fas fa-clock"></i>
                </span>
                <span>+60 min.</span>
            </button>
        </div>
    </div>
</section>

<div class="row form-group">
    <div class="col col-xl-4 col-lg-3 col-md-4 col-sm-6" *ngIf="!adjApkRunning && !adjWebRunning && showWebPhisical">
        <button type="button" class="btn btn-labeled btn-outline-success mb-2" (click)="adjWeb()" style="width: 195px;">
            <!-- Toma de inventario -->
            <span class="btn-label">
                <i class="fas fa-globe"></i>
            </span>
            <span>Conteo f&iacute;sico en WEB</span>
        </button>
    </div>
    <div class="col col-xl-2 col-lg-3 col-md-4 col-sm-6" *ngIf="adjWebRunning">
        <button type="button" class="btn btn-labeled btn-outline-danger mb-2" (click)="cancelAdjweb()" style="width: 245px;">
            <!-- Cancelar toma de inventario -->
            <span class="btn-label">
                <i class="fas fa-globe"></i>
            </span>
             <span>Cancelar Conteo f&iacute;sico en WEB</span>
        </button>
    </div>


    <div class="col col-xl-4 col-lg-3 col-md-4 col-sm-6" *ngIf="!adjApkRunning && !adjWebRunning && showApkPhisical">
        <button type="button" class="mr-1 btn btn-labeled btn-outline-warning mb-0" (click)="adjApk()" style="display: flex; align-items: center;">
            <span class="btn-label">
                <i class="fas fa-mobile-alt" style="text-align: center; flex: 1;"></i>
            </span>
            <span>Conteo f&iacute;sico en APK</span>
        </button>
    </div>
    <div class="col col-xl-4 col-lg-3 col-md-4 col-sm-6" *ngIf="adjApkRunning">
        <button type="button" class="btn btn-labeled btn-outline-danger" (click)="cancelAdjapk()" style="width: 235px;">
            <span class="btn-label">
                <i class="fas fa-mobile"></i>
            </span>
            <span>Cancelar conteo f&iacute;sico en APK</span>
        </button>
    </div>


    <div class="col col-xl-4 col-lg-3 col-md-4 col-sm-6" *ngIf="!adjApkRunning && !adjWebRunning">
        <button type="button" class="mr-1 btn btn-labeled btn-outline-danger mb-2" (click)="cancelAdjust()" style="width: 180px;">
            <span class="btn-label">
                <i class="fas fa-times"></i>
            </span>
            <span>Cancelar compulsa</span>
        </button>
    </div>
</div>


<div class="row form-group">
    <div id="inventoryCutPage" class="col-md-12">
        <!-- <div *ngIf="status == 'PUBLISHED'" class="row">
            <span>&nbsp;<b>Fecha y hora de posteo: {{modifiedAt}}</b></span>
        </div> -->
        <div class="data-container black-text" [formGroup]="inventoryForm">
            <!-- <div class="full-border" [formGroup]="inventoryForm">
           <div class="d-flex">
                <div class="col-3">
                    <b>
                        Estatus: {{
                        status == 'NEW' ? 'NUEVO'
                        : status == 'SAVED' ? 'GUARDADO'
                        : status == 'CLOSED' ? 'CERRADO'
                        : status == 'INCOMPLETESIGNED' ? 'CERRADO'
                        : status == 'PUBLISHED' ? 'PUBLICADO'
                        : status == 'LAPSED' ? 'CADUCADO'
                        : 'OTRO'
                        }}
                    </b>
                </div>
                <div class="column_width center-data" *ngFor="let i of containerData">
                    <div class="column_width center-data "> <small>{{i.ContainerDescription}}</small></div>
                </div>
            </div> -->

            <div class="d-flex">
                <div class="col-3 row-title">
                    <!-- Caratula Numero: {{ceveCutId}} -->
                    Folio compulsa: {{adjustNode.AdjustCode}}
                </div>
                <div class="column_width center-data" *ngFor="let i of containerData">
                    <div class="column_width center-data ">
                        <h5>{{i.ContainerName}}</h5>
                    </div>
                </div>
            </div>

            <!-- <div *ngIf="status !='LAPSED'" class="d-flex" formArrayName="physicalInventory">
                <div class="col-3 bg-rowtitle full-border" >
                    1 Inventario Físico en Centro de ventas: {{adjustNumberCut}}
                </div>
                <div class="column_width center-data" *ngFor="let physical of physicalInventory.controls; index as i">
                    <input type="number" onkeyup="if(this.value<0){this.value= this.value * -1}" class="column_width center-data" [formControlName]="i">
                </div>
            </div> -->

            <div class="d-flex" formArrayName="physicalInventory">
                <div class="col-3 bg-rowtitle full-border row-title">
                    1 Inventario F&iacute;sico en Centro de ventas
                </div>
                <div class="column_width center-data" *ngFor="let physical of physicalInventory.controls; index as i">
                    <input type="number" style="text-align:right"
                           min="0"
                           step="1"
                           onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                           onkeyup="if(this.value<0){this.value= this.value * -1}"
                           onfocusout="if(this.value===''||this.value===null||this.value===undefined){this.value=0;}"
                           class="column_width" [formControlName]="i">
                </div>
            </div>

            <div class="d-flex " formArrayName="couponInventory">
                <div class="col-3 bg-rowtitle full-border row-title">
                    2 Vales de envases
                </div>
                <div class="column_width center-data full-border" *ngFor="let coupon of couponInventory.controls; index as i">
                    <!-- <div class="column_width center-data">{{coupon.value}}</div> -->
                    <input type="number" style="text-align:right"
                           min="0"
                           step="1"
                           onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                           onkeyup="if(this.value<0){this.value= this.value * -1}"
                           onfocusout="if(this.value===''||this.value===null||this.value===undefined){this.value=0;}"
                           class="column_width" [formControlName]="i">
                </div>
            </div>


            <div class="d-flex ">
                <div class="col-3 bg-rowtitle full-border row-title">
                    &nbsp;&nbsp;2.1 Vales de envases AS
                </div>
                <div class="column_width center-data full-border" *ngFor="let paybill of paybillAS">
                    <div class="column_width center-data ">{{paybill | number:'1.0':'en'}}</div>
                </div>
            </div>

            <div class="d-flex " formArrayName="promissoryNoteInventory">
                <div class="col-3 bg-rowtitle full-border row-title">
                    3 Pagares
                </div>
                <div class="column_width center-data full-border" *ngFor="let promissory of promissoryNoteInventory.controls; index as i">
                    <!-- <div class="column_width center-data">{{promissory.value}}</div> -->
                    <input type="number" style="text-align:right"
                           min="0"
                           step="1"
                           onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                           onkeyup="if(this.value<0){this.value= this.value * -1}"
                           onfocusout="if(this.value===''||this.value===null||this.value===undefined){this.value=0;}"
                           class="column_width" [formControlName]="i">
                </div>
            </div>

            <div class="d-flex " formArrayName="totalInventoryVehicle">
                <div class="col-3 bg-rowtitle full-border row-title">
                    4 Existencia Total en Veh&iacute;culos
                </div>
                <div class="column_width center-data full-border" *ngFor="let totalVehicle of totalInventoryVehicle.controls">
                    <div class="column_width center-data "> {{(totalVehicle.value >= 0 ? totalVehicle.value : 0) | number:'1.0':'en'}} </div>
                </div>
            </div>

            <div class="d-flex ">
                <div class="col-3 bg-rowtitle full-border row-title">
                    &nbsp;&nbsp;4.1 Existencia chofer - recolecci&oacute;n
                </div>
                <div class="column_width center-data full-border" *ngFor="let asVeh of asDriverVehicles">
                    <div class="column_width center-data ">{{asVeh | number:'1.0':'en'}}</div>
                </div>
            </div>

            <div class="d-flex ">
                <div class="col-3 bg-rowtitle full-border row-title">
                    &nbsp;&nbsp;4.2 Existencia AS
                </div>
                <div class="column_width center-data full-border" *ngFor="let asVeh of asClients">
                    <div class="column_width center-data ">{{asVeh | number:'1.0':'en'}}</div>
                </div>
            </div>

            <div class="d-flex " formArrayName="rtmVehicles">
                <div class="col-3 bg-rowtitle full-border row-title">
                    &nbsp;&nbsp;4.3 Existencia veh&iacute;culos (Viernes)
                </div>
                <div class="column_width center-data full-border" *ngFor="let rtm of rtmVehicles.controls; index as i">
                    <!-- <div class="column_width center-data">{{rtm.value}}</div> -->
                    <input type="number" style="text-align:right"
                           min="0"
                           step="1"
                           onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                           onkeyup="if(this.value<0){this.value= this.value * -1}"
                           onfocusout="if(this.value===''||this.value===null||this.value===undefined){this.value=0;}"
                           class="column_width" [formControlName]="i">
                </div>
            </div>

            <div class="d-flex " formArrayName="routeCharges">
                <div class="col-3 bg-rowtitle full-border row-title">
                    &nbsp;&nbsp;4.4 Cargos de rutas (del d&iacute;a de la compulsa)
                </div>
                <div class="column_width center-data full-border" *ngFor="let route of routeCharges.controls; index as i">
                    <!-- <div class="column_width center-data">{{route.value}}</div> -->
                    <input type="number" style="text-align:right"
                           min="0"
                           step="1"
                           onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                           onkeyup="if(this.value<0){this.value= this.value * -1}"
                           onfocusout="if(this.value===''||this.value===null||this.value===undefined){this.value=0;}"
                           class="column_width" [formControlName]="i">
                </div>
            </div>

            <div class="d-flex " formArrayName="stockAllocation">
                <div class="col-3 bg-rowtitle full-border row-title">
                    &nbsp;&nbsp;4.5 Stock Allocation
                </div>
                <div class="column_width center-data full-border" *ngFor="let stock of stockAllocation.controls; index as i">
                    <!-- <div class="column_width center-data">{{stock.value}}</div> -->
                    <input type="number" style="text-align:right"
                           min="0"
                           step="1"
                           onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                           onkeyup="if(this.value<0){this.value= this.value * -1}"
                           onfocusout="if(this.value===''||this.value===null||this.value===undefined){this.value=0;}"
                           class="column_width" [formControlName]="i">
                </div>
            </div>

            <div class="d-flex " formArrayName="vanLoadRejection">
                <div class="col-3 bg-rowtitle full-border row-title">
                    &nbsp;&nbsp;4.6 Van Load Rejection
                </div>
                <div class="column_width center-data full-border" *ngFor="let vanLoad of vanLoadRejection.controls; index as i">
                    <!-- <div class="column_width center-data">{{vanLoad.value}}</div> -->
                    <input type="number" style="text-align:right"
                           min="0"
                           step="1"
                           onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                           onkeyup="if(this.value<0){this.value= this.value * -1}"
                           onfocusout="if(this.value===''||this.value===null||this.value===undefined){this.value=0;}"
                           class="column_width" [formControlName]="i">
                </div>
            </div>

            <!--             
            <div class="d-flex ">
                <div class="col-3 bg-rowtitle full-border">
                    &nbsp;&nbsp;4.7 Comodatos Vigentes
                </div>
                <div class="column_width center-data  full-border" *ngFor="let data of currentComodato">
                    <div class="column_width center-data">{{data}}</div>
                </div>
            </div>

            <div class="d-flex ">
                <div class="col-3 bg-rowtitle full-border">
                    &nbsp;&nbsp;4.8 Comodatos Vencidos
                </div>
                <div class="column_width center-data  full-border" *ngFor="let data of expiredComodato">
                    <div class="column_width center-data">{{data}}</div>
                </div>
            </div> -->

            <div class="d-flex ">
                <div class="col-3 bg-rowtitle full-border row-title">
                    5 Tr&aacute;nsito
                </div>
                <div class="column_width center-data full-border" *ngFor="let t of transit">
                    <div class="column_width center-data ">{{t | number:'1.0':'en'}}</div>
                </div>
            </div>

            <div *ngIf="isNodeLeader" class="d-flex ">
                <div class="col-3 bg-rowtitle full-border row-title">
                    5.1 Tr&aacute;nsito de concentrador
                </div>
                <div class="column_width center-data full-border" *ngFor="let t of transitLeader">
                    <div class="column_width center-data ">{{t | number:'1.0':'en'}}</div>
                </div>
            </div>

            <div class="d-flex white-text" formArrayName="totalPhysicalInventory">
                <div class="col-3 bg-total-color  full-border row-title">
                    Inv. F&iacute;sico Total
                </div>
                <div class="column_width center-data  bg-total-color"
                     *ngFor="let totalPhysicalInv of totalPhysicalInventory.controls;">
                    <div class="column_width center-data ">{{totalPhysicalInv.value | number:'1.0':'en'}}</div>
                </div>
            </div>


            <!-- <div class="d-flex collapse-border over-color">
                <div class="col-3 bg-rowtitle full-border pointer" (click)="toggleChargeCollapsed()">
                    <div [ngClass]="{'arrow-left': chargedIsCollapsed,'arrow-down': !chargedIsCollapsed }"></div>
                    6 Equipo cobrado a colaboradores
                </div>
                <div class="column_width center-data full-border" *ngFor="let charge of chargedToCollaborator">
                    <div class="column_width center-data ">{{charge}}</div>
                </div>
            </div>

            
            <div class="d-flex" [collapse]="chargedIsCollapsed" [isAnimated]="true">
                <div class="col-3 bg-rowtitle full-border">
                    &nbsp;&nbsp; 6.1 Vale de chofer
                </div>
                <div class="column_width center-data full-border" *ngFor="let charge of valeChofer">
                    <div class="column_width center-data ">{{charge}}</div>
                </div>
            </div>

            <div class="d-flex" [collapse]="chargedIsCollapsed" [isAnimated]="true">
                <div class="col-3 bg-rowtitle full-border">
                    &nbsp;&nbsp; 6.2 Vale cobrado a colaboradores
                </div>
                <div class="column_width center-data full-border" *ngFor="let charge of valeColabs">
                    <div class="column_width center-data ">{{charge}}</div>
                </div>
            </div>

            <div class="d-flex" [collapse]="chargedIsCollapsed" [isAnimated]="true">
                <div class="col-3 bg-rowtitle full-border">
                    &nbsp;&nbsp; 6.3 Vale de compulsas AS
                </div>
                <div class="column_width center-data full-border" *ngFor="let charge of compulsas">
                    <div class="column_width center-data ">{{charge}}</div>
                </div>
            </div>

            <div class="d-flex" [collapse]="chargedIsCollapsed" [isAnimated]="true">
                <div class="col-3 bg-rowtitle full-border">
                    &nbsp;&nbsp; 6.4 Comodatos Vencidos
                </div>
                <div class="column_width center-data full-border" *ngFor="let charge of expiredChargedComodato">
                    <div class="column_width center-data ">{{charge}}</div>
                </div>
            </div>

            <div class="d-flex ">
                <div class="col-3 bg-rowtitle full-border">
                    7 Bajas (Perdidas Fortuitas)
                </div>
                <div class="column_width center-data " *ngFor="let i of losses">
                    <div class="column_width center-data ">{{i}}</div>
                </div>
            </div>

            <div class="d-flex " formArrayName="diffNotAttributable">
                <div class="col-3 bg-total-color full-border">
                    Diferencia no imputable al Ceve
                </div>
                <div class="column_width center-data  bg-total-color" *ngFor="let diff of diffNotAttributable.controls">
                    <div class="column_width center-data ">{{diff.value}}</div>
                </div>
            </div>

            <div class="d-flex ">
                <div class="col-3 bg-rowtitle full-border">
                    8 Recuperacion Seguridad
                </div>
                <div class="column_width center-data  full-border" *ngFor="let recovery of securityRecovery">
                    <div class="column_width center-data ">{{recovery}}</div>
                </div>
            </div>

            
            <div class="d-flex ">
                <div class="col-3 white-text bg-blue-color full-border">
                    Inventario físico del inventario anterior
                </div>
                <div class="column_width center-data  full-border bg-blue-color" *ngFor="let inv of previousInventory">
                    <div class="column_width white-text center-data ">{{inv}}</div>
                </div>
            </div>

           
            <div class="d-flex ">
                <div class="col-3 white-text bg-blue-color full-border">
                    Entradas
                </div>
                <div class="column_width center-data  full-border bg-blue-color" *ngFor="let inv of inputMovements">
                    <div class="column_width white-text center-data ">{{inv}}</div>
                </div>
            </div>

           
            <div class="d-flex ">
                <div class="col-3 white-text bg-blue-color full-border">
                    Salidas
                </div>
                <div class="column_width center-data  full-border bg-blue-color" *ngFor="let inv of outputMovements">
                    <div class="column_width white-text center-data ">{{inv}}</div>
                </div>
            </div> 

            <div class="d-flex">
                <div class="col-3 full-border" style="display: none;">
                    Inventario Teórico (comprobacion)
                </div>
                <div class="column_width center-data  full-border" *ngFor="let inv of invAvailableCalculated">
                    <div style="display: none;" class="column_width center-data">{{inv}}</div>
                </div>
            </div> -->

            <div class="d-flex">
                <div class="col-3 bg-rowtitle bg-blue-color full-border row-title">
                    Inventario Te&oacute;rico
                </div>
                <div class="column_width center-data full-border bg-blue-color"
                     *ngFor="let supposed of supposedInventory; index as i">
                    <div
                         class="column_width center-data">
                        {{supposed | number:'1.0':'en'}}
                    </div>
                </div>
            </div>

            <div class="d-flex " formArrayName="diffAttributable">
                <div class="col-3 full-border bg-total-color row-title">
                    Diferencia de certificaci&oacute;n<br>(F&iacute;sico Total - (Te&oacute;rico CV's + Saldos AS))
                </div>
                <div class="column_width center-data  full-border bg-total-color" *ngFor="let diff of diffAttributable.controls">
                    <div class="column_width center-data ">{{diff.value | number:'1.0':'en'}}</div>
                </div>
            </div>

            <!-- *ngIf="cutVersion > 0" -->
            <div class="d-flex ">
                <div class="col-3 full-border bg-green-color row-title">
                    Nuevo inventario de certificaci&oacute;n (F&iacute;sico Total - Saldos AS)
                </div>
                <div class="column_width center-data  bg-green-color"
                     *ngFor="let totalPhysicalInv of totalPhysicalInventory.controls; index as i">
                    <div class="column_width center-data ">{{(totalPhysicalInv.value - asClients[i]) | number:'1.0':'en'}}</div>
                </div>
            </div>

        </div>
    </div>


</div>

<div class="row">
    <div class="col-lg-12">
        <button type="button" class="mr-1 btn btn-labeled btn-outline-primary" (click)="saveAdjust()" *ngIf="displaySave" [disabled]="!enableSave">
            <span class="btn-label">
                <i class="fa fa-save"></i>
            </span>
            <span>Guardar compulsa</span>
        </button>
        <button type="button" class="mr-1 btn btn-labeled btn-outline-primary ml-2" (click)="signAdjust()" *ngIf="displaySign" [disabled]="!enableSign">
            <span class="btn-label">
                <i class="fas fa-file-signature"></i>
            </span>
            <span>Firmar compulsa</span>
        </button>
        <button type="button" class="mr-1 btn btn-labeled btn-outline-primary ml-2" (click)="reopenAdjust()" *ngIf="displayReopen" [disabled]="!enableReopen">
            <span class="btn-label">
                <i class="fas fa-undo-alt"></i>
            </span>
            <span>Reabrir compulsa</span>
        </button>

        <button type="button" class="mr-1 btn btn-labeled btn-outline-primary mb-2" (click)="saveAdjustApk()" *ngIf="displaySaveAPK" [disabled]="!enableSaveAPK">
            <span class="btn-label">
                <i class="fa fa-save"></i>
            </span>
            <span>Guardar Conteo APK</span>
        </button>
    </div>
</div>
<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
  <!-- START navbar header-->
  <div class="navbar-header">
    <div style="position: fixed" class="brand-logo" title="{{bd}}">
      <h3 style="color: white">&nbsp;{{environment}}</h3>
    </div>
    <div style="position: fixed" class="brand-logo-collapsed" title="{{bd}}">
      <h5 style="color: white">&nbsp;{{environment}}</h5>
    </div>

    <a class="navbar-brand pointer" (click)="home()">
      <div *ngIf="ceq" class="brand-logo">
        <img class="img-fluid" src="assets/img/logo_ceq.png" style="height:50px">
      </div>
      <div *ngIf="ceq" class="brand-logo-collapsed">
        <img class="img-fluid" src="assets/img/logo_ceq.png" style="height:30px" />
      </div>
      <div *ngIf="moldes" class="brand-logo">
        <img class="img-fluid" src="assets/img/logo_moldes.png" style="height:50px">
      </div>
      <div *ngIf="moldes" class="brand-logo-collapsed">
        <img class="img-fluid" src="assets/img/logo_moldes.png" style="height:30px" />
      </div>
    </a>
  </div>
  <h3 *ngIf="redirect" style="color: white;">Redirigiendo y recuperando datos, espere...</h3>
  <!-- END navbar header-->
  <!-- START Left navbar-->
  <ul class="navbar-nav mr-auto flex-row" *ngIf="!redirect">
    <li class="nav-item">
      <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
      <a class="nav-link d-none d-md-block d-lg-block d-xl-block" trigger-resize="" (click)="toggleCollapsedSideabar()" *ngIf="!isCollapsedText()">
        <em class="fas fa-bars"></em>
      </a>
      <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
      <a class="nav-link sidebar-toggle d-md-none" (click)="settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()">
        <em class="fas fa-bars"></em>
      </a>
    </li>
    <!-- START User avatar toggle-->
    <li class="nav-item d-none d-md-block">
      <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
      <a class="nav-link" (click)="toggleUserBlock($event)">
        <em class="icon-user"></em>
      </a>
    </li>
    <!-- END User avatar toggle-->
    <!-- START lock screen-->
    <li class="nav-item d-none d-md-block">
      <a class="nav-link pointer" title="Cerrar sesión" (click)="logOut()">
        <em class="icon-logout"></em>
      </a>
    </li>
    <!-- END lock screen-->
  </ul>
  <ul class="navbar-nav mr-lg-auto flex-row sizeM">
    <li class="d-md-block">
      <br />
      <p class="text-center" style="font-size:x-large; color:white" *ngIf="ceq">Control de Equipo</p>
      <p class="text-center" style="font-size:x-large; color:white" *ngIf="moldes">Control de Moldes</p>
    </li>
  </ul>
  <!-- END Left navbar-->
  <!-- START Right Navbar-->
  <ul class="navbar-nav flex-row">
    <li class="my-auto mr-3 brand-logo sizeM">
      <img class="img-fluid" src="assets/img/BM.png" style="height:40px">
    </li>&nbsp;

    <!-- MultiOrg -->
    <li class="my-auto mr-3 sizeS">
      <select class="form-control select2" [(ngModel)]="OutputUnit" (ngModelChange)='cmbOutputListChange($event)' name="OutputUnit" [hidden]="!MultiOrg">
        <option [value]="origin.UnitId" *ngFor="let origin of outputList">{{origin.CeveName}}</option>
      </select>
    </li>


    <!-- Update Version -->
    <li class="my-auto mr-3">
      <button type="button" class="btn btn-warning" (click)="updateCeqVersion()" [hidden]="!newVersion">
        <i class="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Actualizar Versión
      </button>
    </li>

    <!-- OrgId combo -->
    <li class="my-auto mr-3">
      <select name="cmbUserOrg" id="cmbUserOrg" class="form-control" (change)="cmbUserOrgChange($event.target.value)" hidden>
        <option *ngFor="let org of userOrgs" [value]="org.UnitId">{{org.UnitDescription}}</option>
      </select>
    </li>

    <!-- Search icon-->
    <li class="nav-item" hidden>
      <a class="nav-link" (click)="openNavSearch($event)">
        <em class="icon-magnifier"></em>
      </a>
    </li>

    <!-- Fullscreen (only desktops)-->
    <li class="nav-item d-none d-md-block">
      <a class="nav-link" #fsbutton (click)="toggleFullScreen($event)">
        <em class="fa fa-expand"></em>
      </a>
    </li>
    <!-- START Alert menu-->
    <li class="nav-item dropdown dropdown-list" dropdown>
      <a class="nav-link dropdown-toggle dropdown-toggle-nocaret pointer" id="notificationBell" dropdownToggle
      tooltip="!Número de remisión copiado!" triggers="focus" placement="right">
        <em class="icon-bell"></em>
        <span class="badge badge-danger">
        {{notifications.length}}</span>
      </a>
      <!-- START Dropdown menu-->
      <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-height-scrollbar">
        <div class="dropdown-item">
          <!-- START list group-->
          <div class="list-group max-width90">
            <div class="row">
              <div class="col-10">
                <button type="button" class="btn btn-block btn-light"
                        *ngIf="notifications.length !== 0" (click)="readAllNotification()">
                  Limpiar todas las notificaciones
                </button>
              </div>
              <div class="col" *ngIf="userLogin.NotificationSound">
                <button type="button" class="btn btn-block btn-primary"
                (click)="toggleNotificationSound()">
                <div class="fa fa-volume-up"></div>
              </button>
              </div>
              <div class="col" *ngIf="!userLogin.NotificationSound">
                <button type="button" class="btn btn-block btn-light"
                (click)="toggleNotificationSound()">
                <div class="fa fa-volume-off"></div>
              </button>
              </div>
            </div>
            <div class="list-group-item list-group-item-action" *ngIf="notifications.length === 0">
              <div class="media pointer">
                <div class="media-body">
                  <p class="m-0">Sin notificaciones</p>
                </div>
              </div>
            </div>
            <div class="list-group-item list-group-item-action" *ngFor="let notification of notifications">
              <div class="media pointer" style="max-width:700px;"  (click)="readNotification(notification)">
                <div class="media-body">
                  <p class="m-0">{{notification.Title}}</p>
                  <p class="m-0 text-muted text-sm" style="width:580px; white-space: normal;">{{notification.Text.split('|')[0]}}</p>

                  <p class="m-0 text-muted text-sm">{{notification.Text.split('|').length > 1 ? notification.Text.split('|')[1]: ''}}</p>
                  <p class="m-0 text-muted text-sm">{{notification.CreatedAt | date: 'medium'}}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- END list group-->
        </div>
      </div>
      <!-- END Dropdown menu-->
    </li>
    <!-- END Alert menu-->
    <!-- START Offsidebar button-->
    <li class="nav-item">
      <a class="nav-link" (click)="toggleOffsidebar(); $event.stopPropagation()">
        <em class="icon-present"></em>
      </a>
    </li>
    <!-- END Offsidebar menu-->
  </ul>
  <!-- END Right Navbar-->

  <app-navsearch [visible]="getNavSearchVisible()" (onclose)="setNavSearchVisible(false)"></app-navsearch>

</nav>
 <nav *ngIf="flagInvInProgress" class="sticky-top bg-alert noselect">
  <small class="color-white"><b>Toma de inventario en progreso...</b></small>&nbsp; &nbsp; &nbsp;
  <small class="color-white"><b>Quedan: {{hours}} horas, {{minutes}} minutos, {{seconds}} segundos.</b></small>&nbsp; &nbsp; &nbsp;
  <small class="color-red"><b>_________________________________</b></small>&nbsp; &nbsp; &nbsp;
  <small class="color-white"><b>Toma de inventario en progreso...</b></small>&nbsp; &nbsp; &nbsp;
  <small class="color-white"><b>Quedan: {{hours}} horas, {{minutes}} minutos, {{seconds}} segundos.</b></small>&nbsp; &nbsp; &nbsp;
  <small class="color-red"><b>_________________________________</b></small>&nbsp; &nbsp; &nbsp;
  <small class="color-white"><b>Toma de inventario en progreso...</b></small>&nbsp; &nbsp; &nbsp;
  <small class="color-white"><b>Quedan: {{hours}} horas, {{minutes}} minutos, {{seconds}} segundos.</b></small>&nbsp; &nbsp; &nbsp;
  <small class="color-red"><b>_________________________________</b></small>&nbsp; &nbsp; &nbsp;
  <small class="color-white"><b>Toma de inventario en progreso...</b></small>&nbsp; &nbsp; &nbsp;
  <small class="color-white"><b>Quedan: {{hours}} horas, {{minutes}} minutos, {{seconds}} segundos.</b></small>&nbsp; &nbsp; &nbsp;
  <small class="color-red"><b>_________________________________</b></small>&nbsp; &nbsp; &nbsp;
</nav>

<!-- Start Adjust Navbar -->

<!-- Replace to use pride theme -->
<!-- <nav *ngIf="adjustInProgress" class="sticky-top pride-bg-alert noselect">-->
<nav *ngIf="adjustInProgress" class="sticky-top adj-bg-alert noselect">
  <small class="adj-text"><b>Compulsa en progreso...</b></small>
  <small class="adj-timer"><b>Quedan: {{adjHoursHeader}} horas, {{adjMinutesHeader}} minutos, {{adjSecondsHeader}} segundos.</b></small>
  <small class="adj-separator"><b>_________________________________</b></small>
  <small class="adj-text"><b>Compulsa en progreso...</b></small>
  <small class="adj-timer"><b>Quedan: {{adjHoursHeader}} horas, {{adjMinutesHeader}} minutos, {{adjSecondsHeader}} segundos.</b></small>
  <small class="adj-separator"><b>_________________________________</b></small>
  <small class="adj-text"><b>Compulsa en progreso...</b></small>
  <small class="adj-timer"><b>Quedan: {{adjHoursHeader}} horas, {{adjMinutesHeader}} minutos, {{adjSecondsHeader}} segundos.</b></small>
  <small class="adj-separator"><b>_________________________________</b></small>
  <small class="adj-text"><b>Compulsa en progreso...</b></small>
  <small class="adj-timer"><b>Quedan: {{adjHoursHeader}} horas, {{adjMinutesHeader}} minutos, {{adjSecondsHeader}} segundos.</b></small>
  <small class="adj-separator"><b>_________________________________</b></small>
</nav>

<!-- END AdjustNavbar -->

<!-- END Top Navbar-->

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { List } from 'lodash';

import { CustomerQuickPick } from '../../common/models/CustomerQuickPick.Model';
import { ContainerBalance } from '../../common/models/ContainerBalance.Model';
import { GenericService } from '../../common/services/GenericServices';
import { Ceve } from '../../common/models/Ceve.Model';
import { Customer } from '../../common/models/Customer.Model';
import { Route } from '../../common/models/Route.Model';
import { number } from 'ngx-custom-validators/src/app/number/validator';

@Injectable({
  providedIn: 'root'
})
export class CustomerBalanceService {
  allChecked = false;
  constructor(private genericServ: GenericService) { }

  getInventoryExcel(unitId: number, fecha: string): Observable<any[]> {
    let url = `inventory/GetInventoryExcel/${unitId}/${fecha}`;
    return this.genericServ.getExcelRequest(url, {}, 0);
  }

  getCevesByUser(userId: number): Observable<Ceve[]> {
    let url = `ceve/getByUser/${userId}`;
    return this.genericServ.getRequest(url, {});
  }

  getCeveRoutes(userId: number): Observable<Ceve[]> {
    let url = `ceve/getCeveRoutes/${userId}`;
    return this.genericServ.getRequest(url, {});
  }

  getCustomersByCeve(unitId: number): Observable<Customer[]> {
    let url = `customer/getByCeve/${unitId}`;
    return this.genericServ.getRequest(url, {});
  }

  getRoute(unitId: number): Observable<Route> {
    let url = `route/${unitId}`;
    return this.genericServ.getRequest(url, {});
  }

  getAllCustomerBalance(org: number, userUnit: number, date: string): Observable<any[]> {
    let url = `inventory/${org}/${userUnit}/${date}/customerBalance`;
    return this.genericServ.getRequest(url, {}, 0);
  }

  getCustomerBalance(userUnit: number): Observable<ContainerBalance[]> {
    let url = `inventory/${userUnit}/invencont`;
    return this.genericServ.getRequest(url, {}, 0);
  }

  GetCustomers(org: number, ceveId: number, fecha: string): Observable<any[]> {
    let url = `customerquickpick/routes?org=${org}&ceveId=${ceveId}&fecha=${fecha}`;
    return this.genericServ.getRequest(url, {});
  }

  GetCustomersRoute(routeid: number): Observable<any[]> {
    let url = "customer/route/" + routeid;
    return this.genericServ.getRequest(url, {});
  }

  getEmployeeTypes(org: number): Observable<any[]> {
    let url = "Employee";
    return this.genericServ.getRequest(url, {}, org);
  }

  CreateCustomerQuickPick(customer: Customer): Observable<Customer> {
    let url = "CustomerQuickPick";
    return this.genericServ.postRequest(url, {}, 0, customer);
  }

  sendBalance(listCustomerQuickPick: List<CustomerQuickPick>, ceveId: number, fecha: string)
  {
    let url = "CustomerQuickPick?ceveId=" + ceveId + "&fecha=" + fecha;
    return this.genericServ.postRequest(url, {}, 0, listCustomerQuickPick);
  }

  getCustomerBalanceQuickPick(userUnit: number): Observable<ContainerBalance[]> {
    let url = `inventory/${userUnit}/invenquickpick`;
    return this.genericServ.getRequest(url, {}, 0);
  }

  GetCustomersFil(ceveId: number, date: Date): Observable<any[]> {
    let url = "CustomerQuickPick/GetCustomersFil ? ceveId="+ ceveId + "& fecha=" + date;
    return this.genericServ.getRequest(url, {});
  }

  SendAdjustment(data: any, org: number, user: string, ceveId: number, selectedDate: string): Observable<any[]>{
    let url = `SelfserviceManager/adjustment?org=${org}&user=${user}&ceveId=${ceveId}&loadDate=${selectedDate}`;
    return this.genericServ.postRequest(url,{},0,data);
  }

  SendWaveLoad(data: any, org: number, user: string, ceveId: number, selectedDate: string): Observable<any[]>{
    let url = `SelfserviceManager/waveLoad?org=${org}&user=${user}&ceveId=${ceveId}&loadDate=${selectedDate}`;
    return this.genericServ.postRequest(url,{},0,data);
  }

  getCustomerBalanceExcel(fechainicial: string, fechafinal: string): Observable<any[]> {
    let url = `CustomerQuickPick/GetCustomerBalanceAllNodes/${fechainicial}/${fechafinal}`;
    return this.genericServ.getExcelRequest(url, {}, 0);
  }

  getCustomerMovtosExcel(ceveId: number, fechainicial: string, fechafinal: string): Observable<any[]> {
    let url = `CustomerQuickPick/GetCustomerMovtos/${ceveId}/${fechainicial}/${fechafinal}`;
    return this.genericServ.getExcelRequest(url, {}, 0);
  }

  getBalanceResume(unitId: number, fecha: string): Observable<any[]> {
    let url = `CustomerQuickPick/GetCustomerBalanceResume/${unitId}/${fecha}`;
    return this.genericServ.getRequest(url, {}, 0);
  }


  //Paybill services
  getPaybillList(unitId: number = 0, creationDate: string = null, salesman: string = '', reference: string = "", selectedStatus: string = "") {
    let url = `Paybill/list`;
    let parameters;
    if (unitId != 0) { parameters == undefined ? parameters = { unitId: unitId } : parameters.unitId = unitId; }
    if (creationDate != '') { parameters == undefined ? parameters = { creationDate: creationDate } : parameters.creationDate = creationDate; }
    if (salesman != '') { parameters == undefined ? parameters = { salesman: salesman } : parameters.salesman = salesman; }
    if (reference != '') { parameters == undefined ? parameters = { reference: reference } : parameters.reference = reference; }
    if (selectedStatus != '') { parameters == undefined ? parameters = { status: selectedStatus } : parameters.status = selectedStatus; }
    console.log(parameters);
    return this.genericServ.getRequest(url, parameters, 0);
  }


  /**TODO:
   * Este metodo estaba inhabilitado en el SP4, pero en multicanal se habilito, revisar si se invoca en algun lado
   */
  getPaybillListExcel(unitId: number = 0, creationDate: string = null, salesman: string = '', reference: string = "", selectedStatus: string = "") {
    let url = `Paybill/excel`;
    let parameters;
    if (unitId != 0) { parameters == undefined ? parameters = { unitId: unitId } : parameters.unitId = unitId; }
    if (creationDate != '') { parameters == undefined ? parameters = { creationDate: creationDate } : parameters.creationDate = creationDate; }
    if (salesman != '') { parameters == undefined ? parameters = { salesman: salesman } : parameters.salesman = salesman; }
    if (reference != '') { parameters == undefined ? parameters = { reference: reference } : parameters.reference = reference; }
    if (selectedStatus != '') { parameters == undefined ? parameters = { status: selectedStatus } : parameters.status = selectedStatus; }
    console.log(parameters);
    return this.genericServ.getExcelRequest(url, parameters, 0);
  }

  getPaybillByReference(reference: string) {
    return this.genericServ.getRequest(`paybill/detail/${reference}`, {}, 0);
  }

  updatePaybill(reference: string, detailsUpdate: any) {
    return this.genericServ.putRequest(`paybill/recovery/${reference}`, {}, 0, detailsUpdate);
  }



  /**
   * Gets paybill data to print from database
   *
   * @param {string} remissionNumber - Remission number to get data
   * @returns {Observable<paybill>} - Observable with remission type
   * @memberof CustomerBalanceService
   */
  getpaybillPrintedPdf(reference: string): Observable<any> {
    return this.genericServ.getPdfRequest(`paybill/print/${reference}`, {}, 0);
  }


}

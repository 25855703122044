import { NgModule, ModuleWithProviders, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { TreeModule } from '@circlon/angular-tree-component';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { FlotDirective } from './directives/flot/flot.directive';
import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { EasypiechartDirective } from './directives/easypiechart/easypiechart.directive';
import { ColorsService } from './colors/colors.service';
import { CheckallDirective } from './directives/checkall/checkall.directive';
import { VectormapDirective } from './directives/vectormap/vectormap.directive';
import { NowDirective } from './directives/now/now.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { JqcloudDirective } from './directives/jqcloud/jqcloud.directive';
import { AgGridModule } from 'ag-grid-angular';
import { CeqTableComponent } from '../ceq/common/ceq-table/ceq-table.component';
import { CeqCommentsComponent } from '../ceq/common/ceq-comments/ceq-comments.component';
import { CeqDateLineComponent } from '../ceq/common/ceq-date-line/ceq-date-line.component';
import { LoadingComponent } from '../ceq/common/loading/loading.component';
import { EditChildRendered } from '../ceq/common/renders/edit-child-rendered';
import { PrintChildRendered } from '../ceq/common/renders/print-child-rendered';
import { DeleteChildRendered } from '../ceq/common/renders/delete-child-rendered';
import { ViewChildRendered } from '../ceq/common/renders/view-child-rendered';
import { SignaturePadModule } from 'angular2-signaturepad';
import { CeqMultiselectComponent } from '../ceq/common/ceq-multiselect/ceq-multiselect.component';

import { InvoiceChildRendered } from '../ceq/common/renders/invoice-child-render';
import { CommentChildRendered } from '../ceq/common/renders/comments-child-render';
import { AcceptChildRendered } from '../ceq/common/renders/accept-child-render';
import { FileChildRendered } from '../ceq/common/renders/file-child-render';
import { CapitalizeChildRendered } from '../ceq/common/renders/capitalize-child-render';

// Autocomplete
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

// Calendar
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';

// Locale
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { SelectChildRendered } from '../ceq/common/renders/select-child-rendered';

//animaciones sweetalert
import 'animate.css';

registerLocaleData(localeEs, 'es');

export function momentAdapterFactory() {
  return adapterFactory(moment);
};

// https://angular.io/styleguide#!#04-10
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    DatepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    TypeaheadModule.forRoot(),
    ToastrModule.forRoot(),
    AgGridModule.withComponents([DeleteChildRendered, EditChildRendered, ViewChildRendered, SelectChildRendered, PrintChildRendered, InvoiceChildRendered, CommentChildRendered, AcceptChildRendered, FileChildRendered, CapitalizeChildRendered]),
    TreeModule,
    AutocompleteLibModule,
    SignaturePadModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: momentAdapterFactory,
    })
  ],
  providers: [
    ColorsService,
    { provide: LOCALE_ID, useValue: 'es' }
  ],
  declarations: [
    FlotDirective,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    CeqTableComponent,
    CeqCommentsComponent,
    CeqDateLineComponent,
    DeleteChildRendered,
    EditChildRendered,
    PrintChildRendered,
    ViewChildRendered,
    LoadingComponent,
    SelectChildRendered,
    InvoiceChildRendered,
    CommentChildRendered,
    AcceptChildRendered,
    FileChildRendered,
    CapitalizeChildRendered,
    CeqMultiselectComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CarouselModule,
    CollapseModule,
    DatepickerModule,
    BsDatepickerModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    RatingModule,
    TabsModule,
    TimepickerModule,
    TooltipModule,
    PopoverModule,
    TypeaheadModule,
    ToastrModule,
    FlotDirective,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    AgGridModule,
    CeqTableComponent,
    TreeModule,
    AutocompleteLibModule,
    SignaturePadModule,
    CalendarModule,
    LoadingComponent,
    CeqCommentsComponent,
    CeqDateLineComponent,
    CeqMultiselectComponent
  ],
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}

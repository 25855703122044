<div class="content-heading">
    <div class="header-container">
        <h3>Compulsa Plantas y Clientes Terceros</h3>
        <small>Ajuste de inventario</small>
    </div>
</div>

<div class="container-fluid">

    <div class="col-lg-12">
        <div class="card card-default">
            <div class="card-body">
                <div class="row">

                    <div class="col-xl-6 col-lg-6 col-md-12 form-group">
                        <label class="form-label">Planta</label>
                        <ng-autocomplete [data]="nodes"
                                         [itemTemplate]="itemOutputTemplate"
                                         [notFoundTemplate]="notFoundoutputTemplate"
                                         [debounceTime]="600"
                                         [minQueryLength]="3"
                                         [searchKeyword]="'UnitDescription'"
                                         (selected)='changeNode($event)'
                                         (inputCleared)="clearNode()"
                                         [(ngModel)]="selectedNodeName"
                                         placeholder="Planta para compulsar"
                                         [disabled]="adjusRunning">
                        </ng-autocomplete>
                        <ng-template #itemOutputTemplate let-item>
                            <a [innerHTML]="item.UnitDescription"></a>
                        </ng-template>

                        <ng-template #notFoundoutputTemplate let-notFound>
                            <div [innerHTML]="'No se encontraron coincidencias'"></div>
                        </ng-template>
                    </div>
                    <div class=" mt-auto" *ngIf="!adjusRunning && hasStatusActivity">
                        <div class="form-group">
                            <button *ngIf="!loading"
                                    class="mr-1  btn btn-labeled btn-outline-warning mb-2"
                                    (click)="ValidateAdjustState()"
                                    [disabled]="loading">
                                    <span class="btn-label">
                                     <i class="fa fa-check"></i>
                                    </span>
                                <span>Validar prerrequisitos</span>
                            </button>

                            <button *ngIf="loading"
                                    class="mr-1  btn btn-labeled btn-outline-warning mb-2"
                                    [disabled]="loading">
                                    <span class="btn-label">
                                     <i class="fa fa-circle-notch fa-spin"></i>
                                    </span> 
                                <span>Validando prerequisitos</span>
                            </button>
                        </div>
                    </div>
                    <div class="mt-auto ml-2" *ngIf="!adjusRunning && hasViewActivity">
                        <div class="form-group">
                            <button *ngIf="!loading"
                                    class="mr-1  btn btn-labeled btn-outline-primary mb-2"
                                    (click)="ViewCurrentAdjust()"
                                    [disabled]="loading">
                                    <span class="btn-label">
                                     <i class="fa fa-eye"></i>
                                    </span>
                                <span>Visualizar Compulsa</span>
                            </button>

                            <button *ngIf="loading"
                                    class="mr-1  btn btn-labeled btn-outline-primary mb-2"
                                    [disabled]="loading">
                                <i class="fa fa-circle-notch fa-spin"></i>&nbsp;
                                Cargando
                            </button>
                        </div>
                    </div>

                </div>

                <div class="row" *ngIf="!adjusRunning">
                    <div class="col col-12 ">
                        <h3>Tr&aacute;nsitos</h3>
                        <hr>
                    </div>
                    <!--Grid-->
                    <div class="col col-12 form-group">
                        <app-ceq-table #CeqGrid
                                       [gridAdditionalOptions]="gridOptions"
                                       [columnDef]="columnDefs"
                                       (apiEmitter)="gridEmiter($event)">
                        </app-ceq-table>
                    </div>
                </div>

                <app-adjust-factory-cut
                                        *ngIf="adjusRunning"
                                        [selectedNodeCut]="selectedNode"
                                        [adjustNode]="activeAdjust"
                                        (parentCancelAdjust)="cancelAdjust()">
                </app-adjust-factory-cut>

            </div>
        </div>
    </div>

</div>
<div class="card b mb-2">
  <div class="card-header bb" *ngIf="!isNew">
    <h4 class="card-title">Detalle de Compra {{purchase.orderId}} - {{purchase.statusDesc}}</h4>
  </div>
  <div class="card-header bb" *ngIf="isNew">
    <h4 class="card-title">Detalle de Compra {{purchase.orderId}} - Nuevo</h4>
  </div>
</div>

<div class="form-container">
  <div class="controls-container">
    <br>
    <form #purchaseForm="ngForm" (ngSubmit)="savePurchase(purchaseForm.value)">
      <fieldset>
        <div class="row">
          <div class="col-sm-3">
            <span>Pedido</span>
            <input type="text" class="form-control" maxlength="25" [(ngModel)]="purchase.orderId" name="orderId"
                   (change)="onChangeOrder($event)" [disabled]="!editedData || statusSelected === 8 || wasReceived"
                   required>
          </div>
          <div class="col-sm-3">
            <span>Proveedor</span>
            <ng-autocomplete [initialValue]="initialProvName" [placeholder]="'Búsqueda'" [data]="provData"
                             [disabled]="!editedData || statusSelected === 8 || wasReceived" [searchKeyword]="inputProvArgument"
                             (selected)='selectProvEvent($event)' [itemTemplate]="itemProvTemplate"
                             [notFoundTemplate]="notFoundProvTemplate" [debounceTime]="600" [isLoading]="isLoadingItemsProv"
                             [minQueryLength]="3" [(ngModel)]="selectedProvName" [ngModelOptions]="{standalone: true}"
                             (inputCleared)="cmbProvChange()">
            </ng-autocomplete>
            <ng-template #itemProvTemplate let-item>
              <a [innerHTML]="item.name"></a>
            </ng-template>
            <ng-template #notFoundProvTemplate let-notFound>
              <div [innerHTML]="'No se encontraron coincidencias'"></div>
            </ng-template>
          </div>
          <div class="col-sm-3">
            <span>Fecha Entrega</span>
            <input type="date" class="form-control" [(ngModel)]="purchase.deliverDate" (change)="onChangeDate($event)"
                   name="deliverDate" [disabled]="!editedData || statusSelected === 8 || wasReceived" min="{{currDateString}}" max="" />
          </div>
          <div class="col-sm-3">
            <span>Horario Entrega</span>
            <input type="time" class="form-control" maxlength="10" [(ngModel)]="purchase.deliverySchedule"
                   (change)="onChangeDate($event)" name="deliverySchedule" [disabled]="!editedData || statusSelected === 8 || wasReceived">
          </div>
        </div>

        <br>

        <div class="row">
          <div class="col-sm-4 form-group">
            <span>Nodo Compra</span>
            <ng-autocomplete [initialValue]="initialOutputName" [placeholder]="'Búsqueda'" [data]="outputData"
                             [disabled]="!editedData || statusSelected === 8 || wasReceived" [searchKeyword]="inputArgument"
                             (selected)='selectOutputEvent($event)' [itemTemplate]="itemOutputTemplate"
                             [notFoundTemplate]="notFoundoutputTemplate" [debounceTime]="600" [isLoading]="isLoadingItemsOutput"
                             [minQueryLength]="3" [(ngModel)]="selectedPurchaseName" [ngModelOptions]="{standalone: true}"
                             (inputCleared)="cmbOutputChange()">
            </ng-autocomplete>
            <ng-template #itemOutputTemplate let-item>
              <a [innerHTML]="item.name"></a>
            </ng-template>

            <ng-template #notFoundoutputTemplate let-notFound>
              <div [innerHTML]="'No se encontraron coincidencias'"></div>
            </ng-template>
          </div>

          <div class="col-sm-4 form-group">
            <span>Nodo Recibe</span>
            <ng-autocomplete [initialValue]="initialInputName" [placeholder]="'Búsqueda'" [data]="inputData"
                             [disabled]="!editedData || statusSelected === 8 || wasReceived" [searchKeyword]="inputArgument"
                             (selected)='selectInputEvent($event)' [itemTemplate]="itemInputTemplate"
                             [notFoundTemplate]="notFoundInputTemplate" [debounceTime]="600" [isLoading]="isLoadingItemsInput"
                             [minQueryLength]="3" [(ngModel)]="selectedReceivesName" [ngModelOptions]="{standalone: true}"
                             (inputCleared)="cmbInputChange()">
            </ng-autocomplete>
            <ng-template #itemInputTemplate let-item>
              <a [innerHTML]="item.name"></a>
            </ng-template>

            <ng-template #notFoundInputTemplate let-notFound>
              <div [innerHTML]="'No se encontraron coincidencias'"></div>
            </ng-template>
          </div>

          <div class="col-md-2 form-group" *ngIf="isNew">
            <span class="form-label">Moneda</span>
            <select [(ngModel)]="currencySelected" (change)='selectCurrencyEvent()' class="form-control"
                    name="currencySelected">
              <option *ngFor="let obj of currency" [value]="obj.currencyId">{{obj.currencyCode}} - {{obj.currencyName}}
              </option>
            </select>
          </div>

          <!-- <div class="col">
            <span>Estatus</span>
            <input type="text" class="form-control w-80" maxlength="30" [(ngModel)]="purchase.statusDesc"
              name="statusDesc" disabled>
          </div> -->
        </div>

        <div class="row">
          <div class="col-sm-12 form-container">
            <div class="desktop">
              <div class="controls-container">
                <div class="subtitle-container">
                  <div class="row">
                    <div class="col-sm-3 cell-header">
                      <h5>Art&iacute;culo</h5>
                    </div>
                    <div class="col-sm-3 cell-header">
                      <h5>Cantidad Programada</h5>
                    </div>
                    <div class="col-sm-3 cell-header">
                      <h5>Cantidad Recibida</h5>
                    </div>
                    <div class="col-sm-3 cell-header">
                      <h5>Cantidad Conciliada</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row controls-container" *ngFor="let det of purchaseDetail">
                <div class="col-sm-3 cell borders">
                  <ng-autocomplete [initialValue]="initialInputName" [placeholder]="'Búsqueda'" [data]="itemsData"
                                   [disabled]="!editedData 
                                            || statusSelected === 8 
                                            || wasReceived "
                                   [searchKeyword]="itemArgument"
                                   (selected)='selectItemEvent($event)' [itemTemplate]="itemItemTemplate"
                                   [notFoundTemplate]="notFoundItemTemplate" [debounceTime]="600" [isLoading]="isLoadingItemsInput"
                                   [minQueryLength]="3" [(ngModel)]="selectedItemDesc" [ngModelOptions]="{standalone: true}"
                                   (inputCleared)="cmbItemChange()">
                  </ng-autocomplete>
                  <ng-template #itemItemTemplate let-item>
                    <a [innerHTML]="item.itemDesc"></a>
                  </ng-template>

                  <ng-template #notFoundItemTemplate let-notFound>
                    <div [innerHTML]="'No se encontraron coincidencias'"></div>
                  </ng-template>
                </div>
                <div class="col-sm-3 cell borders">
                  <input type="number"
                         style="text-align:right"
                         class="form-control w-80"
                         maxlength="30"
                         value="det.quantity" min="1"
                         [(ngModel)]="det.quantity"
                         name="quantity"
                         (ngModelChange)='onChangeQuantity($event)'
                         [disabled]="!editedData 
                                  || statusSelected === 8   
                                  || wasReceived"
                         onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                </div>
                <div class="col-sm-3 cell borders">
                  <input type="number"
                         style="text-align:right"
                         class="form-control w-80"
                         maxlength="30"
                         value="det.quantityReceived"
                         min="1"
                         [(ngModel)]="det.quantityReceived"
                         name="quantityReceived"
                         (ngModelChange)='onChangeQuantityReceived($event)'
                         [disabled]="!receivePurchase || statusSelected === 8"
                         onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                  <i [hidden]="det.quantity !== det.quantityReceived"
                     class="fa fa-check-circle fa-2x color-success"></i>
                  <i [hidden]="det.quantity === det.quantityReceived"
                     class="fa fa-exclamation-circle fa-2x color-warning"></i>
                </div>

                <div class="col-sm-3 cell borders">
                  <input type="number"
                         style="text-align:right"
                         name="quantityConciliated"
                         class="form-control "
                         value="det.quantityConciliated"
                         min="0"
                         [(ngModel)]="det.quantityConciliated"
                         (ngModelChange)='onChangeQuantityConciliated($event)'
                         [disabled]="(statusSelected !== 2 && statusSelected !== 4)  
                                    || wasConciliated 
                                    || quantity === quantityReceived
                                    || action !== 'EDIPUR'"
                         onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                </div>

              </div>

              <br>
              <div class="row controls-container" *ngFor="let det of purchaseDetail">
                <div class="col-sm-4">
                  <span>Certificado por Seguridad&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <input type="checkbox" name="securityCertificate" (change)="onSecChange($event.target.checked)"
                         [(ngModel)]="det.securityCertificate" [disabled]="!receivePurchase || statusSelected === 8 || wasReceived">
                  &nbsp;{{det.securityCertDesc}}
                </div>
                <div class="col-sm-3">
                  Certificado por Control&nbsp;
                  <input type="checkbox" name="controlCertificate" (change)="onCtrlChange($event.target.checked)"
                         [(ngModel)]="det.controlCertificate" [disabled]="!receivePurchase || statusSelected === 8 || wasReceived">
                  &nbsp;{{det.controlCertDesc}}
                </div>
                <div class="col-sm-3">
                  <span>Lote / Serie</span>
                  <input type="text" class="form-control w-80" maxlength="30" [(ngModel)]="purchase.reference"
                         name="reference" (ngModelChange)="purchase.reference = $event.toUpperCase()"
                         [disabled]="!receivePurchase || statusSelected === 8 || wasReceived">
                </div>
              </div>

            </div>
          </div>
        </div>

        <br>

        <div class="row">
          <div class="col-sm-3">
            <span>Costo Total Equipo Programado</span>
            <input type="text" style="text-align:right"
                   class="form-control"
                   maxlength="30"
                   min="1"
                   [(ngModel)]="purchase.equipmentAmount"
                   name="totalEquipmentAmount"
                   (change)="onChangeEquipmentAmount($event)"
                   (focus)="onFocus_EquipmentAmount()"
                   (keypress)="onKeyPress($event)"
                   (blur)="formatCurrency_EquipmentAmount($event)"
                   [disabled]="!editedData 
                              || statusSelected === 3
                              || statusSelected === 4 
                              || statusSelected === 8">
          </div>
          <div class="col-sm-1"></div>
          <div class="col-sm-3">
            <span>Costo Total Equipo Facturado</span>
            <input type="text"
                   style="text-align:right"
                   class="form-control"
                   maxlength="30"
                   min="1"
                   [(ngModel)]="purchase.equipmentInvoiced"
                   name="totalEquipmentInvoiced"
                   (focus)="onFocus_EquipmentInvoiced()"
                   (keypress)="onKeyPress($event)"
                   (blur)="formatCurrency_EquipmentInvoiced($event)"
                   [disabled]="!editInvoicing
                              || statusSelected === 5 
                              || statusSelected === 8 
                              || (wasReceived && action!=='INVOICE')">
          </div>
          <div class="col-sm-1" style="padding-left: 0;">
            <span>&nbsp;</span><br>
            <i class="fa fa-check-circle fa-2x color-success" [hidden]="purchase.equipmentAmount !== purchase.equipmentInvoiced"></i>
            <i class="fa fa-exclamation-circle fa-2x color-warning" [hidden]="purchase.equipmentAmount === purchase.equipmentInvoiced"></i>
          </div>
        </div>

        <br>

        <div class="row">
          <div class="col-sm-3">
            <span>Costo Total Flete Programado</span>
            <input type="text" style="text-align:right"
                   class="form-control"
                   maxlength="30"
                   min="1"
                   [(ngModel)]="purchase.freightAmount"
                   name="totalFreightAmount"
                   (change)="onChangeFreightAmount($event)"
                   (focus)="onFocus_FreightAmount()"
                   (keypress)="onKeyPress($event)"
                   (blur)="formatCurrency_FreightAmount($event)"
                   [disabled]="!editedData 
                              || statusSelected === 3
                              || statusSelected === 4
                              || statusSelected === 8 ">
          </div>
          <div class="col-sm-1"></div>
          <div class="col-sm-3">
            <span>Costo Total Flete Facturado</span>
            <input type="text"
                   style="text-align:right"
                   class="form-control"
                   maxlength="30"
                   min="1"
                   [(ngModel)]="purchase.freightInvoiced"
                   name="totalFreightInvoiced"
                   (focus)="onFocus_FreightInvoiced()"
                   (keypress)="onKeyPress($event)"
                   (blur)="formatCurrency_FreightInvoiced($event)"
                   [disabled]="!editInvoicing 
                              || statusSelected === 5 
                              || statusSelected === 8
                              || (wasReceived && action!=='INVOICE')">
          </div>
          <div class="col-sm-1" style="padding-left: 0;">
            <span>&nbsp;</span><br>
            <i class="fa fa-check-circle fa-2x color-success" [hidden]="purchase.freightAmount !== purchase.freightInvoiced"></i>
            <i class="fa fa-exclamation-circle fa-2x color-warning" [hidden]="purchase.freightAmount === purchase.freightInvoiced"></i>
          </div>

          <div class="col-sm-2">
            <span>Capitalizaci&oacute;n</span>
            <input type="text"
                   class="form-control w-80"
                   maxlength="30"
                   [(ngModel)]="purchase.capitalization"
                   name="capitalization"
                   [disabled]="!editCapitaliza 
                              && !isCapitaliazation 
                              || statusSelected === 8 
                              ">
          </div>

        </div>

        <br>

        <div class="row">
          <div class="col-sm-3">
            <span>Factura</span>
            <input type="text" class="form-control" maxlength="30" [(ngModel)]="purchase.invoice" name="invoice"
                   (ngModelChange)="purchase.invoice = $event.toUpperCase()"
                   [disabled]="!editInvoicing 
                              || statusSelected === 8 
                              || (wasReceived && action!=='INVOICE')"
                   required>
          </div>
          <div class="col-sm-1"></div>
          <div class="col-sm-3">
            <span>Orden de compra de equipo</span>
            <input type="text" class="form-control" maxlength="30" [(ngModel)]="purchase.purchaseOrder"
                   name="purchaseOrder" (ngModelChange)="purchase.purchaseOrder = $event.toUpperCase()"
                   [disabled]="!editInvoicing 
                            || statusSelected === 8 
                            || (wasReceived && action!=='INVOICE')"
                   required>
          </div>
          <div class="col-sm-1"></div>
          <div class="col-sm-3">
            <span>No. De receipt de equipo</span>
            <input type="text" class="form-control" maxlength="30" [(ngModel)]="purchase.receiptNumber"
                   name="receiptNumber" (ngModelChange)="purchase.receiptNumber = $event.toUpperCase()"
                   [disabled]="!editInvoicing 
                              || statusSelected === 8 
                              || (wasReceived && action!=='INVOICE')"
                   required>
          </div>
        </div>

        <br />

        <div class="row">
          <div class="col-md-3 form-group ">
            <label class="form-label">
              Folio UUID de equipo
            </label>
            <input type="text"
                   class="form-control"
                   name="equipmentUuid"
                   maxlength="50"
                   [(ngModel)]="purchase.equipmentUuid"
                   (ngModelChange)="purchase.equipmentUuid = $event.toUpperCase()"
                   [disabled]="!editInvoicing 
                              || statusSelected === 8 
                              || (wasReceived && action!=='INVOICE')"
                   required>
          </div>

          <div class="col-md-3 form-group offset-1">
            <label class="form-label">
              Orden de compra de flete
            </label>
            <input type="text"
                   class="form-control"
                   maxlength="200"
                   name="freightPurchaseOrder"
                   [(ngModel)]="purchase.freightPurchaseOrder"
                   (ngModelChange)="purchase.freightPurchaseOrder = $event.toUpperCase()"
                   [disabled]="!editInvoicing 
                              || statusSelected === 8 
                              || (wasReceived && action!=='INVOICE')">
          </div>

          <div class="col-md-3 form-group offset-1">
            <label class="form-label">
              No. De receipt de flete
            </label>
            <input type="text"
                   class="form-control"
                   maxlength="200"
                   name="freightReceiptNumber"
                   [(ngModel)]="purchase.freightReceiptNumber"
                   [disabled]="!editInvoicing 
                              || statusSelected === 8 
                              || (wasReceived && action!=='INVOICE')
                              ">
          </div>
        </div>

        <br />

        <div class="row">
          <div class="col-sm-4">
            <span>Facturaci&oacute;n Cancelada: Factura / OC / Recibo</span>
            <input type="text" class="form-control" maxlength="30" [(ngModel)]="purchase.canceledInvoicing"
                   name="canceledInvoicing" disabled>
          </div>
        </div>

        <br>

        <div class="row">
          <div class="col-sm-3">
            <span>A&ntilde;o Capex</span>
            <input type="text" class="form-control w-80" maxlength="4" [(ngModel)]="purchase.capexYear"
                   name="capexYear" [disabled]="!editedData || statusSelected === 8 || (wasReceived && action!=='INVOICE')"
                   onkeypress="return event.charCode >= 48 && event.charCode <= 57">
          </div>
          <div *ngIf="!isNew" class="col-sm-3">
            <br>
            <button type="button" class="mr-1 btn btn-labeled btn-outline-primary mb-2" (click)="btnAttachement()" [disabled]="!toAttach">
              <span class="btn-label">
                <i class="fa fa-cloud-upload-alt"></i>
              </span>
              <span>Documentos Adjuntos</span>
            </button>
          </div>
          <div *ngIf="!isNew" class="col-sm-3">
            <br>
            <button type="button" class="mr-1 btn btn-labeled btn-outline-green mb-2" (click)="btnComments()" [disabled]="!toAttach">
              <span class="btn-label">
                <i class="fa fa-comments"></i>
              </span>
              <span>Comentarios</span>
            </button>

          </div>
          <div *ngIf="isNew" class="col-sm-8">
            <span>Comentarios</span>
            <input type="text" class="form-control" [(ngModel)]="purchase.comments" name="comments"
                   [disabled]="!editedData || statusSelected === 8 ">
          </div>
        </div>
      </fieldset>
    </form>
    <hr>

    <div class="row" *ngIf="!searching && editedData && !isNew">
      <div *ngIf="!isNew" class="col-3">
        <button type="button"
                style="display: flex; align-items: center;"
                class="mr-1 btn btn-labeled btn-outline-pink btn-block"
                (click)="btnDeleteQuantity()"
                [disabled]=" statusSelected === 1 
                          || statusSelected === 3 
                          || statusSelected === 5 
                          || statusSelected === 7 
                          || statusSelected === 8 
                          ">
          <span class="btn-label">
              <i class="fa fa-boxes" *ngIf="!saving"></i>
              <i class="fa fa-times corner-pin text-muted" *ngIf="!saving"></i>
              <i class="fa fa-circle-o-notch fa-spin" *ngIf="saving"></i>
          </span>
          <span *ngIf="!saving" style="text-align: center; flex: 1;">Borrar Cantidad Recibida</span>
          <span *ngIf="saving" style="text-align: center; flex: 1;">Procesando ...</span>
        </button>
      </div>
      <div *ngIf="!isNew" class="col-3">
        
        <button type="button"
                style="display: flex; align-items: center;" 
                class="btn btn-labeled btn-outline-info btn-block"
                (click)="btnDeleteInvoice()"
                [disabled]=" statusSelected !==5">
          <span class="btn-label">
            <i class="fa fa-file" *ngIf="!saving"></i>
            <i class="fa fa-times corner-pin text-muted" *ngIf="!saving"></i>
            <i class="fa fa-circle-o-notch fa-spin" *ngIf="saving"></i>
          </span>
          <span *ngIf="!saving" style="text-align: center; flex: 1;">Borrar Facturaci&oacute;n</span>
          <span *ngIf="saving" style="text-align: center; flex: 1;">Procesando ...</span>
        </button>
      </div>
      <div *ngIf="!isNew" class="col-3">
        <button type="button"
                style="display: flex; align-items: center;"
                class="mr-1 btn btn-labeled btn-outline-olive btn-block"
                (click)="btnRebillPurchase()"
                [disabled]=" statusSelected !==5">
          <span class="btn-label">
            <i class="fa fa-clipboard-check" *ngIf="!saving"></i>
            <i class="fa fa-circle-o-notch fa-spin" *ngIf="saving"></i>
          </span>
          
          <span *ngIf="!saving" style="text-align: center; flex: 1;">Refacturar</span>
          <span *ngIf="saving" style="text-align: center; flex: 1;">Procesando ...</span>
        </button>
      </div>
      <div *ngIf="!isNew" class="col-3">
        <button type="button"
                style="display: flex; align-items: center; justify-content: center;" 
                class="mr-1 btn btn-labeled btn-outline-warning btn-block"
                (click)="btnCancelOrder()"
                [disabled]="statusSelected !== 1 && statusSelected !== 7">
          <span class="btn-label">
              <i class="fa fa-times-circle" *ngIf="!canceling"></i>
              <i class="fa fa-circle-o-notch fa-spin" *ngIf="canceling"></i>
          </span>
          <span *ngIf="!canceling" style="text-align: center; flex: 1;">Cancelar Pedido</span>
          <span *ngIf="canceling" style="text-align: center; flex: 1;">Cancelando ...</span>
        </button>
      </div>
    </div>
    <br>
    <div *ngIf="!searching" class="row">
      <div class="col-lg-8"></div>
      <div [ngClass]="{'col-lg-2': isNew, 'col': !isNew}">
        <button type="submit" class="mr-1 btn btn-labeled btn-outline-success btn-block" style="display: flex; align-items: center;" (click)="savePurchase(true)"
                [disabled]="!purchaseForm.valid 
                          || saving 
                          || !editInvoicing && !receivePurchase && !conciliatePurchase && !editedData && !editInvoicing && !editCapitaliza
                          || (statusSelected===4 && quantityConciliated===0)
                          || (statusSelected===5 && (purchase.equipmentAmount!=purchase.equipmentInvoiced || purchase.freightAmount!=purchase.freightInvoiced))
                          || statusSelected === 8 
                          ">
            <span class="btn-label">
              <i class="fa fa-check" *ngIf="!saving"></i><i class="fa fa-circle-o-notch fa-spin" *ngIf="saving"></i>
          </span>
          <span *ngIf="!saving" style="text-align: center; flex: 1;">Guardar</span>
          <span *ngIf="saving" style="text-align: center; flex: 1;">Guardando ...</span>
        </button>
      </div>
      <div class="col-lg-2">
        <button 
          type="button" 
          class="mr-1 btn btn-labeled btn-outline-danger btn-block mb-2" 
          style="display: flex; align-items: center; justify-content: center;" 
          [disabled]="saving" 
          (click)="backClick()"
        >
          <span class="btn-label">
            <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
          </span>
          <span style="text-align: center; flex: 1;">
            Volver
          </span>
        </button>
      </div>
    </div>
  </div>

</div>
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from '../../common/services/GenericServices';
import { User } from '../../common/models/User.Model';
import { RoleByUser } from '../../common/models/RoleByUser.Model';
import { UnitNode } from "../../common/models/UnitNode.Model"
import { UserProfile } from '../../common/models/UserProfile.Model';
import { ProfileLevel } from '../../common/models/ProfileLevel.Model';
import { UserCombo } from '../../common/models/UserCombo.Model';


@Injectable({
  providedIn: 'root'
})
export class UserService {


  constructor(private genericService: GenericService) { }

  getComboUsers(orgId: number, unitId: number): Observable<UserCombo[]> {
    let url = `user/${orgId}/${unitId}/combo`;
    return this.genericService.getRequest(url, {}, 0);
  }

  getUsers(): Observable<User[]> {
    let url = "user";
    return this.genericService.getRequest(url, {}, 0);
  }

  getUser(userId: number): Observable<User> {
    let url = "user";
    return this.genericService.getRequest(url, {}, userId);
  }

  addUser(user: any): Observable<User> {
    let url = "user";
    return this.genericService.postRequest(url, {}, 0, user);
  }

  updateUser(user: User): Observable<User> {
    let url = "user";
    return this.genericService.putRequest(url, {}, 0, user);
  }

  deleteUser(userId: number): Observable<boolean> {
    let url = "user";
    return this.genericService.deleteRequest(url, {}, userId);
  }

  getMultiSystem(userLogin: string): Observable<any> {
    let url = "user/" + userLogin + "/multisystem";
    return this.genericService.getRequest(url, {});
  }

  getUserImage(userId: number): Observable<any> {
    let url = "user/" + userId + "/picture";
    return this.genericService.getRequest(url, {});
  }

  uploadImage(userId: number, image: File): Observable<boolean> {
    let url = "user";
    const formData = new FormData();
    formData.append('image', image);
    return this.genericService.postRequest(url, {}, userId, formData);
  }

  getUserAccess(userId: number): Observable<string[]> {
    let url = "user/" + userId + "/activities";
    return this.genericService.getRequest(url, {}, 0);
  }

  // devuelve si se tienen o no permisos para acceder a una pagina
  // del sistema.
  async getUserAccessPerPage(userId: number, routePage:string) {

    let routeNew = routePage.replace("/", "@");
    while (routeNew.search("/") != -1) {
      routeNew = routeNew.replace("/", "@");
    }

    let url = "user/"+userId+"/"+routeNew+"/page";
    return this.genericService.getRequest(url, {}, 0);
  }

  // devuelve las actividades del usuario firmado.
  getMyAccess(): Observable<string[]> {
    let url = "user/activities";
    return this.genericService.getRequest(url, {}, 0);
  }

  getUserRoles(userId: number): Observable<RoleByUser[]> {
    let url = "rolebyuser";
    return this.genericService.getRequest(url, {}, userId);
  }

  saveUSerRoles(userId: number, userRoles: RoleByUser[]): Observable<boolean> {
    let url = "rolebyuser/user/" + userId + "/setRoles";
    return this.genericService.postRequest(url, {}, 0, userRoles);
  }

  getNodeChildrens(org: number, nodeId: number, userId: number): Observable<UnitNode[]> {
    let url = "unit/" + org + "/" + nodeId + "/childrens";
    return this.genericService.getRequest(url, { userId: userId });
  }

  saveUserProfileNodes(org: number, userId: number, nodes: UserProfile[]): Observable<boolean> {
    let url = "userprofile/" + org + "/" + userId;
    return this.genericService.postRequest(url, {}, 0, nodes);
  }

  getUserProfileNodes(org: number, userId: number): Observable<UserProfile[]> {
    let url = "userprofile/" + org + "/" + userId;
    return this.genericService.getRequest(url, {});
  }

  /**
 * Returns an observable with a list of units assigned to profile user
 * @param org
 * @param userId
 */
  getProfileLevels(userId: number): Observable<ProfileLevel[]> {
    return this.genericService.getRequest(`userprofile/level/${userId}/nodes`, {}, 0);
  }

  resetPassword(userId: number): Observable<any> {
    let url = `security/${userId}/reseetpassword`;
    return this.genericService.putRequest(url, {}, 0, null);
  }

  validateNodeActive(nodeId: number){
    return this.genericService.getRequest(`unit/active/${nodeId}`, {});
  }

  getOrgList(): Observable<any> {
    let url = 'preuser/getOrg';
    return this.genericService.getRequest(url, {});
  }

  getUserOrgs(userLogin: string): Observable<any> {
    let url = `user/orgsUser/${userLogin}`;
    return this.genericService.getRequest(url, {});
  }

  saveUserOrgs(userLogin: string, data: any): Observable<any> {
    let url = `user/saveOrgsUser/${userLogin}`;
    return this.genericService.postRequest(url, {}, 0, data);
  }

  updateWidgetParams(userId: number, UseWidget: boolean, WidgetInterval: number){
    return this.genericService.postRequest(`user/${userId}/widgetParam`, {}, 0 ,{UseWidget,WidgetInterval});
  }

  updateNotificationSound(userId: number, notificationSound: boolean){
    return this.genericService.postRequest(`user/${userId}/notificationSound`, {}, 0 ,{notificationSound});
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { GenericService } from '../../common/services/GenericServices';
import { AdjustDetail, AdjustDetailCut } from './adjust-ceve-cut/adjust-ceve-cut.component';
import { CeqAdjust } from 'src/app/ceq/common/models/AdjustUnit.Model';
import { type } from 'os';
import { observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdjustService {

  constructor(private genericService: GenericService) { }

  getAdjustMaxHours(): Observable<any> {
    return this.genericService.getRequest(`configuration/ADJMAXHOURS`, {});
  }

  getUserCeves(org: number, userId: number) {
    return this.genericService.getRequest(`UserProfile/${userId}/ceve/multiorg`, {});
  }

  getUserNodes(org: number, userId: number) {
    return this.genericService.getRequest(`UserProfile/${org}/${userId}/all`, {});
  }

  getAdjustInventoryCut(unitId: number): Observable<any> {
    return this.genericService.getRequest(`adjust/${unitId}/inventory`, {});
  }

  validateAdjustActive(unitId: number) {
    return this.genericService.getRequest(`adjust/${unitId}/active`, {});
  }

  validateAdjustState(unitId: number): Observable<CeqAdjust> {
    return this.genericService.getRequest(`adjust/${unitId}/status`, {});
  }

  getAdjust(adjustCode: string): Observable<CeqAdjust> {
    return this.genericService.getRequest(`adjust/${adjustCode}`, {});
  }

  startAdjust(unitId: number): Observable<CeqAdjust> {
    return this.genericService.postRequest(`adjust/${unitId}/start`, {}, 0, null);
  }

  startApkAdjust(adjustCode: string): Observable<any> {
    return this.genericService.postRequest(`adjust/${adjustCode}/start/apk`, {}, 0, null);
  }

  cancelAdjust(adjustNumber: string, comment: string): Observable<any> {
    return this.genericService.postRequest(`adjust/${adjustNumber}/cancel`, {}, 0, { Comment: comment });
  }

  cancelApkAdjust(adjustNumber: string): Observable<any> {
    return this.genericService.postRequest(`adjust/${adjustNumber}/cancelapk`, {}, 0, null);
  }

  lapseAdjust(adjustNumber: string): Observable<any> {
    return this.genericService.postRequest(`adjust/${adjustNumber}/lapse`, {}, 0, null);
  }

  ongoingReport(unitId: number): Observable<any> {
    return this.genericService.getRequest(`report/ongoing/${unitId}`, {});
  }

  applyAdjust(adjustNumber: string): Observable<any> {
    return this.genericService.postRequest(`adjust/${adjustNumber}/apply`, {}, 0, null);
  }

  applyAdjustAPK(adjustNumber: string, newInv: any[]): Observable<any> {
    return this.genericService.postRequest(`adjust/${adjustNumber}/applyapk`, {}, 0, newInv);
  }

  saveAdjust(adjustNumber: string, newInv: any[]) {
    return this.genericService.postRequest(`adjust/${adjustNumber}/save`, {}, 0, newInv);
  }

  saveAdjustApk(adjustNumber: string, newInv: any[]) {
    return this.genericService.postRequest(`adjust/${adjustNumber}/save/apk`, {}, 0, newInv);
  }

  closeAdjust(adjustNumber: string): Observable<any> {
    return this.genericService.postRequest(`adjust/${adjustNumber}/close`, {}, 0, null);
  }

  signAdjust(adjustNumber: string): Observable<any> {
    return this.genericService.postRequest(`adjust/${adjustNumber}/sign`, {}, 0, null);
  }

  reopenAdjust(adjustNumber: string): Observable<any> {
    return this.genericService.postRequest(`adjust/${adjustNumber}/reopen`, {}, 0, null);
  }

  // getAdjustDetail(adjustNumber: string): Observable<AdjustDetail[]> {
  //   return this.genericService.getRequest(`adjust/${adjustNumber}/detail`, {}, 0);
  // }

  getAdjustDetailGrouped(adjustNumber: string): Observable<AdjustDetail[]> {
    return this.genericService.getRequest(`adjust/${adjustNumber}/detail/grouped`, {}, 0);
  }

  getTowerDetail(adjustNumber: string, towerNumber: number): Observable<AdjustDetail[]> {
    return this.genericService.getRequest(`adjust/${adjustNumber}/tower/${towerNumber}/detail`, {}, 0);
  }

  updateEventStatus(unitId: number, adjustNumber: string, newStatus: EventStatus) {
    return this.genericService.postRequest(`adjust/${unitId}/${adjustNumber}/Events/Status/${newStatus}`, {}, 0, null);
  }

  getAdjustCountdown(adjustNumber: string) {
    return this.genericService.getRequest(`adjust/${adjustNumber}/countdown`, {}, 0);
  }
  increaseEventCountdown(adjustNumber: string, newCountdown: string) {
    return this.genericService.postRequest(`adjust/${adjustNumber}/event/countdown/${newCountdown}`, {}, 0, null);
  }

  validateAvailableConnetions(connectionsNumber: number): Observable<any> {
    return this.genericService.getRequest(`adjust/connection/${connectionsNumber}`, {}, 0);
  }

  addConnection(connection: any): Observable<any> {
    return this.genericService.postRequest(`adjust/connection`, {}, 0, connection);
  }

  updateConnection(connection: any): Observable<any> {
    return this.genericService.postRequest(`adjust/connection/refresh`, {}, 0, connection);
  }

  deleteConnection(adjustCode: string): Observable<any> {
    return this.genericService.deleteRequest(`adjust/connection/${adjustCode}`, {}, 0);
  }

}

export type EventStatus =
  'CREATED' |
  'COUNTING' |
  'APK' |
  'LAPSED' |
  'CANCELED'
  ;
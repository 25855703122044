import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Inventory } from '../../common/models/Inventory.Model';
import { CeveByContainer } from "../../common/models/CeveByContainer.Model";
import { GenericService } from '../../common/services/GenericServices';
import { Ceve } from '../../common/models/Ceve.Model';
import { File } from '../../common/models/File.Model';
import { FactoryCut } from '../../common/models/FactoryCut.Model';
import { FactoryCutDetail } from '../../common/models/FactoryCutDetail.Model';
import { Container } from '../../common/models/Container.Model';

@Injectable({
  providedIn: 'root'
})
export class InventoryCutService {

  constructor(private genericServ: GenericService) { }

  getCevesByUser(userId: number): Observable<Ceve[]> {
    return this.genericServ.getRequest(`ceve/getByUser/${userId}`, {});
  }


  GetCeveByContainers(ceveId: number): Observable<CeveByContainer[]> {
    let url = "CeveByContainer"
    return this.genericServ.getRequest(url, {}, ceveId);
  }

  getInventoryByNode(node: number): Observable<Inventory[]> {
    return this.genericServ.getRequest("inventory?$filter=OrgUnit eq " + node, {});
  }

  getFactoryContainerListById(id: string): Observable<Container[]> {
    return this.genericServ.getRequest(`Container/ContainersByFactory/${id}`, {});
  }

  getFactoryContainerMldListById(id: string): Observable<Container[]> {
    return this.genericServ.getRequest(`Container/ContainersByFactoryMld/${id}`, {});
  }
  /**
   * Gets the detail of all ceve that have relation whit the factory node
   *
   * @param {*} org
   * @param {number} unitId
   * @param {Date} cutDate
   * @return {*}  {Observable<any>}
   * @memberof InventoryCutService
   */
  getCeveRelationCut(org: number, unitId: number, cutDate: Date): Observable<any> {
    return this.genericServ.getRequest(`inventory/${org}/${unitId}/${cutDate}/getCeveCutByFactory`, {});
  }

  /**
 * Gets the detail of all ceve that have relation whit the factory node
 *
 * @param {*} org
 * @param {number} unitId
 * @param {Date} cutDate
 * @return {*}  {Observable<any>}
 * @memberof InventoryCutService
 */
  getCeveRelationCutString(org: number, unitId: number, cutDate: string): Observable<any> {
    return this.genericServ.getRequest(`inventory/${org}/${unitId}/${cutDate}/getCeveCutByFactory`, {});
  }

  /**
   * Gets the factory cut saved by the desired day
   *
   * @param {number} org - Org of factory
   * @param {number} unitId - Unit id of factory
   * @param {Date} date - Date to get their inventory cut
   * @return {*}  {Observable<FactoryCut>}- factory cut object
   * @memberof InventoryCutService
   */
  getFactoryCutByDay(org: number, unitId: number, date: Date): Observable<FactoryCut> {
    return this.genericServ.getRequest(`FactoryCut/cutByDay/${org}/${unitId}/${date}`, {});
  }

  getFactoryCutByDayString(org: number, unitId: number, date: string): Observable<FactoryCut> {
    return this.genericServ.getRequest(`FactoryCut/cutByDay/${org}/${unitId}/${date}`, {});
  }

  getMldFactoryCutByDay(org: number, unitId: number, date: Date): Observable<FactoryCut> {
    return this.genericServ.getRequest(`FactoryCut/MldcutByDay/${org}/${unitId}/${date}`, {});
  }

  /**
   * Finds factory cut detail and retrieves it
   *
   * @param {string} factoryCutId - Factory cut id to get their detail
   * @return {*}  {Observable<FactoryCutDetail[]>} - factory cut detail list
   * @memberof InventoryCutService
   */
  getFactoryCutDetail(factoryCutId: string): Observable<FactoryCutDetail[]> {
    return this.genericServ.getRequest(`FactoryCut/detail/${factoryCutId}`, {});
  }

  getMldFactoryCutDetail(factoryCutId: string): Observable<FactoryCutDetail[]> {
    return this.genericServ.getRequest(`FactoryCut/Mlddetail/${factoryCutId}`, {});
  }

  /**
   * Add a new file to azure storage
   *
   * @param {File} file - file to add
   * @return {*}
   * @memberof InventoryCutService
   */
  addSign(file: File): any {
    return this.genericServ.postRequest(`Inventory/addSign`, {}, 0, file);
  }

  /**
   * Saves factory cut in database
   *
   * @param {any[]} factoryCut - factory cut to save
   * @return {*}  {*} - status request
   * @memberof InventoryCutService
   */
  addFactoryCut(factoryCut: any): Observable<any> {
    return this.genericServ.postRequest('FactoryCut', {}, 0, factoryCut);
  }
  addFactoryCutMld (factoryCut: any): Observable<any> {
    return this.genericServ.postRequest('FactoryCut/Mld', {}, 0, factoryCut);
  }

  /**
   * Removes a factory cut in database
   *
   * @param {string} factoryCutId - factory cut id to remove
   * @return {*}  {Observable<any>} - Status request
   * @memberof InventoryCutService
   */
  removeFactoryCut(factoryCutId: string): Observable<any> {
    return this.genericServ.deleteRequest(`FactoryCut/${factoryCutId}`, {}, 0);
  }

  getCeveByContainers(ceveId: number): Observable<any> {
    return this.genericServ.getRequest(`CeveByContainer/${ceveId}/inventory`, {});
  }
  getCeveActive(nodeId: number): Observable<any> {
    return this.genericServ.getRequest(`unit/active/${nodeId}`, {});
  }

  /**
 * Saves ceve cut in database
 *
 * @param {any[]} CeveCut - factory cut to save
 * @return {*}  {*} - status request
 * @memberof InventoryCutService
 */
  addCeveCut(ceveCut: any): Observable<any> {
    return this.genericServ.postRequest('CeveCut', {}, 0, ceveCut);
  }

  editCeveCut(ceveCut: any): Observable<any> {
    return this.genericServ.putRequest('CeveCut', {}, 0, ceveCut);
  }

  openCeveCut(ceveCutIdString: string) {
    var ceveCutId = new Object(ceveCutIdString);
    return this.genericServ.putRequest('CeveCut/open', {}, 0, ceveCutId);
  }

  publishCeveCut(ceveCutIdString: string) {
    var ceveCutId = new Object(ceveCutIdString);
    return this.genericServ.putRequest('CeveCut/publish', {}, 0, ceveCutId);
  }

  openFactoryCut(factoryCutIdString: string) {
    var factoryCutId = Object(factoryCutIdString);
    return this.genericServ.putRequest('FactoryCut/open', {}, 0, factoryCutId);
  }
  openMldFactoryCut(factoryCutIdString: string) {
    var factoryCutId = Object(factoryCutIdString);
    return this.genericServ.putRequest('FactoryCut/openMld', {}, 0, factoryCutId);
  }

  publishFactoryCut(factoryCutIdString: string) {
    var factoryCutId = Object(factoryCutIdString);
    return this.genericServ.putRequest('FactoryCut/publish', {}, 0, factoryCutId);
  }
  publishMldFactoryCut(factoryCutIdString: string) {
    var factoryCutId = Object(factoryCutIdString);
    return this.genericServ.putRequest('FactoryCut/publishMld', {}, 0, factoryCutId);
  }

  isNodeChild(org: number, unitId: number) {
    return this.genericServ.getRequest(`CeveCut/isNodeChild/${org}/${unitId}`, {});
  }
  isNodeLeader(org: number, unitId: number) {
    return this.genericServ.getRequest(`CeveCut/isNodeLeader/${org}/${unitId}`, {});
  }

  generateCeveCutSP(unitId: number, startDate: string, endDate: string) {
    return this.genericServ.postRequest(`CeveCut/GenerateCeveCut/${unitId}/${startDate}/${endDate}`, {}, 0, {});
  }

  generateFactoryCutSP(unitId: number, startDate: string, endDate: string) {
    return this.genericServ.postRequest(`FactoryCut/GenerateFactoryCut/${unitId}/${startDate}/${endDate}`, {}, 0, {});
  }

  /**
   * Get ceve cut in database
   *
   * @param {string} Date - factory cut to save
   * @param {number} ceveId - factory cut to save
   * @return {*}  {*} - status request
   * @memberof InventoryCutService
   */
  getCeveCut(ceveId: number, date: string): Observable<any> {
    return this.genericServ.getRequest(`CeveCut/${ceveId}/${date}`, {});
  }

  addMovementForInventoryCut(unitId: number, processDate: string, movementObject: Array<any>) {
    return this.genericServ.postRequest(`Inventory/movementForInventoryCut/${unitId}/${processDate}`, {}, 0, movementObject);
  }

  getCentralTime(): Observable<Date> {
    return this.genericServ.getRequest(`calendar/getCentralTime`, {}, 0);
  }

  getPreviousDate(dateOld: Date){
    console.log('dateOld.getMonth()')
    let date = new Date(dateOld)
    let year = date.getMonth() > 1 ? date.getFullYear() : (date.getFullYear() - 1);
    let month = date.getMonth() > 1 ? (date.getMonth() - 1) : (date.getMonth() +11);
    let dateDay = date.getDate();
    return new Date(year,month,dateDay,date.getHours(), date.getMinutes(), date.getSeconds());
  }
}

export enum FactoryCutLine {
    PREVIOUS = 1,
    PURCHASES = 7,
    RECYCLING = 8,
    OTHERS = 9,
    RECOVERY = 10,
    THEORIC = 11,
    PHYSICAL = 12,
    TRANSIT = 13,
    CEVE = 14,
    FACTORY = 15,
    REAL = 16,
    TOTALBALANCE = 17,
    DIFFERENCE = 18,
    PERCENTAGE = 19,
    MISCELLANEOUS = 20,
    ADJUSTFAB = 21,
    TOTALDIFFERENCES = 22,
};

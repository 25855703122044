import { BaseModel } from "./BaseModel.Model";

export class InventoryMovementDetail extends BaseModel {
  Org: number;
  MovementId: string;
  MovementType: string;
  MoveDate: string;
  ConteinerId: string;
  ConteinerType: string;
  ContainerTypeId: number;
  Quantity: number;


  constructor() {
    super();
    this.Org = 0;
    this.MovementId = '';
    this.MovementType = '';
    this.MoveDate = '';
    this.ConteinerId = '';
    this.ConteinerType = '';
    this.ContainerTypeId=0
    this.Quantity = 0;
  }


}

import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { toNumber } from 'lodash';
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';
import * as moment from 'moment';
import { AdjustDetail, FirebaseEvent, FirebaseTowers } from '../adjust-ceve-cut/adjust-ceve-cut.component';

import { GlobalFunctionsService } from 'src/app/ceq/common/functions/global-functions.service';
import { InventoryCutService } from '../../inventory-cut/inventory-cut.service';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { CalendarService } from 'src/app/ceq/configuration/calendar/calendar.service';
import { AdjustService } from '../adjust.service';
import { FactoryByContainer } from 'src/app/ceq/common/models/FactoryByContainer';
import { AdjustLineEnum } from '../../../common/models/adjustLineEnum.Enum';
import { FactoryCut } from '../../../common/models/FactoryCut.Model';
import { UserLogin } from 'src/app/ceq/common/models/UserLogin.Model';
import { Calendar } from 'src/app/ceq/common/models/Calendar.Model';
import { Container } from 'src/app/ceq/common/models/Container.Model';
import { BaseModel } from "../../../common/models/BaseModel.Model";
import { CeqAdjust } from 'src/app/ceq/common/models/AdjustUnit.Model';
import { Unit } from 'src/app/ceq/common/models/Unit.Model';

@Component({
  selector: 'app-adjust-factory-cut',
  templateUrl: './adjust-factory-cut.component.html',
  styleUrls: ['./adjust-factory-cut.component.scss']
})
export class AdjustFactoryCutComponent implements OnInit, OnDestroy {

  constructor(
    private globalFunctionService: GlobalFunctionsService,
    private db: AngularFireDatabase,
    private adjustServive: AdjustService,
    private settings: SettingsService,
    private calendarServ: CalendarService,
    private inventoryCutService: InventoryCutService) { }

  //Parent-child 
  @Output("parentCancelAdjust") parentCancelAdjust: EventEmitter<any> = new EventEmitter();

  @Input() selectedNodeCut: Unit;
  @Input() adjustNode: CeqAdjust;

  //subs
  eventsSub: Subscription;
  towersSub: Subscription;
  userInAdjustPage: boolean = false;

  //properties
  user: UserLogin;
  userActivities: string[] = [];
  adjWebRunning: boolean = false;
  adjApkRunning: boolean = false;
  phisicalInvActive: boolean = false;
  isSaving: boolean;

  displaySave: boolean = false;
  // displayClose: boolean = false;
  displaySign: boolean = false;
  displayReopen: boolean = false;

  enableSave: boolean = false;
  // enableClose: boolean = false;
  enableSign: boolean = false;
  enableReopen: boolean = false;

  showWebPhisical: boolean = true;
  showApkPhisical: boolean = true;

  displaySaveAPK: boolean = false;
  enableSaveAPK: boolean = false;

  showCountdownBox: boolean = false;
  adjLapTime;
  timer: any;
  currentDateTime: Date = new Date();
  hours: string = "0";
  minutes: string = "0";
  seconds: string = "0";

  countdownMaxTime: string = '2:30:00';
  countdown15Enabled: boolean = false;
  countdown30Enabled: boolean = false;
  countdown45Enabled: boolean = false;
  countdown60Enabled: boolean = false;


  async ngOnInit() {
    this.userInAdjustPage = true;
    this.user = this.settings.getUser();
    this.userActivities = this.settings.getUserAccess();
    //Cut
    await this.InitializeData()
      .then(async () => {
        this.validateAdjustStatus();

        await this.adjustServive.getAdjustMaxHours().toPromise()
          .then(resp => {
            this.countdownMaxTime = resp.Value;
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        this.showErrorMessage(err);
      });

  }

  ngOnDestroy(): void {
    this.userInAdjustPage = false;
    this.stopTimer();
    this.CloseFirebaseConnection();
  }

  showWebOptions() {
    this.displaySave = true;
    // this.displayClose = true;
    this.displaySign = true;
    this.displayReopen = true;
  }

  hideWebOptions() {
    this.displaySave = false;
    // this.displayClose = false;
    this.displaySign = false;
    this.displayReopen = false;
  }

  disableWebOptions() {
    this.enableSave = false;
    // this.enableClose = false;
    this.enableSign = false;
    this.enableReopen = false;
  }

  async reloadAdjust() {
    await this.adjustServive.getAdjust(this.adjustNode.AdjustCode)
      .toPromise()
      .then(resp => {
        this.adjustNode = resp;
        this.validateAdjustStatus();
      })
      .catch(err => {
        this.showErrorMessage(err);
      });
  }

  validateAdjustStatus() {
    console.log('status', this.adjustNode.Status);
    let counter = this.userActivities.includes('ADJINVWEB');
    let certifier = this.userActivities.includes('ADJCHKCERT');
    let onlyViewAdj = this.userActivities.includes("ADJVIEWWEB");

    this.phisicalInvActive = false;

    this.showWebOptions();
    this.disableWebOptions();
    if (this.adjustNode.Status == "CREATED") {
      //si el conteo de equipo en web no esta activo no se muestran las opciones
      if (this.adjWebRunning) {
        //when adjust is started or has ben reopened
        if (counter && !onlyViewAdj) {//Actividad de contador de inventario en web    
          this.phisicalInvActive = true;
          this.enableSave = true;
          this.enableSign = true;
        }
        // this.enableClose = true;
      }
      else {
        this.hideWebOptions();
      }
    }
    if (this.adjustNode.Status == "SAVED") {
      this.adjWebRunning = true;
      // this.enableClose = true;

      if (counter && !onlyViewAdj) {//Actividad de contador de inventario en web    
        this.phisicalInvActive = true;
        this.enableSign = true;
        this.enableSave = true;
      }
    }
    if (this.adjustNode.Status == "REOPENED") {
      this.adjWebRunning = true;

      if (counter && !onlyViewAdj) {//Actividad de contador de inventario en web    
        this.phisicalInvActive = true;
        this.enableSave = true;
        this.enableSign = true;
      }
    }
    if (this.adjustNode.Status == "SIGNED") {
      this.adjWebRunning = true;
      this.enableSign = true;
      this.enableReopen = true;

      if (counter) {
        this.enableSign = false;
        this.enableReopen = false;
      }

      //si ambos usuarios firmaron ya se aplica la compulsa
      if (this.adjustNode.CertifierUser !== '' && this.adjustNode.CertifiedUser !== '') {
        this.applyAdjust();
      }
    }
    if (this.adjustNode.Status == "SIGNEDAPK") {
      // this.enableSaveAPK = event.payload.val().Status == "COUNTED";
      if (counter && !onlyViewAdj) {//Actividad de contador de inventario en web    
        if (!this.isSaving) {
          this.applyAdjustAPK();
        }
      }
      else {
        this.hideLoading();
        this.CloseFirebaseConnection();
        this.phisicalInvActive = false;
        this.adjWebRunning = false;
        this.adjApkRunning = false;
        this.isSaving = false;
        Swal.fire("Compulsa", "Compulsa firmada correctamente, el Ajuste de inventario se aplicara en el ordenador del usuario contador.", "success")
          .then(() => {
            this.parentCancelAdjust.emit();
          });
      }
    }
    if (this.adjustNode.Status == "CLOSED") {
      this.adjWebRunning = true;
      // this.enableSign = true;
      // this.enableReopen = true;
    }
    if (this.adjustNode.Status == "APPLYED") {
      this.adjWebRunning = true;
    }
    if (this.adjustNode.Status == "APK") {
      if (counter && !onlyViewAdj) {//Actividad de contador de inventario en web 
        this.displaySaveAPK = true;
        this.enableSaveAPK = true;
      }
      this.hideWebOptions();
      this.adjApk(false);

    }

    // if (counter && this.adjustNode.CertifiedUser == '') {
    //   this.enableSign = true;
    //   this.enableReopen = false;
    // }

    // if (certifier && this.adjustNode.CertifiedUser != '') {
    //   this.enableSign = true;
    //   this.enableReopen = true;
    // }

  }

  async adjWeb() {

    await this.adjustServive.updateEventStatus(this.selectedNodeCut.UnitId, this.adjustNode.AdjustCode, 'COUNTING')
      .toPromise()
      .then(resp => {
        this.adjWebRunning = true;
        this.validateAdjustStatus();

      })
      .catch(error => {

      });
  }

  cancelAdjweb() {
    Swal.fire({
      text: `¿Desea cancelar el conteo físico?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> &nbsp; Si',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i> &nbsp; No',
      backdrop: `rgba(0,157,210,0.5)`,
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {


        this.adjWebRunning = false;
        this.phisicalInvActive = false;
        this.hideWebOptions();
        this.enableSave = false;
        this.phisicalInvActive = false;

        await this.adjustServive.cancelApkAdjust(this.adjustNode.AdjustCode)
          .toPromise()
          .then(async resp => {

            this.showLoading('Cargando inventario', 'Por favor espere');
            this.adjustServive
              .getAdjustInventoryCut(this.selectedNodeCut.UnitId)
              .subscribe(
                (factoryCutDetail: AdjustDetailCut[]) => {

                  this.clearLists();
                  this.containers.map((cnt) => {
                    this.createLineList(
                      cnt.ContainerId,
                      AdjustLineEnum.PreviousInventory,
                      factoryCutDetail,
                      this.previousInventory
                    );
                    this.createLineList(
                      cnt.ContainerId,
                      AdjustLineEnum.PURCHASES,
                      factoryCutDetail,
                      this.purchases
                    );
                    this.createLineList(
                      cnt.ContainerId,
                      AdjustLineEnum.RECYCLING,
                      factoryCutDetail,
                      this.recycling
                    );
                    this.createLineList(
                      cnt.ContainerId,
                      AdjustLineEnum.Others,
                      factoryCutDetail,
                      this.other
                    );
                    this.createLineList(
                      cnt.ContainerId,
                      AdjustLineEnum.RECOVERY,
                      factoryCutDetail,
                      this.recovery
                    );
                    this.createLineList(
                      cnt.ContainerId,
                      AdjustLineEnum.THEORIC,
                      factoryCutDetail,
                      this.theoricInventory
                    );
                    this.createLineList(
                      cnt.ContainerId,
                      AdjustLineEnum.PHYSICAL,
                      factoryCutDetail,
                      this.physicalInventory
                    );
                    this.createLineList(
                      cnt.ContainerId,
                      AdjustLineEnum.Transit,
                      factoryCutDetail,
                      this.transitInventory
                    );
                    this.createLineList(
                      cnt.ContainerId,
                      AdjustLineEnum.CEVE,
                      factoryCutDetail,
                      this.ceveRelation
                    );
                    this.createLineList(
                      cnt.ContainerId,
                      AdjustLineEnum.FACTORY,
                      factoryCutDetail,
                      this.factoryRelation
                    );
                    this.createLineList(
                      cnt.ContainerId,
                      AdjustLineEnum.REAL,
                      factoryCutDetail,
                      this.realInventory
                    );
                    this.createLineList(
                      cnt.ContainerId,
                      AdjustLineEnum.TotalPhysicalInventory,
                      factoryCutDetail,
                      this.totalBalance
                    );
                    this.createLineList(
                      cnt.ContainerId,
                      AdjustLineEnum.TotalDifferences,
                      factoryCutDetail,
                      this.difference
                    );
                    this.createLineList(
                      cnt.ContainerId,
                      AdjustLineEnum.PERCENTAGE,
                      factoryCutDetail,
                      this.percentage
                    );
                  });
                  // this.isRegionManagerSign =
                  //   this.factoryCutObject.RegionalManagerSign != '';
                  // this.isSupervisorSign = this.factoryCutObject.SupervisorSign != '';
                  // this.isManagerSign = this.factoryCutObject.ManagerSign != '';
                  // this.searching = false;
                  // // this.dataReady = true;
                  // if (
                  //   this.factoryCutObject.Status == 'CLOSED' &&
                  //   this.isRegionManagerSign &&
                  //   this.isSupervisorSign &&
                  //   this.isManagerSign &&
                  //   this.isDateTimeRangeValid
                  // ) {
                  //   // this.publish();
                  // }
                  this.hideLoading();
                });

          })
          .catch(error => {

          });

      }
    });

  }

  async adjApk(reestartCount: boolean = true) {
    await this.adjustServive.validateAvailableConnetions(2)
      .toPromise()
      .then(async () => {
        let adjustCon = {
          Org: this.user.Org,
          AdjustCode: this.adjustNode.AdjustCode,
          ConnChannel: 'Event',
          FirebaseKey: this.user.Token.length > 200 ? this.user.Token.substring(0, 199) : this.user.Token,
          ConnUser: this.user.Email
        };
        let towerCon = {
          Org: this.user.Org,
          AdjustCode: this.adjustNode.AdjustCode,
          ConnChannel: 'Tower',
          FirebaseKey: this.user.Token.length > 200 ? this.user.Token.substring(0, 199) : this.user.Token,
          ConnUser: this.user.Email
        };
        await this.adjustServive.addConnection(adjustCon)
          .toPromise()
          .then(() => {

          })
          .catch(err => {
            this.showErrorMessage(err);
          });
        await this.adjustServive.addConnection(towerCon)
          .toPromise()
          .then(() => {

          })
          .catch(err => {
            this.showErrorMessage(err);
          });


        this.OpenFirebaseConnection();
        this.adjustNode.Status = "APK"

        if (reestartCount) {
          await this.adjustServive.startApkAdjust(this.adjustNode.AdjustCode)
            .toPromise()
            .then(resp => {
              this.saveAdjustApk();
              // if (this.userActivities.includes('ADJINVWEB') && !this.userActivities.includes("ADJVIEWWEB")) {//Actividad de contador de inventario en web    
              //   this.displaySaveAPK = true;
              //   this.enableSaveAPK = true;
              // }
              // this.adjApkRunning = true;
              // this.hideWebOptions();
            })
            .catch(error => {

            });
        }
        else {
          this.adjApkRunning = true;
          this.hideWebOptions();
          if (this.userActivities.includes('ADJINVWEB') && !this.userActivities.includes("ADJVIEWWEB")) {//Actividad de contador de inventario en web    
            this.displaySaveAPK = true;
            this.enableSaveAPK = true;
          }
        }

      })
      .catch(err => {
        this.showErrorMessage(err);
        this.adjWebRunning = false;
        this.adjApkRunning = false;
      });
  }

  async cancelAdjapk() {
    Swal.fire({
      text: `¿Desea cancelar el conteo fisico en APK?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> &nbsp; Si',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i> &nbsp; No',
      backdrop: `rgba(0,157,210,0.5)`,
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {

        await this.adjustServive.deleteConnection(this.adjustNode.AdjustCode)
          .toPromise()
          .then(async () => {

            this.CloseFirebaseConnection();
            this.displaySaveAPK = false;
            this.enableSaveAPK = false;
            this.adjApkRunning = false;
            this.adjustNode.Status = "CREATED";

            await this.adjustServive.cancelApkAdjust(this.adjustNode.AdjustCode)
              .toPromise()
              .then(async resp => {

                this.showLoading('Cargando inventario', 'Por favor espere');
                this.adjustServive
                  .getAdjustInventoryCut(this.selectedNodeCut.UnitId)
                  .subscribe(
                    (factoryCutDetail: AdjustDetailCut[]) => {

                      this.clearLists();
                      this.containers.map((cnt) => {
                        this.createLineList(
                          cnt.ContainerId,
                          AdjustLineEnum.PreviousInventory,
                          factoryCutDetail,
                          this.previousInventory
                        );
                        this.createLineList(
                          cnt.ContainerId,
                          AdjustLineEnum.PURCHASES,
                          factoryCutDetail,
                          this.purchases
                        );
                        this.createLineList(
                          cnt.ContainerId,
                          AdjustLineEnum.RECYCLING,
                          factoryCutDetail,
                          this.recycling
                        );
                        this.createLineList(
                          cnt.ContainerId,
                          AdjustLineEnum.Others,
                          factoryCutDetail,
                          this.other
                        );
                        this.createLineList(
                          cnt.ContainerId,
                          AdjustLineEnum.RECOVERY,
                          factoryCutDetail,
                          this.recovery
                        );
                        this.createLineList(
                          cnt.ContainerId,
                          AdjustLineEnum.THEORIC,
                          factoryCutDetail,
                          this.theoricInventory
                        );
                        this.createLineList(
                          cnt.ContainerId,
                          AdjustLineEnum.PHYSICAL,
                          factoryCutDetail,
                          this.physicalInventory
                        );
                        this.createLineList(
                          cnt.ContainerId,
                          AdjustLineEnum.Transit,
                          factoryCutDetail,
                          this.transitInventory
                        );
                        this.createLineList(
                          cnt.ContainerId,
                          AdjustLineEnum.CEVE,
                          factoryCutDetail,
                          this.ceveRelation
                        );
                        this.createLineList(
                          cnt.ContainerId,
                          AdjustLineEnum.FACTORY,
                          factoryCutDetail,
                          this.factoryRelation
                        );
                        this.createLineList(
                          cnt.ContainerId,
                          AdjustLineEnum.REAL,
                          factoryCutDetail,
                          this.realInventory
                        );
                        this.createLineList(
                          cnt.ContainerId,
                          AdjustLineEnum.TotalPhysicalInventory,
                          factoryCutDetail,
                          this.totalBalance
                        );
                        this.createLineList(
                          cnt.ContainerId,
                          AdjustLineEnum.TotalDifferences,
                          factoryCutDetail,
                          this.difference
                        );
                        this.createLineList(
                          cnt.ContainerId,
                          AdjustLineEnum.PERCENTAGE,
                          factoryCutDetail,
                          this.percentage
                        );
                      });
                      // this.isRegionManagerSign =
                      //   this.factoryCutObject.RegionalManagerSign != '';
                      // this.isSupervisorSign = this.factoryCutObject.SupervisorSign != '';
                      // this.isManagerSign = this.factoryCutObject.ManagerSign != '';
                      // this.searching = false;
                      // // this.dataReady = true;
                      // if (
                      //   this.factoryCutObject.Status == 'CLOSED' &&
                      //   this.isRegionManagerSign &&
                      //   this.isSupervisorSign &&
                      //   this.isManagerSign &&
                      //   this.isDateTimeRangeValid
                      // ) {
                      //   // this.publish();
                      // }
                      this.hideLoading();
                    });

              })
              .catch(error => {

              });
          })
          .catch(err => {
            this.showErrorMessage(err);
          });
      }
    });

  }



  async cancelAdjust() {
    Swal.fire({
      text: `¿Desea cancelar la compulsa?, Esto concluira la compulsa activa.`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> &nbsp; Si',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i> &nbsp; No',
      backdrop: `rgba(0,157,210,0.5)`,
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        let comment = await this.requestCancelComment();
        if (comment != 'CANCELADJ' && comment != '') {
          this.phisicalInvActive = false;
          this.adjWebRunning = false;
          this.adjApkRunning = false;

          await this.adjustServive.cancelAdjust(this.adjustNode.AdjustCode, comment).toPromise()
            .then(resp => {

              this.parentCancelAdjust.emit();
            })
            .catch(err => {
              console.log(err);
              Swal.fire('Compulsa', err.error.Message, 'warning');
            });
        }
      }
    });
  }

  async requestCancelComment(): Promise<string> {
    let comment = 'CANCELADJ';
    await Swal.fire({
      input: 'textarea',
      title: 'Comentario de cancelacion',
      inputPlaceholder: 'Debe ingresar un comentario que justifique la cancelacion de la compulsa',
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      showCancelButton: true,
      allowOutsideClick: false
    }).then(async result => {
      if (result.isConfirmed) {
        if (!result.value) {
          Swal.fire('Debe ingresar el comentario de cancelacion.');
        }
        comment = result.value;
      }
    });
    return comment;
  }

  async saveAdjust() {
    this.disableWebOptions();
    this.showLoading('Guardando compulsa', 'Por favor espere.');
    let newInv = this.buildAdjustBody();
    await this.adjustServive.saveAdjust(this.adjustNode.AdjustCode, newInv)
      .toPromise()
      .then(resp => {
        this.hideLoading();
        this.globalFunctionService.messageToast(`Inventario guardado.`, 'success');
        this.reloadAdjust();
      })
      .catch(err => {
        this.hideLoading();
        this.showErrorMessage(err);
        this.enableSave = true;
      });
  }

  async closeAdjust() {
    this.disableWebOptions();

  }

  async signAdjust() {
    this.disableWebOptions();
    this.showLoading('Firmando compulsa', 'por favor espere');
    //guardo antes de firmar
    if (this.adjustNode.Status == "SIGNED") {
      await this.adjustServive.signAdjust(this.adjustNode.AdjustCode)
        .toPromise()
        .then(result => {
          this.hideLoading();
          this.globalFunctionService.messageToast(`Compulsa firmada correctamente.`, 'success');
          this.reloadAdjust();
        })
        .catch(err => {
          this.showErrorMessage(err);
          this.reloadAdjust();
        });

    }
    else {
      let newInv = this.buildAdjustBody();
      await this.adjustServive.saveAdjust(this.adjustNode.AdjustCode, newInv)
        .toPromise()
        .then(async resp => {
          //firmo despues de guardar
          await this.adjustServive.signAdjust(this.adjustNode.AdjustCode)
            .toPromise()
            .then(result => {
              this.hideLoading();
              this.globalFunctionService.messageToast(`Compulsa firmada correctamente.`, 'success');
              this.reloadAdjust();
            })
            .catch(err => {
              this.showErrorMessage(err);
              this.reloadAdjust();
            });

        })
        .catch(err => {
          this.hideLoading();
          this.showErrorMessage(err);
          this.enableSave = true;
          this.reloadAdjust();
        });
    }
  }

  async reopenAdjust() {
    this.disableWebOptions();
    await this.adjustServive.reopenAdjust(this.adjustNode.AdjustCode)
      .toPromise()
      .then(result => {
        this.globalFunctionService.messageToast(`Compulsa abierta.`, 'success');
        this.reloadAdjust();
      })
      .catch(err => {
        this.showErrorMessage(err);
      });
  }

  async saveAdjustApk() {
    this.enableSaveAPK = false;
    this.showLoading('Guardando compulsa', 'Por favor espere.');
    let newInv = this.buildAdjustBody();
    await this.adjustServive.saveAdjustApk(this.adjustNode.AdjustCode, newInv)
      .toPromise()
      .then(resp => {
        this.hideLoading();
        this.globalFunctionService.messageToast(`Inventario guardado.`, 'success');
        this.reloadAdjust();
      })
      .catch(err => {
        this.hideLoading();
        this.showErrorMessage(err);
        this.enableSaveAPK = true;
      });
  }

  async applyAdjustAPK() {
    if (this.isSaving) { return; }
    this.isSaving = true;

    this.showLoading('Aplicando compulsa', 'Por favor espere.');
    let newInv = this.buildAdjustBody();

    await this.adjustServive.applyAdjustAPK(this.adjustNode.AdjustCode, newInv).toPromise()
      .then(() => {
        this.adjustNode.Status = "APPLYED";
        this.adjWebRunning = false;
        this.adjApkRunning = false;
        this.hideLoading();
        this.CloseFirebaseConnection();
        Swal.fire("Compulsa", "Ajuste de inventario realizado con exito", "success")
        this.isSaving = false;
        this.parentCancelAdjust.emit();

      })
      .catch(err => {
        this.isSaving = false;
        console.log(err);
        Swal.fire('Compulsa', err.error.Message, 'warning');
      });

    //   }
    // });
  }

  buildAdjustBody(): any[] {
    let newInv = [];
    for (let i = 0; i < this.physicalInventory.length; i++) {

      let containerId = this.physicalInventory[i].containerId;
      let contaienrCode = this.containers[i].ContainerCode;

      newInv.push({
        Line: AdjustLineEnum.PREVIOUS,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.previousInventory[i].quantity === null ? 0 : this.previousInventory[i].quantity,
      });

      newInv.push({
        Line: AdjustLineEnum.PURCHASES,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.purchases[i].quantity === null ? 0 : this.purchases[i].quantity,
      });

      newInv.push({
        Line: AdjustLineEnum.RECYCLING,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.recycling[i].quantity === null ? 0 : this.recycling[i].quantity,
      });

      newInv.push({
        Line: AdjustLineEnum.OTHERS,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.other[i].quantity === null ? 0 : this.other[i].quantity,
      });

      newInv.push({
        Line: AdjustLineEnum.RECOVERY,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.recovery[i].quantity === null ? 0 : this.recovery[i].quantity,
      });

      newInv.push({
        Line: AdjustLineEnum.THEORIC,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.theoricInventory[i].quantity === null ? 0 : this.theoricInventory[i].quantity,
      });

      newInv.push({
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.physicalInventory[i].quantity === null ? 0 : this.physicalInventory[i].quantity,
        Line: AdjustLineEnum.PHYSICAL
      });

      newInv.push({
        Line: AdjustLineEnum.Transit,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.transitInventory[i].quantity === null ? 0 : this.transitInventory[i].quantity,
      });

      newInv.push({
        Line: AdjustLineEnum.CEVE,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.ceveRelation[i].quantity === null ? 0 : this.ceveRelation[i].quantity,
      });

      newInv.push({
        Line: AdjustLineEnum.FACTORY,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.factoryRelation[i].quantity === null ? 0 : this.factoryRelation[i].quantity
      });

      newInv.push({
        Line: AdjustLineEnum.REAL,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: (this.transitInventory[i].quantity === null ? 0 : this.transitInventory[i].quantity) + (this.physicalInventory[i].quantity === null ? 0 : this.physicalInventory[i].quantity),


      });

      newInv.push({
        Line: AdjustLineEnum.TOTALBALANCE,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: this.totalBalance[i].quantity === null ? 0 : this.totalBalance[i].quantity,
      });


      let difPhis = this.physicalInventory[i].quantity === null ? 0 : this.physicalInventory[i].quantity;
      let difTran = this.transitInventory[i].quantity === null ? 0 : this.transitInventory[i].quantity;
      let theoric = this.theoricInventory[i].quantity === null ? 0 : this.theoricInventory[i].quantity;
      newInv.push({
        Line: AdjustLineEnum.DIFFERENCE,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: difPhis + difTran - theoric
      });

      newInv.push({
        Line: AdjustLineEnum.PERCENTAGE,
        ContainerId: containerId,
        ContainerCode: contaienrCode,
        Quantity: Math.round(Number(theoric === 0 ? 0 : ((difPhis + difTran - theoric) / theoric) * 100) * 100)
      });

    }

    return newInv;
  }

  async applyAdjust() {
    this.showLoading('Aplicando compulsa', 'Por favor espere.');
    await this.adjustServive.applyAdjust(this.adjustNode.AdjustCode).toPromise()
      .then(() => {
        this.hideLoading();
        Swal.fire("Compulsa", "Ajuste de inventario realizado con exito", "success");
        this.parentCancelAdjust.emit();
      })
      .catch(err => {
        console.log(err);
        Swal.fire('Compulsa', err.error.Message, 'warning');
      });
  }

  async lapseAdjust() {
    this.showLoading('Caducando compulsa', 'Se agoto el tiempo de compulsa, pasara a estatus caducada');

    this.phisicalInvActive = false;
    this.adjWebRunning = false;
    this.adjApkRunning = false;

    await this.adjustServive.lapseAdjust(this.adjustNode.AdjustCode).toPromise()
      .then(resp => {
        this.CloseFirebaseConnection();
        this.phisicalInvActive = false;
        this.adjWebRunning = false;
        this.adjApkRunning = false;
        this.hideLoading();
        this.parentCancelAdjust.emit();
      })
      .catch(err => {
        console.log(err);
        this.hideLoading();
        Swal.fire('Compulsa', err.error.Message, 'warning');
      });

    this.hideLoading();
  }

  showLoading(title: string, message: string) {
    Swal.fire({
      title: title,
      html: message,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
    });
  }

  hideLoading() {
    Swal.close();
  }

  startTimer() {

    let canIncreaseTime = this.userActivities.includes('ADJCNTINCR');

    this.timer = setInterval(() => {

      //Obtengo el dia actual
      this.currentDateTime = new Date();

      //obtengo el tiempo limite para la compulsa
      let timeArr = this.adjustNode.AdjustCountdown.split(':');
      //sumo el tiempo limite al tempo de creacion de la compulsa 
      this.adjLapTime = moment(this.adjustNode.CreatedAt).add(timeArr[0], 'hours').add(timeArr[1], 'minutes').add(timeArr[2], 'seconds');


      //si el tiempo limite de la compulsa es menor al actual caduco la compulsa
      let difSeconds = this.adjLapTime.diff(moment(this.currentDateTime), 'milliseconds');

      if (difSeconds < 0) {
        console.log('Caduco la compulsa');
        //detengo el timer
        this.stopTimer();
        //Caduco la compulsa
        this.lapseAdjust();
        return;
      }
      let hrs = difSeconds / 1000 / 60 / 60;
      if (hrs < 0) {
        this.hours = '0';
        this.minutes = '0';
        this.seconds = '0';
        return;
      }
      let mins = hrs < 1 ? (hrs + 1 % Math.floor(hrs + 1)) * 60 : (hrs % Math.floor(hrs)) * 60;
      let secs = mins < 1 ? (mins + 1 % Math.floor(mins + 1)) * 60 : (mins % Math.floor(mins)) * 60;

      this.hours = isNaN(hrs) ? '0' : Math.floor(hrs).toString();
      this.minutes = isNaN(mins) ? '0' : Math.floor(mins).toString();
      this.seconds = isNaN(secs) ? '0' : Math.floor(secs).toString();

      if (secs < 2) {
        //Cada minuto valido si el tiempo maximo de la compulsa se actualizo
        this.adjustServive.getAdjustCountdown(this.adjustNode.AdjustCode).subscribe(resp => {
          console.log(resp.Message);
          this.adjustNode.AdjustCountdown = resp.Message;
        }, error => {
          console.log(error);
        });
      }
      this.showCountdownBox = false;
      if (hrs < 1 && mins < 15 && canIncreaseTime) {
        //muestro el mensaje para aumentar el tiempo si hay una opcion disponible para aumentar
        if (this.countdown15Enabled ||
          this.countdown30Enabled ||
          this.countdown45Enabled ||
          this.countdown60Enabled) {
          this.showCountdownBox = true;
        }
      }

    }, 1000);
  }

  stopTimer() {
    clearInterval(this.timer);
  }


  evaluateMAxCountDown() {

    let headerCountDown = this.adjustNode.AdjustCountdown.split(':');
    let hours = toNumber(headerCountDown[0]);
    let mins = toNumber(headerCountDown[1]);

    let countdownTime = this.countdownMaxTime.split(':');
    let maxCountDownHours = toNumber(countdownTime[0]);
    let maxCountDownminutes = toNumber(countdownTime[1]);

    this.countdown15Enabled = true;
    this.countdown30Enabled = true;
    this.countdown45Enabled = true;
    this.countdown60Enabled = true;

    if (hours >= maxCountDownHours) {
      this.countdown60Enabled = false;

      if (mins + 15 > maxCountDownminutes) {
        this.countdown15Enabled = false;
      }
      if (mins + 30 > maxCountDownminutes) {
        this.countdown30Enabled = false;
      }
      if (mins + 45 > maxCountDownminutes) {
        this.countdown45Enabled = false;
      }
    }
  }

  async increaseEventCountDown(minutes: number) {
    this.globalFunctionService.messageToast(`Incrementando tiempo.`, 'info');
    // this.showCountdownBox = false;

    let arr = this.adjustNode.AdjustCountdown.split(':');

    if (minutes == 60) {
      arr[0] = (toNumber(arr[0]) + 1).toString();
    }
    else {
      arr[1] = (toNumber(arr[1]) + minutes).toString();
    }

    let countDown = arr.join(':');
    //actualizo tiempo en db
    await this.adjustServive.increaseEventCountdown(this.adjustNode.AdjustCode, countDown)
      .toPromise()
      .then(() => {
        //Compulsa local
        this.adjustNode.AdjustCountdown = countDown;
        let timeArr = countDown.split(':');
        this.adjLapTime = moment(this.adjustNode.CreatedAt).add(timeArr[0], 'hours').add(timeArr[1], 'minutes').add(timeArr[2], 'seconds');
        this.evaluateMAxCountDown();
        this.globalFunctionService.messageToast(`Tiempo incrementado.`, 'info');
      })
      .catch(err => {
        this.showErrorMessage(err);
      });
  }


  //#region firebase

  // firebaseMessages: AngularFireList<FirebaseMessage>;
  // messages: FirebaseMessage[] = [];


  firebaseEvents: AngularFireList<FirebaseEvent>;
  firebaseTowers: AngularFireList<FirebaseTowers>;
  // firebaseSeller: AngularFireList<FirebaseSeller>;

  updateAdjustConnetions() {
    let adjustCon = {
      Org: this.user.Org,
      AdjustCode: this.adjustNode.AdjustCode,
      ConnChannel: 'Event',
      FirebaseKey: this.user.Token.length > 200 ? this.user.Token.substring(0, 199) : this.user.Token,
      ConnUser: this.user.Email
    };
    this.adjustServive.updateConnection(adjustCon)
      .toPromise()
      .then(() => {

      })
      .catch((err) => {
        this.showErrorMessage(err);
      })

    let towerCon = {
      Org: this.user.Org,
      AdjustCode: this.adjustNode.AdjustCode,
      ConnChannel: 'Tower',
      FirebaseKey: this.user.Token.length > 200 ? this.user.Token.substring(0, 199) : this.user.Token,
      ConnUser: this.user.Email
    };
    this.adjustServive.updateConnection(towerCon)
      .toPromise()
      .then(() => {

      })
      .catch((err) => {
        this.showErrorMessage(err);
      })

  }


  OpenFirebaseConnection() {
    //Configure firebase RTD objects to be listened
    // this.firebaseMessages = this.db.list('Messages');

    this.db.database.goOnline();
    //Start firebase listeners
    this.firebaseEvents = this.db.list(`${this.selectedNodeCut.UnitId}/${this.adjustNode.AdjustCode}/Events`);
    this.firebaseTowers = this.db.list(`${this.selectedNodeCut.UnitId}/${this.adjustNode.AdjustCode}/Towers`);
    // this.firebaseSeller = this.db.list(`${this.selectedNodeCut.UnitId}/${this.adjustNode.AdjustCode}/Sellers`);


    //Implement firebase sate change listener
    this.dataState();
  }

  CloseFirebaseConnection() {
    if (this.db != undefined) {
      if (this.db.database != undefined) {
        this.db.database.goOffline();
      }
    }

    if (this.eventsSub != undefined) {
      this.eventsSub.unsubscribe();
      this.eventsSub.remove(this.eventsSub);
    }
    if (this.towersSub != undefined) {
      this.towersSub.unsubscribe();
      this.towersSub.remove(this.towersSub);
    }
    this.firebaseEvents = null;
    this.firebaseTowers = null;
  }


  dataState() {
    this.eventsSub = this.firebaseEvents.stateChanges().subscribe(async event => {
      if (!this.userInAdjustPage) { return; }
      // console.log("firebase event", event);
      // console.log("firebase event", event.payload.val());

      //'child_added' | 'child_removed' | 'child_changed' | 'child_moved';
      if (event.type == 'child_removed') {
        this.CloseFirebaseConnection();
        return;
      }

      this.adjustNode.AdjustCountdown = event.payload.val().Countdow;
      let timeArr = this.adjustNode.AdjustCountdown.split(':');
      this.adjLapTime = moment(this.adjustNode.CreatedAt).add(timeArr[0], 'hours').add(timeArr[1], 'minutes').add(timeArr[2], 'seconds');

      if (event.payload.val().Status == "SIGNEDAPK") {
        this.adjustNode.Status = 'SIGNEDAPK';
        this.validateAdjustStatus();
      }
      else {
        this.evaluateMAxCountDown();

        //Actualizo coneccion para indicar que esta activa
        this.updateAdjustConnetions();
      }
    });

    this.towersSub = this.firebaseTowers.stateChanges().subscribe(async event => {
      if (!this.userInAdjustPage) { return; }
      if (this.gettingDetail || !this.adjApkRunning) { return; }

      this.globalFunctionService.messageToast(`Actualizando inventario.`, 'info');

      await this.getAdjustDetail();

      this.globalFunctionService.messageToast(`Inventario actualizado.`, 'info');

      //Actualizo coneccion para indicar que esta activa
      this.updateAdjustConnetions();

    });

    // this.firebaseSeller.stateChanges().subscribe(async event => {
    //   // console.log('Seller state changes data', event);
    //   // console.log('Seller state changes json', event.payload.toJSON());
    // });

  }

  gettingDetail: boolean = false;
  async getAdjustDetail() {

    this.gettingDetail = true;
    await this.adjustServive.getAdjustDetailGrouped(this.adjustNode.AdjustCode)
      .toPromise()
      .then(detail => {

        if (this.adjApkRunning) {
          let lineDetail = detail.filter(x => x.Line == 0);
          for (let i = 0; i < this.containers.length; i++) {
            let containerCode = this.containers[i].ContainerCode;
            let containerId = this.containers[i].ContainerId;
            let detailFiltered = lineDetail.filter(x => x.ContainerCode == containerCode);
            let val = 0;
            detailFiltered.map(d => val += d.Quantity);
            this.physicalInventory[i].quantity = val;
          }
        }
        else {
          this.containers.map((cnt) => {
            this.createAdjustLists(detail, cnt);
          });
        }
        this.gettingDetail = false;
      })
      .catch(err => {
        this.showErrorMessage(err);
      });

  }

  showErrorMessage(err) {
    if (err?.error?.Message !== '' && err?.error?.Message !== undefined) {
      Swal.fire('Compulsa', err.error.Message, 'warning');
    }
    else if (err?.message !== '' && err?.message !== undefined) {
      Swal.fire('Compulsa', err.message, 'warning');
    }
    else {
      Swal.fire('Compulsa', err, 'warning');
    }
  }

  // getAll(): AngularFireList<FirebaseMessage> {
  //   return this.firebaseMessages;
  // }

  // create(tutorial: FirebaseMessage): any {
  //   return this.firebaseMessages.push(tutorial);
  // }

  // update(key: string, value: any): Promise<void> {
  //   return this.firebaseMessages.update(key, value);
  // }

  // delete(key: string): Promise<void> {
  //   return this.firebaseMessages.remove(key);
  // }

  // deleteAll(): Promise<void> {
  //   return this.firebaseMessages.remove();
  // }

  //#endregion


  //#region Cut Load

  ceveCutId: string;
  ceveCutDate: string;

  factory: any;
  // date: Date = new Date();
  // selectedDate: Date = new Date();
  // loadingDates: boolean = true;
  factoryCutObject: FactoryCut = new FactoryCut();
  // user: UserLogin;
  isFabAdmn: boolean;
  isFabSupervisor: boolean;
  actualDateTime: string;
  datepipe: DatePipe = new DatePipe('en-US');
  // events: Calendar[] = [];
  lastEvent: Calendar;
  working: boolean = false;
  closing: boolean = false;
  // dataReady: boolean = false;
  signActSuppervisorInverseLogistic: boolean = false;
  signActCheifLogistic: boolean = false;
  signActRegionalLogistic: boolean = false;
  isRegionManagerSign: boolean = false;
  isDateTimeRangeValid: boolean = false;
  isSupervisorSign: boolean = false;
  isManagerSign: boolean = false;
  isLastCutDate: boolean = false;
  searching: boolean = false;
  previousDate: string;
  // isLoadingFactory: boolean = false;
  // unitSelected: number = 0;

  containers: Container[] = [];
  containersByFactory: FactoryByContainer[] = [];
  previousInventory: item[] = [];
  purchases: item[] = [];
  recycling: item[] = [];
  other: item[] = [];
  recovery: item[] = [];
  theoricInventory: item[] = [];
  physicalInventory: item[] = [];
  transitInventory: item[] = [];
  ceveRelation: item[] = [];
  factoryRelation: item[] = [];
  realInventory: item[] = [];
  totalBalance: item[] = [];
  difference: item[] = [];
  percentage: item[] = [];
  factoryCut: any[] = [];
  factoryList: any[] = [];

  async InitializeData(): Promise<void> {
    this.showLoading('Cargando inventario', 'Por favor espere.');

    // await this.updateTime();
    // this.date = await this.getCentralTime();
    // this.selectedDate = await this.getCentralTime();
    this.factoryCutObject.FactoryCutId = '';
    this.user = this.settings.getUser();
    this.getEvents();
    var activities = this.settings.getUserAccess();
    this.isFabAdmn = false;
    this.isFabSupervisor = false;
    if (activities.find((x) => x == 'SUPPLANTA')) {
      this.isFabSupervisor = true;
    }
    if (activities.find((x) => x == 'ADMPLANTA')) {
      this.isFabAdmn = true;
    }
    if (activities.includes('FICASLI')) {
      this.signActSuppervisorInverseLogistic = true;
    }
    if (activities.includes('FICAGJL')) {
      this.signActCheifLogistic = true;
    }
    if (activities.includes('FICAGRL')) {
      this.signActRegionalLogistic = true;
    }


    await this.selectFactory();

  }

  getEvents(): void {
    // this.calendarServ.getCalendarCutEvents().subscribe(
    //   (events: Calendar[]) => {
    //     this.loadingDates = false;
    //     this.events = events.sort((b, a) => new Date(a.StartAt).getTime() - new Date(b.StartAt).getTime());
    //     var dates = events.map((x) => x.StartAt);
    //     var newDate = dates.reduce((a, b) => (a > b ? a : b));
    //     this.lastEvent = events.find((x) => x.StartAt == newDate);
    //   },
    //   (err) => {
    //     this.loadingDates = false;
    //     console.log(err);
    //   }
    // );
  }

  /**
  * Calculates section 18
  *
  * @param {number} periodicDifference - periodic difference
  * @param {number} theoricInventory - theoric Inventory
  * @return {*}  {number} - (Periodic difference / theoric Inventory) * 100
  * @memberof InventoryFactoryComponent
  */
  getPercentDifference(
    periodicDifference: number,
    theoricInventory: number
  ): number {
    if (theoricInventory === 0) {
      return 0;
    }
    return (periodicDifference / theoricInventory) * 100;
  }


  /**
   * Clear all list to fill the DOM Table
   *
   * @memberof InventoryFactoryComponent
   */
  clearLists(): void {
    this.previousInventory = [];
    this.purchases = [];
    this.recycling = [];
    this.other = [];
    this.recovery = [];
    this.theoricInventory = [];
    this.physicalInventory = [];
    this.realInventory = [];
    this.ceveRelation = [];
    this.factoryRelation = [];
    this.transitInventory = [];
    this.totalBalance = [];
    this.difference = [];
    this.percentage = [];
  }


  /**
   * Gets all factory inventory, defines the start date and invoke methods to clear an create list to fill the DOM table
   *
   * @memberof InventoryFactoryComponent
   */
  async searchInventory(): Promise<void> {
    await this.calendarServ.getLastCutId(this.selectedNodeCut.UnitId).toPromise()
      .then(async data => {
        this.ceveCutDate = data.cutDate;
        this.ceveCutId = data.cutId;
        await this.getFactoryCutByDay();
      })
      .catch(err => {
        Swal.fire('Compulsa', err.error, 'warning');
      });

  }


  /**
  * Finds factory cut by day, If can't find a previous factory cut generates the actual inventory cut
  *
  * @memberof InventoryFactoryComponent
  */
  async getFactoryCutByDay() {
    // this.inventoryCutService
    //   .getCeveActive(this.selectedNodeCut.UnitId)
    //   .toPromise()
    //   .then((data) => {
    //     if (data) {
    await this.inventoryCutService
      .getCeveRelationCutString(
        this.user.Org,
        this.selectedNodeCut.UnitId,
        this.ceveCutDate
      )
      .toPromise()
      .then(
        async (ceveCut) => {
          this.clearLists();
          await this.getCutFactory();
        },
        (err) => {
          console.log(err);
          this.globalFunctionService.messageToast(`Ocurrió un error al obtener la información de los centro de ventas relacionados.`, 'error');
          this.searching = false;
        }
      );
    //     } else {
    //       Swal.fire({
    //         icon: 'error',
    //         title: `El nodo seleccionado no se encuentra activo.`,
    //       });
    //       this.searching = false;
    //       return;
    //     }
    //   });


  }


  async getCutFactory() {
    //   await this.inventoryCutService
    //     .getFactoryCutByDayString(this.user.Org, this.selectedNodeCut.UnitId, this.ceveCutDate)
    //     .toPromise()
    //     .then(
    //       async (factoryCut) => {
    //         this.factoryCutObject = factoryCut;
    await this.getFactoryCutDetail();
    //       },
    //       (err) => {

    //       }
    //     );
  }


  /**
  * Gets the factory cut detail
  *
  * @memberof InventoryFactoryComponent
  */
  async getFactoryCutDetail() {
    if (this.adjustNode.Status == "CREATED") {
      await this.adjustServive
        .getAdjustInventoryCut(this.selectedNodeCut.UnitId)
        .toPromise()
        .then(
          (factoryCutDetail: AdjustDetailCut[]) => {
            console.log(factoryCutDetail);
            this.containers.map((cnt) => {
              this.createLists(factoryCutDetail, cnt);
            });
            this.isRegionManagerSign =
              this.factoryCutObject.RegionalManagerSign != '';
            this.isSupervisorSign = this.factoryCutObject.SupervisorSign != '';
            this.isManagerSign = this.factoryCutObject.ManagerSign != '';
            this.searching = false;
            // this.dataReady = true;
            if (
              this.factoryCutObject.Status == 'CLOSED' &&
              this.isRegionManagerSign &&
              this.isSupervisorSign &&
              this.isManagerSign &&
              this.isDateTimeRangeValid
            ) {
              // this.publish();
            }
            // this.outOfTime();


            this.hideLoading();
            // this.showToast("Caratula cargada correctamente", "success");
            Swal.fire('Compulsa', 'Prerrequisitos en sistemas completos, durante la ventana de tiempo de certificación no se podrán realizar transacciones.', 'success');

            this.startTimer();
            this.evaluateMAxCountDown();

            if (this.adjustNode.Status == 'APK') {
              this.adjApk(false);
            }
            else if (this.adjustNode.Status == 'COUNTED') {
              this.adjApk(false);
            }

          },
          (err) => {
            this.hideLoading();
            this.globalFunctionService.messageToast(`Ocurrió un error al obtener la información de los centro de ventas relacionados.`, 'error');
            this.searching = false;
          }
        );
    }
    else {
      this.getAdjustDetail().then(() => {
        this.hideLoading();
        this.globalFunctionService.messageToast(`Inventario cargado.`, 'success');

        this.startTimer();
        this.evaluateMAxCountDown();

      });
    }
  }

  createLists(factoryCutDetail: AdjustDetailCut[], cnt: Container) {
    this.createLineList(
      cnt.ContainerId,
      AdjustLineEnum.PreviousInventory,
      factoryCutDetail,
      this.previousInventory
    );
    this.createLineList(
      cnt.ContainerId,
      AdjustLineEnum.PURCHASES,
      factoryCutDetail,
      this.purchases
    );
    this.createLineList(
      cnt.ContainerId,
      AdjustLineEnum.RECYCLING,
      factoryCutDetail,
      this.recycling
    );
    this.createLineList(
      cnt.ContainerId,
      AdjustLineEnum.Others,
      factoryCutDetail,
      this.other
    );
    this.createLineList(
      cnt.ContainerId,
      AdjustLineEnum.RECOVERY,
      factoryCutDetail,
      this.recovery
    );
    this.createLineList(
      cnt.ContainerId,
      AdjustLineEnum.THEORIC,
      factoryCutDetail,
      this.theoricInventory
    );
    this.createLineList(
      cnt.ContainerId,
      AdjustLineEnum.PHYSICAL,
      factoryCutDetail,
      this.physicalInventory
    );
    this.createLineList(
      cnt.ContainerId,
      AdjustLineEnum.Transit,
      factoryCutDetail,
      this.transitInventory
    );
    this.createLineList(
      cnt.ContainerId,
      AdjustLineEnum.CEVE,
      factoryCutDetail,
      this.ceveRelation
    );
    this.createLineList(
      cnt.ContainerId,
      AdjustLineEnum.FACTORY,
      factoryCutDetail,
      this.factoryRelation
    );
    this.createLineList(
      cnt.ContainerId,
      AdjustLineEnum.REAL,
      factoryCutDetail,
      this.realInventory
    );
    this.createLineList(
      cnt.ContainerId,
      AdjustLineEnum.TotalPhysicalInventory,
      factoryCutDetail,
      this.totalBalance
    );
    this.createLineList(
      cnt.ContainerId,
      AdjustLineEnum.TotalDifferences,
      factoryCutDetail,
      this.difference
    );
    this.createLineList(
      cnt.ContainerId,
      AdjustLineEnum.PERCENTAGE,
      factoryCutDetail,
      this.percentage
    );

  }

  createAdjustLists(factoryCutDetail: AdjustDetail[], cnt: Container) {
    this.createAdjustLine(
      cnt.ContainerId,
      AdjustLineEnum.PreviousInventory,
      factoryCutDetail,
      this.previousInventory
    );
    this.createAdjustLine(
      cnt.ContainerId,
      AdjustLineEnum.PURCHASES,
      factoryCutDetail,
      this.purchases
    );
    this.createAdjustLine(
      cnt.ContainerId,
      AdjustLineEnum.RECYCLING,
      factoryCutDetail,
      this.recycling
    );
    this.createAdjustLine(
      cnt.ContainerId,
      AdjustLineEnum.Others,
      factoryCutDetail,
      this.other
    );
    this.createAdjustLine(
      cnt.ContainerId,
      AdjustLineEnum.RECOVERY,
      factoryCutDetail,
      this.recovery
    );
    this.createAdjustLine(
      cnt.ContainerId,
      AdjustLineEnum.THEORIC,
      factoryCutDetail,
      this.theoricInventory
    );
    this.createAdjustLine(
      cnt.ContainerId,
      AdjustLineEnum.PHYSICAL,
      factoryCutDetail,
      this.physicalInventory
    );
    this.createAdjustLine(
      cnt.ContainerId,
      AdjustLineEnum.Transit,
      factoryCutDetail,
      this.transitInventory
    );
    this.createAdjustLine(
      cnt.ContainerId,
      AdjustLineEnum.CEVE,
      factoryCutDetail,
      this.ceveRelation
    );
    this.createAdjustLine(
      cnt.ContainerId,
      AdjustLineEnum.FACTORY,
      factoryCutDetail,
      this.factoryRelation
    );
    this.createAdjustLine(
      cnt.ContainerId,
      AdjustLineEnum.REAL,
      factoryCutDetail,
      this.realInventory
    );
    this.createAdjustLine(
      cnt.ContainerId,
      AdjustLineEnum.TotalPhysicalInventory,
      factoryCutDetail,
      this.totalBalance
    );
    this.createAdjustLine(
      cnt.ContainerId,
      AdjustLineEnum.TotalDifferences,
      factoryCutDetail,
      this.difference
    );
    this.createAdjustLine(
      cnt.ContainerId,
      AdjustLineEnum.PERCENTAGE,
      factoryCutDetail,
      this.percentage
    );

  }

  /**
   * Creates lists for every single line in DOM Table
   *
   * @param {number} containerId - Container id to add to list
   * @param {string} line - Line to add the container
   * @param {FactoryCutDetail[]} detail - Detail list where get the item
   * @param {item[]} listToAdd - list to add the item
   * @memberof InventoryFactoryComponent
   */
  createLineList(
    containerId: number,
    line: number,
    detail: AdjustDetailCut[],
    listToAdd: item[]
  ): void {
    const containerExists: AdjustDetailCut = detail.find(
      (dtl) => dtl.Line === line && dtl.ContainerId === containerId
    );
    let physical: item;
    if (line === AdjustLineEnum.REAL) {
      physical = this.physicalInventory.find(
        (dtl) => dtl.containerId === containerId
      );
    }
    if (containerExists) {
      listToAdd.push({
        containerId: containerId,
        quantity:
          line === AdjustLineEnum.REAL
            ? containerExists.Quantity - physical.quantity
            : containerExists.Quantity,
      });
    } else {
      listToAdd.push({
        containerId: containerId,
        quantity: line === AdjustLineEnum.REAL ? 0 - physical.quantity : 0,
      });
    }
  }

  createAdjustLine(
    containerId: number,
    line: number,
    detail: AdjustDetail[],
    listToAdd: item[]
  ): void {
    let containerCode = this.containers.find(x => x.ContainerId == containerId).ContainerCode;
    const containerExists: AdjustDetail = detail.find(
      (dtl) => dtl.Line === line && dtl.ContainerCode === containerCode
    );
    let physical: item;
    if (line === AdjustLineEnum.REAL) {
      physical = this.physicalInventory.find(
        (dtl) => dtl.containerId === containerId
      );
    }
    if (containerExists) {
      listToAdd.push({
        containerId: containerId,
        quantity:
          line === AdjustLineEnum.REAL
            ? containerExists.Quantity - physical.quantity
            : containerExists.Quantity,
      });
    } else {
      listToAdd.push({
        containerId: containerId,
        quantity: line === AdjustLineEnum.REAL ? 0 - physical.quantity : 0,
      });
    }
  }

  /**
   * triggers when user select some result on autocomplete and assign this value to factory variable
   *
   * @param {*} event
   * @memberof InventoryFactoryComponent
   */
  async selectFactory() {
    // this.factory = event;
    await this.inventoryCutService
      .getFactoryContainerListById(this.selectedNodeCut.UnitId.toString())
      .toPromise()
      .then(async (x) => {
        this.containers = x;

        await this.searchInventory();
        // this.unitSelected = this.selectedNodeCut.UnitId;
      });
  }

  //#endregion

}



interface item {
  containerId: number;
  quantity: number;
}

export class AdjustDetailCut extends BaseModel {
  CutId: string;
  Line: number;
  ContainerId: number;
  Quantity: number;
}


import { Component, OnInit, ViewChild, ɵSWITCH_COMPILE_NGMODULE__POST_R3__ } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { ReportsService } from '../reports.service'
import { InventoryMovementDetail } from '../../common/models/InventoryMovementDetail.Model';
import { InventoryMovementType } from '../../common/models/InventoryMovementType.Model';
import { CeqTableComponent } from '../../common/ceq-table/ceq-table.component';
import { CalendarService } from '../../configuration/calendar/calendar.service';
import { Calendar } from '../../common/models/Calendar.Model';
import { Region } from '../../common/models/Region.Model';
import { Unit } from '../../common/models/Unit.Model';
import { AdjustService } from '../../inventory/adjust/adjust.service';


@Component({
  selector: 'app-rep-movement',
  templateUrl: './rep-movement.component.html',
  styleUrls: ['./rep-movement.component.scss']
})
export class RepMovementComponent implements OnInit {

  constructor( private reportService : ReportsService, 
    private settings: SettingsService,
    private calendarService: CalendarService,
    private router: Router,
    private globalFunctionService: GlobalFunctionsService,
    private adjustService:AdjustService) { }
    minDate: string;
    maxDate: string;
    RegOri: string;
    currentDate: Date;
    inventory : InventoryMovementDetail[];
    regionList: Region[];
    moveList: InventoryMovementType[];
    moveList2: InventoryMovementType[];
    organizacionList: Unit [];
    moveSelected : string ;
    regSelected : string ;
    moveSelected2 : string ;
    orgSelected : string;
    clasificacionList: Region[] = [];
    clasificacionDest: Region[] = [];
    origenList: Unit[];
    destinoList: Unit[];
    unit: number = 0;
    ClaOri : any;
    Ori: any;
    RegDes : any;
    ClaDes : any;
    Dest: any;
    @ViewChild('listReceivedGrid', { static: false }) listReceivedGrid: CeqTableComponent;
    receivedOption: GridOptions;
    gridOptions = {
      context: this,
      frameworkComponents: {     
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) { },
  };

  downloading: boolean = false;
  canSearch: boolean = false;

  columnGeneratedDefs = [   
    {
      headerName: 'Region Origen ',
      field: 'RegOri',
      width: 25,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      resizable: true
    },
    {
      headerName: 'Clasificación Origen',
      field: 'ClaOri',
      width: 25,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      resizable: true
    },
    {
      headerName: 'Origen',
      field: 'Origen',
      width: 25,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      resizable: true
    },
    {
      headerName: 'Region Destino',
      field: 'RegDes',
      width: 25,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      resizable: true
    },
    {
      headerName: 'Clasificacion Destino',
      field: 'ClaDes',
      width: 25,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      resizable: true
    },
    {
      headerName: 'Destino',
      field: 'Destino',
      width: 25,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      resizable: true
    },
    {
      headerName: 'Fecha',
      field: 'fecha',
      width: 25,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      resizable: true
    },
    {
      headerName: 'Equipo',
      field: 'equipo',
      width: 15,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      resizable: true
    },
    {
      headerName: 'Cantidad',
      field: 'cantidad',
      width: 25,
      cellClass: 'align-end',
      editable: false,
      sortable: true,
      resizable: true
    },
    {
      headerName: 'Tipo Movimiento',
      field: 'tipoMov',
      width: 15,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      resizable: true
    },
    {
      headerName: 'Referencia',
      field: 'referencia',
      width: 75,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      resizable: true
    },
    {
      headerName: 'Usuario',
      field: 'usuario',
      width: 25,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      resizable: true
    },
  ];


  async ngOnInit() {
    this.globalFunctionService.canOperate();
    this.globalFunctionService.hasFSBRPending();
    await  this.calendarService.CalendarInventoryInProgress().toPromise().then((calendar: Calendar[] )=>{ 
      if (calendar.length==1)
      {
        this.globalFunctionService.messageToast(`No es posible realizar esta operación, se encuentra en toma de inventario fisico.`, 'warning');
        this.router.navigate([`/home`]);
      }  
    });

    await this.adjustService.validateAdjustActive(this.settings.getUser().UnitId)
      .toPromise()
      .then(resp => {
        if (resp.AdjustCode !== '') {
          this.globalFunctionService.messageToast(`No es posible realizar esta operación, se encuentra en compulsa.`, 'warning');
          this.router.navigate([`/home`]);
        }
      });

    this.currentDate = new Date();
    this.minDate = this.globalFunctionService.getStringDate(this.currentDate, true, 0);
    this.maxDate = this.globalFunctionService.getStringDate(this.currentDate, false, 0);
    this.getOrganizacion();
    this.getClasificaciones();
    this.getInventorMovementType();
    this.getInventorMovementType2()
    // this.moveSelected = this.moveList[0].MovementType;
    this.moveSelected2="0";
  }

  gridReceivedEmiter(gridOptions?: GridOptions){
    console.log('gridOptions');
    console.log(gridOptions);
    this.receivedOption = gridOptions;
    this.listReceivedGrid.data = [];
  }
  
  getClasificaciones() : void {
    console.log ("Clasificaciones");
   
    var Item1:Region = new Region();
    var Item2:Region = new Region();
    var Item3:Region = new Region();

    Item1.UnitId =1;
    Item1.RegionName="Fábrica";

    this.clasificacionList.push(Item1);
    Item2.UnitId=2;
    Item2.RegionName="CEVE";
    this.clasificacionList.push(Item2);
    Item3.UnitId=3;
    Item3.RegionName="Autoservicio";
    this.clasificacionList.push(Item3);
    this.clasificacionDest.push(Item1);
    this.clasificacionDest.push(Item2);
    this.clasificacionDest.push(Item3);
    console.log("Valor clasificaciones "+ this.clasificacionList);

    this.ClaOri= this.clasificacionList[0].UnitId;
    this.ClaDes= this.clasificacionDest[0].UnitId;
  }


  getInventorMovementType(): void {
    let userId=0;
    let org=1;
    let user = JSON.parse(localStorage.getItem("user"));
     if (user != null && user != undefined) {
       userId=user.Id;
       org=user.Org;
     }
  }

  getInventorMovementType2(): void {
    let userId=0;
    let org=1;
    let user = JSON.parse(localStorage.getItem("user"));
    if (user != null && user != undefined) {
      userId=user.Id;
      org=user.Org;
    }
    this.reportService.getMovType().subscribe((move: any[]) => {
      this.moveList2 = move;
      this.moveSelected2="0";
    });
  
  }

  getOrganizacion(): void {
    let userId=0;
    let org=1;
    let user = JSON.parse(localStorage.getItem("user"));
      if (user != null && user != undefined) {
        userId=user.Id;
        org=user.Org;
      }
    this.reportService.getOrganizacionesByUser(org, userId,).subscribe((regiones: Unit[]) => {
      this.organizacionList = regiones;
      this.orgSelected=regiones[0].UnitId.toString();
      this.changeFilterMovType2();         
    });
  }
    
  getRegions(): void {
    this.reportService.getRegionList(1).subscribe((regiones: Region[]) => {
      this.regionList = regiones;
      this.RegOri=this.regionList[5].UnitId.toString();
      this.RegDes=this.regionList[5].UnitId.toString();
      
      this.changeFilterClaDest();
      this.changeFilterClaOri();      
    });    
  }

  changeFilterRegDest()
  {
    this.destinoList=null;
    this.ClaDes=null;
  }

  changeFilterClaDest()
  {
    this.reportService.getNodoList(this.RegDes,this.ClaDes).subscribe((unitRest: Unit[]) => {
      this.destinoList = unitRest;
      this.Dest= this.destinoList[0].UnitId.toString();      
    });
  }

  changeFilterDest()
  {
  }

  changeFilterRegOri()
  {
    this.origenList=null;
    this.ClaOri=null
  }

  changeFilterMovType()
  {
  }

  changeFilterMovType3()
  {
    let userId=0;
    let org=1;
    let user = JSON.parse(localStorage.getItem("user"));
    if (user != null && user != undefined) {
      userId=user.Id;
      org=user.Org;
    }
    this.reportService.getUserNodesByRegion(org,userId,this.regSelected).subscribe((move: any[]) => {
      this.moveList = move;
      this.moveSelected="0";         
    });
  }
  
  changeFilterMovType2()
  {
    let userId=0;
    let org=1;
    let user = JSON.parse(localStorage.getItem("user"));
    if (user != null && user != undefined) {
      userId=user.Id;
      org=user.Org;
    }
    this.reportService.getUserRegions(org,userId,this.orgSelected).subscribe((move: any[]) => {
      this.regionList = move;
      this.regSelected=this.regionList[0].UnitId.toString();
      this.changeFilterMovType3();         
    });
  }

  changeFilterClaOri()
  {
    this.reportService.getNodoList(this.RegOri,this.ClaOri).subscribe((unitRest: Unit[]) => {
      this.origenList = unitRest;
      this.Ori = this.origenList[0].UnitId.toString();      
    });
  }

  changeFilterOri()
  {
  }

  search()
  {
    if (this.Ori==null || this.Dest==null)
    {
      this.globalFunctionService.messageToast(`Por favor capture los filtros faltantes.`, 'warning');
      return;
    }

    if (this.maxDate <  this.minDate)
    {
      this.globalFunctionService.messageToast(`La fecha final no puede ser menor a la fecha inicial.`, 'warning');
      return;
    }
    if (this.moveSelected==null)
    {
      this.globalFunctionService.messageToast(`Debe seleccionar un tipo de movimiento.`, 'warning');
      return;
    }

    let tmpRegOri=this.regionList.find(x => x.UnitId == parseInt(this.RegOri)).RegionName;
    let tmpRegDes=this.regionList.find(x => x.UnitId == this.RegDes).RegionName;
    let tmpClaOri= this.clasificacionDest.find(x => x.UnitId == this.ClaOri).RegionName;
    let tmpClaDes= this.clasificacionDest.find(x => x.UnitId == this.ClaDes).RegionName; 
    this.canSearch=true;
    
    this.reportService.getRepInvMovements(this.Ori,this.Dest,this.minDate,this.maxDate,this.moveSelected).subscribe((unitRest: any[]) => {
      // this.inventory = unitRest;
      unitRest.forEach(x => { x.RegOri=tmpRegOri , x.RegDes=tmpRegDes , x.ClaOri=tmpClaOri , x.ClaDes=tmpClaDes});

      this.listReceivedGrid.data = unitRest;
      this.canSearch=false;      
    });

    this.canSearch=false;
  }

  download() {
    if (this.maxDate <  this.minDate)
    {
      this.globalFunctionService.messageToast(`La fecha final no puede ser menor a la fecha inicial.`, 'warning');
      return;
    }
    let dInicio : Date = new Date(this.maxDate);
    let dFin : Date = new Date(this.minDate);
    //let laps =2592000000; // 30 dias
    let laps =5184000000 // 60 dias

    if ((dInicio.getTime()- dFin.getTime())> laps)
    {
      this.globalFunctionService.messageToast(`Este reporte solo puede ser generado en intervalos máximos de 60 días.`, 'warning');
      return;
    }

    this.downloading=true;

    this.reportService.getRepInvMovementsExcel(this.minDate,this.maxDate,this.regSelected,this.moveSelected,this.moveSelected2).subscribe((resp: any) => {
      if(resp == null){
        this.globalFunctionService.messageToast(`No se ha encontrado información.`, 'warning');
        return;
      }
      console.log("termino llamada");
     
      let blob = new Blob([resp], { type: resp.type });

      const anchor = document.createElement('a');
     
      anchor.download = `CEQ2-MovInventory.xlsx`;
      anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
      anchor.click();
      this.downloading = false;
    }, err => {
      this.globalFunctionService.messageToast(`Error en la invocación.`, 'warning');
      this.downloading=false;
      console.log(err);
    });
  }
}

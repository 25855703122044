import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';

import { CeqTableComponent } from 'src/app/ceq/common/ceq-table/ceq-table.component';
import { DeleteChildRendered } from '../../../common/renders/delete-child-rendered';
import { EditChildRendered } from '../../../common/renders/edit-child-rendered';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { CarrierService } from '../carrier.service';
import { UserLogin } from 'src/app/ceq/common/models/UserLogin.Model';
import { Ceve } from 'src/app/ceq/common/models/Ceve.Model';

@Component({
  selector: 'app-carrier-list',
  templateUrl: './carrier-list.component.html',
  styleUrls: ['./carrier-list.component.scss']
})
export class CarrierListComponent implements OnInit {

  constructor(private router: Router,
    private settings: SettingsService,
    private carrierService: CarrierService) { }


  @ViewChild('CeqGrid', { static: false }) ceqGrid: CeqTableComponent;
  options: GridOptions;
  PageSizes = ["10", "25", "50", "100", "1000"];
  ceveList: Ceve[] = [];
  ceve: number = 0;
  keyword = "CeveName";
  selectedUnit: string;
  userLogin: UserLogin;
  type: string;

  gridOptions = {
    context: this,
    frameworkComponents: {
      childEditRenderer: EditChildRendered,
      childDeleteMessageRenderer: DeleteChildRendered
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) { },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    }
  }

  columnDefs = [
    {
      headerName: 'Empleado',
      field: 'EmployeeCode',
      width: 20,
      cellClass: 'align-start',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      editable: false
    },
    {
      headerName: 'Placa',
      field: 'LicensePlate',
      width: 20,
      cellClass: 'text-center',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      editable: false
    },
    {
      headerName: 'Modelo',
      field: 'ModelYear',
      width: 10,
      cellClass: 'text-center',
      resizable: true,
      editable: false
    },
    {
      headerName: 'Configuración',
      field: 'VehicleConfiguration',
      width: 20,
      cellClass: 'text-center',
      resizable: true,
      editable: false
    },
    {
      headerName: 'Última modificación',
      field: 'ModifiedAt',
      width: 20,
      cellClass: 'text-center',
      editable: false,
      sortable: true,
      unSortIcon: true,
      resizable: true,
      cellRenderer: (params: any) => moment(params.data.ModifiedAt).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      headerName: 'Modificado por',
      field: 'ModifiedBy',
      width: 20,
      cellClass: 'text-center',
      resizable: true,
      editable: false
    },
    {
      headerName: 'Editar',
      field: 'icon',
      width: 5,
      cellRenderer: 'childEditRenderer',
      colId: 'params',
      cellClass: 'text-center',
      hide: false,
    },
    {
      headerName: 'Eliminar',
      field: 'icon',
      width: 5,
      cellRenderer: 'childDeleteMessageRenderer',
      colId: 'params',
      cellClass: 'text-center',
      hide: false,
    }
  ];


  ngOnInit(): void {
    this.userLogin = this.settings.getUser();
    this.ceve = this.userLogin.UnitId;
  }

  gridEmiter(GridOptions: GridOptions) {
    this.options = GridOptions;
    this.getCeveList()
  }

  onPageSizeChange(value: number) {
    console.log(value);
  }

  btnAddClick() {
    window.open('/tax/carrier/create','_blank');
  }

  edit(row: any) {
    console.log("row", row.data);
    window.open('/tax/carrier/' + row.data.EmployeeCode,'_blank');
  }

  delete(row: any) {
    Swal.fire({
      title: 'Confirmacion',
      text: "¿Desea eliminar el vehiculo del empleado " + row.data.EmployeeCode + "?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> &nbsp; Aceptar',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i> &nbsp; Cancelar',
      backdrop: `rgba(0,157,210,0.5)`
    }).then((result) => {
      if (result.isConfirmed) {
        this.carrierService.deleteCarrier(row.data.EmployeeCode).subscribe(data => {
          console.log("result", data);
          Swal.fire(
            '¡Éxito!',
            'El vehículo se eliminó correctamente.',
            'success'
          );
        }, (error) => {
          console.log("error", error);
          Swal.fire('¡Error!', error.error, 'error');
        }, () => {
          this.getCarriers();
        });
      }
    })
  }


  getCeveList() {
    this.settings.getUserProfileLevels().then((userlevels) => {
      if (userlevels != undefined && userlevels.length > 0) {
        let findedLevel = userlevels.find(x => x.UnitId == this.userLogin.UnitId);
        if (findedLevel != undefined && findedLevel != null) {
          this.type = findedLevel.LevelType;
        }
        else {
          this.type = userlevels[0].LevelType;
        }
        this.ceveList = [];
        let userCeves = userlevels.filter(x => x.LevelType == "CEVE");
        if (userCeves.length > 0) {
          userCeves.forEach(unit => {
            let newCeve = new Ceve();
            newCeve.UnitId = unit.UnitId;
            newCeve.CeveName = unit.UnitDescription;
            this.ceveList.push(newCeve);
          });
          this.ceveList = this.ceveList.sort((a, b) => a.CeveName < b.CeveName ? -1 : 1);
          if (this.ceve == undefined) {
            this.ceve = this.ceveList[0].UnitId;
          }
          let userCeve = this.ceveList.find(x => x.UnitId == this.ceve);
          if (userCeve != undefined) {
            this.selectedUnit = userCeve.CeveName;
          }
        }
      }
      this.getCarriers();
    });
  }

  getCarriers() {
    this.ceqGrid.endPoint = `carrier/unit/${this.ceve}`
    this.ceqGrid.refreshTable();
  }

  selectEvent(event: any) {
    this.ceve = event.UnitId;
    this.getCarriers();
  }

  cmbOutputCeve(): void {

  }

}

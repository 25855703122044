import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from '../../../../app/ceq/common/services/GenericServices';

@Injectable()
export class UserblockService {
    public userBlockVisible: boolean;
    constructor(private genericServ: GenericService) {
        // initially visible
        this.userBlockVisible  = true;
    }

    getVisibility() {
        return this.userBlockVisible;
    }

    setVisibility(stat = true) {
        this.userBlockVisible = stat;
    }

    toggleVisibility() {
        this.userBlockVisible = !this.userBlockVisible;
    }

    getMultiorgNodes(unitId: number, org: number): Observable<any>{
        let url = `ceveexternalcode/${org}/Multiorg/external/${unitId}`;
        return this.genericServ.getRequest(url, {}, 0);    
      }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { ZipcodeService } from '../zipcode.service';

import { toNumber } from 'lodash';
import Swal from 'sweetalert2';
import { UserLogin } from 'src/app/ceq/common/models/UserLogin.Model';
import { ZipCodeModel } from 'src/app/ceq/common/models/ZipCode.Model';


@Component({
  selector: 'app-zipcode-detail',
  templateUrl: './zipcode-detail.component.html',
  styleUrls: ['./zipcode-detail.component.scss']
})
export class ZipcodeDetailComponent implements OnInit {

  constructor(private url: ActivatedRoute,
    private router: Router,
    private settings: SettingsService,
    private zipcodeService: ZipcodeService) {
    this.zipCode = new ZipCodeModel();
  }

  org: number = 1;
  userLogin: UserLogin;
  zipCode: ZipCodeModel;
  zipCodeId: number;
  isNew: boolean = false;

  ngOnInit(): void {
    this.userLogin = this.settings.getUser();
    let currentId = this.url.snapshot.paramMap.get('id');

    this.zipCode.Org = this.userLogin.Org;

    if (this.isNullOrWhitespace(currentId)) {
      this.zipCode.IdZipCode = "";
      this.isNew = true;
    }
    else {
      this.zipCode.IdZipCode = currentId;
      this.isNew = false;
      this.getZipCode();
    }
  }

  formSubmit(zipCodeForm: ZipCodeModel) {
    if (this.isNew) {
      //Create
      this.zipcodeService.createZipCode(this.zipCode).subscribe(data => {
        Swal.fire('¡Éxito!', 'Código postal creado', 'success');
        this.btnCancelOnClick();
      }, (error) => {
        Swal.fire('¡Error!', error.error, 'error');
      });
    }
    else {
      //Update
      this.zipcodeService.updateZipCode(this.zipCode).subscribe(data => {
        Swal.fire('¡Éxito!', 'Código postal actualizado', 'success');
        this.btnCancelOnClick();
      }, (error) => {
        Swal.fire('¡Error!', error.error, 'error');
      });
    }
  }

  btnCancelOnClick() {
    this.router.navigate(['/tax/zipcode']);
  }


  isNullOrWhitespace(input) {
    if (typeof input === 'undefined' || input == null) return true;
    return input.replace(/\s/g, '').length < 1;
  }

  getZipCode() {
    this.zipcodeService.getZipCodeById(this.zipCode.Org, this.zipCode.IdZipCode).subscribe(data => {
      this.zipCode = data;
    }, (error) => {
      Swal.fire('¡Error!', "No se encontró la configuración solicitada", 'error');
      this.btnCancelOnClick();
    });
  }


}

<div class="content-heading">
  <div class="header-container">
    <h3>CARATULA CENTRO DE VENTAS {{currentYear}}</h3>
    <small>Car&aacute;tula de inventario</small>
  </div>
</div>
<div class="form-container">
  <div class="container-fluid black-text text-font">
    <div class="row">
      <div class="col-lg-12">
        <div class="col-md-12 col-xs-12">
          <div class="row d-flex">
            <div class="col-2 red-text center-data">
              <h4 class="center-data">CENTRO DE VENTAS:</h4>
            </div>
            <div class="col-5 ng-autocomplete">
              <ng-autocomplete (selected)='selectEvent($event)' [initialValue]="" [searchKeyword]="keyword" [data]="ceve"
                               [placeholder]="'Escriba un centro de ventas'" [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>

              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.CeveName"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="'No se encontraron coincidencias'"></div>
              </ng-template>

            </div>
            <div class="col-1 red-text center-data">
              <h4>FECHA:</h4>
            </div>
            <div class="col-2 red-text center-data">
              <select class="form-control" [(ngModel)]="selectedDate" (change)="selectEvent()">
                <option value="undefined" selected disabled>{{'Seleccione una fecha'}}</option>
                <option *ngFor="let event of listDateAvalible" [value]="event.StartAt">
                  {{event.StartAt | date: 'dd/MMM/yyyy' : 'es'}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div id="inventoryCutPage" class="col" *ngIf="hasSelected">
    <div *ngIf="status == 'PUBLISHED'" class="row">
      <span>&nbsp;<b>Fecha y hora de posteo: {{modifiedAt}}</b></span>
    </div>
    <div class="data-container black-text" [formGroup]="inventoryForm">
      <!-- <div class="full-border" [formGroup]="inventoryForm"> -->
      <div class="d-flex">
        <div class="col-3">
          <b>
            Estatus: {{
          status == 'NEW' ? 'NUEVO'
        : status == 'SAVED' ? 'GUARDADO'
        : status == 'CLOSED' ? 'CERRADO'
        : status == 'INCOMPLETESIGNED' ? 'CERRADO'
        : status == 'PUBLISHED' ? 'PUBLICADO'
        : status == 'LAPSED' ? 'CADUCADO'
        : 'OTRO'
            }}
          </b>
        </div>
        <div class="column_width center-data" *ngFor="let i of containerData">
          <div class="column_width center-data "> <small>{{i.ContainerDescription}}</small></div>
        </div>
      </div>

      <div class="d-flex">
        <div class="col-3">
          N&uacute;mero: {{ceveCutId}}
        </div>
        <div class="column_width center-data" *ngFor="let i of containerData">
          <div class="column_width center-data ">
            <h5>{{i.ContainerName}}</h5>
          </div>
        </div>
      </div>

      <div *ngIf="status !='LAPSED'" class="d-flex" formArrayName="physicalInventory">
        <div class="col-3 bg-rowtitle full-border">
          1 Inventario F&iacute;sico
        </div>
        <div class="column_width center-data" *ngFor="let physical of physicalInventory.controls; index as i">
          <input type="number" style="text-align:right"
            onkeyup="if(this.value<0){this.value= this.value * -1}" class="column_width" [formControlName]="i">
        </div>
      </div>

      <div *ngIf="status =='LAPSED'" class="d-flex" formArrayName="physicalInventoryfromLapsed">
        <div class="col-3 bg-rowtitle full-border">
          1 Inventario F&iacute;sico
        </div>
        <div class="column_width center-data" *ngFor="let physical of physicalInventoryfromLapsed.controls; index as i">
          <input type="number" style="text-align:right"
            onkeyup="if(this.value<0){this.value= this.value * -1}" class="column_width" [formControlName]="i">
        </div>
      </div>

      <div class="d-flex " formArrayName="couponInventory">
        <div class="col-3 bg-rowtitle full-border">
          2 Vales de envases
        </div>
        <div class="column_width center-data " *ngFor="let coupon of couponInventory.controls; index as i">
          <input type="number" style="text-align:right"
            onkeyup="if(this.value<0){this.value= this.value * -1}" class="column_width" [formControlName]="i">
        </div>
      </div>


      <div class="d-flex ">
        <div class="col-3 bg-rowtitle full-border">
          &nbsp;&nbsp;2.1 Vales de envases AS
        </div>
        <div class="column_width center-data full-border" *ngFor="let paybill of paybillAS">
          <div class="column_width center-data ">{{paybill | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex " formArrayName="promissoryNoteInventory">
        <div class="col-3 bg-rowtitle full-border">
          3 Pagares
        </div>
        <div class="column_width center-data " *ngFor="let promissory of promissoryNoteInventory.controls; index as i">
          <input type="number" style="text-align:right"
            onkeyup="if(this.value<0){this.value= this.value * -1}" class="column_width" [formControlName]="i">
        </div>
      </div>

      <div class="d-flex " formArrayName="totalInventoryVehicle">
        <div class="col-3 bg-rowtitle full-border">
          4 Existencia Total en Veh&iacute;culos
        </div>
        <div class="column_width center-data full-border" *ngFor="let totalVehicle of totalInventoryVehicle.controls">
          <div class="column_width center-data "> {{(totalVehicle.value >= 0 ? totalVehicle.value : 0) | number:'1.0':'en'}} </div>
        </div>
      </div>

      <div class="d-flex ">
        <div class="col-3 bg-rowtitle full-border">
          &nbsp;&nbsp;4.1 Existencia chofer AS
        </div>
        <div class="column_width center-data full-border" *ngFor="let asVeh of asDriverVehicles">
          <div class="column_width center-data ">{{asVeh | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex ">
        <div class="col-3 bg-rowtitle full-border">
          &nbsp;&nbsp;4.2 Existencia clientes AS
        </div>
        <div class="column_width center-data full-border" *ngFor="let asVeh of asClients">
          <div class="column_width center-data ">{{asVeh | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex " formArrayName="rtmVehicles">
        <div class="col-3 bg-rowtitle full-border">
          &nbsp;&nbsp;4.3 Existencia veh&iacute;culos RTM (Viernes)
        </div>
        <div class="column_width center-data " *ngFor="let rtm of rtmVehicles.controls; index as i">
          <input type="number" style="text-align:right"
            onkeyup="if(this.value<0){this.value= this.value * -1}" class="column_width" [formControlName]="i">
        </div>
      </div>

      <div class="d-flex " formArrayName="routeCharges">
        <div class="col-3 bg-rowtitle full-border">
          &nbsp;&nbsp;4.4 Cargos de rutas (Sabado)
        </div>
        <div class="column_width center-data " *ngFor="let route of routeCharges.controls; index as i">
          <input type="number" style="text-align:right"
            onkeyup="if(this.value<0){this.value= this.value * -1}" class="column_width" [formControlName]="i">
        </div>
      </div>

      <div class="d-flex " formArrayName="stockAllocation">
        <div class="col-3 bg-rowtitle full-border">
          &nbsp;&nbsp;4.5 Stock Allocation
        </div>
        <div class="column_width center-data " *ngFor="let stock of stockAllocation.controls; index as i">
          <input type="number" style="text-align:right"
            onkeyup="if(this.value<0){this.value= this.value * -1}" class="column_width" [formControlName]="i">
        </div>
      </div>

      <div class="d-flex " formArrayName="vanLoadRejection">
        <div class="col-3 bg-rowtitle full-border">
          &nbsp;&nbsp;4.6 Van Load Rejection
        </div>
        <div class="column_width center-data " *ngFor="let vanLoad of vanLoadRejection.controls; index as i">
          <input type="number" style="text-align:right"
            onkeyup="if(this.value<0){this.value= this.value * -1}" onkeyup="if(this.value<0){this.value= this.value * -1}" class="column_width" [formControlName]="i">
        </div>
      </div>

      <div class="d-flex " *ngIf="cutVersion > 1">
        <div class="col-3 bg-rowtitle full-border">
          &nbsp;&nbsp;4.7 Comodatos Vigentes
        </div>
        <div class="column_width center-data  full-border" *ngFor="let data of currentComodato">
          <div class="column_width center-data">{{data | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex " *ngIf="cutVersion > 1">
        <div class="col-3 bg-rowtitle full-border">
          &nbsp;&nbsp;4.8 Comodatos Vencidos/traspasados
        </div>
        <div class="column_width center-data  full-border" *ngFor="let data of expiredComodato">
          <div class="column_width center-data">{{data | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex ">
        <div class="col-3 bg-rowtitle full-border">
          5 Tr&aacute;nsito
        </div>
        <div class="column_width center-data full-border" *ngFor="let t of transit">
          <div class="column_width center-data ">{{t | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div *ngIf="isNodeLeader" class="d-flex ">
        <div class="col-3 bg-rowtitle full-border">
          5.1 Tr&aacute;nsito de concentrador
        </div>
        <div class="column_width center-data full-border" *ngFor="let t of transitLeader">
          <div class="column_width center-data ">{{t | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex white-text" formArrayName="totalPhysicalInventory">
        <div class="col-3 bg-total-color  full-border">
          Inv. F&iacute;sico Total
        </div>
        <div class="column_width center-data  bg-total-color"
             *ngFor="let totalPhysicalInv of totalPhysicalInventory.controls;">
          <div class="column_width center-data ">{{totalPhysicalInv.value | number:'1.0':'en'}}</div>
        </div>
      </div>


      <div class="d-flex collapse-border over-color">
        <div class="col-3 bg-rowtitle full-border pointer" (click)="toggleChargeCollapsed()" >
          <div [ngClass]="{'arrow-left': chargedIsCollapsed,'arrow-down': !chargedIsCollapsed }"></div>
            6 Equipo cobrado a colaboradores
        </div>
        <div class="column_width center-data full-border" *ngFor="let charge of chargedToCollaborator">
          <div class="column_width center-data ">{{charge | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex" [collapse]="chargedIsCollapsed" [isAnimated]="true" *ngIf="cutVersion > 0">
        <div class="col-3 bg-rowtitle full-border">
          &nbsp;&nbsp; 6.1 Vale de chofer
        </div>
        <div class="column_width center-data full-border" *ngFor="let charge of valeChofer">
          <div class="column_width center-data ">{{charge | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex" [collapse]="chargedIsCollapsed" [isAnimated]="true" *ngIf="cutVersion > 0">
        <div class="col-3 bg-rowtitle full-border">
          &nbsp;&nbsp; 6.2 Vale cobrado a colaboradores
        </div>
        <div class="column_width center-data full-border" *ngFor="let charge of valeColabs">
          <div class="column_width center-data ">{{charge | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex" [collapse]="chargedIsCollapsed" [isAnimated]="true" *ngIf="cutVersion > 0">
        <div class="col-3 bg-rowtitle full-border">
          &nbsp;&nbsp; 6.3 Vale de compulsas AS
        </div>
        <div class="column_width center-data full-border" *ngFor="let charge of compulsas">
          <div class="column_width center-data ">{{charge | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex" [collapse]="chargedIsCollapsed" [isAnimated]="true" *ngIf="cutVersion > 1">
        <div class="col-3 bg-rowtitle full-border">
          &nbsp;&nbsp; 6.4 Comodatos Vencidos
        </div>
        <div class="column_width center-data full-border" *ngFor="let charge of expiredChargedComodato">
          <div class="column_width center-data ">{{charge | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex ">
        <div class="col-3 bg-rowtitle full-border">
          7 Bajas (Perdidas Fortuitas)
        </div>
        <div class="column_width center-data " *ngFor="let i of losses">
          <div class="column_width center-data ">{{i | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex " formArrayName="diffNotAttributable">
        <div class="col-3 bg-total-color full-border">
          Diferencia no imputable al Ceve
        </div>
        <div class="column_width center-data  bg-total-color" *ngFor="let diff of diffNotAttributable.controls">
          <div class="column_width center-data ">{{diff.value | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex ">
        <div class="col-3 bg-rowtitle full-border">
          8 Recuperaci&oacute;n Seguridad
        </div>
        <div class="column_width center-data  full-border" *ngFor="let recovery of securityRecovery">
          <div class="column_width center-data ">{{recovery | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex " *ngIf="cutVersion > 2">
        <div class="col-3 bg-rowtitle full-border">
          9 Miscel&aacute;neos
        </div>
        <div class="column_width center-data  full-border" *ngFor="let misc of miscellaneous">
          <div class="column_width center-data ">{{misc | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex " *ngIf="cutVersion > 0">
        <div class="col-3 white-text bg-blue-color full-border">
          Inventario f&iacute;sico del inventario anterior
        </div>
        <div class="column_width center-data  full-border bg-blue-color" *ngFor="let inv of previousInventory">
          <div class="column_width white-text center-data ">{{inv | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex " *ngIf="cutVersion > 0">
        <div class="col-3 white-text bg-blue-color full-border">
          Entradas
        </div>
        <div class="column_width center-data  full-border bg-blue-color" *ngFor="let inv of inputMovements">
          <div class="column_width white-text center-data ">{{inv | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex " *ngIf="cutVersion > 0">
        <div class="col-3 white-text bg-blue-color full-border">
          Salidas
        </div>
        <div class="column_width center-data  full-border bg-blue-color"  *ngFor="let inv of outputMovements">
          <div class="column_width white-text center-data ">{{inv | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex">
        <div class="col-3 full-border" style="display: none;">
          Inventario Te&oacute;rico (comprobaci&oacute;n)
        </div>
        <div class="column_width center-data  full-border" *ngFor="let inv of invAvailableCalculated">
          <div style="display: none;" class="column_width center-data">{{inv | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex">
        <div class="col-3 bg-rowtitle bg-blue-color full-border">
          Inventario Te&oacute;rico
        </div>
        <div class="column_width center-data full-border" [ngClass]="{'bg-yellow-color': (supposed != invAvailableCalculated[i]),
        'bg-blue-color':  (supposed == invAvailableCalculated[i])}"
             *ngFor="let supposed of supposedInventory; index as i">
          <div
               class="column_width center-data">
            {{supposed | number:'1.0':'en'}}
          </div>
        </div>
      </div>

      <div class="d-flex " formArrayName="diffAttributable">
        <div class="col-3 full-border bg-total-color">
          Diferencia de Inventario<br>(Físico Total - (Teórico CV's + Saldos AS))
        </div>
        <div class="column_width center-data  full-border bg-total-color" *ngFor="let diff of diffAttributable.controls">
          <div class="column_width center-data ">{{diff.value | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex " *ngIf="cutVersion > 2">
        <div class="col-3 bg-rowtitle full-border">
          Ajuste de compulsas al cv's del periodo
        </div>
        <div class="column_width center-data  full-border" *ngFor="let adjust of adjustCeve">
          <div class="column_width center-data ">{{adjust | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex " *ngIf="cutVersion > 2">
        <div class="col-3 bg-rowtitle full-border">
          Diferencias netas del periodo
        </div>
        <div class="column_width center-data  full-border" *ngFor="let total of totalDifferences; index as i">
          <div class="column_width center-data ">{{(adjustCeve[i] + diffAttributable.controls[i].value) | number:'1.0':'en'}}</div>
        </div>
      </div>

      <div class="d-flex " *ngIf="cutVersion > 0">
        <div class="col-3 full-border bg-green-color">
          Inv de cierre (Fisico Total - Saldos AS)
        </div>
        <div class="column_width center-data  bg-green-color"
             *ngFor="let totalPhysicalInv of totalPhysicalInventory.controls; index as i">
          <div class="column_width center-data ">{{(totalPhysicalInv.value - asClients[i]) | number:'1.0':'en'}}</div>
        </div>
      </div>

    </div>
  </div>
  <!-- </div> -->

  <br>

  <div class="d-flex justify-content-around" *ngIf="hasSelected && isCeveSupervisor && (status != 'NEW' && status != 'SAVED')">
    <div class="col-4">
      <div class="card text-center">
        <div class="card-body">

          <b class="black-text">VALID&Oacute;</b>
          <br> Despachador / Recibidor Centro de ventas
          <hr>
          <button [disabled]="!signActDispatcherReceiver || status == 'PUBLISHED' || status == 'LAPSED' || !isDateTimeRangeValid" *ngIf="!isDispatcherReciverSign" class="col-10 btn btn-outline-success" (click)="saveSigns('isDispatcherReciverSign')">
            <i class="fa fa-circle"></i>&nbsp;
            <span>Firmar</span>
          </button>
          <button *ngIf="isDispatcherReciverSign" class="col-10 btn btn-outline-dark" [disabled]="true">
            <i class="fa fa-check-circle"></i>&nbsp;
            <span>Firmó</span>
          </button>
          <br>
          <b *ngFor="let value of signs.dispatcherReciverSign.split('|')"><br>{{value}}</b>

        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card text-center">
        <div class="card-body">

          <b class="black-text">VoBo</b>
          <br> Administrador Centro de Ventas
          <hr>
          <button [disabled]="!signActAdministrator || status == 'PUBLISHED'  || status == 'LAPSED' || !isDateTimeRangeValid" *ngIf="!isManagerSign" class="col-10 btn btn-outline-success" (click)="saveSigns('isManagerSign')">
            <i class="fa fa-circle"></i>&nbsp;
            <span>Firmar</span>
          </button>
          <button *ngIf="isManagerSign" class="col-10 btn btn-outline-dark" [disabled]="true">
            <i class="fa fa-check-circle"></i>&nbsp;
            <span>Firmó</span>
          </button>
          <br>
          <b *ngFor="let value of signs.managerSignature.split('|')"><br>{{value}}</b>

        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card text-center">
        <div class="card-body">
          <b class="black-text">APROB&Oacute;</b>
          <br> Supervisor Despacho Centro de Ventas
          <hr>
          <button [disabled]="!signActSuppervisorDisp || status == 'PUBLISHED'  || status == 'LAPSED' || !isDateTimeRangeValid" *ngIf="!isSupervisorSign" class="col-10 btn btn-outline-success" (click)="saveSigns('isSupervisorSign')">
            <i class="fa fa-circle"></i>&nbsp;
            <span>Firmar</span>
          </button>
          <button *ngIf="isSupervisorSign" class="col-10 btn btn-outline-dark" [disabled]="true">
            <i class="fa fa-check-circle"></i>&nbsp;
            <span>Firmó</span>
          </button>
          <br>
          <b *ngFor="let value of signs.supervisorSignature.split('|')"><br>{{value}}</b>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-around" *ngIf="hasSelected && (status != 'NEW' && status != 'SAVED') && cutVersion > 0">
    <div class="col-4">
      <label>Comentarios de Despachador/Recibidor</label>
      <textarea class="form-control max-height-comment"
                placeholder="Sin comentarios"
                [(ngModel)]="signs.dispatcherReciverComment"
                [disabled]="!signActDispatcherReceiver || (status != 'CLOSED' && status != 'INCOMPLETESIGNED') || !isDateTimeRangeValid || isDispatcherReciverSign">
    </textarea>
    </div>
    <div class="col-4">
      <label>Comentarios de Administrador</label>
      <textarea class="form-control max-height-comment"
                placeholder="Sin comentarios"
                [(ngModel)]="signs.managerComment"
                [disabled]="!signActAdministrator || (status != 'CLOSED' && status != 'INCOMPLETESIGNED') || !isDateTimeRangeValid || isManagerSign">
    </textarea>
    </div>
    <div class="col-4">
      <label>Comentarios de Supervisor Despacho</label>
      <textarea class="form-control max-height-comment"
                placeholder="Sin comentarios"
                [(ngModel)]="signs.supervisorComment"
                [disabled]="!signActSuppervisorDisp || (status != 'CLOSED' && status != 'INCOMPLETESIGNED') || !isDateTimeRangeValid || isSupervisorSign">
    </textarea>
    </div>
  </div>

  <br>

  <div class="row d-flex" *ngIf="hasSelected && isDateTimeRangeValid && status!='LAPSED'">
    <div class=" col-2 center-data" *ngIf="isCeveAdmn && hasSelected">
      <button class="col-10 mr-1 btn btn-labeled btn-outline-primary mb-2" (click)="saveOrEdit(false)"
              [disabled]="status != 'NEW' && status != 'SAVED'">
        <span class="btn-label">
          <i class="fa fa-save"></i>
        </span>
        <span *ngIf="!isSaving">Guardar</span>
        <span *ngIf="isSaving">Guardando...</span>
      </button> &nbsp; &nbsp; &nbsp;
    </div>

    <div class=" col-2 center-data" *ngIf="isCeveAdmn && hasSelected">
      <button class="col-10 mr-1 btn btn-labeled btn-outline-primary mb-2" (click)="saveOrEdit(true)" [disabled]="status != 'SAVED'">
        <span class="btn-label">
          <i class="fa fa-save"></i>
        </span>
        <span *ngIf="!isSaving">Cerrar</span>
        <span *ngIf="isSaving">Cerrando...</span>
      </button> &nbsp; &nbsp; &nbsp;
    </div>
    <div class=" col-3 center-data" *ngIf="isCeveSupervisor  && hasSelected">
      <button class="col-10 mr-1 btn btn-labeled btn-outline-primary mb-2" (click)="openCeveCut()"
              [disabled]="status != 'CLOSED' && status != 'INCOMPLETESIGNED'">
        <span class="btn-label">
          <i class="fa fa-save"></i>
        </span>
        <span *ngIf="!isSaving">Reabrir Inventario</span>
        <span *ngIf="isSaving">Reabriendo...</span>
      </button> &nbsp; &nbsp; &nbsp;
    </div>
  </div>
  <div class="row d-flex">
    <div class=" col-10 center-data"></div>
    <div class=" col-2 center-data">
      <button class="mr-1 btn btn-labeled btn-outline-primary mb-2" (click)="print()" *ngIf="disableButton">
        <span class="btn-label">
          <i class="fa fa-print" aria-hidden="true"></i>
        </span>
        <span>Imprimir</span>
      </button>
    </div>
  </div>
  <br>
  <p *ngIf="hasSelected && !isDateTimeRangeValid"><b>Nota:</b> Se accedió el día <b>{{actualDateTime.split('T')[0]}}</b> a las <b>{{actualDateTime.split('T')[1]}}</b>. La carátula solo podrá ser capturada entre el día  <b>{{lastEvent.StartAt.toString().split('T')[0]}}</b> a las  <b>{{lastEvent.StartAt.toString().split('T')[1]}}</b> y el día <b>{{lastEvent.EndAt.toString().split('T')[0]}}</b> a las  <b>{{lastEvent.EndAt.toString().split('T')[1]}}</b></p>
  <p *ngIf="hasSelected && isDateTimeRangeValid"><b>Nota:</b> La captura de carátula estará disponible hasta el día <b>{{lastEvent.EndAt.toString().split('T')[0]}}</b> a las <b>{{lastEvent.EndAt.toString().split('T')[1]}}</b></p>
  <app-loading *ngIf="isLoading"></app-loading>
  <!-- <button (click)="updateSelfService()">Actualizar saldos AS</button> -->
  <!-- <button (click)="lapsedInventory()">LAPSED</button> -->
</div>

<div class="content-heading">
  <div class="header-container">
    <h3>Compras de Equipo</h3>
    <small>Programaci&oacute;n de compras & Capex</small>
  </div>
</div>

<div class="container-fluid card">
  <div class="row">
    <div class="col-lg-12">

      <tabset [justified]="true" class="ie-fix-flex">
        <tab (selectTab)="changeTab(false)">

          <ng-template tabHeading>Programaci&oacute;n Compras de Equipo</ng-template>

          <div class="card card-default card-body">
            <div class="row">
              <div class="col-lg-2 col-md-6 form-group">
                <span class="form-label">Fecha inicial</span>
                <input type="date"
                       class="form-control"
                       [(ngModel)]="initialDate"
                       max="{{finalDate}}"
                       [disabled]="orderId.length > 0" />
              </div>

              <div class="col-lg-2 col-md-6 form-group">
                <span class="form-label">Fecha final</span>
                <input type="date"
                       class="form-control"
                       [(ngModel)]="finalDate"
                       max="{{maxDate}}"
                       (change)="finalDateChange()"
                       [disabled]="orderId.length > 0" />
              </div>

              <div class="col-lg-2 col-md-6 form-group">
                <span class="form-label">Estatus</span>
                <select [(ngModel)]="statusSelected"
                        class="form-control"
                        name="StatusOption"
                        [disabled]="orderId.length > 0">
                  <option *ngFor="let obj of status" [value]="obj.Id">{{obj.Description}}</option>
                </select>
              </div>

              <div class="col-lg-2 col-md-6 form-group">
                <span class="form-label">Art&iacute;culo</span>
                <div class="input-group">
                  <input type="text"
                         class="form-control"
                         [(ngModel)]="containerCode"
                         (ngModelChange)="containerCode = $event.toUpperCase()"
                         [disabled]="orderId.length > 0" />
                  <div class="input-group-append">
                    <button class="btn btn-secondary" type="button" (click)="containerCode=''" [disabled]="orderId.length > 0">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>


              </div>

              <div class="col-lg-4 col-md-6 form-group">
                <span class="form-label">Pedido</span>
                <div class="input-group">
                  <input type="text"
                         class="form-control"
                         [(ngModel)]="orderId"
                         (ngModelChange)="orderId = $event.toUpperCase()" />
                  <div class="input-group-append">
                    <button class="btn btn-secondary" type="button" (click)="orderId=''">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-lg-2 col-md-6 form-group">
                <span class="form-label">Factura</span>
                <div class="input-group">
                  <input type="text"
                         class="form-control"
                         [(ngModel)]="invoice"
                         (ngModelChange)="invoice = $event.toUpperCase()"
                         [disabled]="orderId.length > 0" />
                  <div class="input-group-append">
                    <button class="btn btn-secondary" type="button" (click)="invoice=''" [disabled]="orderId.length > 0">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-lg-2 col-md-6 form-group">
                <span class="form-label">Orden de Compra:</span>
                <div class="input-group">
                  <input type="text"
                         class="form-control"
                         [(ngModel)]="purchaseOrder"
                         (ngModelChange)="purchaseOrder = $event.toUpperCase()"
                         [disabled]="orderId.length > 0" />
                  <div class="input-group-append">
                    <button class="btn btn-secondary" type="button" (click)="purchaseOrder=''" [disabled]="orderId.length > 0">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>

              </div>

              <div class="col-lg-2 col-md-6 form-group">
                <span class="form-label">No. Recibo:</span>
                <div class="input-group">
                  <input type="text"
                         class="form-control"
                         [(ngModel)]="receiptNumber"
                         (ngModelChange)="receiptNumber = $event.toUpperCase()"
                         [disabled]="orderId.length > 0" />
                  <div class="input-group-append">
                    <button class="btn btn-secondary" type="button" (click)="receiptNumber=''" [disabled]="orderId.length > 0">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 form-group">
                <span class="form-label">Regi&oacute;n Comprador:</span>
                <div class="input-group">
                  <input type="text"
                         class="form-control"
                         [(ngModel)]="regionPurchase"
                         [disabled]="orderId.length > 0" />
                  <div class="input-group-append">
                    <button class="btn btn-secondary" type="button" (click)="regionPurchase=''" [disabled]="orderId.length > 0">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 form-group">
                <span class="form-label">Regi&oacute;n recibe equipo:</span>
                <div class="input-group">
                  <input type="text"
                         class="form-control"
                         [(ngModel)]="regionReceive"
                         [disabled]="orderId.length > 0" />
                  <div class="input-group-append">
                    <button class="btn btn-secondary" type="button" (click)="regionReceive=''" [disabled]="orderId.length > 0">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 form-group">
                <i class="fa fa-spinner fa-spin" *ngIf="lockOriginSearch && working" aria-hidden="true">&nbsp; </i>
                <i class="fa fa-lock" *ngIf="lockOriginSearch && !working" aria-hidden="true"
                   tooltip="Nodos relacionados"
                   data-placement="right"></i>

                <span class="form-label">Nodo Comprador:</span>
                <ng-autocomplete [initialValue]="initialPurchNodeName"
                                 [placeholder]="'Búsqueda'"
                                 [disabled]="orderId.length > 0"
                                 [data]="purchNodeData"
                                 [searchKeyword]="inputArgument"
                                 (selected)='selectPurchNodeEvent($event)'
                                 [itemTemplate]="itemPurchNodeTemplate"
                                 [notFoundTemplate]="notFoundPurchNodeTemplate"
                                 [debounceTime]="600"
                                 [isLoading]="isLoadingItemsPurchNode"
                                 [minQueryLength]="0"
                                 [(ngModel)]="selectedPurchUnitName"
                                 (inputCleared)="cmbPurchNodeChange()">
                </ng-autocomplete>
                <ng-template #itemPurchNodeTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>
                <ng-template #notFoundPurchNodeTemplate let-notFound>
                  <div [innerHTML]="'No se encontraron coincidencias'"></div>
                </ng-template>
              </div>


              <div class="col-lg-3 col-md-6 form-group">
                <i class="fa fa-spinner fa-spin" *ngIf="lockDestinySearch && working" aria-hidden="true">&nbsp; </i>
                <i class="fa fa-lock" *ngIf="lockDestinySearch && !working" aria-hidden="true"
                   tooltip="Nodos relacionados"
                   data-placement="right"></i>

                <span class="form-label">Nodo recibe equipo:</span>
                <ng-autocomplete [initialValue]="initialReceiNodeName"
                                 [placeholder]="'Búsqueda'"
                                 [disabled]="orderId.length > 0"
                                 [data]="receiNodeData"
                                 [searchKeyword]="inputArgument"
                                 (selected)='selectReceiNodeEvent($event)'
                                 [itemTemplate]="itemReceiNodeTemplate"
                                 [notFoundTemplate]="notFoundReceiNodeTemplate"
                                 [debounceTime]="600"
                                 [isLoading]="isLoadingItemsReceiNode"
                                 [minQueryLength]="0"
                                 [(ngModel)]="selectedReceiUnitName"
                                 (inputCleared)="cmbReceiNodeChange()">
                </ng-autocomplete>
                <ng-template #itemReceiNodeTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>
                <ng-template #notFoundReceiNodeTemplate let-notFound>
                  <div [innerHTML]="'No se encontraron coincidencias'"></div>
                </ng-template>
              </div>

              <div class="col-lg-3 col-md-6 form-group">
                <span class="form-label">Proveedor:</span>
                <ng-autocomplete [initialValue]="initialReceiNodeName"
                                 [placeholder]="'Búsqueda'"
                                 [disabled]="orderId.length > 0"
                                 [data]="providers"
                                 [searchKeyword]="'name'"
                                 (selected)='selectProviderEvent($event)'
                                 [itemTemplate]="itemProvider"
                                 [notFoundTemplate]="notFoundProvider"
                                 [debounceTime]="600"
                                 [isLoading]="isLoadingProviders"
                                 [minQueryLength]="3"
                                 [(ngModel)]="selectedProviderName"
                                 (inputCleared)="cmbProviderCleared()">
                </ng-autocomplete>
                <ng-template #itemProvider let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>
                <ng-template #notFoundProvider let-notFound>
                  <div [innerHTML]="'No se encontraron coincidencias'"></div>
                </ng-template>
              </div>

              <div class="col-lg-3 d-flex align-items-center justify-content-end">
                <button type="button" class="mr-1 btn btn-labele btn-outline-primary mb-2" style="padding: 1px 14px; font-size: 14px;" (click)="searchPlannign()">
                  <span class="btn-label">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </span>
                  <span>Buscar</span>
                </button>
                
              </div>

            </div>
            
            <br />
            <app-ceq-table [gridAdditionalOptions]="gridPlanningOptions"
                           [columnDef]="columnPlanningDefs"
                           (apiEmitter)="gridPlanningEmiter($event)"
                           #listPlanningGrid>
            </app-ceq-table>

          </div>

          <br />

          <div class="row">
            <div class="col-lg-10">
              <button type="button" class="mr-1 btn btn-labeled btn-outline-primary mb-2" *ngIf="!downloading" (click)="downloadExcel()">
                <span class="btn-label">
                  <i class="fa fa-download"></i>
                </span>
                <span>Descarga Consulta</span>
              </button>
              <button type="button" class="mr-1 btn btn-labeled btn-outline-primary mb-2" *ngIf="downloading" [disabled]="downloading">
                <span class="btn-label">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </span>
                <span>Descargando ...</span>
              </button>&nbsp;&nbsp;

              <button type="button" class="mr-1 btn btn-labeled btn-outline-pink mb-2" *ngIf="!downloading2" (click)="downloadPurchase()" [disabled]="!isAutPurhcase">
                <span class="btn-label">
                  <i class="fa fa-download"></i>
                </span>
                <span>Descarga plantilla compras</span>
              </button>
              <button type="button" class="mr-1 btn btn-labeled btn-outline-pink mb-2" *ngIf="downloading2" [disabled]="downloading">
                <span class="btn-label">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </span>
                <span>Descargando plantilla compras</span>
              </button>&nbsp;&nbsp;

              <button type="button" class="mr-1 btn btn-labeled btn-outline-info mb-2" *ngIf="!isDownloading" (click)="btnpurchaseupload.click()" [disabled]="!isAutPurhcase">
                <span class="btn-label">
                  <i class="fa fa-upload"></i>
                </span>
                <span>Carga plantilla compras</span>
              </button>
              <input type="file" accept=".xls,.xlsx" #btnpurchaseupload hidden (change)="uploadPurchase($event.target.files)" (click)="uploadClean($event)">
              <button type="button" class="mr-1 btn btn-labeled btn-outline-info mb-2" *ngIf="isDownloading" [disabled]="isDownloading">
                <span class="btn-label">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </span>
                <span>Cargando plantilla compras</span>
              </button>
            </div>
            <div class="col-lg-2">
              <button type="button" class="mr-1 btn btn-labeled btn-outline-success mb-2" *ngIf="!isDownloading" (click)="newPurchase()" [disabled]="!isAutPurhcase">
                <span class="btn-label">
                  <i class="fa fa-plus"></i>
                </span>
                <span>Nuevo</span>
              </button>
            </div>

          </div> <!-- End row bottons-->

        </tab> <!-- Fin Programa Compras de Equipo -->


        <!-- Capex -->
        <tab (selectTab)="changeTab(true)">


          <div class="card card-default card-body">
            <ng-template tabHeading>Capex</ng-template>

            <div class="row">

              <div class="col-md-4">
                <label class="form-label">Nodo</label>
                <ng-autocomplete [initialValue]="initialInputName"
                                 [placeholder]="'Búsqueda'"
                                 [disabled]=""
                                 [data]="outputData"
                                 [searchKeyword]="inputArgument"
                                 (selected)='selectOutputEvent($event)'
                                 [itemTemplate]="itemOutputTemplate"
                                 [notFoundTemplate]="notFoundoutputTemplate"
                                 [debounceTime]="600"
                                 [isLoading]="isLoadingItemsOutput"
                                 [minQueryLength]="3"
                                 [(ngModel)]="selectedUnitName"
                                 (inputCleared)="cmbOutputChange()">
                </ng-autocomplete>
                <ng-template #itemOutputTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>

                <ng-template #notFoundoutputTemplate let-notFound>
                  <div [innerHTML]="'No se encontraron coincidencias'"></div>
                </ng-template>
              </div>
              <div class="col-md-4">
                <label class="form-label">A&ntilde;o Capex:</label>
                <input type="text" class="form-control" [(ngModel)]="capexYear" />
              </div>
              <div class="col-md-4">
                <label class="form-label">Regi&oacute;n:</label>
                <!-- <input type="text" class="form-control" [(ngModel)]="unitDescOrganizatonCapex" /> -->
                <select class="form-control" [(ngModel)]="unitDescOrganizatonCapex" name="region">
                  <option value="" selected>Todos</option>
                  <option *ngFor="let creg of capexRegions" [value]="creg.RegionName">{{creg.RegionName}}</option>
                </select>

              </div>

            </div>

            <br />

            <div class="row">
              <div class="col-md-4">
                <label class="form-label">Tipo Porcentaje:</label>
                <select class="form-control w-70" [(ngModel)]="percentCapex" name="percentCapex" (ngModelChange)='cmbTypeChange($event)'>
                  <option value="" selected>Seleccione una opci&oacute;n</option>
                  <option value="MAA">Mayor a</option>
                  <option value="MEA">Menor a</option>
                  <option value="IGU">Igual a</option>
                </select>
              </div>

              <div class="col-md-4">
                <label class="form-label">Porcentaje Cantidad:</label>
                <input type="number" class="form-control" [(ngModel)]="quantityPercent" [disabled]="!percentCapex"/>
              </div>

            </div>

            <div class="row mt-4">
              <div class="col-md-4 col-lg-2 align-self-end">
                <label class="form-label">&#160;</label>
                <button type="button" class="mr-1 btn btn-labeled btn-outline-primary mb-2" (click)="capexSearch()">
                  <span class="btn-label">
                    <i class=" fa fa-search" aria-hidden="true"></i>
                  </span>
                  <span>Buscar</span>
                </button>
              </div>

            </div> <!-- End row -->

            <br />

            <app-ceq-table [gridAdditionalOptions]="gridCapexOptions"
                           [columnDef]="columnCapexDefs"
                           (apiEmitter)="gridCapexEmiter($event)"
                           #listCapexGrid>
            </app-ceq-table>

          </div> <!-- End card -->

          <br />

          <div class="row">
            <div class="col-lg-12">
              <button type="button" class="mr-1 btn btn-labeled btn-outline-primary mb-2" *ngIf="!isDownloading" (click)="downloadExcelCapex()">
                <span class="btn-label">
                  <i class="fa fa-download"></i>
                </span>
                <span>Descarga Consulta</span>
              </button>
              <button type="button" class="mr-1 btn btn-labeled btn-outline-primary mb-2" *ngIf="isDownloading" [disabled]="isDownloading">
                <span class="btn-label">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </span>
                <span>Descargando ...</span>
              </button>&nbsp;&nbsp;

              <button type="button" class="mr-1 btn btn-labeled btn-outline-pink mb-2" *ngIf="!isDownloading" (click)="downloadCapex()" [disabled]="!isAutPurhcase">
                <span class="btn-label">
                  <i class="fa fa-download"></i>
                </span>
                <span>Descarga plantilla Capex</span>
              </button>
              <button type="button" class="mr-1 btn btn-labeled btn-outline-pink mb-2" *ngIf="isDownloading" [disabled]="isDownloading">
                <span class="btn-label">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </span>
                <span>Descargando plantilla Capex</span>
              </button>&nbsp;&nbsp;

              <button class="mr-1 btn btn-labeled btn-outline-info mb-2" *ngIf="!isDownloading" (click)="btnFileUpload.click()" [disabled]="!isAutPurhcase">
                <span class="btn-label">
                  <i class="fa fa-upload"></i>
                </span>
                <span>Carga plantilla Capex</span>
              </button>
              <input type="file" accept=".xls,.xlsx" #btnFileUpload hidden (change)="onFileSelected($event.target.files)" (click)="uploadClean($event)">
              <button type="button" class="mr-1 btn btn-labeled btn-outline-info mb-2" *ngIf="isDownloading" [disabled]="isDownloading">
                <span class="btn-label">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </span>
                <span>Cargando plantilla Capex</span>
              </button>
            </div>
          </div> <!-- Fin row botones-->

        </tab> <!-- Fin de Capex -->

      </tabset>



    </div>
  </div>
</div>
<div class="content-heading">
  <div class="header-container">
    <h3>Inventario</h3>
    <small>Consulta de inventario</small>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <div class="row filter-container">
                <div class="col-md-5">
                  <span><h5><b>Nodo</b></h5></span>
                  <ng-autocomplete [initialValue]="initialInputName"
                                    [placeholder]="'Búsqueda'"
                                    [disabled]=""
                                    [data]="outputData"
                                    [searchKeyword]="inputArgument"
                                    (selected)='selectOutputEvent($event)'
                                    [itemTemplate]="itemOutputTemplate"
                                    [notFoundTemplate]="notFoundoutputTemplate"
                                    [debounceTime]="600"
                                    [isLoading]="isLoadingItemsOutput"
                                    [minQueryLength]="3"
                                    [(ngModel)]="selectedUnit"
                                    (inputCleared)="cmbOutputChange()">
                  </ng-autocomplete>
                  <ng-template #itemOutputTemplate let-item>
                    <a [innerHTML]="item.name"></a>
                  </ng-template>

                  <ng-template #notFoundoutputTemplate let-notFound>
                    <div [innerHTML]="'No se encontraron coincidencias'"></div>
                  </ng-template>
                  <BR>
                </div>
                <div class="col-md-3" style="text-align:center">
                  <span><h5><b>Fecha inicial</b></h5></span>
                  <div class="row" style="justify-content: center;">
                    <div class="col-x-1">
                      <button type="button" (click)="goDwn(1)" class="btn btn-secondary">
                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div class="col-x-1">
                      <input (change)="updateTable()" type="date" class="form-control btn-secondary" [ngModel]="minDate" (ngModelChange)="minDate=$event">
                    </div>
                    <div class="col-x-1">
                      <button type="button" (click)="goUp(1)" class="btn btn-secondary">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>                  </div>
                <div class="col-md-3" style="text-align:center">
                  <span><h5><b>Fecha final</b></h5></span>
                  <div class="row" style="justify-content: center;">
                    <div class="col-x-1">
                      <button type="button" (click)="goDwn(2)" class="btn btn-secondary">
                        <i class="fa fa-angle-left" aria-hidden="false"></i>
                      </button>
                    </div>
                    <div class="col-x-1">
                      <input (change)="updateTable()" type="date" aria-hiden="true" class="form-control btn-secondary" [ngModel]="maxDate" (ngModelChange)="maxDate=$event">
                    </div>
                    <div class="col-x-1">
                      <button type="button" (click)="goUp(2)" class="btn btn-secondary">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-1" style="justify-content:right;">
                  <h5><b>&nbsp;</b></h5>
                  <button type="button" class="btn btn-outline-primary" (click)="updateTable()">
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <app-ceq-table #CeqGrid
                [gridAdditionalOptions]="gridOptions"
                [columnDef]="columnDefs"
                [RowsToDisplay]="16"
                (apiEmitter)="gridEmiter($event)">
              </app-ceq-table>
            </div>
          </div>
          <div class="row mt-3 col-lg-12 col-md-12">
            <div class="pagesize-control">
              Tama&ntilde;o de p&aacute;gina:
              <select (change)="onPageSizeChange($event.target.value)">
                <option *ngFor="let size of PageSizes" [ngValue]="size">{{size}}</option>
              </select>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>


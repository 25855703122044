import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ZipCodeModel } from '../../common/models/ZipCode.Model';
import { GenericService } from '../../common/services/GenericServices';

@Injectable({
  providedIn: 'root'
})
export class ZipcodeService {

  constructor(private genericService: GenericService) { }

  controllerUrl: string = "zipcode";

  getZipCodeList(): Observable<ZipCodeModel[]> {
    let url = this.controllerUrl;
    return this.genericService.getRequest(url, {}, 0);
  }

  getZipCodeById(org: number, zipcode: String): Observable<ZipCodeModel> {
    let url = `${this.controllerUrl}/${zipcode}`;
    return this.genericService.getRequest(url, {}, org);
  }

  createZipCode(zipcode: ZipCodeModel): Observable<ZipCodeModel> {
    let url = this.controllerUrl;
    return this.genericService.postRequest(url, {}, 0, zipcode);
  }

  updateZipCode(zipcode: ZipCodeModel): Observable<ZipCodeModel> {
    let url = this.controllerUrl;
    return this.genericService.putRequest(url, {}, 0, zipcode);
  }


  deleteZipCode(org: number, zipcode: string): Observable<any> {
    let url = this.controllerUrl;
    return this.genericService.deleteRequest(url, {}, 0);
  }

  importZipCodes(fileToUpload: any): Observable<boolean> {
    let url = `${this.controllerUrl}/import`;
    return this.genericService.postRequest(url, {}, 0, fileToUpload);
  }

}

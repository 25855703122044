import { Component, OnInit } from '@angular/core';
import { CurrencyService } from '../currency.service';
import { CurrencyModel } from '../../../common/models/Currency.Model';
import { SettingsService } from '../../../../core/settings/settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { toNumber } from 'lodash';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-currency-detail',
  templateUrl: './currency-detail.component.html',
  styleUrls: ['./currency-detail.component.scss']
})
export class CurrencyDetailComponent implements OnInit {

  org: number = 0;
  currency: CurrencyModel = new CurrencyModel();
  isNew: boolean = false;

  constructor(private currencyService: CurrencyService, private settings: SettingsService, private url: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.org = this.settings.getUser().Org;

    let currentId = this.url.snapshot.paramMap.get('id');

    if (this.isNullOrWhitespace(currentId) || currentId === 'create') {
      this.currency.currencyId = 0;
      this.isNew = true;
    }
    else {
      this.currency.currencyId = toNumber(currentId);
      this.getCurrency();
      this.isNew = false;
    }
  }

  formSubmit(value: any) {
    if (this.currency.currencyId == 0) {
      //Create
      this.currencyService.createCurrency(this.currency).subscribe(data => {
        Swal.fire('¡Éxito!', 'Moneda creada', 'success');
        this.btnCancelOnClick();
      }, (error) => {
        Swal.fire('¡Error!', error.error, 'error');
      });
    }
    else {
      //Update
      this.currencyService.updateCurrency(this.org, this.currency).subscribe(data => {
        Swal.fire('¡Éxito!', 'Moneda actualizada', 'success');
        this.btnCancelOnClick();
      }, (error) => {
        Swal.fire('¡Error!', error.error, 'error');
      });
    }
  }

  btnCancelOnClick() {
    this.router.navigate(['/tax/currency']);
  }

  getCurrency() {
    this.currencyService.getCurrencyById(this.currency.currencyId).subscribe(data => {
      this.currency = data;
    }, (error) => {
      Swal.fire('¡Error!', "No se encontró la moneda solicitada", 'error');
      this.btnCancelOnClick();
    });
  }

  isNullOrWhitespace(input) {
    if (typeof input === 'undefined' || input == null) return true;
    return input.replace(/\s/g, '').length < 1;
  }
}

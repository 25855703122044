import { Injectable } from '@angular/core';
import { GenericService } from '../common/services/GenericServices';
import { Observable } from 'rxjs';
import { TrackerEmployeeOP } from '../common/models/TrackerEmployeeOP';

@Injectable({
  providedIn: 'root'
})
export class TrackerService {

  constructor(private genericService: GenericService) { }

  getTrackerDays(unitId: number): Observable<any> {
    return this.genericService.getRequest(`tracker/unit/${unitId}`, {});
  }

  getTrackerEmployee(unitId: number, day: String): Observable<any> {
    return this.genericService.getRequest(`tracker/${unitId}/${day}`, {});
  }

  getTrackerPoints(unitId: number, day: String, employee: string): Observable<any> {
    return this.genericService.getRequest(`tracker/${unitId}/${day}/${employee}`, {});
  }

  getTrackedCustomers(day: String, employee: string):Observable<TrackerEmployeeOP[]>{
    return this.genericService.getRequest(`tracker/operation/${day}/${employee}`, {});
  }
}

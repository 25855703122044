import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'print-cell',
  template: `<span><i class="fa fa-print" (click)="invokeParentMethod();" style="width:100%;"></i></span>`,
  styles: [
      `.btn {
      line-height: 0.5
    }`
  ]
})

export class PrintChildRendered implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  public invokeParentMethod() {
    this.params.context.print(this.params);
  }

  refresh(): boolean {
    return false;
  }
}

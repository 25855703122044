import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';

import { LoginComponent } from "./login/login.component";
import { PagesService } from './pages.service';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { NgxBarcode6Module } from 'ngx-barcode6';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


@NgModule({
  imports: [
    SharedModule,
    NgxBarcode6Module,

    MsalModule.forRoot({
      auth: {
        //clientId: 'db262e6b-eb28-45eb-b4d1-d3903a2c403f', // PROD
        //clientId: '4581ed75-d273-41f6-81b7-6cf9844c695e', // QA
        clientId: 'da7e2345-a0cd-43c7-b7ca-dc1180040667', // DEV
        authority: 'https://login.microsoftonline.com/973ba820-4a58-4246-84bf-170e50b3152a',
        //redirectUri: 'https://ceq500.bimboconnect.com/security/login/signin-oidc'
        //redirectUri: 'https://ceq300.bimboconnect.com/security/login/signin-oidc'
        redirectUri: 'https://ceq200.bimboconnect.com/security/login/signin-oidc'
        //redirectUri: 'http://localhost:5000/security/login/signin-oidc'
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
    },
      {
        popUp: !isIE,
        consentScopes: [
          'user.read',
          'openid',
          'profile',
        ],
        protectedResourceMap: [
          ['https://graph.microsoft.com/beta/me', ['user.read']]
        ],
        extraQueryParameters: {}
      })

  ],
  declarations: [
    LoginComponent
  ],
  exports: [
    LoginComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    PagesService
  ]
})
export class PagesModule { }

<div class="wrapper">
  <div class="block-center mt-4 wd-xl">
    <!-- START card-->
    <div class="card card-flat">
      <div class="card-header text-center ">
        <img class="block-center img-fluid" src="assets/img/logo.png" alt="Image" />
      </div>
      <div class="center-data card-body">
        <h4 class="text-center">INICIO DE SESI&Oacute;N</h4>
      </div><br />
      <div class="center-data">

        <!--
    <form [formGroup]="valForm" class="form-validate mb-3" role="form" name="loginForm" novalidate="" (submit)="submitForm($event, valForm.value)">

      <div class="form-group">
        <div class="input-group with-focus">
          <input class="form-control border-right-0" type="text" placeholder="nombre@mail.com" autocomplete="on" formControlName="login" required="required" />
          <div class="input-group-append">
            <span class="input-group-text text-muted bg-transparent border-left-0">
              <em class="fa fa-envelope"></em>
            </span>
          </div>
        </div>
        <div class="text-danger" *ngIf="valForm.controls['login'].hasError('required') && (valForm.controls['login'].dirty || valForm.controls['login'].touched)">Campo requerido</div>
        <div class="text-danger" *ngIf="valForm.controls['login'].hasError('email') && (valForm.controls['login'].dirty || valForm.controls['login'].touched)">Deve ingresar un correo electr&oacute;nico valido</div>
      </div>
      <div class="form-group">
        <div class="input-group with-focus">
          <input class="form-control border-right-0" type="password" placeholder="Contrase&ntilde;a" formControlName="password" required="required" />
          <div class="input-group-append">
            <span class="input-group-text text-muted bg-transparent border-left-0">
              <em class="fa fa-lock"></em>
            </span>
          </div>
        </div>
        <div class="text-danger" *ngIf="valForm.controls['password'].hasError('required') && (valForm.controls['password'].dirty || valForm.controls['password'].touched)">Campo requerido</div>
      </div>
      <div class="clearfix">
         <div class="checkbox c-checkbox float-left mt0">
          <label>
            <input type="checkbox" value="" name="account_remember" />
            <span class="fa fa-check"></span>Recuerdame
          </label>
        </div>
         <div class="float-right">
            <a class="text-muted" [routerLink]="'/recover'">Olvido su contrase&ntilde;a?</a>
        </div>
      </div>
      -->
        <!--
      <button type="submit" class="btn btn-block btn-primary mt-3" [disabled]="!valForm.valid || isBusy">
        <i class="fa fa-circle-o-notch fa-spin" *ngIf="isBusy"></i>&nbsp;
        <span *ngIf="!isBusy">Iniciar sesi&oacute;n</span>
        <span *ngIf="isBusy">Iniciando</span>
      </button>

    </form>
      -->
        <button type="button" class="btn btn-outline-green" *ngIf="!loggedIn" (click)="login()">
          <i class="fa fa-lock-open"></i>&nbsp;Ingresar
        </button>
        <button type="button" class="btn btn-outline-danger" *ngIf="loggedIn" (click)="logout()">
          <i class="fa fa-lock"></i>&nbsp;Cerrar sesi&oacute;n
        </button>
        <!-- <div class="alert alert-danger text-center" ></div> -->
        <!-- <p class="pt-3 text-center">Need to Signup?</p>
    <a class="btn btn-block btn-secondary" [routerLink]="'/register'">Register Now</a> -->
      </div>
      <div class="p-3 text-center">
        <span>&copy;</span>
        <span>{{ settings.getAppSetting('year') }}</span>
        <span class="mx-2">-</span>
        <span>{{ settings.getAppSetting('name') }}</span>
        <br />
        <span>{{ settings.getAppSetting('description') }}</span>
      </div>
    </div>
    <!-- END card-->
  </div>
</div>

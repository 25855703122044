import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { CeqTableComponent } from '../../common/ceq-table/ceq-table.component';
import { DeleteChildRendered } from '../../common/renders/delete-child-rendered';
import { EditChildRendered } from '../../common/renders/edit-child-rendered';

import { GlobalFunctionsService } from '../../common/functions/global-functions.service';
import { SettingsService } from '../../../core/settings/settings.service';

import { Unit } from '../../common/models/Unit.Model';
import { Container } from '../../common/models/Container.Model';
import { InventoryService } from '../inventory.service';
import { BalanceDetail } from '../../common/models/BalanceDetail.Model';
import { Balance } from '../../common/models/Balance.Model';

interface ContainerBalance {
  containerId: number;
  total: number;
}

interface dataRow {
  moveId: string;
  date: Date;
  reference: string;
  moveType: string;
  items: number[];
}

interface LevelDetail {
  name: string;
  unitId: number
}

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss']
})
export class BalanceComponent implements OnInit {

  constructor(private router: Router,
    private inventoryService: InventoryService,
    private settings: SettingsService,
    private globalFunctionService: GlobalFunctionsService
  ) { }

  loading: boolean = false;
  levelList: Unit[];
  levelListB: Unit[];
  selectedLevelA: Unit;
  selectedLevelB: Unit;
  containerList: Container[];
  initialData: any[] = [];
  org: number;
  balance: Balance[];

  level: number = 0;
  levelB: number = 0;
  TCA: number = 0;
  GT: number = 0;
  TAA: number = 0;
  TCB: number = 0;
  TAB: number = 0;
  PosBalance: Balance[];
  PosNode: number = 0;
  datepipe: DatePipe = new DatePipe('en-US')

  containersSet: ContainerBalance[] = [];
  containersSetB: ContainerBalance[] = [];

  dataList: dataRow[] = [];
  dataListB: dataRow[] = [];

  nodesA: LevelDetail[];
  nodesB: LevelDetail[];
  nameNode: string = 'name';
  searchingDetail: boolean = false;

  container: number;
  orgunit: number;

  options: GridOptions;
  optionsB: GridOptions;

  actualDate = new Date();
  balInitialDate: string;
  balFinalDate: string;

  movInitialDate: string;
  movFinalDate: string;

  initialDateTime: string;
  finalDateTime: string;

  loadingRange: boolean = false;
  rangeBalance: Balance[] = [];
  ResetBalanceDate: string = undefined;
  downloadingExcel: boolean = false;

  gridOptions = {
    context: this,
    frameworkComponents: {
      childEditRenderer: EditChildRendered,
      childDeleteMessageRenderer: DeleteChildRendered
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) { },
  }


  gridOptionsB = {
    context: this,
    frameworkComponents: {
      childEditRenderer: EditChildRendered,
      childDeleteMessageRenderer: DeleteChildRendered
    },

    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) { }


  }


  @ViewChild('CeqGrid', { static: false }) ceqGrid: CeqTableComponent;
  @ViewChild('CeqGridB', { static: false }) ceqGridB: CeqTableComponent;

  columnDefsNodoA = [
    {
      headerName: 'Fecha',
      field: 'DateMove',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      cellRenderer: (params: any) => moment(params.data.DateMove).format('YYYY-MM-DD'),
      cellStyle: function (params) {
        if (params.data.UnitIdB == params.context.levelB) {
          return { color: '#FFFFFF', backgroundColor: '#6CAA82' };
        }
        return null;
      }
    }, {
      headerName: 'Referencia',
      field: 'Reference',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      cellStyle: function (params) {
        if (params.data.UnitIdB == params.context.levelB) {
          return { color: '#FFFFFF', backgroundColor: '#6CAA82' };
        }
        return null;
      }
    }, {
      headerName: 'Cargo',
      field: 'Quantity',
      width: 20,
      cellClass: 'align-right',
      editable: false,
      cellRenderer: (params: any) => params.data.Quantity > 0 ? params.data.Quantity : "",
      cellStyle: function (params) {
        if (params.data.UnitIdB == params.context.levelB) {
          return { color: '#FFFFFF', backgroundColor: '#6CAA82' };
        }
        return null;
      }
    }, {
      headerName: 'Abono',
      field: 'Quantity',
      width: 20,
      cellClass: 'align-right',
      editable: false,
      cellRenderer: (params: any) => params.data.Quantity < 0 ? params.data.Quantity : "",
      cellStyle: function (params) {
        if (params.data.UnitIdB == params.context.levelB) {
          return { color: '#FFFFFF', backgroundColor: '#6CAA82' };
        }
        return null;
      }
    }
  ];

  columnDefsNodoB = [
    {
      headerName: 'Fecha',
      field: 'DateMove',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      cellRenderer: (params: any) => moment(params.data.DateMove).format('YYYY-MM-DD'),
      cellStyle: function (params) {
        if (params.data.UnitIdB == params.context.level) {
          return { color: '#FFFFFF', backgroundColor: '#6CAA82' };
        }
        return null;
      }
    }, {
      headerName: 'Referencia',
      field: 'Reference',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      cellStyle: function (params) {
        if (params.data.UnitIdB == params.context.level) {
          return { color: '#FFFFFF', backgroundColor: '#6CAA82' };
        }
        return null;
      }
    }, {
      headerName: 'Cargo',
      field: 'Quantity',
      width: 20,
      cellClass: 'align-right',
      editable: false,
      cellRenderer: (params: any) => params.data.Quantity > 0 ? params.data.Quantity : "",
      cellStyle: function (params) {
        if (params.data.UnitIdB == params.context.level) {
          return { color: '#FFFFFF', backgroundColor: '#6CAA82' };
        }
        return null;
      }
    }, {
      headerName: 'Abono ',
      field: 'Quantity',
      width: 20,
      cellClass: 'align-right',
      editable: false,
      cellRenderer: (params: any) => params.data.Quantity < 0 ? params.data.Quantity : "",
      cellStyle: function (params) {
        if (params.data.UnitIdB == params.context.level) {
          return { color: '#FFFFFF', backgroundColor: '#6CAA82' };
        }
        return null;
      }
    }
  ];

  ngOnInit(): void {
    this.globalFunctionService.hasFSBRPending();
    this.globalFunctionService.canOperate();
    
    this.level = 0;
    this.levelB = 0;

    this.org = this.settings.getUser().Org;
    this.getLevelList();
    this.getLevelListB();

    //filters default value
    this.balInitialDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd 00:00:00');
    this.balFinalDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd 23:59:59');
    this.movInitialDate = this.datepipe.transform(new Date().setHours(-144), 'yyyy-MM-dd 00:00:00');
    this.movFinalDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd 23:59:59');


    this.initialDateTime = this.datepipe.transform(new Date(), 'yyyy-MM-dd 00:00');
    this.finalDateTime = this.datepipe.transform(new Date(), 'yyyy-MM-dd 23:59:59');
  }


  onPageSizeChange(value: number) {

  }

  gridEmiter(GridOptions: GridOptions) {
    this.options = GridOptions;
    this.ceqGrid.endPoint = "balancedetail?$filter=Org eq " + 0;
    this.ceqGrid.refreshTable();
  }

  gridEmiterB(GridOptions: GridOptions) {
    this.optionsB = GridOptions;
    this.ceqGridB.endPoint = "balancedetail?$filter=Org eq " + 0;
    this.ceqGridB.refreshTable();
  }

  getLevelList(): void {
    this.inventoryService.getLevelList(this.org).subscribe((resp: Unit[]) => {
      this.levelList = resp;
      if (resp !== null) {
        this.nodesA = resp.map(unit => {
          return {
            name: `${unit.UnitCode} - ${unit.UnitDescription}`,
            unitId: unit.UnitId
          }
        });
      }
      const orgAll: Unit = new Unit();
      if (this.levelList !== null) {
        this.levelList.unshift(orgAll);
      }
    }, err => {
      Swal.fire({
        icon: 'warning',
        text: 'Error al obtener lista de niveles, verifique su conexión a internet',
      });
    });
  }

  getContainerList(filter: string): void {
    this.inventoryService.getConteinerList(filter).subscribe((resp: Container[]) => {
      this.containerList = resp;
      const containerAll: Container = new Container();
      if (this.containerList !== null) {
        this.containerList.unshift(containerAll);
      }
      this.getBalanceBetweenNodes();
      this.getRangeBalance();
    }, err => {
      Swal.fire({
        icon: 'warning',
        text: 'Error al obtener lista de contenedores, verifique su conexión a internet',
      });
    });
  }

  changeFilterLevel(event: any): void {
    this.level = event.unitId;
    this.selectedLevelA = this.levelList.find(unt => unt.UnitId === Number(event.unitId));
    this.getContainersByFactory();
    this.getBalanceResetDate();
  }

  changeFilterLevelB(event: any): void {
    this.levelB = event.unitId;
    this.selectedLevelB = this.levelListB.find(unt => unt.UnitId === Number(event.unitId));
    this.getContainersByFactory();
    this.getBalanceResetDate();
  }

  getContainersByFactory() {
    this.containerList = [];
    this.balance = [];
    if (this.selectedLevelA && this.selectedLevelB) {
      this.inventoryService.getContainersByFactory(this.level).subscribe(resp => {
        if (resp) {
          if (resp.length === 0) {
            this.inventoryService.getContainersByCeve(this.level).subscribe(resp => {
              this.getContainers(resp);
            });
          } else {
            this.getContainers(resp);
          }
        } else {
          this.inventoryService.getContainersByCeve(this.level).subscribe(resp => {
            this.getContainers(resp);
          });
        }
      });
    }
  }

  getContainers(containersByNode: any): void {
    const containers: number[] = containersByNode.map(cnt => cnt.ContainerId);
    if (containers.length === 0) {
      this.globalFunctionService.messageToast(`El nodo A seleccionado no cuenta con equipo asignado.`, 'warning');
      return;
    }
    let filter = '';
    containers.map(id => {
      filter += `${id}, `;
    });
    this.getContainerList(filter.substring(0, filter.length - 2));
  }

  changeFilterContainer(event: any): void {
    this.container = event;
  }

  clearNodeA(): void {
    this.dataList = [];
    this.dataListB = [];
    this.level = undefined;
    this.selectedLevelA = undefined;
  }

  clearNodeB(): void {
    this.dataList = [];
    this.dataListB = [];
    this.levelB = undefined;
    this.selectedLevelB = undefined;
  }

  getBalanceBetweenNodes(): void {
    if (this.selectedLevelA && this.selectedLevelB) {
      this.containersSet = [];
      this.dataList = [];
      this.loading = true;
      this.inventoryService.getBalanceByNodes(this.org, this.selectedLevelA.UnitId, this.selectedLevelB.UnitId).subscribe((balance: Balance[]) => {
        const containersId = this.containerList.map(cnt => cnt.ContainerId);
        this.balance = balance.filter(bln => containersId.includes(bln.ConteinerId));
        this.loading = false;
      }, err => {
        this.loading = false;
        console.log(err)
      });
    }
  }

  balInitialDateChange() {
    this.getRangeBalance();
  }

  balFinalDateChange() {
    this.getRangeBalance();
  }

  movInitialDateChange() {
    this.btnClick();
  }

  movFinalDateChange() {
    this.btnClick();
  }

  refreshBalance(){
    this.getBalanceBetweenNodes();
    this.getRangeBalance();
  }

  getRangeBalance(): void {
    this.rangeBalance = [];
    if (!this.balInitialDate || !this.balFinalDate) {
      this.globalFunctionService.messageToast('La fecha inicial y fecha final no pueden estar vacíos. ', 'error');
      return;
    }

    if (this.selectedLevelA && this.selectedLevelB && this.balInitialDate && this.balFinalDate) {

      let startdate = `${new Date(this.balInitialDate).getHours()}:${new Date(this.balInitialDate).getMinutes()}`;
      let enddate = `${new Date(this.balFinalDate).getHours()}:${new Date(this.balFinalDate).getMinutes()}`;
      if (new Date(this.balInitialDate) > new Date(this.balFinalDate)) {
        this.globalFunctionService.messageToast('La fecha y hora inicial no pueden ser mayores a la fecha y hora final', 'error');
        return;
      }

      this.loadingRange = true;

      this.inventoryService.getRangeBalance(this.selectedLevelA.UnitId, this.selectedLevelB.UnitId, this.balInitialDate, this.balFinalDate, startdate, enddate).subscribe((balance: Balance[]) => {
        const containersId = this.containerList.map(cnt => cnt.ContainerId);
        this.rangeBalance = balance.filter(bln => containersId.includes(bln.ConteinerId)).sort((x, y) => x.ConteinerId - y.ConteinerId);
        this.loadingRange = false;
      }, err => {
        this.loadingRange = false;
        console.log(err)
      });
    }
  }

  getContainerCode(containerId: number): string {
    const container = this.containerList.find(cnt => cnt.ContainerId === containerId);
    if (container) {
      return container.ContainerCode;
    }
    return 'Inactivo';
  }

  getLevelListB(): void {
    this.inventoryService.getAllFactoryCeve().subscribe((resp: Unit[]) => {
      this.levelListB = resp;
      if (resp !== null) {
        this.nodesB = resp.map(unit => {
          return {
            name: `${unit.UnitCode} - ${unit.UnitDescription}`,
            unitId: unit.UnitId
          }
        });
      }
      const orgAll: Unit = new Unit();
      if (this.levelListB !== null) {
        this.levelListB.unshift(orgAll);
      }
    }, err => {
      Swal.fire({
        icon: 'warning',
        text: 'Error al obtener lista de niveles, verifique su conexión a internet',
      });
    });
  }


  btnClick(): void {
    if (this.level == null) {
      Swal.fire({
        icon: 'warning',
        text: 'Error es necesario seleccionar un nodo A',
      });
      return

    }

    if (this.levelB == null) {
      Swal.fire({
        icon: 'warning',
        text: 'Error es necesario seleccionar un nodo B',
      });
      return
    }

    if (this.level == this.levelB) {
      Swal.fire({
        icon: 'warning',
        text: 'El nodo a y el b no puden ser el mismo',
      });
      return
    }

    if (this.movInitialDate == null) {
      Swal.fire({
        icon: 'warning',
        text: 'Seleccione una fecha inicial',
      });
      return
    }


    if (this.movFinalDate == null) {
      Swal.fire({
        icon: 'warning',
        text: 'Seleccione una fecha final',
      });
      return
    }


    this.loadData();

  }

  async downloadExcel() {

    if (!this.movInitialDate || !this.movFinalDate) {
      this.globalFunctionService.messageToast('La fecha inicial y fecha final no pueden estar vacíos. ', 'error');
      return;
    }

    let startDate = new Date(this.movInitialDate);
    let finalDate = new Date(this.movFinalDate);

    if (startDate > finalDate) {
      this.globalFunctionService.messageToast('La fecha y hora inicial no pueden ser mayores a la fecha y hora final', 'warning');
      return;
    }

    this.downloadingExcel = true;

    if (new Date(new Date(startDate).setDate(startDate.getDate() + 62)) < finalDate) {
      //se excedio el limite de dias 
      this.globalFunctionService.messageToast('Se excedió el limite de 62 días permitido en la descarga del reporte.', 'warning', 10000);
      //la siguiente linea sirve para que la descara del reporte sea de las fechas permitidas 
      // finalDate = new Date(new Date(startDate).setDate(startDate.getDate() + 62));
      this.downloadingExcel = false;
      return;
    }



    let startDateString = this.datepipe.transform(startDate, 'yyyy-MM-dd HH:mm:ss');
    let finalDateString = this.datepipe.transform(finalDate, 'yyyy-MM-dd HH:mm:ss');

    await this.inventoryService.getBalanceDetailReport(this.level, this.levelB, startDateString, finalDateString)
      .toPromise()
      .then(resp => {
        let blob = new Blob([resp], { type: resp.type });
        const anchor = document.createElement('a');
        anchor.download = `Movimientos_${this.selectedLevelA.UnitDescription}_${this.selectedLevelB.UnitDescription}.xlsx`;
        anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
        anchor.click();
        this.downloadingExcel = false;
      })
      .catch(err => {
        this.downloadingExcel = false;
      })
  }

  initialTimeChange() {
    console.log(this.balInitialDate);
  }

  finalTimeChange(event) {
    console.log(event);
    console.log(this.balFinalDate);
  }

  movInitialTimeChange(event) {
    console.log(event);
    console.log(this.movInitialDate);

  }

  async getBalanceResetDate() {
    if (!this.selectedLevelA || !this.selectedLevelB)
      return;

    await this.inventoryService.getResetBalanceDate(this.selectedLevelA.UnitId, this.selectedLevelB.UnitId)
      .toPromise()
      .then(resp => {
        this.ResetBalanceDate = resp.Value;
      })
      .catch(err => {
        this.ResetBalanceDate = undefined;
        console.error(err);
      });
  }

  loadData(): void {
    this.dataList = [];
    this.dataListB = [];
    if (!this.movInitialDate || !this.movFinalDate) {
      this.globalFunctionService.messageToast('La fecha inicial y fecha final no pueden estar vacíos. ', 'error');
      return;
    }

    let startDate = new Date(this.movInitialDate);
    let finalDate = new Date(this.movFinalDate);
    if (startDate > finalDate) {
      this.globalFunctionService.messageToast('La fecha y hora inicial no pueden ser mayores a la fecha y hora final', 'error');
      return;
    }

    this.searchingDetail = true;
    let startDateString = this.datepipe.transform(this.movInitialDate, 'yyyy-MM-dd HH:mm:ss');
    let finalDateString = this.datepipe.transform(this.movFinalDate, 'yyyy-MM-dd HH:mm:ss');

    this.inventoryService.getBalanceDetailByNodes(this.org, this.level, this.levelB, startDateString, finalDateString).subscribe((data: BalanceDetail[]) => {
      this.containersSet = [];
      this.dataList = [];
      this.containersSet = [... new Set(this.balance.map(bln => {
        return {
          containerId: bln.ConteinerId,
          total: 0
        };
      }))];
      if (data) {
        data.map(bln => {
          let container = this.containersSet.find(cnt => cnt.containerId === bln.ConteinerId)
          if (!container) return;
          container.total += bln.Quantity;
          let item: dataRow = {
            moveId: bln.MovementId,
            date: bln.DateMove,
            reference: bln.Reference,
            moveType: bln.MoveType,
            items: []
          };

          const exists = this.dataList.find(data => data.moveId === item.moveId);
          let index = 0;
          item.items = this.containersSet.map(cnt => {
            if (exists && cnt.containerId === bln.ConteinerId) {

              if (exists.items[index] > 0) {
                exists.items[index] += bln.Quantity;
              }
              else {
                exists.items[index] = bln.Quantity;
              }
            }
            index++;
            return cnt.containerId === bln.ConteinerId ? bln.Quantity : 0;
          });

          if (!exists) {
            this.dataList.push(item);
          }
        });
      }
      this.searchingDetail = false;
    }, err => {
      this.searchingDetail = false;
    });


    this.inventoryService.getBalanceDetailByNodes(this.org, this.levelB, this.level, startDateString, finalDateString).subscribe((data: BalanceDetail[]) => {
      this.containersSetB = [];
      this.dataListB = [];
      this.containersSetB = [... new Set(this.balance.map(bln => {
        return {
          containerId: bln.ConteinerId,
          total: 0
        };
      }))];
      if (data) {
        data.map(bln => {
          let container = this.containersSetB.find(cnt => cnt.containerId === bln.ConteinerId)
          if (!container) return;
          container.total += bln.Quantity;
          let item: dataRow = {
            moveId: bln.MovementId,
            date: bln.DateMove,
            reference: bln.Reference,
            moveType: bln.MoveType,
            items: []
          };
          const exists = this.dataListB.find(data => data.moveId === item.moveId);
          let index = 0;
          item.items = this.containersSetB.map(cnt => {
            if (exists && cnt.containerId === bln.ConteinerId) {
              if (exists.items[index] > 0) {
                exists.items[index] += bln.Quantity;
              }
              else {
                exists.items[index] = bln.Quantity;
              }
            }
            index++;
            return cnt.containerId === bln.ConteinerId ? bln.Quantity : 0;
          });

          if (!exists) {
            this.dataListB.push(item);
          }
        });
      }
      this.searchingDetail = false;
    }, err => {
      this.searchingDetail = false;
    });
  }
}

<div class="content-heading">
  <div class="header-container">
    <h3>Monedas</h3>
    <small>Cat&aacute;logo de monedas</small>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <div>
                <app-ceq-table #CeqGrid [gridAdditionalOptions]="gridOptions" [columnDef]="columnDefs" (apiEmitter)="gridEmiter($event)">
                </app-ceq-table>
              </div>
            </div>
          </div>
          <div class="row mt-3 col-lg-12 col-md-12">
            <div class="pagesize-control">
              Tama&ntilde;o de p&aacute;gina:
              <select (change)="onPageSizeChange($event.target.value)">
                <option *ngFor="let size of PageSizes" [ngValue]="size">{{size}}</option>
              </select>
            </div>

          </div>
          <div class="row mt-3 col-lg-12 col-md-12">
            <button type="button" class="mr-1 btn btn-labeled btn-outline-primary mb-2" (click)="btnAddClick()">
              <span class="btn-label">
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
              </span>
              <span>Nueva moneda</span>
            </button>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>

<div class="content-heading">
    <div class="header-Container">
        <h3>Devoluci&oacute;n de equipo</h3>
        <small>Devoluci&oacute;n en Rutas Multicanal</small>
    </div>
</div>


<div class="container-fluid">
    <div class="card card-default">
        <div class="card-body">
            <!-- inicio filtros -->
            <div class="row">
                <div class="form-group col-4">
                    <label for="" class="form-label">Fecha</label>
                    <input type="date" class="form-control">
                </div>

                <div class="form-group col-4">
                    <label for="" class="form-label">Organizacion</label>
                    <input type="text" class="form-control">
                </div>

                <div class="form-group col-4">
                    <label for="" class="form-label">Centro de ventas</label>
                    <input type="text" class="form-control">
                </div>

                <div class="form-group col-4">
                    <label for="" class="form-label">Canal</label>
                    <input type="text" class="form-control">
                </div>

                <div class="form-group col-4">
                    <label for="" class="form-label">Ruta</label>
                    <input type="text" class="form-control">
                </div>

            </div>
            <!-- fin filtros -->

            <hr> <!-- separador -->

            <!-- inicio listas de rutas -->
            <div class="row">
                <!-- inicio rutas sincronizadas -->
                <div class="col-3 border-right">
                    <h3 class="text-center">Rutas Sincronizadas</h3>
                    <div style="max-height: 500px; overflow: auto;">
                        <table class="table">
                            <thead class="table-head-sticky background-nav-color">
                                <tr>
                                    <th scope="col">
                                        <h4 class="m-0">Ruta</h4>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let r of RoutesSync" class="" (click)="SyncRouteClick(r.routeId)">
                                    <td class="hover-item-list">
                                        <h4 class="">{{r.routeCode}}</h4>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- fin rutas sincronizadas -->

                <!-- inicio rutas recibidas  -->
                <div class="col-9">
                    <h3 class="text-center">Rutas Recibidas</h3>


                    <div style=" max-height: 500px;width: 100%; overflow: auto;">
                        <table class="table table-striped m-auto" style="width: max-content;">
                            <thead class="table-head-sticky background-nav-color">
                                <tr class="">
                                    <th class=" table-cell">
                                        <h4 class="m-0 text-center">Total</h4>
                                    </th>
                                    <th class=" table-cell">
                                        <h4 class="m-0 text-center">0</h4>
                                    </th>
                                    <th class=" table-cell">
                                        <h4 class="m-0 text-center">0</h4>
                                    </th>
                                    <th class=" table-cell">
                                        <h4 class="m-0 text-center">0</h4>
                                    </th>
                                
                                </tr>
                                <tr class="">
                                    <th class=" table-cell">
                                        <h4 class="m-0 text-center">Ruta</h4>
                                    </th>
                                    <th class=" table-cell">
                                        <h4 class="m-0 text-center">MTA</h4>
                                    </th>
                                    <th class=" table-cell">
                                        <h4 class="m-0 text-center">MTB</h4>
                                    </th>
                                    <th class=" table-cell">
                                        <h4 class="m-0 text-center">TIR</h4>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let r of RoutesSync">
                                    <th class=" table-cell">
                                        <h4 class="m-0 text-center">{{r.routeCode}}</h4>
                                    </th>
                                    <td class=" table-cell">
                                        <h4 class="m-0 text-center">0</h4>
                                    </td>
                                    <td class=" table-cell">
                                        <h4 class="m-0 text-center">0</h4>
                                    </td>
                                    <td class=" table-cell">
                                        <h4 class="m-0 text-center">0</h4>
                                    </td>
                                    
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>
                <!-- fin rutas recibidas -->
            </div>
            <!-- fin listas de rutas -->
        </div>
    </div>
</div>
<div class="content-heading">
    <div class="header-container">
        <h3>Reporte de Compulsas</h3>
        <small>Reporte Web</small>
    </div>
</div>
<div class="container-fluid">
    <div class="card card-default">
        <div class="card-body">
            <div class="row">
                <div class="col-lg-4">
                    <div class="form-group">
                        <label class="form-label">Nodo</label>
                        <ng-autocomplete [placeholder]="'Búsqueda'"
                                         [data]="unitList"
                                         [searchKeyword]="'UnitDes'"
                                         [itemTemplate]="itemInputTemplate"
                                         [notFoundTemplate]="notFoundinputTemplate"
                                         [debounceTime]="600"
                                         [minQueryLength]="3"
                                         (selected)="selectedUnitChange($event)"
                                         (inputCleared)="unitCleared()"
                                         (closed)="closed()"
                                         [(ngModel)]="unitSelectedName"
                                         [disabled]="downloading">
                        </ng-autocomplete>
                        <ng-template #itemInputTemplate let-item>
                            <a><label [innerHTML]="item.UnitLevel"></label> - <label [innerHTML]="item.UnitDes"></label></a>
                        </ng-template>
                        <ng-template #notFoundinputTemplate let-notFound>
                            <div [innerHTML]="'No se encontraron coincidencias'"></div>
                        </ng-template>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-group">
                        <label class="form-label">Desde</label>
                        <input type="date" class="form-control" [max]="today" [ngModel]="minDate | date:'yyyy-MM-dd'" (ngModelChange)="minDate=$event" [disabled]="downloading">
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-group">
                        <label class="form-label">Hasta</label>
                        <input type="date" class="form-control" [max]="today" [ngModel]="maxDate | date:'yyyy-MM-dd'" (ngModelChange)="maxDate=$event" [disabled]="downloading">
                    </div>
                </div>
            </div>
            <div class="row col">
                <div class="row mt-3 col-lg-12 col-md-12">
                <button class="mr-1 btn btn-labeled btn-outline-success mb-2" (click)="download()" *ngIf="!downloading">
                    <span class="btn-label">
                        <i class="fa fa-file-excel" aria-hidden="true"></i>
                    </span>
                    <span>Descargar reporte</span>
                </button>
                <button class="mr-1 btn btn-labeled btn-outline-success mb-2" *ngIf="downloading" disabled>
                    <span class="btn-label">
                        <i class="fa fa-circle-notch fa-spin"></i>
                    </span>
                    <span>Descargando</span>
                </button>
            </div>
        </div>
    </div>
</div>
</div>
<div class="content-heading">
  <div class="header-container">
    <h3>Traspasos Automáticos</h3>
    <small>Traspasos Automaticos entre nodos</small>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-default">
        <div class="card-body">
          <!-- FILTROS -->
          <div class="row">
            <div class="col-lg-3">
              <span>Buscar</span>
              <input type="text"
                      class="form-control"
                      maxlength="40"
                      [ngModel]="search"
                      (change)="onSearchChangeEVent($event)"                     
                      [ngModelOptions]="{ standalone: true }"
                     />
            </div>
            <div class="col-x-2">
              <span>&nbsp; </span>
              <div>
                <button [disabled]="searching"
                        type="button"
                        class="mr-1  btn btn-labeled btn-outline-primary mb-2" (click)="find()">
                          <span class="btn-label">
                            <i class="fa fa-file-excel" aria-hidden="true"></i>
                        </span>

                  <span>Buscar</span>
                </button>
              </div>
            </div>
          </div>
          <BR />

          <div class="row">
            <div class="col-lg-12">
              <app-ceq-table #CeqGrid
                             [gridAdditionalOptions]="gridOptions"
                             [columnDef]="columnDefs"
                             (apiEmitter)="gridEmiter($event)">
              </app-ceq-table>
            </div>
          </div>
          <BR />
          <button [disabled]="downloading"
                  type="button"
                  class="mr-1  btn btn-labeled btn-outline-primary mb-2"
                  (click)="addTransfer()">
            <div *ngIf="downloading">
              <i class="fas fa-circle-notch fa-spin"></i> Descargando
            </div>
            <div *ngIf="!downloading">
              <span class="btn-label">
              <i class="fa fa-file-excel"></i>
            </span>
              <span>Crear nuevo Traspaso</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


import { Component, OnInit, ViewChild } from '@angular/core';
import { BalanceResume, MultichannelService } from '../multichannel.service';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';
import { EquipmentloanserviceService } from '../../self-service/equipment-loan/equipmentloanservice.service';
import { Unit } from '../../common/models/Unit.Model';
import { Channel } from '../../common/models/Channel.Model';
import { Route } from '../../common/models/Route.Model';
import { ContainerService } from '../../catalog/container/container/container.service';
import { Container } from '../../common/models/Container.Model';
import { resolve } from 'dns';
import { CeqTableComponent } from '../../common/ceq-table/ceq-table.component';
import { GridOptions } from 'ag-grid-community';
import { CeveByContainerService } from '../../catalog/ceve/ceveByContainer.service';
import { error } from 'console';
import { ChannelService } from '../../catalog/channel/channel.service';
import { MultiselectOption } from '../../common/ceq-multiselect/ceq-multiselect.component';

@Component({
  selector: 'app-route-balance',
  templateUrl: './route-balance.component.html',
  styleUrls: ['./route-balance.component.scss']
})
export class RouteBalanceComponent implements OnInit {

  constructor(
    private multichannelSerice: MultichannelService,
    private settings: SettingsService,
    private globalService: GlobalFunctionsService,
    private equipmentLoanService: EquipmentloanserviceService,
    private containerService: ContainerService,
    private ceveByContainerService: CeveByContainerService,
    private channelService: ChannelService
  ) { }

  @ViewChild('ceqGrid', { static: false }) ceqGrid: CeqTableComponent;
  ceqListOptions: GridOptions;

  listOptions = {
    context: this,
    // suppressHorizontalScroll: true,
    // onRowDoubleClicked(event: any): any { },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    }
  };

  columnDefs = [
    {
      field: 'ContainerId',
      hide: true,
      sort: 'asc',
      suppressSizeToFit: true
    },
    {
      headerName: 'Equipo',
      field: 'ContainerCode',
      cellStyle: { background: '#A0CFEC', color: 'darkblue', 'text-align': 'center', border: '1px solid gray' },
      width: 150,
      editable: false,
      sortable: true,
      unSortIcon: true,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: '+ Inventario inicial',
      field: 'InitialBalance',
      width: 150,
      cellStyle: { 'text-align': 'end', background: '#C6DEFF', color: 'blue', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: '+ Cargo',
      field: 'Load',
      width: 150,
      cellStyle: { background: '#C6DEFF', color: 'blue', 'text-align': 'end', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: '- Devolución',
      field: 'Unload',
      width: 150,
      cellStyle: { background: '#C6DEFF', color: 'blue', 'text-align': 'end', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: '- Vale',
      field: 'SellerPaybill',
      width: 150,
      cellStyle: { 'text-align': 'end', background: '#C6DEFF', color: 'blue', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: '+ Recuperación de vale',
      field: 'PaybillRecovery',
      width: 150,
      cellStyle: { 'text-align': 'end', background: '#C6DEFF', color: 'blue', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: '+ Recuperación de vendedor',
      field: 'SellerRecovery',
      width: 150,
      cellStyle: { 'text-align': 'end', background: '#C6DEFF', color: 'blue', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: '- Equipo no controlado',
      field: 'UnControlled',
      width: 150,
      cellStyle: { 'text-align': 'end', background: '#C6DEFF', color: 'blue', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Existencia en camión CEQ',
      field: 'VanStock',
      width: 150,
      cellStyle: { background: '#98FF98', color: 'green', 'text-align': 'end', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Existencia RTM',
      field: 'RtmBalance',
      width: 150,
      cellStyle: { 'text-align': 'end', background: '#FFFFC2', color: 'brown', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Cifra de control',
      field: 'ControlBalance',
      width: 150,
      cellStyle: { 'text-align': 'end', background: '#FFFFC2', color: 'brown', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Vales pendientes',
      field: 'PendingPaybill',
      width: 150,
      cellStyle: { 'text-align': 'end', background: '#FDD7E4', color: 'red', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Comodato',
      field: 'EquipmenLoan',
      width: 150,
      cellStyle: { 'text-align': 'end', background: '#FDD7E4', color: 'red', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Inventario Integrado',
      field: 'IntegratedInventory',
      width: 150,
      cellStyle: { 'text-align': 'end', background: '#FDD7E4', color: 'red', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    }
  ];

  working: boolean = false;
  resume: BalanceResume = new BalanceResume();
  resalta: boolean = false;
  validation: number = 0;

  //filters
  routeBalanceReportActivitty: boolean = false;
  selectedDate: string;
  maxDate: string;

  //Filters
  units: Unit[] = [];
  selectedUnit: Unit = undefined;
  channels: Channel[] = [];
  channelId = undefined;
  routes: Route[] = [];
  routeId = undefined;
  routeCode: string = undefined;
  containerTypeList: MultiselectOption[] = [];
  selectedcontainers: number[] = this.containerTypeList.map(x => x.Id);


  listEmiter(gridOptions: GridOptions): void {
    this.ceqListOptions = gridOptions;
    this.ceqGrid.gridOptions = this.ceqListOptions;
    this.ceqGrid.gridOptions.api.hideOverlay();
  }

  ngOnInit(): void {
    let userActivities = this.settings.getUserAccess();
    this.routeBalanceReportActivitty = userActivities.includes('REPMULTICH');
    this.selectedDate = this.globalService.getStringDate(new Date(), false, 0);
    this.maxDate = this.globalService.getStringDate(new Date(), true, -3);
    this.getUserProfile();
  }

  async getUserProfile() {
    let user = this.settings.getUser();
    await this.globalService.GetParentList(user.Id, 'CEVE')
      .toPromise()
      .then(resp => {
        this.units = resp;
        if (resp.length > 0) {
          this.onUnitSelected(resp[0]);
        }
        this.working = false;
      }).catch(() => {
        this.globalService.messageToast('No se pudieron obtener los centros de venta', 'error');
        this.working = false;
      });
  }

  onUnitClear() {
    this.selectedUnit = undefined;
    this.channels = [];
    this.channelId = undefined;
    this.routes = [];
    this.routeId = undefined;
    this.routeCode = undefined;
    this.containerTypeList = [];
    this.selectedcontainers = [];

    this.ceqGrid.data = [];
  }

  async onUnitSelected(event: Unit) {
    this.selectedUnit = event;
    //getContainers must be before getChannelsByCeve to ensure grid dato is displayed when the page is showed
    await this.getContainers();
    await this.GetRouteBalanceResume();
    await this.getChannelsByCeve();
  }

  async onChannelSelected(event: any) {
    await this.getRoutesByChannel();
  }

  async onRouteSelected(event: any) {
    this.routeCode = this.routes.find(x => x.RouteId == this.routeId).RouteCode;
    await this.GetRouteBalance();
  }

  async onDateSelected() {
    await this.GetRouteBalance();
  }

  async getChannelsByCeve() {
    this.working = true;
    await this.channelService.getMultichannelByCeve(this.selectedUnit.UnitId)
      .toPromise()
      .then(async resp => {
        this.working = false;
        this.channels = resp;
        if (this.channels.length > 0) {
          this.channelId = resp[0].UnitId;
          this.onChannelSelected(this.channelId);
        }
      })
      .catch(async (error) => {
        this.working = false;
        console.error(error);
        await this.globalService.messageToast(error.error.Message, 'error');
      });
  }

  async getRoutesByChannel() {
    this.working = true;
    this.equipmentLoanService.getRoutesByChannel(this.selectedUnit.UnitId, this.channelId)
      .toPromise()
      .then(async resp => {
        this.working = false;
        this.routes = resp;
        if (this.routes.length > 0) {
          this.routeId = resp[0].RouteId;
          this.routeCode = resp[0].RouteCode;
          this.GetRouteBalance();
        }
      })
      .catch(async (error) => {
        this.working = false;
        console.error(error);
        await this.globalService.messageToast(error.error.Message, 'error');
      });
  }

  async getContainers() {
    this.working = true;
    await this.containerService.getContainersByContainerOrg(this.settings.getUser().Org, 0, 0)
      .toPromise()
      .then(async resp => {
        this.working = false;
        let tmpContainers = resp.map(x => { return { Checked: false, Id: x.ContainerId, Text: x.ContainerCode } });
        if (resp.length > 0) {
          //Consulto el equipo configurado por defecto
          let initialContainers = await this.getInitialContainers();
          //si no hay equipo configurado por defecto, selecciono todos el equipo
          if (initialContainers.length == 0) {
            initialContainers = tmpContainers.map(x => x.Id);
          }
          initialContainers.forEach(item => {
            let c = tmpContainers.find(x => x.Id == item);
            if (c != undefined && c != null) {
              c.Checked = true;
            }
          })

          this.containerTypeList=tmpContainers;
          this.selectedcontainers = this.containerTypeList.filter(x => x.Checked == true).map(x => x.Id);
        }
      })
      .catch(async error => {
        this.working = false;
        console.error(error);
        await this.globalService.messageToast(error.error.Message, 'error');
      })
  }

  async getInitialContainers(): Promise<number[]> {
    let containerIds: number[] = [];
    await this.ceveByContainerService.getCheckedRouteBalanceContainers(this.selectedUnit.UnitId)
      .toPromise()
      .then(resp => {
        containerIds = resp;
      })
      .catch(err => {
        console.error(err);
      });
    return new Promise(resolve => resolve(containerIds));
  }

  async ContainerSelected() {
    await this.GetRouteBalance();
  }

  async onRemissionTypeFilterChange(event: MultiselectOption[]) {
    // this.remissionTypeCheckedList = event;
    // this.remissionTypeSelected = event.map(x => x.Id).join(',');

    this.selectedcontainers = event.map(x => x.Id);

    // this.selectedcontainers = this.containerTypeList.filter(x => x.Checked == true).map(x => x.Id);

    await this.GetRouteBalance();
  }

  onRemissionTypeItemCheckedChange(event: MultiselectOption) {
    // if (event.Checked) {
    //   this.remissionTypeCheckedList.push(event);
    // }
    // else{
    //   this.remissionTypeCheckedList = this.remissionTypeCheckedList.filter(x=>x.Id!=event.Id);
    // }


    // this.remissionTypeSelected = this.remissionTypeCheckedList.map(x => x.Id).join(',');

    // this.selectedcontainers = event.map(x => x.Id);

  }


  async GetRouteBalance() {
    this.working = true;
    this.ceqGrid.gridOptions.api.showLoadingOverlay();
    await this.multichannelSerice.getRouteBalance(this.selectedUnit.UnitId, this.channelId, this.routeId, this.selectedcontainers, this.selectedDate)
      .toPromise()
      .then(async resp => {
        this.working = false;
        this.ceqGrid.data = resp;
        this.ceqGrid.gridOptions.api.hideOverlay();
      })
      .catch(async error => {
        this.working = false;
        this.ceqGrid.gridOptions.api.hideOverlay();
        console.error(error);
        await this.globalService.messageToast(error.error.Message, 'error');
      });
  }

  async GetRouteBalanceResume() {
    this.working = true;
    await this.multichannelSerice.getRouteBalanceResume(this.selectedUnit.UnitId, this.selectedDate)
      .toPromise()
      .then(async resp => {
        this.resume = resp;

        this.validation = this.resume.inventario_dia_anterior + this.resume.cargo - this.resume.recoleccion_fisica + this.resume.recuperacion_vendedor - this.resume.vale_vendedor + this.resume.recuperacion_vale - this.resume.no_controlado;
        if ((this.validation - this.resume.inventario_cliente) != 0)
          this.resalta = true;
        else
          this.resalta = false;


        this.working = true;
      })
      .catch(async error => {
        this.working = false;
        console.error(error);
        await this.globalService.messageToast(error.error.Message, 'error');
      });
  }

}
import { BaseModel } from "./BaseModel.Model";

export class FactoryCut extends BaseModel {
    Org: number;
    FactoryCutId: string;
    UnitId: number;
    FactoryCutDate: Date;
    SupervisorSign: string;
    ManagerSign: string;
    RegionalManagerSign: string;
    SupervisorComment: string;
    ManagerComment: string;
    RegionalManagerComment: string;
    Status: string;
    Observation: string;
}

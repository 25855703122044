import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from '../../common/services/GenericServices';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(private genericService: GenericService) { }

  getCurencyList(): Observable<any> {
    let url = 'currency';
    return this.genericService.getRequest(url, {});
  }

  getCurrencyById(currencyId: number): Observable<any> {
    let url = 'currency/' + currencyId;
    return this.genericService.getRequest(url, {});
  }

  createCurrency(currency: any): Observable<any> {
    let url = 'currency';
    return this.genericService.postRequest(url, {}, 0, currency);
  }

  updateCurrency(org: number, currency: any): Observable<any> {
    let url = 'currency';
    return this.genericService.putRequest(url, {}, 0, currency);
  }

  deleteCurrency(currencyId: number): Observable<any> {
    let url = 'currency/' + currencyId;
    return this.genericService.deleteRequest(url, {}, 0);
  }
}

<div class="content-heading">
  <div class="header-container">
    <h3>Producto</h3>
    <small>Detalle del Producto</small>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row">

            <div class="col-lg-3 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">
                  C&oacute;digo de barras
                </label>
                <div class="d-block">
                  <ngx-barcode6 [bc-format]="'CODE128'" [bc-value]="product.Codebar" [bc-display-value]="true">
                  </ngx-barcode6>
                </div>
              </div>
            </div>

            <div class="col-lg-9 col-md-12 col-sm-12">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                  Producto
                  <input class="form-control" type="text" value="" placeholder="Producto" name="ProductDescription"
                         disabled [(ngModel)]="product.ProductDescription" maxlength="200" />
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                  Nombre corto
                  <input class="form-control" type="text" value="" placeholder="Nombre corto" name="ProductName"
                         disabled [(ngModel)]="product.ProductName" maxlength="100" />
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                  Planta ID
                  <input class="form-control" type="text" value="" placeholder="Planta ID" name="ItemPlantaId"
                         disabled [(ngModel)]="product.ItemPlantaId" maxlength="100" />
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                  C&oacute;digo
                  <input class="form-control" type="text" value="" placeholder="Código" name="ProductCode"
                         disabled [(ngModel)]="product.ProductCode" maxlength="100" />
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                  Tipo
                  <input class="form-control" type="text" value="" placeholder="Tipo" name="ProductType"
                         disabled [(ngModel)]="product.ProductType" maxlength="100" />
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                  Precio
                  <input class="form-control" type="text" value="" placeholder="Precio" name="ProductPrice"
                         disabled [(ngModel)]="product.ProductPrice" maxlength="100" />
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                  Cupo
                  <input class="form-control" type="text" value="" placeholder="Código" name="ProductCode"
                         disabled [(ngModel)]="product.ProductConversion" maxlength="100" />
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                  Peso
                  <input class="form-control" type="text" value="" placeholder="Peso" name="ProductWeight"
                         disabled [(ngModel)]="product.ProductWeight" maxlength="100" />
                </div>
              </div>
            </div>

            <!-- botones -->
            <div class="card-body">
              <button type="button" class="mr-1 btn btn-labeled btn-outline-danger mb-2" (click)="btnCancelOnClick()">
                <span class="btn-label">
                  <i class="fa fa-chevron-circle-left"></i>
                </span>
                <span>Salir</span>
              </button>
            </div>
            <!-- fin botones -->

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
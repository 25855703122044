import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CalendarService } from '../../configuration/calendar/calendar.service';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { AdjustService } from '../../inventory/adjust/adjust.service';
import { ReportsService } from '../reports.service';
import { UserLogin } from '../../common/models/UserLogin.Model';
import { Calendar } from '../../common/models/Calendar.Model';
import { Unit } from '../../common/models/Unit.Model';
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';

@Component({
  selector: 'app-rep-inventory',
  templateUrl: './rep-inventory.component.html',
  styleUrls: ['./rep-inventory.component.scss'],
})
export class RepInventoryComponent implements OnInit {
  constructor(
    private reportService: ReportsService,
    private settingsServ: SettingsService,
    private router: Router,
    private calendarService: CalendarService,
    private adjustService:AdjustService,
    private globalFunctionService: GlobalFunctionsService
  ) {}

  downloading: boolean = false;
  working: boolean = false;
  user: UserLogin;
  region: Unit[];
  regionSelected: number = 0;
  clasificationSelected: number = 0;
  unitList: Unit[];
  organizacionList: Unit[];
  organizationSelected: number = 0;
  unitSelected: Unit;
  selectedDate: string;
  datepipe: DatePipe = new DatePipe('en-US');

  async ngOnInit(){
    this.user = this.settingsServ.getUser();
    this.globalFunctionService.canOperate();
    this.globalFunctionService.hasFSBRPending();
    await this.calendarService
      .CalendarInventoryInProgress()
      .subscribe((calendar: Calendar[]) => {
        if (calendar.length == 1) {
          this.globalFunctionService.messageToast(`No es posible realizar esta operación, se encuentra en toma de inventario fisico.`, 'warning');
          this.router.navigate([`/home`]);
        } else {
          //this.router.navigate([`/management/remission-detail/new`]);
        }
      });

    await this.adjustService.validateAdjustActive(this.settingsServ.getUser().UnitId)
      .toPromise()
      .then(resp => {
        if (resp.AdjustCode !== '') {
          this.globalFunctionService.messageToast(`No es posible realizar esta operación, se encuentra en compulsa.`, 'warning');
          this.router.navigate([`/home`]);
        }
      });

    this.initData();
  }

  initData() {
    this.selectedDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.organizationSelected = 0;
    this.getOrganizacion();
  }

  getOrganizacion(): void {
    this.working = true;

    this.reportService
      .getOrganizacionesByUser(this.user.Org,this.user.Id)
      .toPromise()
      .then((regiones: Unit[]) => {
        this.organizacionList = regiones;
        this.organizationSelected = regiones[0].UnitId;
        this.regionSelected = 0;
        this.getRegion();
      })
      .catch(() => {
        this.globalFunctionService.messageToast(`Ocurrió un error al obtener la organización.`, 'warning');
        this.working = false;
      });
  }

  getRegion() {
    this.working == true;
    let userId = 0;
    let org = 1;
    let user = JSON.parse(localStorage.getItem('user'));
    if (user != null && user != undefined) {
      userId = user.Id;
      org = user.Org;
    }

    this.reportService.getUserRegions(org,userId,this.organizationSelected.toString()).subscribe((move: any[]) => {
      this.region = move;
      this.regionSelected=move[0].UnitId;
     
      this.clasificationSelected = 1;
      this.unitSelected = new Unit();
      this.getNodeByRegionLevel();
      this.working == false;
         
    });

    
    
    
  }

  getNodeByRegionLevel() {
    this.working = true;
    var levelType: string =
      this.clasificationSelected == 0
        ? 'BOTH'
        : this.clasificationSelected == 1
        ? 'FAB'
        : this.clasificationSelected == 2
        ? 'CEVE'
        : '';
    if (levelType == '') {
      this.globalFunctionService.messageToast(`Seleccione una clasificación de nodo válida.`, 'warning');
      return;
    }
    this.reportService
      .getNodeByLevel(
        
        levelType,
        this.organizationSelected,
        this.user.Id
      )
      .toPromise()
      .then((resp: Unit[]) => {
        this.unitList = resp;
      })
      .catch(() => {
        this.globalFunctionService.messageToast(`No se encontraron nodos.`, 'warning');
      })
      .finally(() => {
        this.working = false;
        // this.unitSelected= '';
      });
  }

  download() {
    this.downloading = true;
    this.working = true;

    var levelType: string =
      this.clasificationSelected == 0
        ? 'BOTH'
        : this.clasificationSelected == 1
        ? 'FAB'
        : this.clasificationSelected == 2
        ? 'CEVE'
        : '';
    var node =
      this.unitSelected == undefined
        ? 0
        : this.unitSelected.UnitId == undefined
        ? 0
        : this.unitSelected.UnitId;
    this.reportService
      .getInventoryReport(
        this.selectedDate,
        this.user.Id,
        this.organizationSelected,
        this.regionSelected,
        levelType,
        node
      )
      .toPromise()

      .then((resp) => {
        if (resp == null) {
          this.globalFunctionService.messageToast(`No se ha encontrado información.`, 'warning');
          return;
        }
        let blob = new Blob([resp], { type: resp.type });
        const anchor = document.createElement('a');

        anchor.download = `Inventory-${this.selectedDate}.xlsx`;
        anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
        anchor.click();
        this.downloading = false;
      })
      .catch((err) => {
        console.log(err);
        this.globalFunctionService.messageToast(`No se pudo obtener el reporte de inventarios.`, 'warning');
      })
      .finally(() => {
        this.downloading = false;
        this.working = false;
      });
  }

}

<!---------------------------------------------------------------------------->
<div class="content-wrapper">

  <div class="card b mb-2">
    <div class="card-header bb">
      <h4 class="card-title">Detalle de Configuraci&oacute; de Veh&iacute;culo</h4>
    </div>    
  </div>


    <form #Form="ngForm" (ngSubmit)="formSubmit(Form.value)">
      <div class="form-container">
        <div class="row">

          <div class="form-group col-sm-4">
            <label class="form-label">C&oacute;digo</label>
            <input class="form-control" type="text" value="" placeholder="Código" [(ngModel)]="vehicleconfig.VehicleKey" name="VehicleKey" required maxlength="10" [disabled]="!isNew" />
          </div>

          <div class="form-group col-sm-4">
            <label class="form-label">Descripci&oacute;n</label>
            <input class="form-control" type="text" value="" placeholder="Descripción" [(ngModel)]="vehicleconfig.VehicleDescription" name="VehicleDescription" required maxlength="300" />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-sm-4">
            <label class="form-label">Peso Bruto ET y A</label>
            <input class="form-control" type="text" value="" placeholder="Peso Bruto ET y A" [(ngModel)]="vehicleconfig.VehicleWeightETA" name="VehicleWeightETA" required maxlength="15" />
          </div>

          <div class="form-group col-sm-4">
            <label class="form-label">Peso Bruto B</label>
            <input class="form-control" type="text" value="" placeholder="Peso Bruto B" [(ngModel)]="vehicleconfig.VehicleWeightB" name="VehicleWeightB" required maxlength="15" />
          </div>
          <div class="form-group col-sm-4">
            <label class="form-label">Peso Bruto C</label>
            <input class="form-control" type="text" value="" placeholder="Peso Bruto C" [(ngModel)]="vehicleconfig.VehicleWeightC" name="VehicleWeightC" required maxlength="15" />
          </div>
          <div class="form-group col-sm-4">
            <label class="form-label">Peso Bruto D</label>
            <input class="form-control" type="text" value="" placeholder="Peso Bruto D" [(ngModel)]="vehicleconfig.VehicleWeightD" name="VehicleWeightD" required maxlength="15" />
          </div>


          <div class="form-group col-sm-4">
            <label class="form-label">Largo Total (m)</label>
            <input class="form-control" type="text" value="" placeholder="Largo Total (m)" [(ngModel)]="vehicleconfig.VehicleTotalDimensions" name="VehicleTotalDimensions" required maxlength="15" />
          </div>

        </div>

        <br />
        <br />
        <div class="buttons-container">
          <button type="submit" class="mr-1 btn btn-labeled btn-outline-primary mb-2" [disabled]="!Form.valid">
            <span class="btn-label">
              <i class="fa fa-save"></i>
            </span>
            <span>Guardar</span>
          </button>
          &nbsp;&nbsp;
          <button type="button" class="mr-1 btn btn-labeled btn-outline-danger mb-2" (click)="btnCancelOnClick()">
            <span class="btn-label">
              <i class="fa fa-chevron-circle-left"></i>
            </span>
            <span>Volver</span>
          </button>
          
        </div>
      </div>
    </form>
</div>


import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { CeqTableComponent } from '../../common/ceq-table/ceq-table.component';
import { EditChildRendered } from '../../common/renders/edit-child-rendered';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import * as moment from 'moment';

import { SettingsService } from 'src/app/core/settings/settings.service';
import { InventoryService } from '../inventory.service'

import { UserLogin } from '../../common/models/UserLogin.Model';
import { Unit } from '../../common/models/Unit.Model';
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';

@Component({
  selector: 'app-automatictransfer-cat',
  templateUrl: './automatictransfer-cat.component.html',
  styleUrls: ['./automatictransfer-cat.component.scss']
})
export class AutomatictransferCatComponent implements OnInit {

  inputArgument: string = 'UnitDescription';
  search: string = '';
  filtro: string ='Init ';
  selectedUnit: Unit = new Unit();
  date: Date = new Date();
  counter: { ContainerId: number; code: string; qty: number }[] = [];
  userLogin: UserLogin;
  nodes: Unit[] = [];
  downloading: boolean = false;
  searching: boolean = false;
  permit: boolean = false;
  @ViewChild('CeqGrid', { static: false }) ceqGrid: CeqTableComponent;

  options: GridOptions;

  datepipe: DatePipe = new DatePipe('en-US');
  gridOptions = {
    context: this,

    frameworkComponents: { childEditRenderer: EditChildRendered},
    suppressHorizontalScroll: false,
    onRowDoubleClicked(event: any) { },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>',
    }
  };
  
  columnDefs = [
    {
      headerName: 'Código',
      field: 'TransferId',
      editable: false,
      hide: true,
    },
    {
      headerName: 'Descripción',
      field: 'Description',
      width: 15,
      editable: false,
      sortable: true,
      unSortIcon: true,
      resizable: true
    },
    {
      headerName: 'Intermedio',
      field: 'IntermediateName',
      width: 15,
      editable: false,
      sortable: true,
      unSortIcon: true,
      resizable: true
    },
    {
      headerName: 'Final',
      field: 'FinalName',
      width: 15,
      editable: false,
      sortable: true,
      unSortIcon: true,
      resizable: true
    },
    {
      headerName: 'Satelite',
      field: 'SatelliteName',
      width: 15,
      editable: false,
      sortable: true,
      unSortIcon: true,
      resizable: true
    },
    {
      headerName: 'Activado en',
      field: 'ActiveDate',
      width: 10,
      editable: false,
      resizable: true,
      cellRenderer: (params: any) => params.data.ActiveDate == '1980-01-01T00:00:00' ? ' ' : moment(params.data.ActiveDate).format('DD-MM-YYYY HH:mm:ss'),
    },
    {
      headerName: 'Cancelado en',
      field: 'InactiveDate',
      width: 10,
      editable: false,
      resizable: true,
      cellRenderer: (params: any) => params.data.InactiveDate == '1980-01-01T00:00:00' ? ' ' : moment(params.data.InactiveDate).format('DD-MM-YYYY HH:mm:ss'),
    },
    {
      headerName: 'Estado',
      width: 5,
      cellClass: 'align-start',
      editable: false,
      resizable: true,
      cellRenderer: (params: any) => params.data.Active ? 'Activo' : 'Inactivo'
    },
    {
      headerName: 'Modificado por',
      field: 'ModifiedBy',
      width: 10,
      editable: false,
      resizable: true,
    },
    {
      headerName: 'Eliminar',
      field: 'icon',
      width: 5,
      cellRenderer: 'childEditRenderer',
      colId: 'params',
      resizable: true,
      cellClass: 'text-center',
      hide: false
    }
  ];


  constructor(private router: Router,
    private settings: SettingsService, 
    private globalFunctionService: GlobalFunctionsService,
    private inventoryService: InventoryService) {
  }

  ngOnInit(): void {
    this.globalFunctionService.hasFSBRPending();
    this.globalFunctionService.canOperate();

    this.filtro = 'init';
    const Perm = this.settings.getUserAccess();
    if (Perm.includes("FTRAAUT")) {
      this.permit = true;
    }
  }

  gridEmiter(GridOptions: GridOptions) {
    this.options = GridOptions;
    this.ceqGrid.endPoint = 'automatictransfer/filter/'+this.search;
    this.ceqGrid.data = [];
    this.find();
  }

  addTransfer() {
      window.open('/inventory/automatictransfer-det','_blank');
  }

  onSearchChangeEVent(event: any) {
    this.search = event.target.value;
  }

  edit(row: any)
  {
      if (this.permit==false) {
        Swal.fire(
          '¡Warning!',
          'No cuenta con los permisos para poder Activar / Desactivar un traspaso Automático.',
          'success'
        );
        return
      }

    let msg: string;
    console.log(row.data.Active);
    if (row.data.Active == 0) {
      msg = "¿Desea activar esta configuración de traspaso automático?";
    }
    else {
      msg = "¿Desea desactivar esta configuración de traspaso automático?"
    }

    Swal.fire({
      title: 'Confirmacion',
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> &nbsp; Aceptar',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i> &nbsp; Cancelar',
      backdrop: `rgba(0,157,210,0.5)`
    }).then((result) => {
      if (result.isConfirmed) {

        this.inventoryService.AutomaticTransferChageStatus(row.data.TransferId,row.data.Active).subscribe(data => {
          console.log("result", data);
          Swal.fire(
            '¡Éxito!',
            'El traspaso automático se actualizo correctamente.',
            'success'
          );
        }, (error) => {
          console.log("error", error);
          Swal.fire('¡Error!', error.error, 'error');
        }, () => {
          this.ceqGrid.refreshTable();
        });

      }

    })
  }

  find() {
    this.search == undefined ? '' : this.search
    if (this.search!='')
      this.ceqGrid.endPoint = 'automatictransfer/filter/' + this.search;
    else
      this.ceqGrid.endPoint = 'automatictransfer';

    this.ceqGrid.refreshTable();
  }

}

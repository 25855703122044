<div class="content-heading">
    <div class="header-Container">
        <h3>Reporte de Etiquetas</h3>
        <small>Reporte Web</small>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="card card-default">
                <div class="card-body">
                    <div class="row col col-md-12">
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label class="form-label">Centro de ventas</label>
                                <ng-autocomplete class="" [placeholder]="'Búsqueda'" [disabled]="working" [data]="unitList" [searchKeyword]="'UnitDes'" [itemTemplate]="itemInputTemplate" [notFoundTemplate]="notFoundinputTemplate" [debounceTime]="600" [minQueryLength]="3" (selected)="selectedUnitChange($event)" (inputCleared)="unitCleared()">
                                </ng-autocomplete>
                                <ng-template #itemInputTemplate let-item>
                                    <a [innerHTML]="item.UnitDes"></a>
                                </ng-template>
                                <ng-template #notFoundinputTemplate let-notFound>
                                    <div [innerHTML]="'No se encontraron coincidencias'"></div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label class="form-label">Tipo de fecha</label>
                                <select class="form-control" [(ngModel)]="datetype">
                                    <option value="0">Fecha de creación</option>
                                    <option value="1">Fecha de Impresión</option>
                                    <option value="2">Fecha de uso</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label class="form-label">Fecha inicial</label>
                                <input type="date" class="form-control" [max]="today"  [ngModel]="minDate | date:'yyyy-MM-dd'" (ngModelChange)="minDate=$event" required>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label class="form-label">Fecha final</label>
                                <input type="date" class="form-control" [max]="today"  [ngModel]="maxDate | date:'yyyy-MM-dd'" (ngModelChange)="maxDate=$event" required>
                            </div>
                        </div>
                    </div>
                    <div class="row col">
                    <div class="row mt-4 col-lg-12 col-md-12">
                    <div class="col col-md-12">
                        <button class="mr-1 btn btn-labeled btn-outline-success mb-2" (click)="download()" *ngIf="!downloading">
                            <span class="btn-label">
                              <i class="fa fa-file-excel" aria-hidden="true"></i>                           
                            </span>
                            <span>Descargar reporte</span>
                        </button>
                        <button class="btn btn-labeled btn-outline-success mb-2" *ngIf="downloading" disabled>
                            <span class="btn-label">
                                <i class="fa fa-circle-notch fa-spin"></i>
                            </span>
                            <span>Descargando</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { TranslatorService } from '../../core/translator/translator.service';
import { SettingsService } from '../../core/settings/settings.service';
import { GenericService } from '../../ceq/common/services/GenericServices';
import { ThemesService } from '../../core/themes/themes.service';
import { UserService } from "../../ceq/security/user/user.service";

import { UserLogin } from "../../ceq/common/models/UserLogin.Model";
import { User } from '../../ceq/common/models/User.Model';
import { Org } from '../../ceq/common/models/Org.Model';

@Component({
    selector: 'app-offsidebar',
    templateUrl: './offsidebar.component.html',
    styleUrls: ['./offsidebar.component.scss']
})
export class OffsidebarComponent implements OnInit, OnDestroy {

    currentTheme: any;
    selectedLanguage: string;
    userLogin: UserLogin;
    user: User;
    hasWidgetAuth: boolean = false;
    activities: string[];
    widgetStatus: boolean = true;
    interval: number = 0;
    Org: number;
    MultiSystem: boolean = false;
    Organization: Org[] = [];
    elementos: number = 0;

    Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true
    });

    constructor(public settings: SettingsService,
      public themes: ThemesService,
      public translator: TranslatorService,
      public elem: ElementRef,
      private genericServ: GenericService,
      public userService: UserService,
      private router: Router) {
          //this.currentTheme = themes.getDefaultTheme();
          this.selectedLanguage = this.getLangs()[1].code;
    }

    async ngOnInit() {
        let or = new Org;
        or.Org = 1
        or.OrgName = 'CEQ';
        this.Organization.push(or);
        or = new Org;
        or.Org = 2
        or.OrgName = 'Moldes';
        this.Organization.push(or);

        this.anyClickClose();
        this.userLogin = this.settings.getUser();
        this.userService.getUser(this.userLogin.Id).subscribe(data => {
          this.user = data;
          this.Org = this.user.Org;
        });
        if (this.userLogin.Theme === 'undefined') {
          this.currentTheme = this.themes.getDefaultTheme();
        } else {
          this.currentTheme = this.userLogin.Theme;
        }
        this.themes.setTheme(this.currentTheme);
        this.getActivitiesByService(this.userLogin.Id);
        this.elementos = await this.settings.getMultiSystem(this.userLogin.Email);
        if (this.elementos > 1)
          this.MultiSystem = true;
    }

    setTheme() {
        this.themes.setTheme(this.currentTheme);
        this.userLogin.Theme = this.currentTheme;
        this.user.UserTheme = this.currentTheme;
        this.settings.setUser(this.userLogin);
        this.userService.updateUser(this.user).subscribe();
    }

    getLangs() {
        return this.translator.getAvailableLanguages();
    }

    setLang(value) {
        this.translator.useLanguage(value);
    }

    anyClickClose() {
        document.addEventListener('click', this.checkCloseOffsidebar, false);
    }

    checkCloseOffsidebar = e => {
        const contains = (this.elem.nativeElement !== e.target && this.elem.nativeElement.contains(e.target));
        if (!contains) {
            this.settings.setLayoutSetting('offsidebarOpen', false);
        }
    }

    //widgets

    getActivitiesByService(userId: number){
      this.userService.getUserAccess(userId).subscribe(data => {
        this.activities = data;
        if(this.activities != null){
          this.hasWidgetAuth = this.activities.includes('WIDGETS');
        }
        this.widgetStatus = this.userLogin.UseWidget;
        this.interval = this.userLogin.WidgetInterval;
      })
    }

    toggleWidgets(){
        this.userLogin.UseWidget = !this.userLogin.UseWidget;
        this.settings.setUser(this.userLogin);
        this.widgetStatus = this.userLogin.UseWidget;
        console.log(this.userLogin.UseWidget);
        this.userService.updateWidgetParams(this.userLogin.Id,this.widgetStatus, this.interval).toPromise()
        .then(()=>{
          console.log('update emited');
        })
        .catch(()=>{
          console.log('Error al actualizar par+ametros de T');
        });
        if(this.widgetStatus){
            this.router.navigate([
                `/widgets`
              ]);
        }
        else{
            this.router.navigate([
              `/home`
            ]);
        }
    }

    setWidgetInterval(interval: number){
      this.userLogin.WidgetInterval = interval;
      this.settings.setUser(this.userLogin);
      this.interval = interval;
      this.userService.updateWidgetParams(this.userLogin.Id,this.widgetStatus, this.interval).toPromise()
      .then(()=>{
        console.log('update emited');
      })
      .catch(()=>{
        console.log('Error al actualizar par+ametros de T');
      });
    }

    ngOnDestroy() {
        document.removeEventListener('click', this.checkCloseOffsidebar);
    }

    OrgChange(event: number) {
      //LoginAD con la nueva organizacion
      let token = localStorage.getItem("msal.idtoken");
      let decoded = this.getDecodedAccessToken(token);

      this.LoginAD(this.userLogin.Email, token,this.Org).subscribe(response => {

        this.settings.setUser(response).then(() => {
          //redirecciona a pantalla de inicio
          this.settings.isLogin = true;
          if (this.router.url == "/home") {
            window.location.reload();
          }
          else {
            localStorage.setItem("NeedReaload", "true"); // define la variable para que al entrar al Home se realize un Reload de la app
            this.router.navigate(["/home"]);
          }
        // window.location.reload();

        }).catch(err => {
          this.Toast.fire({
            icon: 'error',
            title: JSON.stringify(err)
          });
        });
      });

    }

    /*
    *
    * LoginAD con la nueva organizacion
    *
    */
    LoginAD(login: string, password: string, org : number): Observable<UserLogin> {
      let url = "security/loginAD";
      return this.genericServ.postRequest(url, {}, 0, { Login: login, Password: password, Org: org });
    }

    getDecodedAccessToken(token: string): any {
      try {
        return jwt_decode(token);
      }
      catch (Error) {
        return null;
      }
    }

}

function jwt_decode(token: string): any {
  throw new Error('Function not implemented.');
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/core/settings/settings.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';

import { CeqTableComponent } from 'src/app/ceq/common/ceq-table/ceq-table.component';
import { EditChildRendered } from '../../../common/renders/edit-child-rendered';
import { DeleteChildRendered } from '../../../common/renders/delete-child-rendered';
import { VehicleconfigService } from '../vehicleconfig.service';
import { UserLogin } from 'src/app/ceq/common/models/UserLogin.Model';

@Component({
  selector: 'app-vehicleconfig-list',
  templateUrl: './vehicleconfig-list.component.html',
  styleUrls: ['./vehicleconfig-list.component.scss']
})
export class VehicleconfigListComponent implements OnInit {

  constructor(private router: Router,
    private settings: SettingsService,
    private vehicleConfigService: VehicleconfigService) { }

  // ViewChild
  @ViewChild('CeqGrid', { static: false }) ceqGrid: CeqTableComponent;
  options: GridOptions;
  PageSizes = [10, 25, 50, 100, 1000];
  pageSize = 10;
  userLogin: UserLogin;

  gridOptions = {
    context: this,
    frameworkComponents: {
      childEditRenderer: EditChildRendered,
      childDeleteMessageRenderer: DeleteChildRendered
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) { },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    }
  }

  columnDefs = [
    {
      headerName: 'Código',
      field: 'VehicleKey',
      width: 10,
      cellClass: 'align-start',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      editable: false
    },
    {
      headerName: 'Descripción',
      field: 'VehicleDescription',
      width: 40,
      cellClass: 'align-start',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      editable: false
    },
    {
      headerName: 'Editar',
      field: 'icon',
      width: 5,
      cellRenderer: 'childEditRenderer',
      colId: 'params',
      cellClass: 'text-center',
      hide: false,
    },
    {
      headerName: 'Eliminar',
      field: 'icon',
      width: 5,
      cellRenderer: 'childDeleteMessageRenderer',
      colId: 'params',
      cellClass: 'text-center',
      hide: false,
    }
  ];

  ngOnInit(): void {
  }

  gridEmiter(GridOptions: GridOptions) {
    this.options = GridOptions;
    this.getVehicleConfig();
  }

  onPageSizeChange(value: number) {
    console.log(value);
  }

  getVehicleConfig() {
    this.vehicleConfigService.getVehicleConfigList().subscribe(data => {
      this.ceqGrid.data = data;
    });
  }

  btnAddClick() {
    this.router.navigate(['/tax/vehicleconfig/create']);
  }

  edit(row: any) {
    this.router.navigate(['/tax/vehicleconfig', row.data.VehicleKey]);
  }

  delete(row: any) {
    Swal.fire({
      title: 'Confirmacion',
      text: "¿Desea eliminar la configuración vehicular " + row.data.VehicleKey + "?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> &nbsp; Aceptar',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i> &nbsp; Cancelar',
      backdrop: `rgba(0,157,210,0.5)`
    }).then((result) => {
      if (result.isConfirmed) {
        this.vehicleConfigService.deleteVehicleConfig(row.data.VehicleKey).subscribe(data => {
          console.log("result", data);
          Swal.fire(
            '¡Éxito!',
            'La configuración se eliminó correctamente.',
            'success'
          );
        }, (error) => {
          console.log("error", error);
          Swal.fire('¡Error!', error.error, 'error');
        }, () => {
          this.getVehicleConfig();
        });
      }
    })
  }


}

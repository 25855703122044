<div>
    <div class="input-group">

        <div class="input-group-prepend">
            <div class="input-group-text">
                <div class="checkbox c-checkbox m-0 p-0">
                    <label class=" p-0 m-0">
                        <input type="checkbox" [checked]="checkAll" [(ngModel)]="checkAll" (change)="onCheckAll()"  [disabled]="disabled"/>
                        <span class="fa fa-check  p-0 m-0"></span>
                    </label>
                </div>
            </div>
        </div>

        <button id="btnToggle"
                type="button"
                class="col btn btn-outline-primary dropdown-toggle"
                (click)="toggleDropdown()"
                [disabled]="disabled"
                aria-expanded="false">
            <span *ngIf="checkedList.length >= Items.length && Items.length>0">Todo</span>
            <span *ngIf="checkedList.length <= 0">Seleccione una opcion</span>
            <span *ngIf="checkedList.length > 0 && checkedList.length < Items.length">{{checkedItemsText }}</span>
        </button>





    </div>
    <div class="drop-show col-11 pt-1 pb-1" *ngIf="showDropDown">
        <!-- <div class="searbox-container">
            <input type="text"
                   class="form-control"
                   placeholder="Busqueda..."
                   [(ngModel)]="filterKeyword"
                   (ngModelChange)="filterChange()"
                   (focus)="onSearchboxFocus()"
                   (blur)="onSearchBoxBlur()">
        </div> -->

        <div class="row mt-1 mb-1" *ngFor="let a of Items">
            <div class="">
                <div class="form-check d-flex align-items-end">
                    <div class="checkbox c-checkbox">
                        <label class="m-0">
                            <input type="checkbox" id="inlineCheckbox1"
                                   [checked]="a.Checked"
                                   [(ngModel)]="a.Checked"
                                   (change)="getSelectedValue(a)" />
                            <span class="fa fa-check"></span>{{a.Text}}
                        </label>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>
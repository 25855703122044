import { Injectable } from '@angular/core';
import { List } from 'lodash';
import { Observable } from 'rxjs';

import { GenericService } from '../../common/services/GenericServices';
import { Org } from '../../common/models/Org.Model';
import { UserProfile } from '../../common/models/UserProfile.Model';
import { TransportLine } from '../../common/models/TransportLine.Model';

@Injectable({
  providedIn: 'root'
})
export class TransportLineService {

  constructor(private genericService: GenericService) { }
  controllerUrl: string = "transportline";

  getLines(): Observable<TransportLine[]> {
    let url = this.controllerUrl;
    return this.genericService.getRequest(url, {}, 0);
  }

  getLineById(transportLineId: number): Observable<TransportLine> {
    let url = `transportline/id/${transportLineId}`;
    return this.genericService.getRequest(url, {}, 0);
  }

  newLine(transportLine: TransportLine): Observable<TransportLine> {
    let url = this.controllerUrl;
    return this.genericService.postRequest(url, {}, 0, transportLine);
  }

  updateLine(transportLine: TransportLine): Observable<TransportLine> {
    let url = this.controllerUrl;
    return this.genericService.putRequest(url, {}, 0, transportLine);
  }

  deleteLine(transportLineId: number): Observable<TransportLine> {
    let url = this.controllerUrl + `/${transportLineId}`;
    return this.genericService.deleteRequest(url, {}, 0);
  }

}

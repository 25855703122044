import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FiscalConfigModel } from 'src/app/ceq/common/models/FiscalConfig.Model';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { FiscalconfigService } from '../fiscalconfig.service';

import { toNumber } from 'lodash';
import Swal from 'sweetalert2';
import { UserLogin } from 'src/app/ceq/common/models/UserLogin.Model';

@Component({
  selector: 'app-fiscalconfig-detail',
  templateUrl: './fiscalconfig-detail.component.html',
  styleUrls: ['./fiscalconfig-detail.component.scss']
})
export class FiscalconfigDetailComponent implements OnInit {

  constructor(private url: ActivatedRoute,
    private router: Router,
    private settings: SettingsService,
    private fiscalConfigService: FiscalconfigService) {
    this.fiscalconfig = new FiscalConfigModel();
  }

  org: number = 1;
  userLogin: UserLogin;
  fiscalconfig: FiscalConfigModel;
  fiscalConfigId: number;
  isNew: boolean = false;

  ngOnInit(): void {
    this.userLogin = this.settings.getUser();
    let currentId = this.url.snapshot.paramMap.get('id');

    this.fiscalconfig.Org = this.userLogin.Org;

    if (this.isNullOrWhitespace(currentId)) {
      this.fiscalconfig.IdFiscalConfig = 0;
      this.isNew = true;
    }
    else {
      this.fiscalconfig.IdFiscalConfig = toNumber(currentId);
      this.getFiscalConfig();
      this.isNew = false;
    }
  }

  formSubmit(value: any) {
    if (this.fiscalconfig.IdFiscalConfig == 0) {
      //Create

      this.fiscalConfigService.createFiscalConfig(this.fiscalconfig).subscribe(data => {
        Swal.fire('¡Éxito!', 'Configuración creada', 'success');
        this.btnCancelOnClick();
      }, (error) => {
        Swal.fire('¡Error!', error.error, 'error');
      });
    }
    else {
      //Update
      this.fiscalConfigService.updateFiscalConfig(this.fiscalconfig).subscribe(data => {
        Swal.fire('¡Éxito!', 'Configuración actualizada', 'success');
        this.btnCancelOnClick();
      }, (error) => {
        Swal.fire('¡Error!', error.error, 'error');
      });
    }
  }

  btnCancelOnClick() {
    this.router.navigate(['/tax/fiscalconfig']);
  }



  isNullOrWhitespace(input) {
    if (typeof input === 'undefined' || input == null) return true;
    return input.replace(/\s/g, '').length < 1;
  }

  getFiscalConfig() {
    this.fiscalConfigService.getFiscalConfigById(this.fiscalconfig.IdFiscalConfig).subscribe(data => {
      this.fiscalconfig = data;
    }, (error) => {
      Swal.fire('¡Error!', "No se encontró la configuración solicitada", 'error');
      this.btnCancelOnClick();
    });
  }

}

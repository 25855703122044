import { BaseModel } from './BaseModel.Model';
export class AutomaticTransfer extends BaseModel {
  Org: number;
  TransferId: string;
  Intermediate: number;
  Satellite: number;
  Final: number;
  Description: string;
  Active: number;
  UserIdInactive: number;
  InactiveDate: Date;
  UserIdActive: number;
  ActiveDate: Date;
  FinalName: string
  SatelliteName: string
  IntermediateName: string 



  constructor() {
    super();
    this.Description = "";
 
  }
}

<div class="content-heading hide-print">
  <div class="header-container">
    <h3>CARATULA PLANTAS {{currentYear}}</h3>
    <small>Caratula de inventario</small>
  </div>
</div>

<div class="form-container">
  <div class="row hide-print">
    <div class="center-data col-sm-3">
      <span>Fecha:</span> &nbsp; &nbsp; &nbsp;
      <select class="form-control" [(ngModel)]="selectedDate">
        <option value="undefined" selected disabled>{{loadingDates ? 'Buscando fechas...' : (events.length === 0 ? 'Sin fechas disponibles' : 'Seleccione una fecha')}}</option>
        <option *ngFor="let event of events" [value]="event.StartAt">{{event.StartAt | date: 'dd/MMM/yyyy' : 'es'}}</option>
      </select>
    </div>
    <div class="center-data col-sm-5">
      <span>Planta:</span> &nbsp; &nbsp; &nbsp;
      <ng-autocomplete placeholder="Escriba para búscar" [data]="factoryList" [searchKeyword]="factoryArgument" (selected)='selectFactory($event)' (inputChanged)='getFactoryByArgument($event)' [itemTemplate]="itemOutputTemplate" [notFoundTemplate]="notFoundoutputTemplate"
                       [debounceTime]="600" [isLoading]="isLoadingFactory" [minQueryLength]="3">
      </ng-autocomplete>
      <ng-template #itemOutputTemplate let-item>
        <a [innerHTML]="item.name"></a>
      </ng-template>

      <ng-template #notFoundoutputTemplate let-notFound>
        <div [innerHTML]="'No se encontrarón coincidencias'"></div>
      </ng-template>
      &nbsp; &nbsp; &nbsp;
      <button class="btn btn-outline-primary" (click)="searchInventory()" [disabled]="!selectedDate || !factory || searching">
        <span>Buscar</span>
      </button>
    </div>
    <div class="center-data col-sm-4">
      <div style="width: 100%; display: flex; justify-content: flex-end;">
        <button class="btn btn-outline-primary" (click)="print()" *ngIf="factoryCutObject.Status">
          <i class="fa fa-print" aria-hidden="true"></i>&nbsp;&nbsp;
          <span>Imprimir</span>
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngIf="dataReady" class="center-data col-sm-4">N&uacute;mero: {{factoryCutObject.FactoryCutId }} </div>
    <div *ngIf="dataReady" class="center-data col-sm-4">
      Estado: {{
factoryCutObject.Status == 'NEW' ? 'NUEVO'
        : factoryCutObject.Status == 'SAVED' ? 'GUARDADO'
        : factoryCutObject.Status == 'CLOSED' ? 'CERRADO'
        : factoryCutObject.Status == 'PUBLISHED' ? 'PUBLICADO'
        : factoryCutObject.Status == 'LAPSED' ? 'CADUCADO'
        :  'OTRO'
      }}
    </div>
  </div>
  <br>
  <app-loading *ngIf="searching"></app-loading>
  <div id="cutContainer">
    <h4 *ngIf="factoryCutObject.Status === 'PUBLISHED'">Fecha y hora de posteo: {{factoryCutObject.ModifiedAt | date: 'dd/MMMM/yyyy'}} a las {{factoryCutObject.ModifiedAt | date: 'HH:mm'}}</h4>
    <div class="full-border" *ngIf="dataReady">
      <div class="data-container pointer">
        <div class="d-flex black-text">
          <div class="number-width col-header col-lg-4">
            <div class="col-lg-4"><b>FILIAL</b></div>
            <div class="col-lg-4" *ngIf="factory">
              <h3><b>{{factory.name}}</b></h3>
            </div>
          </div>
          <div class="col-header cell-width" *ngFor="let container of containers">
            <div class="col"><b>{{container.ContainerDescription.split('.')[0]}}</b></div>
            <div class="col" class="bg-data"><b>{{container.ContainerCode}} / {{container.ContainerType.ContainerTypeDescription}}  </b></div>
            
          </div>
        </div>

        <div class="d-flex white-text">
          <div class="number-width border-side bg-title">
            <b><h4>1.- Disponible inicial</h4></b>
          </div>
          <div class="center-data cell-width border-side bg-title" *ngFor="let container of previousInventory">
            <b>{{container.quantity}}</b>
          </div>
        </div>

        <div class="d-flex">
          <div class="number-width border-side bg-data black-text">
            <h4>2.- Compras</h4>
          </div>
          <div class="center-data cell-width border-side blue-text bg-data" *ngFor="let container of purchases">
            {{container.quantity}}
          </div>
        </div>

        <div class="d-flex">
          <div class="number-width border-side bg-data black-text">
            <h4>3.- Bajas por Reciclaje (Plantas/Cedis)</h4>
          </div>
          <div class="center-data cell-width border-side blue-text bg-data" *ngFor="let container of recycling">
            {{container.quantity}}
          </div>
        </div>

        <div class="d-flex">
          <div class="number-width border-side bg-data black-text">
            <h4>4.- Otras bajas Plantas y Cedis</h4>
          </div>
          <div class="center-data cell-width border-side blue-text bg-data" *ngFor="let container of other">
            {{container.quantity}}
          </div>
        </div>

        <div class="d-flex">
          <div class="number-width border-side bg-data black-text">
            <h4>5.- Recuperaci&oacute;n de Seguridad Plantas y Cedis</h4>
          </div>
          <div class="center-data cell-width border-side blue-text bg-data" *ngFor="let container of recovery">
            {{container.quantity}}
          </div>
        </div>

        <div class="d-flex white-text">
          <div class="number-width border-side bg-title">
            <b><h4>6.- Inv. Teórico (Disponible al corte de inventario)</h4></b>
          </div>
          <div class="center-data cell-width border-side bg-title" *ngFor="let container of theoricInventory">
            <b>{{container.quantity}}</b>
          </div>
        </div>

        <div class="d-flex">
          <div class="number-width border-side bg-data black-text">
            <h4>7.- Inventario F&iacute;sico</h4>
          </div>
          <div class="center-data cell-width border-side blue-text bg-data border-bottom-gray" *ngFor="let container of physicalInventory">
            <input class="form-control" type="number"
                   onkeyup="if(this.value<0){this.value= this.value * -1}"
                   onblur="if(container.quantity < 0){container.quantity * -1}"
                   [ngClass]="{'red-text': container.quantity < 0, 'blue-text': container.quantity >= 0}"
                   [(ngModel)]="container.quantity"
                   [disabled]="working || factoryCutObject.Status === 'CLOSED' || factoryCutObject.Status === 'PUBLISHED' || !isFabAdmn || !isLastCutDate || !isDateTimeRangeValid">
          </div>
        </div>

        <div class="d-flex">
          <div class="number-width border-side bg-data black-text">
            <h4>8.- Tr&aacute;nsito</h4>
          </div>
          <div class="center-data cell-width border-side blue-text bg-data border-bottom-gray" *ngFor="let container of transitInventory">
            <b [ngClass]="{'red-text': container.quantity < 0, 'blue-text': container.quantity >= 0}">{{container.quantity}}</b>
          </div>
        </div>

        <div class="d-flex white-text">
          <div class="number-width border-side bg-title border-bottom-black">
            <b><h4>9.- Inventario Real Final (Físico + Tránsito)</h4></b>
          </div>
          <div class="center-data cell-width border-side bg-title black-text border-bottom-black white-text" *ngFor="let container of realInventory; let i = index">
            <b>{{physicalInventory[i].quantity + transitInventory[i].quantity}}</b>
          </div>
        </div>

        <div class="d-flex">
          <div class="number-width border-side bg-data black-text">
            <h4>10.- Flujo con Centros de Venta</h4>
          </div>
          <div class="center-data cell-width border-side blue-text bg-data border-bottom-gray" *ngFor="let container of ceveRelation">
            <b [ngClass]="{'red-text': container.quantity < 0, 'blue-text': container.quantity >= 0}">{{container.quantity}}</b>
          </div>
        </div>

        <div class="d-flex">
          <div class="number-width border-side bg-data black-text">
            <h4>11.- Flujo con Plantas/Cedis</h4>
          </div>
          <div class="center-data cell-width border-side blue-text bg-data border-bottom-gray" *ngFor="let container of factoryRelation">
            <b [ngClass]="{'red-text': container.quantity < 0, 'blue-text': container.quantity >= 0}">{{container.quantity}}</b>
          </div>
        </div>

        <div class="d-flex">
          <div class="number-width border-side bg-data black-text">
            <h4>12.- Flujo Total.</h4>
          </div>
          <div class="center-data cell-width border-side blue-text bg-data border-bottom-gray" *ngFor="let container of totalBalance; let i = index">
            <b [ngClass]="{'red-text': container.quantity < 0, 'blue-text': container.quantity >= 0}">{{container.quantity}}</b>
          </div>
        </div>

        <div class="d-flex white-text">
          <div class="number-width border-side bg-title border-bottom-black">
            <b><h4>13.- Diferencias de inventario (Real final - Teórico)</h4></b>
          </div>
          <div class="center-data cell-width border-side bg-total border-bottom-black" *ngFor="let container of realInventory; let i = index">
            <b [ngClass]="{'red-text': physicalInventory[i].quantity + transitInventory[i].quantity - theoricInventory[i].quantity < 0, 'black-text': physicalInventory[i].quantity + transitInventory[i].quantity - theoricInventory[i].quantity >= 0}">{{physicalInventory[i].quantity + transitInventory[i].quantity - theoricInventory[i].quantity }}</b>
          </div>
        </div>

        <div class="d-flex white-text">
          <div class="number-width border-side bg-title border-bottom-black">
            <b><h4>14.- Diferencias Porcentual (14=13 / 6))</h4></b>
          </div>
          <div class="center-data cell-width border-side bg-total black-text border-bottom-black" *ngFor="let container of realInventory; let i = index">
            <b>{{ getPercentDifference(physicalInventory[i].quantity + transitInventory[i].quantity - theoricInventory[i].quantity, theoricInventory[i].quantity).toFixed(1)}}%</b>
          </div>
        </div>

        <textarea placeholder="Observaciones" rows="3" class="w-100 form-control" [(ngModel)]="factoryCutObject.Observation" [disabled]="factoryCutObject.Status === 'CLOSED' || factoryCutObject.Status === 'PUBLISHED' || !isDateTimeRangeValid">
                </textarea>
      </div>
    </div>
    <br>

    <div class="d-flex justify-content-around" *ngIf="dataReady && (signActSuppervisorInverseLogistic || signActCheifLogistic || signActRegionalLogistic) && (factoryCutObject.Status != 'NEW' && factoryCutObject.Status != 'SAVED' && factoryCutObject.Status != 'LAPSED' )">
      <div class="col-4">
        <div class="card text-center">
          <div class="card-body">

            <b class="black-text">VALID&Oacute;</b>
            <br> Supervisor de Log&iacute;stica Inversa
            <hr>
            <button [disabled]="!signActSuppervisorInverseLogistic || !isDateTimeRangeValid" *ngIf="!isSupervisorSign" class="col-10 btn btn-outline-success" (click)="saveSignsRemove('isSupervisorSign')">
              <i class="fa fa-circle"></i>&nbsp;
              <span>Firmar</span>
            </button>
            <button *ngIf="isSupervisorSign" class="col-10 btn btn-outline-dark" [disabled]="true">
              <i class="fa fa-check-circle"></i>&nbsp;
              <span>Firmó</span>
            </button>
            <br>
            <b *ngFor="let value of factoryCutObject.SupervisorSign.split('|')"><br>{{value}}</b>

          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card text-center">
          <div class="card-body">

            <b class="black-text">APROB&Oacute;</b>
            <br> Gerente/Jefe de Log&iacute;stica
            <hr>
            <button [disabled]="!signActCheifLogistic || !isDateTimeRangeValid" *ngIf="!isManagerSign" class="col-10 btn btn-outline-success" (click)="saveSignsRemove('isManagerSign')">
              <i class="fa fa-circle"></i>&nbsp;
              <span>Firmar</span>
            </button>
            <button *ngIf="isManagerSign" class="col-10 btn btn-outline-dark" [disabled]="true">
              <i class="fa fa-check-circle"></i>&nbsp;
              <span>Firmó</span>
            </button>
            <br>
            <b *ngFor="let value of factoryCutObject.ManagerSign.split('|')"><br>{{value}}</b>

          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card text-center">
          <div class="card-body">

            <b class="black-text">VoBo</b>
            <br> Gerente Regional Log&iacute;stica
            <hr>
            <button [disabled]="!signActRegionalLogistic || !isDateTimeRangeValid" *ngIf="!isRegionManagerSign" class="col-10 btn btn-outline-success" (click)="saveSignsRemove('isRegionManagerSign')">
              <i class="fa fa-circle"></i>&nbsp;
              <span>Firmar</span>
            </button>
            <button *ngIf="isRegionManagerSign" class="col-10 btn btn-outline-dark" [disabled]="true">
              <i class="fa fa-check-circle"></i>&nbsp;
              <span>Firmó</span>
            </button>
            <br>
            <b *ngFor="let value of factoryCutObject.RegionalManagerSign.split('|')"><br>{{value}}</b>

          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-around" *ngIf="dataReady && (signActSuppervisorInverseLogistic || signActCheifLogistic || signActRegionalLogistic) && (factoryCutObject.Status != 'NEW' && factoryCutObject.Status != 'SAVED' )">
      <div class="col-4">
        <label>Comentarios de Supervisor de Log&iacute;stica Inversa</label>
        <textarea class="form-control max-height-comment"
                  placeholder="Sin comentarios"
                  [(ngModel)]="factoryCutObject.SupervisorComment"
                  [disabled]="!signActSuppervisorInverseLogistic || (factoryCutObject.Status != 'CLOSED') || !isDateTimeRangeValid || isSupervisorSign">
              </textarea>
      </div>
      <div class="col-4">
        <label>Comentarios de Gerente/Jefe de Log&iacute;stica</label>
        <textarea class="form-control max-height-comment"
                  placeholder="Sin comentarios"
                  [(ngModel)]="factoryCutObject.ManagerComment"
                  [disabled]="!signActCheifLogistic ||  (factoryCutObject.Status != 'CLOSED') || !isDateTimeRangeValid || isManagerSign">
              </textarea>
      </div>
      <div class="col-4">
        <label>Comentarios de Gerente Regional Log&iacute;stica</label>
        <textarea class="form-control max-height-comment"
                  placeholder="Sin comentarios"
                  [(ngModel)]="factoryCutObject.RegionalManagerComment"
                  [disabled]="!signActRegionalLogistic || (factoryCutObject.Status != 'CLOSED') || !isDateTimeRangeValid || isRegionManagerSign">
              </textarea>
      </div>
    </div>



  </div>
  <br>
  <div class="row" *ngIf="isDateTimeRangeValid && factoryCutObject.Status != 'LAPSED'">
    <div *ngIf="dataReady && isFabAdmn" class="hide-print">
      <button class="btn btn-outline-primary" (click)="saveCut()" [disabled]="working || (factoryCutObject.Status != 'SAVED' && factoryCutObject.Status != 'NEW')">
        <i class="fa fa-save"></i>&nbsp;
        <span *ngIf="!working">Guardar</span>
        <span *ngIf="working">Guardando...</span>
      </button> &nbsp; &nbsp; &nbsp;
      <button class="btn btn-outline-success" (click)="saveCut(true)" [disabled]="factoryCutObject.Status != 'SAVED' || !factoryCutObject.Observation || working || closing">
        <i class="fa fa-check"></i>&nbsp;
        <span *ngIf="!closing">Cerrar</span>
        <span *ngIf="closing">Cerrando...</span>
      </button> &nbsp; &nbsp; &nbsp;
    </div>
    <div *ngIf="dataReady && isFabSupervisor" class="hide-print">
      <button class="btn btn-outline-primary" (click)="openFactoryCut()" [disabled]="working || factoryCutObject.Status != 'CLOSED'">
        <i class="fa fa-save"></i>&nbsp;
        <span *ngIf="!working">Reabrir</span>
        <span *ngIf="working">Reabriendo...</span>
      </button> &nbsp; &nbsp; &nbsp;
    </div>
  </div>
</div>

<!---------------------------------------------------------------------------->
<div class="content-wrapper">

  <div class="card b mb-2">
    <div class="card-header bb">
      <h4 class="card-title">Detalle de L&iacute;nea de Transporte</h4>
    </div>    
  </div>


  <form #Form="ngForm" (ngSubmit)="formSubmit(Form.value)">
    <div class="form-container">
      <div class="row">

        <div class="form-group col-sm-4">
          <label class="form-label">Identificador</label>
          <input class="form-control" type="text" value="" placeholder="Identificador" [(ngModel)]="line.TransportLineId" name="TransportLineId" maxlength="10" disabled />
        </div>

        <div class="form-group col-sm-4">
          <label class="form-label">Transportista</label>
          <input class="form-control" type="text" value="" placeholder="Transportista" [(ngModel)]="line.LineName" name="LineName" required maxlength="30" />
        </div>

        <div class="form-group col-sm-4">
          <label class="form-label">Raz&oacute;n social</label>
          <input class="form-control" type="text" value="" placeholder="Razón social" [(ngModel)]="line.FiscalName" name="FiscalName" required maxlength="50" />
        </div>

        <div class="form-group col-sm-4">
          <label class="form-label">RFC</label>
          <input class="form-control" type="text" value="" placeholder="RFC" [(ngModel)]="line.TransportNIT" name="TransportNIT" required maxlength="30" />
        </div>

      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label class="form-label">Domicilio</label>
          <input class="form-control" type="text" value="" placeholder="Domicilio" [(ngModel)]="line.TransportAddress" name="TransportAddress" required maxlength="100" />
        </div>
      </div>
      <br /><br />
      <div class="buttons-container">
        <button type="submit" class="mr-1 btn btn-labeled btn-outline-primary mb-2" [disabled]="!Form.valid">
          <span class="btn-label">
            <i class="fa fa-save"></i>
          </span>
          <span>Guardar</span>
        </button>
        &nbsp;&nbsp;&nbsp;
        <button type="button" class="mr-1 btn btn-labeled btn-outline-danger mb-2" (click)="btnCancelOnClick()">
          <span class="btn-label">
          <i class="fa fa-chevron-circle-left"></i>
        </span>
          <span>Salir</span> 
        </button>

      </div>
    </div>
</form>

</div>


import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';
import * as moment from 'moment';
import { CeqTableComponent } from '../../../common/ceq-table/ceq-table.component';
import { EditChildRendered } from '../../../common/renders/edit-child-rendered';
import { DeleteChildRendered } from '../../../common/renders/delete-child-rendered';
import { PrintChildRendered } from '../../../common/renders/print-child-rendered';
import { ViewChildRendered } from '../../../common/renders/view-child-rendered';

import { GlobalFunctionsService } from 'src/app/ceq/common/functions/global-functions.service';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { AdjustService } from '../adjust.service';
import { AdjustCeveCutComponent } from '../adjust-ceve-cut/adjust-ceve-cut.component';
import { UserLogin } from 'src/app/ceq/common/models/UserLogin.Model';
import { CeqAdjust } from 'src/app/ceq/common/models/AdjustUnit.Model';
import { Unit } from 'src/app/ceq/common/models/Unit.Model';


@Component({
  selector: 'app-adjust-ceve',
  templateUrl: './adjust-ceve.component.html',
  styleUrls: ['./adjust-ceve.component.scss'],
})
export class AdjustCeveComponent implements OnInit {

  constructor(
    private globalFunctionService: GlobalFunctionsService,
    private settings: SettingsService,
    private adjustServive: AdjustService
  ) {

  }

  @ViewChild(AdjustCeveCutComponent) ceveCutAdj: AdjustCeveCutComponent;
  @ViewChild('CeqGrid', { static: false }) ceqGrid: CeqTableComponent;

  options: GridOptions;
  user: UserLogin;
  nodes: Unit[];
  selectedNode: Unit;
  selectedNodeName: string;
  adjusRunning: boolean = false;
  loading: Boolean = false;
  activeAdjust: CeqAdjust;
  hasStatusActivity: boolean = false;
  hasViewActivity: boolean = false;
  validateTransit: boolean = false;

  gridOptions = {
    context: this,
    frameworkComponents: {
      childEditRenderer: EditChildRendered,
      childPrintRenderer: PrintChildRendered,
      childViewRenderer: ViewChildRendered,
      childDeleteMessageRenderer: DeleteChildRendered
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) { },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    }
  }

  columnDefs = [
    {
      headerName: 'Region Origen',
      field: 'RegionOrigin',
      width: 15,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: 'Clasificación Origen',
      field: 'TypeOrigin',
      width: 10,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: 'Nodo Origen',
      field: 'OutputName',
      width: 35,
      cellClass: 'align-start',
      editable: false,
      resizable: true,
      unSortIcon: true,
      sortable: true
    },
    {
      headerName: 'Region Destino',
      field: 'RegionDestiny',
      width: 15,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: 'Clasificación Destino',
      field: 'TypeDestiny',
      width: 10,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: 'Nodo Destino',
      field: 'InputName',
      width: 35,
      cellClass: 'align-start',
      editable: false,
      resizable: true,
      unSortIcon: true,
      sortable: true
    },
    {
      headerName: 'Transporte',
      field: 'Tractor',
      width: 10,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: 'Remisión',
      field: 'Remission',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      resizable: true,
      unSortIcon: true,
      sortable: true
    },
    {
      headerName: 'Equipo',
      field: 'ContainerCode',
      width: 10,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: 'PT',
      field: 'PT',
      width: 10,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: 'EV',
      field: 'EV',
      width: 10,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: 'Total',
      field: 'Total',
      width: 10,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: 'Estatus',
      field: 'Status',
      width: 5,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: 'Fecha Cierre',
      field: 'CloseDate',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      resizable: true,
      unSortIcon: true,
      sortable: true,
      cellRenderer: (params: any) => moment(params.data.CloseDate).format('YYYY-MM-DD HH:mm')
    },
    {
      headerName: 'Fecha Venta',
      field: 'SellDate',
      width: 15,
      cellClass: 'align-start',
      editable: false,
      resizable: true,
      unSortIcon: true,
      sortable: true,
      cellRenderer: (params: any) => moment(params.data.SellDate).format('YYYY-MM-DD')
    }
  ];

  gridEmiter(gridOptions: GridOptions) {
    this.options = gridOptions;
    this.ceqGrid.data = [];
  }

  async ngOnInit() {
    this.user = this.settings.getUser();
    this.globalFunctionService.canOperate();
    this.globalFunctionService.hasFSBRPending();
    let activities = this.settings.getUserAccess();
    this.hasStatusActivity = activities.includes('ADJPREVAL');
    this.hasViewActivity = activities.includes('ADJVIEWWEB');

    await this.settings.getSettingsByKey('ADJVALCVT')
      .toPromise()
      .then(resp => {
        this.validateTransit = resp.Value === '1';
        console.log(resp);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        this.getUserNodes();
      })
  }

  getUserNodes() {
    this.adjustServive
      .getUserCeves(this.user.Org, this.user.Id)
      .toPromise()
      .then((resp: Unit[]) => {
        this.nodes = resp.filter(x => x.LevelType == 'CEVE');
        if (this.nodes.length > 0) {
          if (this.nodes.some(x => x.UnitId == this.user.UnitId)) {
            this.selectedNode = this.nodes.find(x => x.UnitId == this.user.UnitId);
          }
          else {
            this.selectedNode = this.nodes[0];
          }
        }
        else {
          this.nodes = [{
            OrgId: undefined,
            UnitId: undefined,
            UnitDescription: 'No tiene Centros de ventas asignados',
            CreatedAt: undefined,
            CreatedBy: undefined,
            ModifiedAt: undefined,
            ModifiedBy: undefined,
            ParentId: undefined,
            UnitCode: undefined,
            LevelType: undefined,
          }];
          this.selectedNode = this.nodes[0];
        }
        this.selectedNodeName = this.selectedNode.UnitDescription;

        //this.startAdjust();
        this.refreshGrid();
      })
      .catch(err => {
        console.log(err);
        this.loading = false;
        this.showErrorMessage(err);
      });
  }

  changeNode(event: any) {
    this.selectedNode = event;
    this.refreshGrid();
  }

  clearNode() {
    this.selectedNode = undefined;
    this.ceqGrid.data = [];
    return;
  }


  async refreshGrid() {
    if (this.selectedNode.UnitId == undefined || this.selectedNode.UnitId == null || this.selectedNode.UnitId == 0) {
      return;
    }
    this.ceqGrid.gridOptions.api.showLoadingOverlay();

    if (this.selectedNode == undefined) {
      this.ceqGrid.data = [];
      Swal.fire('', 'Debe seleccionar el nodo el cual se va a compulsar', 'warning');
      return;
    }

    await this.adjustServive.ongoingReport(this.selectedNode.UnitId).toPromise()
      .then(data => {
        this.ceqGrid.data = data;
      })
      .catch(err => {
        console.log(err);
        this.showErrorMessage(err);
      });

    this.ceqGrid.gridOptions.api.hideOverlay();

  }

  async ValidateAdjustState() {
    if (this.selectedNode.UnitId == undefined || this.selectedNode.UnitId == null || this.selectedNode.UnitId == 0) {
      return;
    }

    this.loading = true;
    if (this.validateTransit) {
      if (this.ceqGrid.data.length > 0) {
        Swal.fire('', 'Existen remisiones en transito, favor de validarlas con el equipo certificador de la compulsa', 'warning');
        this.loading = false;
        return;
      }
    }

    await this.adjustServive.validateAdjustState(this.selectedNode.UnitId).toPromise()
      .then(resp => {
        if (resp.AdjustCode === '') {
          this.startAdjust();
        }
        else {
          this.loading = false;
          this.activeAdjust = resp;
          this.adjusRunning = true;
        }

      })
      .catch(err => {
        this.loading = false;
        console.log(err);
        this.adjusRunning = false;
        this.showErrorMessage(err);
      });
  }

  async ViewCurrentAdjust() {
    if (this.selectedNode.UnitId == undefined || this.selectedNode.UnitId == null || this.selectedNode.UnitId == 0) {
      return;
    }

    try {
      this.loading = true;
      await this.adjustServive.validateAdjustActive(this.selectedNode.UnitId)
        .toPromise()
        .then(resp => {
          this.loading = false;
          if (resp.AdjustCode == '') {
            Swal.fire('', 'El nodo seleccionado no tiene una compulsa activa', 'info');
          }
          else {
            this.activeAdjust = resp;
            this.adjusRunning = true;
          }
        })
        .catch(err => {
          this.loading = false;
          this.showErrorMessage(err);
        });
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  }

  async startAdjust() {
    this.loading = true;
    await this.adjustServive.startAdjust(this.selectedNode.UnitId).toPromise()
      .then(data => {
        this.loading = false;
        this.activeAdjust = data;
        this.adjusRunning = true;
      })
      .catch(err => {
        this.loading = false;
        console.log(err);
        this.showErrorMessage(err);
      });

  }

  async cancelAdjust() {
    this.adjusRunning = false;
    this.getUserNodes();
  }


  showErrorMessage(err) {
    if (err?.error?.Message !== '' && err?.error?.Message !== undefined) {
      Swal.fire('Compulsa', err.error.Message, 'warning');
    }
    else if (err?.message !== '' && err?.message !== undefined) {
      Swal.fire('Compulsa', err.message, 'warning');
    }
    else {
      Swal.fire('Compulsa', err, 'warning');
    }
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../common/guards/auth-guard.guard';
import { FormsModule } from '@angular/forms';
import { TreeModule } from '@circlon/angular-tree-component';
import { SharedModule } from '../../shared/shared.module';

import { StateComponent } from './state/state.component';
import { ProductListComponent } from './product/product-list/product-list.component';
import { CarrierListComponent } from './carrier/carrier-list/carrier-list.component';
import { CarrierDetailComponent } from './carrier/carrier-detail/carrier-detail.component';
import { ZipcodeDetailComponent } from './zipcode/zipcode-detail/zipcode-detail.component';
import { ZipcodeListComponent } from './zipcode/zipcode-list/zipcode-list.component';
import { FiscalconfigListComponent } from './fiscalconfig/fiscalconfig-list/fiscalconfig-list.component';
import { FiscalconfigDetailComponent } from './fiscalconfig/fiscalconfig-detail/fiscalconfig-detail.component';
import { ProductDetailComponent } from './product/product-detail/product-detail.component';
import { VehicleconfigListComponent } from './vehicleconfig/vehicleconfig-list/vehicleconfig-list.component';
import { VehicleconfigDetailComponent } from './vehicleconfig/vehicleconfig-detail/vehicleconfig-detail.component';
import { TransportLineDetailComponent } from './transport-line/transport-line-detail/transport-line-detail.component';
import { TransportLineListComponent } from './transport-line/transport-line-list/transport-line-list.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { CurrencyListComponent } from './currency/currency-list/currency-list.component';
import { CurrencyDetailComponent } from './currency/currency-detail/currency-detail.component';

const routes: Routes = [
  { path: 'state', component: StateComponent, canActivate: [AuthGuard] },
  { path: 'product', component: ProductListComponent, canActivate: [AuthGuard] },
  { path: 'product/:id', component: ProductDetailComponent, canActivate: [AuthGuard] },
  { path: 'carrier', component: CarrierListComponent, canActivate: [AuthGuard] },
  { path: 'carrier/create', component: CarrierDetailComponent, canActivate: [AuthGuard] },
  { path: 'carrier/:id', component: CarrierDetailComponent, canActivate: [AuthGuard] },
  { path: 'transportline', component: TransportLineListComponent, canActivate: [AuthGuard] },
  { path: 'transportline/create', component: TransportLineDetailComponent, canActivate: [AuthGuard] },
  { path: 'transportline/:id', component: TransportLineDetailComponent, canActivate: [AuthGuard] },
  { path: 'fiscalconfig', component: FiscalconfigListComponent, canActivate: [AuthGuard] },
  { path: 'fiscalconfig/create', component: FiscalconfigDetailComponent, canActivate: [AuthGuard] },
  { path: 'fiscalconfig/:id', component: FiscalconfigDetailComponent, canActivate: [AuthGuard] },
  { path: 'zipcode', component: ZipcodeListComponent, canActivate: [AuthGuard] },
  { path: 'zipcode/create', component: ZipcodeDetailComponent, canActivate: [AuthGuard] },
  { path: 'zipcode/:id', component: ZipcodeDetailComponent, canActivate: [AuthGuard] },
  { path: 'vehicleconfig', component: VehicleconfigListComponent, canActivate: [AuthGuard] },
  { path: 'vehicleconfig/create', component: VehicleconfigDetailComponent, canActivate: [AuthGuard] },
  { path: 'vehicleconfig/:id', component: VehicleconfigDetailComponent, canActivate: [AuthGuard] },
  { path: 'currency', component: CurrencyListComponent, canActivate: [AuthGuard] },
  { path: 'currency/:id', component: CurrencyDetailComponent, canActivate: [AuthGuard] }

];

@NgModule({
  declarations: [
    StateComponent,
    ProductListComponent,
    CarrierListComponent,
    CarrierDetailComponent,
    ZipcodeDetailComponent,
    ZipcodeListComponent,
    FiscalconfigListComponent,
    FiscalconfigDetailComponent,
    ProductDetailComponent,
    VehicleconfigListComponent,
    VehicleconfigDetailComponent,
    TransportLineDetailComponent,
    TransportLineListComponent,
    CurrencyListComponent,
    CurrencyDetailComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    RouterModule.forChild(routes),
    TreeModule,
    FormsModule,
    NgxBarcode6Module
  ]
})
export class TaxModule { }

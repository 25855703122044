import { Injectable } from '@angular/core';
import { GenericService } from '../../common/services/GenericServices';
import { Observable } from 'rxjs';
import { List } from 'lodash';
import { Ceve } from '../../common/models/Ceve.Model';
import { Factory } from '../../common/models/Factory.Model';
import { Remission } from '../../common/models/Remission.Model';
import { Container } from '../../common/models/Container.Model';
import { IncidentReason } from '../../common/models/IncidentReason.Model';
import { Incidence } from '../../common/models/Incidence.Model';
import { IncidenceDetail } from '../../common/models/IncidenceDetail.Model';

@Injectable({
  providedIn: 'root'
})
export class RemoveEquipmentService {

  constructor(private genericService: GenericService) { }

  GetTeam(): Observable<any[]> {
    let url = "Ceve/GetEquipment";
    return this.genericService.getRequest(url, {});
  }

  GetTeamContainer(cont: string): Observable<any[]> {
    let url = "Container/GetContainer/" + cont;
    return this.genericService.getRequest(url, {});
  }

  GetTeamReason(): Observable<any[]> {
    let url = "IncidentReason/GetReason";
    return this.genericService.getRequest(url, {});
  }


  GetTeamQuantity(entity: number, container: number): Observable<any[]> {
    let url = "Inventory/GetInventoryE/" + entity + "/" + container;
    return this.genericService.getRequest(url, {});
  }

  PostCreateDTeam(incidenceDetail: any): Observable<List<IncidenceDetail>> {
    let url = "IncidenceDetail/List";
    return this.genericService.postRequest(url, {}, 0, incidenceDetail);
  }



  issubordinate(unitid: any): Observable<boolean> {
    let url = "Incidence";
    return this.genericService.getRequest(`Group/issubordinate/${unitid}`, {});
  }

  PostCreateTeam(incidence: any): Observable<Incidence> {
    let url = "Incidence";
    return this.genericService.postRequest(url, {}, 0, incidence);
  }

  getCeveList(): Observable<List<Ceve>> {
    return this.genericService.getRequest(`Ceve`, {});
  }


  applyInicidence(incidenceId: number): Observable<Incidence> {
    return this.genericService.getRequest(`Incidence/applyIncidence/${incidenceId}`, {});
  }

  GetFactoryByuserProfile(userId: number): Observable<any[]> {
    let url = `Factory/GetfactoryByuserProfile/${ userId }`;
    return this.genericService.getRequest(url, {});
  }

  GetCeveByuserProfile(userId: number): Observable<any[]> {
    let url = `Ceve/GetCeveByUserProfile/${ userId }`;
    return this.genericService.getRequest(url, {});
  }
}



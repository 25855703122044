import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-ceq-multiselect',
  templateUrl: './ceq-multiselect.component.html',
  styleUrls: ['./ceq-multiselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CeqMultiselectComponent implements OnInit {
  @Input() Items: MultiselectOption[] = [];
  @Input() disabled: boolean = false;
  @Output() shareCheckedList = new EventEmitter<MultiselectOption[]>();
  @Output() shareIndividualCheckedList = new EventEmitter<MultiselectOption>();


  checkedList: MultiselectOption[] = [];
  currentSelected: MultiselectOption = { Checked: false, Id: 0, Text: '' };
  showDropDown: boolean = false;
  checkedItemsText: string = '';
  checkAll: boolean = false;
  filterKeyword: string = '';
  originalItems: MultiselectOption[] = [];

  constructor(public elem: ElementRef) { }


  ngOnInit(): void {
    //evento para cerrar menu colapsable al hacer click afuera
    document.addEventListener('click', this.checkCloseOptions, false);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.Items) {
      // console.log(changes);
      this.checkedList = this.Items.filter(x => x.Checked === true);
      this.checkAll = this.checkedList.length === this.Items.length;
      this.setDisplayText();
    }
  }

  toggleDropdown() {
    let wasOpened = this.showDropDown;
    this.showDropDown = !this.showDropDown;
    if (wasOpened) {
      this.shareCheckedlist();
    }
  }

  getSelectedValue(item: MultiselectOption) {
    if (item.Checked) {
      this.checkedList.push(item);
    } else {
      var index = this.checkedList.indexOf(item);
      this.checkedList.splice(index, 1);
    }
    this.setDisplayText();
    this.currentSelected = item;

    if (this.Items.length > this.originalItems.length) {
      this.checkAll = this.checkedList.length === this.Items.length;
    }
    else {
      this.checkAll = this.checkedList.length === this.originalItems.length;
    }

    //share individual selected item
    this.shareIndividualStatus();
  }

  onCheckAll() {
    if (this.checkAll) {
      this.Items.forEach(item => {
        item.Checked = this.checkAll;
      });
    }
    else {
      this.Items.forEach(item => {
        item.Checked = this.checkAll;
      });
    }

    this.checkedList = [];
    let checkedItems = this.Items.filter(x => x.Checked == true);
    this.checkedList.push(...checkedItems);
    this.setDisplayText();
    this.shareCheckedlist();
  }

setDisplayText(){
  if(this.checkedList.length<4){
    this.checkedItemsText = this.checkedList.map(x => x.Text).join(', ');
  }
  else{
    let moreLength=this.checkedList.length-3;
    this.checkedItemsText = `${this.checkedList[0].Text},${this.checkedList[1].Text},${this.checkedList[2].Text}, + ${moreLength}`;
  }
}

  shareCheckedlist() {
    this.shareCheckedList.emit(this.checkedList);
  }
  shareIndividualStatus() {
    this.shareIndividualCheckedList.emit(this.currentSelected);
  }

  checkCloseOptions = e => {
    let wasOpened = this.showDropDown;
    if (wasOpened) {

      const contains = (this.elem.nativeElement !== e.target && this.elem.nativeElement.contains(e.target));
      if (!contains) {
        this.showDropDown = false;
        if (this.originalItems.length > 0) {
          this.Items = this.originalItems;
        }

        this.originalItems = [];
        if (this.showDropDown === false) {
          this.shareCheckedlist();
        }
      }
    }
  }




  onSearchboxFocus() {
    if (this.originalItems.length <= 0) {
      this.originalItems = this.Items;
    }
  }

  onSearchBoxBlur() {
    // this.Items = this.originalItems;
  }

  filterChange() {
    if (this.filterKeyword === '') {
      this.Items = this.originalItems;
    }
    else {
      this.Items = this.originalItems.filter(x => x.Text.toUpperCase().includes(this.filterKeyword.toUpperCase()));
    }

  }

}

export interface MultiselectOption {
  Checked: boolean;
  Id: any;
  Text: string;
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TreeModule } from '@circlon/angular-tree-component';
import { SharedModule } from '../../shared/shared.module';

import { AuthGuard } from '../common/guards/auth-guard.guard';
import { PlanningComponent } from './planning/planning.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { AttachmentComponent } from './attachment/attachment.component';
import { CapexDetailComponent } from './capex-detail/capex-detail.component';


const routes: Routes = [
  { path: 'planning', component: PlanningComponent, canActivate: [AuthGuard] },
  { path: 'purchase/:orderId/:providerId/:typeAction', component: PurchaseComponent, canActivate: [AuthGuard] },
  { path: 'attachment/:Table/:Key/:Title/:Perm', component: AttachmentComponent, canActivate: [AuthGuard] },
  { path: 'capex-detail/:capexId', component: CapexDetailComponent, canActivate: [AuthGuard] }
];

@NgModule({
  declarations: [
    PlanningComponent,
    PurchaseComponent,
    AttachmentComponent,
    CapexDetailComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    FormsModule,
    TreeModule
  ],
  exports: [
    RouterModule
  ]
})
export class PurchaseModule { }

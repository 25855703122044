import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'delete-cell',
  template: `<span><i class="fa fa-trash" (click)="invokeParentMethod();" style="width:100%;"></i></span>`,
  styles: [
    `.btn {
            line-height: 0.5
        }`
  ]
})
// tslint:disable-next-line:component-class-suffix
export class DeleteChildRendered implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  public invokeParentMethod() {
    this.params.context.delete(this.params);
  }

  refresh(): boolean {
    return false;
  }
}

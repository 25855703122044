export class BaseModel {
  CreatedAt: Date;
  CreatedBy: string;
  ModifiedAt: Date;
  ModifiedBy: string;

  constructor() {
    this.CreatedAt = new Date();
    this.CreatedBy = "";
    this.ModifiedAt = new Date();
    this.ModifiedBy = "";
  }
}

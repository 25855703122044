import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Sweetalert2 to show alerts
import Swal from 'sweetalert2';
import { CeqTableService } from './ceq-table.service';


declare var $: any;
@Component({
  selector: 'app-ceq-table',
  templateUrl: './ceq-table.component.html',
  styleUrls: ['./ceq-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CeqTableComponent implements OnInit {
  @Input() gridAdditionalOptions: any;
  @Input() columnDef: any = undefined;
  @Input() RowsToDisplay: number = 18;


  @Output() apiEmitter = new EventEmitter();
  @Output() SelectedRowDataEmitter = new EventEmitter<any>();
  @Output() dataReady = new EventEmitter<any>();

  endPoint: string;
  urlPost: string;
  request: any;
  data: any;
  gridOptions: GridOptions;
  resizeEvent = 'resize.ag-grid';
  $win = $(window);
  gridTitleHeight: number = 40;//size in px
  gridRowHeight: number = 25;//size in px


  baseConfiguration = {
    headerHeight: 40,
    columnDefs: this.columnDef,
    rowData: null,
    singleClickEdit: true,
    suppressColumnMoveAnimation: true,
    suppressRowClickSelection: false,
    suppressMovableColumns: true,
    suppressHorizontalScroll: false,
    rowSelection: 'single',
    stopEditingWhenGridLosesFocus: true,
    enableRangeSelection: true,
    localeText: {
      page: 'página',
      more: '',
      to: 'a',
      of: 'de',
      next: 'siguiente',
      last: 'última',
      first: 'primera',
      previous: 'previa',
      loadingOoo: 'cargando',
      noRowsToShow: 'No hay datos disponibles'
    },
    overlayLoadingTemplate:
      `<div class="card-body loader-demo d-flex align-items-center justify-content-center">
        <div class="ball-spin-fade-loader">
          <div><i class="fa fa-circle-o-notch fa-spin fa-5x fa-fw" style="color:#5B5B5B"></i>
          <span class="sr-only">Cargando...</span></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>`
  };

  constructor(private http: HttpClient, private ceqTableServ: CeqTableService) {
  }

  ngOnInit() {
    this.baseConfiguration.columnDefs = this.columnDef;
    this.gridOptions = <GridOptions>Object.assign(this.baseConfiguration, this.gridAdditionalOptions);
  }

  //**Evento que se dispara cuando se asigna una propiedad [] en el NgModel */
  ngOnChanges(changes: SimpleChanges) {
    //Valida que se haya cambiado la propiedad RowsToDisplay
    if (changes.RowsToDisplay) {
      // console.log(changes);
      let grids = document.getElementsByTagName('ag-grid-angular');
      for (let index = 0; index < grids.length; index++) {
        let grid = grids[index] as any;
        grid.style.height = (this.gridTitleHeight + (this.gridRowHeight * this.RowsToDisplay)) + 'px';
      }
    }
  }
  onRowClicked(event: any) {
    this.SelectedRowDataEmitter.emit(event.data)
  }

  gridReady(params: any) {
    params.api.sizeColumnsToFit();
    this.$win.on(this.resizeEvent, () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });
    this.apiEmitter.emit(this.gridOptions);
  }

  refreshTable() {
    console.log('EndPoint', this.endPoint);
    if (this.gridOptions) {
      if (this.gridOptions.api) {
        this.gridOptions.api.showLoadingOverlay();
      }
      this.getData();
    }
  }

  getData() {
    this.ceqTableServ.getData(this.endPoint).subscribe((data: any) => {
      //  console.log(data);
      if (this.gridOptions.api) {
        this.gridOptions.api.hideOverlay();
        this.data = data;
        this.dataReady.emit(this.data);
      }
    }, err => {
      if (this.gridOptions.api) {
        this.gridOptions.api.hideOverlay();
      }
      this.baseConfiguration.localeText.noRowsToShow = 'No hay datos disponibles';
      console.log(`EndPoint return no data`);
      //Swal.fire('Algo salió mal', 'No se pudieron obtener elementos', 'error');
    });
  }

  ngOnDestroy(): void {
    this.$win.off(this.resizeEvent);
  }
}

<div class="content-heading">
    <div class="header-container">
        <h3>Datos fiscales</h3>
        <small>Cat&aacute;logo de datos fiscales</small>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="card card-default">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">

                            <div class="row filter-container">
                                <div class="col-sm-4">
                                    <!-- <span>Pa&iacute;s</span>
                                    <select class="form-control" [(ngModel)]="unit" name="CountryId" (ngModelChange)="changeFilterCountry()" required>
                                        <option value="0">Todos</option>
                                        <option *ngFor="let unit of parentList" [value]="unit.UnitId">{{unit.UnitDescription}}</option>
                                    </select> -->
                                </div>
                            </div>
                            <br>
                            <div>
                                <app-ceq-table #CeqGrid [gridAdditionalOptions]="gridOptions" [columnDef]="columnDefs" (apiEmitter)="gridEmiter($event)">
                                </app-ceq-table>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3 col-lg-12 col-md-12">
                        <div class="pagesize-control">
                            Tama&ntilde;o de p&aacute;gina:
                            <select (change)="onPageSizeChange($event.target.value)">
                                <option *ngFor="let size of PageSizes" [ngValue]="size">{{size}}</option>
                            </select>
                        </div>

                    </div>
                    <div class="row mt-3 col-lg-12 col-md-12">
                        <button type="button" class="mr-1 btn btn-labeled btn-outline-primary mb-2" (click)="btnAddClick()">
                            <span class="btn-label">
                                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                            </span>
                            <span>Nueva configuraci&oacute;n</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

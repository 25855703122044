import { BaseModel } from "./BaseModel.Model";

export class PurchaseModel extends BaseModel {

  constructor() {
    super();

    this.org = 0;
    this.orderId = "";
    this.providerId = 0;
    this.providerName = "";
    this.unitPurchase = 0;
    this.userPurchase = "";
    this.unitReceives = 0;
    this.userReceives = "";
    this.status = 0;
    this.statusDesc = "";
    this.totalEquipmentAmount = 0;
    this.totalFreightAmount = 0;
    this.totalEquipmentInvoiced = 0;
    this.totalFreightInvoiced = 0;
    this.equipmentAmount = "";
    this.freightAmount = "";
    this.equipmentInvoiced = "";
    this.freightInvoiced = "";
    this.deliverySchedule = "";
    this.comments = "";
    this.capitalization = "";
    this.userCapitalization = "";
    this.capexId = 0;
    this.invoice = "";
    this.invoiceCancel = false;
    this.purchaseOrder = "";
    this.receiptNumber = "";
    this.reference = "";
    this.purchasePlanningId = 0;
    this.purchaseAmount = 0;
    this.canceledInvoicing = "";

    this.deliverDay = 0;
    this.deliverDate = "";
    this.unitPurchaseDesc = "";
    this.unitReceivesDesc = "";
    this.containerId = 0;
    this.containerCode = "";
    this.containerName = "";
    this.containerDesc = "";
    this.quantity = 0;
    this.capexYear = "";
    this.capexName = "";
    this.executeYear = 0;
    this.orderIdOld = "";
    this.currencyId = 0;

    this.equipmentUuid = '';
    this.freightPurchaseOrder = '';
    this.freightReceiptNumber = '';
  }

  org: number;
  orderId: string;
  providerId: number;
  providerName: string;
  unitPurchase: number;
  userPurchase: string;
  unitReceives: number;
  userReceives: string;
  status: number;
  statusDesc: string;
  totalEquipmentAmount: number;
  totalFreightAmount: number;
  totalEquipmentInvoiced: number;
  totalFreightInvoiced: number;
  equipmentAmount: string;
  freightAmount: string;
  equipmentInvoiced: string;
  freightInvoiced: string;
  deliverySchedule: string;
  comments: string;
  capitalization: string;
  userCapitalization: string;
  capexId: number;
  invoice: string;
  invoiceCancel: boolean;
  purchaseOrder: string;
  receiptNumber: string;
  reference: string;
  purchasePlanningId: number;
  purchaseAmount: number;
  canceledInvoicing: string;

  deliverDay: number;
  deliverDate: any;
  unitPurchaseDesc: string;
  unitReceivesDesc: string;
  containerId: number;
  containerCode: string;
  containerName: string;
  containerDesc: string;
  quantity: number;
  capexYear: string;
  capexName: string;
  executeYear: number;
  orderIdOld: string;
  currencyId: number;

  equipmentUuid: string;
  freightPurchaseOrder: string;
  freightReceiptNumber: string;
}

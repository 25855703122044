import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { EquipmentLoan } from '../../common/models/EquipmentLoan.Model';
import { EquipmentIO } from '../../common/models/EquipmentIO.Model';
import { EquipmentLoanConfig } from '../../common/models/EquipmentLoanConfig.Model';
import { GenericService } from '../../common/services/GenericServices';

@Injectable({
  providedIn: 'root'
})
export class EsabMovementsService {

  constructor(private genericService: GenericService) { }

  getInitial(unitId: number, date: string){
    return this.genericService.getRequest(`esabMovements/Initial/${date}/${unitId}`,{});
  }
  getMovements(unitId: number, date: string){
    return this.genericService.getRequest(`esabMovements/${date}/${unitId}`,{});
  }

  getUserNodes(org: number, userId: number){
    return this.genericService.getRequest(`UserProfile/${org}/${userId}/all`,{});
  }

  getLoanList(ceveId: number, routeId: number, Reference: string, creationDate: string, status: string) {

   //let d =""+ creationDate.getFullYear().toString() + "-" + creationDate.getMonth().toString() + "-" + creationDate.getDate().toString();

    return this.genericService.getRequest(`EquipmentLoan/list/${ceveId}/${routeId}/${Reference}/${creationDate}/${status}`, {});
  }

  getLoanListConfig(ceveId: number, channelId : number, routeId: number) {

    //let d =""+ creationDate.getFullYear().toString() + "-" + creationDate.getMonth().toString() + "-" + creationDate.getDate().toString();

    return this.genericService.getRequest(`EquipmentLoanConfig/list/${ceveId}/${channelId}/${routeId}`, {});
  }

  CancelEquipmentLoan(Id: string)
  {
    return this.genericService.getRequest(`EquipmentLoan/cancel/${Id}`, {});
  }

  getEquipmentLoan(EquipmentLoanId: string) {
    return this.genericService.getRequest(`EquipmentLoan/${EquipmentLoanId}`, {});
  }

  getEquipmentLoanConfig(ceve: number,channel : number, route: number, retail : number) {
    return this.genericService.getRequest(`EquipmentLoanConfig/${ceve}/${channel}/${route}/${retail}`, {});
  }


  getEquipmentLoanDetail(EquipmentLoanId: string) {
    return this.genericService.getRequest(`EquipmentLoanDetails/equipmentloan/det/${EquipmentLoanId}`, {});
  }


  SaveEquipmentLoan(loan : any) {
    return this.genericService.postRequest(`EquipmentLoan/`, {},0,loan);
  }

  SaveEquipmentLoanConfig(loan: EquipmentLoanConfig) {
    return this.genericService.postRequest(`EquipmentLoanConfig/`, {}, 0, loan);
  }
  UpdateEquipmentLoanConfig(loan: EquipmentLoanConfig) {
    return this.genericService.putRequest(`EquipmentLoanConfig/`, {}, 0, loan);
  }

  RemoveEquipmentLoanConfig(ceveid: number, channelid: number, routeid: number, retailid: number) {
    return this.genericService.deleteRequest(`EquipmentLoanConfig/${ceveid}/${channelid}/${routeid}/${retailid}`, {}, 0);
    
  }


  SaveEquipmentLoanRenew(loan: EquipmentLoan) {
    return this.genericService.postRequest(`EquipmentLoan/renew`, {}, 0, loan);
  }

  SaveEquipmentLoanRecovery(loan: EquipmentLoan) {
    return this.genericService.postRequest(`EquipmentLoan/recovery`, {}, 0, loan);
  }

  getUserRoutes(unitId : number) {
    return this.genericService.getRequest(`Unit/grandchild/${unitId}`, {});
  }


  getUserChannels( unitId: number) {
    return this.genericService.getRequest(`Unit/sons/${unitId}`, {});
  }


  getEmployeeByCeve(unitId: number) {
    return this.genericService.getRequest(`EmployeeRoutes/cevedist/${unitId}`, {});
  }


  getMovementsExcel(date: string, unitId: number): Observable<any[]> {
    let url = `esabmovements/excel/${date}/${unitId}`;
    return this.genericService.getExcelRequest(url, {}, 0);
  }


  generateExcelFromCSV(equipment: EquipmentIO) {
    let escape = encodeURIComponent(equipment.comprobante);
    let escape2 = escape.replace("'", "");
    let url = `esabmovements/generateexcelfromcsv`;
    equipment.comprobante = escape2;
    return this.genericService.postExcelRequest(url, {}, 0,equipment);
  }

  getCentralTime(){
    return this.genericService.getRequest("calendar/getCentralTime",0);
  }

}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { SettingsService } from "../../../core/settings/settings.service";

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {

  constructor(private settings: SettingsService, private router: Router, private authService: MsalService) { }

   canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const loggedIn = !!this.authService.getAccount();

    if (loggedIn === false) {
      // TODO validate access to page
      this.router.navigate(['/login']);
      return false;
    }
    
    return this.ValidateAccess(state.url.toString());
     
  }  
    
  
  // valida la url vs el permiso
  async ValidateAccess (route : string) : Promise<boolean>
  {    
    var result = true; 
    var user = this.settings.getUser();
    
    result = await this.settings.getPermUserPage(user.Id,route);
    console.log("-- ValidateAccess response " + result+ " " + route+ " UserId: "+user.Id)
    if (result == false)
      this.router.navigate(['/home']);
     
     return result;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log("CanActivateClild " + childRoute + " -- " + state);
    return true;
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log("CanLoad " + route + " -- " + segments);
    return true;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { UserService } from "../../ceq/security/user/user.service";
import { toNumber } from 'lodash';
import { ProfileLevel } from '../../ceq/common/models/ProfileLevel.Model';
import { UserLogin } from '../../ceq/common/models/UserLogin.Model';
import { GenericService } from 'src/app/ceq/common/services/GenericServices';

@Injectable()
export class SettingsService {

  // event triggered when invoke changeCustomerImage
  private CustomerImageSource = new BehaviorSubject(localStorage.getItem("CustomerImage"));
  customerImage = this.CustomerImageSource.asObservable();

  //event triggered when invoke changeUserOrgId
  private OrgUnitIdSource = new BehaviorSubject(toNumber(localStorage.getItem("CurrentOrgUnitId")));
  orgUnitId = this.OrgUnitIdSource.asObservable();
  private DistributionRouteList: string[] = [];
  private app: any;
  private layout: any;
  private UserAccess: any;
  public isLogin: boolean = false;
  public _userReady: Subject<any> = new Subject<any>();
  public isLogged = this._userReady.asObservable();

  constructor(private UserService: UserService,
    private genericServ: GenericService) {
    // App Settings
    // -----------------------------------
    this.app = {
      name: 'Grupo Bimbo SA de CV',
      description: 'CEQ - Aplicación de Control de Equipo',
      version: 'v 2.2.0.Development',
      year: ((new Date()).getFullYear())
    };

    // Layout Settings
    // -----------------------------------
    this.layout = {
      isFixed: true,
      isCollapsed: false,
      isBoxed: false,
      isRTL: false,
      horizontal: false,
      isFloat: false,
      asideHover: false,
      theme: null,
      asideScrollbar: false,
      isCollapsedText: false,
      useFullLayout: false,
      hiddenFooter: false,
      offsidebarOpen: false,
      asideToggled: false,
      viewAnimation: 'ng-fadeInUp'
    };

  }

  getAppSetting(name) {
    return name ? this.app[name] : this.app;
  }

  getLayoutSetting(name) {
    return name ? this.layout[name] : this.layout;
  }

  setAppSetting(name, value) {
    if (typeof this.app[name] !== 'undefined') {
      this.app[name] = value;
    }
  }

  setLayoutSetting(name, value) {
    if (typeof this.layout[name] !== 'undefined') {
      return this.layout[name] = value;
    }
  }

  toggleLayoutSetting(name) {
    return this.setLayoutSetting(name, !this.getLayoutSetting(name));
  }

  /**
   * Regresa el usuario guardado en la caché de la sesión
   */
  getUser(): UserLogin {
    return JSON.parse(localStorage.getItem("user"));
  }

  /**
   * Guarda un User en el caché de la sesión convirtiéndolo a JSON
   * @param user
   */
  async setUser(user: UserLogin) {
    localStorage.setItem("user", JSON.stringify(user));
    //defino la imagen del usuario
    this.changeCustomerImage(JSON.stringify(user.Picture));
    return await Promise.all([
      this.setUserAccess(user.Id),
      this.setUserProfileLevels(user.Id)
    ]);
  }

  delUser() {
    localStorage.removeItem("user");
    this.delUserProfileLevels();
    this.delUserAccess();
  }

  changeCustomerImage(imageJson: string) {
    localStorage.setItem("CustomerImage", imageJson);
    this.CustomerImageSource.next(imageJson)
  }

  private setUserProfileLevels(userId: number) {
    return this.UserService.getProfileLevels(userId).subscribe(data => {
      localStorage.setItem("userProfileLevels", JSON.stringify(data));
    });
  }

  public async setUserAccess(userId: number) {
    //console.log("Antes de solicitar permiso");
    return this.UserService.getUserAccess(userId).subscribe(data => {
      // console.log("Permiso 2 : " + data);
      localStorage.setItem("userAccess", JSON.stringify(data));
      this._userReady.next(data);
    });
  }



  getUserAccess(): string[] {
    return JSON.parse(localStorage.getItem("userAccess"));
  }



  /*

    getUserAccess() :  Observable<string[]> {
    let dd: any;

     this.UserService.getMyAccess().subscribe(data => {
      console.log("getMyAccess  : " + data);
       dd = data


    });
    console.log("return getMyAccess  : " + dd);

    return JSON.parse(JSON.stringify(dd));

   }

*/


  public isOnDistributionRouteList(route_id: string) {
    let isfound: boolean = false;
    if (this.DistributionRouteList.length > 0) {
      for (let routeID of this.DistributionRouteList) {
        if (route_id == routeID) {
          isfound = true;
          break;
        }
      }
    }
    return isfound;
  }

  /**
   * @deprecated: use global-function.service.ts / GetUserProfile() instead 
   */
  async getUserProfileLevels(): Promise<ProfileLevel[]> {
    return new Promise(resolve => {
      console.log("obtengo el userprofile del api");
      this.UserService.getProfileLevels(this.getUser().Id).subscribe(data => {
        resolve(data);
      }, error => { });

    })
  }

  private delUserProfileLevels() {
    localStorage.removeItem("userProfileLevels");
  }

  public addDistributionRoute(route_id: string) {
    this.DistributionRouteList.push(route_id);
  }

  public deleteDistributionRoute(route_code: string) {

    var indeX = this.DistributionRouteList.indexOf(route_code);
    if (indeX !== -1) {
      this.DistributionRouteList.splice(indeX, 1);
    }

    //console.log("Elimine elemento " + route_code);
    //console.log(this.DistributionRouteList);

  }

  // Determina si un usuario tiene o no permisos para ver una pagina
  async getPermUserPage(userId: number, route: string): Promise<boolean> {
    return new Promise(async resolve => {

      (await this.UserService.getUserAccessPerPage(userId, route)).subscribe(data => {
        resolve(data);
      });

    });
    //return this.UserService.getUserAccessPerPage(userId,route);

  }

  async getMultiSystem(userLogin: string): Promise<number> {
    return new Promise(resolve => {

      this.UserService.getMultiSystem(userLogin).subscribe(data => {
        resolve(data);
      }, error => { });

    });
  }

  private delUserAccess() {
    localStorage.removeItem("userAccess");
  }

  changeCurrentOrgUnitId(orgUnitId: number) {
    localStorage.setItem("CurrentOrgUnitId", orgUnitId.toString());
    this.OrgUnitIdSource.next(orgUnitId)
  }

  public async toggleWidgets() {
    let flag = Boolean(localStorage.getItem("enableWidgets") == "true");
    let enableWidgetsBit: Boolean = !flag;
    let enableWidgets = JSON.stringify(enableWidgetsBit)
    localStorage.setItem("enableWidgets", enableWidgets);
  }

  /**
   * Get configuration value from key
   * @param key 
   * @returns 
   */
  getSettingsByKey(key: string): Observable<ConfigDTO> {
    return this.genericServ.getRequest(`Configuration/${key}`, {});
  }

}

export interface ConfigDTO {
  Value: string;
}

<div class="content-heading">
    <div class="header-container">
        <h3>Veh&iacute;culos de Log&iacute;stica Secundaria</h3>
        <small>Cat&aacute;logo de veh&iacute;culos</small>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="card card-default">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">

                            <div class="row filter-container">
                                <div class="col-sm-4 ng-autocomplete">

                                    <span>Centro de venta</span>
                                    <ng-autocomplete placeholder="Escribe para buscar..." [data]="ceveList" [searchKeyword]="keyword" (selected)='selectEvent($event)' (inputCleared)="cmbOutputCeve()" [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" [(ngModel)]="selectedUnit">
                                    </ng-autocomplete>
                                    <ng-template #itemTemplate let-item>
                                        <a [innerHTML]="item.CeveName"></a>
                                    </ng-template>

                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="'No se encontraron coincidencias'"></div>
                                    </ng-template>

                                </div>
                            </div>
                            <br>
                            <div>
                                <app-ceq-table #CeqGrid [gridAdditionalOptions]="gridOptions" [columnDef]="columnDefs" (apiEmitter)="gridEmiter($event)">
                                </app-ceq-table>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3 col-lg-12 col-md-12">
                        <!-- <div class="pagesize-control">
                            Tama&ntilde;o de p&aacute;gina:
                            <select (change)="onPageSizeChange($event.target.value)">
                                <option *ngFor="let size of PageSizes" [ngValue]="size">{{size}}</option>
                            </select>
                        </div> -->
                    </div>
                    <div class="row mt-3 col-lg-12 col-md-12">
                        <button type="button" class="mr-1 btn btn-labeled btn-outline-primary mb-2" (click)="btnAddClick()">
                            <span class="btn-label">
                                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                            </span>
                            <span>Nuevo veh&iacute;culo</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
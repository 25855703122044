import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from '../../common/services/GenericServices';
import { DatePipe } from '@angular/common'


@Injectable({
  providedIn: 'root'
})
export class BalanceAdjustService {

  constructor(private genericService: GenericService,public datepipe: DatePipe) { }

  getExampleExcel(): Observable<any[]> {
    return this.genericService.getExcelRequest('balanceadjust/fileexample', {}, 0);
  }

  ValidateBalanceFlowExcel(excel: any): Observable<any> {
    const dataForm = new FormData();
    dataForm.append('file-0', excel);
    return this.genericService.postRequest('balanceadjust/validatenewflow', {}, 0, dataForm);
  }

  applyNewFlow(excel: any): Observable<any> {
    const dataForm = new FormData();
    dataForm.append('file-0', excel);
    return this.genericService.postRequest(`balanceadjust/apply`, {}, 0, dataForm);
  }

  searchFlows(unitId: number, startDate: Date, endDate: Date): Observable<any[]> {
    let url = `balanceadjustdetail/${unitId}/${this.datepipe.transform(startDate,'yyyy-MM-dd')}/${this.datepipe.transform(endDate,'yyyy-MM-dd')}`;
    return this.genericService.getExcelRequest(url, {}, 0);
  }

}

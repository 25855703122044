import { Injectable } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { GenericService } from '../../common/services/GenericServices';
import { FasobareList } from '../../common/models/FasobareList.Model';

@Injectable({
  providedIn: 'root'
})
export class IncidenceptService {

  constructor(private genericServ: GenericService) { }

  /**
   * Obtiene los incidentes de un ceve
   * @param ceve 
   * @returns 
   */
  getIncidents(ceve: number, dispatchDate: string): Observable<any[]> {
    return this.genericServ.getRequest(`fasobare/fromceve/${ceve}/${dispatchDate}`, {});
  }

  /**
   * Obtiene los mensajes de las remisiones de los incidentes de un ceve
   * @param ceve 
   * @param dispatchDate 
   * @returns 
   */
  getMessages(ceve: number, dispatchDate: string): Observable<any[]> {
    return this.genericServ.getRequest(`fasobare/getmessages/${ceve}/${dispatchDate}`, {});
  }

  /**
 * Obtiene los incidentes de un ceve
 * @param ceve 
 * @returns 
 */
  getPending(ceve: number, dispatchDate: string): Observable<any[]> {
    return this.genericServ.getRequest(`fasobare/pending/list/${ceve}/${dispatchDate}`, {});
  }
  
  /**
   * Obtiene los incidentes de un ceve
   * @param ceve 
   * @returns 
   */
  getIncidentsFab(fab: number, dispatchDate: string): Observable<any[]> {
    return this.genericServ.getRequest(`fasobare/fromfab/${fab}/${dispatchDate}`, {});
  }

  /**
   * Obtiene los incidentes entre un ceve y una planta
   * @param ceve 
   * @returns 
   */
  getIncidentsAll(fab: number, ceve:number, dispatchDate: string): Observable<any[]> {
    return this.genericServ.getRequest(`fasobare/fc/${fab}/${ceve}/${dispatchDate}`, {});
  }

  /**
   * Obtiene la relación de remisiones y etiquetas de un ceve
   * @param ceve 
   * @returns 
   */
  getRemLabels(ceve: number): Observable<any[]> {
    return this.genericServ.getRequest(`fasobare/remlabel/${ceve}`, {});
  }

  /**
   * Guarda los cambios para los incidentes de rechazos
   * @param ceve 
   * @returns 
   */
  saveIncidents(incidentList: FasobareList[]): Observable<any[]> {
    return this.genericServ.postRequest(`fasobare/sendtoauthorize`, {},0,incidentList);
  }

  /**
   * Guarda los cambios para los incidentes de rechazos
   * @param ceve 
   * @returns 
   */
  processAuthorization(incidentList: FasobareList[]): Observable<any[]> {
    return this.genericServ.postRequest(`fasobare/processauthorization`, {},0,incidentList);
  }

  /**
   * Descarga reporte de Rechazos pendientes
   * @returns 
   */
  getRejectExcel(): Observable<any[]> {
    let url = `report/rejectpending`;
    return this.genericServ.getExcelRequest(url, {}, 0);
  }

  /**
   * Descarga la plantilla de Rechazos
   * @returns 
   */
  getRejectLoadTemplate(): Observable<any[]> {
    return this.genericServ.getExcelRequest(`fasobare/rejectloadtemplate`, {});
  }

  /**
   * Sube la plantilla de rechazos al sistema
   */
  processFile(file: any):Observable<any>{
    const dataForm = new FormData();
    dataForm.append('file-0', file);
    return this.genericServ.postRequest('fasobare/appendrejects', {}, 0,dataForm);
  }

  /**
   * Obtiene un archivo de Excel con los datos complementarios pendientes
   * @param ceve 
   * @param dispatchDate 
   * @returns 
   */
  getRejectExcelComplementary(ceve:number ,dispatchDate: string): Observable<any[]>{
    return this.genericServ.getExcelRequest(`report/DataComplementaryReceived/${ceve}/${dispatchDate}`, {},0);
  }

  /**
   * Obtiene le número de datos compemenatrios pendientes de enviar a autorización
   * @param ceveid 
   * @returns 
   */
  hasDataComplementaryPending(ceveid: number): Observable<any>{
    return this.genericServ.getRequest(`fasobare/hasdatapending/${ceveid}`, {}, 0);
  }

  /** */
  hasFSBRRemissionPending(): Observable<any> {
    return this.genericServ.getRequest(`fasobare/hasremissionpending`, {}, 0);
  }

  /**
   * Valida los servicios que puede recibir un ceve
   * @param ceveid 
   * @returns 
   */
  checkLoadData(ceveid: number): Observable<any>{
    return this.genericServ.getRequest(`fasobare/checkservices/${ceveid}`, {}, 0);
  }

  /**
   * Envia el listado de datos complementarios a marcar como eliminados
   * @param incidentList 
   * @returns 
   */
  deleteIncidents(incidentList: FasobareList[]): Observable<any[]> {
    return this.genericServ.postRequest(`fasobare/logicaldelete`, {},0,incidentList);
  }
}

import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CeqTableComponent } from '../../common/ceq-table/ceq-table.component';
import { DeleteChildRendered } from '../../common/renders/delete-child-rendered';
import { EditChildRendered } from '../../common/renders/edit-child-rendered';
import { GridOptions } from 'ag-grid-community';
import { SettingsService } from '../../../core/settings/settings.service';
import { UserLogin } from '../../common/models/UserLogin.Model';
import { ReportsService } from '../reports.service';
import { List } from 'lodash';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-events-log',
  templateUrl: './events-log.component.html',
  styleUrls: ['./events-log.component.scss'],
})
export class EventsLogComponent implements OnInit {
  constructor(
    private reportService: ReportsService,
    private settings: SettingsService
  ) {}

  @ViewChild('CeqGrid', { static: false }) ceqGrid: CeqTableComponent;
  datepipe: DatePipe = new DatePipe('en-US');
  actualDate: Date;
  selectedDate: string = '-';
  selectedEvent: string = '';
  user: UserLogin;
  eventList: List<string> = [];
  eventSelected: string = '-';
  keyword: string = '';
  pageSelected: number = 10;

  options: GridOptions;
  PageSizes = [10, 25, 50, 100, 1000, 10000];

  gridOptions = {
    context: this,
    frameworkComponents: {
      childEditRenderer: EditChildRendered,
      childDeleteMessageRenderer: DeleteChildRendered,
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) {},
    onCellClicked(event: any) {
      if(event.colDef.field == 'LogMessage'){
        Swal.fire('Mensaje',event.value)
      }
    },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    }
  };

  columnDefs = [
    {
      headerName: 'Fecha',
      field: 'LogTime',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      unSortIcon: true,
      sort: 'desc',
      resizable: true,
      cellRenderer: (params: any) => moment(params.data.LogTime).format('DD/MM/yyyy HH:mm a')
    },
    {
      headerName: 'Tipo',
      field: 'LogEvent',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      resizable: true,
      unSortIcon: true
    },
    {
      headerName: 'Estado',
      field: 'LogStatus',
      width: 5,
      cellClass: 'align-start',
      resizable: true,
      editable: false
    },
    {
      headerName: 'Mensaje',
      field: 'LogMessage',
      width: 100,
      cellClass: 'align-start',
      resizable: true,
      editable: false
    },
    {
      headerName: 'Usuario',
      field: 'CreatedBy',
      width: 10,
      cellClass: 'align-start',
      resizable: true,
      editable: false
    }
  ];

  ngOnInit(): void {
    this.actualDate = new Date();
    this.user = this.settings.getUser();
    this.selectedDate = this.datepipe.transform(this.actualDate, 'yyyy-MM-dd');
    this.getEventList();
  }

  selectEvent(event: any) {
    if (event != null) {
      this.eventSelected = event;
    }
    this.ceqGrid.endPoint =
      `log/${this.user.Org}/${this.selectedDate}/${this.eventSelected}/event?$top=${this.pageSelected}&$orderby=LogTime desc`;
    this.ceqGrid.refreshTable();
  }

  onPageSizeChange(value: any){
    this.pageSelected = value;
    this.selectEvent(null);
  }

  getEventList() {
    this.reportService.getEventList(this.user.Org).subscribe((x) => {
      this.eventList = x;
    });
  }

  gridEmiter(GridOptions: GridOptions) {
    this.options = GridOptions;
    this.ceqGrid.endPoint =
    `log/${this.user.Org}/${this.selectedDate}/-/event?$top=${this.pageSelected}&$orderby=LogTime desc`;
    this.ceqGrid.refreshTable();
  }

  clearEvent(){
    this.eventSelected = '-';
    this.selectEvent(null);
  }
}

import { BaseModel } from "./BaseModel.Model";

export class ProviderModel extends BaseModel {

  constructor() {
    super();
    this.org = 0;
    this.providerId = 0;
    this.providerName = "";
    this.providerNIT = "";
    this.providerActive = false;
  }

  org: number;
  providerId: number;
  providerName: string;
  providerNIT: string;
  providerActive: boolean;
}

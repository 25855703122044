<!---------------------------------------------------------------------------->
<div class="content-wrapper">

  <div class="card b mb-2">
    <div class="card-header bb">
      <h4 class="card-title">Detalle de Veh&iacute;culo LS</h4>
    </div>    
  </div>

  <form #Form="ngForm" (ngSubmit)="formSubmit(Form.value)">
    <div class="form-container">
      <div class="row">

        <div class="form-group col-sm-4">
          <label class="form-label">Unidad</label>
          <ng-autocomplete placeholder="Escriba para búscar" [data]="units" [searchKeyword]="searchUnitField" (selected)='changeUnit($event)' [itemTemplate]="unitOutputTemplate" [notFoundTemplate]="notFoundoutputTemplate" [(ngModel)]="selectedUnit" name="selectedUnit" [disabled]="!isNew">
          </ng-autocomplete>
          <ng-template #unitOutputTemplate let-item>
            <a [innerHTML]="item.UnitDescription"></a>
          </ng-template>

          <ng-template #notFoundoutputTemplate let-notFound>
            <div [innerHTML]="'No se encontrarón coincidencias'"></div>
          </ng-template>
        </div>

        <div class="form-group col-sm-4">
          <label class="form-label">Empleado</label>
          <ng-autocomplete placeholder="Escriba para búscar" [data]="employees" [searchKeyword]="searchEmployeeField" (selected)='changeEmployee($event)' [itemTemplate]="employeeOutputTemplate" [notFoundTemplate]="notFoundoutputTemplate" [(ngModel)]="selectedEmployee" name="selectedEmployee" [disabled]="!isNew">
          </ng-autocomplete>
          <ng-template #employeeOutputTemplate let-item>
            <a [innerHTML]="item.EmployeeName"></a>
          </ng-template>

        </div>

        <div class="form-group col-sm-4">
          <label class="form-label">Placas</label>
          <input class="form-control" type="text" value="" placeholder="Placas" [(ngModel)]="carrier.LicensePlate" name="LicensePlate" required maxlength="100" />
        </div>

        <div class="form-group col-sm-4">
          <label class="form-label">Configuraci&oacute;n</label>
          <input class="form-control" type="text" value="" placeholder="Configuración C2 o VL" [(ngModel)]="carrier.VehicleConfiguration" name="VehicleConfiguration" required maxlength="100" />
        </div>

        <div class="form-group col-sm-4">
          <label class="form-label">Sub tipo</label>
          <input class="form-control" type="text" value="" placeholder="Sub tipo" [(ngModel)]="carrier.SubTipoTrailer" name="SubTipoTrailer" required maxlength="100" />
        </div>

        <div class="form-group col-sm-4">
          <label class="form-label">A&ntilde;o de modelo</label>
          <input class="form-control"
                 type="number" 
                 value="" 
                 placeholder="Año de modelo" 
                 [(ngModel)]="carrier.ModelYear" 
                 name="ModelYear" 
                 required 
                 maxlength="100" 
                 onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
        </div>

        <div class="form-group col-sm-4">
          <label class="form-label">Permiso SCT</label>
          <input class="form-control" type="text" value="" placeholder="Permiso SCT" [(ngModel)]="carrier.SCTPermit" name="SCTPermit" required maxlength="100" />
        </div>

        <div class="form-group col-sm-4">
          <label class="form-label">N&uacute;mero SCT</label>
          <input class="form-control"
                 type="number"
                 value=""
                 placeholder="Numero SCT"
                 [(ngModel)]="carrier.NumberSCTPermit"
                 name="NumberSCTPermit"
                 required
                 maxlength="100"
                 onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
        </div>

        <div class="form-group col-sm-4">
          <label class="form-label">Seguro</label>
          <input class="form-control" type="text" value="" placeholder="Seguro" [(ngModel)]="carrier.Insurance" name="Insurance" required maxlength="100" />
        </div>

        <div class="form-group col-sm-4">
          <label class="form-label">Poliza de Seguro</label>
          <input class="form-control" type="text" value="" placeholder="Poliza de Seguro" [(ngModel)]="carrier.InsurancePolicy" name="InsurancePolicy" required maxlength="100" />
        </div>


      </div>
      <br /><br />
      <div class="buttons-container">
        <button type="submit" class="mr-1 btn btn-labeled btn-outline-primary mb-2" [disabled]="!Form.valid">
          <span class="btn-label">
            <i class="fa fa-save"></i>
          </span>
          <span>Guardar</span>
        </button>
        &nbsp;&nbsp;&nbsp;
        <button type="button" class="mr-1 btn btn-labeled btn-outline-danger mb-2" (click)="btnCancelOnClick()">
          <span class="btn-label">
            <i class="fa fa-chevron-circle-left"></i>
          </span>
          <span>Salir</span>
        </button>
        
      </div>
    </div>
  </form>

</div>
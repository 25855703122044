import { Component, OnInit, ViewChild } from '@angular/core';
import { CeqTableComponent } from '../../common/ceq-table/ceq-table.component';
import { DeleteChildRendered } from '../../common/renders/delete-child-rendered';
import { EditChildRendered } from '../../common/renders/edit-child-rendered';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import Swal from 'sweetalert2';

import { CustomerBalanceService } from '../../self-service/balance/customer-balance.service';
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';
import { ContainerTypeService} from '../../catalog/container/container-type/container-type.service'
import { InventoryService } from '../inventory.service'
import { SettingsService } from '../../../core/settings/settings.service';

import { ContainerType } from '../../common/models/ContainerType.Model';
import { Inventory } from '../../common/models/Inventory.Model';
import { UserLogin } from '../../common/models/UserLogin.Model';
import { Ceve } from '../../common/models/Ceve.Model';
import { Unit } from '../../common/models/Unit.Model';

@Component({
  selector: 'app-noticemld',
  templateUrl: './noticemld.component.html',
  styleUrls: ['./noticemld.component.scss']
})
export class NoticemldComponent implements OnInit {

  constructor(private router: Router,
    private noticeService: InventoryService, private settings: SettingsService,
    private globalService: GlobalFunctionsService,
    private ctService : ContainerTypeService,
    private customerBalanceService: CustomerBalanceService) { }

  @ViewChild('CeqGrid', { static: false }) ceqGrid: CeqTableComponent;
  minDate: string;
  maxDate: string;
  currentDate: Date;
  date: string;
  inventoryId: number;
  levelList: Unit[];
  ContainerTypeList: ContainerType[];
  initialData: any[] = [];
  org: number;
  inputArgument = 'name';
  outputData: any[] = [];
  isLoadingItemsOutput: boolean;
  initialInputName: number;
  initialOutputName: string;
  userLogin: UserLogin;
  level: number;
  ceveList: Ceve[];
  orgunit: number;
  containerTypeId: number;
  unit: number;
  pageSelected: number = 50;
  selectedUnit: string;

  options: GridOptions;
  PageSizes = [50, 100, 1000];

  gridOptions = {
    context: this,
    frameworkComponents: {
      childEditRenderer: EditChildRendered,
      childDeleteMessageRenderer: DeleteChildRendered
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) { },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    }
  }

  columnDefs = [
    {
      field: 'Container.ContainerId',
      hide: true,
      sort: 'asc',
    },
    {
      headerName: 'Código',
      field: 'Container.ContainerCode',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      unSortIcon: true
    },
    {
      headerName: 'Nombre',
      field: 'Container.ContainerName',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      unSortIcon: true
    },
    {
      headerName: 'Tipo',
      field: 'ContainerTypeDescription',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      unSortIcon: true
    },
   
    {
      headerName: 'Inicial',
      field: 'Initial',
      width: 20,
      cellClass: 'align-right',
      editable: false

    },
    {
      headerName: 'Entradas',
      field: 'Input',
      width: 20,
      cellClass: 'align-start',
      editable: false
    },

    {
      headerName: 'Salidas',
      field: 'Output',
      width: 20,
      cellClass: 'align-start',
      editable: false
    },
    {
      headerName: 'Disponible',
      field: 'Available',
      width: 20,
      cellClass: 'align-start',
      editable: false
    }
  ];

  async ngOnInit() {
    this.userLogin = this.settings.getUser();
    this.org = this.settings.getUser().Org;
    await this.getContainerTypelList();
    await this.getUserCeves();
    await this.getLevelList();
    await this.getOutputByArgument("");
    this.currentDate = new Date();
    this.minDate = this.globalService.getStringDate(this.currentDate, true, 0);
    this.maxDate = this.globalService.getStringDate(this.currentDate, false, 0);
    this.date = this.maxDate;
    await this.updateTable();
  }

  gridEmiter(GridOptions: GridOptions) {
    this.options = GridOptions;
  }

  onPageSizeChange(value: number) {
    this.pageSelected = value;
    this.updateTable();
  }

  getManagement() {   
    this.updateTable();
  }


  async getLevelList() {
    await this.noticeService.getLevelList(this.org).toPromise().then((resp: Unit[]) => {
      this.levelList = resp;
      const orgAll: Unit = new Unit();
      if (this.levelList !== null) {
        this.levelList.unshift(orgAll);
      }
    }, err => {
      Swal.fire({
        icon: 'warning',
        text: 'Error al obtener lista de niveles, verifique su conexión a internet',
      });
    });
  }


  async getContainerTypelList() {
    await this.ctService.getContainerType().toPromise().then((resp: ContainerType[]) => {
      this.ContainerTypeList = resp;
      this.containerTypeId = resp[0].ContainerTypeId;
      
    }, err => {
      Swal.fire({
        icon: 'warning',
        text: 'Error al obtener lista de niveles, verifique su conexión a internet',
      });
    });
  }

  changeFilterLevel(event: any): void {
    this.orgunit = event;
    if (event === '0') {
      this.ceqGrid.data = Array.from(this.initialData);
    } else {
      this.getInventoryByNode(this.orgunit);
    }
  }

  async getInventoryByNode(orgUnit: number) {
    await this.noticeService.getInventoryByNode(orgUnit).toPromise().then((inventory: Inventory[]) => {
      this.ceqGrid.data = inventory;
    });

  }
  /**
   * Triggers at change input select and sets outputData empty
   *
   * @memberof RemissionComponent
   */
  cmbOutputChange(): void {
    this.inventoryId = undefined;
    this.initialInputName = undefined;
  }

  selectOutputEvent(item: any): void {
    this.inventoryId = item.id;
    //this.changeFilterLevel(item.id);
    console.log(item.id);
    if (this.inventoryId != undefined) {
      this.updateTable();
    }
  }

  /**
   * Invokes getCeveListByArgument from remissionService and creates a new list with UnitId and code-name of entity, removes the entity in use from intput
   *
   * @param {*} item
   * @memberof RemissionComponent
   */
  async getOutputByArgument(item: any) {
    this.isLoadingItemsOutput = true;
    await this.noticeService.getLevelListByArgument(this.org, item).toPromise().then((ceveList: Unit[]) => {
      this.selectedUnit = ceveList.find(x => x.UnitId == this.userLogin.UnitId).UnitDescription;
      this.inventoryId = this.userLogin.UnitId;
      this.outputData = ceveList.map(ceve => {
        return {
          id: ceve.UnitId,
          name: `${ceve.UnitDescription}`
        }
      });
      this.isLoadingItemsOutput = false;
    }, err => {
      this.isLoadingItemsOutput = false;
    });
  }

  async updateTable() {
    if (this.minDate == undefined) {
      Swal.fire({
        icon: 'warning',
        text: 'Seleccione una fecha de inicio',
      });
      return;
    }
    if (this.maxDate == undefined) {
      Swal.fire({
        icon: 'warning',
        text: 'Seleccione una fecha final',
      });
      return;
    }
    if (this.inventoryId == undefined) {
      Swal.fire({
        icon: 'warning',
        text: 'Seleccione un Nivel',
      });
      return;
    }
    if (this.containerTypeId == undefined) {
      Swal.fire({
        icon: 'warning',
        text: 'Seleccione un tipo',
      });
      return;
    }
    if (new Date(this.minDate) > new Date(this.maxDate)) {
      this.maxDate = this.minDate;
      Swal.fire({
        icon: 'warning',
        text: 'La fecha inicial no puede ser mayor a la fecha final',
      });
    }

    this.ceqGrid.endPoint =
      `inventory/date/${this.inventoryId}/${this.minDate}/${this.maxDate}?$filter=ContainerTypeId eq ${this.containerTypeId}&$orderby=ConteinerId&$top=${this.pageSelected}`;
    console.log(this.ceqGrid.data);
    this.ceqGrid.refreshTable();
  }

  async getUserCeves() {
    await this.customerBalanceService.getCevesByUser(this.userLogin.Id).toPromise().then((ceves: Ceve[]) => {
      this.ceveList = ceves;
    });
  }

}

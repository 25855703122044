<!---------------------------------------------------------------------------->
<div class="content-wrapper">

  <div class="card b mb-2">
    <div class="card-header bb">
      <h4 class="card-title">Detalle de C&oacute;digo Postal</h4>
    </div>    
  </div>


    <form #Form="ngForm" (ngSubmit)="formSubmit(Form.value)">
      <div class="form-container">
        <div class="row">

          <div class="form-group col-sm-4">
            <label class="form-label">C&oacute;digo</label>
            <input class="form-control" type="text" value="" placeholder="C&oacute;digo" [(ngModel)]="zipCode.IdZipCode" name="IdZipCode" required maxlength="10" [disabled]="!isNew" />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-sm-4">
            <label class="form-label">C&oacute;digo de estado</label>
            <input class="form-control" type="text" value="" placeholder="Valor" [(ngModel)]="zipCode.StateCode" name="StateCode" required maxlength="100" />
          </div>
          <div class="form-group col-sm-4">
            <label class="form-label">Descripci&oacute;n de estado</label>
            <input class="form-control" type="text" value="" placeholder="Valor" [(ngModel)]="zipCode.StateDescription" name="StateDescription" required maxlength="100" />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-sm-4">
            <label class="form-label">C&oacute;digo de ciudad</label>
            <input class="form-control" type="text" value="" placeholder="Valor" [(ngModel)]="zipCode.CityCode" name="CityCode" required maxlength="100" />
          </div>
          <div class="form-group col-sm-4">
            <label class="form-label">Descripci&oacute;n de ciudad</label>
            <input class="form-control" type="text" value="" placeholder="Valor" [(ngModel)]="zipCode.CityDescription" name="CityDescription" required maxlength="100" />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-sm-4">
            <label class="form-label">C&oacute;digo de colonia</label>
            <input class="form-control" type="text" value="" placeholder="Valor" [(ngModel)]="zipCode.NeighborhoodCode" name="NeighborhoodCode" required maxlength="100" />
          </div>
          <div class="form-group col-sm-4">
            <label class="form-label">Descripci&oacute;n de colonia</label>
            <input class="form-control" type="text" value="" placeholder="Valor" [(ngModel)]="zipCode.NeighborhoodName" name="NeighborhoodName" required maxlength="100" />
          </div>
        </div>
        <br /><br />
              <div class="buttons-container">
                <button type="submit" class="mr-1 btn btn-labeled btn-outline-primary mb-2" [disabled]="!Form.valid">
                  <span class="btn-label">
                    <i class="fa fa-save"></i>
                  </span>
                  <span>Guardar</span>
                </button>
                &nbsp;&nbsp;
                <button type="button" class="mr-1 btn btn-labeled btn-outline-primary mb-2" (click)="btnCancelOnClick()">
                  <span class="btn-label">
                    <i class="fa fa-chevron-circle-left"></i>
                  </span>
                  <span>Volver</span>
                </button>
              </div>
              
      </div>
    </form>

</div>

<div class="content-heading">
    <div class="header-Container">
        <h3>Saldo de Rutas</h3>
        <small>Rutas Multicanal</small>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="card card-default">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- start filters -->
                            <div class="row">
                                <div class="form-group col-lg-4 col-md-6 col-sm-12">
                                    <label class="form-label">Centro de ventas</label>
                                    <ng-autocomplete class=""
                                                     [initialValue]=""
                                                     [data]="units"
                                                     [disabled]="working"
                                                     [searchKeyword]="'UnitDescription'"
                                                     [itemTemplate]="itemTemplate"
                                                     [notFoundTemplate]="notFoundTemplate"
                                                     [minQueryLength]="3"
                                                     [debounceTime]="600"
                                                     [(ngModel)]="selectedUnit"
                                                     (inputCleared)="onUnitClear()"
                                                     (selected)="onUnitSelected($event)"
                                                     [placeholder]="'Seleccione una opción'">
                                    </ng-autocomplete>
                                    <ng-template #itemTemplate let-item>
                                        <a [innerHTML]="item.UnitDescription"></a>
                                    </ng-template>
                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="'No se encontraron coincidencias'"></div>
                                    </ng-template>
                                </div>

                                <div class="form-group col-lg-4 col-md-6 col-sm-12">
                                    <label class="form-label">Canal</label>
                                    <select class="form-control"
                                            [(ngModel)]="channelId"
                                            (change)="onChannelSelected($event.target.value)"
                                            [disabled]="working || units.length === 0 || !selectedUnit">
                                        <option [value]="undefined" disabled *ngIf="channels.length === 0">
                                             Centro de ventas sin canales asignados
                                        </option>
                                        <option *ngFor="let channel of channels" [value]="channel.UnitId">
                                            {{channel.ChannelCode}} - {{channel.ChannelName}}
                                        </option>
                                    </select>
                                </div>

                                <div class="form-group col-lg-4 col-md-6 col-sm-12">
                                    <label class="form-label">Ruta</label>
                                    <select class="form-control"
                                            [(ngModel)]="routeId"
                                            (change)="onRouteSelected($event.target.value)"
                                            [disabled]="working || units.length === 0 || !selectedUnit">
                                        <option [value]="undefined" disabled *ngIf="routes.length===0">
                                            Canal sin rutas
                                        </option>
                                        <option *ngFor="let route of routes" [value]="route.RouteId">
                                            {{ route.RouteName }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-lg-3 col-md-6 col-sm-12">
                                    <label class="form-label">Equipo</label>
                                    <app-ceq-multiselect [Items]="containerTypeList"
                                                         (shareCheckedList)="onRemissionTypeFilterChange($event)"
                                                         (shareIndividualCheckedList)="onRemissionTypeItemCheckedChange($event)"
                                                         [disabled]="working || units.length === 0 || !selectedUnit"></app-ceq-multiselect>
                                </div>

                                <div class="form-group col-lg-2 col-md-6 col-sm-12">
                                    <label class="form-label">Fecha</label>
                                    <input class="form-control"
                                           type="date"
                                           [(ngModel)]="selectedDate"
                                           max="{{ maxDate }}"
                                           (change)="onDateSelected()"
                                           [disabled]="working || units.length === 0 || !selectedUnit" />
                                </div>

                            </div>
                            <!-- end filters -->

                            <!-- start cifras de control -->
                            <h4 *ngIf="routeId>0 && units.length !== 0">
                                C&oacute;digo de ruta: {{ routeCode }}
                            </h4>

                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <table class="table table-sm table-bordered table-responsive" border="1">
                                        <thead class="background-nav-color">
                                            <tr>
                                                <th scope="col" class="text-center align-middle">Inventario del d&iacute;a anterior</th>
                                                <th scope="col" class="text-center align-middle">Cargo total</th>
                                                <th scope="col" class="text-center align-middle">Recolección f&iacute;sica total</th>
                                                <th scope="col" class="text-center align-middle">Recuperaci&oacute;n de vendedor total</th>
                                                <th scope="col" class="text-center align-middle">Vale a vendedor total</th>
                                                <th scope="col" class="text-center align-middle">Recuperaci&oacute;n de vale total</th>
                                                <th scope="col" class="text-center align-middle">No controlado total</th>
                                                <th scope="col" class="text-center align-middle">Inventario de cliente total</th>
                                                <th scope="col" class="text-center align-middle">Validaci&oacute;n</th>
                                            </tr>
                                        </thead>
                                        <tbody class>
                                            <tr class="">

                                                <td class="p-0 text-right ">
                                                    <p class="bg-gray mb-0" *ngIf="working || units.length === 0 || !selectedUnit">{{resume.inventario_dia_anterior }}</p>
                                                    <p class="mb-0" *ngIf="!working && units.length > 0 && selectedUnit">{{resume.inventario_dia_anterior }}</p>
                                                </td>
                                                <td class="p-0 text-right">
                                                    <p class="bg-gray mb-0" *ngIf="working || units.length === 0 || !selectedUnit">{{resume.cargo }}</p>
                                                    <p class="mb-0" *ngIf="!working && units.length > 0 && selectedUnit">{{resume.cargo }}</p>
                                                </td>
                                                <td class="p-0 text-right">
                                                    <p class="bg-gray mb-0" *ngIf="working || units.length === 0 || !selectedUnit">{{resume.recoleccion_fisica }}</p>
                                                    <p class="mb-0" *ngIf="!working && units.length > 0 && selectedUnit">{{resume.recoleccion_fisica }}</p>
                                                </td>
                                                <td class="p-0 text-right">
                                                    <p class="bg-gray mb-0" *ngIf="working || units.length === 0 || !selectedUnit">{{resume.recuperacion_vendedor }}</p>
                                                    <p class="mb-0" *ngIf="!working && units.length > 0 && selectedUnit">{{resume.recuperacion_vendedor }}</p>
                                                </td>
                                                <td class="p-0 text-right">
                                                    <p class="bg-gray mb-0" *ngIf="working || units.length === 0 || !selectedUnit">{{resume.vale_vendedor }}</p>
                                                    <p class="mb-0" *ngIf="!working && units.length > 0 && selectedUnit">{{resume.vale_vendedor }}</p>
                                                </td>
                                                <td class="p-0 text-right">
                                                    <p class="bg-gray mb-0" *ngIf="working || units.length === 0 || !selectedUnit">{{resume.recuperacion_vale }}</p>
                                                    <p class="mb-0" *ngIf="!working && units.length > 0 && selectedUnit">{{resume.recuperacion_vale }}</p>
                                                </td>
                                                <td class="p-0 text-right">
                                                    <p class="bg-gray mb-0" *ngIf="working || units.length === 0 || !selectedUnit">{{resume.no_controlado }}</p>
                                                    <p class="mb-0" *ngIf="!working && units.length > 0 && selectedUnit">{{resume.no_controlado }}</p>
                                                </td>
                                                <td class="p-0 text-right">
                                                    <p class="bg-gray mb-0" *ngIf="working || units.length === 0 || !selectedUnit">{{resume.inventario_cliente }}</p>
                                                    <p class="mb-0" *ngIf="!working && units.length > 0 && selectedUnit">{{resume.inventario_cliente }}</p>
                                                </td>
                                                <td class="p-0 text-right" *ngIf="resalta" bgcolor="red">{{validation}}</td>
                                                <td class="p-0 text-right" *ngIf="!resalta">{{validation}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- end cifras de control -->
                            <br />
                            <!-- start table -->
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <app-ceq-table [gridAdditionalOptions]="listOptions"
                                                   [columnDef]="columnDefs"
                                                   (apiEmitter)="listEmiter($event)"
                                                   #ceqGrid>
                                    </app-ceq-table>
                                </div>
                            </div>
                            <!-- end table  -->

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<app-route-balance-report *ngIf="routeBalanceReportActivitty"></app-route-balance-report>
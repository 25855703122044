<!-- start header -->
<div class="content-heading">
  <div class="header-container">
    <h3>Consulta de Flujos</h3>
    <small>Posici&oacute;n entre nodos</small>
  </div>
</div>
<!-- end header -->

<!-- start page -->
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <!-- filters -->
              <!-- start filter templates  -->
              <ng-template #itemOutputTemplate let-item>
                <a [innerHTML]="item.name"></a>
              </ng-template>

              <ng-template #notFoundoutputTemplate let-notFound>
                <div [innerHTML]="'No se encontraron coincidencias'"></div>
              </ng-template>
              <!-- end filter templates  -->

              <div class="row col-12">
                <div class="col-lg-5 col-md-6 col-sm-12 ">
                  <div class="form-group">
                    <label class="form-label">Nodo A</label>
                    <ng-autocomplete placeholder="Búscar nodo A"
                                     [data]="nodesA"
                                     [searchKeyword]="nameNode"
                                     (selected)='changeFilterLevel($event)'
                                     [itemTemplate]="itemOutputTemplate"
                                     [notFoundTemplate]="notFoundoutputTemplate"
                                     (inputCleared)="clearNodeA()">
                    </ng-autocomplete>
                  </div>
                </div>

                <div class="col-lg-5 col-md-6 col-sm-12 ">
                  <div class="form-group">
                    <label class="form-label">Nodo B</label>
                    <ng-autocomplete placeholder="Búscar nodo B"
                                     [data]="nodesB"
                                     [searchKeyword]="nameNode"
                                     (selected)='changeFilterLevelB($event)'
                                     [itemTemplate]="itemOutputTemplate"
                                     [notFoundTemplate]="notFoundoutputTemplate"
                                     (inputCleared)="clearNodeB()">
                    </ng-autocomplete>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-12 order-xl-4 order-lg-3">
                  <div class="form-group">
                    <label class="form-label">
                      Fecha y Hora inicial
                    </label>
                    <input type="datetime-local"
                           class="form-control"
                           (change)="balInitialDateChange()"
                           step="1"
                           [(ngModel)]="balInitialDate">
                  </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-12 order-xl-5 order-lg-4">
                  <div class="form-group">
                    <label class="form-label">
                      Fecha y Hora final
                    </label>
                    <input type="datetime-local"
                           class="form-control"
                           step="1"
                           (change)="balFinalDateChange()"
                           [(ngModel)]="balFinalDate">
                  </div>
                </div>

                <!-- refresh buton -->
                <div class="col-xl-2 col-lg-4 col-md-12 order-xl-3 order-lg-5">
                  <div class="form-group d-flex align-items-center h-100">
                    <button type="button" class="btn btn-labeled btn-outline-primary mb-2" (click)="refreshBalance()">
                      <span class="btn-label">
                        <i class="fa fa-refresh" aria-hidden="true"></i>
                      </span>
                      <span>Actualizar Flujos</span>
                    </button>
                  </div>
                </div>
                <!-- end refresh button -->

              </div>

              <!-- end  filters -->

              <!-- start balance -->
              <br>
              <div class="row" *ngIf=" selectedLevelA && selectedLevelB ">
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <div class="header-container" style="color:#929292">
                    <h4>Flujo entre nodos</h4>
                    <span>Positivos a favor de {{selectedLevelA.UnitDescription}}</span>
                    <br>
                    <span>Negativos a favor de {{selectedLevelB.UnitDescription}}</span>
                  </div>
                </div>
                <div class="col-xl-3 offset-xl-3  col-lg-5 offset-lg-1 col-md-12 col-sm-12" *ngIf="ResetBalanceDate">
                  <div class="alert alert-primary" role="alert" style="background-color: #1a0045;">
                    <h4 class="alert-heading text-center"> Fecha de &uacute;ltimo reinicio de Flujos</h4>
                    <h4>{{ResetBalanceDate}}</h4>
                  </div>
                </div>
              </div>

              <br *ngIf="balance || loading">
              <div style="display: flex; justify-content: center; align-items: center;" *ngIf="loading">
                <i class="fa fa-circle-o-notch fa-spin fa-2x"></i>&nbsp;&nbsp;&nbsp;<span>Cargando flujo entre nodos</span>
              </div>
              <div *ngIf="balance && selectedLevelA && selectedLevelB && !loading">
                <div class="row">
                  <div class=" col-12">
                    <div class="d-flex justify-content-center" style="color:#929292" *ngIf="balance.length === 0">
                      <div class="header-container">
                        <h3>No existe flujo entre los nodos seleccionados</h3>
                      </div>
                    </div>

                    <div class="cell-container" *ngIf="balance.length > 0">
                      <div class="cell" style="border: none;">
                        <div class="cell-header" style="background-color: transparent;"></div>
                        <div class="cell-header justify-content-start bg-transparent">Flujo Historico</div>
                      </div>
                      <div class="cell" *ngFor="let balance of balance">
                        <div class="cell-header"><b>{{getContainerCode(balance.ConteinerId)}}</b></div>
                        <div class="cell-info">{{balance.Balance}}</div>
                      </div>
                    </div>

                    <div class="cell-container" *ngIf="balance.length > 0 && rangeBalance.length > 0">
                      <div class="cell" style="border: none;">
                        <div class="cell-header justify-content-start bg-transparent">Flujo Rango</div>
                      </div>
                      <div class="cell" *ngFor="let balance of rangeBalance">
                        <div class="cell-info">{{balance.Balance}}</div>
                      </div>
                    </div>


                  </div>
                </div>
                <!-- start loading range -->
                <div *ngIf="!loading && loadingRange">
                  <br>
                  <div style="display: flex; justify-content: center; align-items: center;">
                    <i class="fa fa-circle-o-notch fa-spin fa-2x"></i>&nbsp;&nbsp;&nbsp;<span>Cargando flujo por rango entre nodos</span>
                  </div>
                  <br>
                </div>
                <!-- end loading range -->

                <br>
                <div class="row">
                  <div class="col">
                    <i>
                      <i class="fas fa-info-circle text-primary"> </i>
                      El flujo mostrado se constituye por: remisiones, faltantes, sobrantes y traspasos generados entre ambos nodos. Para ver detalles, consultar reporte de Movimientos<br />
                      Puede estar afectado por ajustes planificados (reseteos, acumulados, otros). Para más información, solicitar a ON.
                    </i>
                  </div>
                </div>
              </div>

              <!-- end balance -->


              <!-- consulta de remisiones -->
              <hr>
              <div class="d-flex justify-content-center" style="color:#929292" *ngIf="balance">
                <div class="header-container">
                  <h3>Consulta de Movimientos</h3>
                </div>
              </div>
              <div class="row" *ngIf="balance">

                <div class="col-lg-4 col-md-6 col-sm-12 ">
                  <div class="form-group">
                    <label class="form-label">
                      Fecha y Hora inicial
                    </label>
                    <input type="datetime-local"
                           class="form-control"
                           step="1"
                           (change)="movInitialDateChange()"
                           [(ngModel)]="movInitialDate">
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-12 ">
                  <div class="form-group">
                    <label class="form-label">
                      Fecha y Hora final
                    </label>
                    <input type="datetime-local"
                           class="form-control"
                           step="1"
                           (change)="movFinalDateChange()"
                           [(ngModel)]="movFinalDate">
                  </div>
                </div>

                <div class="col-lg-2 ">
                  <div class="form-group d-flex align-items-center h-100">
                    <button type="button" class="mr-1  btn btn-labeled btn-outline-primary mb-2" (click)="btnClick()" [disabled]="balance.length === 0 || searchingDetail">
                      <span class="btn-label">
                      <i class="fa fa-plus-circle" aria-hidden="true" *ngIf="!searchingDetail"></i>
                      <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true" *ngIf="searchingDetail"></i>
                    </span>
                      <span>Consultar</span>
                    </button>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group d-flex align-items-center h-100">
                    <button type="button" class="mr-1  btn btn-labeled btn-outline-success mb-2" (click)="downloadExcel()" [disabled]="balance.length === 0 || downloadingExcel">
                      <span class="btn-label">
                        <i class="fa fa-file-excel" aria-hidden="true" *ngIf="!downloadingExcel"></i>
                        <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true" *ngIf="downloadingExcel"></i>
                    </span>
                      <span>Exportar a excel</span>
                    </button>
                  </div>
                </div>

                <br>
                <app-loading class="m-auto" *ngIf="searchingDetail"></app-loading>

                <div class="col-12" *ngIf="!searchingDetail">
                  <div class="table-balance-between-nodes" *ngIf="(dataList.length > 0 || dataListB.length > 0) && selectedLevelA && selectedLevelB">
                    <div class="full-header">BALANCE DE EQUIPOS ENTRE NODOS</div>
                    <div class="balance-between-detail">
                      <div class="name-nodes-container">
                        <div class="header-cell w-100" style="height: 3em; border-bottom: 2px solid rgb(168, 168, 168); overflow: visible;">
                          POSITIVOS A FAVOR {{selectedLevelA.UnitDescription}}
                        </div>
                        <div class="header-cell w-100" style="height: 3em; border-right: 2px solid rgb(168, 168, 168); overflow: visible;">
                          NEGATIVOS A FAVOR {{selectedLevelB.UnitDescription}}
                        </div>
                      </div>
                      <div class="w-100">
                        <div class="headers-container">
                          <div class="header-cell w-10" *ngFor="let balance of balance">
                            {{getContainerCode(balance.ConteinerId)}}
                          </div>
                        </div>
                        <div class="data-row" *ngIf="containersSet.length === containersSetB.length">
                          <div class="data-cell w-10" *ngFor="let container of containersSet; let i = index;">
                            {{container.total - containersSetB[i].total}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br>
                  <div class="table-detail" *ngIf="dataList.length > 0">
                    <div class="data-container">
                      <div class="unit-container">
                        Envios de {{selectedLevelA.UnitDescription}} a {{selectedLevelB.UnitDescription}}
                      </div>
                      <div class="info-container">
                        <div class="headers-container">
                          <div class="header-cell w-40 text-center">
                            Fecha de afectaci&oacute;n de inventario
                          </div>
                          <div class="header-cell w-20">
                            Hora
                          </div>
                          <div class="header-cell w-30 text-center">
                            Tipo de movimiento
                          </div>
                          <div class="header-cell w-90">
                            Referencia
                          </div>
                          <div class="header-cell w-10" *ngFor="let balance of balance">
                            {{getContainerCode(balance.ConteinerId)}}
                          </div>
                          <div class="header-cell" style="width: 3em;"></div>
                        </div>
                        <div class="data-content">
                          <div class="data-row" *ngFor="let data of dataList;let od = odd;let ev = even;" [ngClass]="{odd:od, even:ev}">
                            <div class="data-cell w-40">
                              {{data.date | date:'dd-MM-yyyy'}}
                            </div>
                            <div class="data-cell w-20">
                              {{data.date | date:'HH:mm:ss'}}
                            </div>
                            <div class="data-cell w-30 text-center">
                              {{data.moveType}}
                            </div>
                            <div class="data-cell w-90 text-center">
                              <span>{{data.reference}}</span>
                            </div>

                            <div class="data-cell w-10" *ngFor="let item of data.items">
                              {{item}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="total-container">
                      <div class="data-row">
                        <div class="data-cell w-50"></div>
                        <div class="data-cell w-50"></div>
                        <div class="data-cell w-50"></div>
                        <div class="data-cell w-50">
                          <b>TOTAL DE ENVIOS</b>
                        </div>
                        <div class="data-cell w-10" *ngFor="let container of containersSet">
                          {{container.total}}
                        </div>
                          <div class="header-cell" style="width: 3em;"></div>
                      </div>
                    </div>
                  </div>
                  <br>
                  <div class="table-detail" *ngIf="dataListB.length > 0">
                    <div class="data-container">
                      <div class="unit-container">
                        Envios de {{selectedLevelB.UnitDescription}} a {{selectedLevelA.UnitDescription}}
                      </div>
                      <div class="info-container">
                        <div class="headers-container">
                          <div class="header-cell w-40 text-center">
                            Fecha de afectaci&oacute;n de inventario
                          </div>
                          <div class="header-cell w-20">
                            Hora
                          </div>
                          <div class="header-cell w-30 text-center">
                            Tipo de movimiento
                          </div>
                          <div class="header-cell w-90">
                            Referencia
                          </div>
                          <div class="header-cell w-10" *ngFor="let balance of balance">
                            {{getContainerCode(balance.ConteinerId)}}
                          </div>
                          <div class="header-cell" style="width: 3em;"></div>
                        </div>
                        <div class="data-content">
                          <div class="data-row" *ngFor="let data of dataListB;let od = odd;let ev = even;" [ngClass]="{odd:od, even:ev}">
                            <div class="data-cell w-40">
                              {{data.date | date:'dd-MM-yyyy'}}
                            </div>
                            <div class="data-cell w-20">
                              {{data.date | date:'HH:mm:ss'}}
                            </div>
                            <div class="data-cell w-30 text-center">
                              {{data.moveType}}
                            </div>
                            <div class="data-cell w-90 text-center">
                              <span>{{data.reference}}</span>
                            </div>

                            <div class="data-cell w-10" *ngFor="let item of data.items">
                              {{item}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="total-container">
                      <div class="data-row">
                        <div class="data-cell w-50"></div>
                        <div class="data-cell w-50"></div>
                        <div class="data-cell w-50"></div>
                        <div class="data-cell w-50">
                          <b>TOTAL DE ENVIOS</b>
                        </div>
                        <div class="data-cell w-10" *ngFor="let container of containersSetB">
                          {{container.total}}
                        </div>
                        <div class="header-cell" style="width: 3em;"></div>
                      </div>
                    </div>
                  </div>


                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end page -->
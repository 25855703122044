import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { CryptoUtils, Logger } from 'msal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { UserLogin } from '../../../ceq/common/models/UserLogin.Model';
import { SettingsService } from '../../../core/settings/settings.service';
import { PagesService } from '../pages.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  profile: any;
  subscriptions: Subscription[] = [];

  title = 'CEQ';
  isIframe = false;
  loggedIn = false;
  usr : UserLogin;
  
  router: Router;
  isBusy: boolean;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true
  });

  constructor (private broadcastService: BroadcastService,router: Router, private authService: MsalService, public settings: SettingsService,
    private pagesService: PagesService    
  ) {
    this.router = router;    
    this.isBusy = false;
  }

  ngOnInit() {
    localStorage.clear();
        

    let loginSuccessSubscription: Subscription;
    let loginFailureSubscription: Subscription;

    this.isIframe = window !== window.parent && !window.opener;

    this.checkAccount();

    loginSuccessSubscription = this.broadcastService.subscribe('msal:loginSuccess', () => {
      this.checkAccount();
      window.location.reload();
    });

    loginFailureSubscription = this.broadcastService.subscribe('msal:loginFailure', (error) => {
      console.log('Login Fails:', error);
      this.logout()
    });

    this.subscriptions.push(loginSuccessSubscription);
    this.subscriptions.push(loginFailureSubscription);

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }

      console.log('Redirect Success: ', response.accessToken);
    });


    this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
      console.log('MSAL Logging: ', message);
    }, {
      correlationId: CryptoUtils.createNewGuid(),
      piiLoggingEnabled: false
    }));

    if (localStorage.getItem("Login")=="1")
    {
      localStorage.setItem("Login","0");
      this.router.navigate(['/home']);
    }    
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  checkAccount() {
    try{
      this.loggedIn = !!this.authService.getAccount();
    }catch(error){
      console.log(" There's an error", error);
    }
    console.log ("  Login:  " + this.loggedIn)
    if (!this.loggedIn)
    {
      this.settings.delUser();
    }
  }

  login() {
    localStorage.setItem("Login","1");
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    if (isIE) {
      this.authService.loginRedirect();
    } else {
      this.authService.loginPopup().then(() => {
        console.log('going to home')
        this.router.navigate(['/home']);
        window.location.reload();
      });
    }
  }

  logout() {
    this.authService.logout();
  }

 
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { CeqTableComponent } from '../../common/ceq-table/ceq-table.component';
import { DeleteChildRendered } from '../../common/renders/delete-child-rendered';
import { EditChildRendered } from '../../common/renders/edit-child-rendered';
import { GridOptions } from 'ag-grid-community';
import { SettingsService } from '../../../core/settings/settings.service';
import { UserLogin } from '../../common/models/UserLogin.Model';
import { ReportsService } from '../reports.service';
import { DatePipe } from '@angular/common';
import { List } from 'lodash';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ia-process',
  templateUrl: './ia-process.component.html',
  styleUrls: ['./ia-process.component.scss']
})
export class IaProcessComponent implements OnInit {
  constructor(
    private reportService: ReportsService,
    private settings: SettingsService
  ) { }

  @ViewChild('CeqGrid', { static: false }) ceqGrid: CeqTableComponent;
  datepipe: DatePipe = new DatePipe('en-US');
  actualDate: Date;
  selectedDate: string = '-';
  selectedEvent: string = '';
  user: UserLogin;
  eventList: List<string> = [];
  eventSelected: string = '-';
  keyword: string = '';
  pageSelected: number = 1000;
  filter: string;

  options: GridOptions;
  PageSizes = [10, 25, 50, 100, 1000, 10000];

  gridOptions = {
    context: this,
    frameworkComponents: {
      childEditRenderer: EditChildRendered,
      childDeleteMessageRenderer: DeleteChildRendered,
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) { },
    onCellClicked(event: any) {
      if (event.colDef.field == 'ValidationResult') {
        Swal.fire('Resultado', event.value)
      }
    },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    }
  };

  columnDefs = [
    {
      headerName: 'Fecha',
      field: 'CreatedAt',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      unSortIcon: true,
      resizable: true,
      sort: 'desc',
      cellRenderer: (params: any) => moment(params.data.CreatedAt).format('DD/MM/yyyy HH:mm a')
    },
    {
      headerName: 'Proceso',
      field: 'ProcessName',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      resizable: true,
      unSortIcon: true
    },
    {
      headerName: 'Estatus',
      field: 'ValidationStatus',
      width: 5,
      cellClass: 'align-start',
      resizable: true,
      editable: false
    },
    {
      headerName: 'Resultado',
      field: 'ValidationResult',
      width: 100,
      cellClass: 'align-start',
      resizable: true,
      editable: false
    },
    {
      headerName: 'Usuario',
      field: 'CreatedBy',
      width: 10,
      cellClass: 'align-start',
      resizable: true,
      editable: false
    }
  ];

  ngOnInit(): void {
    this.actualDate = new Date();
    this.user = this.settings.getUser();
    this.selectedDate = this.datepipe.transform(this.actualDate, 'yyyy-MM-dd');
    this.getEventList();
  }

  selectEvent(event: any) {
    this.filter = "";
    if (event != null) {
      this.eventSelected = event;
      this.filter = `&$filter=ProcessName eq '` + this.eventSelected + `'`;
    }

    this.ceqGrid.endPoint =
      `validationprocess/1/${this.selectedDate}?$top=${this.pageSelected}` + this.filter;
    this.ceqGrid.refreshTable();
  }

  onPageSizeChange(value: any) {
    this.pageSelected = value;
    this.selectEvent(null);
  }

  getEventList() {
    this.reportService.getProcessList(this.user.Org).subscribe((x) => {
      this.eventList = x;
    });
  }

  gridEmiter(GridOptions: GridOptions) {
    this.options = GridOptions;
    this.ceqGrid.endPoint =
      `validationprocess/1/${this.selectedDate}?$top=${this.pageSelected}`;
    this.ceqGrid.refreshTable();
  }

  clearEvent() {
    this.eventSelected = '-';
    this.selectEvent(null);
  }
}

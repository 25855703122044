import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { Unit } from '../../common/models/Unit.Model';
import { UserLogin } from '../../common/models/UserLogin.Model';
import { ReportsService } from '../../reports/reports.service';
import { InventoryService } from '../inventory.service'
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';
import { CalendarService } from '../../configuration/calendar/calendar.service';
import { AutomaticTransfer } from '../../common/models/AutomaticTransfer';

@Component({
  selector: 'app-automatictransfer-det',
  templateUrl: './automatictransfer-det.component.html',
  styleUrls: ['./automatictransfer-det.component.scss']
})
export class AutomatictransferDetComponent implements OnInit {

  description: string;
  item: AutomaticTransfer;

  constructor(
    private globalFunctionService: GlobalFunctionsService,
    private reportService: ReportsService,
    private settingsServ: SettingsService,
    private inventoryService : InventoryService) {

    this.item = new AutomaticTransfer();

  }

  downloading: boolean = false;
  working: boolean = false;
  user: UserLogin;
  region: Unit;
  regionSelected: number = 0;
  clasificationSelected: number = 0;
  unitList: Unit[];
  organizacionList: Unit[];
  organizationSelected: number = 0;
  unitSelected: Unit;
  selectedDate: string;
  IntermedeateRegion: string = '';
  SatelliteRegion: string = '';
  FinalRegion: string = '';
  datepipe: DatePipe = new DatePipe('en-US');
  FinalSelected: Unit;
  SatelliteSelected: Unit;
  IntermediateSelected: Unit;

  ngOnInit(): void {
    this.getNodeByLevel();
  }

  addTransfer() {
    // crear objeto del modelo del alta
    //let item:  AutomaticTransfer;
    //item = new AutomaticTransfer;

    if (this.item.Description == "") {
      Swal.fire({
        icon: 'warning',
        text: 'Es necesario capturar una descripcion',
      });
      return;
    }


    if (this.FinalSelected == null || this.FinalSelected.UnitId==0 ) {
      Swal.fire({
        icon: 'warning',
        text: 'Es necesario seleccionar un Nodo final',
      });
      return;
    }

    if (this.IntermediateSelected == null || this.IntermediateSelected.UnitId==0) {
      Swal.fire({
        icon: 'warning',
        text: 'Es necesario seleccionar un Nodo Intermedio',
      });
      return;
    }


    if (this.SatelliteSelected == null || this.SatelliteSelected.UnitId==0 ) {
      Swal.fire({
        icon: 'warning',
        text: 'Es necesario seleccionar un Nodo satelite',
      });
      return;
    }

    if (this.IntermediateSelected.UnitId == this.SatelliteSelected.UnitId ||  this.IntermediateSelected.UnitId == this.FinalSelected.UnitId) {
      Swal.fire({
        icon: 'warning',
        text: 'Ninguno de los nodos pueden repetirse ',
      });
      return;
    }


    if (this.SatelliteSelected.UnitId == this.FinalSelected.UnitId ) {
      Swal.fire({
        icon: 'warning',
        text: 'Ninguno de los nodos pueden repetirse ',
      });
      return;
    }




    this.item.Org = 1;
    this.item.Active = 0;
    this.item.ActiveDate = new Date(1980, 1, 1);
    this.item.Final = this.FinalSelected.UnitId;
    this.item.InactiveDate = new Date(1980, 1, 1);
    this.item.Intermediate = this.IntermediateSelected.UnitId;
    this.item.Satellite = this.SatelliteSelected.UnitId;
    this.item.UserIdActive = 0;
    this.item.UserIdInactive = 0;
    this.item.CreatedBy = this.user.Email;
    this.item.ModifiedBy = this.user.Email;
    this.item.CreatedAt = new Date();
    this.item.ModifiedAt = new Date();

    this.inventoryService.addAutomaticTransfer(this.item).subscribe(() => {
      Swal.fire({
        icon: 'success',
        title: '¡Traspaso registrada correctamente!',
      });

      this.cancelTransfer();
    }, err => {

      Swal.fire({
        icon: 'warning',
        text: `Ocurrió un error: ${JSON.stringify(err.error)}`,
      });
    });



  }

  cancelTransfer() {
    // this.router.navigate(['/inventory/automatictransfer-cat']);
    window.close();
  }

  SatelliteOutputChange() {

    this.SatelliteRegion = "";
  }

  FinalOutputChange() {

    this.FinalRegion = "";
  }

  IntermediateOutputChange() {

    this.IntermedeateRegion = "";
  }



  UpdateRegionSatellite(row: any) {

    let userId = 0;
    let org = 1;
    let user = JSON.parse(localStorage.getItem('user'));
    if (user != null && user != undefined) {
      userId = user.Id;
      org = user.Org;
    }
    this.reportService.getRegionByNode( userId, row.UnitId).subscribe((move: Unit) => {
    this.region = move;
    this.SatelliteRegion = move.UnitDescription;

  });

  }


  UpdateRegionFinal(row: any) {

    let userId = 0;
    let org = 1;
    let user = JSON.parse(localStorage.getItem('user'));
    if (user != null && user != undefined) {
      userId = user.Id;
      org = user.Org;
    }
    this.reportService.getRegionByNode(userId, row.UnitId).subscribe((move: Unit) => {
      this.region = move;
      this.FinalRegion = move.UnitDescription;

    });

  }


  UpdateRegionIntermediate(row: any) {

    let userId = 0;
    let org = 1;
    let user = JSON.parse(localStorage.getItem('user'));
    if (user != null && user != undefined) {
      userId = user.Id;
      org = user.Org;
    }
    this.reportService.getRegionByNode(userId, row.UnitId).subscribe((move: Unit) => {
      this.region = move;
      this.IntermedeateRegion = move.UnitDescription;

    });

  }





  getNodeByLevel() {
    this.user = this.settingsServ.getUser();
    let userId = 0;
    let org = 1;
    let user = JSON.parse(localStorage.getItem('user'));
    if (user != null && user != undefined) {
      userId = user.Id;
      org = user.Org;
    }

    this.working = true;
    this.clasificationSelected = 0;
    var levelType: string =
      this.clasificationSelected == 0
        ? 'BOTH'
        : this.clasificationSelected == 1
          ? 'FAB'
          : this.clasificationSelected == 2
            ? 'CEVE'
            : '';
    if (levelType == '') {
      this.globalFunctionService.messageToast(`Seleccione una clasificación de nodo válida.`, 'warning');
      return;
    }
    this.reportService
      .getNodeByLevel(
        levelType,
        this.organizationSelected,
        this.user.Id
      )
      .toPromise()
      .then((resp: Unit[]) => {
        this.unitList = resp;
      })
      .catch(() => {
        this.globalFunctionService.messageToast(`No se encontraron nodos.`, 'warning');
      })
      .finally(() => {
        this.working = false;
        // this.unitSelected= '';
      });
  }


}

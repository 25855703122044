import { BaseModel } from "./BaseModel.Model"

export class EmployeeRoute extends BaseModel {

    constructor() {
        super();
        this.Org = 0;
        this.EmployeeNumber = "";
        this.EmployeeName = "";
        this.EmployeeCode="";
        this.EmployeeTypeName = "";
        this.RouteCode = "";
        this.RouteId = 0;
    }

    Org: number;
    EmployeeNumber: string;
    EmployeeTypeName: string;
    RouteCode: string;
    RouteId: number;
    EmployeeName: string;
    EmployeeCode:string;
}
import { Injectable } from '@angular/core';
import { List } from 'lodash';
import { Observable } from 'rxjs';
import { Address } from '../common/models/Address.Model';
import { Product } from '../common/models/Product.Model';
import { GenericService } from '../common/services/GenericServices';

@Injectable({
  providedIn: 'root'
})
export class TaxService {

  constructor(private genericService: GenericService) { }

  getProduct(org : number, productCode: string, pageSize: number): Observable<List<Product>> {
    var parameters = [];
    if(productCode != ''){parameters.push(productCode);}

    return this.genericService.getRequest(`product/${org}/${pageSize}`, {parameters});
  }

  getProductByCode(org: number, productCode: string){
    return this.genericService.getRequest(`product/productCode/${org}/${productCode}`,{},0);
  }

  getAddress(org: number, addressName: string, pageSize: number): Observable<List<Address>> {
    return this.genericService.getRequest(`address/${org}/${pageSize}/${addressName}`, {});
  }

}

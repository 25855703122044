import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { GenericService } from '../../common/services/GenericServices';

import { Employee } from "../../common/models/Employee.Model";
import { EmployeeType } from "../../common/models/EmployeeType.Model";
import { Unit } from '../../common/models/Unit.Model';
import { EmployeeNotificationGroup, EmployeeNotificationReceiver } from '../../common/models/EmployeeNotificationGroup';
import { EmployeeCombo } from '../../common/models/EmployeeCombo.Model';
import { BossCombo } from '../../common/models/EmployeeCombo.Model';


@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private genericService: GenericService) {

  }

  /**
   * Get all parent nodes from a unit
   *
   * @param {number} orgId - node org
   * @param {number} unitId - unitId of main child node
   * @returns {Observable<List<Unit>>} - List of Unit type
   */
  getParents(orgId: number, unitId: number): Observable<Unit[]> {
    return this.genericService.getRequest(`unit/${orgId}/${unitId}/parents`, {}, 0);
  }

  getEmployeeTypes(org: number): Observable<EmployeeType[]> {
    let url = "employeetype";
    return this.genericService.getRequest(url, {}, org);
  }

  getEmployeeTypeById(employeeTypeId: number, org: number): Observable<EmployeeType> {
    let url = "employeeType/" + employeeTypeId;
    return this.genericService.getRequest(url, {}, org);
  }

  createEmployeeType(employeeType: EmployeeType): Observable<EmployeeType> {
    let url = "employeeType";
    return this.genericService.postRequest(url, {}, 0, employeeType);
  }

  updateEmployeeType(employeeType: EmployeeType): Observable<EmployeeType> {
    let url = "employeeType";
    return this.genericService.putRequest(url, {}, 0, employeeType);
  }

  deleteEmployeeType(employeeTypeId: number, org: number): Observable<boolean> {
    let url = "employeeType/" + employeeTypeId;
    return this.genericService.deleteRequest(url, {}, org);
  }


  getEmployeeBosses(employeeTypeId: number, org: number): Observable<Employee[]> {
    let url = "employee/type/" + employeeTypeId + "/bosses";
    return this.genericService.getRequest(url, {}, org);
  }

  /*
   * devuelve al jefe de un empleado por el codigo de empleado
   */ 
  getEmployeeBossByCode(employeeCode: string): Observable<Employee> {
    let url = "employee/code/" + employeeCode + "/boss";
    return this.genericService.getRequest(url, {}, 0);
  }

  getEmployeeById(employeeId: number, org: number): Observable<Employee> {
    let url = "employee/" + employeeId;
    return this.genericService.getRequest(url, {}, org);
  }

  getEmployeeByCode(employeeCode: string, org: number): Observable<Employee> {
    let url = "employee/code/" + employeeCode;
    return this.genericService.getRequest(url, {}, org);
  }

  getBosses(unitId: number, org: number): Observable<BossCombo[]> {
    let url = "employee/bosses/" + unitId;
    return this.genericService.getRequest(url, {}, org);
  }

  getEmployee(org: number, filter: string, lenght: number): Observable<Employee[]> {
    let url = "employee";
    return this.genericService.getRequest(url, { filter: filter, lenght: lenght }, org);
  }

  GetEmployeesByUnitId(unitId: number, filter: string, lenght: number): Observable<Employee[]> {
    let url = "employee/unit/" + unitId.toString();
    return this.genericService.getRequest(url, { filter: filter, lenght: lenght }, 0);
  }

  createEmployee(employee: Employee): Observable<Employee> {
    let url = "employee";
    return this.genericService.postRequest(url, {}, 0, employee);
  }

  updateEmployee(employee: Employee): Observable<Employee> {
    let url = "employee";
    return this.genericService.putRequest(url, {}, 0, employee);
  }

  deleteEmployee(employeeId: number, org: number): Observable<boolean> {
    let url = "employee/" + employeeId;
    return this.genericService.deleteRequest(url, {}, org);
  }

  getCeveSalers(ceveId: number): Observable<Employee[]> {
    let url = "employee/salers/unit/" + ceveId;
    return this.genericService.getRequest(url, {}, 0);
  }

  //#region EmployeeNotificationGroups

  getnotificationGroups(employeeCode: string): Observable<EmployeeNotificationGroup[]> {
    let url = "notificationgroup/" + employeeCode;
    return this.genericService.getRequest(url, {}, 0);
  }

  getNotificationEmployees(employeeCode: string): Observable<Employee[]> {
    let url = `employee/${employeeCode}/notificationgroup/employee`;
    return this.genericService.getRequest(url, {}, 0);
  }

  saveNotificationGroups(employeeCode: string, receivers: EmployeeNotificationReceiver[]): Observable<any> {
    let url = "notificationgroup/" + employeeCode;
    return this.genericService.postRequest(url, {}, 0, receivers);
  }
  //#endregion

  loadEmployees(data: any): Observable<any[]> {
    let url = `employee/load`;
    return this.genericService.postRequest(url, {}, 0, data);
  }

  getEmployeeCombo(employeeTypeId: number): Observable<EmployeeCombo[]> {
    let url = `employee/combo/${employeeTypeId}`;
    return this.genericService.getRequest(url, {}, 0);
  }
}

import { Injectable } from '@angular/core';
import { GenericService } from '../../common/services/GenericServices';
import { Observable } from 'rxjs';
import { List } from 'lodash';
import { Calendar } from '../../common/models/Calendar.Model';
import { Holliday } from '../../common/models/Holliday.Model';
@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(private genericServ: GenericService) { }

  /**
   * Retieves a list with all calendar events on database
   *
   * @return {*}  {Observable<List<Calendar>>} - List of calendar events
   * @memberof CalendarService
   */
  getCalendarEvents(): Observable<List<Calendar>> {
    return this.genericServ.getRequest(`Calendar`, {});
  }
  /**
   * Retieves a list with all calendar events on database previous to current date
   *
   * @return {*}  {Observable<List<Calendar>>} - List of calendar events
   * @memberof CalendarService
   */
  getCalendarCutEvents(): Observable<List<Calendar>> {
    return this.genericServ.getRequest(`Calendar/onCut`, {});
  }

  /**
   * Adds a new calendar event to database
   *
   * @param {Calendar} calendarEvent - Calendar event to add to database
   * @return {*}  {Observable<any>} - Status request
   * @memberof CalendarService
   */
  addCalendarEvent(calendarEvent: Calendar): Observable<any> {
    return this.genericServ.postRequest(`Calendar`, {}, 0, calendarEvent);
  }

  /**
 * Retieves a list with all Holliday events on database
 *
 * @return {*}  {Observable<List<Holliday>>} - List of calendar events
 * @memberof CalendarService
 */
  getHollidayEvents(): Observable<List<Holliday>> {
    return this.genericServ.getRequest(`Hollidays`, {});
  }

  /**
 * Retieves if a date is a Holliday events on database
 *
 * @return {*}  {Observable<List<Holliday>>} - List of calendar events
 * @memberof CalendarService
 */
  getHollidayByDayEvent(day : Date): Observable<Holliday> {
    return this.genericServ.getRequest(`Hollidays/${day.toDateString()}`, {});
  }

  /**
   * Adds a new calendar event to database
   *
   * @param {Holliday} calendarEvent - Calendar event to add to database
   * @return {*}  {Observable<any>} - Status request
   * @memberof CalendarService
   */
  addHollidayEvent(calendarEvent: Holliday): Observable<any> {
    return this.genericServ.postRequest(`Hollidays`, {}, 0, calendarEvent);
  }

  /**
   * Removes a holliday event from database
   *
   * @param {string} calendarId - Calendar event id to remove from database
   * @return {*}  {Observable<any>} - Status request
   * @memberof CalendarService
   */
  removeHollidayEvent(calendarId: number): Observable<any> {
    return this.genericServ.deleteRequest(`Hollidays/${calendarId}`, {}, 0);
  }

  /**
   * Removes a calendar event from database
   *
   * @param {string} calendarId - Calendar event id to remove from database
   * @return {*}  {Observable<any>} - Status request
   * @memberof CalendarService
   */
  removeCalendarEvent(calendarId: number): Observable<any> {
    return this.genericServ.deleteRequest(`Calendar/${calendarId}`, {}, 0);
  }

  // devuelve el calendario que esta activo al momento de la ejecucion

  CalendarInventoryInProgress():  Observable<List<Calendar>> {
    return this.genericServ.getRequest(`Calendar/inventoryinprogress`, {}, 0);
  }

  /**
   * Devuelve el calendario mientras este en ejecución
   */
  CalendarInventoryLock(): Observable<List<Calendar>> {
    return this.genericServ.getRequest(`Calendar/inventorycutlock`, {}, 0);
  }

  getCentralTime(): Observable<Date> {
    return this.genericServ.getRequest(`calendar/getCentralTime`, {}, 0);
  }

  /**
  * Devuelve el id de la ultima caratula de ceve o planta
  */
  getLastCutId(unitId:number): Observable<any> {
    return this.genericServ.getRequest(`calendar/${unitId}/last`, {}, 0);
  }


}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/ceq/common/services/GenericServices';
import { Container } from '../../../common/models/Container.Model';
import { ContainerType } from 'src/app/ceq/common/models/ContainerType.Model';
import { List } from 'lodash';

/**
 * Service class for Container entity with the next methods:
 *
 * @remarks
 * getRegionList()
 * getContainerData()
 * addContainer()
 * editContainer()
 * removeContainer()
 *
 * @class ContainerService
 */
@Injectable({
  providedIn: 'root'
})
export class ContainerService {

  constructor(private genericService: GenericService) { }



  getContainersByContainerTypeId(containerTypeId: number = 0): Observable<Container[]> {
    let url = containerTypeId != 0 ? "container?$filter=ContainerTypeId eq " + containerTypeId : "container";
    return this.genericService.getRequest(url, {}, 0);
  }

  getContainersByContainerOrg(org: number, unitId: number, containerTypeId: number): Observable<Container[]> {
    let url = `container/GetContainersByOrg/${org}/${unitId}/${containerTypeId}`;
    return this.genericService.getRequest(url, {}, 0);
  }

  /**
   * Returns an observable with a list of ContainerType type
   *
   * @returns {Observable<List<ContainerType>>} - List of ContainerType type
   * @memberof ContainerService
   */
  getContainerTypeList(): Observable<List<ContainerType>> {
    return this.genericService.getRequest(`ContainerType`, {}, 0);
  }

  /**
   * Returns an observable with an instance of Container by ContainerId
   *
   * @param {string} containerId - The Id of Container to get certain data
   *
   * @returns {Observable<Container>} - Observable with an instance of Container
   * @memberof ContainerService
   */
  getContainerData(containerId: string): Observable<Container> {
    return this.genericService.getRequest(`Container/${containerId}`, {}, 0);
  }

  /**
   * Add a new Container to database
   *
   * @param {Container} container - The Container object to add without id
   * @returns {Observable<any>} - Status request
   * @memberof ContainerService
   */
  addContainer(container: Container): Observable<any> {
    return this.genericService.postRequest('Container', {}, 0, container);
  }

  /**
   * Update a Container from a certain Container object with id
   *
   * @param {Container} container - The Container object to update with id
   * @returns {Observable<any>} - Status request
   * @memberof ContainerService
   */
  editContainer(container: Container): Observable<any> {
    return this.genericService.putRequest(`Container/${container.ContainerId}`, {}, 0, container);
  }

  /**
   * Remove a Container from a certain Id
   *
   * @param {string} containerId - The Id of Container to delete
   * @returns {Observable<any>} - Status request
   * @memberof ContainerService
   */
  removeContainer(containerId: string): Observable<any> {
    return this.genericService.deleteRequest(`Container/${containerId}`, {}, 0);
  }
}

<div class="content-heading">
    <div>Mapa de rutas
        <small>This directive allows you to add Google Maps Javascript API elements.</small>
    </div>
</div>

<div class="container-fluid">
    <!-- filters -->
    <div class="row">
        <div class="col-lg-4">
            <div class="form-group">
                <label class="form-label"> Centro de ventas</label>
                <ng-autocomplete [placeholder]="'Búsqueda'"
                                 [data]="nodes"
                                 [searchKeyword]="inputArgument"
                                 [itemTemplate]="itemOutputTemplate"
                                 [notFoundTemplate]="notFoundoutputTemplate"
                                 [debounceTime]="600"
                                 [minQueryLength]="3"
                                 (selected)='selectCeVeEvent($event)'
                                 [disabled]="nodes.length === 0"
                                 [(ngModel)]="selectedUnitDescription">
                </ng-autocomplete>
                <ng-template #itemOutputTemplate let-item>
                    <a [innerHTML]="item.UnitDescription"></a>
                </ng-template>
                <ng-template #notFoundoutputTemplate let-notFound>
                    <div [innerHTML]="'No se encontraron coincidencias'"></div>
                </ng-template>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="form-group">
                <label class="form-label"> Fecha</label>

                <input type="date" class="form-control" [(ngModel)]="selectedDay" (change)="cmbDayChange()" [disabled]="nodes.length === 0">

            </div>
        </div>
        <div class="col-lg-4">
            <div class="form-group">
                <label class="form-label"> Chofer</label>
                <select class="form-control" [(ngModel)]="selectedEmployee" (change)="cmbEmployeeChange()" [disabled]="loadingMap || loadingTimeline || nodes.length === 0">
                    <option *ngFor="let emp of employees" [value]="emp.Employee">
                        {{ emp.Employee }}
                    </option>
                </select>
            </div>
        </div>
    </div>

    <div class="row">
        <!-- map -->

        <!-- <div *ngIf="employees.length==0" style="position: absolute; z-index: 1; margin-left: 1em; width: calc(100% - 3em); height: 31em;">
            <div class="card-body" style=" display:flex; background-color: rgba(120, 120, 120, .5); height: 100%; align-items: center;">
                <h1 style="color: white; margin: 0 auto; ">No se encontraron elementos para el dia solicitado</h1>
            </div>
        </div> -->

        <div [ngClass]="timeLineVisible ? 'col-lg-6' : 'col-lg-12'">
            <div class="card card-default">
                <div class="card-body">
                    <!-- Map load indicator -->
                    <h4 *ngIf="loadingMap">Cargando Mapa...</h4>
                    <div class="progress" *ngIf="loadingMap">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
                    </div>

                    <div id="map" class="map"></div>
                    <div id="popup" class="ol-popup d-none">
                        <a href="#" id="popup-closer" class="ol-popup-closer" (click)="closePopup()"></a>
                        <div id="popup-content" class="popup-content"></div>
                    </div>

                    <br>
                    <button class="mr-1 btn btn-labeled btn-outline-primary mb-2" (click)="exportPng()">
                        <span class="btn-label">
                            <i class="fas fa-lg fa-camera"></i>
                        </span>
                        <span>Capturar Mapa</span>
                    </button>

                </div>
            </div>
        </div>

        <!-- Timeline -->

        <div class="col-lg-6" *ngIf="timeLineVisible">
            <!-- Timeline load indicator -->
            <h4 *ngIf="loadingTimeline">Cargando Itinerario...</h4>
            <div class="progress" *ngIf="loadingTimeline">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
            </div>
            <!-- End timeline load indicator -->
            <div style="height: 450px; overflow-y:scroll;  overflow-x: hidden;">
                <ul [ngClass]="timelineAlt ? 'timeline-alt' : 'timeline'">
                    <li class="timeline-separator heigth-start" data-datetime="Track"></li>
                    <!-- Items in cicle -->
                    <!-- START timeline item-->
                    <li *ngFor="let customer of timeLineCustomers" [ngClass]="customer.IsTitle == true ? 'timeline-separator' : customer.IsCollect == true ? '' : 'timeline-inverted'">
                        <div [ngClass]="badgeSelector(customer.MoveType)" *ngIf="!customer.IsTitle">
                            <em [ngClass]="symbolSelector(customer.MoveType)" *ngIf="customer.MoveType!='ONROUTE'"></em>

                            <svg *ngIf="customer.MoveType=='ONROUTE'" xmlns="http://www.w3.org/2000/svg" height="20" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M8 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm1.5 2.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                <path d="M4 2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2h2c-.167.5-.8 1.6-2 2v2h2c-.167.5-.8 1.6-2 2v2h2c-.167.5-.8 1.6-2 2v1a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-1c-1.2-.4-1.833-1.5-2-2h2V8c-1.2-.4-1.833-1.5-2-2h2V4c-1.2-.4-1.833-1.5-2-2h2zm2-1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6z" />
                            </svg>

                        </div>

                        <div class="timeline-card" *ngIf="!customer.IsTitle">
                            <div class="popover left">
                                <h4 class="popover-header">
                                    {{customer.Title}}
                                </h4>
                                <div class="arrow"></div>
                                <div class="popover-body center">

                                    <small>{{customer.OperationDate | date:'fullDate'}} a las {{customer.OperationDate | date:'hh:mm a'}} </small>
                                    <br>
                                    <small>Chofer: <b>{{customer.EmployeeName}}</b></small>
                                    <br>
                                    <small *ngIf="customer.MoveType=='SER'">Despachador: <b>{{customer.ReceiverName}}</b></small>
                                    <accordion [closeOthers]="true" class="pointer prevent-select" *ngIf="customer.Detail.length>0">
                                        <accordion-group [isOpen]="isAccOpen2">
                                            <div accordion-heading style="height: 60px;">
                                                <em class="fa fa-angle-double-down arrow-position" style="float: right; line-height: 50px; vertical-align: middle;"></em>
                                                <label style="float: right; line-height: 50px; vertical-align: middle; margin-left: 5px; margin-right: 5px;"> Ver detalle </label>
                                                <br style="clear: both;">
                                            </div>

                                            <table class="table table-sm width-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">EQUIPO</th>
                                                        <th scope="col">CANTIDAD</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let det of customer.Detail">
                                                        <td>{{det.ContainerCode}}</td>
                                                        <td>{{det.Quantity}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </accordion-group>
                                    </accordion>

                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- END timeline item-->
                </ul>
            </div>
        </div>

    </div>

</div>
import { BaseModel } from "./BaseModel.Model"
import { EquipmentLoanDetail } from "../models/EquipmentLoanDetail.Model"

export class EquipmentLoan extends BaseModel {

  constructor() {
    super();
    
  }

  Org: number;
  ComodatoId: string;
  UnitId: number;
  ChannelId: number;
  RouteId: string;
  RetailId: string;
  EmployeeId: number;
  Asesor: string;
  Caducidad: number;
  Reference: string;
  Status: string;
  FinishDate: Date;
  Detail: EquipmentLoanDetail[];
  SellerChangeComment: string;
  RTMReference: string ;
}

import { Component, OnInit, ViewChild, Injector, OnDestroy, HostListener } from '@angular/core';
import { Observable, Subject,timer } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { takeUntil } from 'rxjs/operators';
import { toNumber } from 'lodash';
import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';
const screenfull = require('screenfull');

import { GlobalFunctionsService } from '../../ceq/common/functions/global-functions.service';
import { MessagingService } from '../../services/messaging/messaging.service';
import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { GenericService } from '../../ceq/common/services/GenericServices';
import { MenuService } from '../../core/menu/menu.service';
import { UserService } from 'src/app/ceq/security/user/user.service';

import { ProfileLevel } from '../../ceq/common/models/ProfileLevel.Model';
import { Notification } from '../../ceq/common/models/Notification.Model';
import { UserLogin } from '../../ceq/common/models/UserLogin.Model';
import { Calendar } from 'src/app/ceq/common/models/Calendar.Model';
import { Dict } from 'src/app/ceq/common/models/Dict.Model';
import { Ceve } from '../../ceq/common/models/Ceve.Model';
import { Org } from '../../ceq/common/models/Org.Model';
import { AdjustService } from 'src/app/ceq/inventory/adjust/adjust.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    ceq: boolean = true;
    moldes: boolean = false;
    outputList: Ceve[] = [];
    OutputUnit: number;
    Org: number;
    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout
    router: Router;
    private _destroy$ = new Subject();
    redirect: boolean = false;
    newVersion: boolean= false;
    MultiOrg: boolean = false;
    environment: string;
    ceqVersion: string;
    Organization: Org[] = [];
    userOrgs: ProfileLevel[];
    notifications: Notification[] = [];
    userLogin: UserLogin;
    RespDict : Dict;
    TimeToCheck: number = 600000; // 10 minutos
    bd: string;

    Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true
    });

    //Inventory in progress control
    NextCalendar: Calendar;
    flagInvInProgress: boolean = false;
    invProgressTimer: any;
    hours: any;
    minutes: any;
    seconds: any;

//Adjut in progress
  adjustInProgress:boolean=false;
  adjTimerHeader: any;
  adjHoursHeader: number=0;
  adjMinutesHeader: number=0;
  adjSecondsHeader: number=0;


    isNavSearchVisible: boolean;
    @ViewChild('fsbutton', { static: true }) fsbutton;  // the fullscreen button
    // @HostListener('window:focus', ['$event'])
    @HostListener('window:focus', ['$event'])
    visibilitychange() {
      this.focusValid();
    }

    constructor(public menu: MenuService,
                public userblockService: UserblockService,
                public settings: SettingsService,
                public injector: Injector,
                private messagingService: MessagingService,
                private authService: MsalService,
                public globalFunction: GlobalFunctionsService,
                public userService: UserService,
                private genericServ: GenericService,
                private adjustservice:AdjustService) {
      // show only a few items on demo
      this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
      if(this.settings.getUser() !== null) {
          this.setUserAccess();
          this.userLogin = this.settings.getUser();
          this.getNotifications();
      } else {
          this.redirect = true;
          // setTimeout(
          //     () => {
          //         window.location.reload();
          // }, 3000);
      }
    }

    ngOnDestroy(): void {
        this.messagingService.currentMessage$.complete();
        this.messagingService.currentMessage$.unsubscribe();
        this._destroy$.next();
    }


    cmbOutputListChange(event: number) {
      this.setPrincipalNode(this.userLogin.Id,event, 1).subscribe((ceves: any[]) => {
          console.log ("Valor "+ event);
          this.userLogin.UnitId=toNumber(event);
          this.userLogin.RouteName=this.outputList.find(x => x.UnitId == event).CeveName;
          this.settings.setUser(this.userLogin);

          this.router.navigate(["/home"]).then(() => {
              window.location.reload();
          });
      }, err => {
          console.log(err);
      });
    }

    setPrincipalNode(userId: number,unitId: number, org: number): Observable<any>{
      let url = `user/${org}/${userId}/${unitId}/PrincipalNode`;
      return this.genericServ.getRequest(url, {}, 0);
    }


    getMultiorgNodes(unitId: number, org: number): Observable<any>{
      let url = `ceveexternalcode/${org}/Multiorg/external/${unitId}`;
      return this.genericServ.getRequest(url, {}, 0);
    }

    async ngOnInit() {
        this.isNavSearchVisible = false;
        let env = location.href;

        this.globalFunction.getEnvironment().subscribe((x: Dict) => {
          this.bd = x.key;

          this.environment = "";
          if (env.search("ceq200") != -1) {
            this.environment = "DEV";
          }
          if (env.search("ceq300") != -1) {
            this.environment = "QA";
          }
          if (env.search("local") != -1) {
            this.environment = this.bd;
          }
        });

        this.globalFunction.getCeqVersion().subscribe((data : Dict)  => {
            this.ceqVersion = data.value;
            let currentVersion= localStorage.getItem("CeqVersion");
            console.log("CeqVersion Local : "+ currentVersion);
            console.log("CeqVersion Server : "+ this.ceqVersion);
            if (currentVersion != null)
            {
                if (currentVersion != this.ceqVersion)
                {
                    console.log ("New Version")
                    this.newVersion=true;
                    this.updateCeqVersion();
                }
                else
                {
                    console.log ("Version Actual")
                    this.newVersion=false;
                }
            }
            else
            {
                console.log ("primera version")
                localStorage.setItem("CeqVersion",this.ceqVersion);
            }

        });

        var ua = window.navigator.userAgent;
        if (ua.indexOf("MSIE ") > 0 || !!ua.match(/Trident.*rv\:11\./)) { // Not supported under IE
            this.fsbutton.nativeElement.style.display = 'none';
        }

        // Switch fullscreen icon indicator
        const el = this.fsbutton.nativeElement.firstElementChild;
        screenfull.on('change', () => {
            if (el)
                el.className = screenfull.isFullscreen ? 'fa fa-compress' : 'fa fa-expand';
        });

        this.router = this.injector.get(Router);

        // Autoclose navbar on mobile when route change
        this.router.events.subscribe((val) => {
            // scroll view to top
            window.scrollTo(0, 0);
            // close collapse menu
            this.navCollapsed = true;
        });

        this.messagingService.requestPermission();
        this.messagingService.receiveMessage();

        this.messagingService.currentMessage$.subscribe(async msg => {
          if(msg === null) return;

          var elemento = document.getElementById("notificationBell");
          elemento.classList.add("tadaBell");
          this.getNotifications();
          console.log(msg);
          if(this.userLogin.NotificationSound){
            const audio = new Audio('assets/audio/bell.mp3');
            // console.log(audio);
            audio.volume = 0.5;
            // console.log(audio.volume);
            audio.play();
          }

          await new Promise(f => setTimeout(f, 2000));
          elemento.classList.remove('tadaBell');

          this.OnNotificationReceived(msg);

        });

        this.getCheckVersion();
        this.userLogin = this.settings.getUser();
        this.OutputUnit = this.userLogin.UnitId;
        this.Org = this.userLogin.Org;
        if (this.Org == 2) {
          this.ceq = false;
          this.moldes = true;
        }

      this.getMultiorgNodes(this.userLogin.UnitId, 1).subscribe((ceves: any[]) => {
        console.log ("MultiOrg : " + ceves);
        this.outputList = ceves;
        if (ceves && ceves.length!=0)
            this.MultiOrg=true;

        this.validateUnitAdjust();

      }, err => {
        console.log(err);
      });
    }

    toggleNotificationSound(){
      this.userLogin.NotificationSound = !this.userLogin.NotificationSound;
      this.settings.setUser(this.userLogin);
      console.log(this.userLogin.NotificationSound);

      this.userService.updateNotificationSound(this.userLogin.Id,this.userLogin.NotificationSound).toPromise()
      .then(()=>{
        console.log('update emited');
      })
      .catch(()=>{
        console.log('Error al actualizar par+ametros de T');
      });
    }

    /*
    *
    * LoginAD con la nueva organizacion
    *
    */
    LoginAD(login: string, password: string, org : number): Observable<UserLogin> {
      let url = "security/loginAD";
      return this.genericServ.postRequest(url, {}, 0, { Login: login, Password: password, Org: org });
    }

    /*
    ** pooling for version change.
    **/
    getCheckVersion() {
      this.globalFunction.getCeqVersion()
      .pipe(takeUntil(this._destroy$))
      .subscribe(notification0 => {
        this.RespDict = notification0;

        this.ceqVersion = this.RespDict.value;
        let currentVersion= localStorage.getItem("CeqVersion");
        console.log("CeqVersion Local Timer : "+ currentVersion);
        console.log("CeqVersion Server Timer : "+ this.ceqVersion);
        if (currentVersion != null)
        {
            if (currentVersion != this.ceqVersion)
            {
                console.log ("New Version")
                this.newVersion=true;
                this.updateCeqVersion();
            }
            else
            {
                console.log ("Version Actual")
                this.newVersion=false;
            }
        }
        else
        {
            console.log ("primera version")
            localStorage.setItem("CeqVersion",this.ceqVersion);
        }

        this.getNextCalendar().toPromise()
        .then((resp: Calendar) =>{
          if(resp != null && resp != undefined){
            this.NextCalendar = resp;
            this.getLocalTime().toPromise()
            .then((centralTime: Date)=>{
              // console.log((new Date(resp.StartAt).getTime() - new Date(centralTime).getTime())/60000);
              // console.log('resp.StartAt.getDate() - centralTime.getDate()');
              if(((new Date(resp.StartAt).getTime() - new Date(centralTime).getTime())/600000) < 10){
                this.validateCalendarProgress(centralTime);
              }
              else{
                // console.log('Not inventoryInPreogress');
                this.flagInvInProgress = false;
              }
            })
          }
        });

        timer(this.TimeToCheck).pipe(takeUntil(this._destroy$))
            .subscribe(t => this.getCheckVersion() );
      });
    }

    validateCalendarProgress(centralTime: any){
      if(this.invProgressTimer){
        clearInterval(this.invProgressTimer);
      }
      if(  (new Date(this.NextCalendar.StartAt).getTime() - new Date(centralTime).getTime())/60000 < 10
      && (new Date(this.NextCalendar.EndAt).getTime() - new Date(centralTime).getTime()) > 0){

        let diff = moment(moment(this.NextCalendar.EndAt)).diff(centralTime, 'milliseconds');
        let hrs = diff / 1000 / 60 / 60;
        if (hrs < 0) {
          this.hours = '0';
          this.minutes = '0';
          this.seconds = '0';
          return;
        }
        let mins = hrs < 1 ? (hrs+1 % Math.floor(hrs+1)) * 60 : (hrs % Math.floor(hrs)) * 60;
        let secs = mins < 1 ? (mins+1 % Math.floor(mins+1)) * 60 : (mins % Math.floor(mins)) * 60;
        //console.log(hrs);
        //console.log(mins);
        //console.log(secs);
        this.hours = isNaN(hrs) ? '0' : Math.floor(hrs).toString();
        this.minutes = isNaN(mins) ? '0' : Math.floor(mins).toString();
        this.seconds = isNaN(secs) ? '0' : Math.floor(secs).toString();

        if((new Date(this.NextCalendar.StartAt).getTime() - new Date(centralTime).getTime()) < 0){
          this.flagInvInProgress = true;
        }

        this.invProgressTimer = setInterval(() => {
          // console.log('calendarValidRange');
          // console.log(centralTime);
          this.validateCalendarProgress(new Date(centralTime).getTime() + 1000);
      }, 1000);
      }
      else{
        // console.log('false - calendarValidRange');
        this.flagInvInProgress = false;
        clearInterval(this.invProgressTimer);
      }
    }

    focusValid(){
      if(this.flagInvInProgress){
        this.getLocalTime().toPromise()
            .then((centralTime: Date)=>{
              this.validateCalendarProgress(new Date(centralTime).getTime());
            })
      }
    }

    /*
    * Update version in localstorage
    */
    updateCeqVersion(): void {

        localStorage.setItem("CeqVersion",this.ceqVersion);
        this.router.navigate(["/home"]).then(() => {
            window.location.reload();
          });
    }

    getNotifications(): void {
        this.messagingService.getNotifications(this.userLogin.Org, this.userLogin.Id).subscribe( notifications => {
            this.notifications = notifications;
        }, err => {
            console.log(err);
        });
    }

    setUserAccess(): void {
        if (!this.settings.isLogin) {
            this.settings.isLogin = false;
            this.settings.setUserAccess(this.settings.getUser().Id).then(() => { });
        }
    }

    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        // console.log(stat);
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleOffsidebar() {
        this.settings.toggleLayoutSetting('offsidebarOpen');
    }

    toggleCollapsedSideabar() {
        this.settings.toggleLayoutSetting('isCollapsed');
    }

    isCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }

    toggleFullScreen(event) {
        if (screenfull.enabled) {
            screenfull.toggle();
        }
    }

    logOut() {
        this.authService.logout();
        localStorage.clear();
        this.router.navigate(["/login"]);
        //    window.location.reload();
    }

    cmbUserOrgChange(orgId: number) {
        console.log("orgId", orgId);
    }


    readNotification(notification: Notification): void {
        this.messagingService.readNotification(notification.Org, notification.NotificationId).subscribe(() => {
            this.notifications = this.notifications.filter(ntf => ntf.NotificationId !== notification.NotificationId);
            if(notification.Url.includes('incidence/validate-incidence-detail/') ||
                  notification.Url.includes('management/remission-detail/') ||
                  (notification.Url.includes('/miscellaneous/miscellaneous-detail/') &&
                            (notification.Title == 'Miscelaneo pendiente: Registrado' || notification.Title == 'Miscelaneo pendiente: Rechazado'))){
                    window.open(notification.Url);
                  }
                  else{
                    this.router.navigate([`/${notification.Url}`]);
                  }
        }, err => {
            console.log(err);
        });
    }

    readAllNotification() {
      Swal.fire({
        title: '¿Quiere limpiar todas las notificaciones?',
        text: "Esta acción no se puede deshacer",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Limpiar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
            // this.notifications.forEach(element => {
            //   this.messagingService.readNotification(element.Org, element.NotificationId).subscribe(() => {
            //     this.notifications = this.notifications.filter(ntf => ntf.NotificationId !== element.NotificationId);
            //   });
            // }
            // )
            let notificationsId = this.notifications.map(x=> {return x.NotificationId});
            this.messagingService.readNotificationList(this.userLogin.Org,notificationsId).toPromise()
            .then(()=>{
              this.notifications = [];
              this.Toast.fire(
                '¡Hecho!',
                'Las notificaciones se han eliminado',
                'success'
              )
            })
            .catch(()=>{
              this.Toast.fire(
                '¡Error!',
                'No se han logrado marcar leídas todas las notificaciones',
                'warning'
              )
            });

        }
      })
    }
    getNextCalendar(){
      return this.genericServ.getRequest(`calendar/next`,{},0);
    }
    getLocalTime(){
      return this.genericServ.getRequest(`calendar/getCentralTime`,{},0);
    }
    home(){
      this.userLogin = this.settings.getUser();
          //Valida ruteo
      if(this.userLogin.UseWidget){
          this.router.navigate([`/widgets`]);
        }
        else{
          this.router.navigate(['/home']);
        }
    }

  validateUnitAdjust() {
    // console.log('valdiate unit adjust');
    this.adjustservice.validateAdjustActive(this.userLogin.UnitId)
      .toPromise()
      .then(resp => {
        if (resp.AdjustCode == '') { return; }

        this.UnitAdjustStarted(resp.CreatedAt, resp.AdjustCountdown);

      })
      .catch(err => {
        console.log(err);
      })


  }

  OnNotificationReceived(notif) {

    if (notif.data == undefined) { return; }
    if (notif.data.Action == undefined || notif.data.Action == null || notif.data.Action == '') { return; }

    if (notif.data.Action == 'AdjustStarted') {
      this.UnitAdjustEnded();
      this.UnitAdjustStarted(notif.data.CreatedAt, notif.data.CountDown);
    }

    if (notif.data.Action == 'AdjustEnded') {
      this.UnitAdjustEnded();
    }


  }

  UnitAdjustStarted(startDate:string,countDown:string) {
    // console.log('AdjustStarted');

    //obtengo el tiempo limite para la compulsa
    let timeArr = (countDown).split(':');
    let adjustCreatedAt = moment(new Date(startDate));

    //sumo el tiempo limite al tempo de creacion de la compulsa
    let maxTime = moment(adjustCreatedAt).add(timeArr[0], 'hours').add(timeArr[1], 'minutes').add(timeArr[2], 'seconds');

    this.adjTimerHeader = setInterval(() => {

      //si el tiempo limite de la compulsa es menor al actual caduco la compulsa
      let difSeconds = maxTime.diff(moment(new Date()), 'milliseconds');

      if (difSeconds <= 0) {
        //detengo el timer
        this.UnitAdjustEnded();
        return;
      }

      let hrs = difSeconds / 1000 / 60 / 60;
      if (hrs < 0) {
        this.adjHoursHeader = 0;
        this.adjMinutesHeader = 0;
        this.adjSecondsHeader = 0;
        return;
      }
      let mins = hrs < 1 ? (hrs + 1 % Math.floor(hrs + 1)) * 60 : (hrs % Math.floor(hrs)) * 60;
      let secs = mins < 1 ? (mins + 1 % Math.floor(mins + 1)) * 60 : (mins % Math.floor(mins)) * 60;

      this.adjHoursHeader = isNaN(hrs) ? 0 : Math.floor(hrs);
      this.adjMinutesHeader = isNaN(mins) ? 0 : Math.floor(mins);
      this.adjSecondsHeader = isNaN(secs) ? 0 : Math.floor(secs);

      this.adjustInProgress = true;
    }, 1000);

  }

  UnitAdjustEnded() {
    this.adjustInProgress = false;
    this.adjHoursHeader = 0;
    this.adjMinutesHeader = 0;
    this.adjSecondsHeader = 0;
    clearInterval(this.adjTimerHeader);
    // console.log('AdjustEnded');
  }



  }

function jwt_decode(token: string): any {
    throw new Error('Function not implemented.');
}

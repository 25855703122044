import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VehicleConfigModel } from '../../common/models/Vehicleconfig.Model';
import { GenericService } from '../../common/services/GenericServices';
import { OTMVehicleConfig } from '../../common/models/OTMVehicleConfig.Model';

@Injectable({
  providedIn: 'root'
})
export class VehicleconfigService {

  constructor(private genericService: GenericService) { }

  controllerUrl: string = "vehicleconfiguration";

  getVehicleConfigList(): Observable<VehicleConfigModel[]> {
    let url = this.controllerUrl;
    return this.genericService.getRequest(url, {}, 0);
  }

  getVehicleConfigByKey(vehicleConfigKey: string): Observable<VehicleConfigModel> {
    let url = `${this.controllerUrl}/${vehicleConfigKey}`;
    return this.genericService.getRequest(url, {}, 0);
  }

  addVehicleConfig(vechicleConfig: VehicleConfigModel): Observable<any> {
    let url = this.controllerUrl;
    return this.genericService.postRequest(url, {}, 0, vechicleConfig);
  }

  updateVehicleConfig(vechicleConfig: VehicleConfigModel): Observable<any> {
    let url = this.controllerUrl;
    return this.genericService.putRequest(url, {}, 0, vechicleConfig);
  }

  deleteVehicleConfig(vehicleConfigKey: string): Observable<any> {
    let url = `${this.controllerUrl}/${vehicleConfigKey}`;
    return this.genericService.deleteRequest(url, {}, 0);
  }

  validateVehicleConfig(vehicleConfig: OTMVehicleConfig): Observable<any> {
    return this.genericService.postRequest('otm/validateccp', {}, 0, vehicleConfig);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TransportLine } from '../../../common/models/TransportLine.Model';
import { SettingsService } from '../../../../core/settings/settings.service';
import { TransportLineService } from '../transport-line.service';
import { EmployeeService } from '../../../catalog/employee/employee.service';
import { InventoryService } from '../../../inventory/inventory.service';

import { toNumber } from 'lodash';
import Swal from 'sweetalert2';
import { UserLogin } from '../../../common/models/UserLogin.Model';
import { Employee } from '../../../common/models/Employee.Model';
import { Unit } from '../../../common/models/Unit.Model';

@Component({
  selector: 'app-transport-line-detail',
  templateUrl: './transport-line-detail.component.html',
  styleUrls: ['./transport-line-detail.component.scss']
})
export class TransportLineDetailComponent implements OnInit {

  constructor(private url: ActivatedRoute,
    private router: Router,
    private settings: SettingsService,
    private transportLineService: TransportLineService,
    private employeeService: EmployeeService,
    private inventoryService: InventoryService) {
    this.line = new TransportLine();
  }

  org: number = 1;
  userLogin: UserLogin;
  line: TransportLine;
  vehicleId: number;
  isNew: boolean = false;
  lines: TransportLine[] = [];
  selectedLine: number;
  searchLineField: string = 'LineName';

  ngOnInit(): void {
    this.userLogin = this.settings.getUser();
    let currentId = this.url.snapshot.paramMap.get('id');
    this.line.Org = this.userLogin.Org;

    if (this.isNullOrWhitespace(currentId)) {
      this.isNew = true;
    }
    else {
      this.isNew = false;
      this.line.TransportLineId = toNumber(currentId);
      this.getFiscalConfig();
    }
  }

  formSubmit(value: any) {
    if (this.isNew) {
      //Create
      this.transportLineService.newLine(this.line).subscribe(data => {
        Swal.fire('¡Éxito!', 'Línea de transporte creada', 'success');
        this.btnCancelOnClick();
      }, (error) => {
        Swal.fire('¡Error!', error.error, 'error');
      });
    }
    else {
      //Update
      this.transportLineService.updateLine(this.line).subscribe(data => {
        Swal.fire('¡Éxito!', 'Línea de transporte actualizada', 'success');
        this.btnCancelOnClick();
      }, (error) => {
        Swal.fire('¡Error!', error.error, 'error');
      });
    }
  }

  btnCancelOnClick() {
    // this.router.navigate(['/tax/transportline']);
    window.close();
  }

  changeLine(event: TransportLine): void {
    this.line.TransportLineId = event.TransportLineId;
  }


  isNullOrWhitespace(input) {
    if (typeof input === 'undefined' || input == null) return true;
    return input.replace(/\s/g, '').length < 1;
  }

  getFiscalConfig() {
    this.transportLineService.getLineById(this.line.TransportLineId).subscribe(data => {
      this.line = data;
      this.selectedLine = data.TransportLineId;

    }, (error) => {
      Swal.fire('¡Error!', "No se encontró la linea de transporte solicitada", 'error');
      this.btnCancelOnClick();
    });
  }
}

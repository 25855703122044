<div class="content-wrapper">

  <div class="card b mb-2">
    <div class="card-header bb">
      <h4 class="card-title">Detalle de Moneda</h4>
    </div>    
  </div>


  <form #Form="ngForm" (ngSubmit)="formSubmit(Form.value)">
    <div class="form-container">
      <div class="row">

        <div class="form-group col-sm-4">
          <label class="form-label">C&oacute;digo</label>
          <input class="form-control" type="text" value="" placeholder="C&oacute;digo" [(ngModel)]="currency.currencyCode" name="x" required maxlength="3" [disabled]="currency.currencyId !== 0" />
        </div>

        <div class="form-group col-sm-4">
          <label class="form-label">Descripci&oacute;n</label>
          <input class="form-control" type="text" value="" placeholder="Nombre" [(ngModel)]="currency.currencyName" name="y" required maxlength="50" />
        </div>

      </div>
      <br /><br />
      <div class="buttons-container">
        <button type="submit" class="mr-1 btn btn-labeled btn-outline-primary mb-2" [disabled]="!Form.valid">
          <span class="btn-label">
            <i class="fa fa-save"></i>
          </span>
          <span>Guardar</span>
        </button>
        &nbsp;&nbsp;
        <button type="button" class="mr-1 btn btn-labeled btn-outline-danger mb-2" (click)="btnCancelOnClick()">
          <span class="btn-label">
          <i class="fa fa-chevron-circle-left"></i>
        </span>
        <span>Volver</span>
        </button>
        
      </div>
    </div>
  </form>
</div>

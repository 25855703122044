import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../core/settings/settings.service';
import { GlobalFunctionsService } from '../../ceq/common/functions/global-functions.service';
import { Dict } from '../../ceq/common/models/Dict.Model';

@Component({
    selector: '[app-footer]',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  ceqVersion: string;

  constructor(public settings: SettingsService,
    public globalFunction: GlobalFunctionsService)
  {
  }

  ngOnInit() {
    this.globalFunction.getCeqVersion().subscribe((data: Dict) => {
      this.ceqVersion = data.value;
    });
  }

}

<div class="card-header">
  <div class="text-gray-darker">
    Detalle del Capex
  </div>
</div>
<div class="form-container">
  <div class="controls-container">
    <br>
    <form #capexForm="ngForm" (ngSubmit)="saveCapex(capexForm.value)">
      <fieldset>
        <div class="row">
          <div class="col-sm-6">
            <span>Nombre Capex</span>
            <input type="text" class="form-control w-80" maxlength="25" [(ngModel)]="capex.capexName" name="capexName" required>
          </div>

          <div class="col-sm-6">
            <label class="form-label">Nodo</label>
            <ng-autocomplete [initialValue]="initialOutputName"
                             [placeholder]="'Búsqueda'"
                             [data]="outputData"
                             [searchKeyword]="inputArgument"
                             (selected)='selectOutputEvent($event)'
                             [itemTemplate]="itemOutputTemplate"
                             [notFoundTemplate]="notFoundoutputTemplate"
                             [debounceTime]="600"
                             [isLoading]="isLoadingItemsOutput"
                             [minQueryLength]="3"
                             [(ngModel)]="selectedCapexName"
                             [ngModelOptions]="{standalone: true}"
                             (inputCleared)="cmbOutputChange()">
            </ng-autocomplete>
            <ng-template #itemOutputTemplate let-item>
              <a [innerHTML]="item.name"></a>
            </ng-template>

            <ng-template #notFoundoutputTemplate let-notFound>
              <div [innerHTML]="'No se encontraron coincidencias'"></div>
            </ng-template>
          </div>
        </div>

        <br>

        <div class="row">
          <div class="col-sm-3">
            <span>Monto del Capex</span>
            <input type="text" style="text-align:right" class="form-control w-80" maxlength="30" min="1" [(ngModel)]="capex.original" name="originalAmount" (keypress)="onKeyPress($event)" (focus)="onFocus_OriginalAmount()" (blur)="formatCurrency_OriginalAmount($event)" required>
          </div>
          <div class="col-sm-3">
            <span>Monto Reservado</span>
            <input type="text" style="text-align:right" class="form-control w-80" maxlength="30" min="1" [(ngModel)]="capex.reserve" name="reserveAmount" (keypress)="onKeyPress($event)" (focus)="onFocus_ReserveAmount()" (blur)="formatCurrency_ReserveAmount($event)" [disabled]="!editedData">
          </div>
          <div class="col-sm-3">
            <span>Monto Ejercido</span>
            <input type="text" style="text-align:right" class="form-control w-80" maxlength="30" min="1" [(ngModel)]="capex.executed" name="executedAmount" (keypress)="onKeyPress($event)" (focus)="onFocus_ExecutedAmount()" (blur)="formatCurrency_ExecuteAmount($event)" [disabled]="!editedData">
          </div>
          <div class="col-sm-3">
            <span>Monto Disponible</span>
            <input type="text" style="text-align:right" class="form-control w-80" maxlength="30" min="1" [(ngModel)]="capex.available" name="availableAmount" (keypress)="onKeyPress($event)" (focus)="onFocus_AvailableAmount()" (blur)="formatCurrency_AvailableAmount($event)" [disabled]="!editedData">
          </div>
        </div>

        <br>

        <div class="row">
          <div class="col-sm-6">
            <span>Tipo Moneda</span>
            <ng-autocomplete [initialValue]="initialCurrName"
                             [placeholder]="'Búsqueda'"
                             [data]="currencies"
                             [searchKeyword]="inputArgument"
                             (selected)='selectCurrencyEvent($event)'
                             [itemTemplate]="itemCurrTemplate"
                             [notFoundTemplate]="notFoundCurrTemplate"
                             [debounceTime]="600"
                             [isLoading]="isLoadingItemsCurr"
                             [minQueryLength]="3"
                             [(ngModel)]="capex.currencyName"
                             [ngModelOptions]="{standalone: true}"
                             (inputCleared)="cmbCurrencyChange()">
            </ng-autocomplete>
            <ng-template #itemCurrTemplate let-item>
              <a [innerHTML]="item.name"></a>
            </ng-template>

            <ng-template #notFoundCurrTemplate let-notFound>
              <div [innerHTML]="'No se encontraron coincidencias'"></div>
            </ng-template>
          </div>
          <div class="col-sm-6">
            <span>A&ntilde;o Capex</span>
            <input type="text" class="form-control w-80" maxlength="30" [(ngModel)]="capex.capexYear" name="capexYear" required>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>
<div class="form-container">
  <div class="buttons-container">
    &nbsp;
    <button type="submit" class="mr-1 btn btn-labeled btn-outline-success mb-2" (click)="saveCapex(true)" [disabled]="!capexForm.valid">
      <span class="btn-label">
        <i class="fa fa-check" *ngIf="!saving"></i>
        <i class="fa fa-circle-o-notch fa-spin" *ngIf="saving"></i>
      </span>
      <span *ngIf="!saving">Guardar</span>
      <span *ngIf="saving">Guardando ...</span>
    </button>&nbsp; &nbsp;
    <button type="button" class="mr-1 btn btn-labeled btn-outline-danger mb-2" [disabled]="saving" (click)="backClick()">
      <span class="btn-label">
        <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
      </span>
      <span>Volver</span>
    </button>
    
  </div>
</div>

// Common modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TreeModule } from '@circlon/angular-tree-component';
import { SharedModule } from '../../shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';

// Guards
import { AuthGuard } from '../common/guards/auth-guard.guard';

//Components
import { EventsLogComponent } from './events-log/events-log.component';
import { IaProcessComponent } from './ia-process/ia-process.component';
import { RepBalanceComponent } from './rep-balance/rep-balance.component';
import { RepIncidenceComponent } from './rep-incidence/rep-incidence.component';
import { RepInventoryComponent } from './rep-inventory/rep-inventory.component';
import { RepMovementComponent } from './rep-movement/rep-movement.component';
import { RepOngoingComponent } from './rep-ongoing/rep-ongoing.component';
import { ReportCutComponent } from './report-cut/report-cut.component';
import { RepLabelComponent } from './rep-label/rep-label.component';
import { EquipmentioComponent } from './equipmentio/equipmentio.component';
import { RepEquipmentloanComponent } from './rep-equipmentloan/rep-equipmentloan.component';
import { RepAdjustComponent } from './rep-adjust/rep-adjust.component';
import { RepContainerExchangeComponent } from './rep-container-exchange/rep-container-exchange.component';

const routes: Routes = [
  { path: 'events-log', component: EventsLogComponent,canActivate: [AuthGuard] },
  { path: 'ia-process', component: IaProcessComponent, canActivate: [AuthGuard] },
  { path: 'rep-balance', component: RepBalanceComponent, canActivate: [AuthGuard] },
  { path: 'rep-incidence', component: RepIncidenceComponent, canActivate: [AuthGuard] },
  { path: 'rep-inventory', component: RepInventoryComponent, canActivate: [AuthGuard] },
  { path: 'rep-movement', component: RepMovementComponent, canActivate: [AuthGuard] },
  { path: 'rep-ongoing', component: RepOngoingComponent, canActivate: [AuthGuard] },
  { path: 'rep-cut', component: ReportCutComponent, canActivate: [AuthGuard] },
  { path: 'rep-label', component: RepLabelComponent, canActivate: [AuthGuard] },
  { path: 'rep-equipmentloan', component: RepEquipmentloanComponent, canActivate: [AuthGuard] },
  { path: 'equipmentio', component: EquipmentioComponent, canActivate: [AuthGuard] },
  { path: 'rep-adjust', component: RepAdjustComponent, canActivate: [AuthGuard] },
  { path: 'rep-container-exchange', component: RepContainerExchangeComponent, canActivate: [AuthGuard] },
];

@NgModule({
  declarations: [EventsLogComponent,
    IaProcessComponent,
    RepBalanceComponent,
    RepIncidenceComponent,
    RepInventoryComponent,
    RepMovementComponent,
    RepOngoingComponent,
    ReportCutComponent,
    RepLabelComponent,
    RepEquipmentloanComponent,
    EquipmentioComponent,
    RepAdjustComponent,
    RepContainerExchangeComponent
  ], 
  imports: [
    SharedModule,
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    TreeModule
  ],
  exports: [
    RouterModule
  ],
  providers: [
  ]
})
export class ReportsModule { }

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SettingsService } from '../../../core/settings/settings.service';
import { GenericService } from "../services/GenericServices";

@Injectable({
  providedIn: 'root'
})
export class CeqTableService {

  constructor(private service: GenericService) { }

  getData(url: string) {
    return this.service.getRequest(url, {});
  }
}

import { ReturnStatement } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { FiscalConfigModel } from '../../common/models/FiscalConfig.Model';
import { GenericService } from '../../common/services/GenericServices';

@Injectable({
  providedIn: 'root'
})
export class FiscalconfigService {

  constructor(private genericService: GenericService) { }

  controllerUrl: string = "fiscalconfig";

  getFiscalConfigList(): Observable<FiscalConfigModel[]> {
    let url = this.controllerUrl;
    return this.genericService.getRequest(url, {});
  }

  getFiscalConfigById(fiscalConfigId: number): Observable<FiscalConfigModel> {
    let url = `${this.controllerUrl}/${fiscalConfigId}`;
    return this.genericService.getRequest(url, {});
  }

  createFiscalConfig(fiscalConfig: FiscalConfigModel): Observable<FiscalConfigModel> {
    let url = this.controllerUrl;
    return this.genericService.postRequest(url, {}, 0, fiscalConfig);
  }

  updateFiscalConfig(fiscalConfig: FiscalConfigModel): Observable<FiscalConfigModel> {
    let url = this.controllerUrl;
    return this.genericService.putRequest(url, {}, 0, fiscalConfig);
  }

  deleteFiscalConfig(fiscalConfigId: number): Observable<any> {
    let url = `${this.controllerUrl}/${fiscalConfigId}`;
    return this.genericService.deleteRequest(url, {}, 0);
  }
}

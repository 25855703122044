import { Injectable } from '@angular/core';
import { GenericService } from '../../common/services/GenericServices';
import { Observable } from 'rxjs';
import { List } from 'lodash';
import { Ceve } from '../../common/models/Ceve.Model';
import { Factory } from '../../common/models/Factory.Model';
import { Remission } from '../../common/models/Remission.Model';
import { Container } from '../../common/models/Container.Model';
import { RemissionDetail } from '../../common/models/RemissionDetail.Model';
import { RemissionShortDetail } from '../../common/models/RemissionDetail.Model';
import { RemissionProductItem } from '../../common/models/RemissionDetail.Model'
import { StatusRemission } from '../../common/models/StatusRemission.Model';
import { DocumentTransport, RemissionOTM } from '../../common/models/DocumentTransport.Model';
import { RemissionTrackHeader } from '../../common/models/RemissionTrackingDto.Model';
import { ContainerType } from '../../common/models/ContainerType.Model';
import { RemissionProdLabelNumber } from '../../common/models/RemissionProduct.Model';

@Injectable({
  providedIn: 'root'
})
export class RemissionService {

  constructor(private genericServ: GenericService) { }

  /**
   * Get all status remission in database
   *
   * @return {*}  {Observable<StatusRemission[]>} - Status remission list
   * @memberof RemissionService
   */
  getStatusRemissionList(): Observable<StatusRemission[]> {
    return this.genericServ.getRequest(`StatusRemission`, {});
  }

  /**
   * Get a remission by it's number
   * @param org
   * @param remissionNumber
   */
  getRemissionByNumber(org: number, remissionNumber: string): Observable<any[]> {
    let url = `Remission/${org}/${remissionNumber}/byRemissionNumber`;
    return this.genericServ.getRequest(url, {});
  }

  /**
   * Gets remission data from database
   *
   * @param {string} remissionNumber - Remission number to get data
   * @returns {Observable<Remission>} - Observable with remission type
   * @memberof RemissionService
   */
  getRemissionData(remissionNumber: string): Observable<Remission> {
    return this.genericServ.getRequest(`remission/${remissionNumber}`, {});
  }

  /**
   * Se obtiene el istado de remisioens por otm
   * @param remissionNumber 
   * @returns 
   */
  getRemissionsByOTM(otmNumber: string): Observable<any> {
    return this.genericServ.getRequest(`remission/remissionsByOtm/${otmNumber}`, {});
  }

  /**
   * Gets remission data to print from database
   *
   * @param {string} remissionNumber - Remission number to get data
   * @returns {Observable<Remission>} - Observable with remission type
   * @memberof RemissionService
   */
  getRemissionPrintedPdf(remissionNumber: string, getPdf: boolean = false): Observable<any> {
    return this.genericServ.getPdfRequest(`remission/print/${remissionNumber}/${getPdf}`, {}, 0);
  }

  getRemissionPrinted(remissionNumber: string): Observable<any> {
    return this.genericServ.getRequest(`remission/print/${remissionNumber}/${false}`, {}, 0);
  }

  /**
   * Get a factory by unit number
   *
   * @param {number} unitId - Unit id to find a factory
   * @returns {Observable<Factory>} Observable of factory type
   * @memberof RemissionService
   */
  getFactoryData(unitId: number): Observable<Factory> {
    return this.genericServ.getRequest(`factory/${unitId}`, {});
  }

  /**
   * Get a ceve by unit number
   *
   * @param {number} unitId - Unit id to find a ceve
   * @returns {Observable<Ceve>} - Observable of ceve type
   * @memberof RemissionService
   */
  getCeveData(unitId: number): Observable<Ceve> {
    return this.genericServ.getRequest(`ceve/${unitId}`, {});
  }

  /**
  * Get the ceve leader of the concentrator where unit are , instead return the ceve
  *
  * @param {number} unitId - Unit id to find a ceve
  * @returns {Observable<Ceve>} - Observable of ceve type
  * @memberof RemissionService
  */
  getCeveLeaderData(unitId: number): Observable<Ceve> {
    return this.genericServ.getRequest(`ceve/${unitId}/leader`, {});
  }

  /**
   * Gets remission detail data from specified remision number
   *
   * @param {string} remissionNumber - Remission number to retrieve data
   * @returns {Observable<RemissionDetail[]>} - Observable with list of remissionDetail type
   * @memberof RemissionService
   */
  getRemissionDetailData(remissionNumber: string): Observable<RemissionDetail[]> {
    return this.genericServ.getRequest(`remissiondetail/${remissionNumber}`, {});
  }

  /**
   * Get Product List of Remission
   * @param remissionNumber
   */
  getRemissionProductData(remissionNumber: string): Observable<any[]> {
    return this.genericServ.getRequest(`remissiondetail/product/${remissionNumber}`, {});
  }

  /**
   *
   * @param remissionNumber
   */
  getContainerExchangeData(remissionNumber: string): Observable<RemissionDetail[]> {
    return this.genericServ.getRequest(`remissiondetail/exchange/${remissionNumber}`, {});
  }

  /**
 *
 * @param remissionNumber
 */
  getRemissionExchangeData(remissionNumber: string): Observable<RemissionDetail[]> {
    return this.genericServ.getRequest(`remissiondetail/remissionexchange/${remissionNumber}`, {});
  }

  /**
   * Get a brief detail remission data
   * @param remissionNumber
   */
  getRemissionShortDetail(remissionNumber: string): Observable<RemissionShortDetail[]> {
    return this.genericServ.getRequest(`remissiondetail/short/${remissionNumber}`, {});
  }

  /**
   * Get a brief detail remission data
   * @param remissionNumber
   */
  getRemissionPtDetail(remissionNumber: string): Observable<RemissionProductItem[]> {
    return this.genericServ.getRequest(`remissiondetail/pt/${remissionNumber}`, {});
  }

  /**
   * Gets ceve list by search argument
   *
   * @param {string} argument- text to search
   * @returns {Observable<List<Ceve>>} - Observable list of Ceve type
   * @memberof RemissionService
   */
  getCeveListByArgument(argument: string, userId: number): Observable<List<Ceve>> {
    return this.genericServ.getRequest(`ceve/GetByArgument/${argument}/${userId}`, {});
  }

  /**
   * Gets ceve list by search argument looking in whole ceve list
   *
   * @param {string} argument- text to search
   * @returns {Observable<List<Ceve>>} - Observable list of Ceve type
   * @memberof RemissionService
   */
  getCeveListByArgumentFull(argument: string, origin: number): Observable<List<Ceve>> {
    return this.genericServ.getRequest(`ceve/GetByArgumentFull/${argument}/${origin}`, {});
  }

  /**
   * Gets ceve factory by search argument
   *
   * @param {string} argument - text to search
   * @returns {Observable<List<Factory>>} - Observable list of Ceve type
   * @memberof RemissionService
   */
  getFactoryListByArgument(argument: string, userId: number): Observable<List<Factory>> {
    return this.genericServ.getRequest(`factory/GetByArgument/${argument}/${userId}`, {});
  }

  /**
   * Gets ceve factory by search argument
   *
   * @param {string} argument - text to search
   * @returns {Observable<List<Factory>>} - Observable list of Ceve type
   * @memberof RemissionService
   */
  getFactoryTerListByArgument(argument: string, userId: number): Observable<List<Factory>> {
    return this.genericServ.getRequest(`factory/GetTerByArgument/${argument}/${userId}`, {});
  }

  /**
   * Gets all container list
   *
   * @returns {Observable<List<Container>>} - Observable list of Container type
   * @memberof RemissionService
   */
  getContainerList(): Observable<List<Container>> {
    return this.genericServ.getRequest(`container`, {});
  }

  /**
   * Retrieves all inventory from a desired node
   *
   * @param {number} org - Org parent of node
   * @param {number} unitId - Unit id to find their inventory
   * @return {*}  {Observable<any[]>} - List of inventory
   * @memberof RemissionService
   */
  getNodeInventory(org: number, unitId: number): Observable<any[]> {
    return this.genericServ.getRequest(`Inventory?$filter=Org eq ${org} and OrgUnit eq ${unitId}`, {});
  }

  /**
   * Gets all nodes which the user have permission
   *
   * @param {number} userId - userId to get nodes
   * @returns {Observable<any>} - Nodes from user
   * @memberof RemissionService
   */
  getUserNodes(userId: number): Observable<any> {
    return this.genericServ.getRequest(`userprofile/level/${userId}/nodes`, {});
  }

  /**
   * Adds a new remission to database
   *
   * @param {Remission} remission - Remission to add
   * @returns {Observable<any>} - Status request
   * @memberof RemissionService
   */
  addRemission(remission: Remission): Observable<any> {
    return this.genericServ.postRequest(`remission`, {}, 0, remission);
  }

  /**
  * Adds remission detail to database
  * @param remissionDetail: list of RemissionDetail
  * @param option: plab or multipoint
  * @param nextUnit: Unit node for next destination
  * @returns {Observable<any>} - Status request
  * @memberof RemissionService
  */
  addRangeRemissionDetail(remissionDetail: RemissionDetail[]): Observable<any> {
    return this.genericServ.postRequest(`RemissionDetail/range`, {}, 0, remissionDetail);
  }

  /**
   * Add remission PT detail to database
   * @param remissionDetail: list of  RemissionProductItem
   * @param option: plab or multipoint
   * @param nextUnit: Unit node for next destination
   * @returns 
   */
  addRemissionDetailPT(remissionDetail: RemissionProductItem[], remission: string): Observable<any> {
    return this.genericServ.postRequest(`RemissionDetail/pt/${remission}`, {}, 0, remissionDetail);
  }

  /**
   * Receive remission since CEQ, changing status to close
   *
   * @param {Remission} remission - Remission to update
   * @returns {Observable<any>} - Status request
   * @memberof RemissionService
   */
  editRemission(remission: Remission, newDestiny: number = 0): Observable<any> {
    return this.genericServ.putRequest(`remission/${remission.RemissionNumber}/${newDestiny}`, {}, 0, remission);
  }


  addTrackingIncidence(remissionNumber: string): Observable<any> {
    return this.genericServ.postRequest(`RemissionDetail/tracking/${remissionNumber}`, {}, 0, {});
  }

  /**
   * Adds remission detail to database
   *
   * @param {RemissionDetail} remissionDetail - Remission detail object to add
   * @returns {Observable<any>} - Status request
   * @memberof RemissionService
   */
  addRemissionDetail(remissionDetail: RemissionDetail): Observable<any> {
    return this.genericServ.postRequest(`RemissionDetail`, {}, 0, remissionDetail);
  }

  /**
   * Apply a remission movement to inventory
   *
   * @param {string} remissionNumber - remission Number to apply
   * @returns {Observable<Remission>} - Status request
   * @memberof RemissionService
   */
  applyRemission(remissionNumber: string): Observable<Remission> {
    return this.genericServ.getRequest(`remission/applyRemision/${remissionNumber}`, {});
  }

  /**
   * Removes a remission from database
   *
   * @param {string} remissionNumber - Remission number to remove
   * @returns {Observable<Remission>} - Status request
   * @memberof RemissionService
   */
  removeRemission(remissionNumber: string): Observable<Remission> {
    return this.genericServ.deleteRequest(`remission/${remissionNumber}`, {}, 0);
  }

  /**
   * Get all ceves and factory
   *
   * @returns {Observable<Any>}
   * @memberof RemissionService
   */
  getCeveFActoryList(): Observable<any> {
    return this.genericServ.getRequest(`unit/ceveFactory`, {});
  }

  /**
   * Get a new number of remission to a unitid given
   * @param unitid
   */
  getNewRemissionNumber(unitid: number): Observable<any> {
    return this.genericServ.getRequest(`remission/new?unitid=${unitid}`, {});
  }

  getNewMldRemissionNumber(unitid: number): Observable<any> {
    return this.genericServ.getRequest(`remission/newMld?unitid=${unitid}`, {});
  }

  GetRemision(unitId: number): Observable<any[]> {
    let url = `Unit/Remision/${unitId}`;
    return this.genericServ.getRequest(url, {});
  }

  getRemissionExcel(org: number, originUnitId: number, unitId: number, statusCode: number, startDate: string, endDate: string, shipDate: string, userId: number,remissionNumber:string, otmNumber: string, remissionType:string): Observable<any[]> {
    if(remissionNumber == ''){
      remissionNumber = 'NA';
    }
    if(otmNumber == ''){
      otmNumber = 'NA';
    }
    let url = `remission/excel/${org}/${originUnitId}/${unitId}/${statusCode}/${startDate}/${endDate}/${shipDate}/${userId}/${remissionNumber}/${otmNumber}/${remissionType}`;
    return this.genericServ.getExcelRequest(url, {}, 0);
  }

  getRemissionDetailExcel(org: number, originUnitId: number, unitId: number, statusCode: number, startDate: string, endDate: string, shipDate: string, userId: number, remissionNumber: string, otmNumber: string, remissionType: string): Observable<any[]> {
    if(remissionNumber == ''){
      remissionNumber = 'NA';
    }
    if(otmNumber == ''){
      otmNumber = 'NA';
    }
    let url = `remission/detail/excel/${org}/${originUnitId}/${unitId}/${statusCode}/${startDate}/${endDate}/${shipDate}/${userId}/${remissionNumber}/${otmNumber}/${remissionType}`;
    return this.genericServ.getExcelRequest(url, {}, 0);
  }
 
  CancelRemissionNumber(unitId: number, remissionNumber: string): Observable<any> {
    let url = `remission/${unitId}/${remissionNumber}/cancel`;
    return this.genericServ.postRequest(url, {}, 0, null);
  }

  saveCartaPorte(remission: string, carrier: DocumentTransport, option: string, nextUnit: number): Observable<any> {
    let url = `cartaporte/webcarrier/${remission}/${option}/${nextUnit}`;
    return this.genericServ.postRequest(url, {}, 0, carrier);
  }

  /**
   * Generate CP and AR documents
   * @param remissions 
   * @param dolly 
   * @returns 
   */
  saveCCPandAR(remissions: RemissionOTM[], dolly: string): Observable<any> {
    let url = `cartaporte/ccpar/${dolly}`;
    return this.genericServ.postRequest(url, {}, 0, remissions);
  }

  getCartaPorteData(remission: string): Observable<any[]> {
    let url = `cartaporte/webcarrier/rem/${remission}`;
    return this.genericServ.getRequest(url, {}, 0);
  }

  getCartaPorteReference(reference: string): Observable<any[]> {
    let url = `cartaporte/webcarrier/ref/${reference}`;
    return this.genericServ.getRequest(url, {}, 0);
  }

  getTransportLines(): Observable<any[]> {
    let url = `transportline`;
    return this.genericServ.getRequest(url, {}, 0);
  }

  sendThirdparty(remission: string): Observable<any[]> {
    let url = `cartaporte/thirdparty/${remission}`;
    return this.genericServ.postRequest(url, {}, 0, null);
  }

  verifyCartaPorte(unitId: number, reference: string): Observable<any>{
    let url = `cartaporte/multipoint-verify/${unitId}/${reference}`;
    return this.genericServ.getRequest(url, {}, 0);
  }

  saveMultipoint(remission: string, reference: string): Observable<any> {
    let url = `cartaporte/multipoint/${remission}/${reference}`;
    return this.genericServ.postRequest(url, {}, 0, null);
  }

  getMultipointDocs(unitId: number): Observable<any> {
    let url = `cartaporte/multipoint/documents/${unitId}`;
    return this.genericServ.getRequest(url, {}, 0);
  }

  getMultiorgNodes(unitId: number, org: number): Observable<any>{
    let url = `ceveexternalcode/${org}/Multiorg/external/${unitId}`;
    return this.genericServ.getRequest(url, {}, 0);
  }

  getSynergyNodes(unitId: number): Observable<any>{
    let url = `ceveexternalcode/remission/external/${unitId}`;
    return this.genericServ.getRequest(url, {}, 0);
  }

  getEcoListByArgument(type: string, argument: string): Observable<List<any>> {
    let url = `OTM/trailer/${type}/${argument}`;
    return this.genericServ.getRequest(url, {}, 0);
  }

  getDriverListByArgument(argument: string): Observable<List<any>> {
    let url = `OTM/driver/${argument}`;
    return this.genericServ.getRequest(url, {}, 0);
  }

  validateRemission(remissionNumber: string): Observable<Remission> {
    return this.genericServ.getRequest(`remission/validateRemission/${remissionNumber}`, {});
  }

  validateNodeActive(nodeId: number){
    return this.genericServ.getRequest(`unit/active/${nodeId}`, {});
  }

  getRemissionSource(unitId: number, driver: string): Observable<any> {
    let url = `documentselfregulation/remissionSource/${unitId}/${driver}`;
    return this.genericServ.getRequest(url, {}, 0);
  }

  getSelfRegData(remissionNumber: string): Observable<any> {
    let url = `documentselfregulation/selfRegulationData/${remissionNumber}`;
    return this.genericServ.getRequest(url, {}, 0);
  }

  getRemissionTrack(remissionNumber: string) : Observable<RemissionTrackHeader>{
    return this.genericServ.getRequest(`remission/tracking-remission/${remissionNumber}`, {}, 0);
  }

  getRemissionFile(remissionNumber: string) : Observable<RemissionTrackHeader>{
    return this.genericServ.getRequest(`remission/GetFile/${remissionNumber}`, {}, 0);
  }

  GetSingleNode(nodeId: number){
    return this.genericServ.getRequest(`unit/${nodeId}`, {});
  }

  getContainerTypeList(): Observable<List<ContainerType>> {
    return this.genericServ.getRequest(`containerType`, {});
  }

  getContainerMldOriTypeList(event: string): Observable<List<ContainerType>> {
    return this.genericServ.getRequest(`containertype/containerMldOriTypes/${event}`, {});
  }

  getContainerMldDesTypeList(event: string): Observable<List<ContainerType>> {
    return this.genericServ.getRequest(`containertype/containerMldDesTypes/${event}`, {});
  }

  getFactoryListMld(userId: number): Observable<List<Factory>> {
    return this.genericServ.getRequest(`factory/GetByArgumentMld/${userId}`, {});
  }

  getRemissionMldShortDetail(remissionNumber: string): Observable<RemissionShortDetail[]> {
    return this.genericServ.getRequest(`remissiondetail/Mldshort/${remissionNumber}`, {});
  }

  getContainerMldTypeList(): Observable<List<ContainerType>> {
    return this.genericServ.getRequest(`containertype/`, {});
  }

  getSaleOrderTemplate(): Observable<any[]> {
    return this.genericServ.getExcelRequest(`remissiondetail/saleordertemplate`, {});
  }

  validateSaleOrder(file: any):Observable<any>{
    const dataForm = new FormData();
    dataForm.append('file-0', file);
    return this.genericServ.postRequest('remissiondetail/saleorderfile', {}, 0,dataForm);
  }

  getMassiveLoadTemplate(): Observable<any[]> {
    return this.genericServ.getExcelRequest(`remission/massiveloadtemplate`, {});
  }

  verifyMassiveLoad(file: any):Observable<any>{
    const dataForm = new FormData();
    dataForm.append('file-0', file);
    return this.genericServ.postRequest('remission/massiveloadvalid', {}, 0,dataForm);
  }

  generateLabelNumber(unitId: number): Observable<RemissionProdLabelNumber>{
    return this.genericServ.getRequest(`label/nextserial/${unitId}`,0);
  }
  
  deleteUnusedLabel(labelCode: string,unitId:number): Observable<RemissionProdLabelNumber> {
    return this.genericServ.getRequest(`label/${labelCode}/removeheader/${unitId}`, 0);
  }

}

<div class="item user-block" *ngIf="userBlockIsVisible()">
    <!-- User picture-->
    <div class="user-block-picture">
        <div class="user-block-status" [routerLink]="['/security/user/self', user.Id]">
            <img class="profile-pic" [src]=" (user.Picture.Image !== null ? userPicture : '../../../../assets/img/user/default_profile.jpg')" alt="Avatar" />
            <div class="circle bg-success circle-lg"></div>
        </div>
    </div>
    <!-- Name and Job-->
    <div class="user-block-info">
      <span class="user-block-name">{{ 'sidebar.WELCOME' | translate }} {{user.Name}}</span>
      <span class="user-block-role">{{user.Job}}</span>
      <span class="user-block-role">{{user.RouteName}}</span>
     
    </div>
</div>

import { BaseModel } from "./BaseModel.Model";
import { CeveCutStatus } from "./CeveCutStatus.Enum";

export class CeveCut extends BaseModel {
    Org: number;
    CeveCutId: string;
    UnitId: number;
    DispatcherReciverSign: string;
    ManagerSign: string;
    SupervisorSign: string;
    DispatcherReciverComment: string;
    ManagerComment: string;
    SupervisorComment: string;
    Status: CeveCutStatus;
    CeveCutDay: string;
    Version: number;
}

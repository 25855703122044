import { Component, OnInit } from '@angular/core';
import { UserLogin } from '../../common/models/UserLogin.Model';
import { Unit } from '../../common/models/Unit.Model';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { ReportsService } from '../reports.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';

@Component({
  selector: 'app-rep-container-exchange',
  templateUrl: './rep-container-exchange.component.html',
  styleUrls: ['./rep-container-exchange.component.scss']
})
export class RepContainerExchangeComponent implements OnInit {

  constructor(
    private settings: SettingsService,
    private reportService: ReportsService,
    private globalFunctionService: GlobalFunctionsService
  ) { }

  downloadingDetail: boolean = false;
  downloadingTotals: boolean = false;
  user: UserLogin;
  nodes: Unit[];
  detailOrigin: Unit;
  detailDestination: Unit;
  detailLabel: string = '';
  detailLabelStartDate: string = '';
  detailLabelEndDate: string = '';
  detailRem: string = '';
  detailRemStartDate: string = '';
  detailRemEndDate: string = '';

  totalOrigin: Unit;
  totalDestination: Unit
  totalRem: string = '';
  totalRemStartDate: string = '';
  totalRemEndDate: string = '';

  datepipe: DatePipe = new DatePipe('en-US');
  maxDate: string = this.datepipe.transform(new Date(), 'yyyy-MM-dd');

  async ngOnInit() {
    this.user = this.settings.getUser();
    this.globalFunctionService.canOperate();
    this.globalFunctionService.hasFSBRPending();
    this.detailLabelStartDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.detailLabelEndDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.totalRemStartDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.totalRemEndDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.detailRemStartDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.detailRemEndDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    await this.getUserNodes();
  }

  async getUserNodes() {
    await this.reportService
      .getAllUserNodes(this.user.Org, this.user.Id)
      .toPromise()
      .then((resp: Unit[]) => {
        if (resp.length > 0) {

          this.nodes = resp.filter(x => x.LevelType == 'CEVE' || x.LevelType == "FAB");

          this.nodes.unshift({
            OrgId: 0,
            UnitId: 0,
            UnitDescription: 'Todos',
            CreatedAt: undefined,
            CreatedBy: undefined,
            ModifiedAt: undefined,
            ModifiedBy: undefined,
            ParentId: 0,
            UnitCode: undefined,
            LevelType: undefined,
          });
        }
        else {
          this.nodes = [{
            OrgId: undefined,
            UnitId: undefined,
            UnitDescription: 'No tiene Centros de ventas asignados',
            CreatedAt: undefined,
            CreatedBy: undefined,
            ModifiedAt: undefined,
            ModifiedBy: undefined,
            ParentId: undefined,
            UnitCode: undefined,
            LevelType: undefined,
          }];
        }

        this.detailOrigin = this.nodes[0];
        this.detailDestination = this.nodes[0];
        this.totalOrigin = this.nodes[0];
        this.totalDestination = this.nodes[0];

      })
      .catch(err => {
        console.log(err);
        this.showErrorMessage(err);
      })
      .finally(() => {
        this.downloadingDetail = false;
        this.downloadingTotals = false;
      });
  }

  totalOriginSelected() {
  }

  totalOriginCleared() {
    this.totalOrigin = this.nodes[0];
  }


  totalDestinationSelected() {

  }

  totalDestinationCleared() {
    this.totalDestination = this.nodes[0];
  }


  detailOriginSelected() {

  }

  detailOriginCleared() {
    this.detailOrigin = this.nodes[0];
  }


  detailDestinationSelected() {

  }

  detailDestinationCleared() {
    this.detailDestination = this.nodes[0];
  }



  async downloadDetail() {
    let type = 'D';
    if (!this.validate(type)) { return; }

    let lbl = this.detailLabel == '' ? '0' : this.detailLabel;
    let lblStartDate = this.detailLabelStartDate == '' ? '2000-01-01' : this.detailLabelStartDate;
    let lblEndDate = this.detailLabelEndDate == '' ? '2000-01-01' : this.detailLabelEndDate;
    let rem = this.detailRem == '' ? '0' : this.detailRem;
    let remStartDate = this.detailRemStartDate == '' ? '2000-01-01' : this.detailRemStartDate;
    let remEndDate = this.detailRemEndDate == '' ? '2000-01-01' : this.detailRemEndDate;

    const originValid = typeof this.detailOrigin.UnitId === 'number' && !isNaN(this.detailOrigin.UnitId);
    const destinationValid = typeof this.detailDestination.UnitId === 'number' && !isNaN(this.detailDestination.UnitId);

    if (!originValid) {
        this.showErrorMessage('Debe seleccionar un origen.');
        return;
    }

    if (!destinationValid) {
      this.showErrorMessage('Debe seleccionar un destino.');
      return;
    }

    this.downloadingDetail = true;
    await this.reportService
      .containerExchangeReport(
        type,
        this.detailOrigin.UnitId,
        this.detailDestination.UnitId,
        lbl,
        lblStartDate,
        lblEndDate,
        rem,
        remStartDate,
        remEndDate
      )
      .toPromise()
      .then(
        (resp: any) => {

          let blob = new Blob([resp], { type: resp.type });
          const anchor = document.createElement('a');
          anchor.download = `Reporte detalle cambio de equipo.xlsx`;
          anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
          anchor.click();
        },
        (err) => {
          console.log(err);
          this.showErrorMessage(err);
        }
      )
      .catch((err) => {
        this.showErrorMessage(err);
      })
      .finally(() => {
        this.downloadingDetail = false;
      });

  }

  async downloadTotal() {
    let type = 'T';
    if (!this.validate(type)) { return; }
    
    const toriginValid = typeof this.totalOrigin.UnitId === 'number' && !isNaN(this.totalOrigin.UnitId);
    const tdestinationValid = typeof this.totalDestination.UnitId === 'number' && !isNaN(this.totalDestination.UnitId);

    if (!toriginValid) {
        this.showErrorMessage('Debe seleccionar un origen.');
        return;
    }

    if (!tdestinationValid) {
      this.showErrorMessage('Debe seleccionar un destino.');
      return;
    }

    let rem = this.totalRem == '' ? '0' : this.totalRem;
    let remStartDate = this.totalRemStartDate == '' ? '2000-01-01' : this.totalRemStartDate;
    let remEndDate = this.totalRemEndDate == '' ? '2000-01-01' : this.totalRemEndDate;

    this.downloadingTotals = true;
    await this.reportService
      .containerExchangeReport(
        type,
        this.totalOrigin.UnitId,
        this.totalDestination.UnitId,
        '0',
        '2000-01-01',
        '2000-01-01',
        rem,
        remStartDate,
        remEndDate
      )
      .toPromise()
      .then(
        (resp: any) => {

          let blob = new Blob([resp], { type: resp.type });
          const anchor = document.createElement('a');
          anchor.download = `Reporte totales cambio de equipo.xlsx`;
          anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
          anchor.click();
        },
        (err) => {
          console.log(err);
          this.showErrorMessage(err);
        }
      )
      .catch((err) => {
        this.showErrorMessage(err);
      })
      .finally(() => {
        this.downloadingTotals = false;
      });
  }

  validate(type: string): boolean {

    if (type == 'D') {
      if (this.detailLabelStartDate > this.detailLabelEndDate) {
        this.globalFunctionService.messageToast(`La fecha inicio modificacion de etiqueta no puede ser mayor a la fecha fin.`, 'warning');
        return false;
      }
      if (this.detailRemStartDate > this.detailRemEndDate) {
        this.globalFunctionService.messageToast(`La fecha inicio recepcion de remision no puede ser mayor a la fecha fin.`, 'warning');
        return false;
      }
    }
    else {
      if (this.totalRemStartDate > this.totalRemEndDate) {
        this.globalFunctionService.messageToast(`La fecha de remision no puede ser mayor al final.`, 'warning');
        return false;
      }
    }

    return true;
  }

  showErrorMessage(err) {
    if (err?.error?.Message !== '' && err?.error?.Message !== undefined) {
      Swal.fire('', err.error.Message, 'warning');
    }
    else if (err?.message !== '' && err?.message !== undefined) {
      Swal.fire('', err.message, 'warning');
    }
    else {
      Swal.fire('', err, 'warning');
    }
  }


}

import { Component, OnInit, ViewChild } from '@angular/core';
import { CeqTableComponent } from '../../../../ceq/common/ceq-table/ceq-table.component';
import { DeleteChildRendered } from '../../../common/renders/delete-child-rendered';
import { GridOptions } from 'ag-grid-community';
import { Product } from '../../../common/models/Product.Model'
import { SettingsService } from '../../../../core/settings/settings.service';
import { TaxService } from '../../tax.service';
import { UserLogin } from '../../../common/models/UserLogin.Model';
import { List } from 'lodash';
import { Router } from '@angular/router';
import { ViewChildRendered } from '../../../common/renders/view-child-rendered';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})

export class ProductListComponent implements OnInit {


  @ViewChild('CeqGrid', { static: false }) ceqGrid: CeqTableComponent;

  productName: string = '';
  ProductList: List<Product>;
  user: UserLogin;
  pageSizes = [100,10,50,1000,'Todos']
  pageSize: number = 100;
  productCode: string = '';

  constructor(
    private settings: SettingsService,
    private router: Router,
    private taxService: TaxService
  ) {
    this.user = settings.getUser();
    taxService.getProduct(this.user.Org, '', 0).toPromise()
    .then((resp: List<Product>) => {
      console.log("init");
      console.log(resp);
      this.ProductList = resp;

    })
  }

  gridOptions: GridOptions = {
    context: this,
    frameworkComponents: {
      viewChildRendered: ViewChildRendered,
      childDeleteMessageRenderer: DeleteChildRendered
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) { },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    },
    onCellClicked(event: any) {
      if (event.colDef.field == 'ItemPlantaId') {
        Swal.fire('Planta ID', event.value)
      }
      if (event.colDef.field == 'ProductDescription') {
        Swal.fire('Producto', event.value)
      }
    }
  }

  columnDefs = [
    {
      headerName: 'Item Planta ID',
      field: 'ItemPlantaId',
      width: 10,
      cellClass: 'text-right',
      sortable: true,
      unSortIcon: true,
      sort: 'desc',
      resizable: true,
      editable: false
    },{
      headerName: 'Código',
      field: 'ProductCode',
      width: 10,
      cellClass: 'text-right',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      editable: false
    }, {
      headerName: 'Producto',
      field: 'ProductDescription',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      unSortIcon: true,
      resizable: true
    },{
      headerName: 'Descripción Corta',
      field: 'ProductName',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      unSortIcon: true,
      resizable: true
    },{
      headerName: 'Cupo',
      field: 'ProductConversion',
      width: 20,
      cellClass: 'text-right',
      editable: false,
      resizable: true
    },{
      headerName: 'Precio',
      field: 'ProductPrice',
      width: 10,
      cellClass: 'text-right',
      resizable: true,
      editable: false
    },{
      headerName: 'Peso',
      field: 'ProductWeight',
      width: 10,
      cellClass: 'text-right',
      resizable: true,
      editable: false
    },
    {
      headerName: 'Ver',
      field: 'icon',
      width: 10,
      cellRenderer: 'viewChildRendered',
      colId: 'params',
      cellClass: 'text-center',
      hide: false
    }
  ]

  ngOnInit(): void {

  }

  gridEmiter(GridOptions: GridOptions) {
    this.gridOptions = GridOptions;
    this.ceqGrid.endPoint = `product/${this.user.Org}/${this.pageSize}`;
    this.ceqGrid.refreshTable();
  }

  view(row: any) {
    window.open('/tax/product/' + row.data.ProductCode,'_blank');
  }

  getProduct(){
    var parameters = '';
    if(this.productCode != ''){
      parameters = `productCode=${this.productCode}`
    }
    if(this.productName != ''){
      if(this.productCode != ''){
        parameters = parameters + `&productName=${this.productName}`
      }
      else{
        parameters = `productName=${this.productName}`
      }
    }

    if(parameters != ''){
      parameters = '?' + parameters
    }
    console.log(`product/${this.user.Org}/${this.pageSize}${parameters}`);
    this.ceqGrid.endPoint = `product/${this.user.Org}/${this.pageSize}${parameters}`;
    this.ceqGrid.refreshTable();
  }

  onPageSizeChange(value: any){
    if(value == 'Todos'){
      this.pageSize = 0;
    }
    else{
      this.pageSize = value;
    }
    this.getProduct();
  }
}

import { Component, OnInit } from '@angular/core';
import { Unit } from '../../common/models/Unit.Model';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { DatePipe } from '@angular/common';
import { UserLogin } from '../../common/models/UserLogin.Model';
import { EsabMovementsService } from '../../inventory/esab-movements/esab-movements.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { EmployeeRoute } from 'src/app/ceq/common/models/EmployeeRoute.Model';

import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { ContainerType } from '../../common/models/ContainerType.Model';
import { Container } from '../../common/models/Container.Model';
import { Inventory } from '../../common/models/Inventory.Model';
import { Factory } from '../../common/models/Factory.Model';
import { RemoveEquipmentService } from '../../enterprise/remove-equipment/remove-equipment.service';

import { GlobalFunctionsService } from '../../common/functions/global-functions.service';
import { CalendarService} from '../../configuration/calendar/calendar.service';
import { EquipmentLoan } from '../../common/models/EquipmentLoan.Model';
import { EquipmentLoanDetail } from '../../common/models/EquipmentLoanDetail.Model';
import { ReportsService } from '../reports.service'
import { Calendar } from '../../common/models/Calendar.Model';
import { AdjustService } from '../../inventory/adjust/adjust.service';


@Component({
  selector: 'app-rep-equipmentloan',
  templateUrl: './rep-equipmentloan.component.html',
  styleUrls: ['./rep-equipmentloan.component.scss']
})
export class RepEquipmentloanComponent implements OnInit {

  inputArgument: string = 'UnitDescription';

  nodes: Unit[] = [];
  routes: Unit[] = [];
  channels: Unit[] = [];
  customers: Unit[] = [];
  employees: EmployeeRoute[] = [];
  status: string = '[{Id:0,Name:Todos},{Id:1,Name:Cancelado}]';
  reference: string;
  userLogin: UserLogin;
  date: Date;
  selectedDate: Date;
  selectedUnit: Unit ;
  selectedRoute: Unit = new Unit();
  selectedChannel: Unit = new Unit();
  selectedCustomer: Unit = new Unit();
  selectedEmployee: EmployeeRoute = new EmployeeRoute();
  ceveSelected: Unit = new Unit();
  searching: boolean = false;
  datepipe: DatePipe = new DatePipe('en-US');
  routeId: number;
  statusId: number;
  channelId: number;
  customerId: number;
  employeeId: string;
  rango: number = 7;
  asesor: string = "";
  initialDate: string = "";

  finalDate: string = "";
  working: boolean = false;
  actualDate: string = "";

  saving: boolean = false;
  Block: boolean = false;
  radioStatus: boolean;
  entidades: any[];
  entidad: number;
  cantidad: number;
  entSeleccionado: number;
  folio: string = "";
  referencia: string = "";
  quantity: number;
  entityList: Factory[] = [];
  IncidenceNumber: string;
  containertype: ContainerType[];
  container: Container[];
  containers: number;
  contSeleccionado: number = 0;
  inventory: Inventory[];
  inventorys: any;
  available: any;

  reason: number;
  canAdd: boolean;
  butDisabled: boolean;
  entid: number;
  unitEquipement: number;
  signUpForm: FormGroup;
  equipment: FormControl;
  equipmentValues = ['Envases', 'Contenedor'];

  equipo: any;
  downloading : boolean = false;

  Incid: any;
  selectedActivityId: number;
  activities: Container[];
  assignedActivities: any[] = [];
  keyword = "name";
  data: { org: number, unitid: number, code: string, name: string, type: string }[];
  equipmentLoan: EquipmentLoan = new EquipmentLoan();
  lstED: EquipmentLoanDetail[] = [];


  constructor(
    private router: Router,
    private services: EsabMovementsService,
    private settings: SettingsService,
    private RemoveEquipmentService: RemoveEquipmentService,
    private CalServices : CalendarService,
    private reportService : ReportsService,
    private adjustService:AdjustService,
    private globalFunctionService: GlobalFunctionsService
  ) { }

  async ngOnInit() {
    this.globalFunctionService.canOperate();
    this.globalFunctionService.hasFSBRPending();
   await  this.CalServices.CalendarInventoryInProgress().subscribe((calendar: Calendar[] )=>{
      if (calendar.length==1)
      {
        this.globalFunctionService.messageToast(`No es posible realizar esta operación, se encuentra en toma de inventario fisico.`, 'warning');
        this.router.navigate([`/home`]);
    }
    else
    {
      //this.router.navigate([`/management/remission-detail/new`]);
    }

    });

    await this.adjustService.validateAdjustActive(this.settings.getUser().UnitId)
      .toPromise()
      .then(resp => {
        if (resp.AdjustCode !== '') {
          this.globalFunctionService.messageToast(`No es posible realizar esta operación, se encuentra en compulsa.`, 'warning');
          this.router.navigate([`/home`]);
        }
      });

    this.userLogin = this.settings.getUser();
    this.services.getCentralTime().toPromise()
      .then((resp: Date) => {
        this.date = resp;

      });


    this.actualDate = this.datepipe.transform(new Date(), 'dd/MM/yyyy');
    this.initialDate = this.actualDate;
    this.finalDate = this.actualDate;
    this.initialDate = new Date().toISOString().split('T')[0];
    this.finalDate = new Date().toISOString().split('T')[0];




    this.getUserNodes();



  }

  toastWarningNotification(message: string) {
    Swal.fire({
      icon: 'warning',
      title: JSON.stringify(message),
    });
  }



  changeDate(initial: boolean) {
    
    /*
      if (initial) {
        this.finalDate = this.initialDate;
      } else {
        this.initialDate = this.finalDate;
      }
      */
  }



btnDowndload()
{
  if (this.selectedUnit.UnitId == undefined || this.selectedUnit.UnitId == null)
  {
    //   Swal.fire({
    //     icon: 'warning',
    //     text: 'Es necesario seleccionar un CEVE!'
    //   });
    //  return;
    this.selectedUnit=this.nodes[0];
  }

  if (this.initialDate == undefined || this.initialDate=="" ) {
    Swal.fire({
      icon: 'warning',
      text: 'Debe seleccionar una fecha inicial'
    });

    return;

  }

  if (this.finalDate == undefined || this.finalDate=="") {
    Swal.fire({
      icon: 'warning',
      text: 'Debe seleccionar una fecha final'
    });

    return;

  }

  if (new Date(this.initialDate) > new Date(this.finalDate)) {
    Swal.fire({
      icon: 'warning',
      text: 'La fecha inicial no puede ser mayor a la fecha final'
    });

    return;
  }

this.downloading = true;
    console.log ("Antes de llamada");
    this.reportService.getRepEquipmentLoan(this.selectedUnit.UnitId, this.initialDate, this.finalDate).subscribe((resp: any) => {
      console.log("Fin llamada");
      if(resp == null){
        this.downloading=false;
        Swal.fire({
          icon: 'warning',
          title: 'No se ha encontrado información.'
        });
        return;
      }
     console.log("termino llamada");

      let blob = new Blob([resp], { type: resp.type });

      const anchor = document.createElement('a');

      anchor.download = `CEQ-ReporteEquipmentLoan.xlsx`;
      anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
      anchor.click();
      this.downloading=false;

    }, err => {
      this.downloading=false;
      Swal.fire({
        icon: 'warning',
        title: 'Error en la invocación'
      });

      console.log(err);
    });


}

  GetTeamDropReason() {


  }


  GetTeamDropQuantity() { }


  removeRow(containerId: string): void {
    this.assignedActivities = this.assignedActivities.filter(env => env.containerId.toString() !== containerId.toString());
    this.GetTeamDropQuantity();
  }

  addItem(container: any): void {
    if (container.quantity == 0)
      return
    let exists = this.assignedActivities.find(c => c.containerId.toString() === container.SelectedControler);
    if (exists) {
      exists.Cantidad += container.quantity;
      this.available = this.available - container.quantity
    } else {
      this.assignedActivities.push({
        containerId: container.SelectedControler,
        ContainerCode: this.container.find(c => c.ContainerId.toString() === container.SelectedControler.toString()).ContainerCode,
        ContainerDescription: this.container.find(c => c.ContainerId.toString() === container.SelectedControler.toString()).ContainerDescription,
        Cantidad: container.quantity,
        ContainerType: this.container.find(c => c.ContainerId.toString() === container.SelectedControler.toString()).ContainerTypeId
      });
      this.available = this.available - container.quantity;
      // }
    }
    this.cantidad = 0;
  }


  saveComodato()

  {



  }

  clearControls(): void {
    this.folio = '';
    this.referencia = '';
    this.assignedActivities = [];
    this.contSeleccionado = 0;
    this.available = 0;
    this.unitEquipement = 0;
    this.radioStatus = false;
    this.butDisabled = true;
    this.Block = false;


    this.SelectedControler
  }


  btnCancelOnClick() {
    this.router.navigate(['/self-service/equipment-loan/list']);
  }

  SelectedControler() {
    this.GetTeamDropQuantity();
    this.contSeleccionado;
  }


  GetTeamDropContainer(equipo) {
    this.RemoveEquipmentService.GetTeamContainer(equipo).subscribe((resp: Container[]) => {
      this.container = resp;
      const contAll: Container = new Container();
      this.cantidad = 1;
      //contAll.ContainerId = 0;
      //contAll.ContainerDescription = 'Todos';
      //if (contAll.ContainerDescription = 'Todos') {
      //  this.available = 0;
      //  this.cantidad = 1;
      //}

      if (this.container != undefined && this.container != null) {
        //this.container.unshift(contAll);
      }


    }, err => {
      Swal.fire({
        icon: 'warning',
        text: 'Error al obtener lista de Equipo, verifique su conexión a internet.',
      });
    });

  }




  radioChangeHandler(equipment) {
    this.butDisabled = false;
    this.equipo = equipment;
    this.GetTeamDropContainer(this.equipo);
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

clearCeVeEvent(value: any): void {
  this.selectedUnit = null;
}


  selectCeVeEvent(value: any): void {

    this.selectedUnit = value;
    this.getChannelNodes(value.UnitId);
    //Empleados a nivel de CEVE
   // this.getEmployeeNodes(value.UnitId);
  }

  cmbChannelChange(value: any): void {

    console.log("Valor channelId " + this.channelId);
    this.getRouteNodes(this.channelId);

  }



  cmbCustomerChange(value: any): void {


  //  this.customerId=value.UnitId
  }


  cmbRouteChange(value: any): void {

    this.getCustomerNodes(this.routeId);
  }

  cmbEmployeeChange(value: any): void {

    console.log(value);
  }

  getEmployeeNodes(unitid: number) {
    this.employees = [];

    this.services
      .getEmployeeByCeve(unitid)
      .toPromise()
      .then((resp: EmployeeRoute[]) => {


        resp.map((x) => {

            this.employees.push(x);

        });
        this.selectedEmployee = this.employees[0];
      })
      .catch();
  }


  getCustomerNodes(unitid: number) {
    this.customers = [];

    this.services
      .getUserChannels(unitid)
      .toPromise()
      .then((resp: Unit[]) => {


        resp.map((x) => {
          if (x.LevelType == 'CLI') {
            this.customers.push(x);
          }
        });
        this.selectedCustomer = this.customers[0];
      })
      .catch();
  }



  getChannelNodes(unitid: number) {
    this.routes = [];
    this.routeId = 0;
    this.channels = [];
    this.services
      .getUserChannels(unitid)
      .toPromise()
      .then((resp: Unit[]) => {


        resp.map((x) => {
          if (x.LevelType == 'CANAL') {
            this.channels.push(x);
          }
        });
        this.selectedChannel = this.channels[0];
      })
      .catch();
  }


  getRouteNodes(unitid: number) {
    this.routes = [];

    this.services
      .getUserChannels(unitid)
      .toPromise()
      .then((resp: Unit[]) => {


        resp.map((x) => {
          if (x.LevelType == 'RUTA') {
            this.routes.push(x);
          }
        });
        this.selectedRoute = this.routes[0];
      })
      .catch();
  }

  getUserNodes() {
    this.services
      .getUserNodes(this.userLogin.Org, this.userLogin.Id)
      .toPromise()
      .then((resp: Unit[]) => {

      let todos = new Unit;
        todos.UnitId = 0;
        todos.UnitDescription = "Todos";
        this.nodes.push(todos);

        resp.map((x) => {
          if (x.LevelType == 'CEVE') {
            this.nodes.push(x);
          }
        });

       // this.selectedUnit = this.nodes[0];
        this.selectedRoute = new Unit;
        this.selectedChannel = new Unit;
        this.channels = [];
        this.routes = [];
        this.routeId = 0;
        this.channelId = 0;
        this.selectedUnit = todos;


      })
      .catch();
  }


}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserLogin } from '../../ceq/common/models/UserLogin.Model';
import { GenericService } from "../../ceq/common/services/GenericServices"

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor(private genericService: GenericService) { }

  Login(login: string, password: string): Observable<UserLogin> {
    let url = "security/login";
    return this.genericService.postRequest(url, {}, 0, { Login: login, Password: password, FirebaseToken: "" });
  }
  
  LoginAD(login: string, password: string): Observable<UserLogin> {
    let url = "security/loginAD";
    console.log(url);
    return this.genericService.postRequest(url, {}, 0, { Login: login, Password: password });
  }

}

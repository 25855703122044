<div class="content-heading">
    <div class="header-container">
        <h3>Ajuste de Flujos</h3>
        <small>Inventarios</small>
    </div>
</div>

<div class="card" style="height: 450px;">
    <div class="card-body">
        <section class="row">
            <div class="col col-md-12">
                <button type="button" class="mr-1 btn btn-labeled btn-outline-success mb-2" (click)="downloadExmpleFile()">
                    <span class="btn-label"><i class="fas fa-file-excel"></i></span>
                    <span>Descargar formato de ajuste</span>
                </button>
            </div>
        </section>

        <hr>

        <section class="row">
            <div class="col col-md-3">
                <button class="mr-1 btn btn-labeled btn-outline-primary mb-2" [disabled]="step!=0" (click)="btnFileUpload.click()">
                    <span class="btn-label"><i class="fas fa-file-upload"></i></span>
                    <span>Cargar nuevos flujos</span>
                </button>
                <input type="file" accept=".xls,.xlsx" #btnFileUpload hidden (change)="onFileSelected($event.target.files)" (click)="uploadClean($event)">
            </div>
            <div class="col col-md-9">
                <progress *ngIf="step!=0" style="width: 100%;"></progress>
                <br>
                <h4 *ngIf="step==1">Validando informaci&oacute;n, por favor no cierre la pesta&ntilde;a o el explorador</h4>
                <h4 *ngIf="step==2">Aplicando ajuste, por favor no cierre la pestaña o el explorador</h4>
            </div>
        </section>

        <hr>

        <section class="row">
            <div class="col-12 col-md-12">
                <h4>Consulta de Ajustes de Flujos</h4>
            </div>
            <div class="col col-md-6 form-group">
                <label class="form-label">Nodo</label>
                <ng-autocomplete [initialValue]="initialInputName" [placeholder]="'Búsqueda'" [disabled]="" [data]="outputData" [searchKeyword]="inputArgument" (selected)='selectOutputEvent($event)' [itemTemplate]="itemOutputTemplate" [notFoundTemplate]="notFoundoutputTemplate" [debounceTime]="600" [isLoading]="isLoadingItemsOutput" [minQueryLength]="3" [(ngModel)]="selectedUnit" (inputCleared)="cmbOutputChange()">
                </ng-autocomplete>
                <ng-template #itemOutputTemplate let-item>
                    <a [innerHTML]="item.name"></a>
                </ng-template>

                <ng-template #notFoundoutputTemplate let-notFound>
                    <div [innerHTML]="'No se encontraron coincidencias'"></div>
                </ng-template>
            </div>
            <div class="col col-md-6"></div>

            <div class="col col-md-4 form-group">
                <label class="form-label">Fecha inicial</label>
                <input type="date" class="form-control" [ngModel]="startDate | date:'yyyy-MM-dd'" (ngModelChange)="startDate=$event">
            </div>
            <div class="col col-md-4 form-group">
                <label class="form-label">Fecha final</label>
                <input type="date" class="form-control" [ngModel]="endDate | date:'yyyy-MM-dd'" (ngModelChange)="endDate=$event">
            </div>

            <div class="col col-md-12">
                <button type="button" class="mr-1 btn btn-labeled btn-outline-info mb-2" (click)="downloadFile()">
                    <span class="btn-label"><i class="fas fa-file-download"></i></span>
                    <span>Descargar consulta</span>
                </button>
            </div>
        </section>
    </div>
</div>
import { BaseModel } from './BaseModel.Model';
import { ContainerBalance } from './ContainerBalance.Model';
import { ContainerType } from "./ContainerType.Model"

export class Container extends BaseModel {
  ContainerId: number;
  ContainerCode: string;
  ContainerName: string;
  ContainerDescription: string;
  ContainerTypeId: number;
  ContainerType: ContainerType;
  ContainerTypeDescription: string;
  ContainerBalance: ContainerBalance;
  ContainerActive: boolean;
  TowerCode: string;
  ContainerWeight: number;
  ContainerPrice: number;
  LabelCap: number;
  ContainerHeight: number;
  ContainerLength: number;
  ContainerWidth: number;
}
